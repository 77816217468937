import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { RESPONDENT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";
import { DocGenerator } from "utils";
import { downloadLegalDoc } from "slices/disputeSlice";
import { formatDate } from "utils";
import { getClaimantDetails } from "../ArbitratorHotstar/NewArbitratorSingleDispute";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: `"Inter", "sans-serif"`,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
  },
  blue: {
    fontFamily: `"Inter", "sans-serif"`,
    color: "#2F80ED",
    lineHeight: "24px",
  },
  fonts: {
    lineHeight: "24px",
    fontSize: "16px",
    fontFamily: `"Inter", "sans-serif"`,
  },
  item: {
    "&:hover": {
      background: "#EEEEEE",
    },
  },
  calenderBtn: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#2F80ED",
    textTransform: "none",
    borderRadius: "2px",
    border: "1px solid #2F80ED",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
    width: "100%",
    maxWidth: "100%",
    "&:hover": {
      background: "#2F80ED",
      color: "#FFFFFF",
      border: "1px solid #2F80ED",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));
export default function DisputeDisplayHotstar({
  activeDispute,
  activeCase,
  profile,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [claimant_email, setClaimant_email] = React.useState("");
  React.useEffect(() => {
    getClaimantDetails(activeDispute.id).then((res) => {
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        if (element.profile_type == "general") {
          setClaimant_email(element.user.email);
          setLoading(false);
          break;
        }
      }
    });
  }, []);

  const [decisionFiles, setdecisionFiles] = React.useState(
    activeDispute.all_docs
  );
  const [finalDecision, setFinalDecision] = React.useState();

  React.useEffect(() => {
    decisionFiles.forEach((file) => {
      if (file.is_final) {
        setFinalDecision(file.id);
      }
    });
  }, [decisionFiles]);

  if (loading) return <CircularCenteredLoader />;

  return (
    <Container>
      <Box p="2rem 0">
        <Grid container>
          <Grid item md={2} sm={0} xs={0}></Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper
              style={{ background: "none", border: "none", boxShadow: "none" }}
            >
              <Button
                onClick={() => {
                  // history.push("/disputes");
                  history.goBack();
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Paper>
            <Paper style={{ padding: "3.5rem" }}>
              <Box className={classes.text}>
                <Typography style={{ fontSize: "2rem", lineHeight: "36px" }}>
                  {activeDispute.name.split("&")[0]}
                </Typography>
                <Box height="1rem"></Box>
              </Box>
              <Grid container direction="row">
                <Grid
                  item
                  xs
                  sm={3}
                  md={3}
                  style={{ padding: "10px 20px 10px 0" }}
                >
                  <Typography
                    className={classes.blue}
                    onClick={() => {
                      DocGenerator(activeDispute.id, "claimant");
                    }}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    VIEW GRIEVANCE
                  </Typography>
                </Grid>
                {activeDispute.flow_type !== "mediation" &&
                  activeCase.type !== "claimant" &&
                  activeCase.type === "respondent" && (
                    <Grid
                      item
                      xs
                      sm={3}
                      md={3}
                      style={{ padding: "10px 10px 10px 0" }}
                    >
                      <Typography
                        className={classes.blue}
                        onClick={() => {
                          DocGenerator(activeDispute.id, RESPONDENT);
                        }}
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        VIEW RESPONSE
                      </Typography>
                    </Grid>
                  )}
              </Grid>
              <Box height="2rem"></Box>
              <Grid container className={classes.fonts}>
                <Grid item xs={12} md={6}>
                  <span className={classes.blue}>Date of filing: </span>
                  {formatDate(activeDispute.created)}
                  <Box height="0.1rem"></Box>
                  <span className={classes.blue}>Registration Date: </span>
                  {activeDispute.approved
                    ? formatDate(activeDispute.approved_date)
                    : "--"}
                  <Box height="0.1rem"></Box>
                  <span className={classes.blue}>Contact: </span>
                  {claimant_email}
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item xs={12} md={5}>
                  <span className={classes.blue}>Grievance ID: </span>
                  {activeDispute.id}
                  {/* {activeDispute.approved ? activeDispute.id : "--"} */}
                  <Box height="0.1rem"></Box>
                  <span className={classes.blue}>Decision: </span>
                  {finalDecision ? (
                    <u
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                        fontFamily: `"Inter", "sans-serif"`,
                        color: "#2F80ED",
                        lineHeight: "24px",
                      }}
                      onClick={() => {
                        downloadLegalDoc(finalDecision);
                      }}
                    >
                      <span className={classes.blue}>VIEW</span>
                    </u>
                  ) : (
                    "(In Progress)"
                  )}
                  <Box height="0.1rem"></Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box height="2rem"></Box>
                  <Grid container direction="row" justify="space-evenly">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ padding: "10px" }}
                    >
                      <Button
                        className={classes.calenderBtn}
                        variant="outlined"
                        // color="myblack"
                        size="large"
                        onClick={() => {
                          history.push("/events");
                        }}
                      >
                        <FormattedMessage id="buttons.calendar" />
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ padding: "10px" }}
                    >
                      <Button
                        variant="outlined"
                        className={classes.calenderBtn}
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push(`/disputes/${activeDispute.id}/files`);
                        }}
                      >
                        <FormattedMessage id="common.files" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Box height="4rem"></Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
