import { containerContext } from "features/Chat/ChatContainer";
import throttle from "lodash/throttle";
import React, { useContext, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAnchorMessage } from "slices/chatSlice";
import { fetchMembers, setActive } from "slices/groupSlice";
import {
  fetchHistory,
  scrollToLast,
  scrollToMessage,
  subscribeToRoom,
  typingIndicatorSub,
  unsubscribeToRoom,
} from "utils/chatUtils";
import ChatBody from "./ChatBody";

// import isEmpty from "lodash/isEmpty";

const throttledFetchMembers = throttle(fetchMembers, 500);

export default function ChatBodyContainer({
  selected,
  classes,
  rightDocOpen,
  smallScreen,
  setStep,
}) {
  const dispatch = useDispatch();
  const { ws, messageContainer, showVC, setShowVC } =
    useContext(containerContext);

  const { typing, messages, anchorMessage } = useSelector(
    (state) => state.chat,
    shallowEqual
  );

  const { groups, loading, members } = useSelector(
    (state) => state.groups,
    shallowEqual
  );

  const group = groups?.find( group => group._id === selected);

  const prevGroup = useRef();

  useEffect(() => {
    dispatch(setActive(group._id));
    localStorage.setItem('activeGroup',group._id);
    dispatch(throttledFetchMembers(group._id));
    // prevGroup.current && unsubscribeToRoom(ws);
    fetchHistory(ws, group._id);
    anchorMessage
      ? scrollToMessage(messageContainer, anchorMessage, () => {
          dispatch(setAnchorMessage(null));
        })
      : scrollToLast(messageContainer);
    // subscribeToRoom(ws, group._id);
    typingIndicatorSub(ws, group._id);
    prevGroup.current = group._id;
  }, [group?._id]);

  return (
    <React.Fragment>
      {!loading && Object.keys(group).length > 0 && (
        <ChatBody
          rightDocOpen={rightDocOpen}
          group={group}
          classes={classes}
          messages={messages}
          members={members}
          typing={typing}
          messageContainer={messageContainer}
          smallScreen={smallScreen}
          setStep={setStep}
          showVC={showVC}
        />
      )}
    </React.Fragment>
  );
}
