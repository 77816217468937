import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem 0 2rem 3rem",
    cursor: "pointer",
  },
  selectedpaper: {
    padding: "2rem 0 2rem 3rem",
    background: "#edf4fe",
    // opacity: "0.12",
  },
  heading: {
    fontSize: "20px",
    lineHeight: "22px",
    color: "#333333",
  },
  text: {
    fontSize: "56px",
    lineHeight: "62px",
    letterSpacing: "0.05em",
    color: "#333333",
    marginTop: "0.5rem",
  },
}));

export default function ChooseStepTwo({ choosestep2, setChoosestep2, data }) {
  const classes = useStyles();

  return (
    <>
      <Grid container style={{ marginTop: "0.5rem" }} spacing={3}>
        <Grid item md={4} sm={6} xs={12} style={{display:'flex'}}>
          <Paper
            style={{width:'100%'}}
            className={choosestep2 == 1 ? classes.selectedpaper : classes.paper}
            onClick={() => {
              setChoosestep2(1);
            }}
          >
            <Typography className={classes.heading}>Complaints</Typography>
            <Typography className={classes.text}>{data[0]}</Typography>
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12} style={{display:'flex'}}>
          <Paper
            style={{width:'100%'}}
            className={choosestep2 == 2 ? classes.selectedpaper : classes.paper}
            onClick={() => {
              setChoosestep2(2);
            }}
          >
            <Typography className={classes.heading}>Arbitrations</Typography>
            <Typography className={classes.text}>{data[1]}</Typography>
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12} style={{display:'flex'}}>
          <Paper
            style={{width:'100%'}}
            className={choosestep2 == 3 ? classes.selectedpaper : classes.paper}
            onClick={() => {
              setChoosestep2(3);
            }}
          >
            <Typography className={classes.heading}>
              Appellate Arbitrations
            </Typography>
            <Typography className={classes.text}>{data[2]}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
