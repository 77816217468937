import { Backdrop, Button, Fade } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import instance from "api/globalaxios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isHotStar, isMuthoot, isStar } from "Routes/Routes";
import Question from "./Question";
// import EditProfileRow from "./EditProfileRow";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  editprofile: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

export default function QuestionModal({
  openmodal,
  handleCloseModal,
  activeDispute,
  setOpenmodal3,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState();
  const answerValues = new Map();
  const [loading, setLoading] = useState(true);

  function setAnswersAPI(answerValues) {
    return async (dispatch) => {
      try {
        const response = await instance.post(
          `/api/closedisputeanswer/${activeDispute.id}/`,
          [
            {
              dispute: activeDispute.id,
              answer: answerValues.get(1) == true ? 1 : 0,
              question: 1,
            },
          ]
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const OnSibmit = () => {
    if (!(answerValues.get(1) == true || answerValues.get(1) == false)) {
      alert("Choose one option in every question");
    } else {
      dispatch(setAnswersAPI(answerValues));
      setOpenmodal3(false);
    }
  };

  function getQuestions() {
    return async (dispatch) => {
      try {
        const response = await instance.get("/closedisputequestion/");
        setQuestions(response.data.results);
        return response.data.results;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function getAnswers(ques) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/closedisputeanswer/${activeDispute.id}/`
        );
        setAnswers(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  React.useEffect(() => {
    dispatch(getQuestions()).then((ques) => {
      dispatch(getAnswers(ques)).then(() => {
        setLoading(false);
      });
    });
  }, []);

  if (loading) return null;
  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>
        <div className={classes.paper}>
          <Typography className={classes.editprofile}>
            Report Details
          </Typography>
          <Box height="1.5rem"></Box>
          <Question
            question={questions[0]}
            answer={
              answers.length > 0
                ? answers.find((answer) => answer.question === questions[0].id)
                : null
            }
            answerValues={answerValues}
          />
          <Box height="2rem" />
          <Button
            variant="contained"
            className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
            color="primary"
            onClick={OnSibmit}
          >
            SUBMIT
          </Button>
        </div>
      </Fade>
    </Modal>
  );
}
