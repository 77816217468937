import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { isMuthoot } from "Routes/Routes";
import React from "react";

const useStyles = makeStyles((theme) => ({
  headingText: {
    color: isMuthoot ? 'black' : "#4F4F4F",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "36px",
    marginTop: "-30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  blueContainer: {
    "mix-blend-mode": "multiply",
    background:
      "radial-gradient(50% 284.81% at 50% 100%, rgba(253,253,253,1) 0%, rgba(255,255,255,1) 0.01%, rgba(69,188,255,0.25) 100%)",
    borderRadius: "12px",
  },
  greenContainer: {
    "mix-blend-mode": "multiply",
    background:
      "radial-gradient(50% 284.81% at 50% 100%, rgba(255,255,255,1) 0%, rgba(0,209,121,0.25) 100%)",
    borderRadius: "12px",
  },
  countText: {
    color: "#4F4F4F",
    // fontFamily: `'Satoshi-Regular',sans-serif`,
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  hover: {
    padding: "1rem",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.8",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
  },
}));

export const GradientBox = ({
  headingText,
  leftText,
  leftCount,
  rightText,
  rightCount,
  color,
  handleOnClickLeft,
  handleOnClickRight,
}) => {
  const classes = useStyles();

  return (
    <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
      <Hidden smUp>
        <Box height="2rem" />
      </Hidden>
      <Typography className={classes.headingText}>{headingText}</Typography>
      <Grid
        container
        className={
          color == "blue" ? classes.blueContainer : classes.greenContainer
        }
      >
        <Grid item xs={6} onClick={handleOnClickLeft} className={classes.hover}>
          <Typography className={classes.countText}>{leftText}</Typography>
          <Typography className={classes.countText}>{leftCount}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          onClick={handleOnClickRight}
          className={classes.hover}
        >
          <Typography className={classes.countText}>{rightText}</Typography>
          <Typography className={classes.countText}>{rightCount}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
