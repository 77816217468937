import {
  Box,
  Button,
  Collapse,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { noticeDetails } from "../MockData";

const TrackingRowData = ({ item, classes }) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [notice, setNotice] = useState();
  const [loading, setLoading] = useState(false);

  const toogleExpand = (e) => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded) {
      setLoading(true);
      setNotice(noticeDetails[item.notice_id]);
      setLoading(false);
    }
  }, [expanded]);

  const handleLoanClick = (e) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    history.push("/cred/loan/" + item.loan_id);
  };

  const handleViewBtnClick = (e) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
  };

  return (
    <>
      <Box className={`${expanded ? classes.selectedGrid : ""}`}>
        <Grid
          container
          justify="space-between"
          onClick={toogleExpand}
          className={classes.trackrowgrid}
          spacing={1}
        >
          <Grid item sm={1} xs={3}>
            <Typography
              className={classes.trackingboxblueUnderlinedtext}
              onClick={handleLoanClick}
            >
              {item.loan_id}
            </Typography>
          </Grid>
          <Grid item sm={1} xs={3}>
            <Typography className={classes.noticeboxblacktext}>
              {item.notice_id}
            </Typography>
            <Typography className={classes.trackingboxbluetext}>
              {item.bucket_name}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Typography className={classes.noticeboxblacktext}>
              {item.recipient_name}
            </Typography>
          </Grid>
          <Grid item sm={1} xs={2}>
            <Typography className={classes.noticeboxblacktext}>
              {item.notice_type}
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid item sm xs>
              <Typography className={classes.noticeboxblacktext}>
                Delivered
              </Typography>
              <Typography
                className={
                  item.sms.state == "Delivered"
                    ? classes.greenText
                    : classes.orangeText
                }
              >
                {item.sms.state}
              </Typography>
            </Grid>
            <Grid item sm xs>
              <Typography className={classes.noticeboxblacktext}>
                Delivered
              </Typography>
              <Typography
                className={
                  item.whatsapp.state == "Opened"
                    ? classes.greenText
                    : classes.orangeText
                }
              >
                {item.whatsapp.state}
              </Typography>
            </Grid>
            <Grid item sm xs>
              <Typography className={classes.noticeboxblacktext}>
                Delivered
              </Typography>
              <Typography
                className={
                  item.email.state == "Opened"
                    ? classes.greenText
                    : classes.orangeText
                }
              >
                {item.email.state}
              </Typography>
            </Grid>
            <Grid item sm xs>
              <Typography className={classes.noticeboxblacktext}>
                Delivered
              </Typography>
              <Typography
                className={
                  item.mail.state == "Delivered"
                    ? classes.greenText
                    : classes.orangeText
                }
              >
                {item.mail.state}
              </Typography>
            </Grid>
            <Grid item sm xs>
              <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
                <Button
                  variant="outlined"
                  className={classes.viewbtn}
                  onClick={handleViewBtnClick}
                >
                  <Typography className={classes.viewbtntext}>View</Typography>
                </Button>
              </a>
            </Grid>
          </Hidden>
        </Grid>
        <Collapse in={expanded}>
          {loading ? (
            <CircularCenteredLoader />
          ) : !notice ? (
            "Not found..."
          ) : (
            <Grid
              container
              justify="space-between"
              className={classes.trackExpandedgrid}
              spacing={1}
            >
              <Grid item sm={1} xs={3}></Grid>
              <Grid item sm={1} xs={3}></Grid>
              <Grid item sm={2} xs={4}>
                <Typography className={classes.expand_created_by_text}>
                  Created by
                </Typography>
                <Typography className={classes.expand_name_text}>
                  {notice.created_by.name}
                </Typography>
                <Typography className={classes.expand_green_text}>
                  {notice.created_by.date}
                </Typography>
                <Typography className={classes.expand_green_text}>
                  {notice.created_by.time}
                </Typography>
              </Grid>
              <Grid item sm={1} xs={2}></Grid>
              <Hidden xsDown>
                <Grid item sm xs>
                  {/* sms */}
                  <Typography className={classes.expand_created_by_text}>
                    {notice.sms.received_date ? (
                      notice.sms.received_date
                    ) : (
                      <br />
                    )}
                  </Typography>
                  <Typography className={classes.expand_sent_text}>
                    {notice.sms.state == "Delivered"
                      ? "SMS sent"
                      : "SMS not sent"}
                  </Typography>
                  <Typography className={classes.expand_green_text}>
                    {notice.sms.state == "Delivered"
                      ? `Delivered - ${notice.sms.delivery_time}`
                      : ``}
                  </Typography>
                </Grid>
                <Grid item sm xs>
                  {/* whatsapp */}
                  <Typography className={classes.expand_created_by_text}>
                    {notice.whatsapp.received_date ? (
                      notice.whatsapp.received_date
                    ) : (
                      <br />
                    )}
                  </Typography>
                  <Typography className={classes.expand_sent_text}>
                    {notice.whatsapp.state == "Delivered"
                      ? "Whatsapp sent"
                      : "Whatsapp not sent"}
                  </Typography>
                  <Typography className={classes.expand_green_text}>
                    {notice.whatsapp.delivery_time
                      ? `Delivered - ${notice.whatsapp.delivery_time}`
                      : ``}
                  </Typography>
                  <Typography className={classes.expand_green_text}>
                    {notice.whatsapp.read_time
                      ? `Read - ${notice.whatsapp.read_time}`
                      : ``}
                  </Typography>
                </Grid>
                <Grid item sm xs>
                  {/* email */}
                  <Typography className={classes.expand_created_by_text}>
                    {notice.email.received_date ? (
                      notice.email.received_date
                    ) : (
                      <br />
                    )}
                  </Typography>
                  <Typography className={classes.expand_sent_text}>
                    {notice.email.state == "Delivered"
                      ? "Email sent"
                      : "Email not sent"}
                  </Typography>
                  <Typography className={classes.expand_green_text}>
                    {notice.email.delivery_time
                      ? `Delivered - ${notice.email.delivery_time}`
                      : ``}
                  </Typography>
                  <Typography className={classes.expand_green_text}>
                    {notice.email.read_time
                      ? `Read - ${notice.email.read_time}`
                      : ``}
                  </Typography>
                </Grid>
                <Grid item sm xs>
                  {/* mail */}
                  <Typography className={classes.expand_created_by_text}>
                    {notice.mail.received_date ? (
                      notice.mail.received_date
                    ) : (
                      <br />
                    )}
                  </Typography>
                  <Typography className={classes.expand_sent_text}>
                    {notice.mail.state == "Delivered" ||
                    notice.mail.state == "In Transit"
                      ? "Mail sent"
                      : "Mail not sent"}
                  </Typography>
                  <Typography
                    className={
                      notice.mail.state == "In Transit"
                        ? classes.expand_orange_text
                        : classes.expand_green_text
                    }
                  >
                    {notice.mail.state == "In Transit"
                      ? "Transit Gateway"
                      : notice.mail.delivery_time
                      ? `Delivered - ${notice.mail.delivery_time}`
                      : ``}
                  </Typography>
                </Grid>
                <Grid item sm xs></Grid>
              </Hidden>
            </Grid>
          )}
        </Collapse>
      </Box>
      <Box height="1rem" />
    </>
  );
};

export default TrackingRowData;

/*

*/
