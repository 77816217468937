import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import CircleTickIcon from '../../images/tick-circle.svg';
import NsdlIcon from '../../images/Nsdl.svg';
import NseIcon from '../../images/Nse.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '3rem 4rem 0 3rem',
    [theme.breakpoints.only('md')]: {
      paddingTop: '1rem',
    },
	// height: '100vh',
	display: 'flex',
	flexDirection: 'column',
	// justifyContent: 'center',
	// alignItems: 'center'
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#E0E0E0',
    marginBottom: '0.4rem',
    fontFamily: 'Satoshi-Bold',
  },
  content: {
    color: '#E0E0E0',
    fontFamily: 'Satoshi-Regular',
  },
  space: {
    height: '2rem',
    [theme.breakpoints.only("lg")]: {
      height: '1.5rem',
    },
  },

  headingSebi: {
    fontSize: '3vw',
    color: '#000000',
    fontFamily: 'Satoshi-Bold',
    fontWeight: 700,
    marginTop: '30px',
  },
  readMore: {
    fontSize: '1vw',
    color: '#000000',
    fontFamily: 'Satoshi-Regular',
    fontWeight: 400,
    textDecoration: 'underline'
  },
  empanelledWithHeadline: {
    fontSize: '2vw',
    color: '#000000',
    fontFamily: 'Satoshi-Bold',
    fontWeight: 700,
    marginTop: '40px'
  },
  dot: {
    display: 'inline-block',
    margin: '0 0.5rem',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    cursor: 'pointer',
	  backgroundColor: '#000',
  },
  dotContainer: {
    textAlign: 'center',
    display:'flex',
    justifyContent:'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '50%',
    paddingBottom: '50px'
  },
}));

export default function GraphicsCarousel({currentScreen, setCurrentScreen}) {
  const classes = useStyles();

const graphics_data = [
	{
		title: 'Check your email for login details',
		content: 'Your case details have already been migrated to Webnyay for resolution',
	},
	{
		title: 'Conciliation process',
		content: 'Communication through video conferencing with the other party and a Conciliator over a period of 21 days',
	},
	{
		title: 'In-built hearing rooms',
		content: 'Access controlled, private, secure and seamless hearing rooms for easy resolution',
	},
	{
		title: 'Opt for Arbitration',
		content: `If conciliation doesn't lead to a resolution, the dispute can be advanced to Arbitration`,
	},
	{
		title: 'Arbitration process',
		content: 'The Arbitrator(s) will review the details and make a binding decision marking the end of the dispute resolution process',
	},
	{
		title: 'Contact your Case Manager for any queries',
		content: 'Feel free to reach out to your Webnyay-appointed Case Manager for any technical assistance'
	}
]

useEffect(() => {
    const timer = setTimeout(() => {
      // Switch to the next step after 5 seconds
      setCurrentScreen((prevStep) => (prevStep === 1 ? 2 : 1));
    }, 10000);

    // Cleanup the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [currentScreen]); // Re-run the effect when the currentStep changes


  const handleDotClick = (screenNumber) => {
      setCurrentScreen(screenNumber);
  };

  return (
    <>
      <Box className={classes.container} >
        {currentScreen === 2 && graphics_data?.map((data, index) => (
          <>
            <div className={classes.space} key={index + 20}></div>
            <Grid container item key={index}>
              <Grid item lg={1} md={1} sm={1} xs={1} style={{ textAlign: 'center' }}>
                <img src={CircleTickIcon} alt="TickIcon" style={{ height: '1.5rem', width: '1.5rem' }} />
              </Grid>
              <Grid item lg={11} md={11} sm={11} xs={11}>
                <Typography className={classes.title}>{data.title}</Typography>
                <Typography className={classes.content}>{data.content}</Typography>
              </Grid>
            </Grid>
          </>
        ))}
		{currentScreen === 1 &&
			<>
				<Grid container>
					<Typography className={classes.headingSebi}>Resolving disputes in the Securities Market with SEBI's new ODR regime</Typography>
					<Typography className={classes.readMore}>
						<a
							href="https://www.sebi.gov.in/legal/master-circulars/dec-2023/master-circular-for-online-resolution-of-disputes-in-the-indian-securities-market_80236.html"
							target="_blank"
							rel="noopener noreferrer"
						>
							Read more
						</a>
					</Typography>
				</Grid>
				<Grid>
					<Typography className={classes.empanelledWithHeadline}>Empanelled with</Typography>
					<Grid>
						<img width='40%' height='40%' src={NsdlIcon} alt="NSDL" />
						<img width='40%' height='40%' src={NseIcon} alt="NSE" />
					</Grid>
				</Grid>	
			</>
		}
        <div className={classes.dotContainer}>
          <div className={classes.dot} onClick={() => handleDotClick(1)}></div>
          <div className={classes.dot} onClick={() => handleDotClick(2)}></div>
        </div>
      </Box>
    </>
  );
}
