export const overviewData = {
  total: 150,
  p1: 23,
  p2: 78,
  p3: 49,
};

/*
sms - Delivered / Undelivered
whatsapp - Undelivered / Unread / Opened
email - Undelivered / Unread / Opened
mail - Undelivered / In Transit / Delivered
*/

export const trackingData = [
  {
    loan_id: "LD1245",
    notice_id: 77820,
    bucket_name: "Bucket 1",
    recipient_name: "Rakesh Suri",
    notice_type: "90 Days",
    sms: {
      state: "Delivered",
    },
    whatsapp: {
      state: "Opened",
    },
    email: {
      state: "Opened",
    },
    mail: {
      state: "Delivered",
    },
  },
  {
    loan_id: "LD1249",
    notice_id: 77821,
    bucket_name: "Bucket 2",
    recipient_name: "Amitabh Garg",
    notice_type: "30 Days",
    sms: {
      state: "Delivered",
    },
    whatsapp: {
      state: "Opened",
    },
    email: {
      state: "Unread",
    },
    mail: {
      state: "In Transit",
    },
  },
  {
    loan_id: "LD1212",
    notice_id: 77810,
    bucket_name: "Bucket 2",
    recipient_name: "Amitabh Garg",
    notice_type: "30 Days",
    sms: {
      state: "Undelivered",
    },
    whatsapp: {
      state: "Opened",
    },
    email: {
      state: "Opened",
    },
    mail: {
      state: "Delivered",
    },
  },
  {
    loan_id: "LD1222",
    notice_id: 37810,
    bucket_name: "Bucket 3",
    recipient_name: "Rohit Garg",
    notice_type: "60 Days",
    sms: {
      state: "Delivered",
    },
    whatsapp: {
      state: "Undelivered",
    },
    email: {
      state: "Opened",
    },
    mail: {
      state: "Delivered",
    },
  },
];

export const noticeDetails = {
  77820: {
    created_by: {
      name: "Nikhil Vig",
      date: "20/08/2021",
      time: "9:56 AM",
    },
    sms: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
    },
    whatsapp: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
      read_time: "11:17 PM",
    },
    email: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
      read_time: "11:17 PM",
    },
    mail: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
    },
  },
  77821: {
    created_by: {
      name: "Nikhil Vig",
      date: "20/08/2021",
      time: "9:56 AM",
    },
    sms: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
    },
    whatsapp: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
      read_time: "11:17 PM",
    },
    email: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
      // read_time: "11:17 PM",
    },
    mail: {
      state: "In Transit",
      // received_date: "21/08/2021",
      // delivery_time: "9:58 PM",
    },
  },
  77810: {
    created_by: {
      name: "Nikhil Vig",
      date: "20/08/2021",
      time: "9:56 AM",
    },
    sms: {
      state: "Undelivered",
      // received_date: "21/08/2021",
      // delivery_time: "9:58 PM",
    },
    whatsapp: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
      read_time: "11:17 PM",
    },
    email: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
      read_time: "11:17 PM",
    },
    mail: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
    },
  },
  37810: {
    created_by: {
      name: "Nikhil Vig",
      date: "20/08/2021",
      time: "9:56 AM",
    },
    sms: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
    },
    whatsapp: {
      state: "Undelivered",
      // received_date: "21/08/2021",
      // delivery_time: "9:58 PM",
      // read_time: "11:17 PM",
    },
    email: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
      read_time: "11:17 PM",
    },
    mail: {
      state: "Delivered",
      received_date: "21/08/2021",
      delivery_time: "9:58 PM",
    },
  },
};
