import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { isHotStar, isMuthoot, isStar } from "Routes/Routes";
import FormattedMessage from "features/common/TranslatedMessage";
import refreshPng from "images/refresh.png";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  primaryText: {
    fontSize: "18px",
    lineHeight: "31px",
    padding: " 0 330px 0 350px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "25px",
      padding: " 18px 30px 32px 32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "25px",
      padding: "  0 100px 0 150px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "22px",
      padding: "18px 32px 32px 36px",
    },
  },
}));

export default function Fallback() {
  const history = useHistory();
  console.log("🚀 ~ Fallback ~ history:", history.entries)


  const classes = useStyles();
  return (
    <Box height="80vh" textAlign="center">
      <Box height="20vh" />
      {!isHotStar && !isStar && !isMuthoot && (
        <img
          src={refreshPng}
          alt="Refresh"
          onClick={() => {
            history.goBack();
            console.log('on clicking refresh')
          }}
        />
      )}
      <Box height="2rem" />
      {(isHotStar || isStar || isMuthoot) && (
        <>
          <Typography
            variant="h4"
            color="primary"
            className={classes.primaryText}
          >
            {isMuthoot ? (
              <FormattedMessage id="pages.fallback.headingMuthoot" />
            ) : (
              <FormattedMessage id="pages.fallback.heading0" />
            )}
          </Typography>
          <Box height="1rem" />
          <Button
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              history.goBack();
            }}
          >
            <FormattedMessage id="buttons.back" />
          </Button>
        </>
      )}
    </Box>
  );
}
