import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import instance from "api/globalaxios";
import bluepenSvg from "images/bluepen.svg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Assign from "./Assign";
import CreateLoginDetails from "./CreateLoginDetails";
import EditProfile from "./EditProfile";
import { deAssignProfile } from "./GRO";
import { joinHearingRooms } from "Admin/RVTiac/Assign";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "35px",
    textAlign: "center",
    borderRadius: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // width: "425px",
    // height: "360px",
    wordBreak: "break-all",
    // [theme.breakpoints.down("sm")]: {
    //   width: "82%",

    // },
  },
  headingblue: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  bluetext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    textDecorationLine: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  text: {
    // fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
}));

export default function SRB({
  groData: initialGroData,
  caseId,
  companyId,
  mode,
  casefiles,
  lawyerData,
  refresh,
  type,
  allProfiles,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [groData, setGroData] = useState([]);

  React.useEffect(() => {
    if (type) {
      let res = [];
      initialGroData.map((item) => {
        if (type == "CLAIMANT") {
          if (lawyerData.includes(item.profile.id)) {
            res.push(item);
          }
        } else {
          if (lawyerData.includes(item.profile.id)) {
            res.push(item);
          }
        }
      });
      setGroData(res);
    } else {
      setGroData(initialGroData);
    }
  }, [type, initialGroData]);

  const [openmodal, setOpenmodal] = useState(false);

  const handleOpenModal = () => {
    setOpenmodal(true);
  };

  const handleCloseModal = () => {
    setOpenmodal(false);
    refresh();
  };

  const [openmodal2, setOpenmodal2] = useState(false);

  const handleOpenModal2 = () => {
    setOpenmodal2(true);
  };

  const handleCloseModal2 = () => {
    setOpenmodal2(false);
  };

  const [openmodal3, setOpenmodal3] = useState(false);

  const handleOpenModal3 = () => {
    setOpenmodal3(true);
  };

  const handleCloseModal3 = () => {
    setOpenmodal3(false);
  };

  const remove = (removethisprofile) => {
    if (type) {
      casefiles.forEach((item) => {
        if (type == "CLAIMANT") {
          if (item.type == "claimant") {
            deAssignProfile(removethisprofile.profile.id, caseId, item.id).then(
              () => {
                refresh();
                dispatch(joinHearingRooms(caseId));
              }
            );
          }
        } else {
          if (item.type == "respondent") {
            deAssignProfile(removethisprofile.profile.id, caseId, item.id).then(
              () => {
                refresh();
                dispatch(joinHearingRooms(caseId));
              }
            );
          }
        }
      });
    } else {
      deAssignProfile(removethisprofile.profile.id, caseId).then(() => {
        refresh();
        dispatch(joinHearingRooms(caseId));
      });
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.headingblue}>
            {type ? `${type} LAWYER(S)` : `LAWYER(S)`}
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={bluepenSvg}
            alt="pen image"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              if (groData.length > 0) handleOpenModal3();
              else alert("There are no profiles to edit");
            }}
          />
        </Grid>
      </Grid>
      <Box height="1rem"></Box>
      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            Assign
          </Typography>
        </Grid>
      </Grid>
      {groData.length > 0 ? (
        groData.map((item) => {
          if (
            item.profile.profile_type == "lawyer" ||
            item.profile.profile_type == "lawyer_rv"
          )
            return (
              <React.Fragment key={item.user.id}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      className={classes.blackheading}
                      style={{ textAlign: "left" }}
                    >
                      {item.user.first_name} {item.user.last_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.bluetext}
                      style={{ cursor: "pointer", lineHeight: "20px" }}
                      onClick={() => {
                        remove(item);
                      }}
                    >
                      Remove
                    </Typography>
                  </Grid>
                </Grid>

                <Typography
                  className={classes.text}
                  style={{ textAlign: "left" }}
                >
                  Email: {item.user.email}
                </Typography>

                <Typography
                  className={classes.text}
                  style={{ textAlign: "left" }}
                >
                  Phone: {item.profile.phone_number}{" "}
                </Typography>

                <Typography
                  className={classes.text}
                  style={{ textAlign: "left" }}
                >
                  Address: {item.profile.address ? item.profile.address : "--"}
                </Typography>

                <Box height="1rem"></Box>
              </React.Fragment>
            );
        })
      ) : (
        <>
          <Box height="9.3rem"></Box>
        </>
      )}
      {openmodal && (
        <Assign
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          caseId={caseId}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
          groData={groData}
          casefiles={casefiles}
          caseType_RV={type == "CLAIMANT" ? "Claimant" : "Respondent"}
          allProfiles={allProfiles}
          mode={mode}
        />
      )}

      <Grid container style={{ marginTop: "auto" }}>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal2}
          >
            + Create login details
          </Typography>
        </Grid>
      </Grid>
      {openmodal2 && (
        <CreateLoginDetails
          openmodal={openmodal2}
          handleCloseModal={handleCloseModal2}
          caseId={caseId}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
          companyId={companyId}
          mode={mode}
        />
      )}
      {openmodal3 && (
        <EditProfile
          openmodal={openmodal3}
          handleCloseModal={handleCloseModal3}
          profileData={groData}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
        />
      )}
    </Paper>
  );
}
