import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    flexShrink: "0",
    [theme.breakpoints.down("sm")]: { padding: "1rem 0" },
  },
  footer_text: {
    [theme.breakpoints.down("sm")]: { fontSize: "0.8rem" },
  },
  footer_logo: { [theme.breakpoints.down("sm")]: { fontSize: "1.5rem" } },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  powered: {
    fontSize: "12px",
  },
  typo: {
    margin: "3rem 0 0 0",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
}));

// const links = {
//   "Terms of use": "files/termsAndConditions.pdf",
//   Privacy: "files/wenyayPrivacyPolicy.pdf",
//   "Contact Us": "/contact",
// };

const links = [
  {
    title: <FormattedMessage id="footer.terms" />,
    value: "/files/termsAndConditions.pdf",
  },
  {
    title: <FormattedMessage id="footer.privacy" />,
    value: "/files/wenyayPrivacyPolicy.pdf",
  },
  // {
  //   title: <FormattedMessage id="footer.contact" />,
  //   value: "/contact",
  // },
];

const renderLinks = (links, classes) => {
  // const linksArray = Object.keys(links);
  return links.map((link, index) => {
    let newTab = index < 2 ? true : false;
    return (
      <Grid key={index} item xs={4} sm={4} md={2}>
        <Link
          className={classes.link}
          to={link.value}
          target={newTab ? "_blank" : "_self"}
        >
          <Typography variant="subtitle1" className={classes.footer_text}>
            {link.title}
          </Typography>
        </Link>
      </Grid>
    );
  });
};

export default function HotstarFooter() {
  const token = useSelector((state) => state.login.token);
  const classes = useStyles();
  const location = useLocation();
  const pathNameArray = location.pathname.split("/");
  const isChat = pathNameArray[pathNameArray.length - 1] === "chat";
  return (
    <Fragment>
      {!isChat && (
        <Box
          bgcolor="#D9D7D7"
          textAlign="center"
          p="2rem 0"
          className={classes.footer}
        >
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.powered}>
                    Powered by
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to='/'>

                  <img
                    src="/Logo_Transparent.png"
                    alt=""
                    style={{ width: "15rem", height: "4.6rem" }}
                    />
                    </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Typography className={classes.typo}>
                Please refer to Disney+ Hotstar's{" "}
                <a
                  href="https://www.hotstar.com/in/terms-of-use"
                  target="_blank"
                  style={{ color: "#454545" }}
                >
                  <strong style={{ textDecoration: "underline" }}>
                    Terms Of Use
                  </strong>
                </a>{" "}
                and{" "}
                <a
                  href="https://www.hotstar.com/in/privacy-policy"
                  target="_blank"
                  style={{ color: "#454545" }}
                >
                  <strong style={{ textDecoration: "underline" }}>
                    Privacy Policy
                  </strong>
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}
