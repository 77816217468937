import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { blue, deepPurple, green, purple, red } from "@material-ui/core/colors";
import React from "react";
import AreaChart from "./AreaChart";
import PieChart from "./PieChart";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "2rem",
    lineHeight: "3rem",
  },
  barGraphContainer: {
    width: "60%",
    marginTop: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pieContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rangeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  formControl: {
    minWidth: "10rem",
  },
  colorsValuesContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

const colors = [blue[500], purple[500], deepPurple[500], red[500], green[500]];

export default function Analytics({
  disputeStatusData,
  disputeModeData,
  disputeCountBarData,
  handleChange: handleRangeChange,
  range,
}) {
  const classes = useStyles();

  const [graph, setGraph] = React.useState(0);

  const handleChange = (event) => {
    setGraph(event.target.value);
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="5rem 2rem"
        textAlign="center"
      >
        <div className={classes.rangeContainer}>
          <FormControl className={classes.formControl}>
            {/* <InputLabel id="demo-simple-select-label">Select Graph</InputLabel> */}
            <Select
              variant="outlined"
              labelId="range-select-label"
              id="analytics-range-select"
              value={range}
              onChange={handleRangeChange}
            >
              <MenuItem value="week">Past week</MenuItem>
              <MenuItem value="month">Past Month</MenuItem>
              <MenuItem value="quarter">Past 3 Months</MenuItem>
              <MenuItem value="half">Past 6 Months</MenuItem>
              <MenuItem value="all">All time</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Box height="4rem"></Box>
        <Grid container justify="space-evenly" spacing={6}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography className={classes.heading}>
              Status of all the disputes
            </Typography>
            <Grid container justify="center">
              <Grid item md={8} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <PieChart data={disputeStatusData} colors={colors} />
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className={classes.colorsValuesContainer}>
                  <ColorValues data={disputeStatusData} colors={colors} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography className={classes.heading}>
              Mode for resolving disputes
            </Typography>
            <Grid container justify="center">
              <Grid item md={8} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <PieChart data={disputeModeData} colors={colors} />
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className={classes.colorsValuesContainer}>
                  <ColorValues data={disputeModeData} colors={colors} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <div style={{ width: "10rem" }}>
          <FormControl className={classes.formControl} fullWidth>
            <Select
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={graph}
              onChange={handleChange}
            >
              <MenuItem value={0}>Bar Graph</MenuItem>
              <MenuItem value={1}>Area Chart</MenuItem>
            </Select>
          </FormControl>
        </div> */}

        <div className={classes.barGraphContainer}>
          <Typography className={classes.heading}>Dispute count</Typography>
          <Box height="2rem"></Box>

          <AreaChart data={disputeCountBarData} />
        </div>
      </Box>
    </div>
  );
}

const ColorValues = ({ colors, data }) => {
  return (
    <div>
      {data.map((singleData, index) => {
        return (
          <Box display="flex" mb="0.7rem" key={singleData.name}>
            <Box
              height="2rem"
              width="2rem"
              style={{ backgroundColor: colors[index] }}
            ></Box>
            <Box width="0.3rem"></Box>
            <Typography>{singleData.name}</Typography>
          </Box>
        );
      })}
    </div>
  );
};
