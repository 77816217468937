import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bigtext: {
    color: "#454545",
    fontSize: "26px",
    fontWeight: "600",
    fontFamily: `'Inter',sans-serif`,
  },
  boldText: {
    color: "#454545",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: `'Inter',sans-serif`,
  },
  text: {
    color: "#454545",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
  },
  redlink: {
    color: "rgb(103, 11, 23)",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: `'Inter',sans-serif`,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));

const AfterSetPasswordScreen = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box mt="2rem">
      <Grid container justify="center">
        <Grid item md={8} sm={10} xs={11}>
          <Typography className={classes.bigtext}>
            Thank you. Your response has been saved.
          </Typography>
          <Typography className={classes.boldText} display="inline">
            Do not use your browser&apos;s back button.{" "}
          </Typography>
          <Typography className={classes.text} display="inline">
            If you need to return to the form, please use one of the links
            provided below.
          </Typography>
          <ul>
            <li>
              <Typography
                className={classes.redlink}
                onClick={() => {
                  history.push(
                    `resumerfa?email=${localStorage.getItem(
                      "email"
                    )}&dispute_id=${localStorage.getItem(
                      "dispute_id"
                    )}&find_active_step=true`
                  );
                }}
              >
                Resume this form now
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.redlink}
                onClick={() => {
                  history.push('/RFA')
                }}
              >
                Start a new form
              </Typography>
            </li>
          </ul>
          <Typography className={classes.text} display="inline">
            You can resume this form at any time.{" "}
          </Typography>
          <Typography className={classes.boldText} display="inline">
            Make sure you can remember the email and password you just provided.{" "}
          </Typography>
          <Typography className={classes.text} display="inline">
            You will need them to resume your form.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AfterSetPasswordScreen;
