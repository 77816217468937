import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link"; 
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    // backgroundColor: "#f5f5f5",
  },
  title: { 
    fontSize: "2rem", 
    marginBottom: theme.spacing(2) 
  },
  description: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  table: 
  { 
    width: "100%", 
    marginBottom: theme.spacing(4) 
  },
  tableHeader: { 
    backgroundColor: "#cfcfcf" 
  },
  tableRow: { 
    // "&:nth-of-type(even)": { 
    //     backgroundColor: "#f9f9f9" 
    // } 
    backgroundColor: 'white',
  },
  tableCell: { 
    padding: theme.spacing(2), 
    borderBottom: "1px solid #ccc" 
  },
  circularNumberLink: {
    color: "#007bff",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: 'underline !Important',
    }
  },
}));

const MyComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const tableData = [
    {
      id: '001',
      circularNumber: "RBI/DBR/2015-16/18",
      dateOfIssue: "May 29, 2019",
      subject: "Master Direction - Amendent to Know Your Customer (KYC) Direction, 2016",
      status: "46.2%",
    },
    {
      id: '002',
      circularNumber: "RBI/CEPD/2023-24/108",
      dateOfIssue: "December 29, 2023",
      subject: "Master Direction - Reserve Bank of India (Internal Ombudsman for Regulated Entities) Directions, 2023",
      status: "Not-complied",
    },
    {
      id: '003',
      circularNumber: "RBI/DBS/2016-17/28",
      dateOfIssue: "July 03, 2017",
      subject: "Master Directions on Frauds – Classification and Reporting by commercial banks and select FIs",
      status: "Complied",
    },
  ];

  return (
    <div className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        COMPLIANCE MANAGEMENT TOOL
      </Typography>
      <TableContainer  className={classes.table}>
        <Table style={{border:'1px solid #e5e5e5',maxWidth:'100%'}}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>
                Circular/Direction
              </TableCell>
              <TableCell className={classes.tableHeader}>
                Date Of Issue
              </TableCell>
              <TableCell className={classes.tableHeader}>
                Subject
              </TableCell>
              <TableCell className={classes.tableHeader}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <Link onClick={()=>{ history.push(`/paytm/compliance/${row.id}`)}} className={classes.circularNumberLink}>
                    {row.circularNumber}
                  </Link>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.dateOfIssue}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.subject}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default MyComponent;