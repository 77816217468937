import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
	gap:'1.5rem',
	width:'auto',
  },
  selected: {
    paddingBottom: "0.2rem",
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  unselected: {
    fontSize: '1.2rem',
    paddingBottom: "0.2rem",
    cursor: "pointer",
		fontFamily: 'Satoshi-Medium',
  },
}));

export default function Tabs({
  tabs,
	handleClickTab,
	choice,
	tabsStyles,
	tabsClasses,
	tabsContainerClass,
}) {
  const classes = useStyles();
  const tabsCSS = tabsClasses ? tabsClasses : classes;

  return (
    <>
      <Grid container className={tabsContainerClass ? tabsContainerClass: classes.container} justifyContent='center'>
				{
					tabs && (
						tabs.map((tab,index)=>{
							return (
								<Grid
									key={index}
									item
									className={
										choice === tab.id ? tabsCSS.selected : tabsCSS.unselected
									}
									style={tabsStyles ? tabsStyles : null}
									onClick={()=>handleClickTab(tab.id)}
								>
									{tab.label}
								</Grid>
							)
						})
					)
				}
			</Grid>
    </>
  );
}
