import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

const ZoomEnd = () => {
  const history = useHistory();
  let { disputeId } = useParams();
useEffect(() => {
    history.push(`/disputes/${disputeId}/chat`);
}, [disputeId,history])
  return <div>ZoomEnd</div>;
};

export default ZoomEnd;
