import React, { useState } from "react";
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import instance from "api/globalaxios";
import bluepenSvg from "images/bluepen.svg";
import Assign from "./Assign";
import CreateLoginDetails from "./CreateLoginDetails";
import EditProfile from "./EditProfile";
import { deAssignProfile } from "Admin/SuperAdmin/Overview/SubAdminOverview/GRO";
import { joinHearingRooms } from "Admin/RVTiac/Assign";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "35px",
    textAlign: "center",
    borderRadius: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // width: "425px",
    // height: "360px",
    wordBreak: "break-all",
  },
  headingblue: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  bluetext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    textDecorationLine: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  text: {
    // fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
}));

export default function CaseManager({
  casemanagerData: initialcasemanagerData,
  caseId,
  companyId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [casemanagerData, setcasemanagerData] = useState(
    initialcasemanagerData
  );

  const refresh = () => {
    dispatch(disputeDetails());
  };

  function disputeDetails() {
    return async () => {
      try {
        const response = await instance.get(
          `/api/disputedetailsbyid/${caseId}/`
        );
        let d = response.data;
        setcasemanagerData(d.dispute_data.case_manager_rv);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const [openmodal, setOpenmodal] = useState(false);

  const handleOpenModal = () => {
    setOpenmodal(true);
  };

  const handleCloseModal = () => {
    setOpenmodal(false);
    refresh();
  };

  const [openmodal2, setOpenmodal2] = useState(false);

  const handleOpenModal2 = () => {
    setOpenmodal2(true);
  };

  const handleCloseModal2 = () => {
    setOpenmodal2(false);
  };

  const [openmodal3, setOpenmodal3] = useState(false);

  const handleOpenModal3 = () => {
    setOpenmodal3(true);
  };

  const handleCloseModal3 = () => {
    setOpenmodal3(false);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.headingblue}>
            CASE MANAGER(S)
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={bluepenSvg}
            alt="pen image"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              if (casemanagerData > 0) handleOpenModal3();
              else alert("There are no profiles to edit");
            }}
          />
        </Grid>
      </Grid>
      <Box height="1rem"></Box>
      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            Assign
          </Typography>
        </Grid>
      </Grid>
      {casemanagerData.length > 0 ? (
        casemanagerData.map((item) => {
          return (
            <>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    className={classes.blackheading}
                    style={{ textAlign: "left" }}
                  >
                    {item.user.first_name} {item.user.last_name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.bluetext}
                    style={{ cursor: "pointer", lineHeight: "20px" }}
                    onClick={() => {
                      deAssignProfile(item.profile.id, caseId).then(() => {
                        refresh();
                        dispatch(joinHearingRooms(caseId));
                      });
                    }}
                  >
                    Remove
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                className={classes.text}
                style={{ textAlign: "left" }}
              >
                Email: {item.user.email}
              </Typography>
              <Typography
                className={classes.text}
                style={{ textAlign: "left" }}
              >
                Phone: {item.profile.phone_number}{" "}
              </Typography>
              <Typography
                className={classes.text}
                style={{ textAlign: "left" }}
              >
                Address: {item.profile.address ? item.profile.address : "--"}
              </Typography>
              <Box height="1rem"></Box>
            </>
          );
        })
      ) : (
        <>
          <Box height="9.3rem"></Box>
        </>
      )}
      <Grid container style={{ marginTop: "auto" }}>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal2}
          >
            + Create login details
          </Typography>
        </Grid>
      </Grid>

      {openmodal && (
        <Assign
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          caseId={caseId}
          profileType={"case_manager"}
          groData={casemanagerData}
        />
      )}
      {openmodal2 && (
        <CreateLoginDetails
          openmodal={openmodal2}
          handleCloseModal={handleCloseModal2}
          caseId={caseId}
          profileType={"case_manager"}
          companyId={companyId}
        />
      )}
      {openmodal3 && (
        <EditProfile
          openmodal={openmodal3}
          handleCloseModal={handleCloseModal3}
          profileData={casemanagerData}
          profileType={"case_manager"}
        />
      )}
    </Paper>
  );
}
