import { Grid, Typography, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { SECRETARIAT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import { DocGenerator } from "utils";
import DownloadSvg from "images/download.svg";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  isCDR,
  isHotStar,
  isRv,
  isDigipub,
  isWebnyay,
  isDev,
  isNSE,
  isVikalp,
  isStar,
  isMuthoot,
} from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "3.6rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.8rem",
    },
  },
  value: {
    // fontSize: 14,
    border: "none",
    padding: "1rem",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
}));

export default function DisputeRow({ dispute, profileType, profile }) {
  const classes = useStyles();
  const history = useHistory();

  const download = (e) => {
    e.stopPropagation();
    DocGenerator(dispute.id, "claimant");
  };

  let data;
  if (isHotStar || isStar || isMuthoot) {
    if (profileType == "arbitrator") {
      data = [
        {
          title: <FormattedMessage id="dispute.disputeId" />,
          value: dispute.approved ? dispute.id : "-----",
          // value:dispute.id,
        },
        {
          title: <FormattedMessage id="dispute.title" />,
          value: dispute.name,
        },

        {
          title: <FormattedMessage id="dispute.hotstarsearchDate" />,
          value: formatDate(dispute.created),
        },
        {
          title: <FormattedMessage id="dispute.registrationDate" />,
          value: dispute.approved
            ? formatDate(dispute.approved_date)
            : dispute.forward
            ? "forwarded"
            : "-------",
        },
      ];
    } else {
      data = [
        {
          title: <FormattedMessage id="dispute.disputeId" />,
          // value: dispute.id,
          value: dispute.approved ? dispute.id : "-----",
        },
        {
          title: <FormattedMessage id="dispute.title" />,
          value: dispute.name,
        },
        {
          title: <FormattedMessage id="dispute.hotstarsearchDate" />,
          value: formatDate(dispute.created),
        },
        {
          title: <FormattedMessage id="dispute.registrationDate" />,
          value: dispute.approved
            ? formatDate(dispute.approved_date)
            : dispute.forward
            ? "forwarded"
            : "-------",
        },

        ,
      ];
    }
  } else if (isDigipub) {
    data = [
      {
        title: <FormattedMessage id="dispute.GrievanceId" />,
        value: dispute.id,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
        value: dispute.name,
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
        value: formatDate(dispute.created),
      },
      {
        title: <FormattedMessage id="dispute.viewGrievance" />,
      },
    ];
  } else {
    data = [
      {
        title: <FormattedMessage id="dispute.disputeId" />,
        value: dispute.id,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
        value: dispute.name,
      },
      {
        title: <FormattedMessage id="dispute.mode" />,
        value:
          (dispute.company_product == 523 && isWebnyay) ||
          (dispute.company_product == 613 && isDev)
            ? "Grievance"
            : dispute.flow_type,
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
        value: formatDate(dispute.created),
      },
    ];
  }

  const onRowClick = () => {
    if (isVikalp && profile.profile_type == "general" && dispute.status == "claimant_complete") {
      sessionStorage.setItem("disputeId", dispute.id);
      sessionStorage.setItem("email", profile.user.email);
      history.push("/dispute");
    } else if (profileType === SECRETARIAT) {
      history.push(`/disputes/${dispute.id}/chat`);
    } else {
      history.push(`/disputes/${dispute.id}`);
    }
  };

  return (
    <React.Fragment>
      {isRv ? (
        <Paper className={classes.paper} onClick={onRowClick}>
          <Grid container justify="flex-start">
            <Grid item sm={4} xs={4}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[0].value ? data[0].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[1].value ? data[1].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[3].value ? data[3].value : "----"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ) : isDigipub ? (
        <Paper className={classes.paper} onClick={onRowClick}>
          <Grid container justify="flex-start">
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[0].value ? data[0].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[1].value ? data[1].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[2].value ? data[2].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value} onClick={download}>
                  <FormattedMessage id="dispute.viewGrievance" />
                  &nbsp;&nbsp;&nbsp;
                  <Tooltip
                    title={
                      isCDR || isVikalp
                        ? "Statement Of Claim"
                        : "View Grievance"
                    }
                    placement="top"
                  >
                    <img src={DownloadSvg} onClick={download} />
                  </Tooltip>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper className={classes.paper} onClick={onRowClick}>
          <Grid container justify="flex-start">
            <Grid item sm={2} xs={2}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[0].value ? data[0].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box
                className={classes.contentContainer}
                style={{ overflowWrap: "anywhere" }}
              >
                <Typography className={classes.value}>
                  {data[1].value ? data[1].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2} xs={2}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[2].value ? data[2].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[3].value ? data[3].value : "----"}
                </Typography>
              </Box>
            </Grid>
            {(profileType == "vendor" ||
              profileType == "arbitrator" ||
              profileType == "general" ||
              profileType == "mediator" ||
              profileType == "lawyer") && (
              <Grid item sm={2} xs={2}>
                <Box className={classes.contentContainer}>
                  <Typography className={classes.value} onClick={download}>
                    {isCDR || isVikalp ? (
                      <FormattedMessage id="dispute.viewStatementOfClaim" />
                    ) : isNSE ? (
                      <FormattedMessage id="dispute.viewCase" />
                    ) : (
                      <FormattedMessage id="dispute.viewGrievance" />
                    )}
                    {/* &nbsp; */}
                    <Tooltip
                      title={
                        isCDR || isVikalp
                          ? "Statement Of Claim"
                          : isNSE
                          ? "View Case"
                          : "View Grievance"
                      }
                      placement="top"
                    >
                      <img src={DownloadSvg} onClick={download} />
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}

      <Box height="1rem"></Box>
    </React.Fragment>
  );
}
