import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { downloadInvoice } from "Admin/Common/InvoiceList";
import { format } from "date-fns";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FlashButton from "features/common/FlashButton/FlashButton";
import { DownloadFile } from "features/filesRV/FileApi";
import Tabs from "features/Tabs/Tabs";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isDifc, isMSME, isSebi } from "Routes/Routes";
import {
  getActivitiesByTab,
  getActivityByTabAndId,
} from "slices/activitySlice";
import { getAllMeetingsWithDisputeName } from "slices/appointmentSlice";
import ActivityRow from "./ActivityRow";

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "left",
    margin: "1rem 0 0 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.5rem",
    },
  },
  overview: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "400",
    color: "#252733",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    padding: "1rem 2rem",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
    "&:hover": {
      background: "#ebebeb",
    },
  },
  selectedPaper: {
    padding: "1rem 2rem",
    textAlign: "center",
    borderRadius: "8px",
    background: "#BDBDBD",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
  },
  headingPaper: {
    fontWeight: "500",
    fontSize: "20px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontize: "15px",
    },
  },
  numberPaper: {
    fontWeight: "500",
    fontSize: "40px",
    color: "#2C2C2C",
    [theme.breakpoints.down("sm")]: {
      fontize: "30px",
    },
  },
  activityText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
  },
  activityPaper: {
    border: `1px solid ${theme.palette.border}`,
    padding: "1.5rem",
    textAlign: "left",
  },
  activityUsername: {
    fontSize: "1.25rem",
    fontFamily: `Satoshi-Bold`,
    color: "black",
  },
  activityheading: {
    fontSize: "1.25rem",
    color: "#5A5A5A",
  },
  activityTime: {
    color: "#5A5A5A",
    lineHeight: "1.5rem",
  },
  activityfileFolderName: {
    fontSize: "1.25rem",
    color: "black",
    lineHeight: "1.5rem",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  disputeHeading: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#333333",
  },
  disputeText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#333333",
    wordBreak: "break-word",
  },
  sortbybtn: {
    cursor: "pointer",
    border: "none",
    background: "#E0E0E0",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    borderRadius: "4px",
  },
  sortbytext: {
    padding: "10px 30px 10px 10px",
    fontSize: "12px",
    lineHeight: "0px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#454545",
  },
  tabindividual: {
    display: "flex",
    width: "33%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
  },
  tabsContainer: {
    gap: "1rem",
  },
  selected: {
    width: "9rem",
    textAlign: "center",
    padding: "0.8rem 2rem",
    cursor: "pointer",
    fontSize: "0.9rem",
    fontFamily: "Satoshi-Bold",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  unselected: {
    width: "9rem",
    textAlign: "center",
    fontSize: "0.9rem",
    padding: "0.8rem 2rem",
    cursor: "pointer",
    fontFamily: "Satoshi-Bold",
    color: theme.palette.primary.main,
    backgroundColor: "white",
  },
}));

const GeneralSebiActivities = ({
  activities,
  setActivities,
  isGetActivityByTabAndId = false,
  disputeId,
  activityTitle,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const [currentTab, setCurrentTab] = useState("general");
  const [pagination, setPagination] = useState({
    page: 1,
    count: 0,
    next: null,
    previous: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [activityLoader, setActivityLoader] = useState(false);
  const [currentAppointment, setCurrentAppointment] = React.useState();

  const findCurrentAppointment = () => {
    dispatch(getAllMeetingsWithDisputeName()).then((res) => {
      // Get the current date and time
      const now = new Date();

      // Calculate the date and time 10 minutes from now
      const tenMinutesFromNow = new Date(now.getTime() + 10 * 60000);

      // Find the appointment whose meeting is either ongoing or starting in the next 10 minutes
      let upcomingAppointment = null;

      for (const appointment of res.appointments) {
        const meetingStart = new Date(appointment.meeting.start);
        const meetingEnd = new Date(appointment.meeting.end);

        if (disputeId) {
          if (
            disputeId === appointment.meeting.dispute.id &&
            ((meetingStart <= now && now <= meetingEnd) ||
              (now < meetingStart && meetingStart <= tenMinutesFromNow))
          ) {
            upcomingAppointment = appointment;
            break;
          }
        } else {
          if (
            (meetingStart <= now && now <= meetingEnd) ||
            (now < meetingStart && meetingStart <= tenMinutesFromNow)
          ) {
            upcomingAppointment = appointment;
            break;
          }
        }
      }

      if (upcomingAppointment) {
        setCurrentAppointment(upcomingAppointment);
      }
    });
  };

  const handleTabChange = (newValue) => {
    setCurrentTab(newValue);
    setCurrentPage(1);
    setPagination({
      page: 1,
      count: activities?.count,
      next: activities?.next,
      previous: activities?.previous,
    });
  };

  const handlePagination = (url) => {
    if (url) {
      const urlParams = new URLSearchParams(url);
      const nextPage = parseInt(urlParams.get("page"));
      if (isNaN(nextPage)) {
        setCurrentPage(1);
        fetchCurrentTabData(currentTab, 1);
      }
      if (!isNaN(nextPage) && nextPage > 0) {
        setCurrentPage(nextPage);
        fetchCurrentTabData(currentTab, nextPage);
      }
    }
  };

  const handleNextPage = () => {
    handlePagination(pagination.next);
  };

  const handlePreviousPage = () => {
    handlePagination(pagination.previous);
  };

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const activityCategories = [
    {
      id: "general",
      label: "General",
    },
    {
      id: "files",
      label: "Files",
    },
    {
      id: "payment",
      label: "Payment",
    },
  ];

  const activityCategoriesDifc = [
    {
      id: "general",
      label: "General",
    },
    {
      id: "files",
      label: "Files",
    },
  ];

  const fetchCurrentTabData = async (currentTab, pageNumber) => {
    setActivityLoader(true);
    try {
      const response = isGetActivityByTabAndId
        ? await dispatch(
            getActivityByTabAndId(currentTab, pageNumber, disputeId)
          )
        : await dispatch(getActivitiesByTab(currentTab, pageNumber));
      setActivities(response);
      setPagination({
        page: response ? pagination.page + 1 : pagination.page,
        count: response?.count,
        next: response?.next,
        previous: response?.previous,
      });
      setActivityLoader(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (currentTab) {
      setActivityLoader(true);
      dispatch(
        isGetActivityByTabAndId
          ? getActivityByTabAndId(currentTab, pagination.page, disputeId)
          : getActivitiesByTab(currentTab, pagination.page)
      )
        .then((res) => {
          setActivities(res);
          setPagination({
            page: 1,
            count: res?.count,
            next: res?.next,
            previous: res?.previous,
          });
          setActivityLoader(false);
          if (currentTab === "general") findCurrentAppointment();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentTab]);

  const handleClickOnFileFolderName = (
    isFolder,
    fileId,
    folderId,
    disputeId
  ) => {
    if (!fileId && !folderId) {
      return;
    }
    if (isFolder) {
      history.push(`/disputes/${disputeId}/files/${folderId}`);
    } else {
      DownloadFile(fileId)
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleClickOnInvoice = (invoice_id) => {
    if (!invoice_id) {
      return;
    }
    downloadInvoice(invoice_id);
  };

  return (
    <div>
      <Grid container>
        <Grid item>
          <Typography
            style={{ fontFamily: "Satoshi-Bold", fontSize: "1.5rem" }}
          >
            {activityTitle}
          </Typography>
        </Grid>
      </Grid>
      <Box height="2rem" />
      <>
        <Tabs
          tabs={isDifc ? activityCategoriesDifc : activityCategories}
          handleClickTab={handleTabChange}
          choice={currentTab}
          tabsClasses={{
            selected: classes.selected,
            unselected: classes.unselected,
          }}
          tabsContainerClass={classes.tabsContainer}
        />
        <Box height="1.5rem" />
      </>

      {/* Hearing activity */}
      {!activityLoader &&
        (isSebi || isMSME || isDifc) &&
        currentAppointment &&
        currentTab === "general" &&
        currentPage === 1 && (
          <React.Fragment key={0}>
            <Paper className={classes.activityPaper}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    className={classes.activityheading}
                    display="inline"
                  >
                    Hearing is underway
                  </Typography>
                </Grid>
                <Grid>
                  <FlashButton
                    onClick={() => {
                      history.push(
                        `/disputes/${currentAppointment.meeting.dispute.id}/chat`
                      );
                    }}
                    buttonText="Click to join"
                  />
                </Grid>
              </Grid>
              <Typography className={classes.activityTime}>
                {format(
                  new Date(currentAppointment.meeting.start),
                  "d MMMM y, h:mm a"
                )}{" "}
                - {currentAppointment.meeting.dispute.name}
              </Typography>
            </Paper>
            <Box height="1rem" />
          </React.Fragment>
        )}

      {activityLoader ? (
        <CircularCenteredLoader />
      ) : (
        activities?.results?.map((item) => {
          return (
            <ActivityRow
              bytesToSize={bytesToSize}
              classes={classes}
              handleClickOnFileFolderName={handleClickOnFileFolderName}
              handleClickOnInvoice={handleClickOnInvoice}
              item={item}
              profile={profile}
            />
          );
        })
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          alignItems: "center",
        }}
      >
        <Button
          disabled={currentPage == 1 || activities?.results?.length == 0}
          onClick={handlePreviousPage}
        >
          <NavigateBefore />
        </Button>
        <div>{currentPage}</div>
        <Button
          disabled={
            currentPage == Math.ceil(pagination.count / 10) ||
            activities?.results?.length == 0
          }
          onClick={handleNextPage}
        >
          <NavigateNext />
        </Button>
      </div>
    </div>
  );
};

export default GeneralSebiActivities;
