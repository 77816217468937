import {
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import {
  ADJUDICATION,
  ARBITRATION,
  MEDIATION,
  RESPONDENT_COMPLETE,
  RESPONDENT_INPROGRESS,
  GRIEVANCE_REDRESSAL,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import isEmpty from "lodash/isEmpty";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { updateCase } from "slices/casesSlice";
import { updateDispute } from "slices/disputeSlice";
import { getCompanyType } from "slices/profileSlice";
import FullPageSpinner from "features/common/FullPageSpinner";
import { isHotStar, isDigipub, isNSE, isStar, isMuthoot } from "Routes/Routes";
import instance from "api/globalaxios";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem 2rem 2rem 1rem",
    maxWidth: "21rem",
    margin: "5px",
  },
  radioHeading: {
    fontSize: "1.4rem",
    lineHeight: "1.8rem",
    // textTransform: "capitalize",
    paddingTop: "5px",
  },
  radioDetails: {
    fontSize: "1.6rem",
    lineHeight: "1.8rem",
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

const options = [ARBITRATION, MEDIATION, ADJUDICATION, GRIEVANCE_REDRESSAL];

const getOptionDetails = (option) => {
  const details = {
    0: <FormattedMessage id="methods.arbitration.details" />,
    1: <FormattedMessage id="methods.mediation.details" />,
    2: <FormattedMessage id="methods.adjudication.details" />,
    3: <FormattedMessage id="methods.grievance_redressal.details" />,
  };
  return details[option];
};

const RadioCard = ({ option, selectedValue, handleChange, company }) => {
  const detail = getOptionDetails(option);
  const classes = useStyles();
  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Box display="flex" alignItems="flex-start">
          <Radio
            checked={selectedValue === options[option]}
            onChange={handleChange}
            value={options[option]}
            color="primary"
          />
          <Box width="0.8rem"></Box>
          <Box>
            {(isHotStar || isStar || isMuthoot) ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={classes.radioHeading}
                >
                  Submit a Response to the Grievance Officer
                </Typography>
                <Box height="1.5rem"></Box>
                {/* <Typography
                  variant="subtitle2"
                  style={{ textAlign: "center", textTransform: "none" }}
                >
                  {detail}
                </Typography> */}
              </>
            ) : company.name == "CBSE" ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={classes.radioHeading}
                >
                  Acknowledge grievance and prepare a response
                </Typography>
                <Box height="1.5rem"></Box>
                {/* <Typography
                  variant="subtitle2"
                  style={{ textAlign: "center", textTransform: "none" }}
                >
                  {detail}
                </Typography> */}
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={classes.radioHeading}
                >
                  {options[option]}
                </Typography>
                <Box height="1.5rem"></Box>
                <Typography
                  variant="subtitle2"
                  style={{
                    textAlign: "left",
                    textTransform: "none",
                    fontSize: "1rem",
                  }}
                >
                  {detail}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Paper>
      <Box width="1rem"></Box>
    </Fragment>
  );
};

export default function RespondentFlowSelection({
  activeDispute,
  activeCase,
  flows,
  profile,
}) {
  const [selectedValue, setSelectedValue] = React.useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  console.log("flows:", flows);

  const [loading, setLoading] = React.useState(true);
  const [companyType, setcompanyType] = React.useState([]);
  const [company, setcompany] = React.useState([]);

  async function getCompany() {
    try {
      let companyType = "";
      let response = await instance({
        method: "get",
        url: "/api/profile/",
      });
      let id = response.data.results[0].company;
      console.log("id is:", id);
      if (id) {
        try {
          let response = await instance({
            method: "get",
            url: `/api/company/${id}/`,
          });
          companyType = response.data;

          return companyType;
        } catch (error) {
          console.log(error);
        }
      }
      return companyType;
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    getCompany().then((data) => {
      setcompany(data);
      console.log("company", data);
    });
    getCompanyType().then((data) => {
      setcompanyType(data);
      setLoading(false);
    });
  }, []);

  console.log("loading:", loading);
  console.log("Now The company type is:", companyType);

  let renderedOptions = null;

  if (loading) return <FullPageSpinner />;

  if (companyType === "nbf" || companyType === "ott") {
    renderedOptions = isEmpty(flows)
      ? [GRIEVANCE_REDRESSAL]
      : options.filter((option) => {
          let hasFlow = false;
          flows.forEach((flow) => {
            if (flow.flow === option) {
              hasFlow = true;
            }
          });
          return hasFlow;
        });
  } else if (company.name == "CBSE") {
    renderedOptions = isEmpty(flows)
      ? [ARBITRATION]
      : options.filter((option) => {
          let hasFlow = false;
          flows.forEach((flow) => {
            if (flow.flow === option) {
              hasFlow = true;
            }
          });
          return hasFlow;
        });
  } else {
    renderedOptions = isEmpty(flows)
      ? [ARBITRATION, MEDIATION]
      : options.filter((option) => {
          let hasFlow = false;
          flows.forEach((flow) => {
            if (flow.flow === option) {
              hasFlow = true;
            }
          });
          return hasFlow;
        });
  }

  return (
    <Container>
      <Box height="3rem"></Box>
      <Box textAlign="center" width="100%" margin="0 auto">
        {!isHotStar && !isStar && !isMuthoot && !isDigipub && !isNSE && (
          <Typography
            variant="h3"
            style={{ lineHeight: "3rem", fontSize: "2rem" }}
          >
            <FormattedMessage id="pages.waitingScreens.respondentChoice.question" />
          </Typography>
        )}
        {isDigipub && (
          <Typography
            variant="h3"
            style={{ lineHeight: "3rem", fontSize: "2rem" }}
          >
            <FormattedMessage id="pages.waitingScreens.respondentChoice.question-digipub" />
          </Typography>
        )}
        {isNSE && (
          <Typography
            variant="h3"
            style={{ lineHeight: "3rem", fontSize: "2rem" }}
          >
            <FormattedMessage id="pages.waitingScreens.respondentChoice.question-nse" />
          </Typography>
        )}
      </Box>
      <Box height="3rem"></Box>
      <Box display="flex" justifyContent="center" style={{ flexWrap: "wrap" }}>
        {renderedOptions.map((option, index) => {
          return (
            <RadioCard
              option={options.indexOf(option)}
              selectedValue={selectedValue}
              handleChange={handleChange}
              key={index}
              company={company}
            />
          );
        })}
      </Box>
      <Box height="2rem"></Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          onClick={(e) => {
            dispatch(
              updateCase({ proposed_flow: selectedValue, id: activeCase.id })
            )
              .then((response) => {
                let disputePayload = {
                  id: activeDispute.id,
                  status: RESPONDENT_COMPLETE,
                  flow_type: selectedValue,
                };

                if (selectedValue === GRIEVANCE_REDRESSAL) {
                  disputePayload.status = RESPONDENT_INPROGRESS;
                  disputePayload.flow_type = ARBITRATION;
                } else if (
                  selectedValue === ARBITRATION ||
                  selectedValue === ADJUDICATION
                ) {
                  disputePayload.status = RESPONDENT_INPROGRESS;
                  disputePayload.flow_type = selectedValue;
                }

                return dispatch(updateDispute(disputePayload));
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          color="primary"
          size="large"
          disabled={isEmpty(selectedValue)}
          className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
        >
          <FormattedMessage id="buttons.proceed" />
        </Button>
      </Box>
      <Box height="2rem"></Box>
    </Container>
  );
}
