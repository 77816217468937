import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import instance from "api/globalaxios";
import { useSnackbar } from "contexts/SnackbarContext";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCompaniesByGroup } from "slices/companySlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  input: {
    display: "none",
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
}));

export default function CreateSebiDispute({ profile }) {
  const classes = useStyles();

  const [, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [fileUploaded, setFileUploaded] = useState();
  const { companies } = useSelector((state) => state.companies, shallowEqual);
  const [company, setCompany] = useState("");
  const { showSnackbar } = useSnackbar();
  const [disputeId, setDisputeId] = useState(null);

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompaniesByGroup("sebi"));
  }, [dispatch]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setFileUploaded(file);
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const fileContent = e.target.result;
        const parsedJsonData = JSON.parse(fileContent);
        setJsonData(parsedJsonData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    reader.readAsText(file);
  };

  async function handleCreateDispute() {
    try {
      setLoading(true);
      setDisputeId(null);

      const formData = new FormData();
      formData.append("file", fileUploaded);
      formData.append("company_id", company);

      await instance({
        method: "post",
        url: "/sebi/processjson/",
        data: formData,
      });
      setLoading(false);
      showSnackbar("Dispute Created", "success");
    } catch (err) {
      const reason = err.response?.data?.error;
      if (reason) {
        setLoading(false);
        showSnackbar(reason, "error");
        return;
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" direction="column">
        <Grid item>
          <Grid
            container
            justify="space-between"
            direction="row"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography className={classes.heading}>
                Create Sebi Dispute
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.username}>
                {profile.user.first_name} {profile.user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Box height="5rem" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <FormControl
                  required
                  fullWidth={true}
                  variant="outlined"
                  disabled={companies.length === 0}
                >
                  <InputLabel id="against">MII</InputLabel>
                  <Select
                    id="company"
                    name="company"
                    value={company}
                    onChange={handleCompanyChange}
                    label="MII"
                  >
                    {companies.map((item, index) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        disabled={profile.company ? item.id !== profile.company : false}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <input
                  accept=".json"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload JSON File
                  </Button>
                </label>
              </Paper>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateDispute}
              disabled={!company || !fileUploaded}
            >
              Create Dispute
            </Button>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="h5">Uploaded JSON Data</Typography>
                {jsonData ? (
                  <Typography
                    style={{ whiteSpace: "break-spaces", textAlign: "left" }}
                  >
                    {JSON.stringify(jsonData, null, 4)}
                  </Typography>
                ) : (
                  <p>No JSON data uploaded yet.</p>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
