import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDisputesCount,
  getDisputesForPaginationHotstar,
} from "slices/disputeSlice";
import Disputes from "./HotstarDisputes/Disputes";

export const typeChoices = ["registered", "closed", "serious", "frivolous"];

export default function DisputesContainerHotstar() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  const [count, setCount] = useState({ received: {}, registered: {} });
  const [countLoading, setCountLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  let sessionType = sessionStorage.getItem("hotstar-type");

  const [type, setType] = useState(sessionType ? sessionType : typeChoices[2]);
  const [disputes, setDisputes] = useState([]);
  const [disputesLoading, setDisputesLoading] = useState(false);

  React.useEffect(() => {
    dispatch(getDisputesCount()).then((res) => {
      setCount(res);
      setCountLoading(false);
    });
    reload()
  }, []);


  const reload = (newType = type) => {
    setDisputesLoading(true);
    setCurrentPage(1);
    dispatch(getDisputesForPaginationHotstar(currentPage, newType)).then((res) => {
      let count = res.count;
      let t = parseInt(count / 100);
      let r = count % 100;
      let total = t + (r > 0 ? 1 : 0);
      setTotalPages(total);
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  const getUserDataAnotherPage = (pageNo) => {
    setDisputesLoading(true);
    dispatch(getDisputesForPaginationHotstar(pageNo, type)).then((res) => {
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  let childProps = {
    disputes,
    profile,
    user,
    count,
    countLoading,
    disputesLoading,
    setType,
    type,
    reload,
    totalPages,
    currentPage,
    setCurrentPage,
    getUserDataAnotherPage,
  };

  if (countLoading) return <CircularCenteredLoader />;
  return <Disputes {...childProps} />;
}
