import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { isCDR, isHotStar, isDigipub, isNSE, isVikalp, isStar, isMuthoot } from "Routes/Routes";
import { formatDate } from "utils";

export default function ClaimantDispute({
  classes,
  dispute,
  onClick,
  disputeStatus,
  claimantName,
  respondentName,
}) {
  const complaintOrDispute =
    isCDR || isVikalp ? "dispute" : isNSE ? "case" : "complaint";

  return (
    <Box textAlign="center" display="flex" justifyContent="center">
      <Paper className={classes.item}>
        <Card className="mainstep3">
          <CardContent>
            <Box display="flex">
              <FiberManualRecordIcon style={{ color: "#6FCF79" }} />
              <Typography variant="body2">
                <FormattedMessage
                  id={`dispute.${disputeStatus.toLowerCase()}`}
                />
              </Typography>
              <Box flexGrow={1}></Box>
              <Typography variant="body2">
                {isHotStar || isStar || isMuthoot || isDigipub ? (
                  <FormattedMessage id="dispute.latestGrievance" />
                ) : isNSE ? (
                  "Latest Case"
                ) : (
                  <FormattedMessage id="dispute.latestDispute" />
                )}
              </Typography>
            </Box>
            <Box height="1rem"></Box>
            <Typography
              gutterBottom
              variant="body1"
              className={classes.dispute_heading}
            >
              {claimantName} filed a {complaintOrDispute} against{" "}
              {respondentName} on {formatDate(dispute.order_date)}
            </Typography>

            <Box height="1rem"></Box>

            <Typography variant="subtitle1" color="textSecondary" component="p">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onClick}
                className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
              >
                <FormattedMessage id="buttons.proceed" />
                {/* Proceed */}
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}
