import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import instance from "api/globalaxios";
import { RESPONDENT } from "app/constants";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FormattedMessage from "features/common/TranslatedMessage";
import { DropzoneDialog } from "material-ui-dropzone";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isCDR, isVikalp } from "Routes/Routes";
import {
  approvedDispute,
  closedDispute,
  downloadLegalDoc,
  ForwardDisputeHotstar,
  gethost,
  updateDispute,
  uploadDispute,
} from "slices/disputeSlice";
import { DocGenerator, formatDate } from "utils";
import eyeRafiki from "../../../images/hotstareye.svg";
import QuestionModal from "../ArbitratorCommonContent/QuestionModal";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: `"Inter", "sans-serif"`,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    // background:"grey"
  },
  blue: {
    fontFamily: `"Inter", "sans-serif"`,
    color: "#2F80ED",
    lineHeight: "24px",
  },
  bluetext: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F80ED",
  },
  blacktext: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#333333",
  },
  viewdec: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F80ED",
    textDecoration: "underline",
    cursor: "pointer",
  },
  register: {
    flexGrow: 1,
    width: "100%",
    background: "#2F80ED",
    borderRadius: "2px",
    color: "#FFFFFF",
    height: "100%",
    textAlign: "left",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "400",
    border: "1px solid #2F80ED",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
    "&:hover": {
      background: "#FFFFFF",
      color: "#2F80ED",
      border: "1px solid #2F80ED",
    },
    "&:disabled": {
      border: "1px solid #c6c6c6",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  itempaper: {
    padding: "1rem",
    margin: " 0 0 0.3rem 0",
  },
  itemtext: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#4F4F4F",
  },
  share: {
    fontSize: "14px",
    textDecoration: "underline",
    color: "#2F80ED",
    cursor: "pointer",
  },
  shared: {
    fontSize: "14px",
    textDecoration: "underline",
    color: "rgb(33, 155, 17)",
  },
  calender_File_Btn: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#2F80ED",
    backgroundColor: "#ffffff",
    textTransform: "none",
    borderRadius: "2px",
    border: "1px solid #2F80ED",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
    width: "100%",
    maxWidth: "100%",
    "&:hover": {
      background: "#2F80ED",
      color: "#FFFFFF",
      border: "1px solid #2F80ED",
    },
    "&:disabled": {
      border: "1px solid #c6c6c6",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

export async function getClaimantDetails(id) {
  return await instance({
    method: "get",
    url: "/getmembers/" + id + "/",
  });
}

export default function NewArbitratorSingleDispute({
  activeDispute: initialDispute,
  profile,
  activeDispute,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [dispute, setDispute] = React.useState(initialDispute);
  const [loading, setLoading] = React.useState(true);
  const [claimant_email, setClaimant_email] = React.useState("");
  React.useEffect(() => {
    getClaimantDetails(initialDispute.id).then((res) => {
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        if (element.profile_type == "general") {
          setClaimant_email(element.user.email);
          setLoading(false);
          break;
        }
      }
    });
  }, []);

  const [decisionFiles, setdecisionFiles] = React.useState(dispute.all_docs);
  const [finalDecision, setFinalDecision] = React.useState();

  const [isDisputeClosed, setIsDisputeClosed] = React.useState(
    dispute.status == "closed"
  );

  const [openmodal3, setOpenmodal3] = React.useState(false);
  const handleCloseModal3 = (e) => {
    e.stopPropagation();
    setOpenmodal3(false);
  };

  React.useEffect(() => {
    decisionFiles.forEach((file) => {
      if (file.is_final) {
        setFinalDecision(file.id);
      }
    });
  }, [decisionFiles]);

  const reload = () => {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/dispute/${dispute.id}/` + "?host=" + gethost()
        );
        setDispute(response.data);
        setdecisionFiles(response.data.all_docs);
      } catch (error) {
        console.log(error);
      }
    };
  };

  const shareDecision = (file) => {
    return async (dispatch) => {
      try {
        const response = await instance.put(
          "/finaldecession/" + file.id + "/",
          { dispute_id: dispute.id },
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
  };
  const onClickProceed = () => {
    dispatch(uploadDispute(files, dispute)).then((resp) =>
      dispatch(
        updateDispute({ id: dispute.id, arbitrator_status: "webnyay_upload" })
      )
    );
  };
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const addFile = (file) => {
    console.log(file);
    files.push(file);
    setFiles(files);
  };
  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };

  // const Forward = () => {
  //   // dispatch(uploadDispute(files, dispute)).then((resp) =>
  //   dispatch(

  //     ForwardDisputeHotstar(activeDispute.id)
  //   )
  //   // );
  // };

  const UploadDialog = ({ open, setOpen, addFile }) => {
    const classes = useStyles();
    return (
      <Box textAlign="center" position="relative">
        <Box>
          <Box>
            <IconButton
              className={classes.icon}
              aria-label="delete"
              // color="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              <AddCircleOutlineIcon className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        <DropzoneDialog
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          maxFileSize={104857600}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            files.forEach((file) => {
              addFile(file);
            });
            setOpen(false);
          }}
          showPreviews={true}
          filesLimit={1}
          showFileNamesInPreview={true}
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/msword",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
        />
      </Box>
    );
  };

  const renderFiles = (files, deleteFile) => {
    console.log("files", files);
    return files.length !== 0
      ? files.map((file, index) => {
          return (
            <Paper className={classes.itempaper}>
              <Box
                textAlign="center"
                position="relative"
                key={index}
                // display="flex"
                // justifyContent="center"
              >
                <Box>
                  <Typography
                    style={{ wordBreak: "break-all", paddingTop: "20px" }}
                    variant="subtitle2"
                  >
                    {file.name}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      deleteFile(index);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          );
        })
      : null;
  };

  if (loading) return <CircularCenteredLoader />;

  return (
    <Container>
      <Box p="2rem 0">
        <Grid container>
          <Grid item md={2} sm={0} xs={0}></Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper
              style={{ background: "none", border: "none", boxShadow: "none" }}
            >
              <Button
                onClick={() => {
                  // history.push("/disputes");
                  history.goBack();
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Paper>
            <Paper style={{ padding: "3.5rem" }}>
              <Box className={classes.text}>
                <Typography
                  style={{
                    fontSize: "28px",
                    lineHeight: "36px",
                    overflowWrap: "anywhere",
                  }}
                >
                  {dispute.name.split("&")[0]}
                </Typography>
                <Box height="1rem"></Box>
              </Box>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography
                    className={classes.blue}
                    onClick={() => {
                      DocGenerator(dispute.id, "claimant");
                    }}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {isCDR || isVikalp ? (
                      <FormattedMessage id="dispute.statementOfClaim1" />
                    ) : (
                      "VIEW GRIEVANCE"
                    )}
                  </Typography>
                </Grid>
                {true && (
                  <Grid item>
                    <Typography
                      className={classes.blue}
                      onClick={() => {
                        DocGenerator(dispute.id, RESPONDENT);
                      }}
                      style={{
                        textDecorationLine: "underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {isCDR || isVikalp ? (
                        <FormattedMessage id="dispute.statementOfDefence1" />
                      ) : (
                        "VIEW RESPONSE"
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Box height="3rem"></Box>
              <Grid container>
                <Grid item md={6} sm={6} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography className={classes.bluetext}>
                        Date of filing:
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      <Typography className={classes.blacktext}>
                        {formatDate(dispute.created)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography className={classes.bluetext}>
                        Grievance ID:
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      <Typography className={classes.blacktext}>
                        {dispute.approved ? dispute.id : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6} sm={6} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography className={classes.bluetext}>
                        Registration date:
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      {dispute.approved_date ? (
                        <Typography className={classes.blacktext}>
                          {formatDate(dispute.approved_date)}
                        </Typography>
                      ) : (
                        <Typography>{"--"}</Typography>
                        // dispute.forward ? (
                        //     <Typography className={classes.blacktext}>{"forward"}</Typography>

                        //   ) : (
                        //     <Typography>{"--"}</Typography>
                        //   )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography className={classes.bluetext}>
                        Decision:
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      {finalDecision ? (
                        <Typography
                          className={classes.viewdec}
                          onClick={() => {
                            downloadLegalDoc(finalDecision);
                          }}
                        >
                          VIEW
                        </Typography>
                      ) : (
                        <Typography className={classes.blacktext}>
                          {"(In Progress)"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6} sm={6} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography className={classes.bluetext}>
                        Contact:
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      <Typography className={classes.blacktext}>
                        {claimant_email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box height="3rem"></Box>
              <Grid item xs={12} md={8}>
                <Grid container direction="row" justify="space-evenly">
                  <Grid item xs={12} sm={6} md={6} style={{ padding: "10px" }}>
                    <Button
                      className={classes.calender_File_Btn}
                      variant="outlined"
                      // color="myblack"
                      size="large"
                      onClick={() => {
                        history.push("/events");
                      }}
                    >
                      <FormattedMessage id="buttons.calendar" />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} style={{ padding: "10px" }}>
                    <Button
                      variant="outlined"
                      className={classes.calender_File_Btn}
                      color="myblack"
                      size="large"
                      onClick={() => {
                        history.push(`/disputes/${activeDispute.id}/files`);
                      }}
                    >
                      <FormattedMessage id="common.files" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Box height="1.5rem"></Box>
            {/* BUttons */}
            <Grid container spacing={2} justify="space-evenly">
              <Grid item md={4} sm={4} xs={8}>
                <Button
                  variant="contained"
                  className={classes.register}
                  disabled={dispute.approved}
                  onClick={() => {
                    dispatch(
                      approvedDispute({
                        id: dispute.id,
                        approved: true,
                      })
                    ).then(() => {
                      dispatch(reload());
                    });
                  }}
                >
                  Register
                </Button>
              </Grid>
              <Grid item md={4} sm={4} xs={8}>
                <Button
                  variant="contained"
                  className={classes.register}
                  onClick={(event) => {
                    setOpenmodal3(true);
                  }}
                >
                  Input Outcome
                </Button>
              </Grid>
              {openmodal3 && (
                <QuestionModal
                  openmodal={openmodal3}
                  handleCloseModal={handleCloseModal3}
                  activeDispute={activeDispute}
                  setOpenmodal3={setOpenmodal3}
                />
              )}
              <Grid item md={4} sm={4} xs={8}>
                <Button
                  variant="contained"
                  className={classes.calender_File_Btn}
                  disabled={dispute.forward}
                  // onClick={(e) => { Forward() }}
                  onClick={() => {
                    dispatch(
                      ForwardDisputeHotstar({
                        id: dispute.id,
                        forward: true,
                      })
                    ).then(() => {
                      dispatch(reload());
                    });
                  }}
                >
                  Forward
                </Button>
              </Grid>
            </Grid>
            <Box height="4rem" />
            <Paper
              style={{ background: "none", boxShadow: "none", border: "none" }}
              className={classes.itempaper}
            >
              <Grid container>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography className={classes.blacktext}>
                    Upload Decision
                  </Typography>
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                  <Typography className={classes.blacktext}>
                    Upload Date
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            {decisionFiles.map((item) => {
              return (
                <Paper className={classes.itempaper}>
                  <Grid container>
                    <Grid item md={6} sm={6} xs={6}>
                      <Typography
                        className={classes.itemtext}
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                      <Typography className={classes.itemtext}>
                        {formatDate(item.created)}
                      </Typography>
                    </Grid>
                    <Grid item md={1} sm={1} xs={1}>
                      <img
                        src={eyeRafiki}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          downloadLegalDoc(item.id);
                        }}
                        alt=""
                      />
                    </Grid>
                    <Grid item md={2} sm={2} xs={2}>
                      {item.is_final ? (
                        <Typography className={classes.shared}>
                          Shared
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.share}
                          onClick={() => {
                            dispatch(shareDecision(item)).then(() => {
                              dispatch(reload());
                            });
                          }}
                        >
                          Share
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
            {files.length === 0 && dispute.status != "closed" && (
              <Paper className={classes.itempaper}>
                <UploadDialog open={open} setOpen={setOpen} addFile={addFile} />
              </Paper>
            )}
            {renderFiles(files, deleteFile)}
            <Box height="2rem"></Box>
            {files.length != 0 && (
              <Grid container justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.register}
                    onClick={(e) => {
                      onClickProceed();
                    }}
                  >
                    <FormattedMessage id="buttons.submit" />
                  </Button>
                </Grid>
              </Grid>
            )}
            <Box height="4rem"></Box>
            <Grid container>
              <Grid item sm={12} style={{ margin: "0 auto" }}>
                <Button
                  variant="contained"
                  className={classes.register}
                  onClick={(e) => {
                    dispatch(
                      closedDispute({ id: dispute.id, status: "closed" })
                    );
                  }}
                  disabled={dispute.status == "closed"}
                >
                  CLOSE GRIEVANCE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box height="5rem"></Box>
    </Container>
  );
}
