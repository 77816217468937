import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CreateNewLogin from "./CreateAccount/CreateNewLogin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
}));

export const getAvailableProfilesDIFC = (profile) => {
  return profile.profile_type === "sub_admin_tiac_rv"
    ? [
        { type: "general_rv", name: "Party" },
        { type: "lawyer_rv", name: "Counsel" },
        { type: "arbitrator_rv", name: "Arbitrator" },
        { type: "mediator", name: "Mediator" },
        { type: "witness_rv", name: "Barrister" },
        { type: "expert_rv", name: "Solicitor" },
        { type: "case_manager", name: "Case Manager" },
      ]
    : profile.profile_type === "case_manager"
    ? [
        { type: "general_rv", name: "Party" },
        { type: "lawyer_rv", name: "Counsel" },
        { type: "arbitrator_rv", name: "Arbitrator" },
        { type: "mediator", name: "Mediator" },
        { type: "witness_rv", name: "Barrister" },
        { type: "expert_rv", name: "Solicitor" },
      ]
    : [];
};

export default function CreateAccount({ profile }) {
  const classes = useStyles();

  const profiles = getAvailableProfilesDIFC(profile);

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" direction="column">
        <Grid item>
          <Grid
            container
            justify="space-between"
            direction="row"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography className={classes.heading}>
                Create Accounts
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.username}>
                {profile.user.first_name}
              </Typography>
            </Grid>
          </Grid>
          <Box height="5rem"></Box>
          <CreateNewLogin profiles={profiles} opened={true} profile={profile} />
        </Grid>
      </Grid>
    </div>
  );
}
