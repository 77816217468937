import {
  Box,
  Button,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import downSvg from "../images/dataanalytics/down.svg";
import ChooseStepTwo from "./ChooseStepTwo";
import MapContainer from "./MapContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4rem 7rem",
    [theme.breakpoints.down('md')]:{
      padding: "40px",
    },
    [theme.breakpoints.down('sm')]:{
      padding: "30px",
    },
    [theme.breakpoints.down('sm')]:{
      padding: "20px",
    }
  },
  paperitem: {
    padding: "1rem",
    textAlign: "center",
    cursor: "pointer",
  },
  selectedpaper: {
    padding: "1rem",
    textAlign: "center",
    background: "#edf4fe",
    // opacity: "0.12",
  },
  textitem: {
    fontSize: "20px",
    color: "#333333",
  },
  select: {
    marginTop: "0.5rem",
    width: "100%",
    background: "#4F4F4F",
    borderRadius: "4px",
    color: "#ffffff",
    textTransform: "none",
    padding: "10px 0",
    "&:hover": {
      background: "#4F4F4F",
    },
  },
  icon: {
    fill: "#ffffff",
  },
  menu: {
    width: "100%",
  },
}));

export default function DataAnalytics({
  type,
  setType,
  choosestep2,
  setChoosestep2,
  data,
  states,
  stateLists,
  stateOnClick,
  activeState,
  setactiveState,
}) {
  const classes = useStyles();
  const rangeValues = ["1 Year", "6 Months", "3 Months"];
  const [range, setRange] = useState("1 Year");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item md={8} sm={9} xs={12}>
          <Grid container justify="flex-start" wrap='wrap' spacing={3}>
            <Grid item md={4} sm={6} xs={12} style={{display:'flex'}}>
              <Paper
                style={{width:'100%'}}
                className={
                  type == 1 ? classes.selectedpaper : classes.paperitem
                }
                onClick={() => {
                  setType(1);
                  setChoosestep2();
                  setactiveState();
                }}
              >
                <Typography className={classes.textitem}>INVESTOR</Typography>
              </Paper>
            </Grid>
            <Grid item md={4} sm={6} xs={12} style={{display:'flex'}}>
              <Paper
              style={{width:'100%'}}
                className={
                  type == 2 ? classes.selectedpaper : classes.paperitem
                }
                onClick={() => {
                  setType(2);
                  setChoosestep2();
                  setactiveState();
                }}
              >
                <Typography className={classes.textitem}>
                  TRADING MEMBER
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={4} sm={6} xs={12} style={{display:'flex'}}>
              <Paper
              style={{width:'100%'}}
                className={
                  type == 3 ? classes.selectedpaper : classes.paperitem
                }
                onClick={() => {
                  setType(3);
                  setChoosestep2();
                  setactiveState();
                }}
              >
                <Typography className={classes.textitem}>ARBITRATOR</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={3} xs={12}>
          <Grid container justify="flex-end">
            <Grid item md={4} sm={5} xs={5}>
              <Button
                className={classes.select}
                onClick={(e) => {
                  handleClick(e);
                }}
              >
                {range} &nbsp;&nbsp;&nbsp;&nbsp;
                <img src={downSvg} />
              </Button>
              <Menu
                id="range-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                className={classes.menu}
              >
                <MenuItem
                  onClick={() => {
                    setRange(rangeValues[0]);
                    handleClose();
                  }}
                  value={rangeValues[0]}
                >
                  {rangeValues[0]}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setRange(rangeValues[1]);
                    handleClose();
                  }}
                  value={rangeValues[1]}
                >
                  {rangeValues[1]}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setRange(rangeValues[2]);
                    handleClose();
                  }}
                >
                  {rangeValues[2]}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {type == 1 && (
        <ChooseStepTwo
          choosestep2={choosestep2}
          setChoosestep2={setChoosestep2}
          data={data}
        />
      )}
      {type == 1 && (
        <MapContainer
          states={states}
          stateLists={stateLists}
          stateOnClick={stateOnClick}
          activeState={activeState}
          choosestep2={choosestep2}
          data={data}
        />
      )}
    </Box>
  );
}
