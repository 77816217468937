import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Avatar from "features/Chat/Avatar";
import { isCDR, isVikalp } from "Routes/Routes";
import { replaceUserNameWithName } from "utils/chatUtils";

const useStyles = makeStyles((theme) => {
  return {
    text: {
      fontSize: "1.5rem",
      fontWeight: '700',
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
        lineHeight: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1rem",
      },
    },
    updatedText: {
      fontSize: "1rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
        lineHeight: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1rem",
      },
    },
    unread: {
      color: 'white',
      backgroundColor: '#25d366',
      borderRadius: '50%',
      textAlign: 'center',
      height: '12px',
      width: '12px',
      fontSize: '9.8px',
      padding: '4px',
    },
    unreadStamp: {
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
    },
    mentions: {
      fontSize: '15px',
      color: '#25d366',
      marginRight: '3px',
    }
  };
});

export default function GroupDisplay({
  group: { name: groupName, lastMessage: { msg = "Hi there...", mentions } = {}, counters },
  name,
  fontSize,
  caption,
  rightDocOpen,
  setStep,
  smallScreen,
  unreadStatus,
}) {
  const classes = useStyles();
  const onClick = () => {
    setStep && setStep(2);
  };
  let Newname =
    isCDR || isVikalp
      ? name.trim() == "Main Room"
        ? "Arbitration"
        : name
      : name;

  return (
    <Box display="flex" onClick={onClick} style={{ cursor: "pointer", width:'100%' }}>
      <Avatar value={Newname.trim()[0].toUpperCase()} />
      <Box width="1rem"></Box>
      <div style={{flex: 1}}>
        <Typography
          variant="subtitle2"
          className={fontSize === "large" ? classes.text : classes.updatedText}
        >
          {Newname}
        </Typography>
        {caption && <Typography style={{fontSize: '12px'}} className="lineClamp" variant="caption" dangerouslySetInnerHTML={{__html: replaceUserNameWithName(msg,mentions)}}></Typography>}
        {!caption && smallScreen && (
          <Typography variant="caption">
            {"Click here to view options"}
          </Typography>
        )}
      </div>
      {(unreadStatus && (counters?.unreads > 0)) && 
        <>
          <Box width="0.8rem"></Box>
          <Box className={classes.unreadStamp}>
            {counters?.userMentions > 0 && 
              <Typography className={classes.mentions}>@</Typography>
            }
            <Typography className={classes.unread}>
              {counters?.unreads}
            </Typography>
          </Box>
        </>
      }
    </Box>
  );
}
