import { FormControl, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "20px",
    lineHeight: "24px",
    margin: "1rem 0 0 0",
    textAlign : "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
}));

const modes = [
  { name: "Arbitration", value: "arbitration" },
  { name: "Mediation", value: "mediation" },
];

function ModeSelect({ mode, setmode }) {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.heading}>Mode *</Typography>
      <FormControl
        variant="outlined"
        fullWidth={true}
        data-testid="mode-select"
        required={true}
        style={{margin: "1rem 0 0 0"}}
      >
        <Select
          value={mode}
          onChange={(e) => {
            setmode(e.target.value);
          }}
        >
          {modes.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              <Typography>{item.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default ModeSelect;
