import React from "react";
import NewAnalytics from "./NewAnalyticsContainer";
import PreviousAnalytics from "./AnalyticsContainer";
import { getCompanyType } from "slices/profileSlice";
import FullPageSpinner from "features/common/FullPageSpinner";
import { isHotStar, isMuthoot, isStar } from "Routes/Routes";

export default function FlowHandle({ profile }) {
  const [loading, setLoading] = React.useState(true);
  const [companyType, setcompanyType] = React.useState([]);

  React.useEffect(() => {
    getCompanyType().then((data) => {
      setcompanyType(data);
      setLoading(false);
    });
  }, []);

  if (loading) return <FullPageSpinner />;
  if (companyType === "nbf" || profile.profile.user.id == 221 || isHotStar || isStar || isMuthoot) {
    return <NewAnalytics />;
  } else {
    return <PreviousAnalytics />;
  }
}
