import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { UPLOAD_COMPLETE } from "app/constants";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import { DropzoneDialog } from "material-ui-dropzone";
import mime from "mime-types";
import React from "react";
import { useDispatch } from "react-redux";
import { updateCase } from "slices/casesSlice";
import { addUserFile } from "slices/disputeSlice";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: "3rem",
    top: "35%",
    position: "absolute",
  },
}));

const UploadDialog = ({ open, setOpen, addFile }) => {
  const classes = useStyles();
  return (
    <Box p="1rem 0" textAlign="center" position="relative">
      <Paper style={{ width: "80%", height: "165px" }}>
        <Box>
          <IconButton
            className={classes.icon}
            aria-label="delete"
            // color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddCircleOutlineIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Paper>
      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          files.forEach((file) => {
            addFile(file);
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={4}
        showFileNamesInPreview={true}
        //  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/msword",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />
    </Box>
  );
};

const renderFiles = (files, deleteFile) => {
  return files.length !== 0
    ? files.map((file, index) => {
        return (
          <Box
            p="1rem 0"
            textAlign="center"
            position="relative"
            key={index}
            // display="flex"
            // justifyContent="center"
          >
            <Paper style={{ width: "80%", height: "165px" }}>
              <Typography
                style={{ wordBreak: "break-all", paddingTop: "20px" }}
                variant="subtitle2"
              >
                {file.name}
              </Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Paper>
          </Box>
        );
      })
    : null;
};

export default function UploadOtt({ activeCase, activeDispute, profile }) {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [files1, setFiles1] = React.useState([]);
  const [files2, setFiles2] = React.useState([]);
  const [files3, setFiles3] = React.useState([]);
  const [files4, setFiles4] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [is2uploaded, setIs2uploaded] = React.useState(false);
  const [is1uploaded, setIs1uploaded] = React.useState(false);
  const [is3uploaded, setIs3uploaded] = React.useState(false);
  const [is4uploaded, setIs4uploaded] = React.useState(false);
  const [buttonvalue1, setbuttonvalue1] = React.useState("SAVE");
  const [buttonvalue2, setbuttonvalue2] = React.useState("SAVE");
  const [buttonvalue3, setbuttonvalue3] = React.useState("SAVE");
  const [buttonvalue4, setbuttonvalue4] = React.useState("SAVE");

  const addFile1 = (file) => {
    files1.push(file);
    setFiles1(files1);
  };

  const addFile2 = (file) => {
    files2.push(file);
    setFiles2(files2);
  };

  const addFile3 = (file) => {
    files3.push(file);
    setFiles3(files3);
  };

  const addFile4 = (file) => {
    files4.push(file);
    setFiles4(files4);
  };

  const dispatch = useDispatch();

  const deleteFile1 = (index) => {
    let localFiles = [...files1];
    localFiles.splice(index, 1);
    setFiles1(localFiles);
  };

  const deleteFile2 = (index) => {
    let localFiles = [...files2];
    localFiles.splice(index, 1);
    setFiles2(localFiles);
  };

  const deleteFile3 = (index) => {
    let localFiles = [...files3];
    localFiles.splice(index, 1);
    setFiles3(localFiles);
  };

  const deleteFile4 = (index) => {
    let localFiles = [...files4];
    localFiles.splice(index, 1);
    setFiles4(localFiles);
  };

  async function uploadAndUpdateCase(files, activeCase) {
    const data = new FormData();
    files.forEach(async (file) => {
      data.append(file.name, file);
      const fileBody = {
        dispute: activeCase.dispute,
        path: 123,
        file,
        profile: profile.id,
        type: mime.lookup(file.name),
        name: file.name,
        size: file.size,
        is_attachment: true,
      };
      await dispatch(addUserFile(fileBody));
    });
  }

  const onSubmit = async (e) => {
    setLoading(true);
    if (
      (is1uploaded || files1.length == 0) &&
      (is3uploaded || files3.length == 0) &&
      (is4uploaded || files4.length == 0) &&
      is2uploaded
    ) {
      dispatch(updateCase({ id: activeCase.id, case_status: UPLOAD_COMPLETE }));
    } else {
      alert("Please upload the mandatory documents and save them to proceed.");
    }
    setLoading(false);
  };

  const onSave = (files) => {
    setLoading(true);
    uploadAndUpdateCase(files, activeCase).then((response) => {
      setLoading(false);
      if (files == files1) {
        if (files.length != 0) {
          setbuttonvalue1("SAVED");
          setIs1uploaded(true);
        }
      } else if (files == files2) {
        if (files.length != 0) {
          setbuttonvalue2("SAVED");
          setIs2uploaded(true);
        }
      } else if (files == files3) {
        if (files.length != 0) {
          setbuttonvalue3("SAVED");
          setIs3uploaded(true);
        }
      } else {
        if (files.length != 0) {
          setbuttonvalue4("SAVED");
          setIs4uploaded(true);
        }
      }
    });
  };

  return (
    <div style={{ margin: "-54px -53px 0px -100px" }}>
      <Box p="2rem 0">
        <Box
          style={{ margin: "27px 0px 0px 427px", padding: "0px 0px 26px 0" }}
        >
          <Typography variant="h3">
            <FormattedMessage id="pages.upload.UpDoc" />
          </Typography>
        </Box>

        <div style={{ margin: "27px 0px 0px 327px" }}>
          <Typography //style={{ float: "left"}}
          >
            <FormattedMessage id="pages.upload.ott1" />
          </Typography>
          {files1.length === 0 && (
            <UploadDialog open={open1} setOpen={setOpen1} addFile={addFile1} />
          )}
          {renderFiles(files1, deleteFile1)}
          <Box paddingLeft="68%">
            <DebouncedButton
              size="large"
              variant={buttonvalue1 == "SAVE" ? "outlined" : "contained"}
              color="primary"
              onClickHandler={(e) => {
                onSave(files1);
                e.preventDefault();
              }}
              disabled={loading}
              loading={loading}
              value={buttonvalue1}
              debounceTime={1000}
            />
          </Box>
        </div>

        <div style={{ margin: "27px 0px 0px 327px" }}>
          <Typography //style={{ float: "left"}}
          >
            <FormattedMessage id="pages.upload.ott2" />
          </Typography>
          {files2.length === 0 && (
            <UploadDialog open={open2} setOpen={setOpen2} addFile={addFile2} />
          )}
          {renderFiles(files2, deleteFile2)}
          <Box paddingLeft="68%">
            <DebouncedButton
              size="large"
              variant={buttonvalue2 == "SAVE" ? "outlined" : "contained"}
              color="primary"
              onClickHandler={(e) => {
                onSave(files2);
                e.preventDefault();
              }}
              disabled={loading}
              loading={loading}
              value={buttonvalue2}
              debounceTime={1000}
            />
          </Box>
        </div>

        <div style={{ margin: "27px 0px 0px 327px" }}>
          <Typography //style={{ float: "left"}}
          >
            <FormattedMessage id="pages.upload.ott3" />
          </Typography>
          {files3.length === 0 && (
            <UploadDialog open={open3} setOpen={setOpen3} addFile={addFile3} />
          )}
          {renderFiles(files3, deleteFile3)}
          <Box paddingLeft="68%">
            <DebouncedButton
              size="large"
              variant={buttonvalue3 == "SAVE" ? "outlined" : "contained"}
              color="primary"
              onClickHandler={(e) => {
                onSave(files3);
                e.preventDefault();
              }}
              disabled={loading}
              loading={loading}
              value={buttonvalue3}
              debounceTime={1000}
            />
          </Box>
        </div>

        <div style={{ margin: "27px 0px 0px 327px" }}>
          <Typography //style={{ float: "left"}}
          >
            <FormattedMessage id="pages.upload.ott4" />
          </Typography>
          {files4.length === 0 && (
            <UploadDialog open={open4} setOpen={setOpen4} addFile={addFile4} />
          )}
          {renderFiles(files4, deleteFile4)}
          <Box paddingLeft="68%">
            <DebouncedButton
              size="large"
              variant={buttonvalue4 == "SAVE" ? "outlined" : "contained"}
              color="primary"
              onClickHandler={(e) => {
                onSave(files4);
                e.preventDefault();
              }}
              disabled={loading}
              loading={loading}
              value={buttonvalue4}
              debounceTime={1000}
            />
          </Box>
        </div>

        <Box
          p="0.5rem 0"
          display="flex"
          justifyContent="center"
          // m ="0.5rem 0"
          m="0px -174px 0px 0px"
        >
          <DebouncedButton
            size="large"
            variant="outlined"
            color="primary"
            onClickHandler={onSubmit}
            disabled={loading}
            loading={loading}
            value={<FormattedMessage id="buttons.submit" />}
            debounceTime={1000}
          />
        </Box>
      </Box>
    </div>
  );
}
