import { Backdrop, Fade, Grid, Snackbar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import CloseModalButton from "Admin/Common/CloseModalButton";
import { joinHearingRooms } from "Admin/RVTiac/Assign";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCase } from "slices/casesSlice";
import {
  updateDispute,
  updateDisputeDpcgcAdmin,
  updateDisputeHotstarAdmin,
} from "slices/disputeSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      padding: theme.spacing(3, 4, 3),
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      padding: theme.spacing(3, 1, 3),
    },
  },
  assign: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
  caseId: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
  profileType: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
  box: {
    padding: "0.7rem",
  },
  box2: {
    cursor: "pointer",
    padding: "0.7rem",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
  profileheading: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#25213B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  profileheading: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#25213B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
}));

export default function Assign({
  openmodal,
  handleCloseModal,
  caseId,
  profileType,
  casefiles,
  caseType_RV,
  allProfiles,
  mode,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  let company = sessionStorage.getItem("company");
  const [loading, setLoading] = useState(true);

  const [profiles, setProfiles] = useState([]);

  const [previousProfessionalsId, setpreviousProfessionalsId] = useState([]);
  const [previousCaseManagers, setPreviousCaseManagers] = useState([]);
  const [previousLawyerRv, setPreviousLawyerRv] = useState([]);

  React.useEffect(() => {
    if (
      profileType == "arbitrator" ||
      profileType == "arbitrator_rv" ||
      (profileType == "lawyer" && mode !== "CDR") ||
      profileType == "witness" ||
      profileType == "mediator" ||
      profileType == "case_manager"
    ) {
      if (company == "hotstar") {
        dispatch(disputeDetailsHotstarAdmin());
      } else if (company == "dpcgc") {
        dispatch(disputeDetailsDpcgcAdmin());
      } else {
        dispatch(disputeDetails());
      }
    }
  }, []);

  function disputeDetails() {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/disputedetailsbyid/${caseId}/`
        );
        let d = response.data;
        let res = [];
        let professionals = d.dispute_data.professionals;
        professionals.forEach((item) => {
          if (
            item.profile.profile_type != "lawyer_rv" &&
            item.profile.profile_type != "lawyer"
          )
            res.push(item.profile.id);
        });
        let resC = [];
        d.dispute_data.case_manager_rv.forEach((item) => {
          resC.push(item.profile.id);
        });
        setPreviousCaseManagers(resC);
        setpreviousProfessionalsId(res);
      } catch (error) {
        console.log(error);
      }
    };
  }
  function disputeDetailsHotstarAdmin() {
    return async (dispatch) => {
      try {
        const response = await instanceHotstar.get(
          `/api/disputedetailsbyid/${caseId}/`
        );
        let d = response.data;
        let res = [];
        let professionals = d.dispute_data.professionals;
        professionals.forEach((item) => {
          if (item.profile.profile_type != "lawyer") res.push(item.profile.id);
        });
        let resC = [];
        d.dispute_data.case_manager_rv.forEach((item) => {
          resC.push(item.profile.id);
        });
        setPreviousCaseManagers(resC);
        setpreviousProfessionalsId(res);
      } catch (error) {
        console.log(error);
      }
    };
  }
  function disputeDetailsDpcgcAdmin() {
    return async (dispatch) => {
      try {
        const response = await instanceDPCGC.get(
          `/api/disputedetailsbyid/${caseId}/`
        );
        let d = response.data;
        let res = [];
        let professionals = d.dispute_data.professionals;
        professionals.forEach((item) => {
          res.push(item.profile.id);
        });

        setpreviousProfessionalsId(res);
      } catch (error) {
        console.log(error);
      }
    };
  }

  const [openSnackBar1, setOpenSnackBar1] = useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };

  React.useEffect(() => {
    if (company == "hotstar") {
      dispatch(getUsersHotstarAdmin());
    } else if (company == "dpcgc") {
      dispatch(getUsersDpcgcAdmin());
    } else {
      dispatch(getUsers());
    }
  }, []);

  function getUsers() {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/userappointmentprofilelist/${null}/${null}/${profileType}`
        );
        setProfiles(response.data.profiles);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function getUsersHotstarAdmin() {
    return async (dispatch) => {
      try {
        const response = await instanceHotstar.get(
          `/api/userappointmentprofilelist/${null}/${null}/${profileType}`
        );
        setProfiles(response.data.profiles);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function getUsersDpcgcAdmin() {
    return async (dispatch) => {
      try {
        const response = await instanceDPCGC.get(
          `/api/userappointmentprofilelist/${null}/${null}/${profileType}`
        );
        setProfiles(response.data.profiles);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const assignUser = (profile) => {
    if (
      profileType == "arbitrator" ||
      profileType == "arbitrator_rv" ||
      profileType == "mediator" ||
      (profileType == "lawyer" && mode !== "CDR") ||
      profileType == "witness" ||
      profileType == "witness_rv"
    ) {
      let res = previousProfessionalsId;
      res.push(profile.id);
      if (company == "hotstar") {
        dispatch(
          updateDisputeHotstarAdmin({
            id: caseId,
            professionals: res,
          })
        ).then(() => {
          setOpenSnackBar1(true);
        });
      } else if (company == "dpcgc") {
        dispatch(
          updateDisputeDpcgcAdmin({
            id: caseId,
            professionals: res,
          })
        ).then(() => {
          setOpenSnackBar1(true);
        });
      } else {
        dispatch(
          updateDispute({
            id: caseId,
            professionals: res,
          })
        ).then(() => {
          dispatch(joinHearingRooms(caseId));
          setOpenSnackBar1(true);
        });
      }
    } else if (
      profileType == "lawyer_rv" ||
      (profileType === "lawyer" && mode === "CDR")
    ) {
      if (caseType_RV == "Claimant") {
        casefiles.forEach((c) => {
          if (c.type == "claimant") {
            let id = c.id;
            let lawyersIds;
            if (previousLawyerRv.length > 0) {
              lawyersIds = previousLawyerRv;
            } else {
              lawyersIds = [...c.lawyer_rv];
            }
            lawyersIds.push(profile.id);
            setPreviousLawyerRv(lawyersIds);
            dispatch(updateCase({ id: id, lawyer_rv: lawyersIds })).then(() => {
              setOpenSnackBar1(true);
              dispatch(joinHearingRooms(caseId));
            });
          }
        });
      } else {
        casefiles.forEach((c) => {
          if (c.type == "respondent") {
            let id = c.id;
            let lawyersIds;
            if (previousLawyerRv.length > 0) {
              lawyersIds = previousLawyerRv;
            } else {
              lawyersIds = [...c.lawyer_rv];
            }
            lawyersIds.push(profile.id);
            setPreviousLawyerRv(lawyersIds);
            dispatch(updateCase({ id: id, lawyer_rv: lawyersIds })).then(() => {
              setOpenSnackBar1(true);
              dispatch(joinHearingRooms(caseId));
            });
          }
        });
      }
    } else if (profileType == "case_manager") {
      let ids = previousCaseManagers;
      ids.push(profile.id);
      setPreviousCaseManagers(ids);
      dispatch(
        updateDispute({
          id: caseId,
          case_manager_rv: ids,
        })
      ).then(() => {
        setOpenSnackBar1(true);
        dispatch(joinHearingRooms(caseId));
      });
    }
  };

  const body = (
    <div className={classes.paper}>
      <CloseModalButton onClick={handleCloseModal} />
      <Typography className={classes.assign}>Assign</Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.caseId}>Case Id : {caseId}</Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.profileType}>
        Profile Type : {profileType}
      </Typography>
      <Box height="1.5rem"></Box>

      <Grid container justify="center">
        <Grid item md={10} sm={12} xs={12}>
          <Box className={classes.box}>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Typography className={classes.profileheading}>Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.profileheading}>
                  Email
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {profiles.map((profile) => {
            if (!allProfiles.some((item) => item.profile.id == profile.id))
              return (
                <Box
                  className={classes.box2}
                  onClick={(e) => {
                    assignUser(profile);
                  }}
                >
                  <Grid container justify="space-between">
                    <Grid item xs style={{ lineBreak: "anywhere" }}>
                      <Typography className={classes.profile}>
                        {profile.user.first_name} {profile.user.last_name}
                      </Typography>
                    </Grid>
                    <Grid item xs style={{ lineBreak: "anywhere" }}>
                      <Typography className={classes.profile}>
                        {profile.user.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
          })}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Assigned"
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Assigned
        </Alert>
      </Snackbar>
    </div>
  );

  if (loading) {
    return <CircularCenteredLoader />;
  }
  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
