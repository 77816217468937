import { createSlice } from "@reduxjs/toolkit";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import isEmpty from "lodash/isEmpty";
import { gethost } from "./disputeSlice";
import { filterPastMeetings } from "features/Chat/Scheduler/Schedule.utils";
import axios from 'axios'
import { getRealhost } from "./disputeSlice";

export const initialState = {
  loading: false,
  slots: [],
  bookedSlots: [],
  meetings: [],
  errors: [],
  allUserMeetings: [],
};

const appointmentSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    setProgress: (state, { payload }) => {
      state.loading = payload;
    },
    setSlots: (state, { payload }) => {
      state.slots = payload;
      state.loading = false;
    },
    setBooked: (state, { payload }) => {
      state.bookedSlots = payload;
      state.loading = false;
    },
    setMeeting: (state, { payload }) => {
      state.meetings = payload;
      state.loading = false;
    },
    setAllMeetings: (state, { payload }) => {
      state.allUserMeetings = payload;
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

export const {
  setProgress,
  setErrors,
  setMeeting,
  setAllMeetings,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;

export const checkMeeting = (disputeId) => async (dispatch) => {
  try {
    let response = await instance({
      method: "get",
      url: `/api/meeting/`,
      params: { dispute__id: disputeId },
    });
    const meetings = filterPastMeetings(response.data.results);
    dispatch(setMeeting(meetings));
    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setErrors(err));
  }
};

export const addCalenderEvent = (data) => async (dispatch) => {
  try {
    let response = await instance({
      method: "post",
      url: `/api/meeting/`,
      data: data,
    });
    dispatch(getAllMeetings());

    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setErrors(err));
  }
};

export const editMeetingTitle = (id, title) => async (dispatch) => {
  try {
    const data = {
      name : title
    }
    let response = await instance({
      method: "patch",
      url: `/api/meeting/${id}/`,
      data: data,
    });
    dispatch(getAllMeetings());

    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setErrors(err));
  }
};

export const deleteMeeting = (meetingId, disputeId) => async (dispatch) => {
  try {
    let response = await instance({
      method: "delete",
      url: `/api/meeting/${meetingId}/?host=${getRealhost()}`,
    });
    dispatch(checkMeeting(disputeId));
    dispatch(getAllMeetings());
  } catch (err) {
    console.log(err);
    dispatch(setErrors(err));
  }
};

export const getAllMeetings = () => async (dispatch) => {
  dispatch(setProgress(true));
  try {
    let response = await instance({
      method: "get",
      url: `/api/user-meetings-list/`,
    });
    // const meetings = filterPastMeetings(response.data.results);
    dispatch(setAllMeetings(response.data.appointments));
    dispatch(setProgress(false));

    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setProgress(false));

    dispatch(setErrors(err));
  }
};


export const getAllMeetingsWithDisputeName = () => async (dispatch) => {
  dispatch(setProgress(true));
  try {
    let response = await instance({
      method: "get",
      url: `/api/user-meetings-list/?is_dispute_serializer=true`,
    });
    // const meetings = filterPastMeetings(response.data.results);
    dispatch(setAllMeetings(response.data.appointments));
    dispatch(setProgress(false));

    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setProgress(false));

    dispatch(setErrors(err));
  }
};


export const getAllMeetingsOfUser = (id) => async (dispatch) => {
  dispatch(setProgress(true));
  try {
    let response = await instance({
      method: "get",
      url: `/api/user-meetings-list-admin/${id}/`,
    });
    // const meetings = filterPastMeetings(response.data.results);
    dispatch(setAllMeetings(response.data.appointments));
    dispatch(setProgress(false));

    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setProgress(false));

    dispatch(setErrors(err));
  }
};
export const getAllMeetingsOfUserHotstarAdmin = (id) => async (dispatch) => {
  dispatch(setProgress(true));
  try {
    let response = await instanceHotstar({
      method: "get",
      url: `/api/user-meetings-list-admin/${id}`,
    });
    // const meetings = filterPastMeetings(response.data.results);
    dispatch(setAllMeetings(response.data.appointments));
    dispatch(setProgress(false));

    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setProgress(false));

    dispatch(setErrors(err));
  }
};
export const getAllMeetingsOfUserDpcgcAdmin = (id) => async (dispatch) => {
  dispatch(setProgress(true));
  try {
    let response = await instanceDPCGC({
      method: "get",
      url: `/api/user-meetings-list-admin/${id}`,
    });
    // const meetings = filterPastMeetings(response.data.results);
    dispatch(setAllMeetings(response.data.appointments));
    dispatch(setProgress(false));

    return response.data;
  } catch (err) {
    console.log(err);
    dispatch(setProgress(false));

    dispatch(setErrors(err));
  }
};
