import React, { useState } from 'react';
import {
    Box,
    Paper,
    Grid,
    makeStyles,
    Button,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
    Typography,
    Snackbar

} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { updateDispute, uploadDispute } from "slices/disputeSlice";
import { useDispatch } from "react-redux";
import { downloadLegalDoc } from "slices/disputeSlice";
import { formatDate } from "utils";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneDialog } from "material-ui-dropzone";
import instance from 'api/globalaxios';
import Clients from './UserDetails/Clients';
import GeneralUsers from './UserDetails/GeneralUsers';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: "2rem",
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 0.5rem",
        },
    },
    heading: {
        fontSize: "24px",
        lineHeight: "28px",
        letterSpacing: "0.3px",
        color: "#252733",
        marginLeft: "20px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
        },
    },
    username: {
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.2px",
        color: "#252733",
    },
    paper: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
        borderRadius: "8px",
        cursor: "pointer",

    },
    text: {
        fontSize: "16px",
        lineHeight: "36px",
        color: "#2C2C2C",


    }
}));

export default function UserDetails({ profile }) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    let t = sessionStorage.getItem('userDetails');

    const [showClients, setShowClients] = useState(t=='clients'?true:false);
    const [showGeneralUsers, setShowGeneralUsers] = useState(t=='generalusers'?true:false);

    if (showClients || showGeneralUsers) {
        if (showClients) {
            return (
                <Clients profile={profile} setShowClients={setShowClients}/>
            )
        }
        else {
            return (
                <GeneralUsers profile={profile} setShowGeneralUsers={setShowGeneralUsers}/>
            )
        }
    }
    else {
        return (
            <div className={classes.root}>
                <Grid container justify="flex-start" direction="column">
                    <Grid item>
                        <Grid container justify="space-between" direction="row" style={{padding:"10px"}}>
                            <Grid item>
                                <Typography className={classes.heading} >User Details</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.username} >{profile.user.first_name}{" "}{profile.user.last_name}</Typography>
                            </Grid>
                        </Grid>
                        <Box height="5rem"></Box>
                        <Grid container style={{ marginLeft: "20px" }} justify="flex-start" spacing={4}>
                            <Grid item md={2} sm={3} xs={7}>
                                <Paper className={classes.paper} onClick={(e) => { setShowClients(true);sessionStorage.setItem('userDetails','clients') }}>
                                    <Typography className={classes.text}>Clients</Typography>
                                </Paper>
                            </Grid>
                            <Grid item md={2} sm={3} xs={7}>
                                <Paper className={classes.paper} onClick={(e) => { setShowGeneralUsers(true);sessionStorage.setItem('userDetails','generalusers') }}>
                                    <Typography className={classes.text}>General Users</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}