import { isArbitrationConference, isDifc } from "Routes/Routes";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getActivities } from "slices/activitySlice";
import { getDisputesForPagination } from "slices/disputeSlice";
import DisputesRvTiac from "./DisputesRvTiac";

export default function DisputesRvTiacContainer({ profile }) {
  const dispatch = useDispatch();

  const [disputesActiveClaimant, setDisputesActiveClaimant] = useState([]);
  const [disputesResolvedClaimant, setDisputesResolvedClaimant] = useState([]);
  const [disputesActiveRespondent, setDisputesActiveRespondent] = useState([]);
  const [disputesResolvedRespondent, setDisputesResolvedRespondent] = useState(
    []
  );
  const [disputesActiveArbitrator, setDisputesActiveArbitrator] = useState([]);
  const [disputesResolvedArbitrator, setDisputesResolvedArbitrator] = useState(
    []
  );
  const [allActivities, setallActivities] = useState([]);

  React.useEffect(() => {
    let active = disputesActiveClaimant.concat(disputesActiveRespondent);
    let resolved = disputesResolvedClaimant.concat(disputesResolvedRespondent);
    active.sort(function (a, b) {
      var nameA = a.id;
      var nameB = b.id;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    resolved.sort(function (a, b) {
      var nameA = a.id;
      var nameB = b.id;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesActiveArbitrator(active);
    setDisputesResolvedArbitrator(resolved);
  }, [
    disputesActiveClaimant,
    disputesResolvedClaimant,
    disputesActiveRespondent,
    disputesResolvedRespondent,
  ]);

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading5, setLoading5] = useState(true);
  const [loading6, setLoading6] = useState(true);
  const [loading7, setloading7] = useState(true);

  useEffect(() => {
    if (isDifc || isArbitrationConference) {
      if (
        ["arbitrator_rv", "mediator", "witness_rv", "expert_rv"].includes(profile.profile_type)
      ) {
        dispatch(getDisputesForPagination(1, "active", "")).then((res) => {
          let results = [...res.results];
          if (isDifc || isArbitrationConference) results.reverse();
          setDisputesActiveArbitrator(results);
          setLoading6(false);
        });
        dispatch(getDisputesForPagination(1, "resolved", "")).then((res) => {
          let results = [...res.results];
          if (isDifc || isArbitrationConference) results.reverse();
          setDisputesResolvedArbitrator(results);
          setloading7(false);
        });
      } else {
        dispatch(getDisputesForPagination(1, "active", "claimant")).then(
          (res) => {
            setDisputesActiveClaimant(res.results);
            setLoading1(false);
          }
        );
        dispatch(getDisputesForPagination(1, "resolved", "claimant")).then(
          (res) => {
            setDisputesResolvedClaimant(res.results);
            setLoading2(false);
          }
        );
        dispatch(getDisputesForPagination(1, "active", "respondent")).then(
          (res) => {
            setDisputesActiveRespondent(res.results);
            setLoading3(false);
          }
        );
        dispatch(getDisputesForPagination(1, "resolved", "respondent")).then(
          (res) => {
            setDisputesResolvedRespondent(res.results);
            setLoading4(false);
          }
        );
      }
    } else {
      if (
        ["arbitrator_rv", "mediator", "witness"].includes(profile.profile_type)
      ) {
        dispatch(getDisputesForPagination(1, "active", "")).then((res) => {
          setDisputesActiveArbitrator(res.results);
          setLoading6(false);
        });
        dispatch(getDisputesForPagination(1, "resolved", "")).then((res) => {
          setDisputesResolvedArbitrator(res.results);
          setloading7(false);
        });
      } else {
        dispatch(getDisputesForPagination(1, "active", "claimant")).then(
          (res) => {
            setDisputesActiveClaimant(res.results);
            setLoading1(false);
          }
        );
        dispatch(getDisputesForPagination(1, "resolved", "claimant")).then(
          (res) => {
            setDisputesResolvedClaimant(res.results);
            setLoading2(false);
          }
        );
        dispatch(getDisputesForPagination(1, "active", "respondent")).then(
          (res) => {
            setDisputesActiveRespondent(res.results);
            setLoading3(false);
          }
        );
        dispatch(getDisputesForPagination(1, "resolved", "respondent")).then(
          (res) => {
            setDisputesResolvedRespondent(res.results);
            setLoading4(false);
          }
        );
      }
    }

    dispatch(getActivities()).then((res) => {
      setallActivities(res);
      setLoading5(false);
    });
  }, [profile]);

  if (
    [
      "arbitrator_rv",
      "mediator",
      "witness",
      "witness_rv",
      "expert_rv",
    ].includes(profile.profile_type)
  ) {
    if (loading5 || loading6 || loading7) return <CircularCenteredLoader />;
    return (
      <DisputesRvTiac
        profile={profile}
        allActivities={allActivities}
        disputesActiveArbitrator={disputesActiveArbitrator}
        disputesResolvedArbitrator={disputesResolvedArbitrator}
      />
    );
  }

  if (loading1 || loading2 || loading3 || loading4 || loading5)
    return <CircularCenteredLoader />;
  return (
    <DisputesRvTiac
      profile={profile}
      disputesActiveClaimant={disputesActiveClaimant}
      disputesResolvedClaimant={disputesResolvedClaimant}
      disputesActiveRespondent={disputesActiveRespondent}
      disputesResolvedRespondent={disputesResolvedRespondent}
      disputesActiveArbitrator={disputesActiveArbitrator}
      disputesResolvedArbitrator={disputesResolvedArbitrator}
      allActivities={allActivities}
    />
  );
}
