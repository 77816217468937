import React, { useReducer } from "react";
import DetailsForm from "features/singleDisputeRv/DetailsForm";
import UserVerifyForm from "features/singleDisputeRv/UserVerifyForm";

const initialState = {
  fetched: false,
  profileDate: {},
  empty: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set":
      return { ...state, fetched: true, profileData: action.payload };
    case "notEmpty":
      return { ...state, empty: false };
  }
};

export default function Details({
  activeDispute,
  caseFileType,
  rvStatus,
  profileType,
  activeCase,
  step
}) {

  console.log('step',step)
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fetched, profileData, empty } = state;

  const profileFetched = (data) => {
    dispatch({ type: "set", payload: data });
  };

  const setNotEmpty = () => {
    dispatch({ type: "notEmpty" });
  };

  const formProps = {
    activeDispute,
    caseFileType,
    rvStatus,
    profileData,
    empty,
    profileType,
    activeCase,
    step
  };

  console.log("here", fetched);

  if (fetched) return <DetailsForm {...formProps} />;
  else
    return (
      <UserVerifyForm
        profileFetched={profileFetched}
        setNotEmpty={setNotEmpty}
        rvStatus={rvStatus}
        step={step}
      />
    );
}
