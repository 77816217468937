import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { StyledTextFieldTiac } from "features/TempTiacFormV2/ClaimantPage/questions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAvailableProfilesTIAC } from "./CreateAccount";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    padding: "1.5rem 2rem",
    borderRadius: "8px",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  headingNew: {
    fontSize: "16px",
    lineHeight: "36px",
    display: "flex",
    alignItems: "center",
    texttransform: "capitalize",
    color: "#4F4F4F",
  },
  bigbtn: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#FFFFFF",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  box: {
    cursor: "pointer",
    padding: "0.7rem",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
  box2: {
    cursor: "pointer",
    padding: "0.7rem",
  },
  profile: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#25213B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
}));

export default function Calender({ profile }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [profileType, setProfileType] = useState("");
  const profiles = getAvailableProfilesTIAC(profile);
  const [loading2, setLoading2] = useState(false);
  const [receivedData, setReceivedData] = useState([]);

  const onFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onProfileChange = (e) => {
    setProfileType(e.target.value);
  };
  const onSubmit = () => {
    // if (firstname == "" || email == "" || profileType == "") {
    if (firstname == "" && email == "" && profileType == "") {
      alert("enter someting");
    } else {
      dispatch(getUserFilesOnCondition()).then((resWebnyay) => {
        setReceivedData(resWebnyay);
        setLoading2(false);
      });
    }
    // }
    // else {
    //     dispatch(getUserFiles());
    // }
  };

  // React.useEffect(() => {
  //     dispatch(getCompaniesAndProfiles());
  // }, [])

  function getUserFilesOnCondition() {
    return async () => {
      try {
        setLoading2(true);
        if (firstname == "" && email == "" && profileType != "") {
          const response = await instance.get(
            `/api/userappointmentprofilelist/${null}/${null}/${profileType}`
          );
          return response.data.profiles;
        } else if (firstname == "" && email != "" && profileType == "") {
          const response2 = await instance.get(
            `/api/userappointmentprofilelist/${null}/${email}/${null}`
          );
          return response2.data.profiles;
        } else if (firstname != "" && email == "" && profileType == "") {
          const response3 = await instance.get(
            `/api/userappointmentprofilelist/${firstname}/${null}/${null}`
          );
          return response3.data.profiles;
        } else if (firstname == "" && email != "" && profileType != "") {
          const response4 = await instance.get(
            `/api/userappointmentprofilelist/${null}/${email}/${profileType}`
          );
          return response4.data.profiles;
        } else if (firstname != "" && email == "" && profileType != "") {
          const response5 = await instance.get(
            `/api/userappointmentprofilelist/${firstname}/${null}/${profileType}`
          );
          return response5.data.profiles;
        } else if (firstname != "" && email != "" && profileType == "") {
          const response6 = await instance.get(
            `/api/userappointmentprofilelist/${firstname}/${email}/${null}`
          );
          return response6.data.profiles;
        } else {
          const response7 = await instance.get(
            `/api/userappointmentprofilelist/${firstname}/${email}/${profileType}`
          );
          return response7.data.profiles;
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" direction="column">
        <Grid item>
          <Grid
            container
            justify="space-between"
            direction="row"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography className={classes.heading}>Calendar</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.username}>
                {profile.user.first_name} {profile.user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Box height="5rem"></Box>
          <Grid container justify="center" direction="row" spacing={4}>
            <Grid item lg={5} md={6} sm={7} xs={11}>
              <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                  <Grid item>
                    <Typography className={classes.headingNew}>
                      Find Calendar for
                    </Typography>
                  </Grid>
                </Grid>
                <Box height="1rem"></Box>
                <Grid
                  container
                  justify="space-evenly"
                  spacing={3}
                  direction="column"
                >
                  <Grid item sm={12} xs={12}>
                    <StyledTextFieldTiac
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      fullWidth={true}
                      value={firstname}
                      onChange={onFirstNameChange}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <StyledTextFieldTiac
                      id="email"
                      name="email"
                      label="Email ID"
                      variant="outlined"
                      fullWidth={true}
                      value={email}
                      onChange={onEmailChange}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <FormControl
                      fullWidth={true}
                      variant="outlined"
                      disabled={profiles.length === 0}
                    >
                      <InputLabel id="against">Profile type</InputLabel>
                      <Select
                        id="profile_type"
                        name="profile_type"
                        value={profileType}
                        onChange={onProfileChange}
                        label="Profile type"
                      >
                        {profiles.map((profile) => (
                          <MenuItem key={profile.type} value={profile.type}>
                            {profile.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box height="2rem"></Box>
                <Button
                  variant="contained"
                  style={{ background: "#2F80ED" }}
                  size="large"
                  className={classes.bigbtn}
                  onClick={onSubmit}
                >
                  Search
                </Button>
              </Paper>
            </Grid>
            {receivedData.length > 0 && (
              <Grid item lg={5} md={6} sm={7} xs={11}>
                {loading2 ? (
                  <CircularCenteredLoader />
                ) : (
                  <>
                    {receivedData.length > 0 && (
                      <Paper
                        style={{
                          background: "none",
                          border: "none",
                          boxShadow: "none",
                        }}
                      >
                        <Box className={classes.box2}>
                          <Grid container justify={"space-between"}>
                            <Grid item>
                              <Typography className={classes.profile}>
                                Name
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.profile}>
                                Email
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    )}
                    <Paper>
                      {receivedData.map((item) => {
                        return (
                          <Box
                            className={classes.box}
                            onClick={() => {
                              history.push(`/events/${item.id}`);
                            }}
                          >
                            <Grid container justify={"space-between"}>
                              <Grid item>
                                <Typography className={classes.profile}>
                                  {item.user.first_name} {item.user.last_name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography className={classes.profile}>
                                  {item.user.email}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </Paper>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
