import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import instance from "api/globalaxios";
import Axios from "axios";
import FormattedMessage from "features/common/TranslatedMessage";
import {
  getProfileTiacDispute,
  getTempDetails,
} from "features/TempTiacFormV3/ClaimantPage/questions";
import { updateDisputeTiac } from "features/TempTiacFormV3/UploadPage/questions";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCountries } from "slices/profileSlice";
import {claimantPageConstants} from '../constants/index';
import ConfigRow from '../constants/ConfigRow';
import getFieldValue from "../constants/getFieldValue";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },
  select: {
    "&:after": {
      borderBottomColor: "#2F80ED",
    },
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // "font-size": "18px",
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    color: "#2C3345",
    padding: "1rem",
    fontFamily: `'Inter',sans-serif`,
  },

  text2: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },
  text3: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

var signUpData = {};
export default function RespondentPage({ setActiveStep, submitDetailsSignal }) {
  const [fieldsInput, setFieldsInput] = useState({});
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();
  const {RESPONDENT_DETAILS_PAGE} = claimantPageConstants;

  function getProfiles(email, cancelToken) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/tiacanonymousemailautofill/?email=${email}`,
          { cancelToken: cancelToken.token }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const getAutofillProfiles = (email) => {
    try {
      // let email = e.traget.value;
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email &&
          res.results[0].profile_type == "general_rv"
        ) {
          let profile = res.results[0];
          profile["name"] =
            res.results[0].user.first_name +
            " " +
            res.results[0].user.last_name;
          setFetchedProfile(profile);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = useCallback(debounce(getAutofillProfiles, 500), []);

  const classes = useStyles();

  let history = useHistory();
  const dispatch = useDispatch();

  const [allCountries, setallCountries] = useState([]);
  const [country, setCountry] = useState("-");
  const [countryError, setcountryError] = useState();

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  let id = localStorage.getItem("dispute_id");
  let localstorageEmail = localStorage.getItem("email");
  let language = localStorage.getItem("i8_lang");
  const [dispute, setDispute] = useState();

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  useEffect(() => {
    getTempDetails(id).then((res) => {
      let fetchTempProfile = {};
      if (res && res.data) {
        setFieldsInput(res.data);
      }
    });
  }, [id]);

  React.useEffect(() => {
    getDisputeTiac(localstorageEmail, id)
      .then((res) => {
        if (res.data.results[0].id) {
          setDispute(res.data.results[0]);
          // if (res.data.results[0].rv_respondent_choose_step_complete)
          getProfileTiacDispute(localstorageEmail, id, "respondent").then(
            (res) => {
              if (res.data.user) {
                // setFetchedProfile(res.data);
                // autoFill(res.data);
              }
            }
          );
        } else history.push("/RFA2");
      })
      .catch((e) => {
        history.push("/RFA2");
      });
  }, []);

  async function saveTempDetailsRespondent(data) {
    return await instance.post(`/tiacsavetempdetails/`, {
      dispute_id: data.dispute_id,
      respondent_email: data.respondent_email,
      respondent_name: data.respondent_name,
      respondent_nationality: data.respondent_nationality,
      respondent_address: data.respondent_address,
      respondent_city: data.respondent_city,
      respondent_region: data.respondent_region,
      respondent_postal_code: data.respondent_postal_code,
      respondent_country: data.respondent_country,
      respondent_phone_number: data.respondent_phone_number,
      respondent_fax: data.respondent_fax,
    });
  }

  useEffect(() => {
    if (submitDetailsSignal) {
      let {
        respondent_name,
        respondent_nationality,
        respondent_address,
        respondent_city,
        respondent_region,
        respondent_postal_code,
        respondent_phone_number,
        respondent_email,
        respondent_fax,
      } = fieldsInput;
      let tempData = {
        dispute_id: id,
        email: respondent_email,
        full_name: respondent_name,
        nationality: respondent_nationality,
        address: respondent_address,
        city: respondent_city,
        region: respondent_region,
        postal_code: respondent_postal_code,
        country: country,
        phone_number: respondent_phone_number,
        fax: respondent_fax,
      };
      respondent_phone_number = "" + respondent_phone_number;
      if (
        respondent_email?.trim() == "" ||
        respondent_name?.trim() == "" ||
        respondent_nationality?.trim() == "" ||
        respondent_address?.trim() == "" ||
        respondent_city?.trim() == "" ||
        respondent_region?.trim() == "" ||
        respondent_postal_code?.trim() == "" ||
        country == "-" ||
        respondent_phone_number?.trim() == ""
      ) {
        updateDisputeTiac(
          {
            id: id,
            rv_respondent_choose_step_complete: false,
          },
          localstorageEmail
        ).then((resp) => {
          saveTempDetailsRespondent(tempData).then(() => {
            history.push("/resumerfa2?random=" + Math.random());
          });
        });
      } else {
        saveTempDetailsRespondent(tempData).then(() => {
          history.push("/resumerfa2?random=" + Math.random());
        });
      }
    }
  }, [submitDetailsSignal]);

  function checkFieldError(fieldName, condition, errorText, hasError) {
    if (condition) {
        setFieldsInput((prevFieldsInput) => ({
            ...prevFieldsInput,
            error: {
                ...prevFieldsInput.error,
                [fieldName]: errorText,
            }
        }));
        return [...hasError, fieldName]
    }

    return hasError;
  }

  function handleSubmitDetails(){

    //field validation
    let hasError = [];
    hasError = checkFieldError("respondent_email", !fieldsInput.respondent_email, "Invalid email", hasError);
    hasError = checkFieldError("respondent_name", !fieldsInput.respondent_name, "Full name is required", hasError);
    hasError = checkFieldError("respondent_nationality", !fieldsInput.respondent_nationality, "Nationality is required", hasError);
    hasError = checkFieldError("respondent_address", !fieldsInput.respondent_address, "Apartment and Street is required", hasError);
    hasError = checkFieldError("respondent_city", !fieldsInput.respondent_city, "City is required", hasError);
    hasError = checkFieldError("respondent_region", !fieldsInput.respondent_region, "Region and State is required", hasError);
    hasError = checkFieldError("respondent_postal_code", !fieldsInput.respondent_postal_code, "Postal code is required", hasError);
    hasError = checkFieldError("respondent_phone_number", !fieldsInput.respondent_phone_number, "Phone Number is required", hasError);
    hasError = checkFieldError("respondent_country", !fieldsInput.respondent_country, "Country is required", hasError);

    if (hasError.length) return false;

    let updatedData = {
      "dispute_id": id,
      "respondent_email": fieldsInput.respondent_email,
      "respondent_name": fieldsInput.respondent_name,
      "respondent_nationality": fieldsInput.respondent_nationality,
      "respondent_address": fieldsInput.respondent_address,
      "respondent_city": fieldsInput.respondent_city,
      "respondent_region": fieldsInput.respondent_region,
      "respondent_postal_code": fieldsInput.respondent_postal_code,
      "respondent_phone_number": fieldsInput.respondent_phone_number,
      "respondent_fax": fieldsInput.respondent_fax,
      "respondent_country": fieldsInput.respondent_country,
    }
    // if (country == "-") {
    //   setcountryError(<FormattedMessage id="tiac.requiredcountry" />);
    // } else {
      updateDisputeTiac(
        {
          id: id,
          rv_respondent_choose_step_complete: true,
        },
        localstorageEmail
      ).then(() => {
        saveTempDetailsRespondent(updatedData).then(() => {
          setActiveStep(3);
        });
      });
    // }
  }

  function handleFieldChange(value, name) {
    setFieldsInput((prevFieldsInput) => ({
        ...prevFieldsInput,
        ...(getFieldValue(value, fieldsInput, name))
    }));
  }

  return (
    <>
    <ConfigRow
      fieldsInput={fieldsInput}
      cards={RESPONDENT_DETAILS_PAGE(allCountries)}
      onChange={handleFieldChange}
      classes={classes}
    />
    <Grid container justify="center" spacing={3}>
      <Grid item>
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              style={{ background: "#2F80ED" }}
              onClick={handleSubmitDetails}
            >
              <Typography className={classes.btnTextWhite}>
                <FormattedMessage id="buttons.proceed" />
              </Typography>
            </Button>
          </Box>
        </Grid>
    </Grid>
    </>
  );
}
