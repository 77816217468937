import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Icon1 from "images/icon1.svg";
import Icon2 from "images/icon2.svg";
import Icon3 from "images/icon3.svg";
import Icon4 from "images/icon4.svg";
import Icon5 from "images/icon5.svg";
import Icon6 from "images/icon6.svg";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f0f0f0",
    [theme.breakpoints.down("xs")]: {
      padding:"10px",
    },
  },
  featured: {
    color: "#70c8ce",
    fontSize: "55px",
    fontWeight: "bolder",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  text: {
    color: "#333333",
    fontSize: "15px",
    lineHeight: "20px",
    margin: "10px 0",
    padding: "0 20px",
  },
}));


const differencesData = [
  {
    icon: Icon1,
    content: `Our dedicated in-house legal
    and technical team has years
    of experience `,
  },
  {
    icon: Icon2,
    content: `Bespoke platform to suit your needs with no requirement to install any program or software
    `,
  },
  {
    icon: Icon3,
    content: `We are secure and comply with the various data privacy regimes across the world
    `,
  },
  {
    icon: Icon4,
    content: `Two layers of security
    authentication
    `,
  },
  {
    icon: Icon5,
    content: `Our remote access solutions
    are proven and tested
    
    `,
  },
  {
    icon: Icon6,
    content: `User friendly and impartial
  
    `,
  },
];

export default function WhatSetsUsApart() {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item sm={9} xs={10}>
        <Box height="3rem" />
        <Grid container>
          <Grid item>
            <Typography className={classes.featured}>
              What Sets Us Apart
            </Typography>{" "}
          </Grid>
        </Grid>
        <Box height="1rem" />
        <Grid container justify="center">
          {differencesData.map((data) => {
            return (
              <Grid item md={4} sm={6} xs={12}>
                <Grid
                  contianer
                  justify="center"
                  alignItems="center"
                  direction="column"
                  style={{ padding: "27px" }}
                >
                  <Grid item style={{ textAlign: "center" }}>
                    <img src={data.icon} height="60px" alt="" />
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    {" "}
                    <Typography className={classes.text}>
                      {data.content}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box height="1rem" />
      </Grid>
    </Grid>
  );
}
