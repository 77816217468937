import { makeStyles } from "@material-ui/core";

const drawerWidth = 300;
const docWidth = 300;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "90vh",
    borderRadius: "1rem",
    flexDirection: (props) => (props.smallScreen ? "column" : "row"),
  },
  drawer: {
    width: (props) => (props.smallScreen ? "100%" : drawerWidth),
    borderRight: `0.1rem solid ${theme.palette.border}`,
    height: "100%",
    overflowY: "scroll",
  },
  selected: {
    background: "rgba(0, 0, 0, 0.04)",
  },
  // item: {
  //   "&:hover": {
  //     background: blue["700"],
  //   },
  // },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%"
  },
  rightDoc: {
    borderLeft: `0.1rem solid ${theme.palette.border}`,
    height: "100%",
    minWidth: 300,
    width: (props) => (props.smallScreen ? "100%" : 300),
    overflowY: "scroll",
  },
  greyColor: {
    color: theme.palette.common.grey1,
  },
  vcModeContainer: {
    display: "flex",
  },
  recordingFilesWrapper: {
    display: 'flex',
    background: '#F8F8F8',
    height: '46px',
    marginTop: '24px',
    alignItems: 'center'
  },
  isSelectedClassRecordingFiles: {
    color: '#4A3BFF',
    fontSize: '12px',
    fontWeight: '700',
    height: '38px',
    background: '#FFF',
    width: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  notSelectedClassRecordingFiles: {
    color: '#64748B',
    fontSize: '12px',
    fontWeight: '700',
    height: '38px',
    width: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  vcBox: {
    color: '#707C97',
    cursor: 'pointer',
    height: '43px',
    margin: '10px',
    display: 'flex',
    fontSize: '14px',
    background: '#fff',
    alignItems: 'center',
    paddingLeft: '14px',
    justifyContent: 'space-between'
  }
}));
