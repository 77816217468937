import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import ReactApexChart from 'react-apexcharts';





export default function BarChart() {
	const yAxisSeries = {
		trading_member: [{
			name: '1 Day',
			type: 'column',
			data: [10, 35, 80]
		}, {
			name: '30 Days',
			type: 'column',
			data: [15, 40, 90]
		}, {
			name: '60 Days',
			type: 'column',
			data: [18, 45, 70]
		}, {

			name: '90 Days',
			type: 'column',
			data: [7, 50, 85]
		},
		],
		relationship: [{
			name: 'Closed',
			type: 'column',
			data: [1400, 2000, 2500, 1500, 2500, 2800]
		}, {
			name: 'Pending',
			type: 'column',
			data: [1100, 2000, 2100, 400, 2100, 2900]
		}, {
			name: 'New',
			type: 'column',
			data: [1400, 2000, 2500, 1500, 2500, 2800]
		},
		{
			name: 'Drafts',
			type: 'column',
			data: [1400, 2000, 2500, 1500, 2500, 2800]
		},
		{
			name: 'Rejected',
			type: 'column',
			data: [1400, 2000, 2500, 1500, 2500, 2800]
		}],
		claim_amount: [{
			name: 'Closed',
			type: 'column',
			data: [1300, 2100, 2500, 1500, 2600, 2700]
		}, {
			name: 'Pending',
			type: 'column',
			data: [1200, 3000, 2200, 400, 3100, 3900]
		}, {
			name: 'New',
			type: 'column',
			data: [2400, 2000, 2500, 2500, 2500, 2800]
		},
		{
			name: 'Drafts',
			type: 'column',
			data: [2400, 2000, 2500, 2500, 2500, 2800]
		},
		{
			name: 'Rejected',
			type: 'column',
			data: [2400, 2000, 2500, 2500, 2500, 2800]
		}]
	}
	const xAxisCategories = {
		nature: ['P1', 'P2', 'P3'],
		relationship: ['Investor', 'Sub-Broker', 'Authorized Person'],
		claim_amount: ['> Rs 50,000', 'Rs 50,000 - Rs 5,00,000', 'Rs 5,00,000 - Rs 10,00,000', 'Rs 20,00,000 and above'],
	}

	const [series, setSeries] = useState(yAxisSeries.trading_member);
	const [xseries, setXSeries] = useState(xAxisCategories.nature);
	const [tickamount, setTickAmount] = useState(5);


	const chartData = {
		options: {
			chart: {
				type: 'bar',
				height: 200,
				stacked: false,
				toolbar: {
					show: false
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				// curve: 'straight',
				width: 1,
			},
			colors: ["#9EF3B1", "#81F5FD", "#61A0FF", "#EEFF85", "#FF665C"],
			xaxis: {
				categories: xseries,
				axisTicks: {
					show: false,
				},
				axisBorder: {
					show: false,
				}
			},
			yaxis: [
				{
					tickAmount: tickamount,
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
						color: '#4F4F4F'
					},
					labels: {
						align: 'right',
						// minWidth: 0,
						// maxWidth: 160,
						style: {
							colors: '#4F4F4F',
							cssClass: 'apexcharts-yaxis-label',
						},
						offsetX: 0,
						offsetY: 0,
						rotate: 0,
					},
					tooltip: {
						enabled: false
					}
				}
			],
			tooltip: {
				enabled: true,
				// fixed: {
				//   enabled: true,
				//   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				//   offsetY: 30,
				//   offsetX: 60
				// },
			},
			legend: {
				show: true,
				horizontalAlign: 'left',
				offsetX: 75,
				offsetY: -10
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -20,
						offsetY: 0
					}
				}
			}],
			grid: {
				show: true,
				borderColor: '#90A4AE',
				strokeDashArray: 2,
				xaxis: {
					lines: {
						show: true
					}
				},
				yaxis: {
					lines: {
						show: true
					}
				},
				row: {
					colors: undefined,
					opacity: 0.5
				},
				column: {
					// colors: undefined,
					colors: ['#e5e5e5', 'transparent'],
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 10
				}
			}
		},
	}
	return (
		<Box id="chart" style={{ margin: '15px auto' }}>
			<ReactApexChart
				options={chartData.options}
				series={series}
				type="bar"
				height={450}
			/>
		</Box>
	)
}
