import { Box, Grid, IconButton, Menu, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import FolderImage from "images/folder.svg";
import DotsImage from "images/threeDots.svg";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import { deleteFolder, UpdateFolder } from "./FileApi";
import EditPermissionsFolder from "./EditPermissionsFolder";
import { format } from "date-fns";

export default function FolderView({
  folder,
  disputeId,
  classes,
  reload,
  profile,
  allProfiles,
  parent_folder,
  user_id,
}) {
  const history = useHistory();
  const [anchorElLocal, setAnchorElLocal] = useState(null);
  const handleClick = (e) => {
    setAnchorElLocal(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLocal(null);
  };

  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  useEffect(() => {
    if (
      folder &&
      folder.uploaded_by.id != profile.user.id &&
      folder.edit_permission.indexOf(profile.user.id) == -1
    ) {
      setButtonsEnabled(false);
    }
    if (
      profile.profile_type == "sub_admin_tiac_rv" ||
      profile.profile_type == "case_manager"
    ) {
      setButtonsEnabled(true);
    }
  }, []);

  const [openmodalUploadFolder, setOpenmodalUploadFolder] = useState(false);
  const handleOpenUploadFolderModal = () => {
    if (profile.user.id == folder.uploaded_by.id)
      setOpenmodalUploadFolder(true);
    else alert("only owner can edit permissions");
  };
  const handleCloseUploadFolderModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFolder(false);
    setAnchorElLocal(null);
  };
  const handleCloseUploadFolderModal = () => {
    setOpenmodalUploadFolder(false);
    setAnchorElLocal(null);
  };

  const [rename, setRename] = useState(false);
  const [name, setName] = useState(folder.name);
  const handleFolderNameChange = (e) => {
    setName(e.target.value);
  };
  const inputRef = useRef(null);
  const handleOnBlur = (e) => {
    setRename(false);
    if (folder.name != name) {
      UpdateFolder(folder.id, {
        name: name,
      }).then(() => {
        reload();
      });
    }
  };

  const handleRename = () => {
    if (buttonsEnabled) {
      handleClose();
      setRename(true);
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 100);
    } else {
      alert("You don't have edit permissions.");
    }
  };

  const handleDelete = () => {
    if (buttonsEnabled) {
      deleteFolder(folder.id).then(() => {
        reload();
      });
    } else {
      alert("You don't have edit permissions.");
    }
  };

  const handleOpenFolder = (id) => {
    if (disputeId) history.push(`/disputes/${disputeId}/files/${id}`);
    else history.push(`/files/user/${user_id}/${id}`);
  };

  return (
    <>
      <Grid
        container
        className={classes.filesHover}
        spacing={1}
        onDoubleClick={(e) => {
          handleOpenFolder(folder.id);
        }}
      >
        <Grid item lg={7} md={7} sm={7} xs={5}>
          <Typography
            style={{
              color: "#4F4F4F",
              fontWeigt: "500",
              fontFamily: `'Inter',sans-serif`,
              fontSize: "14px",
              wordBreak: "break-all",
            }}
          >
            <span>
              <img src={FolderImage} alt="folder" />
            </span>
            <span style={{ marginLeft: "1rem" }}>
              {!rename ? (
                name
              ) : (
                <input
                  ref={inputRef}
                  value={name}
                  style={{ width: "70%" }}
                  onChange={handleFolderNameChange}
                  onBlur={handleOnBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOnBlur();
                    }
                  }}
                />
              )}
            </span>
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            style={{
              color: "#4F4F4F",
              fontWeigt: "500",
              fontSize: "14px",
              fontFamily: `'Inter',sans-serif`,
              wordBreak: "break-all",
            }}
          >
            {folder.uploaded_by.first_name} {folder.uploaded_by.last_name}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            style={{
              color: "#4F4F4F",
              fontWeigt: "500",
              fontSize: "14px",
              fontFamily: `'Inter',sans-serif`,
              wordBreak: "break-all",
            }}
          >
            {/* {format(new Date(folder.modified), "d MMMM y, h:mm a")} */}
            {formatDate(folder.modified)}
          </Typography>
        </Grid>
        {/* <Grid item lg={1} md={1} sm={1} xs={1} style={{ textAlign: "right" }}>
          <IconButton
            onClick={handleClick}
            aria-controls={`three-dots-menu${folder.id}`}
            aria-haspopup="true"
            style={{ padding: 0 }}
          >
            <img src={DotsImage} alt="options" />
          </IconButton>
        </Grid> */}
      </Grid>
      {/* <Menu
        id={`three-dots-menu${folder.id}`}
        anchorEl={anchorElLocal}
        keepMounted
        open={Boolean(anchorElLocal)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleRename}
            >
              Rename
            </Typography>
            <Typography
              className={classes.filesandfolder}
              onClick={handleDelete}
            >
              Delete
            </Typography>
          </Box>
        </Box>
      </Menu> */}
      <EditPermissionsFolder
        openmodal={openmodalUploadFolder}
        handleCloseModal={handleCloseUploadFolderModal}
        handleCloseModalRefresh={handleCloseUploadFolderModalAndRefresh}
        folder={folder}
        allProfiles={allProfiles}
        parent_folder={parent_folder}
        disputeId={disputeId}
      />
    </>
  );
}
