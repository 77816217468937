import { makeStyles } from "@material-ui/core/styles";
import { CLAIMANT, RESPONDENT } from "app/constants";
import ClaimantDispute from "features/disputes/ClaimantDispute";
import RespondentDispute from "features/disputes/RespondentDispute";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isVikalp } from "Routes/Routes";
import { getCase } from "slices/casesSlice";
import { getDispute, getSplitBy } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  dispute_heading: {
    [theme.breakpoints.down("sm")]: { fontSize: "1.2rem" },
  },
  date_text: {
    [theme.breakpoints.down("sm")]: { fontSize: "0.8rem" },
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

const Dispute = ({ dispute, cases, profile }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };

  const split_by = getSplitBy()
  const [claimantName, respondentName] = dispute.name.split(split_by);

  //   let answer = answers.find((ans) => ans.question === question.id);

  let selectedCase = cases.find((cse) => cse.dispute === dispute.id);

  const disputeStatus = dispute.status === "complete" ? "Resolved" : "Active";

  // const onClick = async (e) => {
  //   if (selectedCase) {
  //     await dispatch(getDispute(dispute.id));
  //     dispatch(getCase(dispute.id)).then(
  //       (response) => {
  //         history.push(`disputes/${dispute.id}`);
  //       },
  //       (error) => {
  //         throw error;
  //       }
  //     );
  //   }
  // };

  const onClick = () => {
    if (isVikalp && profile.profile_type == "general") {
      sessionStorage.setItem("disputeId", dispute.id);
      sessionStorage.setItem("email", profile.user.email);
      history.push("/dispute");
    } else {
      history.push(`/disputes/${dispute.id}`);
    }
  };

  if (selectedCase && selectedCase.type === CLAIMANT) {
    return (
      <ClaimantDispute
        dispute={dispute}
        classes={classes}
        onClick={onClick}
        disputeStatus={disputeStatus}
        claimantName={claimantName}
        respondentName={respondentName}
      />
    );
  } else if (!selectedCase || selectedCase.type === RESPONDENT) {
    return (
      <RespondentDispute
        dispute={dispute}
        disputeStatus={disputeStatus}
        profile={profile}
        classes={classes}
        onClick={onClick}
        selectedCase={selectedCase}
        claimantName={claimantName}
        respondentName={respondentName}
      />
    );
  } else {
    return null;
  }
};

export default Dispute;
