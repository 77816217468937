import { Box, Button, colors, Grid, makeStyles, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useBodyBackgroundChange } from "hooks";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { backgrounds } from "contexts/BackgroundContext";
import { Chart } from "react-google-charts"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem",
    },
  },
  loanbox: {
    border: "1px solid #ebebeb",
    borderRadius: 8,
    width: "fit-content",
    padding: "1rem 4rem 1rem 1.6rem",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 3rem 0.5rem 1.2rem",
    },
  },
  loanid: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blackheading: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  transparentBox: {
    border: "1px solid #F2F2F2",
    borderRadius: 4,
    padding: "2rem 3rem",
    height: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 2rem",
    },
  },
  transparentBoxHeading: {
    color: "#454545",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  transparentBoxText: {
    color: "#454545",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  penImg: {
    marginLeft: "3rem",
    height: 16,
    [theme.breakpoints.down("sm")]: {
      height: 14,
    },
    [theme.breakpoints.down("xs")]: {
      height: 14,
      float: "right",
      marginLeft: 0,
    },
  },
  greybg: {
    background: "#F2F2F2",
    border: "1px solid #BDBDBD",
    borderRadius: 4,
    padding: "0.5rem 1rem 0.7rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.2rem 0.6rem 0.3rem 0.6rem",
    },
  },
  greytext: {
    color: "#4B5563",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  greybgHeading: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  blackHeading: {
    color: "#333333",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  draftBlack: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 16,
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  underlinedBlue: {
    color: "#1D4ED8",
    textDecoration: "underline",
  },
  viewbtn: {
    border: "1px solid #1D4ED8",
    textTransform: "none",
    padding: "5px 25px",
  },
  viewbtntext: {
    color: "#1D4ED8",
    fontFamily: `'Poppins',sans-serif`,
    fontWeight: 500,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  greentext: {
    color: "#047857",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  orangetext: {
    color: "#F2994A",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  closeBntText: {
    color: "white",
    fontFamily: `'Poppins',sans-serif`,
    fontWeight: 500,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  rootTable: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 550,
    maxWidth: '100%',
    border: '1px solid #e5e5e5',
    margin: 0,
  },
}));

const PaytmComplianceReport = () => {
  useBodyBackgroundChange(backgrounds.WHITE);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const data = [
    ["Status", "Count"],
    ['Complied', 6],
    ['In progress', 4],
    ['Not complied', 3],
  ];

  const tableData = [
    {
      guideline: 'Customer identification of “individuals”',
      status: "Complied",
      modifiedOn: "2020-04-01",
    },
    {
      guideline: "CDD Measures for Sole Proprietary firms",
      status: "In progress",
      modifiedOn: "2019-04-23",
    },
    {
      guideline: "Central KYC records Registry (CKYCR)",
      status: "Complied",
      modifiedOn: "2023-07-03",
    },
    {
      guideline: "Customer Acceptance Policy",
      status: "Not-complied",
      modifiedOn: "2019-05-29",
    },
    {
      guideline: "Risk Management",
      status: "In progress",
      modifiedOn: "2024-041-01",
    },
    {
      guideline: "Customer Identification Procedure (CIP)",
      status: "Complied",
      modifiedOn: "2021-09-20",
    },
    {
      guideline: "Customer Due Diligence Procedure",
      status: "Not-complied",
      modifiedOn: "2019-05-29",
    },
    {
      guideline: "On - going Due Dilegence",
      status: "In progress",
      modifiedOn: "2021-06-23",
    },
    {
      guideline: "Enhanced & Simplified Due Diligence",
      status: "In progress",
      modifiedOn: "2023-04-03",
    },
    {
      guideline: "Accounts of Politically Exposed Persons (PEPs)",
      status: "Not-complied",
      modifiedOn: "2019-05-29",
    },
    {
      guideline: "Record Management",
      status: "Complied",
      modifiedOn: "2019-08-15",
    },
    {
      guideline: "Requirements/obligations under International Agreements  Communications from International Agencies",
      status: "Complied",
      modifiedOn: "2019-08-15",
    },
    {
      guideline: "Other Instructions 56. 38 Secrecy Obligations and Sharing of Information",
      status: "Complied",
      modifiedOn: "2019-08-15",
    },

  ];

  const options = {
    title: "Compliance Status",
    colors: ['#1f6f6f','#54a1a1','#9fc8c8'],
  };

  const statuses = ["Complied", "In progress", "Not-complied"];

  function StatusSelect(currentStatus, onChange) {
    return (
      <Select style={{width:'100%'}} value={currentStatus} onChange={(e)=>{console.log(currentStatus)}} sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.MuiSelect-nativeInput':{border:0} }}>
        {statuses.map((status, index) => (
          <MenuItem key={index} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.loanbox}>
          <Typography className={classes.loanid}>RBI Circular/Direction</Typography>
          <Typography className={classes.blackheading}>RBI/DBR/2015-16/18</Typography>
        </Box>
        <Box className={classes.loanbox} style={{textAlign:'center', border:'none', margin:'16px auto'}}>
          <Typography className={classes.blackheading}>Master Direction - Amendent to Know Your Customer (KYC) Direction, 2016</Typography>
        </Box>
      </Box>
      <Box className={classes.transparentBox} style={{width:'80%'}}>
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width={"100%"}
            height={"400px"}
        />
      </Box>
      <div className={classes.rootTable}>
        <Typography variant="h4" className={classes.title}>
            Compliance Overview
        </Typography>
        <TableContainer>
            <Table className={classes.table} aria-label="compliance table">
            <TableHead>
                <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Guideline</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Modified On</TableCell>
                </TableRow>
            </TableHead>
            <TableBody style={{background:'white'}}>
                {tableData.map((row, index) => (
                <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell component="th" scope="row">
                    {row.guideline}
                    </TableCell>
                    <TableCell>{StatusSelect(row.status)}</TableCell>
                    <TableCell>{row.modifiedOn}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
      </div>
      <Box style={{height:'3rem'}}/>
      <Box>
        <Grid container justify="center" spacing={6}>
          <Grid
            item
          >
            <Button color="secondary" variant="contained" onClick={()=>{history.push('/paytm/compliances')}}>Go back</Button>
          </Grid>
          <Grid
            item
          >
            <Button color="primary" variant="contained">GENERATE COMPLIANCE REPORT</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PaytmComplianceReport;
