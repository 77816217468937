export const content = [
  {
    "Virtual Hearing Room":
      ` (a) Use Webnyaya’s secure, private and dedicated video conferencing solution (hosted on our cloud). No data is shared externally
      <br><br>
      (b) Use integrated Zoom VC – you do not require a Zoom subscription
      <br><br>
      (c) Communicate with messages, audio-video files and audio recordings. All communications are stored and searchable for the life of the case
      <br><br>
      (d) Record hearings and access them at any time
      <br><br>
      (e) Audit trail of all user actions and communications
`,
  },
  {
    "Breakout Rooms": `We will create more rooms upon request. The default rooms are:  
        <br><br>
        (a) parties and their counsels for privileged and confidential discussions
        <br><br>
        (b) Arbitral Tribunal (when there are three arbitrators)
        <br><br>(c) Arbitral Tribunal and Tribunal secretary / legal researchers
        <br><br>(d) Multiple Claimant(s)/ Respondent(s)
        <br><br>(e) Claimant(s) and Respondent(s) to discuss procedural issues
        <br><br>(f) solicitors and barristers or advocates and senior advocates
        <br><br> Each breakout room has all the functionality of the Virtual Hearing Room and operates as a separate digital room.`,
  },
  {
    "Automate creation of electronic hearing bundles":
`
(a) Convert MS word documents to PDF when uploaded
<br><br>
(b) One-click OCR functionality to make documents searchable
<br><br>(c) Paginate and stamp documents with unique references or tags
<br><br>(d) Hyperlink the bundle with in-built automations 
<br><br>(e) Automatically hyperlink the references in pleadings with evidence and contractual documents
<br><br>(f) Hyperlink witness statements, expert reports and other documents within the bundle
`,
  },
  {
    "Get organised with our Document Management System":
      ` (a) Store documents securely on Webnyaya’s cloud
      <br><br>
      (b) “Serve” documents on other parties, counsel, arbitrators and the arbitral institution with a single click
      <br><br>
      (c) Share documents with your colleagues, witnesses and experts
      <br><br>
      (d) Manage permissions and access to documents
      `,
  },
  {
    "Transcription Services":
     `
     (a) AI enabled transcription (automatic) in English
     <br><br>
     (b) Panel of trained transcribers for near real-time transcription in English
     <br><br>
     (c) Obtain transcriptions – in real-time, end-of-day and within 24 hours
     `,
  },
  {
    "Management Dashboard":
      `
      (a) Manage and track multiple disputes on one platform
      <br><br>
      (b) Customise the dashboard as per your liking with status updates, case updates, procedural timetable, upcoming deadlines and many more
      <br><br>
      (c) Cutting-edge data analytics and insights from disputes
      `,
  },
  {
    "Generate Procedural documents": 
    `    (a) Answer some simple questions and generate drafts of Terms of reference, procedural orders and a procedural timetable
    <br><br>
    (b) We have an ever-increasing database of procedural documents from leading arbitrations!
    <br><br>
    `,
  },
  {
    "IT Support on-call": `
    (a) We will be available to avoid technical distractions during hearings. 
    <br><br>
    (b) We will troubleshoot technical issues and glitches – in online, physical and hybrid hearings.
    <br><br>
    (c) We accommodate all time zones.`,
  },
  {
    "Remote assistance availability": `
    (a) Delegate administrative tasks to competent and trained junior lawyers (including cross-referencing of the bundle) and preparation of indices
    <br><br>
    (b) Experienced EPE (electronic presentation of evidence) operator to display and navigate the evidence during the hearings
    <br><br>
    (c) Reprographics support to arrange physical hearing bundles for in-person hearings.`,
  },
  {
    Calendar: `
    (a) Schedule hearings and meetings. Receive reminders on email and WhatsApp 
    <br><br>
    (b) View availability of others in the arbitration
    `,
  },
  {
    "Affix Digital Signatures": `
     Save paper and time by signing Awards, pleadings, applications, affidavits, witness statements, expert reports and other documents using legally recognised digital signature tools
      `,
  },
  {
    "Financial Management": `
    (a) Track arbitration costs with invoices, payment details and payment receipts
    <br><br>
    (b) Track legal and expert costs with invoices and payment details. Split costs between professional fees and disbursements
    <br><br>
    (c) Segregate fees from the government taxes and surcharges
    <br><br>
    (d) Milestone based cost tracker
    `,
  },
  {
    "Don’t worry about Security": `
    (a) We know data and documents are confidential so we take security seriously. 
    <br><br>
    (b) We regularly conduct security tests and store all data on AWS. We can provide on-premise solutions (where feasible).
    <br><br>
    (c) We follow ISO security standards and are conscious of legal ethics and confidentiality. 
  `,
  },
];
