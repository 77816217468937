import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CaseDisplay from "./SubAdminOverview/CaseDisplay";
import GrievanceRow from "./SubAdminOverview/GrievanceRow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  listheading: {
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  listtext: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textTransform: "capitalize",
    color: "#454545",
    paddingBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  listtextblue: {
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textTransform: "capitalize",
    color: "#3F51B5",
    paddingBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  headingtribunal: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.3px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  companylist: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
}));

export default function SubAdminOverview({
  profile,
  isSmallScreen,
  company: company_name,
  id,
  setOpenGrievanceRedressal,
  setOpenCDR,
  setOpenRV,
  setShowSubAdmin,
  openGrievanceRedressal,
  openCDR,
  openRV,
  mode,
  type,
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  let r = sessionStorage.getItem("subAdminInSuperAdminOverviewStep");
  let i = sessionStorage.getItem("caseid");

  const [loading, setLoading] = useState(true);
  const [loadingGrievances, setLoadingGrievances] = useState(false);
  const [openCase, setOpenCase] = useState(r ? true : false);
  const [caseId, setCaseId] = useState(i ? i : null);

  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [tribunalList, setTribunalList] = useState([]);
  const [secretariatList, setSecretariatList] = useState([]);
  const [grievanceList, setGrievanceList] = useState([]);
  // const [type, setType] = useState(true);
  // const [closedcompany, setClosedCompany] = useState("");
  // const [closedcompanyList, setClosedCompanyList] = useState([]);
  // const [closedtribunalList, setClosedTribunalList] = useState([]);
  // const [closedsecretariatList, setClosedSecretariatList] = useState([]);
  const [closedgrievanceList, setClosedGrievanceList] = useState([]);

  const [loadingclosedGrievances, setLoadingClosedGrievances] = useState(false);

  React.useEffect(() => {
    {
      type
        ? dispatch(apiFunction()).then((res) => {})
        : dispatch(apiClosedFunction()).then((res) => {});
    }
  }, []);

  function apiFunction() {
    return async (dispatch) => {
      try {
        let response;
        if (company_name && id) {
          response = await instance.get(`/api/disputeinfobycompany/${id}/`);
          let result = [];
          result.push(response.data.company_data);
          setCompany(company_name);
          setCompanyList(result);
          setTribunalList(response.data.arbitrator);
          setSecretariatList(response.data.secretariats);
        } else if (company_name) {
          if (company_name == "hotstar") {
            response = await instanceHotstar.post("/api/disputeinfobygroup/", {
              group: company_name,
            });
            setCompany(company_name);
            setCompanyList(response.data.company_data);
            setTribunalList(response.data.arbitrator);
            setSecretariatList(response.data.secretariats);
          } else if (company_name == "dpcgc") {
            response = await instanceDPCGC.post("/api/disputeinfobygroup/", {
              group: company_name,
            });
            setCompany(company_name);
            setCompanyList(response.data.company_data);
            setTribunalList(response.data.arbitrator);
            setSecretariatList(response.data.secretariats);
          } else {
            response = await instance.post("/api/disputeinfobygroup/", {
              group: company_name,
            });
            setCompany(company_name);
            setCompanyList(response.data.company_data);
            setTribunalList(response.data.arbitrator);
            setSecretariatList(response.data.secretariats);
          }

          // companyList.map((item) => {
          //   setType(item.close)
          // })
        }
        setLoading(false);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function apiClosedFunction() {
    return async (dispatch) => {
      try {
        let response;
        if (company_name && id) {
          response = await instance.get(
            `/api/closeddisputeinfobycompany/${id}/`
          );
          let result = [];
          result.push(response.data.company_data);
          setCompany(company_name);
          setCompanyList(result);
          setTribunalList(response.data.arbitrator);
          setSecretariatList(response.data.secretariats);
        } else if (company_name) {
          if (company_name == "hotstar") {
            response = await instanceHotstar.post(
              "/api/closeddisputeinfobygroup/",
              {
                group: company_name,
              }
            );
            setCompany(company_name);
            setCompanyList(response.data.company_data);
            setTribunalList(response.data.arbitrator);
            setSecretariatList(response.data.secretariats);
          } else if (company_name == "dpcgc") {
            response = await instanceDPCGC.post(
              "/api/closeddisputeinfobygroup/",
              {
                group: company_name,
              }
            );
            setCompany(company_name);
            setCompanyList(response.data.company_data);
            setTribunalList(response.data.arbitrator);
            setSecretariatList(response.data.secretariats);
          } else {
            response = await instance.post("/api/closeddisputeinfobygroup/", {
              group: company_name,
            });
            setCompany(company_name);
            setCompanyList(response.data.company_data);
            setTribunalList(response.data.arbitrator);
            setSecretariatList(response.data.secretariats);
          }
        }
        setLoading(false);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const loadGrievances = (id) => {
    setLoadingGrievances(true);
    if (company == "hotstar") {
      dispatch(apiFunction2Hotstar(id)).then((res) => {});
    } else if (company == "dpcgc") {
      dispatch(apiFunction2DpcgcAdmin(id)).then((res) => {});
    } else {
      dispatch(apiFunction2(id)).then((res) => {});
    }
  };

  function apiFunction2(id) {
    return async (dispatch) => {
      try {
        let response;
        response = await instance.get(`/api/disputebycompanyid/${id}/`);
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function apiFunction2Hotstar(id) {
    return async (dispatch) => {
      try {
        let response;
        response = await instanceHotstar.get(`/api/disputebycompanyid/${id}/`);
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function apiFunction2DpcgcAdmin(id) {
    return async (dispatch) => {
      try {
        let response;
        response = await instanceDPCGC.get(`/api/disputebycompanyid/${id}/`);
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const loadClosedGrievances = (id) => {
    setLoadingGrievances(true);
    if (company == "hotstar") {
      dispatch(apiClosedFunction2Hotstar(id)).then((res) => {});
    } else if (company == "dpcgc") {
      dispatch(apiClosedFunction2DpcgcAdmin(id)).then((res) => {});
    } else {
      dispatch(apiClosedFunction2(id)).then((res) => {});
    }
  };

  function apiClosedFunction2(id) {
    return async (dispatch) => {
      try {
        let response;
        response = await instance.get(`/api/closeddisputebycompanyid/${id}/`);
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function apiClosedFunction2Hotstar(id) {
    return async (dispatch) => {
      try {
        let response;
        response = await instanceHotstar.get(
          `/api/closeddisputebycompanyid/${id}/`
        );
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function apiClosedFunction2DpcgcAdmin(id) {
    return async (dispatch) => {
      try {
        let response;
        response = await instanceDPCGC.get(
          `/api/closeddisputebycompanyid/${id}/`
        );
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <div className={classes.root}>
        {!openCase ? (
          <Grid container justify="flex-start" direction="column">
            <Grid item>
              <Grid container justify="space-between" direction="row">
                <Grid item>
                  {company == "nbf" ? (
                    <Typography className={classes.heading}>
                      NBF (News Broadcasters Federation)
                    </Typography>
                  ) : (
                    <Typography
                      className={classes.heading}
                      style={{ textTransform: "capitalize" }}
                    >
                      {company}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box height="1rem"></Box>
              <Grid container justify="flex-start">
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    onClick={() => {
                      setShowSubAdmin(false);
                      sessionStorage.setItem("overviewStep", 2);
                      sessionStorage.removeItem("id");
                      sessionStorage.removeItem("company");
                      sessionStorage.removeItem("mode");
                    }}
                    className={classes.backbtn}
                    startIcon={<ArrowBackIosIcon />}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
              <Box height="2rem"></Box>
              {/* <Grid
                container
                justify="space-evenly"
                direction="row"
                alignItems="stretch"
                style={{ display: "flex",border:'1px solid blue' }}
                spacing={isSmallScreen() ? 1 : 3}
              >
                <Grid item md={6} sm={7} xs={11} style={{ flex: "1" }}></Grid>
                <Grid item md={6} sm={5} xs={10} style={{ flex: "1" }}>
                  <Grid container justify="space-between" spacing={2}>
                    <Grid item sm={3} xs={5}>
                      <Typography className={classes.headingtribunal}>
                        Tribunal
                      </Typography>
                    </Grid>
                    <Grid item sm={3} xs={5} className={classes.listheading}>
                      <Typography></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box height="1rem"></Box> */}
              <Grid
                container
                justify="space-evenly"
                direction="row"
                alignItems="stretch"
                style={{ display: "flex" }}
                spacing={isSmallScreen() ? 1 : 3}
              >
                <Grid
                  item
                  lg={6}
                  md={8}
                  sm={10}
                  xs={11}
                  style={{ paddingTop: "40px", display: "flex" }}
                >
                  <Paper
                    style={{
                      height: "auto",
                      width: "100%",
                      padding: "10px 20px",
                      wordWrap: "break-word",
                    }}
                  >
                    <Grid
                      container
                      justify="flex-start"
                      spacing={isSmallScreen() ? 0 : 2}
                    >
                      <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
                        <Typography className={classes.listheading}>
                          NAME
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
                        {type ? (
                          <Typography className={classes.listheading}>
                            OPEN GRIEVANCES
                          </Typography>
                        ) : (
                          <Typography className={classes.listheading}>
                            CLOSE GRIEVANCES
                          </Typography>
                        )}
                      </Grid>
                      <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
                        <Typography className={classes.listheading}>
                          GRO (NAME)
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box height="1rem"></Box>
                    {companyList.map((item) => {
                      return (
                        <Grid
                          key={item.company_id}
                          container
                          justify="flex-start"
                          spacing={isSmallScreen() ? 1 : 2}
                          className={classes.companylist}
                          onClick={(e) => {
                            {
                              type
                                ? loadGrievances(item.company_id)
                                : loadClosedGrievances(item.company_id);
                            }
                            sessionStorage.setItem(
                              "company_id",
                              item.company_id
                            );
                          }}
                        >
                          <Grid
                            item
                            sm={4}
                            xs={4}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listtext}>
                              {item.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={4}
                            xs={4}
                            style={{ textAlign: "left" }}
                          >
                            {type ? (
                              <Typography className={classes.listtext}>
                                {item.open}
                              </Typography>
                            ) : (
                              <Typography className={classes.listtext}>
                                {item.close}
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            sm={4}
                            xs={4}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listtext}>
                              {item.gro_name == "" ? "--" : item.gro_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Box height="1rem"></Box>
                  </Paper>
                </Grid>
                <Grid item lg={6} md={8} sm={10} xs={11}>
                  <Grid
                    container
                    direction="column"
                    style={{ display: "flex", height: "100%" }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: "1",
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid
                          item
                          sm={3}
                          xs={5}
                          style={{ textAlign: "start", padding: "0 20px" }}
                        >
                          <Typography className={classes.headingtribunal}>
                            Tribunal
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={3}
                          xs={5}
                          className={classes.listheading}
                        >
                          <Typography></Typography>
                        </Grid>
                      </Grid>
                      <Box height="1rem"></Box>
                      <Paper
                        style={{
                          height: "100%",
                          padding: "10px 20px",
                          wordWrap: "break-word",
                        }}
                      >
                        <Grid container justify="space-between" spacing={2}>
                          <Grid
                            item
                            md={4}
                            sm={5}
                            xs={6}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listheading}>
                              NAME
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sm={5}
                            xs={6}
                            style={{ textAlign: "left" }}
                          >
                            <Typography
                              className={classes.listheading}
                            ></Typography>
                          </Grid>
                        </Grid>
                        <Box height="1rem"></Box>
                        {tribunalList.map((item) => {
                          return (
                            <Grid
                              container
                              justify="space-between"
                              spacing={isSmallScreen() ? 1 : 2}
                            >
                              <Grid
                                item
                                md={4}
                                sm={5}
                                xs={6}
                                style={{ textAlign: "left" }}
                              >
                                <Typography className={classes.listtext}>
                                  {item.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={4}
                                sm={5}
                                xs={6}
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.listtextblue}
                                  onClick={(e) => {
                                    history.push(`/events/${item.id}`);
                                  }}
                                >
                                  View Calender
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Paper>
                    </Grid>
                    <Box height="0.6rem"></Box>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: "1",
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid
                          item
                          sm={3}
                          xs={5}
                          style={{ textAlign: "start", padding: "0 20px" }}
                        >
                          <Typography className={classes.headingtribunal}>
                            Secretariat
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={3}
                          xs={5}
                          className={classes.listheading}
                        >
                          <Typography></Typography>
                        </Grid>
                      </Grid>
                      <Box height="1rem"></Box>
                      <Paper
                        style={{
                          height: "100%",
                          padding: "10px 20px",
                          wordWrap: "break-word",
                        }}
                      >
                        <Grid container justify="space-between" spacing={2}>
                          <Grid
                            item
                            md={4}
                            sm={5}
                            xs={6}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listheading}>
                              NAME
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sm={5}
                            xs={6}
                            style={{ textAlign: "left" }}
                          >
                            <Typography
                              className={classes.listheading}
                            ></Typography>
                          </Grid>
                        </Grid>
                        <Box height="1rem"></Box>
                        {secretariatList.map((item) => {
                          return (
                            <Grid
                              container
                              justify="space-between"
                              spacing={isSmallScreen() ? 1 : 2}
                            >
                              <Grid
                                item
                                md={4}
                                sm={5}
                                xs={6}
                                style={{ textAlign: "left" }}
                              >
                                <Typography className={classes.listtext}>
                                  {item.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={4}
                                sm={5}
                                xs={6}
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.listtextblue}
                                  onClick={(e) => {
                                    history.push(`/events/${item.id}`);
                                  }}
                                >
                                  View Calender
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box height="3rem"></Box>
              <Grid container justify="flex-start">
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.blueheading}>G. ID</Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    CLAIMANT
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    RESPONDENT
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={2} style={{ paddingLeft: "10px" }}>
                  <Typography className={classes.blueheading}>LEVEL</Typography>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.blueheading}>
                    START DATE
                  </Typography>
                </Grid>
              </Grid>
              <Box height="0.4rem"></Box>

              {loadingGrievances ? (
                <CircularCenteredLoader />
              ) : (
                grievanceList.map((dispute) => {
                  return (
                    <GrievanceRow
                      key={dispute.id}
                      dispute={dispute}
                      setOpenCase={setOpenCase}
                      setCaseId={setCaseId}
                    />
                  );
                })
              )}
            </Grid>
          </Grid>
        ) : (
          <CaseDisplay
            setOpenCase={setOpenCase}
            isSmallScreen={isSmallScreen}
            caseId={caseId}
            mode={mode}
          />
        )}
      </div>
    );
  }
}
