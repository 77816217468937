import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDispute } from "slices/disputeSlice";
import {
  getFilesAdminViewOther,
  getFoldersAdminViewOther,
  getProfilesOfDispute,
} from "./FileApi";
import Files from "./Files";
import { removeSessionStorage } from "features/tiac_header_waiting/TiacAppHeader";
import { isMSME, isSebi } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  btntext: {
    color: "#2F80ED",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    textTransform: "none",
  },
  hov: {
    "&:hover": {
      color: "#333333",
    },
  },
}));

export default function FileContainer() {
  let { disputeId, folder_id, user_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.profile, shallowEqual);

  const [dispute, setDispute] = useState();
  const [loading, setLoading] = useState(true);

  const [parent_folder, setParent_folder] = useState();
  const [allFiles, setAllFiles] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);

  useEffect(() => {
    if (disputeId) {
      dispatch(getDispute(disputeId)).then((res) => {
        setDispute(res.results[0]);
        setLoading(false);
      });
      getProfiles();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    reload();
  }, [folder_id]);

  const getProfiles = () => {
    getProfilesOfDispute(disputeId)
      .then((res) => {
        let profiles = [];
        res.data.details.claimant.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Claimant";
          if (p.profile.id != profile.profile.id) profiles.push(p);
        });
        res.data.details.claimant_lawyer.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Claimant's Counsel";
          if (p.profile.id != profile.profile.id) profiles.push(p);
        });
        res.data.details.respondent.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Respondent";
          if (p.profile.id != profile.profile.id) profiles.push(p);
        });
        res.data.details.respondent_lawyer.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Respondent's Counsel";
          if (p.profile.id != profile.profile.id) profiles.push(p);
        });
        res.data.details.professionals.forEach((item) => {
          let p = item;
          if (p.profile.profile_type == "arbitrator_rv") {
            p.profile.profile_type = "Arbitrator";
          } else if (p.profile.profile_type === "witness") {
            p.profile.profile_type = "Witness";
          } else {
            p.profile.profile_type =
              isSebi || isMSME ? "Conciliator" : "Mediator";
          }
          if (p.profile.id != profile.profile.id) profiles.push(p);
        });
        setAllProfiles(profiles);
      })
      .catch((error) => {});
  };

  const reload = () => {
    setAllFiles([]);
    setAllFolders([]);
    getFilesAdminViewOther(disputeId, folder_id, user_id).then((res) => {
      setAllFiles(res.data);
    });
    getFoldersAdminViewOther(disputeId, folder_id, user_id).then((res) => {
      setAllFolders(res.data);
    });
    // getFolder(folder_id).then((response) => {
    //   setParent_folder(response.data[0]);
    //   if (response.data.length > 0) {
    //     let routes = getParent(response.data[0]);
    //     routes = routes.reverse();
    //     setAllRoutes(routes);
    //   } else {
    //     setAllRoutes([]);
    //   }
    // });
  };

  const getParent = (parent) => {
    let arr = [];
    arr.push({ name: parent.name, id: parent.id });
    if (parent.parent_folder) {
      let t = getParent(parent.parent_folder);
      t.map((item) => {
        arr.push(item);
      });
    }
    return arr;
  };

  if (loading) return <CircularCenteredLoader />;
  return (
    <Grid container justify="center">
      <Grid item lg={10} md={10} sm={11} xs={11} className={classes.root}>
        <Button
          startIcon={
            <KeyboardArrowLeftIcon
              onClick={() => {
                if (
                  profile.profile.profile_type == "sub_admin_tiac_rv" ||
                  profile.profile.profile_type == "case_manager"
                ) {
                  removeSessionStorage();
                }
                history.push("/");
              }}
              style={{ color: "#2F80ED", cursor: "pointer" }}
            />
          }
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={{ backgroundColor: "transparent", cursor: "default" }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Typography
                className={`${classes.btntext} ${classes.hov}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    profile.profile.profile_type == "sub_admin_tiac_rv" ||
                    profile.profile.profile_type == "case_manager"
                  ) {
                    removeSessionStorage();
                  }
                  history.push("/");
                }}
              >
                Dashboard
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.btntext}>|</Typography>
            </Grid>
            {disputeId && (
              <>
                <Grid item>
                  <Typography
                    className={`${classes.btntext} ${classes.hov}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      sessionStorage.setItem(
                        "tiac_overview_caseid",
                        dispute.id
                      );
                      history.push(`/`);
                    }}
                  >
                    {dispute.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.btntext}>|</Typography>
                </Grid>
              </>
            )}
            <Grid item>
              <Typography
                className={`${classes.btntext} ${classes.hov}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (disputeId)
                    history.push(
                      `/disputes/${disputeId}/files/user/${user_id}/0`
                    );
                  else history.push(`/files/user/${user_id}/0`);
                }}
              >
                Files
              </Typography>
            </Grid>
            {allRoutes.map((route) => {
              return (
                <React.Fragment key={route.id}>
                  <Grid item>
                    <Typography className={classes.btntext}>|</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.btntext} ${classes.hov}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (disputeId) {
                          history.push(
                            `/disputes/${disputeId}/files/user/${user_id}/${route.id}`
                          );
                        } else {
                          history.push(`/files/user/${user_id}/${route.id}`);
                        }
                      }}
                    >
                      {route.name}
                    </Typography>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Button>
        <Files
          disputeId={disputeId}
          folder_id={folder_id}
          allFiles={allFiles}
          allFolders={allFolders}
          reload={reload}
          allProfiles={allProfiles}
          profile={profile.profile}
          parent_folder={parent_folder}
          user_id={user_id}
        />
      </Grid>
    </Grid>
  );
}
