import instance from "api/globalaxios";

const crypto = require("crypto");
const base64 = require("base-64");

const KEY = "kXp2s5u8x/A?D(G+KbPeShVmYq3t6w9y";

// console.log(decryptText("VRd5ZiU+souD32exTx96L+7x0SAcKTg0Rw44UlKeVIw="));

export function decryptText(encodedText) {
  try {
    const encryptedText = decodeURIComponent(encodedText)
    const encryptedBuff = Buffer.from(encryptedText, "base64");
    const iv = encryptedBuff.slice(0, 16);
    const data = encryptedBuff.slice(16);
    let decipher = crypto.createDecipheriv("aes-256-cbc", KEY, iv);
    let decrypted = decipher.update(data, "utf8", "utf8");
    decrypted += decipher.final("utf8");
    return base64.decode(decrypted);
  } catch (e) {
    console.error(e);
  }
}

export const getQueryParams = (params, url) => {
  let href = url;
  //this expression is to get the query strings
  let reg = new RegExp("[?&]" + params + "=([^&#]*)", "i");
  let queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

export async function getCompaniesByGroupAnonymous(groupName) {
  return await instance({
    method: "get",
    url: "/api/companyanonymousall/",
    params: {
      group: groupName,
    },
  });
}

export async function createCompany(company_name, name, email, phone) {
  return await instance({
    method: "get",
    url: `/addcompanyanonymousvikalp/${company_name}/${
      name.trim() ? name : null
    }/${email}/${phone}/`,
  });
}

/*
http://localhost:3000/intro?email=sadf&name=asdf&id=asdf&phoneNo=23

http://localhost:3000/intro?email=VRd5ZiU+souD32exTx96L+7x0SAcKTg0Rw44UlKeVIw=&name=VRd5ZiU+souD32exTx96L+7x0SAcKTg0Rw44UlKeVIw=&id=VRd5ZiU+souD32exTx96L+7x0SAcKTg0Rw44UlKeVIw=&phoneNo=VRd5ZiU+souD32exTx96L+7x0SAcKTg0Rw44UlKeVIw=




*/
