import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { backgrounds } from "contexts/BackgroundContext";
import FormattedMessage from "features/common/TranslatedMessage";
import { useBodyBackgroundChange } from "hooks";
import MailSentVectorSVG from "images/mail-sent/rafiki.svg";
import React, { Fragment } from "react";
import { isCDR, isHotStar, isMuthoot, isStar, isVikalp } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "6rem",
    color: "#707C97",
  },

  hotstar: {
    fontSize: "25px",
    lineHeight: "37px",
    fontWeight: "500",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      // lineHeight: "2.5rem",
    },
  },
  primaryText: {
    fontSize: "2rem",
    lineHeight: "2.6rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      // lineHeight: "2.5rem",
    },
  },
  // container: {
  //   width: "70%",
  //   [theme.breakpoints.down("sm")]: {
  //     width: "90%",
  //   },
  // },
  container: {
    padding: "20px",
    // width: "70%",
    // [theme.breakpoints.down("sm")]: {
    //   width: "90%",
    // },
  },
  vectorImage: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  content: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
}));

export default function ClaimantWaiting({ disputeName }) {
  const classes = useStyles();

  // useBodyBackgroundChange(backgrounds.DEFAULT);

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item className={classes.container} lg={9} md={10} sm={10}>
          <div className={classes.mainContainer}>
            <div className={classes.content}>
              <Typography
                className={(isHotStar || isStar || isMuthoot) ? classes.hotstar : classes.primaryText}
              >
                {isCDR || isVikalp ? (
                  <FormattedMessage id="pages.waitingScreens.claimantWaitingcdr.heading1" />
                ) : (
                  <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading1" />
                )}
              </Typography>
              <Box height="0.1rem"></Box>
              <Typography
                variant="h3"
                className={(isHotStar || isStar || isMuthoot) ? classes.hotstar : classes.primaryText}
              >
                {(isHotStar || isStar || isMuthoot) ? (
                  <FormattedMessage id="pages.waitingScreens.claimantWaiting.headingHotstar" />
                ) : isCDR || isVikalp ? (
                  <FormattedMessage id="pages.waitingScreens.claimantWaitingcdr.heading2" />
                ) : (
                  <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading2" />
                )}
              </Typography>
              {!isHotStar && !isStar && !isMuthoot && (
                <>
                  <Box height="0.1rem"></Box>
                  <Typography variant="h3" className={classes.primaryText}>
                    {isCDR || isVikalp ? (
                      <FormattedMessage id="pages.waitingScreens.claimantWaitingcdr.heading3" />
                    ) : (
                      <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading3" />
                    )}
                  </Typography>
                  <Box height="2rem"></Box>
                  <Typography variant="h3" className={classes.primaryText}>
                    {isCDR || isVikalp ? (
                      <FormattedMessage id="pages.waitingScreens.claimantWaitingcdr.heading4" />
                    ) : (
                      <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading4" />
                    )}
                  </Typography>
                </>
              )}
              {/* <Typography className={classes.hotstar}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading1" />
              </Typography>
              <Box height="0.1rem"></Box>
              <Typography variant="h3" className={classes.hotstar}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.headingHotstar" />
              </Typography> */}

              {/* <Box height="0.1rem"></Box>
              <Typography variant="h3" className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading3" />
              </Typography>
              <Box height="2rem"></Box>
              <Typography variant="h3" className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading4" />
              </Typography> */}
            </div>
            <Hidden smDown>
              <Box flexGrow={1}></Box>
            </Hidden>

            {/* <img
              className={classes.vectorImage}
              src={MailSentVectorSVG}
              alt="mail sent vector image"
            /> */}
            <img
              className={classes.vectorImage}
              src={MailSentVectorSVG}
              alt="mail sent vector image"
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}
