import { isHotStar, isMuthoot, isStar } from "Routes/Routes";

export default (cases, disputes) => {
  let claimaintDisputes = {
    active: [],
    resolved: [],
  };

  let respondentDisputes = {
    active: [],
    resolved: [],
  };

  cases.forEach((cse) => {
    let currentDispute = disputes.find((dispute) => dispute.id === cse.dispute);
    if (currentDispute) {
      if (cse.type === "respondent") {
        if (isHotStar || isStar || isMuthoot) {
          if (currentDispute.approved) {
            if (
              currentDispute.level_1_compete ||
              currentDispute.status == "closed"
            ) {
              respondentDisputes.resolved.push(currentDispute);
            } else {
              respondentDisputes.active.push(currentDispute);
            }
          }
        } else {
          if (
            currentDispute.level_1_compete ||
            currentDispute.status == "closed"
          ) {
            respondentDisputes.resolved.push(currentDispute);
          } else {
            respondentDisputes.active.push(currentDispute);
          }
        }
      } else {
        if (
          currentDispute.level_1_compete ||
          currentDispute.status == "closed"
        ) {
          claimaintDisputes.resolved.push(currentDispute);
        } else {
          claimaintDisputes.active.push(currentDispute);
        }
      }
    }
  });
  return { respondentDisputes, claimaintDisputes };
};
