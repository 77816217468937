import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { IconButton, StepConnector, Tooltip } from '@material-ui/core';
import { CheckCircleOutlineOutlined, PlayForWork, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { DocGenerator } from 'utils';
import { format } from 'date-fns';
import { isValidDate } from './DisputesMuthoot';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const StyledConnector = withStyles({
    // active: {
    //   '& $line': {
    //     borderColor: '#4A3BFF',
    //   },
    // },
    vertical: {
      padding: '0 0 6px',
      marginLeft: '0.7rem',
    },
    completed: {
      '& $line': {
        borderColor: '#4A3BFF',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderRadius: 1,
      borderLeftWidth: 3,
      margin: 0,
    },
  })(StepConnector);

  function StyledIcon(props) {
    const { active, completed } = props;
  
    return (
      <div
      >
        {completed ? <CheckCircleOutlineOutlined fontSize='medium' color='primary' /> : <RadioButtonUncheckedOutlined fontSize='medium' style={{color:'#0000008a'}} />}
      </div>
    );
  }
  
function getSteps(status, isPrincipalNodalResponded) {
  console.log('status',status);
  switch(status) {
    case null:
    case 'Forward':
    case 'Forward_nodal':
      return ['Acknowledged', 'Resolved'];

    case 'GRO_Accept':
      return ['Acknowledged', 'Accepted by GRO', 'Resolved'];

    case 'GRO_Accept_nodal':
      return ['Acknowledged', 'Accepted by Principal GRO', 'Resolved'];
    
    case 'Forward to IO':
    case 'Forward_MD':
      return ['Acknowledged', isPrincipalNodalResponded ? 'Rejected by Principal GRO' : 'Rejected by GRO', 'Acknowledged by IO', 'Resolved'];

    case 'IO_Reject':
      return ['Acknowledged', isPrincipalNodalResponded ? 'Rejected by Principal GRO' : 'Rejected by GRO', 'Acknowledged by IO', 'Rejected by IO', 'Resolved'];

    case 'Forward to MD':
      return ['Acknowledged', isPrincipalNodalResponded ? 'Rejected by Principal GRO' : 'Rejected by GRO', 'Acknowledged by IO', 'Accepted by IO', 'Acknowledged by MD', 'Resolved'];

    case 'MD_GRO':
      return ['Acknowledged', isPrincipalNodalResponded ? 'Rejected by Principal GRO' : 'Rejected by GRO', 'Acknowledged by IO', 'Accepted by IO', 'Acknowledged by MD', 'MD agrees with GRO', 'Resolved'];

    case 'MD_IO':
      return ['Acknowledged', isPrincipalNodalResponded ? 'Rejected by Principal GRO' : 'Rejected by GRO', 'Acknowledged by IO', 'Accepted by IO', 'Acknowledged by MD', 'MD agrees with IO', 'Resolved'];

    default:
      return ['Acknowledged', 'Resolved'];
  }
}

export default function ShowProgress({activeDispute,MUTHOOT_STATUS,IS_END_USER,timeLines,isPrincipalNodalResponded}) {
  const classes = useStyles();

  const STATUS = (activeDispute.forward_md && MUTHOOT_STATUS === 'Forward_MD') ? 'Forward to MD' : (activeDispute.forward && (MUTHOOT_STATUS === 'Forward' || MUTHOOT_STATUS === 'Forward_nodal')) ? 'Forward to IO' : ((MUTHOOT_STATUS === 'MD_GRO' || MUTHOOT_STATUS === 'MD_IO') && activeDispute.status !== 'closed') ? 'Forward to MD' : MUTHOOT_STATUS;
  const steps = getSteps(STATUS, isPrincipalNodalResponded);
  const [activeStep, setActiveStep] = React.useState(activeDispute.status === 'closed' ? steps.length : steps.length - 1);

  const handleDownloadResponse = (status, isPrincipalNodalResponded) => {
    const profile_type = (status === 'MD agrees with GRO' || status === 'MD agrees with IO') ? 'md' : (status === 'Accepted by IO' || status === 'Rejected by IO') ? 'io' : isPrincipalNodalResponded ? 'nodal' : 'respondent';
    DocGenerator(activeDispute.id, profile_type);
  }

  const getDateAndTime = (label,timeLines) => {
    if(label === 'Acknowledged'){
      return timeLines['created'];
    } else if(label === 'Accepted by GRO' || label === 'Accepted by Principal GRO' || label === 'Rejected by Principal GRO' || label === 'Rejected by GRO' || label === 'Acknowledged by IO'){
      return timeLines['gro'];
    } else if(label === 'Accepted by IO' || label === 'Rejected by IO' || label === 'Acknowledged by MD'){
      return timeLines['io'];
    } else if(label === 'MD agrees with GRO' || label === 'MD agrees with IO'){
      return timeLines['md'];
    } else if(label === 'Resolved' && activeDispute.status === 'closed'){
      return timeLines[activeDispute?.forward_md ? 'md' : activeDispute?.forward ? 'io' : 'gro'];
    } else {
      return '';
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<StyledConnector/>}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StyledIcon}>
              <span style={{display:'flex',gap:'8px'}}>
                <span style={(index <= activeStep - 1)?{color:'#4A3BFF'}:{color:'#0000008a'}}>{label}</span>
                {(label === 'Accepted by GRO' || label === 'Accepted by Principal GRO' || label === 'Rejected by Principal GRO' || label === 'Rejected by GRO' || label === 'Rejected by IO' || label === 'Accepted by IO' || label === 'MD agrees with GRO' || label === 'MD agrees with IO') && !IS_END_USER && (
                  <Tooltip title='Download Response' placement='bottom'>
                    <IconButton
                      onClick={() => handleDownloadResponse(label,isPrincipalNodalResponded)}
                      style={{padding:0}}
                    >
                      <PlayForWork color='primary'/>
                    </IconButton>
                  </Tooltip>
                )}
              </span>
              <span style={{fontSize:'0.8rem',color:'#aaaaaa'}}>
                {isValidDate(getDateAndTime(label,timeLines)) ? format(new Date(getDateAndTime(label,timeLines)), "dd MMMM | h:mm a") : ''}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
