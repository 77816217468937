import {
  Box,
  IconButton,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import { MEDIATOR } from "app/constants";
import SecretariatReviewStatus from "features/singleDispute/SecretariatReviewStatus";
import { DropzoneDialog } from "material-ui-dropzone";
import React from "react";
import { useDispatch } from "react-redux";
import { updateDispute, uploadDispute } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "30%",
    textAlign: "center",
    padding: "3rem 1rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  heading: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#000",
  },
  sectionContainer: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  iconContainer: {
    background: "rgba(112, 124, 151, 0.15)",
    display: "inline-block",
    borderRadius: "0.3rem",
  },
  icon: {
    color: "rgba(112, 124, 151, 0.7)",
    fontSize: "4rem",
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  closeButton: {
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "1.5rem",
    textTransform: "capitalize",
  },
}));

export default function UploadSignedDoc({ dispute, profile }) {
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState({});
  const classes = useStyles();
  let dispatch = useDispatch();

  let award_type = "Arbitrator award";
  let upload_text = "Upload signed arbitral award here";

  if (profile.profile_type === MEDIATOR) {
    award_type = "Settlement Agreement";
    upload_text = "Upload signed Settlement Agreement here";
  }
  return (
    <Box>
      <SecretariatReviewStatus
        status="complete"
        dispute={dispute}
        profile={profile}
      />
      <Box height="1.5rem"></Box>
      <Box display="flex">
        <Paper className={classes.paper}>
          <Typography variant="h5" className={classes.heading}>
            {award_type}
          </Typography>
          <Box className={classes.sectionContainer}>
            <Box height="1rem"></Box>
            <Box display="flex">
              <Box className={classes.iconContainer}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <PostAddOutlinedIcon className={classes.icon} />
                </IconButton>
              </Box>
              <Box width="0.4rem"></Box>
              <p className={classes.blueText}>{upload_text}</p>
            </Box>
          </Box>
        </Paper>
        <Box flexGrow={1}></Box>
        <DropzoneDialog
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          maxFileSize={104857600}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onSave={async (files) => {
            dispatch(uploadDispute(files, dispute, "complete")).then((resp) => {
              dispatch(updateDispute({ id: dispute.id, status: "complete" }));
            });
            setOpen(false);
          }}
          showPreviews={true}
          filesLimit={1}
          showFileNamesInPreview={true}
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
        />
      </Box>
    </Box>
  );
}

{
  /* <Typography variant="h5" display="block" gutterBottom>
          Upload Signed Doc
        </Typography>
        <Box display="flex" alignItems="center">
          {files.length > 0 && (
            <Typography variant="h6">{files[0].name}</Typography>
          )}
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddCircleOutlineIcon style={{ fontSize: "2rem" }} />
          </IconButton>
        </Box>
        <Box height="1rem"></Box>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Finish
        </Button> */
}
