import { backgrounds } from "contexts/BackgroundContext";
import CircularLoading from "features/common/CircularCenteredLoader";
import { useBodyBackgroundChange } from "hooks";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDisputes } from "slices/disputeSlice";
import Analytics from "./Analytics";
import {
  createDisputeCountMap,
  createDisputeModeMap,
  createDisputeStatusMap,
  filterDispute,
  transformDisputeStatusPieData,
  transformDisputeToBarData,
} from "./Analytics.utils";

export default function AnalyticsContainer() {
  useBodyBackgroundChange(backgrounds.WHITE);

  const dispatch = useDispatch();
  const [disputeStatusPieData, setStatusPieData] = useState([]);
  const [disputeModePieData, setModePieData] = useState([]);
  const [disputeCountBarData, setCountBarData] = useState([]);

  const [range, setRange] = React.useState("week");

  const handleChange = (event) => {
    setRange(event.target.value);
  };

  const { disputes, loading } = useSelector(
    (state) => state.disputes,
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(getDisputes());
  }, []);

  React.useEffect(() => {
    if (disputes.length > 0) {
      const filteredDisputes = filterDispute(disputes, range);
      setStatusPieData(
        transformDisputeStatusPieData(
          createDisputeStatusMap(filteredDisputes),
          totalCount
        )
      );
      setModePieData(
        transformDisputeStatusPieData(
          createDisputeModeMap(filteredDisputes),
          totalCount
        )
      );
      setCountBarData(
        transformDisputeToBarData(createDisputeCountMap(filteredDisputes))
      );
    }
  }, [disputes.length, range]);

  if (loading) return <CircularLoading />;

  const totalCount = disputes.length;

  return (
    <Analytics
      handleChange={handleChange}
      range={range}
      disputeStatusData={disputeStatusPieData}
      disputeModeData={disputeModePieData}
      disputeCountBarData={disputeCountBarData}
    />
  );
}
