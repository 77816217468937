import { Box, Button, Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { backgrounds } from "contexts/BackgroundContext";
import { addHours, addMinutes, isAfter, isBefore } from "date-fns";
import Calender from "features/common/Calendar";
import CircularLoader from "features/common/CircularCenteredLoader";
import Modal from "features/common/Modal";
import FormattedMessage from "features/common/TranslatedMessage";
import AddEventForm from "features/globalCalendar/AddEventForm";
import EventDisplay from "features/globalCalendar/EventDisplay";
import { useBodyBackgroundChange } from "hooks";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import querystring from "query-string";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  isCDR,
  isDev,
  isDifc,
  isDigipub,
  isHotStar,
  isMSME,
  isMuthoot,
  isNbf,
  isRv,
  isSebi,
  isStar,
  isViacom,
  isVikalp,
  isWebnyay,
} from "Routes/Routes";
import { getAllMeetings } from "slices/appointmentSlice";
import {
  getDisputescdr,
  getDisputesHotstar,
  getDisputesOpen,
  getDisputesOpen1,
} from "slices/disputeSlice";
import { getTimeZones } from "slices/timezoneSlice";

const parseDate = (date) => {
  return new Date(date);
};

const selectAllow = (eventInfo) => {
  let currentDate = new Date();
  if (isAfter(eventInfo.start, currentDate)) {
    return isAfter(eventInfo.start, currentDate);
  } else {
    return false;
  }
  // return true;
};

const transformFunction = (eventData) => {
  let {
    meeting: {
      start,
      end,
      name,
      dispute,
      id: meetingId,
      ext_link,
      tiac_custom_dispute_id,
      group,
    },
    id: appointmentId,
    name: appointmentName,
  } = eventData;

  let isPastEvent = isBefore(parseDate(start), new Date());

  if (
    isWebnyay ||
    isHotStar ||
    isStar ||
    isMuthoot ||
    isNbf ||
    isViacom ||
    isDigipub
  ) {
    name = "Grievance Hearing";
  } else if (isRv && !isDifc) {
    name = "Case Hearing";
  }

  let title = isPastEvent ? `${name} (past event)` : `${name}`;

  return {
    title,
    start: parseDate(start),
    end: parseDate(end),
    display: isPastEvent ? "block" : "auto",
    ext_link: ext_link,
    isPastEvent,
    dispute,
    tiac_custom_dispute_id,
    group,
    meetingId,
    appointmentId,
    appointmentName,
    backgroundColor: isPastEvent ? "grey" : "#3788d8",
    borderColor: isPastEvent ? "grey" : "#3788d8",
  };
};

export default function CalenderContainer() {
  const location = useLocation();
  const history = useHistory();

  useBodyBackgroundChange(backgrounds.WHITE);

  const { disputeId, group: groupName } = querystring.parse(location.search);
  const routeState = location.state;
  const [addEventOpen, setOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [eventInfo, setEventInfo] = React.useState({});
  const [eventDetails, setEventDetails] = React.useState({});
  const [opendispute, setOpenDispute] = React.useState("");
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  const handleEventDetailsClose = () => {
    setDetailsOpen(false);
  };

  const handleAddEventClose = () => {
    setOpen(false);
  };

  const onSelect = (info) => {
    setEventInfo(info);
    setOpen(true);
  };

  const handleAddEventClick = () => {
    const currentDate = new Date();

    setEventInfo({
      start: addMinutes(currentDate, 5),
      end: addHours(currentDate, 1),
    });

    setOpen(true);
  };

  const onEventClick = (info) => {
    setEventDetails(info);
    setDetailsOpen(true);
  };

  React.useEffect(() => {
    dispatch(getAllMeetings());
    if (isHotStar || isStar || isMuthoot) {
      dispatch(getDisputesHotstar(profile.profile.profile_type));
    } else if (isCDR || isVikalp || isWebnyay || isDigipub) {
      dispatch(getDisputescdr(profile.profile.profile_type));
    } else if (profile.profile.is_tiac_profile) {
      dispatch(getDisputesOpen1()).then((res) => {
        setOpenDispute(res);
      });
    } else {
      dispatch(getDisputesOpen());
    }
  }, []);

  React.useEffect(() => {
    dispatch(getTimeZones());
  }, [dispatch]);

  const {
    appointments: { allUserMeetings, loading: appointmentLoading },
    disputes: { disputes, loading: disputeLoading },
    groups: { groups },
  } = useSelector((state) => state, shallowEqual);

  let sources = [{ events: allUserMeetings }];

  let loading = disputeLoading || appointmentLoading;

  const childProps = {
    transformFunction,
    sources,
    selectable: true,
    onSelect,
    onEventClick,
    selectAllow,
    handleAddEventClick,
  };

  const [showGuide, setShowGuide] = useState(
    location.pathname == "/events" &&
      (user.id == 473 || user.id == 214) &&
      !isDev
  );

  // React.useEffect(()=>{
  //   if(counter == 0){
  //     setShowGuide(location.pathname == "/events" && (user.id == 473 || user.id == 214))
  //     let c = counter + 1;
  //     setCounter(c)
  //   }
  // },[counter])

  // React.useEffect(()=>{
  //   if(addEventOpen){
  //     setShowGuide(false)
  //   }
  // },[addEventOpen])

  const exit = (t) => {
    // setShowGuide(false);
  };

  const complete = (t) => {
    setShowGuide(false);
  };

  const start = () => {};

  const steps = [
    {
      element: ".fc-custom1-button",
      intro: "Click here to schedule a meeting.",
    },
    // {
    //   element: ".calenderstep2",
    //   intro: "Change the date and time by clicking here.",
    // },
  ];

  const isScheduleButtonDisabled =
    ((isSebi || isMSME) &&
      profile.profile.profile_type !== "mediator" &&
      profile.profile.profile_type !== "arbitrator_rv" &&
      profile.profile.profile_type !== "case_manager") ||
      (isDifc &&
        profile.profile.profile_type !== "case_manager" &&
        profile.profile.profile_type !== "sub_admin_tiac_rv" &&
        profile.profile.profile_type !== "mediator" &&
        profile.profile.profile_type !== "arbitrator_rv"
      );

  if (loading) return <CircularLoader />;

  return (
    <React.Fragment>
      <Steps
        enabled={showGuide}
        steps={steps}
        initialStep={0}
        onExit={exit}
        onStart={start}
        onComplete={complete}
        options={{
          hideNext: false,
          hidePrev: true,
          keyboardNavigation: true,
          exitOnEsc: true,
          exitOnOverlayClick: false,
          showBullets: false,
          showStepNumbers: false,
          disableInteraction: true,
        }}
      />
      <Box height="0.8rem"></Box>
      <Grid container justify="left">
        <Grid sm={7} xs={7} item>
          <Grid container justify="flex-start">
            <Grid item style={{ padding: "0 20px" }}>
              <Button
                onClick={() => {
                  // history.push("/disputes");
                  history.goBack();
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Calender {...childProps} />
      {!isScheduleButtonDisabled && (
        <Modal open={addEventOpen} handleClose={handleAddEventClose}>
          <AddEventForm
            groups={groups}
            eventInfo={eventInfo}
            disputeId={disputeId}
            handleAddEventClose={handleAddEventClose}
            disputes={disputes}
            groupName={groupName}
            routeState={routeState}
            sources={sources}
            profile={profile.profile}
            opendispute={opendispute}
          />
        </Modal>
      )}
      <Modal open={detailsOpen} handleClose={handleEventDetailsClose}>
        <EventDisplay
          eventDetails={eventDetails}
          handleEventDetailsClose={handleEventDetailsClose}
          sources={sources}
          profile={profile.profile}
        />
      </Modal>
    </React.Fragment>
  );
}
