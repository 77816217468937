import { Box, Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "features/common/TranslatedMessage";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "utils";
import DetailsReviewContainer from "./DetailsReview";
import QuestionsContainer from "./Questions";
import UploadContainer from "./Upload";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    <FormattedMessage id="pages.questions.questions" />,
    <FormattedMessage id="pages.upload.upload" />,
    <FormattedMessage id="pages.review.detailsReview" />,
    <FormattedMessage id="pages.status.status" />,
  ];
}

function getStepContent(step, dispute, profile) {
  switch (step) {
    case 0:
      return <QuestionsContainer profile={profile} />;
    case 1:
      return <UploadContainer profile={profile} />;
    case 2:
      return <DetailsReviewContainer profile={profile} />;
    case 3:
      return "Under Review";
    default:
      return "Unknown step";
  }
}

function getStep(status) {
  if (status === "new" || status === "in_progress") return 0;
  if (status === "questions_complete") return 1;
  if (status === "upload_complete") return 2;
  if (status === "review_complete") return 3;
  return 0;
}

export default function SingleDisputeDetails({
  activeCase,
  activeDispute,
  profile,
}) {
  const classes = useStyles();
  let { disputeId } = useParams();

  // const [loading, setLoading] = useState(true);

  // const { activeDispute } = useSelector(
  //   (state) => state.disputes,
  //   shallowEqual
  // );

  // const { activeCase } = useSelector((state) => state.cases, shallowEqual);

  let activeStep = getStep(activeCase.case_status);

  const steps = getSteps();

  // if (isEmpty(activeCase) || isEmpty(activeDispute)) {
  //   return (
  //     <Box m="4rem 0 0 0" display="flex" justifyContent="center">
  //       <CircularProgress disableShrink />
  //     </Box>
  //   );
  // }
  return (
    <Container maxWidth="lg">
      <Box>
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          className={classes.dispute_heading}
        >
          {activeDispute.name.split(" ")[3] == "Shemaroo"
            ? activeDispute.name.replace("vs", "&")
            : activeDispute.name}
        </Typography>
        <Typography
          className={classes.date_text}
          variant="subtitle1"
          color="textSecondary"
          component="p"
        >
          {/* Created on {activeDispute.created.split("T")[0]} */}
          Created on {formatDate(activeDispute.created)}
        </Typography>
      </Box>

      <Box height="1.5rem"></Box>

      <div className={classes.root}>
        <Hidden smDown>
          <Box display="flex">
            <Box>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                style={{ padding: 0, background: "none" }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel style={{ width: "max-content" }}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box height="2rem"></Box>

            <Box flexGrow={1}>
              {getStepContent(activeStep, activeDispute, profile)}
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Stepper
            activeStep={activeStep}
            orientation="horizontal"
            style={{ padding: 0, background: "none" }}
            alternativeLabel
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box height="2rem"></Box>

          {getStepContent(activeStep, activeDispute, profile)}
        </Hidden>

        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
          </Paper>
        )}
      </div>
      <Box height="2rem"></Box>

      <Box display="flex" justifyContent="flex-start">
        {activeCase.case_status === "review_complete" ? (
          <>
            <Box width="1rem"></Box>
            <Link to={`/disputes/${disputeId}/chat`}>
              <Button variant="contained" color="primary" size="large">
                <FormattedMessage id="common.hearingRooms" />
              </Button>
            </Link>
          </>
        ) : null}
      </Box>
    </Container>
  );
}
