import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Icon2 from "images/Va.png";
import Icon1 from "images/Lidw.jpeg";
import Icon3 from "images/AI.png";


const useStyles = makeStyles((theme) => ({
  root: {
    background:"white",
    [theme.breakpoints.down("xs")]: {
      padding:"10px",
    },
  },
  featured: {
    color: "#70c8ce",
    fontSize: "55px",
    fontWeight: "bolder",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  text: {
    color: "#333333",
    fontSize: "15px",
    lineHeight: "20px",
    margin: "10px 0",
    padding: "0 20px",
  },
}));


const differencesData = [
  {
    icon: Icon1,
    content: `London International Disputes Week`,
  },
  {
    icon: Icon2,
    content: `Virtual Arbitration
    `,
  },
  {
    icon: Icon3,
    content: `Numerous arbitral institutions and mediation centres    `,
  },
];

export default function WhatSetsUsApart() {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item sm={9} xs={10}>
        <Box height="3rem" />
        <Grid container>
          <Grid item>
            <Typography className={classes.featured}>
		Recognized By
            </Typography>{" "}
          </Grid>
        </Grid>
        <Box height="1rem" />
        <Grid container justify="center">
          {differencesData.map((data) => {
            return (
              <Grid item md={4} sm={6} xs={12}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  style={{ padding: "27px" }}
		  spacing={2}

                >
                  <Grid item style={{ textAlign: "center" }}>
                    <img src={data.icon} height="60px" alt="" />
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    {" "}
                    <Typography className={classes.text}>
                      {data.content}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box height="1rem" />
      </Grid>
    </Grid>
  );
}
