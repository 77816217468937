import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import ContactMailIcon from '@material-ui/icons/ContactMailOutlined'
import React, { useState } from "react";
import classNames from "classnames";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f0f0f0",
    [theme.breakpoints.down("xs")]: {
      padding:"10px",
    },
  },
  featured: {
    color: "#70c8ce",
    fontSize: "55px",
    fontWeight: "bolder",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  singleContainer:{
    padding: "27px 0",margin:'30px 0',
    borderRadius:'10px',
    cursor:"pointer" 
  },
  stat:{

    color: "#3f51b5",
    // color: "#70c8ce",
    fontSize: "60px",
    lineHeight: "20px",
    fontWeight:'bold',
    margin: "10px",
    padding: "0 20px",
  },
  text: {
    color: "#333333",
    fontSize: "15px",
    // padding: "20px 20px",
    textTransform:'uppercase'
  },
  colorFlip:{
    color:'white'
  }
}));


const differencesData = [
  {
    stat:'32',
    content: `Countries / Jurisdictions reached `,
  },
  {
    stat:"200",

    content: `hearing sessions conducted `,
  },
  {
    stat:'1000',
    content: `meeting sessions conducted
    `,
  },
  {
        stat:'250',
    content: `Procedural documents generated
    `,
  },
  {
    stat:'4',
    content: `hearings conducted simultaneously
    `,
  },
  {
    icon: ContactMailIcon,
    content: `Contact Us `,
    background:true,
    color:'invert',
    url:'https://www.webnyay.in/contact/' 
  },
];

export default function WhatSetsUsApart() {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item sm={9} xs={10}>
        <Box height="3rem" />
        <Grid spacing={5} container justify="center">
          {differencesData.map((data) => {
            return (
              <Grid

              item md={4} sm={6} xs={12}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  className={classes.singleContainer}
              style={data.background ? {backgroundColor: "#70c8ce"}:{}}
              spacing={2}
              onClick={()=>{
                if(data.url){
                  window.open(data.url)
                }
              }}
                >
                  <Grid item style={{ textAlign: "center" }} >
                    {data.icon  &&
                    <data.icon  style={{color:'white',fontSize:"30px"}} />
          }
          {data.stat &&          

                  <Typography className={classes.stat}>
                      {parseInt(data.stat)}+
                    </Typography>
                    }
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    {" "}
                    <Typography className={classNames(classes.text,{[classes.colorFlip]:data.url})}>
                      {data.content}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box height="1rem" />
      </Grid>
    </Grid>
  );
}
