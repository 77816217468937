import { Box, Collapse, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";

export default function SendgridEmailUpdate({entry,index,classes}) {
  
  const [expanded, setExpanded] = useState(false);
  const toogleExpand = (e) => {
    setExpanded(!expanded);
  };
  const delivered = entry?.events?.find(event => event?.event === 'delivered');
  const openedList = entry?.events?.filter(event => event?.event === 'open');
  const opened = openedList[openedList?.length - 1];
  const bounced = entry?.events?.find(event => event?.event === 'bounce');

  return (
    <Box onClick={toogleExpand} style={{borderBottom:'1px solid #E5E5E5',borderLeft:'1px solid #E5E5E5',padding:'8px'}}>
      <Grid container spacing={2} key={index}>
        <Grid item sm={6} xs={6}>
          <Typography>
            {entry?.subject}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={3}>
          {delivered ? (
            <Typography className={classes.green}>
              Delivered<br/>
              <span style={{fontSize:'10px'}}>{delivered?.timestamp}</span>
            </Typography>
            ) : (
              bounced ? (
                <Typography className={classes.red}>
                  Bounced<br/>
                  <span style={{fontSize:'10px'}}>{bounced?.timestamp}</span>
                </Typography>
              ):(
                <Typography className={classes.blue}>
                  In transit
                </Typography>
              )
            )
          }
        </Grid>
        <Grid item sm={3} xs={3}>
          <Typography className={opened ? classes.green : null}>
            {opened ? 'Read' : 'Unread'}<br/>
            <span style={{fontSize:'10px'}}>{opened?.timestamp}</span>
          </Typography>
        </Grid>
      </ Grid>
      <Collapse in={expanded} >
        <Box>
          {entry?.events.map((item)=>{
            return (
              <Typography style={{fontSize: '12px'}}>
              <span style={{textTransform:'capitalize'}}>{item?.event}</span> - {item?.timestamp} {item?.reason} 
              </Typography>
            )
          })}
        </Box>
      </Collapse>
    </Box>
  )
}