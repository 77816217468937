import { InputAdornment, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import { StyledTextFieldTiac } from "features/TempTiacFormV2/ClaimantPage/questions";
import { useFormik } from "formik";
import ArbConflogo1 from "images/arbitration_conference/3DC58360-F827-4EBA-885E-C894DA85F2CF.PNG";
import ArbConflogo2 from "images/arbitration_conference/3DEABF52-56E0-46FD-98D9-FEDCD22E468A.PNG";
import ArbConflogo3 from "images/arbitration_conference/DE6D1DD9-1502-4836-9E5C-5027B454620E.PNG";
import ArbConflogo4 from "images/arbitration_conference/f94fe446-9e01-47ca-a929-1b83dd809bb7.JPG";
import ArbConflogo5 from "images/arbitration_conference/IMG-8749.JPG";
import WebnyayLogo from "images/webnyay.svg";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isArbitrationConference, isDifc } from "Routes/Routes";
import {
  setLogOut,
  setTokenAsync
} from "slices/loginSlice";
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Email Required"),
  password: Yup.string().required("Password Required"),
  // .min(6, "Password Too Short!")
  // .max(20, "Password Too Long!")
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  loginButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#000",
    color: "white",
    border: "1px solid transparent",
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #000",
      color: "black",
    },
  },
  logo: {
    flexGrow: 1,
    width: "100%",
  },
  login: {
    fontSize: 24,
    fontWeight: "400",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  forgot: {
    fontSize: 12,
    fontWeight: 500,
    color: "#696F79",
    padding: "9px 9px 9px 0",
    textDecoration: "underline",
  },
  partnerText: {
    fontSize: 16,
    fontWeight: 400,
    color: "black",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  divider: {
    borderLeft: "1px solid black",
  },
}));

const LoginDifc = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { state: routeState } = useLocation();
  const apiErrors = useSelector((state) => state.login.errors);
  const hasErrors = useSelector((state) => state.login.hasErrors);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(setLogOut());
      let { email, password } = values;
      dispatch(setTokenAsync(email, password)).then(() => {
        if (routeState) {
          history.push(`${routeState.from.pathname}`);
        } else {
          history.push("/");
        }
      });
    },
  });

  const [passwordType, setPasswordType] = React.useState("password");
  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  React.useEffect(() => {
    dispatch(setLogOut()); // to remove login error alert
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper className={classes.paper}>
        <Grid container direction="column" justify="center" alignItems="center">
          {/* {isDifc && (
            <Grid item sm={4} xs={6}>
              <img
                src="/DIFC_logo.png"
                alt="DIFC logo"
                className={classes.logo}
              />
            </Grid>
          )} */}
          <Grid item xs>
            <Typography className={classes.login}>Log in</Typography>
          </Grid>
        </Grid>

        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <StyledTextFieldTiac
            variant="filled"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label={<FormattedMessage id="user.email" />}
            name="email"
            autoComplete="email"
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.email && formik.touched.email && (
            <Alert severity="error">{formik.errors.email}</Alert>
          )}
          {hasErrors && apiErrors.email && (
            <Alert severity="error">{apiErrors.email[0]}</Alert>
          )}
          <StyledTextFieldTiac
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label={<FormattedMessage id="auth.password" />}
            type={passwordType}
            id="password"
            value={formik.password}
            autoComplete="current-password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType === "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          {hasErrors && apiErrors.password && (
            <Alert severity="error">{apiErrors.password[0]}</Alert>
          )}
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/forgot-password">
                <Typography className={classes.forgot}>
                  Forgot Password?
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.loginButton}
          >
            Sign In
          </Button>
          {hasErrors && apiErrors.non_field_errors && (
            <Alert severity="error">{apiErrors.non_field_errors[0]}</Alert>
          )}
          <Box height="1rem"></Box>
          {isDifc && (
            <Grid container justify="center">
              {/* <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={11}>
                    <Typography className={classes.partnerText}>
                      Knowledge Partner
                    </Typography>
                  </Grid>
                  <Box height="0.7rem" />
                  <Grid item xs={7}>
                    <img
                      src={MiddleSexUNiversityLogo}
                      alt="middlesex university logo"
                      style={{ flexGrow: 1, width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={11}>
                    <Typography className={classes.partnerText}>
                      Technology Partner
                    </Typography>
                  </Grid>
                  <Box height="1rem" />
                  <Grid item xs={9}>
                    <img
                      // src="/Logo_Transparent.png"
                      src={WebnyayLogo}
                      alt="webnyay logo"
                      style={{ flexGrow: 1, width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isArbitrationConference && (
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item sm={6} xs={6}>
                <img src={ArbConflogo4} alt="logo" style={{ width: "100%" }} />
              </Grid>
              <Grid item sm={6} xs={6}>
                <img src={ArbConflogo5} alt="logo" style={{ width: "100%" }} />
              </Grid>
              <Grid item sm={4} xs={6}>
                <img src={ArbConflogo2} alt="logo" style={{ width: "100%" }} />
              </Grid>
              <Grid item sm={4} xs={6}>
                <img src={ArbConflogo3} alt="logo" style={{ width: "100%" }} />
              </Grid>
              <Grid item sm={4} xs={6} style={{ textAlign: "center" }}>
                <img src={ArbConflogo1} alt="logo" style={{ width: "60%" }} />
              </Grid>
            </Grid>
          )}
        </form>
      </Paper>

      <Box height="2rem"></Box>
    </Container>
  );
};

export default LoginDifc;
