import React, { useEffect } from "react";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getUser } from "slices/userSlice";
import { getCompanies } from "slices/companySlice";

import Intro from "./IntroRv";

export const introFormContext = React.createContext();

export default function IntroContainer() {
  const { user } = useSelector((state) => state.user, shallowEqual);
  const { profile } = useSelector((state) => state.profile, shallowEqual);

  const dispatch = useDispatch();
  const loading = !profile.user || !user.email;
  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  if (loading) return <CircularCenteredLoader />;
  else return <Intro loading={loading} user={user} profile={profile} />;
}
