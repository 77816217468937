import React from "react";
import Footer from "./Footer";
import Header from "./HeaderContact";
import Landing from "./LandingContact";

export default function ContactUs({ isLoggedIn }) {
  return (
    <>
      <Header isLoggedIn={isLoggedIn} />
      <Landing />
      <Footer />
    </>
  );
}
