import React from "react";
import DoneGif from "images/Hotstar/done.gif";

import { Box, Typography } from "@material-ui/core";
import Popup from "reactjs-popup";

export default function DonePopup({ opener, onClose, text }) {
  React.useEffect(() => {
    if (opener) {
      setTimeout(() => {
        onClose();
      }, 4000);
    }
  }, [opener]);
  return (
    <Popup
      open={opener}
      closeOnDocumentClick
      closeOnEscape
      onClose={onClose}
      position="center center"
      modal
      nested
      contentStyle={{
        background: "white",
        width: "358px",
      }}
      overlayStyle={{ background: "rgba(51, 51, 51, 0.45)" }}
    >
      <Box style={{ padding: "1.3rem 2rem", textAlign: "center" }}>
        <ReloadableDoneGif />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "400",
            color: "#2F80ED",
            fontFamily: `'Inter',sans-serif`,
            lineHeight: "29px",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Popup>
  );
}

class ReloadableDoneGif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gif: DoneGif,
      loaded: DoneGif,
    };
  }

  componentDidMount() {
    this.reloadGif();
  }

  reloadGif = () => {
    console.log("reloading now");
    this.setState({ loaded: "" });
    setTimeout(() => {
      this.setState({ loaded: this.state.gif });
    }, 0);
  };

  render() {
    console.log("this.state", this.state);
    return <img src={this.state.loaded} style={{ width: "70%" }} alt="Done" />;
  }
}
