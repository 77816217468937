import {
  Backdrop,
  Button,
  Fade,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import CloseModalButton from "Admin/Common/CloseModalButton";
import instance from "api/globalaxios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { gethost } from "slices/disputeSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      padding: theme.spacing(3, 4, 3),
    },
  },
  createlogindetails: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
  caseId: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
  profileType: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
  box: {
    padding: "0.7rem",
  },
  box2: {
    cursor: "pointer",
    padding: "0.7rem",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
  profileheading: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#25213B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  profileheading: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#25213B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
}));

export default function CreateLoginDetails({
  openmodal,
  handleCloseModal,
  caseId,
  profileType,
  companyId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openSnackBar1, setOpenSnackBar1] = useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const handleCloseSnackBar2 = () => {
    setOpenSnackBar2(false);
  };
  const [openSnackBar3, setOpenSnackBar3] = useState(false);
  const handleCloseSnackBar3 = () => {
    setOpenSnackBar3(false);
  };
  const [openSnackBar4, setOpenSnackBar4] = useState(false);
  const handleCloseSnackBar4 = () => {
    setOpenSnackBar4(false);
  };
  const [openSnackBar5, setOpenSnackBar5] = useState(false);
  const handleCloseSnackBar5 = () => {
    setOpenSnackBar5(false);
  };

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const onFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onSubmit = () => {
    if (!firstname || !lastname || !email || !phone) {
      setOpenSnackBar3(true);
      // alert("enter details")
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        setOpenSnackBar4(true);
        // alert("enter email correct")
      } else {
        if (phone.length != 10) {
          setOpenSnackBar5(true);
          // alert("enrer phone")
        } else {
          dispatch(createProfile()).then(
            () => {},
            (error) => {
              console.log(error);
            }
          );
        }
      }
    }
  };

  function createProfile() {
    return async (dispatch) => {
      //   dispatch(setProgress());
      try {
        const response = await instance.post(
          "/customsignup/?host=" + gethost(),
          {
            phone_number: phone,
            profile_type: profileType,
            company_id: companyId,
            dispute_id: caseId,
            user: {
              email: email,
              password: "Test@123",
              first_name: firstname,
              last_name: lastname,
            },
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        setOpenSnackBar2(true);
        return response.data;
      } catch (error) {
        console.log(error);
        // alert("ERROR")
        setOpenSnackBar1(true);
      }
    };
  }

  const body = (
    <div className={classes.paper}>
      <CloseModalButton onClick={handleCloseModal} />
      <Typography className={classes.createlogindetails}>
        Create Login Details
      </Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.caseId}>Case Id : {caseId}</Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.profileType}>
        Profile Type : {profileType}
      </Typography>
      <Box height="1.5rem"></Box>
      <Grid container justify="space-evenly" spacing={3}>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            fullWidth={true}
            value={firstname}
            onChange={onFirstNameChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth={true}
            value={lastname}
            onChange={onLastNameChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Phone Number"
            variant="outlined"
            type="number"
            fullWidth={true}
            value={phone}
            onChange={onPhoneChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email ID"
            variant="outlined"
            fullWidth={true}
            value={email}
            onChange={onEmailChange}
          />
        </Grid>
      </Grid>
      <Box height="2rem"></Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.bigbtn}
        onClick={onSubmit}
      >
        Create
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Problem in creating guest profile."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Problem in creating guest profile.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar2}
        onClose={handleCloseSnackBar2}
        message="Guest profile is created."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Profile is created.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar3}
        onClose={handleCloseSnackBar3}
        message="Please enter all the details."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="warning">
          Please enter all the details.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar4}
        onClose={handleCloseSnackBar4}
        message="Please enter valid email address."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter valid email address.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar5}
        onClose={handleCloseSnackBar5}
        message="Please enter valid phone number."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter valid phone number.
        </Alert>
      </Snackbar>
    </div>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
