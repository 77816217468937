import { createSlice } from "@reduxjs/toolkit";
import { chatInstance } from "api/globalaxios";

const userStatus = {
  0: "offline",
  1: "online",
  2: "away",
  3: "busy",
};

export const initialState = {
  loading: false,
  groups: [],
  activeGroup: "",
  members: {},
  files: [],
};

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setProgress: (state, { payload }) => {
      state.loading = payload;
    },
    setGroups: (state, { payload }) => {
      state.groups = [...payload];
    },
    setMembers: (state, { payload }) => {
      state.members = payload;
    },
    setError: (state) => {
      state.loading = false;
    },
    setActive: (state, { payload }) => {
      state.activeGroup = payload;
    },
    setFiles: (state, { payload }) => {
      state.files = payload;
    },
    updateGroups: (state, { payload }) => {
      const groups = state.groups;
      const groupId = payload?.latest_msg?.rid;
      const tempGroup = groups.splice(groups.findIndex(group => group._id === groupId) , 1)[0];
      if(tempGroup){
        tempGroup.lastMessage = payload?.latest_msg;
        if(payload?.activeGroup_msg){
          tempGroup.counters.unreads = 0;
          tempGroup.counters.userMentions = 0;
          markGroupAsRead(groupId);
        }
        else {
          tempGroup.counters.unreads = tempGroup.counters.unreads + 1;
          if(payload?.userMentions > 0){
            tempGroup.counters.userMentions += payload?.userMentions;
          }
        }
        groups.unshift(tempGroup);
        state.groups = [...groups];
      }
    },
  }
});

export const setAllMembers = (membersArray) => {
  return (dispatch) => {
    const membersObject = {};
    membersArray.forEach((member) => {
      membersObject[member._id] = { ...member };
    });
    dispatch(setMembers(membersObject));
  };
};

export const updateStatus = (statusArray, members) => {
  return (dispatch) => {
    const newMembers = { ...members };
    statusArray.forEach((status) => {
      if (!newMembers[status[0]]) {
        return;
      }
      const newMemberObject = { ...newMembers[status[0]] };
      newMemberObject.status = userStatus[status[2]];
      newMembers[status[0]] = newMemberObject;
    });
    dispatch(setMembers(newMembers));
  };
};

export const getGroupCounters = async (groupId) => {
  try {
    const res = await chatInstance({
      method: 'get',
      url: `/api/v1/groups.counters`,
      params: {
        roomId: groupId,
      },
    })
    return res?.data;
  }
  catch(err){
    console.log(err);
    return {}
  }
}

export const getAllGroups = (disputeId) => {
  return async (dispatch) => {
    dispatch(setProgress(true));
    try {
      const response = await chatInstance({
        method: "get",
        url: '/api/v1/groups.list?sort={"_updatedAt": -1}',
      });
      let { groups } = response.data;

      //TODO revert ot back to custom fields
      // let newGroups = groups.filter(
      //   (group) => group.customFields.disputeId === disputeId.toString()
      // );

      let newGroups = groups.filter((group) => {
        const nameArray = group.name.split("_");
        return nameArray[nameArray.length - 1] === disputeId.toString();
      });

      newGroups = await Promise.all(newGroups.map(async (group) => {
        const counters = await getGroupCounters(group._id);
        group.counters = counters;
        return group;
      }))

      // newGroups.sort((a, b) => {
      //   const nameA = a.name.toUpperCase();
      //   const nameB = b.name.toUpperCase();
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }
      //   return 0;
      // });

      dispatch(setGroups(newGroups));

      dispatch(setProgress(false));
    } catch (err) {
      console.log(err);
      // console.log(err.request.status);
      // console.log(err.message);
      // console.log(err.request.response);
      dispatch(setProgress(false));
    }
  };
};

export const getAllGroupsForRefresh = (disputeId) => {
  return async (dispatch) => {
    try {
      const response = await chatInstance({
        method: "get",
        url: '/api/v1/groups.list?sort={"_updatedAt": -1}',
      });
      let { groups } = response.data;
      let newGroups = groups.filter((group) => {
        const nameArray = group.name.split("_");
        return nameArray[nameArray.length - 1] === disputeId.toString();
      });

      newGroups = await Promise.all(newGroups.map(async (group) => {
        const counters = await getGroupCounters(group._id);
        group.counters = counters;
        return group;
      }))

      // newGroups.sort((a, b) => {
      //   const nameA = a.name.toUpperCase();
      //   const nameB = b.name.toUpperCase();
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }
      //   return 0;
      // });

      dispatch(setGroups(newGroups));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllDPCGCGroups = (disputeId) => {
  return async (dispatch) => {
    dispatch(setProgress(true));
    try {
      const response = await chatInstance({
        method: "get",
        url: '/api/v1/groups.list?sort={"_updatedAt": -1}',
      });
      let { groups } = response.data;

      //TODO revert ot back to custom fields
      // let newGroups = groups.filter(
      //   (group) => group.customFields.disputeId === disputeId.toString()
      // );

      const newGroups = groups.filter((group) => {
        const nameArray = group.name.split("_");
        return nameArray[nameArray.length - 1] === disputeId.toString();
      });

      dispatch(setGroups(newGroups));

      dispatch(setProgress(false));
    } catch (err) {
      console.log(err);
      // console.log(err.request.status);
      // console.log(err.message);
      // console.log(err.request.response);
      dispatch(setProgress(false));
    }
  };
};

export const fetchMembers = (groupId) => {
  return async (dispatch) => {
    try {
      const response = await chatInstance({
        method: "get",
        url: `/api/v1/groups.members`,
        params: {
          roomId: groupId,
        },
      });
      let {
        data: { members },
      } = response;
      dispatch(setAllMembers(members));
    } catch (err) {
      console.log(err);
    }
  };
};

export const uploadFile = (data) => {
  return async (dispatch) => {
    let { groupId, file, msg = "This is a message" } = data;
    const newData = new FormData();
    console.log(data);
    newData.append("file", file);
    newData.append("msg", msg);
    try {
      await chatInstance({
        method: "post",
        url: `/api/v1/rooms.upload/${groupId}`,
        data: newData,

        headers: {
          "content-type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getFiles = (groupId) => {
  return async (dispatch) => {
    try {
      const response = await chatInstance({
        method: "get",
        url: "/api/v1/groups.files",
        params: { roomId: groupId, sort: { uploadedAt: -1 } },
      });
      dispatch(setFiles(response.data.files));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setNoGroups = () => {
  return async (dispatch) => {
    dispatch(setProgress(true));
    try {
      dispatch(setGroups([]));
      dispatch(setProgress(false));
    } catch (err) {
      console.log(err);
      dispatch(setProgress(false));
    }
  };
};

export const markGroupAsRead = async (groupId) => {
  try {
      const response = await chatInstance({
        method: "post",
        url: "/api/v1/subscriptions.read",
        data: { rid: groupId },
      });
    } catch (err) {
      console.log(err);
    }
}

export const {
  setProgress,
  setGroups,
  setError,
  setActive,
  setMembers,
  setFiles,
  updateGroups,
} = groupSlice.actions;

export default groupSlice.reducer;
