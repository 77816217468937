import { Box, Typography } from '@material-ui/core'
import React from 'react'

function FailPage() {
  return (
    <Box>
        <Typography variant='h2'>Payment is failed.</Typography>
    </Box>
  )
}

export default FailPage
