import moment from "moment-timezone";

/**
 * Convert utc date time string to local timezone moment
 * @param {*} utcDateTimeString
 * @returns LocalTime
 */
export function convertUTCToLocalMomentTime(
  utcDateTimeString,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const utcDateTime = moment.utc(utcDateTimeString);
  return utcDateTime.tz(timezone);
}

/**
 * Returns user's timezone
 */
export function getTimeZone() {
  return moment.tz.guess();
}

// /**
//  *
//  * @param {*} meeting
//  * @param {*} timezone
//  * @returns Formatted String with timezone info
//  */
// export function formatMeetingDate(utcDateTimeString, timezone=getTimeZone()) {
//   // Parse the date string into a Date object
//   const date = new Date(utcDateTimeString);
//   if (!timezone){
//     timezone = 'Asia/Kolkata'
//   }

//   // Check if the date is valid
//   if (isNaN(date.getTime())) {
//     console.error("Invalid date format:", utcDateTimeString);
//     return "Invalid Date";
//   }

//   // Format the time part (12-hour with AM/PM)
//   const timeFormatter = new Intl.DateTimeFormat("en-US", {
//     hour: "numeric",
//     minute: "numeric",
//     hour12: true,
//     timeZone: timezone,
//   });
//   const timePart = timeFormatter.format(date);

//   // Format the date part (9th November 2024)
//   const dateFormatter = new Intl.DateTimeFormat("en-GB", {
//     day: "numeric",
//     month: "long",
//     year: "numeric",
//     timeZone: timezone,
//   });
//   const datePart = dateFormatter.format(date);

//   // Format the timezone part (GMT+5:30)
//   const timeZoneFormatter = new Intl.DateTimeFormat("en-US", {
//     timeZoneName: "short",
//     timeZone: timezone,
//   });
//   const timeZonePart = timeZoneFormatter
//     .formatToParts(date)
//     .find((part) => part.type === "timeZoneName").value;

//   // Add the ordinal suffix to the day
//   const day = datePart.split(" ")[0];
//   const ordinalSuffix = getOrdinalSuffix(day);
//   const formattedDatePart = datePart.replace(day, day + ordinalSuffix);

//   // Combine all parts into the desired format
//   return `${timePart} on ${formattedDatePart} ${timeZonePart}`;
// }

// Helper function to add ordinal suffix to a number
// function getOrdinalSuffix(day) {
//   if (day === "1" || day === "21" || day === "31") {
//     return "st";
//   } else if (day === "2" || day === "22") {
//     return "nd";
//   } else if (day === "3" || day === "23") {
//     return "rd";
//   } else {
//     return "th";
//   }
// }

export function getMeetingTimeZoneInfo(
  utcDateTimeString,
  timezone = getTimeZone()
) {
  // Parse the date string into a Date object
  const date = new Date(utcDateTimeString);
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date format:", utcDateTimeString);
    return "Invalid Date";
  }

  const timeZoneFormatter = new Intl.DateTimeFormat("en-US", {
    timeZoneName: "short",
    timeZone: timezone,
  });
  const timeZonePart = timeZoneFormatter
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName").value;

  return `${timeZonePart}`;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12 December, 2012 at 12:00 PM GMT+5:30
 */
export function getMeetingTimeFormatwithDateFirstAndComma(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("D MMMM, YYYY [at] hh:mm A") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12/12/2012 at 12:00 PM GMT+5:30
 */
export function getMeetingTimeFormatwithDateFirstAndCommaDIFC(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("D/MM/YYYY [at] hh:mm A") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12 December 2012 12:00 PM GMT+5:30
 */
export function getMeetingTimeFormatwithDateFirst(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("D MMMM YYYY hh:mm A") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12/12/2012 12:00 PM GMT+5:30
 */
export function getMeetingTimeFormatwithDateFirstDIFC(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("D/MM/YYYY hh:mm A") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12:00 PM on 12 December, 2012 GMT+5:30
 */
export function getMeetingTimeFormatwithTimeFirstAndComma(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("hh:mm A [on] D MMMM, YYYY") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12:00 PM on 12/12/2012 GMT+5:30
 */
export function getMeetingTimeFormatwithTimeFirstAndCommaDIFC(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("hh:mm A [on] D/MM/YYYY") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12:00 PM on 12th December 2012 GMT+5:30
 */
export function getMeetingTimeFormatwithTimeFirst(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("h:mm A [on] Do MMMM YYYY") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}

/**
 *
 * @param {*} utc_date_time
 * @param {*} timezone
 * @returns formatted date in this format : 12:00 PM on 12th December 2012 GMT+5:30
 */
export function getMeetingTimeFormatwithTimeFirstDIFC(
  utc_date_time,
  timezone = getTimeZone()
) {
  if (!timezone) {
    timezone = "Asia/Kolkata";
  }
  const local_date_time = convertUTCToLocalMomentTime(utc_date_time, timezone);
  let formatted_date_time =
    "" +
    local_date_time.format("h:mm A [on] D/MM/YYYY") +
    " " +
    getMeetingTimeZoneInfo(utc_date_time, timezone);
  return formatted_date_time;
}
