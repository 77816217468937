import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import instance from "api/globalaxios";
import { backgrounds } from "contexts/BackgroundContext";
import { useBodyBackgroundChange } from "hooks";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import Chart from "react-google-charts";
import { FormattedMessage } from "react-intl";
import { DateRangePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isDigipub, isHotStar, isMuthoot, isStar } from "Routes/Routes";
import {
  ComplianceCompanyReportDocxGenerator,
  ComplianceCompanyReportPdfGenerator,
  ComplianceReportDocxGenerator,
  ComplianceReportPdfGenerator,
  ComplianceSROReportDocxGenerator,
  ComplianceSROReportPdfGenerator,
  Level1ReportGenerator,
  PublishComplianceReport,
} from "utils/apituils";
import "./Analytics.css";
import DisputeRow from "./DisputeRow";
import arrowDownImage from "./down-arrow.svg";
import PieChart from "./NewPieChart";
import Tabl from "./Table";
import arrowUpImage from "./up-arrow.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "3.6rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.8rem",
    },
  },
  value: {
    // fontSize: 14,
    border: "none",
    padding: "0.5rem",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
}));

const useStyle = makeStyles((theme) => ({
  heading: {
    fontSize: "2rem",
    lineHeight: "3rem",
  },
  barGraphContainer: {
    width: "60%",
    marginTop: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pieContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rangeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  formControl: {
    minWidth: "10rem",
  },
  colorsValuesContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  listtext: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textTransform: "capitalize",
    color: "#454545",
    paddingBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

const colors = ["#3F51B5", "#D9D7D7", "#454545"];

export default function Analytics({
  start_Date,
  set_start,
  end_Date,
  set_end,
  Nodc,
  NodSRO,
  TimeTakenCom,
  TimeTakenSRO,
  TimeTakenCompanyandSRO,
  averageTimeCompany,
  averageTimeSRO,
  averageTimeCompanySRO,
  companyActionTaken,
  SROActionTaken,
  UserId,
}) {
  useBodyBackgroundChange(backgrounds.WHITE);

  const Checkbox = (props) => (
    <input type="checkbox" style={{ margin: "15px" }} {...props} />
  );

  const [noOfDisputes, setNoOfDisputes] = useState(true);
  const handleNoOfDisputes = (event) => {
    setNoOfDisputes(event.target.checked);
  };

  const [action, setAction] = useState(true);
  const handleAction = (event) => {
    setAction(event.target.checked);
  };

  const [timeTaken, setTimeTaken] = useState(true);
  const handleTimeTaken = (event) => {
    setTimeTaken(event.target.checked);
  };

  const [feedback, setFeedback] = useState(true);
  const handleFeedback = (event) => {
    setFeedback(event.target.checked);
  };

  const [displayCompany, setdisplayCompany] = useState(false);
  const [countCompany, setcountCompany] = useState(0);

  const [displaySRO, setdisplaySRO] = useState(false);
  const [countSRO, setcountSRO] = useState(0);

  const [displayCompanySRO, setdisplayCompanySRO] = useState(false);
  const [countCompanySRO, setcountCompanySRO] = useState(0);
  const [rating, setrating] = useState(4);

  const [searchText, setSearchText] = useState("");
  const [disputes, setDisputes] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    if (searchText != "") {
      dispatch(gethotstardata(searchText));
      setLoading(false);
    } else {
      setDisputes(null);
      setLoading(true);
    }
  }, [searchText]);

  let arrActionsTakenDummy = [
    ["Element", "value", { role: "style" }],

    ["Apology", 60, "#3F51B5"],
    ["Redaction", 12, "#454545"],
    ["Compensation", 34, "#D9D7D7"],
  ];

  let arrActionsTakenCompany = [
    ["Element", "value", { role: "style" }],

    [
      "Taken down",
      !isEmpty(companyActionTaken) ? companyActionTaken[0].value : 0,
      "#3F51B5",
    ],
    [
      "Redacted",
      !isEmpty(companyActionTaken) ? companyActionTaken[1].value : 0,
      "#454545",
    ],
    [
      "Apologised",
      !isEmpty(companyActionTaken) ? companyActionTaken[2].value : 0,
      "#D9D7D7",
    ],
    [
      "Warning",
      !isEmpty(companyActionTaken) ? companyActionTaken[3].value : 0,
      "#3F51B5",
    ],
    [
      "Dismissed",
      !isEmpty(companyActionTaken) ? companyActionTaken[4].value : 0,
      "#454545",
    ],
  ];

  let arrActionsTakenSRO = [
    ["Element", "value", { role: "style" }],

    [
      "Taken down",
      !isEmpty(SROActionTaken) ? SROActionTaken[0].value : 0,
      "#3F51B5",
    ],
    [
      "Redacted",
      !isEmpty(SROActionTaken) ? SROActionTaken[1].value : 0,
      "#454545",
    ],
    [
      "Apologised",
      !isEmpty(SROActionTaken) ? SROActionTaken[2].value : 0,
      "#D9D7D7",
    ],
    [
      "Warning",
      !isEmpty(SROActionTaken) ? SROActionTaken[3].value : 0,
      "#3F51B5",
    ],
    [
      "Dismissed",
      !isEmpty(SROActionTaken) ? SROActionTaken[4].value : 0,
      "#454545",
    ],
  ];

  var opti = {
    legend: { position: "none" },
    bar: { groupWidth: "95%" },
    width: "90%",
    vAxis: { format: "0" },
  };

  const toggleCompany = () => {
    let t = displayCompany;
    setdisplayCompany(!t);
  };

  const toggleSRO = () => {
    let t = displaySRO;
    setdisplaySRO(!t);
  };

  const toggleCompanySRO = () => {
    let t = displayCompanySRO;
    setdisplayCompanySRO(!t);
  };

  function searchHandler(event) {}

  const ColorValues = ({ colors, data }) => {
    return (
      <div className="compnayLevelRightPnale">
        {data.map((singleData, index) => {
          return (
            <Box
              className="companyColor"
              display="flex"
              mb="0.7rem"
              key={singleData.name}
            >
              <Box
                height="1rem"
                width="1rem"
                style={{ backgroundColor: colors[index] }}
              ></Box>
              <Box width="0.3rem"></Box>
              <Typography>{singleData.name}</Typography>
              <Box className="countMargin">
                <br />
                <b>{singleData.value}</b>
              </Box>
            </Box>
          );
        })}
      </div>
    );
  };
  const dispatch = useDispatch();

  function gethotstardata(movie) {
    return async (dispatch) => {
      try {
        const response = await instance.get(`/api/HotstarsearchView/${movie}/`);
        setDisputes(response.data.dispute_id);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function DisputesList() {
    const classes = useStyles(0);
    const history = useHistory();
    const data = [
      {
        title: <FormattedMessage id="dispute.disputeId" />,
      },
      {
        title: <FormattedMessage id="name" />,
      },

      {
        title: <FormattedMessage id="dispute.startDate" />,
      },
    ];

    return (
      <Container>
        {!isEmpty(disputes) && (
          <React.Fragment>
            <Box height="0.5rem"></Box>
            <div className={classes.paper}>
              <Grid container justify="flex-start">
                {data.map(({ title }) => {
                  return (
                    <Grid sm={4} xs={4} key={title}>
                      <Box className={classes.contentContainer}>
                        <div>
                          <Typography className={classes.title}>
                            {title}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              {!loading &&
                disputes.map((dispute) => {
                  return <DisputeRow key={dispute.id} dispute={dispute} />;
                })}
            </div>
          </React.Fragment>
        )}
      </Container>
    );
  }

  function Company() {
    if (displayCompany) {
      document.getElementById("icon1").src = arrowUpImage;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {noOfDisputes && (
            <div class="outer short  small-width">
              <h4>Number of grievances</h4>
              <p>
                Total{" "}
                <b>{!isEmpty(Nodc) ? Nodc[0].value + Nodc[1].value : 0}</b>
              </p>
              <Box className="pie">
                <PieChart data={Nodc} colors={colors} />
              </Box>
              <Box className="piecolor">
                <ColorValues data={Nodc} colors={colors} />
              </Box>
            </div>
          )}

          {timeTaken && (
            <div class="outer short" style={{ height: "32rem" }}>
              <div class="companyTimeTaken">
                <section>Time taken to close the grievance</section>
                <br />
                Average time taken at Company level{" "}
                <b>
                  {isNaN(averageTimeCompany) ? 0 : `${averageTimeCompany} days`}
                </b>
              </div>
              <div className="space-btm my-custom-scrollbar">
                <Tabl data={TimeTakenCom} />
              </div>
              <div class="companyExpand">
                <p>EXPAND LIST</p>
              </div>
            </div>
          )}
          {action && (
            <div class="outer short">
              <div class="companyTimeTaken">
                <section>Actions taken</section>
                <br />
                Actions taken by the Grievance Redressal Officer
              </div>
              <div class="ActionsTaken">
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={arrActionsTakenCompany}
                  options={opti}
                />
              </div>
            </div>
          )}
          {feedback && !isHotStar && !isStar && !isMuthoot && (
            <div class="outer short" style={{ height: "15rem" }}>
              <div class="companyTimeTaken">
                <section>Feedback</section>
                <br />
                Average Satisfaction & feedback ratings from customers
              </div>
              <div>
                <div class="rating">
                  <Rating value={rating} readOnly size="large" />
                </div>
              </div>
            </div>
          )}

          <div class="buttons">
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceCompanyReportPdfGenerator(start_Date, end_Date);
                } else {
                  alert("Please Select Appropriate Dates");
                }
              }}
              value="Download .PDF"
              style={{
                background: "#3F51B5",
                color: "white",
                cursor: "pointer",
              }}
            />
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceCompanyReportDocxGenerator(start_Date, end_Date);
                } else {
                  alert("Please Select Appropriate Dates");
                }
              }}
              value="Download .DOCX"
              style={{
                background: " #454545",
                color: "white",
                cursor: "pointer",
              }}
            />
            {(UserId == 236 || UserId == 221 || isDigipub) && (
              <input
                type="submit"
                onClick={(e) => {
                  if (start_Date && end_Date) {
                    Level1ReportGenerator(start_Date, end_Date);
                  } else {
                    alert("Please Select Appropriate Dates");
                  }
                }}
                value="Generate Compliance Report"
                style={{
                  background: "#3F51B5",
                  color: "white",
                  cursor: "pointer",
                  width: "auto",
                }}
              />
            )}
          </div>
          {(UserId == 236 || UserId == 356 || UserId == 390) && (
            <div class="buttons">
              <input
                type="submit"
                onClick={(e) => {
                  if (start_Date && end_Date) {
                    PublishComplianceReport(start_Date, end_Date);
                  } else {
                    alert("Please Select Appropriate Dates");
                  }
                }}
                value="Publish Compliance Report"
                style={{
                  background: "#3F51B5",
                  color: "white",
                  cursor: "pointer",
                  width: "auto",
                }}
              />
            </div>
          )}
        </div>
      );
    } else {
      if (countCompany != 0) {
        document.getElementById("icon1").src = arrowDownImage;
        return null;
      } else {
        setcountCompany(1);
        return null;
      }
    }
  }

  function SRO() {
    if (displaySRO) {
      document.getElementById("icon2").src = arrowUpImage;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {noOfDisputes && (
            <div class="outer short  small-width">
              <h4>Number of grievances raised</h4>
              <p>
                Total{" "}
                <b>
                  {!isEmpty(NodSRO) ? NodSRO[0].value + NodSRO[1].value : 0}
                </b>
              </p>
              <Box className="pie">
                <PieChart data={NodSRO} colors={colors} />
              </Box>
              <Box className="piecolor">
                <ColorValues data={NodSRO} colors={colors} />
              </Box>
            </div>
          )}
          {/* {
          noOfDisputes && startDate && endDate ?
            <DrawPieChartData numOfDisputes={NoOfDisputesSROData}/> :
            <DrawPieChartData numOfDisputes={NodSRO}/>
          } */}
          {timeTaken && (
            <div class="timeTakenCard">
              <div class="companyTimeTaken">
                <section>Time taken to close the grievance</section>
                <br />
                Average time taken at SRO Level{" "}
                <b>{isNaN(averageTimeSRO) ? 0 : `${averageTimeSRO} days`}</b>
              </div>
              <div className="space-btm my-custom-scrollbar">
                <Tabl data={TimeTakenSRO} />
              </div>
              <div class="companyExpand">
                <p>EXPAND LIST</p>
              </div>
            </div>
          )}
          {action && (
            <div class="outer short">
              <div class="companyTimeTaken">
                <section>Actions recommended</section>
                <br />
                Actions recommended by SRO
              </div>
              <div class="ActionsTaken">
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={arrActionsTakenSRO}
                  options={opti}
                />
              </div>
            </div>
          )}

          <div class="buttons">
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceSROReportPdfGenerator(start_Date, end_Date);
                } else {
                  alert("Please Select Appropriate Dates");
                }
              }}
              value="Download .PDF"
              style={{
                background: "#3F51B5",
                color: "white",
                cursor: "pointer",
              }}
            />
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceSROReportDocxGenerator(start_Date, end_Date);
                } else {
                  alert("Please setlect Dates");
                }
              }}
              value="Download .DOCX"
              style={{
                background: " #454545",
                color: "white",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      );
    } else {
      if (countSRO != 0) {
        document.getElementById("icon2").src = arrowDownImage;
        return null;
      } else {
        setcountSRO(1);
        return null;
      }
    }
  }

  function CompanySRO() {
    if (displayCompanySRO) {
      document.getElementById("icon3").src = arrowUpImage;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {timeTaken && (
            <div class="outer short" style={{ height: "32rem" }}>
              <div class="companyTimeTaken">
                <section>Time taken to close the grievance</section>
                <br />
                Average time taken at both levels{" "}
                <b>
                  {isNaN(averageTimeCompanySRO)
                    ? 0
                    : `${averageTimeCompanySRO} days`}
                </b>
              </div>
              <div className="space-btm my-custom-scrollbar">
                <Tabl data={TimeTakenCompanyandSRO} />
              </div>
              <div class="companyExpand">
                <p>EXPAND LIST</p>
              </div>
            </div>
          )}

          <div class="buttons">
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceReportPdfGenerator(start_Date, end_Date);
                } else {
                  alert("Please Select Appropriate Dates");
                }
              }}
              value="Download .PDF"
              style={{
                background: "#3F51B5",
                color: "white",
                cursor: "pointer",
              }}
            />
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceReportDocxGenerator(start_Date, end_Date);
                } else {
                  alert("Please setlect Dates");
                }
              }}
              value="Download .DOCX"
              style={{
                background: " #454545",
                color: "white",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      );
    } else {
      if (countCompanySRO != 0) {
        document.getElementById("icon3").src = arrowDownImage;
        return null;
      } else {
        setcountCompanySRO(1);
        return null;
      }
    }
  }

  return (
    <div>
      <div class="outer outer-box">
        <div class="inner1">
          <b>Search analytics for:</b>
        </div>
        <div class="inner2">
          <div class="inner21">
            <label>
              <Checkbox
                name="cht"
                checked={noOfDisputes}
                onChange={handleNoOfDisputes}
              />
              <span>Number of grievances</span>
            </label>
            <label>
              <Checkbox name="cht" checked={action} onChange={handleAction} />
              <span>Action taken/recommended</span>
            </label>
            <label>
              <Checkbox
                name="cht"
                checked={timeTaken}
                onChange={handleTimeTaken}
              />
              <span>Time taken</span>
            </label>
            {!isHotStar && !isStar && !isMuthoot && (
              <label>
                <Checkbox
                  name="cht"
                  checked={feedback}
                  onChange={handleFeedback}
                />
                <span>Feedback</span>
              </label>
            )}
            {/* <label>
              <Checkbox
                name="cht"
                checked={feedback}
                onChange={handleFeedback}
              />
              <span>Feedback</span>
            </label> */}
          </div>
          <div class="inner22">
            <div class="inner221">
              <div class="inner221main">
                <DateRangePicker
                  startDate={start_Date}
                  endDate={end_Date}
                  onStartDateChange={set_start}
                  onEndDateChange={set_end}
                  maximumDate={new Date()}
                  // minimumLength={1}
                  format="dd/MM/yyyy"
                >
                  {({ startDateInputProps, endDateInputProps, focus }) => (
                    <div className="date-range">
                      <div class="date-stye">
                        <span>Date</span>
                      </div>
                      <div class="date-left">
                        <input
                          {...startDateInputProps}
                          placeholder="Start date"
                        />
                        -
                        <input {...endDateInputProps} placeholder="End date" />
                      </div>
                      <div class="date-right">
                        <img src="/calender.png" alt="" class="calendericon" />
                      </div>
                    </div>
                  )}
                </DateRangePicker>
              </div>
            </div>

            <div className="inner222">
              <input
                type="submit"
                className="input1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  set_start();
                  set_end();
                }}
                value="CLEAR"
              />
              {/* <input type="submit" className="input1 input2" onClick={e => searchHandler(e)} value="SEARCH" /> */}
            </div>
          </div>
        </div>
      </div>

      {UserId != 272 && (
        <>
          <div class="outer company outer-box1" onClick={toggleCompany}>
            <div class="companytext">
              <p>Company level</p>
            </div>
            <div class="companyicon">
              <span>
                <img id="icon1" src={arrowDownImage} />
              </span>
            </div>
          </div>
          <Company />
        </>
      )}
      {UserId != 236 && UserId != 221 && !isDigipub && (
        <>
          <div class="outer company asdf outer-box1" onClick={toggleSRO}>
            <div class="companytext">
              <p>SRO level</p>
            </div>
            <div class="companyicon">
              <span>
                <img id="icon2" src={arrowDownImage} />
              </span>
            </div>
          </div>
          <SRO />
        </>
      )}

      {UserId != 236 && UserId != 272 && UserId != 221 && !isDigipub && (
        <>
          <div
            class="outer company asdf bottomMargin outer-box1"
            onClick={toggleCompanySRO}
          >
            <div class="companytext">
              <p>Company & SRO </p>
            </div>
            <div class="companyicon">
              <span>
                <img id="icon3" src={arrowDownImage} />
              </span>
            </div>
          </div>
          <CompanySRO />
        </>
      )}
      {(isHotStar || isStar || isMuthoot) && (
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <input
            type="text"
            placeholder="Title of the Content"
            style={{ fontSize: "30px", width: "35%" }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          {!loading && <DisputesList />}
        </div>
      )}
    </div>
  );
}
