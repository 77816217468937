import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { format } from "date-fns";
import FileImage from "images/FileCircle.svg";
import FolderImage from "images/FolderCircle.svg";
import React, { useEffect, useState } from "react";
import { isDifc } from "Routes/Routes";
import {
  convertUTCToLocalMomentTime,
  getMeetingTimeFormatwithDateFirstAndComma,
  getMeetingTimeFormatwithDateFirstAndCommaDIFC,
  getMeetingTimeFormatwithTimeFirstAndComma,
  getMeetingTimeFormatwithTimeFirstAndCommaDIFC,
} from "slices/date";

export default function ActivityRow({
  bytesToSize,
  classes,
  handleClickOnFileFolderName,
  handleClickOnInvoice,
  item,
  profile,
}) {
  let isFolder = !item.is_for_file;
  const fileId = item?.file;
  const folderId = item?.folder;
  const disputeId = item?.dispute?.id;
  const file_or_folder_name =
    item.category === "payment"
      ? item.file_or_folder_name.split("_")[0]
      : item?.file_or_folder_name;
  const invoice_id =
    item.category === "payment" ? item.file_or_folder_name.split("_")[1] : null;
  const utc_date_time = item.date_time;

  const [formatted_local_time, setFormatted_local_time] = useState("");
  useEffect(() => {
    if (utc_date_time && item?.activity_name.includes("#")) {
      if (item.activity_name.includes("A hearing has been scheduled for")) {
        if (isDifc) {
          setFormatted_local_time(
            getMeetingTimeFormatwithDateFirstAndCommaDIFC(
              utc_date_time,
              profile?.profile?.timezone
            )
          );
        } else {
          setFormatted_local_time(
            getMeetingTimeFormatwithDateFirstAndComma(
              utc_date_time,
              profile?.profile?.timezone
            )
          );
        }
      } else if (item.activity_name.includes("Your hearing scheduled for")) {
        if (isDifc) {
          setFormatted_local_time(
            getMeetingTimeFormatwithTimeFirstAndCommaDIFC(
              utc_date_time,
              profile?.profile?.timezone
            )
          );
        } else {
          setFormatted_local_time(
            getMeetingTimeFormatwithTimeFirstAndComma(
              utc_date_time,
              profile?.profile?.timezone
            )
          );
        }
      } else {
        const local_date_time = convertUTCToLocalMomentTime(
          utc_date_time,
          profile?.profile?.timezone
        );
        setFormatted_local_time(local_date_time.format("hh:mm A"));
      }
    }
  }, [item, utc_date_time]);

  return (
    <React.Fragment key={item.id}>
      <Paper className={classes.activityPaper}>
        {(item.category === "files" ||
          (item.category === "payment" && item.file_or_folder_name)) && (
          <>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <img
                  src={isFolder ? FolderImage : FileImage}
                  alt={isFolder ? "folder image" : "file image"}
                  style={
                    fileId || folderId || invoice_id
                      ? { height: "3.5rem", cursor: "pointer" }
                      : { height: "3.5rem" }
                  }
                  onClick={() => {
                    if (item.category === "files")
                      handleClickOnFileFolderName(
                        isFolder,
                        fileId,
                        folderId,
                        disputeId
                      );
                    else handleClickOnInvoice(invoice_id);
                  }}
                />
              </Grid>
              <Grid item sm={10} xs={9}>
                <Typography
                  className={classes.activityfileFolderName}
                  style={
                    !fileId && !folderId && !invoice_id
                      ? { textDecoration: "none", cursor: "default" }
                      : null
                  }
                  onClick={() => {
                    if (item.category === "files")
                      handleClickOnFileFolderName(
                        isFolder,
                        fileId,
                        folderId,
                        disputeId
                      );
                    else handleClickOnInvoice(invoice_id);
                  }}
                >
                  {file_or_folder_name}
                </Typography>
                {item.category === "files" && (
                  <Typography className={classes.activityTime}>
                    {bytesToSize(item?.size)}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box height="0.7rem" />
          </>
        )}
        <Typography className={classes.activityUsername} display="inline">
          {item.uploaded_by
            ? `${item.uploaded_by.first_name} ${item.uploaded_by.last_name} `
            : ""}
        </Typography>
        <Typography className={classes.activityheading} display="inline">
          {utc_date_time && item?.activity_name.includes("#")
            ? item?.activity_name.replace("#", formatted_local_time)
            : item?.activity_name}
        </Typography>
        <Typography className={classes.activityTime}>
          {isDifc &&
            (item?.activity_name.includes("You have a case hearing upcoming")
              ? `${format(new Date(item?.created), "dd/MM/yyyy")} - `
              : `${format(new Date(item?.created), "dd/MM/yyyy, h:mm a")} - `)}
          {!isDifc &&
            (item?.activity_name.includes("You have a case hearing upcoming")
              ? `${format(new Date(item?.created), "d MMMM y")} - `
              : `${format(new Date(item?.created), "d MMMM y, h:mm a")} - `)}
          {item?.dispute?.name}
        </Typography>
      </Paper>
      <Box height="1rem" />
    </React.Fragment>
  );
}
