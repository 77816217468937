import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import instance from "api/globalaxios";
import React, { useState } from "react";
import {
  getFiles,
  getFolder,
  getFolders,
  UpdateFile,
  UpdateFolder,
} from "./FileApi";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    position: "absolute",
    left: "50%",
    top: "45%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      top: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      // top: "45%",
    },
  },
  firstText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  selected: {
    background: "#F2F2F2",
    padding: "0.3rem 1.3rem",
  },
  unselected: {
    padding: "0.3rem 1.3rem",
    cursor: "pointer",
  },
  choiceText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  fieldsText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    marginTop: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.3rem",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  inputfield: {
    width: "100%",
    height: "3rem",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    "&:focus": {
      outline: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  dropbox: {
    width: "100%",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "1rem 2px",
    textAlign: "center",
  },
  alertSelected: {
    padding: "0.3rem",
    background: "#E0E0E0",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  alertUnSelected: {
    padding: "0.3rem",
    cursor: "pointer",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  cancel: {
    textDecoration: "underline",
    fontSize: "20px",
    fontWeight: "400",
    color: "#2F80ED",
    lineHeight: "36px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "26px",
    },
  },
  add: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: "36px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  addbtn: {
    background: "#2F80ED",
    marginLeft: "3rem",
    width: "120px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      marginLeft: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
      marginLeft: "1rem",
    },
  },
  permissionsTable: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  permissionsTableRows: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  box: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "20rem",
    cursor: "all-scroll",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "15rem",
    },
  },
}));

export default function SendForSign({
  openmodal,
  handleCloseModal,
  disputeId,
  file,
  allProfiles,
  parent_folder,
}) {
  const classes = useStyles();

  const [viewPermissions, setViewPermissions] = useState([]);

  const [loading, setLoading] = useState(false);

  async function sendFileModelForSign() {
    let emaildata = [];
    viewPermissions.forEach((item) => {
      let profile = allProfiles.find((p) => p.user.id == item);
      if (profile) {
        let data = {
          email: profile.user.email,
          name: profile.user.first_name + " " + profile.user.last_name,
        };
        emaildata.push(data);
      }
    });
    return await instance.post(`/getfilesigned/${file.id}/`, {
      signemailsdata: emaildata,
    });
  }

  const handleSign = () => {
    setLoading(true);
    sendFileModelForSign().then((res) => {
      handleCloseModal();
      setViewPermissions([]);
      setLoading(false);
    });
  };

  const body = (
    <Box className={classes.paper}>
      <Grid container>
        <Grid item className={classes.selected}>
          <Typography className={classes.choiceText}>Send For Sign</Typography>
        </Grid>
      </Grid>
      <Box height="1rem" />
      <Grid container className={classes.container} justify="center">
        <Box
          style={{
            background: "#F2F2F2",
            width: "100%",
            border: "1px solid #E0E0E0",
          }}
        >
          <Box
            style={{
              textAlign: "center",
            }}
            className={classes.box}
          >
            <Typography className={classes.permissionsTable}>
              Choose members
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container className={classes.container} justify="center">
        <Grid container style={{ width: "100%" }}>
          <Grid
            item
            xs={6}
            style={{
              height: "-webkit-fill-available",
              borderBottom: "1px solid #E0E0E0",
              borderLeft: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Box
              style={{
                background: "#F2F2F2",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className={classes.box}>
                <Typography className={classes.permissionsTable}>
                  Name
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              height: "-webkit-fill-available",
              borderBottom: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Box
              style={{
                background: "#F2F2F2",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className={classes.box}>
                <Typography className={classes.permissionsTable}>
                  Profile type
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              height: "-webkit-fill-available",
              borderBottom: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Box
              style={{
                background: "#F2F2F2",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                style={{
                  textAlign: "center",
                }}
                className={classes.box}
              >
                <Typography className={classes.permissionsTable}>
                  Choose
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.scroll}>
        {allProfiles.map((item) => {
          let checkedView = "";
          if (viewPermissions.indexOf(item.user.id) != -1) {
            checkedView = true;
          } else {
            checkedView = false;
          }
          const handleViewCheckbox = (e) => {
            checkedView = e.target.checked;
            if (e.target.checked) {
              let arr = [...viewPermissions];
              arr.push(item.user.id);
              setViewPermissions(arr);
            } else {
              let arr = [...viewPermissions];
              let index = arr.indexOf(item.user.id);
              if (index > -1) {
                arr.splice(index, 1);
                setViewPermissions(arr);
              }
            }
          };

          return (
            <Grid
              container
              className={classes.container}
              justify="center"
              key={item.user.id}
            >
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    height: "-webkit-fill-available",
                    borderBottom: "1px solid #E0E0E0",
                    borderLeft: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    style={{
                      background: "#F2F2F2",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box className={classes.box}>
                      <Typography className={classes.permissionsTableRows}>
                        {item.user.first_name} {item.user.last_name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    height: "-webkit-fill-available",
                    borderBottom: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    style={{
                      background: "#F2F2F2",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box className={classes.box}>
                      <Typography className={classes.permissionsTableRows}>
                        {item.profile.profile_type}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    height: "-webkit-fill-available",
                    borderBottom: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    style={{
                      background: "#F2F2F2",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      style={{
                        textAlign: "center",
                      }}
                      className={classes.box}
                    >
                      <input
                        type="checkbox"
                        checked={checkedView}
                        onChange={handleViewCheckbox}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <Box height="1rem" />
      <Grid container justify="flex-end" className={classes.container}>
        <Grid item>
          <Typography className={classes.cancel} onClick={handleCloseModal}>
            Cancel
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.addbtn}
            onClick={handleSign}
            disabled={loading || viewPermissions.length == 0}
          >
            <Typography className={classes.add}>Send</Typography>
          </Button>
        </Grid>
      </Grid>
      <Box height="1rem" />
    </Box>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
