import {
  Backdrop,
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { auth, signInWithGoogle } from "app/firebase";
import GoogleIcon from "images/google-icon.svg";
import React from "react";
import { useDispatch } from "react-redux";
import { socialAuth } from "slices/loginSlice";
import { isMSME, isSebi } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  container: {},
  button: {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 2px rgba(0,0,0,0.6)",
    transition: "all 0.5s",
    "&:hover": {
      // color: "#DB4437",
      // border: "1px solid #DB4437",
      // backgroundColor: "transparent",
      boxShadow: "1px 1px 10px rgba(0,0,0,0.6)",
    },
  },
  buttonForSebi: {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    borderRadius: 0,
    padding: "0.7rem",
    textTransform: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const setGoogleLoading = (value) => {
  localStorage.setItem("googleLoading", value);
};

export default function GoogleAuth() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isGoogleAuthenticating = localStorage.getItem("googleLoading");

  const [loading, setLoading] = React.useState(isGoogleAuthenticating);

  React.useEffect(() => {
    auth
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          auth.currentUser.getIdToken(true).then((idToken) => {
            dispatch(socialAuth({ idToken })).then(() => {
              setLoading(false);
              setGoogleLoading(false);
            });
          });
        } else {
          setLoading(false);
          setGoogleLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleLoginClick = () => {
    signInWithGoogle();
    setGoogleLoading(true);
  };

  return (
    <div className={classes.container}>
      {loading && (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Button
        variant={isSebi || isMSME ? "outlined" : "contained"}
        color="error"
        className={isSebi || isMSME ? classes.buttonForSebi : classes.button}
        onClick={handleLoginClick}
        startIcon={
          <img
            style={
              isSebi || isMSME
                ? { width: "2rem", height: "1.25rem" }
                : { width: "2rem", height: "2rem" }
            }
            src={GoogleIcon}
            alt="Google"
          />
        }
        fullWidth
      >
        {isSebi || isMSME ? "Sign in with Google" : "Google Sign in"}
      </Button>
    </div>
  );
}
