import React, { Fragment, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getQuestionsAnonymous } from "slices/questionSlice";
import Questions from "./Questions";

export default function QuestionsContainer({ profile }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getQuestionsAnonymous(
        activeDispute.company_product,
        activeCase.type,
        profile.user.email
      )
    );
  }, []);

  const answerLoading = useSelector((state) => state.answers.loading);

  let {
    questions: { questions },
    disputes: { activeDispute },
    cases: { activeCase },
  } = useSelector((state) => state, shallowEqual);

  return (
    <Fragment>
      {questions.length > 0 && (
        <Questions
          profile={profile}
          questions={questions}
          activeDispute={activeDispute}
          activeCase={activeCase}
          answerLoading={answerLoading}
        />
      )}
    </Fragment>
  );
}
