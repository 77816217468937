import React, {Fragment} from "react";
import {
    Box,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    withStyles,
    styled,
    Radio,
    makeStyles,
    IconButton,
  } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FormattedMessage from "features/common/TranslatedMessage";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import get from 'lodash/get'
import { DropzoneDialog } from "material-ui-dropzone";
import {claimantPageConstants} from '../constants/index';

const useStyles = makeStyles((theme) => ({
    formContainer: {
      padding: "2rem",
      height: "100%",
    },
    select: {
      "&:after": {
        borderBottomColor: "#2F80ED",
      },
    },
  
    guestbtn: {
      // width: "10%",
      // height : "50%",
      height: "100%",
      background: "#101C50",
      borderRadius: "4px",
      color: "#FFFFFF",
      textTransform: "none",
      "&:hover": {
        background: "#FFFFFF",
        color: "#101C50",
        border: "1px solid #101C50",
      },
    },
  
    text: {
      // "font-size": "18px",
      color: "#2C3345",
      fontFamily: `'Inter',sans-serif`,
      fontWeight: "500",
    },
  
    text1: {
      "font-size": "18px",
      color: "#2C3345",
      padding: "1rem",
      fontFamily: `'Inter',sans-serif`,
      [theme.breakpoints.down("sm")]: {
        textalign: "justify",
      },
    },
    field: {
      background: "#F5F5F5",
    },
    field1: {
      background: "#FFFFFF",
    },
  
    text2: {
      "font-size": "18px",
      fontFamily: `'Inter',sans-serif`,
      color: "#464d5f",
      padding: "0 1rem",
    },
    text3: {
      "font-size": "18px",
      fontFamily: `'Inter',sans-serif`,
      color: "#464d5f",
    },
  
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    required: {
      color: "#f23a3c",
    },
    guestbtn: {
      background: "#101C50",
      borderRadius: "4px",
      color: "#FFFFFF",
      textTransform: "none",
      "&:hover": {
        background: "#FFFFFF",
        color: "#101C50",
        border: "1px solid #101C50",
      },
    },
    paper: {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    input1: {
      height: 180,
    },
    radioHeading: {
      fontSize: "18px",
      color: "#2C3345",
      fontFamily: `'Inter',sans-serif`,
      lineHeight: "40px",
    },
    btnTextWhite: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: `'Inter',sans-serif`,
      textTransform: "none",
    },
    btnTextBlack: {
      color: "#4F4F4F",
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: `'Inter',sans-serif`,
      textTransform: "none",
    },
}));

export const getOptions = () => {
    return {
      1: { text: <FormattedMessage id="tiac.choice_yes" />, value: "Yes/Да/Ҳа" },
      2: { text: <FormattedMessage id="tiac.choice_no" />, value: "No/Нет/Йўқ" },
    };
};

export const StyledTextFieldTiac = withStyles({
    root: {
      "& .MuiInput-underline:after": {
        borderBottomColor: "#2F80ED",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#2F80ED",
        },
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#2F80ED",
      },
    },
  })(TextField);

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
}));
  
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#2F80ED",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
});

const UploadDialog = ({ open, setOpen, addFile }) => {
    const classes = useStyles();
    return (
        <Box textAlign="center" style={{ padding: "0 1rem" }}>
        <Paper
            className={classes.paper}
            style={{ padding: "3rem", maxWidth: "100%" }}
        >
            <Box>
            <IconButton
                className={classes.icon}
                aria-label="delete"
                onClick={() => {
                setOpen(true);
                }}
            >
                <AddCircleOutlineIcon className={classes.icon} />
            </IconButton>
            </Box>
            <Typography>
            <FormattedMessage id="tiac.uploaddocuments" />
            </Typography>
        </Paper>

        <DropzoneDialog
            cancelButtonText={"cancel"}
            submitButtonText={"submit"}
            maxFileSize={104857600}
            open={open}
            onClose={() => setOpen(false)}
            onSave={(files) => {
            files.forEach((file) => {
                addFile(file);
            });
            setOpen(false);
            }}
            showPreviews={true}
            filesLimit={1}
            showFileNamesInPreview={true}
            acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/msword",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ]}
        />
        </Box>
    );
};

const RadioCard = ({ option, selectedValue, handleChange }) => {
    const options = getOptions();
    const classes = useStyles();
  
    return (
      <Fragment>
        <Box display="flex" alignItems="flex-start">
          <StyledRadio
            checked={selectedValue === options[option].value}
            onChange={handleChange}
            value={options[option].value}
            color="primary"
          />
          <Box mt="-2px">
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.radioHeading}
            >
              {options[option].text}
            </Typography>
          </Box>
        </Box>
  
        <Box width="1rem"></Box>
      </Fragment>
    );
};

function StyledRadio(props) {
    return (
        <Radio
            sx={{
                "&:hover": {
                bgcolor: "transparent",
                },
            }}
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

function renderFieldInput(
    field,
    fieldsInput,
    onChange,
    classes
) {
    const fieldType = field.type;
    let language = localStorage.getItem("i8_lang");

    switch (fieldType) {
        case claimantPageConstants.CONFIG_FIELD_SELECT_TYPE: {
          const error = get(fieldsInput, "error." + field.name, "");
          console.log(error,'error');
            return (
                <>
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={12}
                      style={{ padding: "1rem" }}
                    >
                      <FormControl fullWidth>
                    <Select
                        role="select"
                        disabled={field.disabled}
                        //className={cx(styles.select)}
                        value={fieldsInput[field.name] || ""}
                        onChange={(e) => {
                            onChange && onChange(e.target.value, field.name)
                            onChange && onChange("", "error." + field.name);
                        }}
                        // sx={compact([materialUIConstants.FONT_STYLE_SX, boxCustomStyle])}
                    >
                       {
                            (field.options || []).map((option, index) => (
                                <MenuItem
                                    key={index + "_" + option.id}
                                    value={option.id}
                                    //sx={materialUIConstants.FONT_STYLE_SX}
                                >
                                    {language === "ru" || language === "uz"
                                    ? option.russian_name
                                    : option.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    </FormControl>
                    {error ?  <Alert severity="error"><FormattedMessage id={field.errorIntlKey} defaultMessage={error} /></Alert> : null}
                    </Grid>
                </>
            )
        }
        case claimantPageConstants.CONFIG_FIELD_INPUT_BASE: {
          const error = get(fieldsInput, "error." + field.name, "");
            return (
                <>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                        <StyledTextFieldTiac
                            variant="outlined"
                            margin="normal"
                            value={fieldsInput[field.name] || ''}
                            fullWidth
                            id="email"
                            autoFocus
                            name="email"
                            autoComplete="email"
                            onChange={(e) => {
                                onChange && onChange(e.target.value, field.name);
                                onChange && onChange("", "error." + field.name);
                            }}
                            //onBlur={formik.handleBlur}
                            // onChange={(e) => {
                            // formik.handleChange(e);
                            // handleEmailChange(e.target.value);
                            // }}
                            // disabled={field.proceed}
                            disabled={field.email && field.proceed}
                        />
                          {error ?  <Alert severity="error"><FormattedMessage id={field.errorIntlKey} defaultMessage={error} /></Alert> : null}
                    </Grid>
                </>
            )
        }
        case claimantPageConstants.CONFIG_FIELD_RADIO_TYPE: {
            return (
                <>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                        <Box>
                            <RadioCard
                                option={1}
                                // selectedValue={selectedValue}
                                // handleChange={handleChange}
                            />
                            <RadioCard
                                option={2}
                                // selectedValue={selectedValue}
                                // handleChange={handleChange}
                            />
                      </Box>
                    </Grid>
                </>
            )
        }
        case claimantPageConstants.CONFIG_FIELD_UPLOAD_TYPE: {
            return (
                <>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                        <UploadDialog

                        />
                    </Grid>
                </>
            )
        }
    }
}


function ConfigCard({card, fieldsInput, onChange, classes}){
    return(
       (card.fields || []).map((field)=> {
            return(
                <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem", marginTop: "1.5rem" }}
                >
                    <Typography  className={classes.text1}>
                        {field.text && (field.proceed ? "1." : "2.")}
                        {field.number}
                        {" "}
                        {field.intlKey ?
                        <FormattedMessage id={field.intlKey} defaultMessage={field.title} />
                        :
                        field.title }
                        {" "}
                        {field.required && <span className={classes.required}>*</span>}
                    </Typography>
                    {renderFieldInput(field, fieldsInput, onChange, classes)}
                    {field.subTitle && 
                        <Typography className={classes.text2}>
                        {field.subTitle}
                        </Typography>
                    }
                </Grid>
            )
       })
    )
}

function ConfigRow({fieldsInput, cards, onChange, classes}){
    return (
        <>
           {
                Object.values(cards).map((card, index) => (
                    <Box p="2rem 0 7rem 0">
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={11}>
                                <Paper className={classes.formContainer}>
                                    <Box>
                                    <Typography variant="h4" className={classes.text}>
                                        {card.text && (card.proceed ? "1. " : "2. ")}
                                        <FormattedMessage id={card.intlKey} defaultMessage={card.title} />
                                    </Typography>
                                    <ConfigCard
                                        card={card}
                                        fieldsInput={fieldsInput}
                                        onChange={onChange}
                                        classes={classes}
                                    />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                ))
            }
        </>
    )
}

export default ConfigRow;