import { Box, Button, Grid, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import downSvg from "../images/dataanalytics/down.svg";

const useStyles = makeStyles((theme) => ({

  select: {
    marginTop: "0.5rem",
    width: "100%",
    background: "#4F4F4F",
    borderRadius: "4px",
    color: "#ffffff",
    textTransform: "none",
    padding: "10px",
    "&:hover": {
      background: "#4F4F4F",
    },
  },
  icon: {
    fill: "#ffffff",
  },
  menu: {
    width: "100%",
  },
}));

export default function SummaryGraph({series,formatY}) {
  const classes = useStyles();


  const rangeValues = ["1 Year", "6 Months", "3 Months"];
  const [range, setRange] = useState("1 Year");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatter = (value, index)=>{
    if(formatY){
    if(!(index?.dataPointIndex > -1)){
      if(index == 0){
        return '';
      }
      else if(index == 1){
        return '> Rs '+(value);
      }
      // else if(index == 4){
      //   return 'Rs '+(value)+' and above';
      // }
      else{
        return ['Rs '+(value/index),' - Rs '+value];
      }
    }
    else{
      if(index?.seriesIndex === 0){
        return 'Rs ' + value;
      }
      else if(index?.seriesIndex === 1){
        return value;
      }
      else{
        return value;
      }
    }

    }
    else{
      return value;
    }


  }

  const chartData = {
    options: {
      chart: {
        foreColor: '#fff',
        background: '#333',
        type: 'area',
        height: 350,
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        width: [2, 4],
        dashArray: [0, 5],
      },
      colors: ['#FF665C', '#6FCF97'],

      // title: {
      //   text: 'Summary',
      //   align: 'left',
      //   style: {
      //       color:'#fff',
      //     fontSize: '14px'
      //   }
      // },
      markers: {
        size: 0
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'top',
        horizontalAlign: 'right',
        floating: false,
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        markers: {
          width: 50,
          height: 8,
          strokeWidth: 0,
          strokeColor: '#fff',
          radius: 12,
          offsetX: -20,
          offsetY: 0
        },
        itemMargin: {
          horizontal: 15
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        // title: {
        //   text: 'Month'
        // },
        tickPlacement: 'on', //between
        labels: {
          style: {
            colors: '#fff',
          }
        },
        //   type: 'datetime',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: true,
        floating: false,
        tickAmount: 5,
        // logarithmic: false,
        // logBase: 10,
        // min: 0,
        // max: 2000000,
        forceNiceScale: true,
        decimalsInFloat: undefined,
        labels: {
          style: {
            colors: '#fff',
          },
          offsetY: 0,
          offsetX: 0,
          formatter: formatter 
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          show: true,
          position: 'back',
          stroke: {
            color: '#b6b6b6',
            width: 1,
            dashArray: 0,
          },
        },
      },

      fill: {
        type: ['gradient', 'solid'],
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.9,
          opacityTo: 0.05,
          stops: [0, 90, 100]
        }
      },
      tooltip: {
        x: {
          format: "yyyy",
        },
        fixed: {
          enabled: false,
          position: 'topRight'
        }
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 2,
        position: 'back',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
        padding: {
          left: 20
        }
      }
    },
  }




  return (
    <Paper
      style={{
        overflow: "hidden",
        textAlign: "center",
        // padding: "3rem",
        height: '-webkit-fill-available',
        maxHeight: '100%',
      }}
    >

      <Grid container justify="flex-start" alignItems='center' style={{ background: "#333333", padding: '20px' }}>
        <Grid item>
          <Typography style={{fontFamily:"Inter", fontSize: "20px", color: "#fff" }}>
            Summary
          </Typography>
        </Grid>
        <Grid item style={{ padding: "10px" }}>
          <Button
            className={classes.select}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            {range} &nbsp;&nbsp;&nbsp;&nbsp;
            <img src={downSvg} />
          </Button>
          <Menu
            id="range-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className={classes.menu}
          >
            <MenuItem
              onClick={() => {
                setRange(rangeValues[0]);
                handleClose();
              }}
              value={rangeValues[0]}
            >
              {rangeValues[0]}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRange(rangeValues[1]);
                handleClose();
              }}
              value={rangeValues[1]}
            >
              {rangeValues[1]}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRange(rangeValues[2]);
                handleClose();
              }}
            >
              {rangeValues[2]}
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item md={12} sm={12}>
          <Box id="chart" style={{ margin: '35px auto' }}>
            <ReactApexChart
              options={chartData.options}
              series={series}
              type="area"
              height={450}
            />
          </Box>
        </Grid>
      </Grid>
      {/* <Box height="2rem" /> */}
    </Paper>
  )
}
