import { Button, Grid, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { updateDisputesNameForUserApi } from "Admin/Sebi/CaseDisplay";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getRealhost } from "slices/disputeSlice";
import { updateProfile } from "slices/profileSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
  },
  editprofile: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
}));

export default function EditProfileRow({ profileData }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const haveProfile = profileData.profile ? true : false;
  const id = haveProfile ? profileData.profile.id : profileData.id;
  const user_id = profileData.user.id;
  const [firstname, setFirstname] = useState(profileData.user.first_name);
  const [lastname, setLastname] = useState(profileData.user.last_name);
  const [phone, setPhone] = useState(
    haveProfile ? profileData.profile.phone_number : profileData.phone_number
  );
  const [email, setEmail] = useState(profileData.user.email);
  const [address, setAddress] = useState(
    haveProfile ? profileData.profile.address : profileData.address
  );

  const onFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const onSubmit = () => {
    const profileData = {
      id: id,
      phone_number: phone,
      address: address,
      user: {
        first_name: firstname,
        last_name: lastname,
        email: email,
      },
    };

    dispatch(updateProfile(profileData)).then(() => {
      dispatch(
        updateDisputesNameForUserApi(user_id, getRealhost())
      )
    });
  };

  return (
    <>
      <Grid container justify="space-evenly" spacing={3}>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            fullWidth={true}
            value={firstname}
            onChange={onFirstNameChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth={true}
            value={lastname}
            onChange={onLastNameChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Phone Number"
            variant="outlined"
            type="number"
            fullWidth={true}
            value={phone}
            onChange={onPhoneChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email ID"
            variant="outlined"
            fullWidth={true}
            value={email}
            onChange={onEmailChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Address"
            variant="outlined"
            fullWidth={true}
            value={address}
            onChange={onAddressChange}
          />
        </Grid>
      </Grid>
      <Box height="2rem"></Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.bigbtn}
        onClick={onSubmit}
      >
        Save
      </Button>
    </>
  );
}
