import { Box, Chip, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/styles";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
// import TagFacesIcon from '@mui/icons-material/TagFaces';

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: "#61a0ff40",
  },
  inactive: {
    backgroundColor: "#e5e5e5",
  },
  deletableactive: {
    "&:focus": {
      backgroundColor: "#61a0ff40",
    },
  },
  deletableinactive: {
    "&:focus": {
      backgroundColor: "#e5e5e5",
    },
  },
}));

export default function CategoriesBarChart() {
  const classes = useStyles();

  const [categories, setCategories] = useState(["nature", "trading_member"]);
  const [tickamount, setTickAmount] = useState(5);

  const yAxisSeries = {
    trading_member: [
      {
        name: "Closed",
        type: "column",
        data:
          categories[1] == "relationship"
            ? [1400, 200, 2500]
            : categories[1] == "claim_amount"
            ? [1400, 2000, 2500, 1500]
            : [1400, 2000, 2500, 1500, 2500, 2800],
      },
      {
        name: "Pending",
        type: "column",
        data:
          categories[1] == "relationship"
            ? [1100, 300, 3100]
            : categories[1] == "claim_amount"
            ? [1100, 3000, 3100, 1400]
            : [1100, 3000, 3100, 400, 2100, 2900],
      },
      {
        name: "New",
        type: "column",
        data:
          categories[1] == "relationship"
            ? [1400, 200, 2500]
            : categories[1] == "claim_amount"
            ? [1400, 2000, 2500, 1200]
            : [1400, 2000, 2500, 1500, 2500, 2800],
      },
      {
        name: "Drafts",
        type: "column",
        data:
          categories[1] == "relationship"
            ? [1400, 200, 2500]
            : categories[1] == "claim_amount"
            ? [1400, 2000, 2500, 1300]
            : [1400, 2000, 2500, 1500, 2500, 2800],
      },
      {
        name: "Rejected",
        type: "column",
        data:
          categories[1] == "relationship"
            ? [1400, 200, 2500]
            : categories[1] == "claim_amount"
            ? [1400, 2000, 2500, 1600]
            : [1400, 2000, 2500, 1500, 2500, 2800],
      },
    ],
    relationship: [
      {
        name: "Closed",
        type: "column",
        data: [1400, 2000, 2500, 1500, 2500, 2800],
      },
      {
        name: "Pending",
        type: "column",
        data: [1100, 2000, 2100, 400, 2100, 2900],
      },
      {
        name: "New",
        type: "column",
        data: [1400, 2000, 2500, 1500, 2500, 2800],
      },
      {
        name: "Drafts",
        type: "column",
        data: [1400, 2000, 2500, 1500, 2500, 2800],
      },
      {
        name: "Rejected",
        type: "column",
        data: [1400, 2000, 2500, 1500, 2500, 2800],
      },
    ],
    claim_amount: [
      {
        name: "Closed",
        type: "column",
        data:
          categories[0] == "relationship"
            ? [1600, 2100, 2500]
            : [1300, 2100, 2500, 1500, 2600, 2700],
      },
      {
        name: "Pending",
        type: "column",
        data:
          categories[0] == "relationship"
            ? [1200, 3000, 2200]
            : [1200, 3000, 2200, 400, 3100, 3900],
      },
      {
        name: "New",
        type: "column",
        data:
          categories[0] == "relationship"
            ? [2400, 2000, 2500]
            : [2400, 2000, 2500, 2500, 2500, 2800],
      },
      {
        name: "Drafts",
        type: "column",
        data:
          categories[0] == "relationship"
            ? [2400, 2000, 2500]
            : [2400, 2000, 2500, 2500, 2500, 2800],
      },
      {
        name: "Rejected",
        type: "column",
        data:
          categories[0] == "relationship"
            ? [2400, 2000, 2500]
            : [2400, 2000, 2500, 2500, 2500, 2800],
      },
    ],
  };
  const xAxisCategories = {
    nature: [
      "Non receipt /Delay",
      "Trade related",
      "Charges",
      "Order Execution",
      "Primary Market",
      "Others",
    ],
    relationship: ["Investor", "Sub-Broker", "Authorized Person"],
    claim_amount: [
      "> Rs 50,000",
      "Rs 50,000 - Rs 5,00,000",
      "Rs 5,00,000 - Rs 10,00,000",
      "Rs 20,00,000 and above",
    ],
  };
  const [series, setSeries] = useState(yAxisSeries.trading_member);
  const [xseries, setXSeries] = useState(xAxisCategories.nature);

  const chartData = {
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: 'straight',
        width: 1,
      },
      colors: ["#9EF3B1", "#81F5FD", "#61A0FF", "#EEFF85", "#FF665C"],
      xaxis: {
        categories: xseries,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          tickAmount: tickamount,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#4F4F4F",
          },
          labels: {
            align: "right",
            // minWidth: 0,
            // maxWidth: 160,
            style: {
              colors: "#4F4F4F",
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value, index) => {
              if (!(index?.dataPointIndex > -1)) {
                if (categories.includes("trading_member")) {
                  if (index === 0) {
                    return "";
                  } else if (index === 1) {
                    return "XYZ";
                  } else if (index === 2) {
                    return "Axis fund";
                  } else if (index === 3) {
                    return "Nippon";
                  } else if (index === 4) {
                    return "Angel Investment";
                  } else if (index >= 5) {
                    return "";
                  } else {
                    return "Closed,pending";
                  }
                } else if (categories[1] === "relationship") {
                  if (index === 0) {
                    return "";
                  } else if (index === 1) {
                    return "Investors";
                  } else if (index === 2) {
                    return "Sub-Broker";
                  } else if (index === 3) {
                    return "Authorized Person";
                  } else if (index >= 4) {
                    return "";
                  } else {
                    return "Closed,pending";
                  }
                } else if (categories[1] === "claim_amount") {
                  if (index == 0) {
                    return "";
                  } else if (index == 1) {
                    return "> Rs " + value;
                  }
                  // else if(index == 4){
                  //   return 'Rs '+(value)+' and above';
                  // }
                  else {
                    return "Rs " + value / index + " - Rs " + value;
                  }
                }
                // if(categories[0] === 'nature'){
                // if(index == 1){
                // }
                // else
                // {
                //     return value
                // }
                // }
              } else {
                return value;
              }
            },
          },
          tooltip: {
            enabled: false,
          },
        },
      ],
      tooltip: {
        enabled: true,
        // fixed: {
        //   enabled: true,
        //   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        //   offsetY: 30,
        //   offsetX: 60
        // },
      },
      legend: {
        show: true,
        horizontalAlign: "left",
        offsetX: 75,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -20,
              offsetY: 0,
            },
          },
        },
      ],
      grid: {
        show: true,
        borderColor: "#90A4AE",
        strokeDashArray: 2,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          // colors: undefined,
          colors: ["#e5e5e5", "transparent"],
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
    },
  };

  var chips = [
    { key: 0, id: "nature", label: "Nature", activeIndex: true },
    {
      key: 1,
      id: "trading_member",
      label: "Trading Member",
      activeIndex: true,
    },
    { key: 2, id: "relationship", label: "Relationship", activeIndex: false },
    { key: 3, id: "claim_amount", label: "Claim Amount", activeIndex: false },
  ];
  const [chipData, setChipData] = useState(chips);

  React.useEffect(() => {
    if (categories[0] == "nature" && categories[1] == "trading_member") {
      setXSeries(xAxisCategories.nature);
      setSeries(yAxisSeries.trading_member);
      setTickAmount(5);
    } else if (categories[0] == "nature" && categories[1] == "relationship") {
      setXSeries(xAxisCategories.nature);
      setSeries(yAxisSeries.relationship);
      setTickAmount(4);
    } else if (categories[0] == "nature" && categories[1] == "claim_amount") {
      setXSeries(xAxisCategories.nature);
      setSeries(yAxisSeries.claim_amount);
      setTickAmount(5);
    } else if (
      categories[0] == "trading_member" &&
      categories[1] == "relationship"
    ) {
      setXSeries(xAxisCategories.relationship);
      setSeries(yAxisSeries.trading_member);
      setTickAmount(5);
    } else if (
      categories[0] == "trading_member" &&
      categories[1] == "claim_amount"
    ) {
      setXSeries(xAxisCategories.claim_amount);
      setSeries(yAxisSeries.trading_member);
      setTickAmount(5);
    } else if (
      categories[0] == "relationship" &&
      categories[1] == "claim_amount"
    ) {
      setXSeries(xAxisCategories.relationship);
      setSeries(yAxisSeries.claim_amount);
      setTickAmount(5);
    }
  }, [categories]);

  function setClickedChip(clickData, newChipData) {
    //           if (item.id == categories[1]){
    //             return {...item, activeIndex:!item.activeIndex};
    //           }
    //           else{
    //             if (item.key == clickData.key){
    //               return {...item, activeIndex:!clickData.activeIndex};
    //             }
    //             return item;
    //             // return chipitem;
    //           }
    let updatedList = newChipData.map((item) => {
      if (item.key == clickData.key) {
        return { ...item, activeIndex: !clickData.activeIndex };
      }
      return item;
    });
    setChipData(updatedList);
    return updatedList;
  }

  const handleClick = (chipClick) => {
    let chipActiveIndex = chipData;
    const updatedChipData = setClickedChip(chipClick, chipActiveIndex);
    const filteredCategories = updatedChipData.filter(
      (value) => value.activeIndex == true
    );
    const updatedCategories = filteredCategories.map((value) => value.id);
    setCategories(updatedCategories);
  };
  const handleDelete = (chipToDelete) => () => {};

  return (
    <Paper
      style={{
        overflow: "hidden",
        textAlign: "center",
        padding: "0 1rem",
        height: "-webkit-fill-available",
        maxHeight: "100%",
      }}
    >
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ padding: "20px" }}
      >
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle1" style={{ fontSize: "20px" }}>
            Complaints
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: "16px" }}>
            Select two categories
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            listStyle: "none",
            padding: "20px 0",
          }}
        >
          {chipData.map((data) => {
            return (
              <ListItem key={data.key}>
                <Chip
                  // disableRipple
                  label={data.label}
                  onDelete={handleDelete(data)}
                  onClick={() => handleClick(data)}
                  // className={`${classes.deletableactive} ${data.activeIndex ? classes.active : classes.inactive}`}
                  className={
                    data.activeIndex
                      ? `${classes.deletableactive} ${classes.active}`
                      : `${classes.deletableinactive} ${classes.inactive}`
                  }
                />
              </ListItem>
            );
          })}
        </Grid>
        <Grid item md={12} sm={12}>
          {categories.length == 2 ? (
            <Box id="chart" style={{ margin: "15px auto" }}>
              <ReactApexChart
                options={chartData.options}
                series={series}
                type="bar"
                height={450}
              />
            </Box>
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{ fontSize: "16px", color: "red" }}
              >
                Select only two categories
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box height="2rem" />
    </Paper>
  );
}
