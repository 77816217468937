import {
  Grid,
  Icon,
  IconButton,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core";
import { containerContext } from "features/Chat/ChatContainer";
import React, { useContext, useEffect, useRef, useState } from "react";
import { sendMessage } from "utils/chatUtils";
import AttachmentIcon from "@material-ui/icons/Attachment";
import MicIcon from "@material-ui/icons/Mic";
import UtilityButton from "features/Chat/UtilityButton";
import { DropzoneDialog } from "material-ui-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "slices/groupSlice";
import { typingIndicator } from "utils/chatUtils";
import ArrowImage from '../../../images/Arrow.svg';
import MentionSuggestionList from "../MentionSuggestionList/MentionSuggestionList";
import { getChatAuthToken } from "utils/localStorage";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "2.5rem",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    background: theme.palette.secondPrimary,
  },

  container: {
    // borderTop: `0.2rem solid ${theme.palette.border}`,
    // paddingTop: "1.4rem",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  root: {
    background: "#fff",
    padding: "1rem 1.5rem",
    borderRadius:' 56px',
    border: '1px solid #EDEDED',
    color: '#040415',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight:' 24px', 
  },
  underline: {
    "&:before": {
      borderBottom: "2px solid green",
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: "2px solid blue",
    },
    "&:after": {
      borderBottom: "3px solid purple",
    },
  },
}));

const actions = [
  { icon: <AttachmentIcon />, name: "File" },
  { icon: <MicIcon />, name: "Voice" },
];

export default function InputBox({ groupId, handleStartRecording, members }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState("");
  const { ws } = useContext(containerContext);

  const username = useSelector((state) => state.user.user.first_name);
  const classes = useStyles();
  const theme = useTheme();

  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [openMentionSuggestions, setOpenMentionSuggestions] = useState(false);
  const anchorRef = useRef(null);
  const {userId} = getChatAuthToken();
  const groupMembers = Object.values(members).filter(member => (member.name !== 'svc_site_prod') && (userId !== member._id) );


  let timer,
    timeoutVal = 1000;

  const onKeyUp = () => {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    timer = window.setTimeout(() => {
      typingIndicator(ws, groupId, username, false);
    }, timeoutVal);
  };

  const onKeyPress = () => {
    window.clearTimeout(timer);
    typingIndicator(ws, groupId, username, true);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSendClick();
    }
  };

  const onUploadClick = () => {
    setOpen(true);
  };

  useEffect(()=>{
    if(mentionSuggestions.length > 0)
      setOpenMentionSuggestions(true);
  },[mentionSuggestions])

  const onChange = (e) => {
    const inputValue = e.target.value;
    setMessage(inputValue);
    if (inputValue.includes('@')) {
      const suggestions = generateMentionSuggestions(inputValue);
      setMentionSuggestions(suggestions);
    } else {
      setMentionSuggestions([]);
    }
  };

  const generateMentionSuggestions = (inputValue) => {
    const searchTerm = inputValue.substring(inputValue.lastIndexOf('@') + 1).toLowerCase();
    return groupMembers.filter(member => member.name.toLowerCase().startsWith(searchTerm));
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    anchorRef.current.focus();
    setOpenMentionSuggestions(false);
  };

  const handleSelectMention = (event,item) => {
    handleClose(event);
    setMessage(prevInput => {
      const mentionIndex = prevInput.lastIndexOf('@')+1;
      const start = prevInput.substring(0, mentionIndex);
      const end = prevInput.substring(mentionIndex);
      return `${start}${item.name} ${end}`;
    });
  }

  const replaceNameWithUsername = () => {
    let msg = message;
    groupMembers.forEach(member => {
      if(msg.includes(`@${member.name}`)){
        let reg = new RegExp(member.name, 'g');
        msg = msg.replace(reg, member.username);
      }
    })
    return msg;
  }

  const onSendClick = () => {
    const msg = replaceNameWithUsername();
    msg.trim().length > 0 && sendMessage(ws, msg, groupId);
    setMessage("");
  };

  const handlers = [onUploadClick, handleStartRecording];

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item sm={1} xs={2} style={{ position: "relative" }}>
          <UtilityButton actions={actions} handlers={handlers} />
        </Grid>
        <Grid item sm={10} xs={8}>
          <MentionSuggestionList 
            mentionSuggestions={mentionSuggestions}
            open={openMentionSuggestions}
            anchorRef={anchorRef}
            handleClose={handleClose}
            handleSelectMention={handleSelectMention}
          />
          <TextField
            style={{ borderRadius: "1rem" }}
            fullWidth
            id="filled-basic"
            variant="filled"
            placeholder="Type something"
            InputProps={{ disableUnderline: true }}
            value={message}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            inputRef={anchorRef}
            aria-haspopup="true"
            autoFocus={mentionSuggestions === false}
          />
        </Grid>
        <Grid item sm={1} xs={2}>
          <IconButton onClick={onSendClick} style={{padding:'8px'}}>
            <img src ={ArrowImage} alt="arrow"/>
          </IconButton>
        </Grid>
      </Grid>
      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={async (files) => {
          dispatch(uploadFile({ file: files[0], groupId }));
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />

      {/* <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          files.forEach((file) => {
            addFileToServer(file, file.name);
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
      /> */}
    </React.Fragment>
  );
}
