import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import filterBlackSvg from "images/filter.svg";
import searchSvg from "images/search.svg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ClientProfile from "./ClientProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    // padding: "1.2rem 1.7rem",
    borderRadius: "8px",
    marginLeft: "20px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  paper2: {
    border: "1px solid #828282",
    boxSizing: "border-box",
    padding: "0.2rem 0.7rem",
    margin: "1rem 1.5rem",
  },
  paper3: {
    border: "1px solid #828282",
    boxSizing: "border-box",
    padding: "0.2rem 0.7rem",
    margin: "1rem 1.5rem",
    background: "none",
    border: "none",
    boxShadow: "none",
  },
  filter: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#828282",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.05em",
    color: "#2C2C2C",
    padding: "17px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  grid: {
    paddingLeft: "1.6rem",
    background: "#ECEEF8",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.3rem",
    },
  },
  blackoption: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2C2C2C",
  },
  blueoption: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3F51B5",
  },
  selectedoption: {
    borderBottom: "2px solid #3F51B5",
  },
  optiongrid: {
    cursor: "pointer",
  },
  sortby: {
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.05em",
    color: "#828282",
  },
  sortbyblackHeading: {
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
    color: "#2C2C2C",
  },
}));

export default function Clients({ profile, setShowClients }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [selectall, setSelectall] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorFilter, setAnchorFilter] = React.useState(null);
  const [allclients, setAllclients] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [clientsDefaultData, setClientsDefaultData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [hide, setHide] = useState(false);

  const [sortby, setsortby] = useState("default");
  const [users, setUsers] = useState("all");

  const handleDefaultSort = (e) => {
    setsortby(e.target.value);
    var result = [];
    if (users == "all") {
      result = [...clientsDefaultData];
    } else if (users == "active") {
      [...clientsDefaultData].forEach((item) => {
        if (item.status) result.push(item);
      });
    } else {
      [...clientsDefaultData].forEach((item) => {
        if (!item.status) result.push(item);
      });
    }
    setClientsData(result);
  };

  const handleNameSort = (e) => {
    setsortby(e.target.value);
    var result = [];
    if (users == "all") {
      result = [...clientsDefaultData];
    } else if (users == "active") {
      [...clientsDefaultData].forEach((item) => {
        if (item.status) result.push(item);
      });
    } else {
      [...clientsDefaultData].forEach((item) => {
        if (!item.status) result.push(item);
      });
    }
    result.sort(function (a, b) {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setClientsData(result);
  };

  const handleLastLoginSort = (e) => {
    setsortby(e.target.value);
    var result = [];
    if (users == "all") {
      result = [...clientsDefaultData];
    } else if (users == "active") {
      [...clientsDefaultData].forEach((item) => {
        if (item.status) result.push(item);
      });
    } else {
      [...clientsDefaultData].forEach((item) => {
        if (!item.status) result.push(item);
      });
    }
    result.sort(function (a, b) {
      var nameA = new Date(a.last_login);
      var nameB = new Date(b.last_login);
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setClientsData(result);
  };

  const handleAllUsers = (e) => {
    setUsers(e.target.value);
    var result = [...clientsDefaultData];
    if (sortby == "default") {
      setClientsData(result);
    }
    if (sortby == "name") {
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setClientsData(result);
    }
    if (sortby == "lastlogin") {
      result.sort(function (a, b) {
        var nameA = new Date(a.last_login);
        var nameB = new Date(b.last_login);
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setClientsData(result);
    }
  };

  const handleActiveUsers = (e) => {
    setUsers(e.target.value);
    var result = [];
    [...clientsDefaultData].forEach((item) => {
      if (item.status) {
        result.push(item);
      }
    });
    if (sortby == "default") {
      setClientsData(result);
    }
    if (sortby == "name") {
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setClientsData(result);
    }
    if (sortby == "lastlogin") {
      result.sort(function (a, b) {
        var nameA = new Date(a.last_login);
        var nameB = new Date(b.last_login);
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setClientsData(result);
    }
  };

  const handleInactiveUsers = (e) => {
    setUsers(e.target.value);
    var result = [];
    [...clientsDefaultData].forEach((item) => {
      if (!item.status) {
        result.push(item);
      }
    });
    if (sortby == "default") {
      setClientsData(result);
    }
    if (sortby == "name") {
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setClientsData(result);
    }
    if (sortby == "lastlogin") {
      result.sort(function (a, b) {
        var nameA = new Date(a.last_login);
        var nameB = new Date(b.last_login);
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setClientsData(result);
    }
  };

  const searchHandler = (e) => {
    let search = e.target.value;
    if (search == "") {
      setSearchData([]);
    } else {
      // search all / gr / cdr / rv at a time

      let result = [];
      [...clientsDefaultData].forEach((item) => {
        if (item.name.toLowerCase().includes(search.toLowerCase())) {
          result.push(item);
        }
      });
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      if (result.length > 0) {
        setSearchData(result);
        setHide(false);
      } else {
        setSearchData([]);
        setHide(true);
      }
    }
  };

  const [option1, setOption1] = useState(true);
  const [option2, setOption2] = useState(false);
  const [option3, setOption3] = useState(false);
  const [option4, setOption4] = useState(false);

  const handleOption1 = (e) => {
    setOption1(true);
    setOption2(false);
    setOption3(false);
    setOption4(false);
    setClientsData(allclients);
    var result = [...allclients];
    setClientsDefaultData(result);
    setsortby("default");
    setUsers("all");
  };
  const handleOption2 = (e) => {
    setOption2(true);
    setOption1(false);
    setOption3(false);
    setOption4(false);
    var result = [];
    allclients.forEach((item) => {
      if (
        item.platform == "grievance" ||
        item.name == "hotstar" ||
        item.name == "nbf" ||
        item.name == "dpcgc"
      ) {
        result.push(item);
      }
    });
    setClientsData(result);
    let result2 = [...result];
    setClientsDefaultData(result2);
    setsortby("default");
    setUsers("all");
  };
  const handleOption3 = (e) => {
    setOption3(true);
    setOption2(false);
    setOption1(false);
    setOption4(false);
    var result = [];
    allclients.forEach((item) => {
      if (item.platform == "cdr") {
        result.push(item);
      }
    });
    setClientsData(result);
    var result2 = [...result];
    setClientsDefaultData(result2);
    setsortby("default");
    setUsers("all");
  };
  const handleOption4 = (e) => {
    setOption4(true);
    setOption2(false);
    setOption3(false);
    setOption1(false);
    var result = [];
    allclients.forEach((item) => {
      if (item.platform == "rv") {
        result.push(item);
      }
    });
    setClientsData(result);
    var result2 = [...result];
    setClientsDefaultData(result2);
    setsortby("default");
    setUsers("all");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickFilter = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorFilter(null);
  };

  const handleSelectAllChange = (e) => {
    setSelectall(e.target.checked);
  };

  React.useEffect(() => {
    dispatch(getClients()).then((res) => {});
  }, []);

  function getClients() {
    return async (dispatch) => {
      try {
        const response = await instance.get("/api/disputeinfobyallgroup/");
        var res = [];
        response.data.forEach((item) => {
          if (
            item.name !== "webnyay" &&
            item.name !== "barandbench" &&
            item.name != "hotstar"
          ) {
            res.push(item);
          }
        });
        const response3 = await instanceHotstar.get(
          "/api/disputeinfobyallgroup/"
        );
        response3.data.map((item) => {
          if (item.name == "hotstar") {
            res.push(item);
          }
        });
        const responseDpcgc = await instanceDPCGC.get(
          "/api/disputeinfobyallgroup/"
        );
        responseDpcgc.data.map((item) => {
          res.push(item);
        });
        const response2 = await instance.get("/api/disputeinfobyallcompany/");
        response2.data.forEach((item) => {
          res.push(item);
        });
        setLoading(false);
        setAllclients([...res]);
        setClientsData(res);
        setClientsDefaultData([...res]);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <div className={classes.root}>
        <Grid container justify="flex-start" direction="column">
          <Grid item sm xs>
            <Grid
              container
              justify="space-between"
              direction="row"
              style={{ padding: "10px" }}
            >
              <Grid item>
                <Typography className={classes.heading}>
                  User Details - Clients
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.username}>
                  {profile.user.first_name} {profile.user.last_name}
                </Typography>
              </Grid>
            </Grid>
            <Box height="1rem"></Box>
            <Grid container justify="flex-start">
              <Grid item style={{ padding: "15px 20px" }}>
                <Button
                  onClick={() => {
                    setShowClients(false);
                    sessionStorage.removeItem("userDetails");
                  }}
                  className={classes.backbtn}
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
            {/* <Box height="4rem"></Box> */}
            <Paper className={classes.paper3}>
              <Grid
                container
                justify="flex-start"
                style={{ borderBottom: "1px solid #2C2C2C" }}
                spacing={3}
              >
                <Grid
                  item
                  className={
                    option1 ? classes.selectedoption : classes.optiongrid
                  }
                  onClick={(e) => {
                    if (!option1) handleOption1();
                  }}
                >
                  <Typography
                    className={
                      option1 ? classes.blueoption : classes.blackoption
                    }
                  >
                    All
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={
                    option2 ? classes.selectedoption : classes.optiongrid
                  }
                  onClick={(e) => {
                    if (!option2) handleOption2();
                  }}
                >
                  <Typography
                    className={
                      option2 ? classes.blueoption : classes.blackoption
                    }
                  >
                    GR
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={
                    option3 ? classes.selectedoption : classes.optiongrid
                  }
                  onClick={(e) => {
                    if (!option3) handleOption3();
                  }}
                >
                  <Typography
                    className={
                      option3 ? classes.blueoption : classes.blackoption
                    }
                  >
                    CDR
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={
                    option4 ? classes.selectedoption : classes.optiongrid
                  }
                  onClick={(e) => {
                    if (!option4) handleOption4();
                  }}
                >
                  <Typography
                    className={
                      option4 ? classes.blueoption : classes.blackoption
                    }
                  >
                    RV
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Box height="1rem"></Box>
            <Grid container justify="flex-start">
              <Grid item md={12} sm={12} xs={12}>
                <Paper className={classes.paper}>
                  <Grid container justify="flex-start">
                    <Grid item style={{ textAlign: "left" }}>
                      <Paper
                        className={classes.paper2}
                        onClick={handleClickFilter}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        style={{ cursor: "pointer" }}
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <img src={filterBlackSvg} />
                          </Grid>
                          <Grid item>
                            <Typography className={classes.filter}>
                              Filter
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorFilter}
                        keepMounted
                        open={Boolean(anchorFilter)}
                        onClose={handleCloseFilter}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Grid container spacing={2} direction="row">
                          <Grid item>
                            <Box display="flex">
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography className={classes.sortby}>
                                  SORT BY:
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box height="0.7rem"></Box>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="default">Default</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="default"
                                  name="sortby"
                                  value="default"
                                  onChange={handleDefaultSort}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={sortby == "default" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="name">Name</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="name"
                                  name="sortby"
                                  value="name"
                                  onChange={handleNameSort}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={sortby == "name" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="lastlogin">Last Login</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="lastlogin"
                                  name="sortby"
                                  value="lastlogin"
                                  onChange={handleLastLoginSort}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={sortby == "lastlogin" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box height="1rem"></Box>
                        <Divider />
                        <Box height="1rem"></Box>
                        <Grid container spacing={2} direction="row">
                          <Grid item>
                            <Box display="flex">
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography className={classes.sortby}>
                                  USERS:
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="all">All</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="all"
                                  name="users"
                                  value="all"
                                  onChange={handleAllUsers}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={users == "all" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="active">Active</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="active"
                                  name="users"
                                  value="active"
                                  onChange={handleActiveUsers}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={users == "active" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="inactive">Inactive</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="inactive"
                                  name="users"
                                  value="inactive"
                                  onChange={handleInactiveUsers}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={users == "inactive" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Menu>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paper2}
                        style={{ width: "100%" }}
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <img src={searchSvg} />
                          </Grid>
                          <Grid item>
                            <input
                              type="text"
                              placeholder="Search Users by Name"
                              style={{ width: "112%", border: "none" }}
                              onChange={searchHandler}
                            ></input>
                            {/* <Typography className={classes.filter}> Search Users by Name, Email or Date</Typography> */}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify="flex-start"
                    alignItems="baseline"
                    className={classes.grid}
                  >
                    <Grid
                      item
                      md={1}
                      sm={1}
                      xs={1}
                      // style={{ textAlign: "left" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectall}
                            onChange={handleSelectAllChange}
                            name="selectall"
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sm={3}
                      xs={3}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        NAME
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sm={3}
                      xs={3}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        USER STATUS
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        OPEN CASES
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        CLOSED CASES
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sm={1}
                      xs={1}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleClose}>Edit</MenuItem>
                        <MenuItem onClick={handleClose}>View Profile</MenuItem>
                        <MenuItem onClick={handleClose}>Activate User</MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  <Box height="1px"></Box>
                  {searchData.length > 0 || hide
                    ? searchData.map((item) => {
                        return (
                          <>
                            <ClientProfile
                              name={item.name}
                              status={item.status}
                              last_login={item.last_login}
                              open={item.open}
                              closed={item.closed}
                            />
                            <Divider />
                          </>
                        );
                      })
                    : clientsData.map((item) => {
                        return (
                          <>
                            <ClientProfile
                              name={item.name}
                              status={item.status}
                              last_login={item.last_login}
                              open={item.open}
                              closed={item.closed}
                            />
                            <Divider />
                          </>
                        );
                      })}

                  <Box height="0.5rem"></Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
