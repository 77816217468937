import { containerContext } from "features/Chat/ChatContainer";
import React, { useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VideoConferencing from "./VideoConferencing";

export default function VideoConferencingContainer() {
  const { disputeId } = useParams();

  const { activeGroup, groups } = useSelector(
    (state) => state.groups,
    shallowEqual
  );

  const currentGroup = groups.filter((group) => group._id === activeGroup)[0];
  const { setShowVC, vcType } = useContext(containerContext);

  const user = useSelector((state) => state.user.user, shallowEqual);

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  let company = sessionStorage.getItem("company");
  let childProps = {
    user,
    profile,
    disputeId,
    roomName:
      company == "dpcgc"
        ? `${disputeId}_${currentGroup.name}-dpcgc_${activeGroup}`
        : `${disputeId}_${currentGroup.name}_${activeGroup}`,
    setShowVC: setShowVC,
    vcType: vcType,
  };
  console.log('🌹 dyte link\n', `${window.location.origin}/meeting/chatroom/${disputeId}${disputeId}_${currentGroup.name}_${activeGroup}`)
  return <VideoConferencing {...childProps} />;
}
