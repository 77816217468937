import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { format, parseISO } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import Question from "features/intro/AnonymousDisputeFlow/Question";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDigipub } from "Routes/Routes";
import { editAnswerAnonymous, getAnswersAnonymous } from "slices/answerSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem",
    margin: "1rem",
  },
  question_heading: {
    lineHeight: "24px",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: { fontSize: "1rem" },
    color: theme.palette.text.primary,
  },
}));

export default function SingleDetail({
  question,
  answer,
  profile,
  getQuestionsAnswers,
  addToogledAnswer,
  removeToogledAnswer,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeCase = useSelector((state) => state.cases.activeCase);
  const [editMode, toggleEditMode] = React.useState(false);
  const answerValues = new Map();
  answerValues.set(question.id, answer.value);

  function getAns(questionId) {
    for (var entry of answerValues.entries()) {
      if (entry[0] === questionId) {
        return entry[1];
      }
    }
  }

  const onSubmit = async (e) => {
    if (
      !question.optional &&
      question.type !== "boolean" &&
      question.type !== "date"
    ) {
      var returned_ans = getAns(question.id);
      if (typeof returned_ans === "undefined") {
        alert(
          "Please fill mandatory field => " +
            question.name.substr(0, 40) +
            "...."
        );
        return 0;
      } else if (returned_ans.trim() === "") {
        alert(
          "Please fill mandatory field => " +
            question.name.substr(0, 40) +
            "...."
        );
        return 0;
      }
    }

    if (
      !question.optional &&
      question.mandatory_boolean &&
      question.type === "boolean"
    ) {
      var returned_ans = getAns(question.id);
      if (!returned_ans) {
        alert("Please Agree with: " + question.name.substr(0, 40) + "....");
        return 0;
      }
    }

    if (!question.optional && question.type === "time") {
      let arr = answerValues.get(question.id).split(":");
      if (arr[0] > 5 || arr[1] > 59 || arr[2] > 59) {
        alert("Please enter time less than 5:59:59");
        return 0;
      }
    }

    if (isDigipub && question.type === "date") {
      if (!answerValues.has(question.id)) {
        alert(
          "Please fill mandatory field => " +
            question.name.substr(0, 40) +
            "...."
        );
        return 0;
      }
    }

    if (question.type === "time_range") {
      let arr = answerValues.get(question.id).split("to");
      console.log("arr", arr);
      if (arr.length > 1) {
        let fromArr = arr[0].split(":");
        let toArr = arr[1].split(":");
        let fromTimeInSeconds =
          parseInt(fromArr[0]) * 60 * 60 +
          parseInt(fromArr[1]) * 60 +
          parseInt(fromArr[2]);
        let ToTimeInSeconds =
          parseInt(toArr[0]) * 60 * 60 +
          parseInt(toArr[1]) * 60 +
          parseInt(toArr[2]);
          if((fromTimeInSeconds >= ToTimeInSeconds) && fromTimeInSeconds>0) {
          alert(
            "Please enter 'from' time less than 'to' time in : " +
              question.name.substr(0, 40) +
              "...."
          );
          return 0;
          // answerValues.delete(question.id);
        }
      }
    }

    await dispatch(
      editAnswerAnonymous(
        {
          question: question.id,
          answerId: answer.id,
          answer: answerValues.get(question.id)?.toString(),
          caseId: activeCase.id,
        },
        profile.user.email
      )
    );
    toggleEditMode(false);
    removeToogledAnswer(answer.id);
    await dispatch(getAnswersAnonymous(activeCase.id, profile.user.email));
    getQuestionsAnswers();
  };

  let valueToDisplay = answer.value;
  if (question.type === "boolean") {
    if (answer.value === "true") {
      valueToDisplay = "Yes";
    } else {
      valueToDisplay = "No";
    }
  } else if (question.type === "date") {
    valueToDisplay = format(parseISO(answer.value), "dd-MM-yyyy");
  }

  return (
    <Paper className={classes.paper}>
      {!editMode ? (
        <React.Fragment>
          <Box>
            <Box textAlign="start">
              <Typography variant="h6" className={classes.question_heading}>
                {question.name}
              </Typography>
              <Box height="0.8rem"></Box>

              <pre>
                <Typography
                  variant="subtitle1"
                  style={{ wordBreak: "break-word" }}
                >
                  {valueToDisplay}
                </Typography>
              </pre>
            </Box>
            <IconButton
              onClick={() => {
                toggleEditMode(true);
                addToogledAnswer(answer.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Question question={question} answerValues={answerValues} />
          <Box height="1rem"></Box>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            <FormattedMessage id="buttons.save" />
          </Button>
        </React.Fragment>
      )}
    </Paper>
  );
}
