import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "0.5rem",
  },
  button: {
    padding: "0.5rem 3.2rem",
    fontSize: "1.2rem",
  },
}));
