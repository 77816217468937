import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Button,
  Box,
} from "@material-ui/core";

import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#454545",
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: `'Inter',sans-serif`,
    margin: "5px",
    textTransform: "none",
  },
  browntext: {
    color: "#800000",
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: `'Inter',sans-serif`,
    margin: "5px",
    textTransform: "none",
    textAlign: "center",
  },
}));

function findMin(amount) {
  if (amount <= 35000) return 2300;
  else if (amount <= 75000) {
    return 2300 + (amount - 35000) * 0.08;
  } else if (amount <= 200000) {
    return 7500 + (amount - 75000) * 0.045;
  } else if (amount <= 500000) {
    return 9500 + (amount - 200000) * 0.025;
  } else if (amount <= 700000) {
    return 22500 + (amount - 500000) * 0.014;
  } else if (amount <= 1500000) {
    return 29500 + (amount - 700000) * 0.009;
  } else if (amount <= 3500000) {
    return 30300 + (amount - 1500000) * 0.005;
  } else if (amount <= 7000000) {
    return 51500 + (amount - 3500000) * 0.0012;
  } else if (amount <= 35000000) {
    return 52500 + (amount - 7000000) * 0.0011;
  } else if (amount <= 55000000) {
    return 62500 + (amount - 35000000) * 0.001;
  } else if (amount <= 75000000) {
    return 68500 + (amount - 55000000) * 0.0007;
  } else if (amount <= 350000000) {
    return 72500 + (amount - 75000000) * 0.0006;
  } else {
    return 74500 + (amount - 350000000) * 0.0004 <= 1000000
      ? 74500 + (amount - 350000000) * 0.0004
      : 1000000;
  }
}

function findMax(amount) {
  if (amount <= 35000) return 4500;
  else if (amount <= 75000) {
    return 4500 + (amount - 35000) * 0.1;
  } else if (amount <= 200000) {
    return 9500 + (amount - 75000) * 0.053;
  } else if (amount <= 500000) {
    return 26500 + (amount - 200000) * 0.048;
  } else if (amount <= 700000) {
    return 28500 + (amount - 500000) * 0.037;
  } else if (amount <= 1500000) {
    return 45500 + (amount - 700000) * 0.024;
  } else if (amount <= 3500000) {
    return 65500 + (amount - 1500000) * 0.012;
  } else if (amount <= 7000000) {
    return 90000 + (amount - 3500000) * 0.007;
  } else if (amount <= 35000000) {
    return 115000 + (amount - 7000000) * 0.002;
  } else if (amount <= 55000000) {
    return 200000 + (amount - 35000000) * 0.0015;
  } else if (amount <= 75000000) {
    return 220000 + (amount - 55000000) * 0.0007;
  } else if (amount <= 350000000) {
    return 210000 + (amount - 75000000) * 0.0006;
  } else {
    return 220500 + (amount - 350000000) * 0.0004 <= 1000000
      ? 220500 + (amount - 350000000) * 0.0004
      : 1000000;
  }
}

export default function TiacCalculator() {
  const classes = useStyles();

  const [currency, setCurrency] = useState("$");
  const [amount, setAmount] = useState(0);
  const [procedure, setProcedure] = useState("ordinary");
  const [noOfArbitrators, setNoOfArbitrators] = useState("1");
  const [showResult, setShowResult] = useState(false);
  const [showError, setshowError] = useState(false);
  const [resultAmount, setResultAmount] = useState("");
  const [resultNoOfArbitratos, setResultNoOfArbitratos] = useState("");
  const [resultMin, setResultMin] = useState("");
  const [resultMax, setResultMax] = useState("");
  const [resultAvg, setResultAvg] = useState("");
  const [
    resultAvgMultipliedByNoOfArbitratos,
    setResultAvgMultipliedByNoOfArbitratos,
  ] = useState("");
  const [total, setTotal] = useState("");

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handleAmountChange = (e) => {
    var x = Number(e.target.value);
    if (isNaN(x)) {
      return 0;
    }
    setAmount(e.target.value);
  };
  const handleProcedureChange = (e) => {
    setProcedure(e.target.value);
  };
  const handleNumberOfArbitratorsChange = (e) => {
    setNoOfArbitrators(e.target.value);
  };
  const handleFocus = (event) => event.target.select();

  const handleSubmit = () => {
    setshowError(false);
    var x = Number(amount);
    x = x.toFixed(2);

    setResultAmount(x);
    if (isNaN(x)) {
      setshowError(true);
      return 0;
    }
    var min = findMin(x);
    var max = findMax(x);
    var avg = (min + max) / 2;
    min = min.toFixed(2);
    max = max.toFixed(2);
    avg = avg.toFixed(2);
    setResultMin(min);
    setResultAvg(avg);
    setResultMax(max);
    avg = avg * parseInt(noOfArbitrators);
    avg = avg.toFixed(2);
    setResultAvgMultipliedByNoOfArbitratos(avg);
    if (procedure == "expedited") {
      min = min * parseInt(noOfArbitrators);
      min = min.toFixed(2);
      setTotal(min);
    } else {
      setTotal(avg);
    }

    setResultNoOfArbitratos(noOfArbitrators);
    setShowResult(true);
  };

  return (
    <Grid container justify="center">
      <Grid item md={8} sm={10} style={{ margin: "5rem 0 3rem 0" }}>
        {/*  */}
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography className={classes.text}>Currency</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-currency"
                value={currency}
                onChange={handleCurrencyChange}
                name="radio-buttons-currency"
              >
                <FormControlLabel
                  value="$"
                  control={<Radio />}
                  label={<Typography className={classes.text}>US$</Typography>}
                />
                {/* <FormControlLabel
                  value="BRL"
                  control={<Radio />}
                  label={<Typography className={classes.text}>BRL</Typography>}
                /> */}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography className={classes.text}>
              Amount in dispute in {currency == "$" ? "US$" : "BRL"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              // type="number"
              value={amount}
              onChange={(e) => {
                handleAmountChange(e);
              }}
              onFocus={handleFocus}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography className={classes.text}>Procedure</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-procedure"
                value={procedure}
                onChange={handleProcedureChange}
                name="radio-buttons-procedure"
              >
                <FormControlLabel
                  value="ordinary"
                  control={<Radio />}
                  label={
                    <Typography className={classes.text}>Ordinary</Typography>
                  }
                />
                <FormControlLabel
                  value="expedited"
                  control={<Radio />}
                  label={
                    <Typography className={classes.text}>Expedited</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography className={classes.text}>
              Number of arbitrators
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-no-of-arbitrators"
                value={noOfArbitrators}
                onChange={handleNumberOfArbitratorsChange}
                name="radio-buttons-no-of-arbitrators"
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={<Typography className={classes.text}>1</Typography>}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label={<Typography className={classes.text}>3</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Button variant="outlined" onClick={handleSubmit}>
            <Typography className={classes.text}>Calculate</Typography>
          </Button>
        </Grid>
        <Box height="3rem" />
        {showError && (
          <Typography className={classes.text}>
            Dispute Amount is not a valid input. Please rectify these issues.
          </Typography>
        )}
        {/* Results */}
        {showResult && !showError && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.browntext}>
                  Requested estimation
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>
                  Amount in dispute
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>
                  {currency}
                  {resultAmount}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>
                  Number of arbitrators
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>
                  {resultNoOfArbitratos}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.browntext}>
                  Fees per arbitrator
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>Min</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>
                  {currency}
                  {resultMin}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>Avg</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>
                  {currency}
                  {resultAvg}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>Max</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>
                  {currency}
                  {resultMax}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.browntext}>
                  Advance on costs (without arbitrator expenses)
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>
                  Average fees multiplied by number of arbitrators
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>
                  {currency}
                  {resultAvgMultipliedByNoOfArbitratos}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>
                  Administrative expenses
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>{currency}0</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Typography className={classes.text}>Total</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.text}>
                  {currency}
                  {total}
                </Typography>
              </Grid>
            </Grid>
            <Box height="3rem" />
          </>
        )}
      </Grid>
    </Grid>
  );
}
