import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    disputeText: {
        fontSize: '20px',
        lineHeight: '36px',
        color: '#000',
        fontWeight: 400
    },
    disputeCount: {
        fontSize: '32px',
        lineHeight: '36px',
        color: '#000',
        fontWeight: 700
    },
    disputeBox: {
        height: '125px',
        width: '216px',
        background: '#FAFCFE',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}));

export const DisputeBox = ({
    headingText,
    count,
    handleClick
}) => {
  const classes = useStyles();

  return (
    <Grid item style={{ textAlign: "center" }}>
      <Grid container>
        <Grid item onClick={handleClick} className={classes.disputeBox}>
          <Typography className={classes.disputeText}>{headingText}</Typography>
          <Typography className={classes.disputeCount}>{count}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
