import React from "react";
import { Box, Paper, Grid, Typography, makeStyles } from "@material-ui/core";
import { closed_reason_choices } from "./CaseDisplay";

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "left",
    margin: "1rem 0 0 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.5rem",
    },
  },
  overview: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "400",
    color: "#252733",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    padding: "1rem 2rem",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
    "&:hover": {
      background: "#ebebeb",
    },
  },
  selectedPaper: {
    padding: "1rem 2rem",
    textAlign: "center",
    borderRadius: "8px",
    background: "#BDBDBD",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
  },
  headingPaper: {
    fontWeight: "500",
    fontSize: "20px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontize: "15px",
    },
  },
  numberPaper: {
    fontWeight: "500",
    fontSize: "40px",
    color: "#2C2C2C",
    [theme.breakpoints.down("sm")]: {
      fontize: "30px",
    },
  },
  activityText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
  },
  activityPaper: {
    border: "1px solid #BDBDBD",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    padding: "2rem 2rem 1.3rem 2rem",
  },
  activityUsername: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#2F80ED",
  },
  activityheading: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityTime: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityfileFolderName: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    wordBreak: "break-word",
  },
  disputeHeading: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#333333",
  },
  disputeText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#333333",
    wordBreak: "break-word",
  },
  sortbybtn: {
    cursor: "pointer",
    border: "none",
    background: "#E0E0E0",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    borderRadius: "4px",
  },
  sortbytext: {
    padding: "10px 30px 10px 10px",
    fontSize: "12px",
    lineHeight: "0px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#454545",
  },
}));

const GeneralDisputeItem = ({
  dispute,
  setOpenCase,
  setCaseId,
  formatDate,
  choice,
}) => {
  const classes = useStyles();

  const arr = dispute.name.split("v.");
  const complainant = arr[0];
  const respondent = arr[1];

  return (
    <Box key={dispute.id}>
      <Paper
        style={{ padding: "1rem", cursor: "pointer" }}
        onClick={() => {
          setOpenCase(true);
          setCaseId(dispute.id);
          sessionStorage.setItem("tiac_overview_caseid", dispute.id);
        }}
      >
        <Grid container justify="flex-start" spacing={1}>
          <Grid item sm={2} xs={3}>
            <Typography className={classes.disputeText}>
              {dispute.approved ? dispute.tiac_custom_dispute_id : "---"}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3}>
            <Typography className={classes.disputeText}>
              {complainant}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3}>
            <Typography className={classes.disputeText}>
              {respondent}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={3}>
            <Typography className={classes.disputeText}>
              {dispute.flow_type === "arbitration"
                ? formatDate(dispute.converted_to_arbitration_at)
                : formatDate(dispute.created)}
            </Typography>
          </Grid>
          {choice === "closed" && (
            <Grid item sm={2} xs={3}>
              <Typography className={classes.disputeText}>
              {closed_reason_choices.find(item => item.value === dispute.closed_reason)?.name}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Box height="0.6rem"></Box>
    </Box>
  );
};

export default GeneralDisputeItem;
