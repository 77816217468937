import { createSlice } from "@reduxjs/toolkit";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { gethost } from "./disputeSlice";

export const initialState = {
  loading: false,
  hasErrors: false,
  profile: {},
  errors: {},
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      if (payload.results.length === 1) {
        state.profile = payload.results[0];
      }
      state.loading = false;
      state.hasErrors = false;
      state.errors = {};
    },

    setError: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errors = payload;
    },
  },
});

export const { setProgress, setSucessfull, setError } = profileSlice.actions;

export default profileSlice.reducer;

export function getProfile() {
  return async (dispatch) => {
    dispatch(setProgress());

    try {
      let response = await instance({
        method: "get",
        url: "/api/profile/",
      });
      console.log(response.data);
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      // console.log(error.request.status);
      // console.log(error.message)
      // console.log(error.request.response)
      dispatch(setError());
    }
  };
}

export async function getCompanyType() {
  try {
    let companyType = "";
    let response = await instance({
      method: "get",
      url: "/api/profile/",
    });
    let id = response.data.results[0].company;
    console.log("id is:", id);
    if (id) {
      try {
        let response = await instance({
          method: "get",
          url: `/api/company/${id}/`,
        });
        companyType = response.data.company_type;
        console.log("Type is:", companyType);
        return companyType;
      } catch (error) {
        console.log(error);
      }
    }
    return companyType;
  } catch (error) {
    console.log(error);
  }
}

export async function getProfiles(profile_type) {
  try {
    let response = await instance({
      method: "get",
      url: `/api/profilelist/${profile_type}/`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    // console.log(error.request.status);
    // console.log(error.message)
    // console.log(error.request.response)
  }
}

export async function getCountries() {
  try {
    let response = await instance({
      method: "get",
      url: `/api/country/`,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    // console.log(error.request.status);
    // console.log(error.message)
    // console.log(error.request.response)
  }
}
export function createProfile(profileData) {
  let { phoneNumber, gender, birthDate, user } = profileData;
  console.log(birthDate);
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: `/api/profile/`,
        data: {
          phone_number: phoneNumber,
          gender,
          birth_date: birthDate,
          user,
        },
      });
      console.log(response.data);
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      // console.log(error.message);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);
      dispatch(setError({}));
    }
  };
}

export function updateProfile(profileData) {
  let { id, phone_number, address, user } = profileData;
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "patch",
        url: `/api/profile/${id}/`,
        data: {
          phone_number: phone_number,
          address: address,
          user,
        },
      });
      console.log(response.data);
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      // console.log(error.message);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);
      dispatch(setError({}));
    }
  };
}
export function updateProfileHotstarAdmin(profileData) {
  let { id, phone_number, address, user } = profileData;
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instanceHotstar({
        method: "patch",
        url: `/api/profile/${id}/`,
        data: {
          phone_number: phone_number,
          address: address,
          user,
        },
      });
      console.log(response.data);
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      // console.log(error.message);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);
      dispatch(setError({}));
    }
  };
}
export function updateProfileDpcgcAdmin(profileData) {
  let { id, phone_number, address, user } = profileData;
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instanceDPCGC({
        method: "patch",
        url: `/api/profile/${id}/`,
        data: {
          phone_number: phone_number,
          address: address,
          user,
        },
      });
      console.log(response.data);
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      // console.log(error.message);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);
      dispatch(setError({}));
    }
  };
}

export const getProfileByPhonenumber = async (phoneNumber) => {
  try {
    let response = await instance({
      method: "get",
      url: `/api/profileget/${phoneNumber}/`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    // console.log(error.request.status);
    // console.log(error.message)
    // console.log(error.request.response)
  }
};

export const createProfileIfNot = (data) => async (dispatch) => {
  const {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    gender,
    birthDate,
    profileType = "general",
  } = data;
  try {
    let profile = await getProfileByPhonenumber(phoneNumber);
    if (isEmpty(profile)) {
      let response = await instance({
        method: "post",
        url: "/customsignup/?host=" + gethost(),
        data: {
          user: { email, password, first_name: firstName, last_name: lastName },
          phone_number: phoneNumber,
          gender,
          birth_date: birthDate,
          profile_type: profileType,
        },
      });
      profile = response.data;
    }
    return profile;
  } catch (error) {
    console.log(error.message);
    // console.log(error.request.status);
    // console.log(error.message);
    // console.log(error.request.response);
  }
};

export const createProfileIfNotTiac = (data) => async (dispatch) => {
  const {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    gender,
    birthDate,
    profileType = "general",
  } = data;
  try {
    let response = await instance({
      method: "post",
      url: "/customsignup/?host=" + gethost(),
      data: {
        user: { email, password, first_name: firstName, last_name: lastName },
        phone_number: phoneNumber.trim() == "" ? null : phoneNumber,
        gender,
        birth_date: birthDate,
        profile_type: profileType,
        is_tiac_profile: true,
      },
    });
    let profile = response.data;

    return profile;
  } catch (error) {
    console.log(error.message);
    // console.log(error.request.status);
    // console.log(error.message);
    // console.log(error.request.response);
  }
};
