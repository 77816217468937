import { Box, Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { SECRETARIAT } from "app/constants";
import classNames from "classnames";
import GroupDisplay from "features/Chat/GroupDisplay";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isArbitrationConference, isDifc, isDigipub } from "Routes/Routes";
import { getAllGroupsForRefresh, setGroups } from "slices/groupSlice";
import { createGroupName } from "utils";

const findSecretariatGroup = (cases, disputeId, groupName, activeDispute) => {
  let profileId = groupName.split("-")[1];

  if (profileId) {
    // Support group with claimant and respondent
    let supportTo = cases.find((singleCase) => {
      return (
        singleCase.dispute === disputeId &&
        singleCase.profile === parseInt(profileId)
      );
    });
    if (supportTo) return `${supportTo.type}-1`;

    // Support group with lawyers

    let supportToLawyer = cases.find((singleCase) => {
      return (
        singleCase.dispute === disputeId &&
        singleCase.lawyer === parseInt(profileId)
      );
    });
    if (supportToLawyer) return `${supportToLawyer.type}-2`;

    if (activeDispute.professionals.length > 0) {
      let professionalIndex = activeDispute.professionals.findIndex(
        (singleProfessional) => singleProfessional === parseInt(profileId)
      );
      if (professionalIndex > -1) return `Arbitrator-${professionalIndex + 1}`;
    }
  }

  return groupName;
};

const renderGroups = (
  groups,
  classes,
  selected,
  setSelected,
  setStep,
  disputeId,
  cases,
  profileType,
  activeDispute,
  dispatch
) => {
  // console.log('groups',groups);
  // console.log('selected',selected);
  return groups.map((group, index) => {
    const listClasses = classNames({
      [classes.item]: true,
      [classes.selected]: (selected === group._id),
    });

    let groupName = createGroupName(group.name);

    // if case manager is the logged in user
    if (profileType === SECRETARIAT) {
      groupName = findSecretariatGroup(
        cases,
        disputeId,
        groupName,
        activeDispute
      );
    }

    if (
      activeDispute.name.split(" ")[activeDispute.name.split(" ").length - 1] ==
      "Bench"
    ) {
      if (groupName !== " negotiation") {
        return (
          <React.Fragment key={group.name}>
            <ListItem
              button
              index={index}
              className={listClasses}
              onClick={() => {
                setSelected(group._id);
                setStep(1);
                dispatch(getAllGroupsForRefresh(disputeId));
              }}
            >
              <GroupDisplay group={group} caption={true} name={groupName} unreadStatus={true} />
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment key={group.name}>
          <ListItem
            button
            index={index}
            className={listClasses}
            onClick={() => {
              setSelected(group._id);
              setStep(1);
              dispatch(getAllGroupsForRefresh(disputeId));
            }}
          >
            <GroupDisplay group={group} caption={true} name={groupName} unreadStatus={true}/>
          </ListItem>
          <Divider />
        </React.Fragment>
      );
    }
  });
};

export default function ChatSidebar({
  classes,
  selected,
  setSelected,
  setStep,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups.groups);
  let newGroups = [];
  groups.map((item) => {
    if (!item.name.includes("negotiation")) {
      newGroups.push(item);
    }
  });
  const cases = useSelector((state) => state.cases.cases);
  const activeDispute = useSelector(
    (state) => state.disputes.activeDispute,
    shallowEqual
  );
  const { id: disputeId } = activeDispute;
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const profile = useSelector((state) => state.profile.profile);

  const theme = useTheme();
  return (
    <Box>
      <Box className={classes.drawer}>
        {/* <div className={classes.toolbar} /> */}
        <Button
          onClick={() => {
            dispatch(setGroups([]));
            if (
              (profile.profile_type == "general_rv" ||
                profile.profile_type == "lawyer_rv" ||
                profile.profile_type == "arbitrator_rv") &&
              (isDifc || isArbitrationConference)
            ) {
              history.push(`/disputes/${disputeId}/`);
            } else if (
              (profile.profile_type == "sub_admin_tiac_rv" ||
                profile.profile_type == "case_manager") &&
              (isDifc || isArbitrationConference)
            ) {
              history.push("/");
            } else {
              history.goBack();
            }
          }}
          startIcon={<ArrowBackIosIcon />}
        >
          Back
        </Button>
        <List style={{ padding: "0" }}>
          <ListItem
            style={{
              height: "5rem",
              borderBottom: `0.1rem solid ${theme.palette.border}`,
            }}
          >
            <Typography variant="h5" style={{ fontSize: "1.25rem" }}>
              <FormattedMessage id={isDifc ? "common.meetingRooms" : "common.hearingRooms"} />
            </Typography>
          </ListItem>
          <Box className="hearingroomstep1">
            {renderGroups(
              isDigipub ? newGroups : groups,
              classes,
              selected,
              setSelected,
              setStep,
              disputeId,
              cases,
              profileType,
              activeDispute,
              dispatch
            )}
          </Box>
          {/* <Divider style={{ background: "#fff" }} /> */}
        </List>
      </Box>
    </Box>
  );
}
