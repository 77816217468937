import { createMuiTheme } from "@material-ui/core/styles";
import Robotottf from "assets/fonts/Roboto/Roboto-Bold.ttf";
import Satoshittf from "assets/fonts/Satoshi/Satoshi-Medium.ttf";

const robotoRegular = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Roboto'),
    local('Roboto-Regular'),
    url(${Robotottf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const satoshiMedium = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Satoshi'),
    local('Satoshi-Medium'),
    url(${Satoshittf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const NewTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#4A3BFF",
      // mainGradient: "linear-gradient(91.33deg, #60A9F6 0%, #2A8BF2 100%)",
      mainGradient: "#60A9F6",
    },
    secondary: {
      main: "#2BB879",
    },
    secondPrimary: "linear-gradient(325.78deg, #2A8BF2 14.76%, #7CB8F7 87.3%)",

    common: {
      black: "#000",
      white: "#F7F8FA",
      grey: "#FAFBFF",
      grey1: "#707C97",
      yellow: "#FBD56F",
      red: "#FA8282",
      green: "#33B669",
      lightBlue: "#D3E5EB",
    },
    border: "#E5E5E5",
  },
  typography: {
    // color: "#454545",
    fontFamily: 'Satoshi-Medium',
    subtitle1: {},
    subtitle2: {
      textTransform: "capitalize",
    },
    caption: {
      color: "#949DB1",
    },
    h1: {
      fontFamily: 'Satoshi-Bold',
      fontSize: '1.7rem',
      fontWeight: 700,
      lineHeight: 'normal'
    },
    h5: {
    //   color: "#949DB1",
      fontWeight: "bold",
      fontSize: "2rem",
      lineHeight: "2.1rem",
    },
    h2: {
    //   color: "#454545",
      fontWeight: "bold",
    },
    body1: {
    //   color: "#454545",
      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: 'Satoshi-Medium',
      // fontSize: "1.3rem",
    },
    body2: {
      fontSize: "1.1rem",
    },
    h3: {
      fontSize: "3rem",
      lineHeight: "3rem",
      fontWeight: "bold",
      color: "#454545",
    },
    h6: {
      color: "#949DB1",
    },
  },
  shadows: ['none'],
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [satoshiMedium],
        body: {
          backgroundColor: "#fff",
          fontFamily: "Satoshi-Medium",
        },
        html: {
          scrollBehavior: "smooth",
        },
      },
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: 'capitalize',
        fontFamily: 'Satoshi-Bold',
        fontSize: '1rem',
        padding: '0.75rem 1.5rem'
      },
      contained: {
        boxShadow: 'none',
      },    
      text: {
        padding: '0.75rem 1.5rem',
      },
      
    },
    MuiIconButton: {
      root: {
        padding: '0.75rem',
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      }
    },
    MuiCheckbox: {
      root: {
        color: '#4A3BFF',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      }
    }
  },
});

export default NewTheme;
