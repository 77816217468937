import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import TIAC from "images/TIAC.jpeg";

import FormattedMessage from "features/common/TranslatedMessage";

import React, { Fragment, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import instance from "api/globalaxios";
import fileDownload from "js-file-download";

// import "intro.js/introjs.css";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // "font-size": "18px",
    color: "#2C3345",
  },

  text1: {
    "font-size": "22px",
    color: "#2C3345",
    padding: "1rem",
  },

  text2: {
    "font-size": "18px",

    color: "#464d5f",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
}));

export async function GetAnswersTiac(email, disputeid) {
  return await instance.get(
    `/api/tiacanonymousanswer/?email=${email}&dispute=${disputeid}`
  );
}

export async function GetNewAnswersTiac(email, disputeid) {
  return await instance.get(
    `/newtiacanonymousanswer/?email=${email}&dispute=${disputeid}`
  );
}

export async function editAnswerTiac(
  email,
  disputeid,
  answerid,
  value,
  caseid,
  questionid
) {
  return await instance.put(
    `/api/tiacanonymousanswer/${answerid}/?email=${email}&dispute=${disputeid}`,
    {
      value: value,
      case: caseid,
      question: questionid,
    }
  );
}

const Final = () => {
  // GetAnswersTiac();
  let id = localStorage.getItem("dispute_id");
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const caseLoading = useSelector((state) => state.cases.loading);
  // const disputeLoading = useSelector((state) => state.disputes.loading);
  let history = useHistory();

  async function generateDoc() {
    return await instance({
      method: "get",
      url: "/tiacgeneratedocafterregistration/" + id + "/",
      responseType: "blob",
    });

    instance.get(`/tiacgeneratedocafterregistration/${id}/`);
  }

  const formik = useFormik({
    initialValues: {
      date: "",
      tiac: "",
    },
  });
  console.log("valuesss", formik.name);

  const handleOnBack = () => {
    history.push("/tiac_additionalinfo");
  };

  const handleSaveAsDraft = () => {
    history.push("/");
  };

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10} md={8}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" className={classes.text}>
                <FormattedMessage id="tiac.heading8" />
              </Typography>
              <Box height="1rem"></Box>
              <hr
                style={{
                  background: "#F8F8F8",
                  width: "100%",
                }}
              />

              <Grid container spacing={3} justify="flex-start">
                <Grid item md={12} sm={12} xs={12}>
                  <Typography className={classes.text1}>
                    Date of registration/Дата регистрации/Рўйхатга олинган сана:
                  </Typography>
                  <Grid item md={5} sm={10} xs={12}>
                    <TextField
                      required
                      variant="outlined"
                      fullWidth={true}
                      value={formik.date}
                      // style={{ marginRight: '2px', width: '50%' }}
                      // className={classes.field}
                    />
                  </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography className={classes.text1}>
                    TIAC arbitration case №/Арбитражное дело TIAC №/TIAC
                    Арбитраж низоси №:
                  </Typography>
                  <Grid item md={5} sm={10} xs={12}>
                    <TextField
                      required
                      variant="outlined"
                      fullWidth={true}
                      value={formik.tiac}
                      // style={{ marginRight: '2px', width: '50%' }}
                      // className={classes.field}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box height="1rem"></Box>
              <hr
                style={{
                  background: "#F8F8F8",
                  // width: '100%',
                }}
              />
              <Box height="2rem"></Box>
              <Grid container justify="space-evenly">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOnBack}
                    className={classes.guestbtn}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.guestbtn}
                      onClick={() => {
                        generateDoc().then((response) => {
                          // fileDownload(response.data, `Generateddoc.docx`);
                          fileDownload(response.data, `Generate.pdf`);
                        });
                        // history.push("/");
                      }}
                    >
                      <FormattedMessage id="buttons.form" />
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveAsDraft}
                    className={classes.guestbtn}
                  >
                    Save as draft
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};

export default Final;
