import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { isArbitrationConference, isDifc } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  disputeType: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#1F2937",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  selected: {
    background: "#E0E0E0",
  },
  unselected: {
    cursor: "pointer",
  },
  caseName: {
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  blue: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#2F80ED",
    textDecoration: "underline",
    textAlign: "right",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

export default function TiacCard({
  disputes,
  role,
  setRole,
  disputesType,
  history,
}) {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={6} style={{ padding: "0.4rem 0" }}>
          <Typography className={classes.heading}>
            {isDifc || isArbitrationConference ? "Rounds" : `As ${role}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ padding: "0.4rem 0", textAlign: "center" }}
          className={
            disputesType == "active" ? classes.selected : classes.unselected
          }
          onClick={() => {
            setRole("active");
          }}
        >
          <Typography className={classes.disputeType}>Open</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ padding: "0.4rem 0", textAlign: "center" }}
          className={
            disputesType == "resolved" ? classes.selected : classes.unselected
          }
          onClick={() => {
            setRole("resolved");
          }}
        >
          <Typography className={classes.disputeType}>Closed</Typography>
        </Grid>
      </Grid>
      <Box height="1rem" />
      {disputes.map((dispute) => {
        return (
          <React.Fragment key={dispute.id}>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Typography className={classes.caseName}>
                  {dispute.name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={classes.blue}
                  onClick={() => {
                    history.push(`disputes/${dispute.id}`);
                  }}
                >
                  View
                </Typography>
              </Grid>
            </Grid>
            <Box height="1rem" />
          </React.Fragment>
        );
      })}
    </>
  );
}
