import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import FormattedMessage from "features/common/TranslatedMessage";
import { StyledTextFieldTiac } from "features/TempTiacFormV2/ClaimantPage/questions";
import { sendSignature } from "features/TempTiacFormV2/TiacSign/TiacSign";
import { updateDisputeTiac } from "features/TempTiacFormV2/UploadPage/questions";
import { editAnswerTiac, GetAnswersTiac } from "features/Tiacfinal/final";
import Sign from "features/TiacSign";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // color: "#2C3345",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    // color: "#2C3345",
    padding: "1rem",
    // fontFamily: `'Inter',sans-serif`,
  },

  text2: {
    "font-size": "18px",
    // fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  paper: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  input1: {
    height: 180,
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  sigImage: {
    width: "22rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
const SignupSchema = Yup.object().shape({
  first: Yup.string().required("Required "),
  second: Yup.string().required("Required "),
  third: Yup.string().required("Required "),
  forth: Yup.string().required("Required "),
  fifth: Yup.string().required("Required "),
  sixth: Yup.string().required("Required "),
  seventh: Yup.string().required("Required "),
});
var signUpData = {};

async function UploadAnswer(answer, casefile, question) {
  return await instance.post(
    `/api/tiacanonymousanswer/?email=${localStorage.getItem("email")}`,
    {
      question: question.id,
      case: casefile.id,
      value: answer,
    }
  );
}
async function getCaseFile(email, disputeId) {
  return await instance({
    method: "get",
    url: "/api/tiaccasefileanonymous/",
    params: {
      email: email,
      id: disputeId,
    },
  });
}
async function getQuestions(email) {
  return await instance({
    method: "get",
    url: "/tiacanonymousquestions",
    params: {
      email: email,
    },
  });
}
async function getDisputeTiac(email, dispute_id) {
  return await instance.get(
    `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
  );
}

async function getSignatureUrl(email, id) {
  return await instance.get(`/signatureurl/?email=${email}&id=${id}`);
}

const AdditionalInfoPageWithoutOtp = ({
  setActiveStep,
  isAllPreviosStepsCompleted,
  submitDetailsSignal,
}) => {
  let id = localStorage.getItem("dispute_id");
  let email = localStorage.getItem("email");

  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  let signPad = useRef({});
  const [signError, setSignError] = useState();
  const [isSignatureDisabled, setIsSignatureDisabled] = useState(false);
  const [signatureImageUrl, setSignatureImageUrl] = useState();
  const startSign = () => {
    setSignError();
  };
  function clear() {
    signPad.current.clear();
    setSignError("Required");
  }

  const [dispute, setDispute] = useState();

  React.useEffect(() => {
    getDisputeTiac(email, id)
      .then((res) => {
        if (res.data.results[0].id) {
          setDispute(res.data.results[0]);
          if (res.data.results[0].sig) {
            setIsSignatureDisabled(true);
            getSignatureUrl(email, res.data.results[0].sig).then((re) => {
              setSignatureImageUrl(re.data.url);
            });
          }
        } else history.push("/RFA");
      })
      .catch((e) => {
        history.push("/RFA");
      });
  }, [email, history, id]);

  const [casefile, setCasefile] = useState();
  const [quesiton1, setQuesiton1] = useState();
  const [quesiton2, setQuesiton2] = useState();
  const [quesiton3, setQuesiton3] = useState();
  const [quesiton4, setQuesiton4] = useState();
  const [quesiton5, setQuesiton5] = useState();
  const [quesiton6, setQuesiton6] = useState();
  const [quesiton7, setQuesiton7] = useState();

  const [answer1, setanswer1] = useState();
  const [answer2, setanswer2] = useState();
  const [answer3, setanswer3] = useState();
  const [answer4, setanswer4] = useState();
  const [answer5, setanswer5] = useState();
  const [answer6, setanswer6] = useState();
  const [answer7, setanswer7] = useState();

  const [answerSet, setAnswerSet] = useState(false);

  React.useEffect(() => {
    getCaseFile(email, id)
      .then((res) => {
        setCasefile(res.data.results[0]);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, [email, id]);
  React.useEffect(() => {
    getQuestions(email).then(
      (res) => {
        setQuesiton1(
          res.data.results.find((item) => item.display_order_id == 71)
        );
        setQuesiton2(
          res.data.results.find((item) => item.display_order_id == 72)
        );
        setQuesiton3(
          res.data.results.find((item) => item.display_order_id == 73)
        );
        setQuesiton4(
          res.data.results.find((item) => item.display_order_id == 74)
        );
        setQuesiton5(
          res.data.results.find((item) => item.display_order_id == 75)
        );
        setQuesiton6(
          res.data.results.find((item) => item.display_order_id == 76)
        );
        setQuesiton7(
          res.data.results.find((item) => item.display_order_id == 77)
        );
      },
      (error) => {
        console.log("err", error);
      }
    );
  }, [email]);

  const formik = useFormik({
    initialValues: {
      first: "",
      second: "",
      third: "",
      forth: "",
      fifth: "",
      sixth: "",
      seventh: "",
      registration: "",
      case: "",
    },
    validationSchema: SignupSchema,

    onSubmit: async (values) => {
      if (!isSignatureDisabled && signPad.current.isEmpty()) {
        setSignError("Required");
      } else {
        let {
          first,
          second,
          third,
          forth,
          fifth,
          sixth,
          seventh,
          registration,
        } = values;

        if (isAllPreviosStepsCompleted) {
          //   send otp
        } else {
          signUpData = values;
          setLoading(true);
          if (answer1) {
            editAnswerTiac(
              email,
              id,
              answer1.id,
              first,
              answer1.case,
              answer1.question
            ).then((r1) => {
              if (answer2) {
                editAnswerTiac(
                  email,
                  id,
                  answer2.id,
                  second,
                  answer2.case,
                  answer2.question
                ).then((r2) => {
                  if (answer3) {
                    editAnswerTiac(
                      email,
                      id,
                      answer3.id,
                      third,
                      answer3.case,
                      answer3.question
                    ).then((r3) => {
                      if (answer4) {
                        editAnswerTiac(
                          email,
                          id,
                          answer4.id,
                          forth,
                          answer4.case,
                          answer4.question
                        ).then((r4) => {
                          if (answer5) {
                            editAnswerTiac(
                              email,
                              id,
                              answer5.id,
                              fifth,
                              answer5.case,
                              answer5.question
                            ).then((r5) => {
                              if (answer6) {
                                editAnswerTiac(
                                  email,
                                  id,
                                  answer6.id,
                                  sixth,
                                  answer6.case,
                                  answer6.question
                                ).then((r6) => {
                                  if (answer7) {
                                    editAnswerTiac(
                                      email,
                                      id,
                                      answer7.id,
                                      seventh,
                                      answer7.case,
                                      answer7.question
                                    ).then((r7) => {
                                      alert(
                                        "Please complete all the steps first."
                                      );
                                      window.location.reload();
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
          if (!answer1) {
            UploadAnswer(first, casefile, quesiton1).then((res1) => {
              if (!answer2) {
                UploadAnswer(second, casefile, quesiton2).then((res2) => {
                  if (!answer3) {
                    UploadAnswer(third, casefile, quesiton3).then((res3) => {
                      if (!answer4) {
                        UploadAnswer(forth, casefile, quesiton4).then(
                          (res4) => {
                            if (!answer5) {
                              UploadAnswer(fifth, casefile, quesiton5).then(
                                (res5) => {
                                  if (!answer6) {
                                    UploadAnswer(
                                      sixth,
                                      casefile,
                                      quesiton6
                                    ).then((res6) => {
                                      if (!answer7) {
                                        UploadAnswer(
                                          seventh,
                                          casefile,
                                          quesiton7
                                        ).then((res7) => {
                                          alert(
                                            "Please complete all the steps first."
                                          );
                                          window.location.reload();
                                        });
                                      }
                                    });
                                  }
                                }
                              );
                            }
                          }
                        );
                      }
                    });
                  }
                });
              }
            });
          }
          return 0;
        }

        if (!isSignatureDisabled && !signPad.current.isEmpty()) {
          dispatch(
            sendSignature(
              id,
              email,
              signPad.current.getTrimmedCanvas().toDataURL("image/png")
            )
          );
        }

        signUpData = values;
        setLoading(true);
        if (answer1) {
          editAnswerTiac(
            email,
            id,
            answer1.id,
            first,
            answer1.case,
            answer1.question
          ).then((r1) => {
            if (answer2) {
              editAnswerTiac(
                email,
                id,
                answer2.id,
                second,
                answer2.case,
                answer2.question
              ).then((r2) => {
                if (answer3) {
                  editAnswerTiac(
                    email,
                    id,
                    answer3.id,
                    third,
                    answer3.case,
                    answer3.question
                  ).then((r3) => {
                    if (answer4) {
                      editAnswerTiac(
                        email,
                        id,
                        answer4.id,
                        forth,
                        answer4.case,
                        answer4.question
                      ).then((r4) => {
                        if (answer5) {
                          editAnswerTiac(
                            email,
                            id,
                            answer5.id,
                            fifth,
                            answer5.case,
                            answer5.question
                          ).then((r5) => {
                            if (answer6) {
                              editAnswerTiac(
                                email,
                                id,
                                answer6.id,
                                sixth,
                                answer6.case,
                                answer6.question
                              ).then((r6) => {
                                if (answer7) {
                                  editAnswerTiac(
                                    email,
                                    id,
                                    answer7.id,
                                    seventh,
                                    answer7.case,
                                    answer7.question
                                  ).then((r7) => {
                                    updateDisputeTiac(
                                      {
                                        id: id,
                                        tiac_dispute_step:
                                          "additional_info_complete",
                                      },
                                      email
                                    ).then((res) => {
                                      tempInvoiceAndOtp(email, id)
                                        .then((res) => {
                                          if (res.data.status == "success") {
                                            updateDisputeTiac(
                                              {
                                                id: id,
                                                tiac_dispute_step:
                                                  "additional_info_complete",
                                              },
                                              email
                                            );
                                            history.push("/tiac_waitingscreen");
                                          }
                                        })
                                        .catch((err) => {
                                          console.log("err", err);
                                          console.log(
                                            "resp",
                                            err.response.data
                                          );
                                          alert(err.response.data.status);
                                        });
                                      // generateDoc();
                                      // history.push("/tiac_waitingscreen");
                                    });
                                  });
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
        if (!answer1) {
          UploadAnswer(first, casefile, quesiton1).then((res1) => {
            if (!answer2) {
              UploadAnswer(second, casefile, quesiton2).then((res2) => {
                if (!answer3) {
                  UploadAnswer(third, casefile, quesiton3).then((res3) => {
                    if (!answer4) {
                      UploadAnswer(forth, casefile, quesiton4).then((res4) => {
                        if (!answer5) {
                          UploadAnswer(fifth, casefile, quesiton5).then(
                            (res5) => {
                              if (!answer6) {
                                UploadAnswer(sixth, casefile, quesiton6).then(
                                  (res6) => {
                                    if (!answer7) {
                                      UploadAnswer(
                                        seventh,
                                        casefile,
                                        quesiton7
                                      ).then((res7) => {
                                        updateDisputeTiac(
                                          {
                                            id: id,
                                            tiac_dispute_step:
                                              "additional_info_complete",
                                          },
                                          email
                                        ).then((res) => {
                                          tempInvoiceAndOtp(email, id)
                                            .then((res) => {
                                              if (
                                                res.data.status == "success"
                                              ) {
                                                updateDisputeTiac(
                                                  {
                                                    id: id,
                                                    tiac_dispute_step:
                                                      "additional_info_complete",
                                                  },
                                                  email
                                                );
                                                history.push(
                                                  "/tiac_waitingscreen"
                                                );
                                              }
                                            })
                                            .catch((err) => {
                                              console.log("err", err);
                                              console.log(
                                                "resp",
                                                err.response.data
                                              );
                                              alert(err.response.data.status);
                                            });
                                          // generateDoc();
                                          // history.push("/tiac_waitingscreen");
                                        });
                                      });
                                    }
                                  }
                                );
                              }
                            }
                          );
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }
    },
  });

  React.useEffect(() => {
    if (
      quesiton1 &&
      quesiton2 &&
      quesiton3 &&
      quesiton4 &&
      quesiton5 &&
      quesiton6 &&
      quesiton7 &&
      !answerSet
    )
      GetAnswersTiac(email, id).then((res) => {
        setAnswerSet(true);
        let t1 = res.data.results.find((item) => item.question == quesiton1.id);
        formik.setFieldValue("first", t1 ? t1.value : "");
        setanswer1(t1);
        let t2 = res.data.results.find((item) => item.question == quesiton2.id);
        formik.setFieldValue("second", t2 ? t2.value : "");
        setanswer2(t2);
        let t3 = res.data.results.find((item) => item.question == quesiton3.id);
        formik.setFieldValue("third", t3 ? t3.value : "");
        setanswer3(t3);
        let t4 = res.data.results.find((item) => item.question == quesiton4.id);
        formik.setFieldValue("forth", t4 ? t4.value : "");
        setanswer4(t4);
        let t5 = res.data.results.find((item) => item.question == quesiton5.id);
        formik.setFieldValue("fifth", t5 ? t5.value : "");
        setanswer5(t5);
        let t6 = res.data.results.find((item) => item.question == quesiton6.id);
        formik.setFieldValue("sixth", t6 ? t6.value : "");
        setanswer6(t6);
        let t7 = res.data.results.find((item) => item.question == quesiton7.id);
        formik.setFieldValue("seventh", t7 ? t7.value : "");
        setanswer7(t7);
      });
  }, [
    answerSet,
    email,
    formik,
    id,
    quesiton1,
    quesiton2,
    quesiton3,
    quesiton4,
    quesiton5,
    quesiton6,
    quesiton7,
  ]);

  useEffect(() => {
    if (submitDetailsSignal) {
      let { first, second, third, forth, fifth, sixth, seventh, registration } =
        formik.values;

      if (!isSignatureDisabled && !signPad.current.isEmpty()) {
        dispatch(
          sendSignature(
            id,
            email,
            signPad.current.getTrimmedCanvas().toDataURL("image/png")
          )
        );
      }
      if (answer1) {
        editAnswerTiac(
          email,
          id,
          answer1.id,
          first,
          answer1.case,
          answer1.question
        ).then((r1) => {
          if (answer2) {
            editAnswerTiac(
              email,
              id,
              answer2.id,
              second,
              answer2.case,
              answer2.question
            ).then((r2) => {
              if (answer3) {
                editAnswerTiac(
                  email,
                  id,
                  answer3.id,
                  third,
                  answer3.case,
                  answer3.question
                ).then((r3) => {
                  if (answer4) {
                    editAnswerTiac(
                      email,
                      id,
                      answer4.id,
                      forth,
                      answer4.case,
                      answer4.question
                    ).then((r4) => {
                      if (answer5) {
                        editAnswerTiac(
                          email,
                          id,
                          answer5.id,
                          fifth,
                          answer5.case,
                          answer5.question
                        ).then((r5) => {
                          if (answer6) {
                            editAnswerTiac(
                              email,
                              id,
                              answer6.id,
                              sixth,
                              answer6.case,
                              answer6.question
                            ).then((r6) => {
                              if (answer7) {
                                editAnswerTiac(
                                  email,
                                  id,
                                  answer7.id,
                                  seventh,
                                  answer7.case,
                                  answer7.question
                                ).then((r7) => {
                                  history.push(
                                    "/resumerfa?random=" + Math.random()
                                  );
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
      if (!answer1) {
        UploadAnswer(first, casefile, quesiton1).then((res1) => {
          if (!answer2) {
            UploadAnswer(second, casefile, quesiton2).then((res2) => {
              if (!answer3) {
                UploadAnswer(third, casefile, quesiton3).then((res3) => {
                  if (!answer4) {
                    UploadAnswer(forth, casefile, quesiton4).then((res4) => {
                      if (!answer5) {
                        UploadAnswer(fifth, casefile, quesiton5).then(
                          (res5) => {
                            if (!answer6) {
                              UploadAnswer(sixth, casefile, quesiton6).then(
                                (res6) => {
                                  if (!answer7) {
                                    UploadAnswer(
                                      seventh,
                                      casefile,
                                      quesiton7
                                    ).then((res7) => {
                                      history.push(
                                        "/resumerfa?random=" + Math.random()
                                      );
                                    });
                                  }
                                }
                              );
                            }
                          }
                        );
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
  }, [
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    casefile,
    dispatch,
    email,
    formik.values,
    history,
    id,
    isSignatureDisabled,
    quesiton1,
    quesiton2,
    quesiton3,
    quesiton4,
    quesiton5,
    quesiton6,
    quesiton7,
    submitDetailsSignal,
  ]);

  async function tempInvoiceAndOtp(email, dispute_id) {
    return await instance.get(
      `/tiacotpandinvoice/${dispute_id}/?email=${email}`
    );
  }

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3} justify="center">
        {/* <Grid item xs></Grid> */}
        <Grid item xs={11}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" className={classes.text}>
                <FormattedMessage id="tiac.heading7" />
              </Typography>
              <Box height="1rem"></Box>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} justify="flex-start">
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      7.1. Proposal as to the seat of arbitration, if there is
                      no agreement on the seat/Предложение в отношении места
                      арбитража (страна, процессуальное право которой будет
                      применяться), если нет соглашения между истцом и
                      ответчиком о месте арбитража/Даъвогар ва жавобгар ўртасида
                      арбитраж суди тўғрисида келишув бўлмаса, арбитраж суди
                      тўғрисида таклиф (процессуал қонуни амал қиладиган
                      мамлакат): <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.first}
                        fullWidth
                        id="first"
                        // label={<FormattedMessage id="user.email" />}
                        name="first"
                        autoComplete="first"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.first && formik.touched.first && (
                        <Alert severity="error">{formik.errors.first}</Alert>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      7.2. Proposal as to language of arbitration, if there is
                      no agreement on the language of arbitration/Предложение в
                      отношении языка арбитража, если нет соглашения между
                      истцом и ответчиком о языке арбитража/Даъвогар ва жавобгар
                      ўртасида арбитраж тили бўйича келишув бўлмаса, арбитраж
                      судининг тилига оид таклиф:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.second}
                        fullWidth
                        id="second"
                        // label={<FormattedMessage id="user.email" />}
                        name="second"
                        autoComplete="second"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.second && formik.touched.second && (
                        <Alert severity="error">{formik.errors.second}</Alert>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      7.3. Comments on the applicable law/Комментарии о
                      применимом праве/Амалдаги қонунга шарҳлар:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.third}
                        fullWidth
                        id="third"
                        multiline
                        rows={3}
                        name="third"
                        autoComplete="third"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.third && formik.touched.third && (
                        <Alert severity="error">{formik.errors.third}</Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      7.4. Confirmation of identity of any third party funder,
                      who finances these arbitral proceedings/Указание названия
                      организации, которая финансирует этот арбитраж (в случае,
                      если этот арбитраж финансируется третьей стороной, а не
                      истцом)/Ушбу арбитражни молиялаштирадиган ташкилот номини
                      кўрсатинг (агар бу арбитраж даъвогар томонидан эмас, балки
                      учинчи шахс томонидан молиялаштирилса):{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.forth}
                        fullWidth
                        id="forth"
                        // label={<FormattedMessage id="user.email" />}
                        name="forth"
                        autoComplete="forth"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.forth && formik.touched.forth && (
                        <Alert severity="error">{formik.errors.forth}</Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      7.5. Confirmation of the methods for determining the fees
                      and expenses of the Tribunal (hourly rate or according to
                      the TIAC Schedule of costs and if an hourly rate, the
                      suggestion as to the rate)/Предложение в отношении
                      компенсации арбитров (почасовая ставка или согласно
                      таблице гонораров Ташкентского международного арбитражного
                      центра, и в случае почасовой ставки, предложение по
                      ставке)/Арбитр товон пули бўйича таклиф (соатлик ставка
                      ёки Тошкент халқаро арбитраж марказининг тўловлар
                      жадвалига мувофиқ):
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.fifth}
                        fullWidth
                        id="fifth"
                        // label={<FormattedMessage id="user.email" />}
                        name="fifth"
                        autoComplete="fifth"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.fifth && formik.touched.fifth && (
                        <Alert severity="error">{formik.errors.fifth}</Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      7.6. Description of the nature and circumstances giving
                      rise to the claim/Описание обстоятельств, которые
                      послужили основанием для подачи заявки в арбитраж/Арбитраж
                      судига ариза беришга сабаб бўлган ҳолатларнинг тавсифи:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.sixth}
                        fullWidth
                        id="sixth"
                        multiline
                        rows={3}
                        name="sixth"
                        autoComplete="sixth"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.sixth && formik.touched.sixth && (
                        <Alert severity="error">{formik.errors.sixth}</Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      7.7. Preliminary statement of relief sought and, to the
                      extent possible, an indication of the amount
                      claimed/Предварительные требования и, если возможно,
                      предварительная сумма требований/Даъвонинг дастлабки
                      талаблари ва иложи бўлса, даъвонинг дастлабки миқдори{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        // required
                        variant="outlined"
                        margin="normal"
                        value={formik.values.seventh}
                        fullWidth
                        id="seventh"
                        multiline
                        rows={3}
                        name="seventh"
                        autoComplete="seventh"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.seventh && formik.touched.seventh && (
                        <Alert severity="error">{formik.errors.seventh}</Alert>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      I, the undersigned, request the Tashkent International
                      Arbitration Center to take all necessary actions in
                      accordance with the TIAC Arbitration Rules 2019/Я,
                      нижеподписавшийся, прошу Ташкентский международный
                      арбитражный центр принять все меры по данному арбитражному
                      разбирательству согласно Арбитражному регламенту TIAC
                      2019г./Мен, қуйидаги имзо чекувчи, Тошкент халқаро
                      арбитраж марказидан ушбу арбитраж низо бўйича TIAC 2019
                      йилги Арбитраж регламентига мувофиқ барча чораларни
                      кўришни илтимос қиламан:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid item style={{ padding: "0 2rem" }}>
                      <Box height="2rem"></Box>
                      {!isSignatureDisabled ? (
                        <Sign
                          signPad={signPad}
                          startSign={startSign}
                          clear={clear}
                        />
                      ) : (
                        <img
                          src={signatureImageUrl}
                          alt="user's sign"
                          className={classes.sigImage}
                        />
                      )}
                      {signError && (
                        <Grid container>
                          <Grid item md={6} sm={8} xs={12}>
                            <Alert severity="error">{signError}</Alert>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Box height="3rem"></Box>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ background: "#2F80ED" }}
                        disabled={loading}
                        onClick={() => {
                          if (
                            !isSignatureDisabled &&
                            signPad.current.isEmpty()
                          ) {
                            setSignError(
                              <FormattedMessage id="tiac.required" />
                            );
                          }
                        }}
                      >
                        <Typography className={classes.btnTextWhite}>
                          <FormattedMessage id="buttons.proceed" />
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs /> */}
      </Grid>
    </Box>
  );
};

export default AdditionalInfoPageWithoutOtp;
