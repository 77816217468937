import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import ADRPsSvg from "images/adrps.svg";
import calenderSvg from "images/calender.svg";
import casemanagersSvg from "images/casemanagers.svg";
import clientsSvg from "images/clients.svg";
import createAccountsSvg from "images/createaccounts.svg";
import driveSvg from "images/drive.svg";
import financeSvg from "images/finance.svg";
import permissionsSvg from "images/permissions.svg";
import plusSvg from "images/plus.svg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CDR from "./Overview/CDR";
import GrievanceRedressal from "./Overview/GrievanceRedressal";
import RV from "./Overview/RV";
import SubAdminOverview from "./Overview/SubAdminOverview";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paperOpen: {
    padding: "20px 0",
    width: "100%",
    borderRadius: "8px",
    cursor: "pointer",
  },
  headingOpen: {
    "font-weight": "500",
    "font-size": "20px",
    "line-height": "23px",
    "text-align": "center",
    "letter-spacing": "0.4px",
    color: "#454545",
  },
  numberOpen: {
    "font-weight": "500",
    "font-size": "40px",
    "line-height": "47px",
    "text-align": "center",
    "letter-spacing": "1px",
    color: "#2C2C2C",
  },
  paperWidget: {
    padding: "70px 0px 0px 0px",
    width: "260px",
    height: "110px",
    borderRadius: "8px",
    cursor: "pointer",
  },
  textWidget: {
    "font-size": "16px",
    "line-height": "19px",
    "text-align": "center",
    "letter-spacing": "0.3px",
    color: "#000000",
  },
  paperPlusWidget: {
    padding: "60px 0 10px 0",
    width: "260px",
    height: "110px",
    borderRadius: "8px",
  },
}));

export default function Overview({
  setStep,
  setSelected,
  profile,
  isSmallScreen,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  let t = sessionStorage.getItem("overviewStep");
  let i = sessionStorage.getItem("id");
  let com = sessionStorage.getItem("company");
  let m = sessionStorage.getItem("mode");

  const [showSubAdmin, setShowSubAdmin] = useState(t == 3 ? true : false);
  const [id, setId] = useState(i ? i : null);
  const [company, setCompany] = useState(com ? com : null);
  const [mode, setMode] = useState(m ? m : null);

  let g = sessionStorage.getItem("overviewG");
  let c = sessionStorage.getItem("overviewC");
  let r = sessionStorage.getItem("overviewR");

  const [openGrievanceRedressal, setOpenGrievanceRedressal] = useState(
    g ? g : false
  );
  const [openCDR, setOpenCDR] = useState(c ? c : false);
  const [openRV, setOpenRV] = useState(r ? r : false);
  const [openGrievanceRedressalCount, setOpenGrievanceRedressalCount] =
    useState(0);
  const [openCDRCount, setOpenCDRCount] = useState(0);
  const [openRVCount, setOpenRVCount] = useState(0);
  const [type, setType] = useState(true);
  const RenderOptions = () => {
    if (openGrievanceRedressal) {
      return (
        <GrievanceRedressal
          setOpenGrievanceRedressal={setOpenGrievanceRedressal}
          openGrievanceRedressalCount={openGrievanceRedressalCount}
          setShowSubAdmin={setShowSubAdmin}
          setId={setId}
          setCompany={setCompany}
          setMode={setMode}
          setType={setType}
        />
      );
    }
    if (openCDR) {
      return (
        <CDR
          setOpenCDR={setOpenCDR}
          openCDRCount={openCDRCount}
          setShowSubAdmin={setShowSubAdmin}
          setId={setId}
          setCompany={setCompany}
          setMode={setMode}
          setType={setType}
        />
      );
    }
    if (openRV) {
      return (
        <RV
          setOpenRV={setOpenRV}
          openRVCount={openRVCount}
          setShowSubAdmin={setShowSubAdmin}
          setId={setId}
          setCompany={setCompany}
          setMode={setMode}
          isSmallScreen={isSmallScreen}
          setType={setType}
          profile={profile}

        />
      );
    }
  };

  React.useEffect(() => {
    dispatch(apiFunction()).then((res) => {
      dispatch(apiFunctionHotstar()).then((resHotstar) => {
        dispatch(apiFunctionDpcgcAdmin()).then((resDpcgc) => {
          setOpenGrievanceRedressalCount(
            res.data.grievance.open +
              resHotstar.data.grievance.open +
              resDpcgc.data.grievance.open
          );
        });
      });
    });
  }, []);

  function apiFunction() {
    return async (dispatch) => {
      try {
        const response = await instance.get("/api/companybyplateformcount/");
        // setOpenGrievanceRedressalCount(response.data.data.grievance.open);
        setOpenCDRCount(response.data.data.cdr.open);
        setOpenRVCount(response.data.data.rv.open);
        // setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function apiFunctionHotstar() {
    return async (dispatch) => {
      try {
        const response = await instanceHotstar.get(
          "/api/companybyplateformcount/"
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function apiFunctionDpcgcAdmin() {
    return async (dispatch) => {
      try {
        const response = await instanceDPCGC.get(
          "/api/companybyplateformcount/"
        );
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    if (showSubAdmin) {
      sessionStorage.setItem("overviewStep", 3);
      return (
        <SubAdminOverview
          profile={profile}
          isSmallScreen={isSmallScreen}
          company={company}
          id={id}
          setOpenGrievanceRedressal={setOpenGrievanceRedressal}
          setOpenCDR={setOpenCDR}
          setOpenRV={setOpenRV}
          setShowSubAdmin={setShowSubAdmin}
          openGrievanceRedressal={openGrievanceRedressal}
          openCDR={openCDR}
          openRV={openRV}
          mode={mode}
          type={type}
        />
      );
    } else {
      if (!openGrievanceRedressal && !openCDR && !openRV) {
        let t = sessionStorage.getItem("overviewStep");
        if (!t) sessionStorage.setItem("overviewStep", 1);
      }
      return (
        <div className={classes.root}>
          <Grid container justify="flex-start" direction="column">
            <Grid item>
              <Grid container justify="center" direction="row">
                <Grid item xs={11} sm={6} md={6} style={{ textAlign: "start" }}>
                  <Typography className={classes.heading}>
                    Overview (Open disputes)
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={6} md={6} style={{ textAlign: "end" }}>
                  <Typography className={classes.username}>
                    {profile.user.first_name} {profile.user.last_name}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="2rem"></Box>
              {!openGrievanceRedressal && !openCDR && !openRV ? (
                <>
                  <Grid
                    container
                    justify="center"
                    spacing={3}
                    direction="row"
                    wrap="wrap"
                  >
                    <Grid item xs={11} sm={6} md={4}>
                      <Paper
                        className={classes.paperOpen}
                        onClick={(e) => {
                          setOpenGrievanceRedressal(true);
                          sessionStorage.setItem("overviewStep", 2);
                          sessionStorage.setItem("overviewG", true);
                          sessionStorage.removeItem("company");
                          sessionStorage.removeItem("mode");
                          sessionStorage.removeItem("id");
                          sessionStorage.removeItem("caseid");
                          sessionStorage.removeItem(
                            "subAdminInSuperAdminOverviewStep"
                          );
                          sessionStorage.removeItem("clientsStep");
                        }}
                      >
                        <Typography className={classes.headingOpen}>
                          Grievance Redressal
                        </Typography>
                        <Box height="0.5rem"></Box>
                        <Typography className={classes.numberOpen}>
                          {openGrievanceRedressalCount}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={11} sm={6} md={4}>
                      <Paper
                        className={classes.paperOpen}
                        onClick={(e) => {
                          setOpenCDR(true);
                          sessionStorage.setItem("overviewStep", 2);
                          sessionStorage.setItem("overviewC", true);
                          sessionStorage.removeItem("company");
                          sessionStorage.removeItem("mode");
                          sessionStorage.removeItem("id");
                          sessionStorage.removeItem("caseid");
                          sessionStorage.removeItem(
                            "subAdminInSuperAdminOverviewStep"
                          );
                          sessionStorage.removeItem("clientsStep");
                        }}
                      >
                        <Typography className={classes.headingOpen}>
                          CDR
                        </Typography>
                        <Box height="0.5rem"></Box>
                        <Typography className={classes.numberOpen}>
                          {openCDRCount}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={11} sm={6} md={4}>
                      <Paper
                        className={classes.paperOpen}
                        onClick={(e) => {
                          setOpenRV(true);
                          sessionStorage.setItem("overviewStep", 2);
                          sessionStorage.setItem("overviewR", true);
                          sessionStorage.removeItem("company");
                          sessionStorage.removeItem("mode");
                          sessionStorage.removeItem("id");
                          sessionStorage.removeItem("caseid");
                          sessionStorage.removeItem(
                            "subAdminInSuperAdminOverviewStep"
                          );
                          sessionStorage.removeItem("clientsStep");
                        }}
                      >
                        <Typography className={classes.headingOpen}>
                          RV
                        </Typography>
                        <Box height="0.5rem"></Box>
                        <Typography className={classes.numberOpen}>
                          {openRVCount}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Box height="6rem"></Box>
                  <Grid container>
                    <Grid item style={{ textAlign: "start" }}>
                      <Typography className={classes.heading}>
                        Widgets
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box height="2rem"></Box>
                  <Grid container justify="space-evenly" spacing={5}>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(2);
                        }}
                      >
                        <img
                          src={clientsSvg}
                          alt="Clients image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          Clients
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(3);
                        }}
                      >
                        <img
                          src={createAccountsSvg}
                          alt="Create accounts image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          Create Accounts
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(4);
                        }}
                      >
                        <img
                          src={financeSvg}
                          alt="Finance image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          Finance
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(5);
                        }}
                      >
                        <img
                          src={casemanagersSvg}
                          alt="Case managers image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          Case Managers
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(6);
                        }}
                      >
                        <img
                          src={ADRPsSvg}
                          alt="ADRPs image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          ADRP’s
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(7);
                        }}
                      >
                        <img
                          src={permissionsSvg}
                          alt="Permissions image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          Permissions
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(8);
                        }}
                      >
                        <img
                          src={driveSvg}
                          alt="Drive image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          Drive
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        className={classes.paperWidget}
                        onClick={(e) => {
                          setSelected(9);
                        }}
                      >
                        <img
                          src={calenderSvg}
                          alt="Calender image"
                          width=""
                          height="50px"
                        ></img>
                        <Box height="0.7rem"></Box>
                        <Typography className={classes.textWidget}>
                          Calender
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paperPlusWidget}>
                        <img src={plusSvg} alt="Plus image"></img>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paperPlusWidget}>
                        <img src={plusSvg} alt="Plus image"></img>
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <RenderOptions />
              )}
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
