import { Box, makeStyles, Typography } from "@material-ui/core";
import Avatar from "features/Chat/Avatar";
import DocTemplate from "features/Chat/DocTemplate";
import React, { Fragment } from "react";
import {
  red,
  green,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    maxWidth: "70%",
  },
  root: {
    borderRadius: "16px 16px 16px 4px",
    background: "#fff",
    border: "1px solid #D0CFCF",
    wordBreak: "break-all",
    color: '#000',
    textAlign: 'left',
  },
  time: {
    color: "#5A5A5A",
  },
  name: {
    fontFamily: 'Satoshi-Bold',
    lineHeight: 1.2,
    fontSize: '0.9rem',
  }
}));

const colors = {
  A: red[500],
  B: green[500],
  C: red[600],
  D: pink[500],
  E: purple[500],
  F: deepPurple[500],
  G: indigo[500],
  H: blue[500],
  I: lightBlue[500],
  J: cyan[500],
  K: teal[500],
  L: green[500],
  M: lightGreen[500],
  N: lime[700],
  O: yellow[500],
  P: amber[500],
  Q: orange[500],
  R: deepOrange[500],
  S: deepPurple[800],

  T: amber[800],
  U: lime[800],

  V: orange[800],

  W: purple[700],
  X: yellow[700],
  Y: lightGreen[800],
  Z: red[800],
};

export default function RecievedMessage({
  content,
  domId,
  file,
  attachements,
  parsedTimestamp,
  from,
  name,
}) {
  const classes = useStyles();
  return (
    <Fragment>
      {file ? (
        <DocTemplate
          file={file}
          domId={domId}
          classes={classes}
          position="flex-start"
          from={from}
          parsedTimestamp={parsedTimestamp}
          name={name}
        />
      ) : (
        <Box m="2rem 0">
          <div className={classes.container} id={domId}>
            <Avatar value={name[0].toUpperCase()} name={name} />
            <Box width="1rem"></Box>
            <Box textAlign="end">
              <Box p="0.7rem" className={classes.root}>
                <Typography className={classes.name} style={{color: colors[name[0].toUpperCase()]}}>{name}</Typography>
                <Typography variant="subtitle1" dangerouslySetInnerHTML={{__html: content}}></Typography>
              </Box>
              <Typography variant="caption" className={classes.time}>
                {parsedTimestamp}
              </Typography>
            </Box>
          </div>
        </Box>
      )}
    </Fragment>
  );
}
