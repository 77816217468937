import React, { useState } from "react";
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import bluepenSvg from "images/bluepen.svg";
import Assign from "./Assign";
import CreateLoginDetails from "./CreateLoginDetails";
import EditProfile from "./EditProfile";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "35px",
    textAlign: "center",
    borderRadius: "4px",
    // width: "425px",
    // height: "360px",
    wordBreak: "break-all",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headingblue: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  bluetext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    textDecorationLine: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  text: {
    // fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
}));

export default function Complainant({
  groData,
  caseId,
  companyId,
  mode,
  allProfiles,
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openmodal, setOpenmodal] = useState(false);

  const handleOpenModal = () => {
    setOpenmodal(true);
  };

  const handleCloseModal = () => {
    setOpenmodal(false);
  };

  const [openmodal2, setOpenmodal2] = useState(false);

  const handleOpenModal2 = () => {
    setOpenmodal2(true);
  };

  const handleCloseModal2 = () => {
    setOpenmodal2(false);
  };

  const [openmodal3, setOpenmodal3] = useState(false);

  const handleOpenModal3 = () => {
    setOpenmodal3(true);
  };

  const handleCloseModal3 = () => {
    setOpenmodal3(false);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.headingblue}>CLAIMANT(S)</Typography>
        </Grid>
        <Grid item>
          <img
            src={bluepenSvg}
            alt="pen image"
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal3}
          />
        </Grid>
      </Grid>
      <Box height="1rem"></Box>
      {/* <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            Assign
          </Typography>
        </Grid>
      </Grid> */}
      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography className={classes.blackheading}>
            {groData.user.first_name} {groData.user.last_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography className={classes.text}>
            Email: {groData.user.email}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography className={classes.text}>
            Phone: {groData.profile.phone_number}{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography className={classes.text}>
            Address: {groData.profile.address ? groData.profile.address : "--"}
          </Typography>
        </Grid>
      </Grid>
      {/* <Box height="1rem"></Box> */}
      <Box height="5.5rem"></Box>

      {openmodal && (
        <Assign
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          caseId={caseId}
          profileType={"general"}
          groData={groData}
          allProfiles={allProfiles}
          mode={mode}
        />
      )}

      {/* <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal2}
          >
            + Create login details
          </Typography>
        </Grid>
      </Grid> */}
      {openmodal2 && (
        <CreateLoginDetails
          openmodal={openmodal2}
          handleCloseModal={handleCloseModal2}
          caseId={caseId}
          profileType={"general"}
          companyId={companyId}
          mode={mode}
        />
      )}
      {openmodal3 && (
        <EditProfile
          openmodal={openmodal3}
          handleCloseModal={handleCloseModal3}
          profileData={groData}
          profileType={"general"}
        />
      )}
    </Paper>
  );
}
