import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { isHotStar, isMuthoot, isStar } from "Routes/Routes";
import { downloadLegalDoc } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "5rem",
    marginTop: "2rem",
  },
  text: {
    fontSize: "40px",
    lineHeight: "36px",
  },
}));

export default function ClosedDispute({ activeDispute }) {
  const classes = useStyles();

  const docs = activeDispute.all_docs;
  let doc;
  docs.map((item) => {
    if (item.is_final) {
      doc = item.id;
    }
  });

  return (
    <Paper className={classes.paper}>
      <Grid container justify="center">
        <Grid item>
          <Typography className={classes.text}>Grievance is closed</Typography>
        </Grid>
      </Grid>
      <Box height="1.6rem"></Box>
      {doc && (isHotStar || isStar || isMuthoot) && (
        <Box display="flex" justifyContent="center">
          <Button
            style={{ margin: "0 0 0.5rem 0" }}
            color="primary"
            size="large"
            onClick={() => {
              downloadLegalDoc(doc);
            }}
          >
            Final Decision by grievance officer
          </Button>
        </Box>
      )}
    </Paper>
  );
}
