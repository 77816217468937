import { Box, Button, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import React from "react";

export default function DropdownButton({
  buttonText,
  options,
  classes,
  handleClickOption,
  selectedOption,
}){
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (value) => {
    handleClickOption(value);
    handleClose();
  }

  return (
    <>
      <Box >
        <Button
          aria-controls= {buttonText + '-menu'}
          aria-haspopup="true"
          onClick={handleClick}
          className={
            Boolean(anchorEl)
              ? classes.typeButtonBlue
              : classes.typeButton
          }
          endIcon={
            <KeyboardArrowDown
              style={
                Boolean(anchorEl)
                  ? { color: "#fff" }
                  : { color: "#4F4F4F" }
              }
            />
          }
        >
          {buttonText}
        </Button>
        <Menu
          id= {buttonText + '-menu'}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ padding: 8, maxHeight: '450px' }}
        >
          {options.map((item, index) => {
            const value = item?.value || item.title
            return (
              <MenuItem
                className={classes.typeFormControl}
                key={index}
                onClick={()=>handleClickMenuItem(item)}
                selected = {selectedOption === value}
                style={{gap: '10px'}}
              >
                {item?.startIcon && (
                  <ListItemIcon style={{minWidth: 'auto'}}>
                    {item?.startIcon}
                  </ListItemIcon>
                )}
                <Typography>{item.title}</Typography>
                {item?.endIcon && (
                  <ListItemIcon style={{minWidth: 'auto'}}>
                    {item?.endIcon}
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </>
  )
}