import { Step, StepButton, Stepper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { isMSME } from "Routes/Routes";
import instance from "api/globalaxios";
import Axios from "axios";
import ClaimantLawyerPage from "features/TempMSMETiacFormV1/ClaimantLawyerPage";
import ClaimantPage from "features/TempMSMETiacFormV1/ClaimantPage";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { StyledTextFieldTiac } from "../ClaimantPage/questions";
import SetPassword from "./SetPassword";

export const stepsFiledByClaimant = [
  "Claimant",
  "Claimant's Counsel",
  "Respondent",
  "Upload",
];

export const stepsFiledByClaimantCounsel = [
  "Claimant's Counsel",
  "Claimant",
  "Respondent",
  "Upload",
];

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      // height: "90%",
      order: 1,
      marginBottom: "2rem",
      align: "center",
    },
    [theme.breakpoints.down("xs")]: {
      // width: "90%",
      // height: "90%",
      order: 1,
      marginBottom: "2rem",
      align: "center",
    },
  },
  text: {
    fontSize: "18px",
    // fontWeight: "bold",
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  dropdown: {
    color: "white",
    padding: "16px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  heading: {
    textAlign: "left",
    color: "#454545",
    fontFamily: `'Inter',sans-serif`,
    fontSize: "36px",
    // fontWeight: "bold",
    lineHeight: "1.2",
    letterSpacing: "-0.00833em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
  },
}));

export function getProfiles(email, cancelToken) {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        `/api/tiacanonymousemailautofill/?email=${email}`,
        { cancelToken: cancelToken.token }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

export async function getDisputeTiac(email, dispute_id) {
  return await instance.get(
    `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
  );
}

const Schema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
});

export default function Form() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const [proceed, setProceed] = React.useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingEmailData, setLoadingEmailData] = useState(false);
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();
  const [
    checkSaveMyProgressAndResumeLater,
    setcheckSaveMyProgressAndResumeLater,
  ] = useState(false);
  const [showSaveCheckBox, setshowSaveCheckBox] = useState(true);
  const [isAfterScreenVisible, setIsAfterScreenVisible] = useState(false);
  const [profileTypeError, setProfileTypeError] = useState();

  useEffect(() => {
    if (fetchedProfile) {
      setshowSaveCheckBox(false);
    } else {
      setshowSaveCheckBox(true);
    }
  }, [fetchedProfile]);

  const handleOnClickCheckBox = () => {
    setcheckSaveMyProgressAndResumeLater(!checkSaveMyProgressAndResumeLater);
  };

  const autofill = (fetchedProfile) => {
    if (fetchedProfile.profile_type == "general_rv") {
      formik.setFieldValue("choice", "claimant");
    } else if (fetchedProfile.profile_type == "lawyer_rv") {
      formik.setFieldValue("choice", "claimant_lawyer");
    } else {
      formik.setFieldValue("choice", "");
      console.log(fetchedProfile.profile_type);
      let profile = fetchedProfile.profile_type;
      if (profile == "general_rv") {
        profile = "party";
      } else if (profile == "lawyer_rv") {
        profile = "counsel";
      } else if (profile == "arbitrator_rv") {
        profile = "arbitrator";
      } else if (profile == "case_manager") {
        profile = "case manager";
      }
      setProfileTypeError(
        "An account with this email exists in our database as a " +
          profile +
          " profile."
      );
    }
  };

  useEffect(() => {
    if (fetchedProfile) autofill(fetchedProfile);
  }, [fetchedProfile]);

  const formik = useFormik({
    initialValues: {
      email: "",
      choice: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      localStorage.setItem("email", values.email);
      setProceed(true);
      setcheckSaveMyProgressAndResumeLater(false);
    },
  });

  const getAutofillProfiles = (email) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      setProfileTypeError();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email
        ) {
          setFetchedProfile(res.results[0]);
        }
        setLoadingEmailData(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = useCallback(debounce(getAutofillProfiles, 500), []);

  useEffect(() => {
    localStorage.removeItem("dispute_id");
    localStorage.removeItem("email");
    localStorage.removeItem("arbi_step");
    localStorage.removeItem("activeStep");
    localStorage.removeItem("full_name");
    localStorage.removeItem("phone_number");
    setLoading(false);
  }, []);

  const steps =
    formik.values.choice == "claimant"
      ? stepsFiledByClaimant
      : stepsFiledByClaimantCounsel;
  const activeStep = 0;
  const [submitDetailsSignal, setSubmitDetailsSignal] = useState(false);
  const [
    afterPasswordSubmitDetailsSignal,
    setAfterPasswordSubmitDetailsSignal,
  ] = useState(false);

  const handleStepClick = (index) => {
    setLoading(true);
    localStorage.setItem("activeStep", index);
    setSubmitDetailsSignal(true);
  };

  if (!isMSME) {
    history.push("/");
  }

  useEffect(() => {
    if (isAfterScreenVisible) {
      history.push("/afterpassword");
    }
  }, [isAfterScreenVisible]);

  if (proceed) {
    return (
      <>
        <Grid container direction="row">
          <Grid item sm={3} xs={12}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              nonLinear
              style={{ background: "none", padding: "50px 15px" }}
            >
              {steps.map((label, index) => (
                <Step key={label} completed={false}>
                  <StepButton
                    color="inherit"
                    onClick={() => {
                      handleStepClick(index);
                    }}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {loading && <CircularCenteredLoader />}
          </Grid>

          <Grid item sm={9} xs={12}>
            {formik.values.choice == "claimant" ? (
              <ClaimantPage
                proceed={proceed}
                setProceed={setProceed}
                checkSaveMyProgressAndResumeLater={
                  checkSaveMyProgressAndResumeLater
                }
                setcheckSaveMyProgressAndResumeLater={
                  setcheckSaveMyProgressAndResumeLater
                }
                showSaveCheckBox={showSaveCheckBox}
                submitDetailsSignal={submitDetailsSignal}
                handleOnClickCheckBox={handleOnClickCheckBox}
                afterPasswordSubmitDetailsSignal={
                  afterPasswordSubmitDetailsSignal
                }
                setIsAfterScreenVisible={setIsAfterScreenVisible}
              />
            ) : (
              <ClaimantLawyerPage
                proceed={proceed}
                checkSaveMyProgressAndResumeLater={
                  checkSaveMyProgressAndResumeLater
                }
                setcheckSaveMyProgressAndResumeLater={
                  setcheckSaveMyProgressAndResumeLater
                }
                showSaveCheckBox={showSaveCheckBox}
                setProceed={setProceed}
                submitDetailsSignal={submitDetailsSignal}
                handleOnClickCheckBox={handleOnClickCheckBox}
                afterPasswordSubmitDetailsSignal={
                  afterPasswordSubmitDetailsSignal
                }
                setIsAfterScreenVisible={setIsAfterScreenVisible}
              />
            )}
            <SetPassword
              setAfterPasswordSubmitDetailsSignal={
                setAfterPasswordSubmitDetailsSignal
              }
              profileType={
                formik.values.choice == "claimant" ? "general_rv" : "lawyer_rv"
              }
              email={formik.values.email}
              checkSaveMyProgressAndResumeLater={
                checkSaveMyProgressAndResumeLater
              }
            />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Box p="2rem 0 7rem 0">
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs></Grid>
          <Grid item md={8} sm={10} xs={10}>
            <Paper className={classes.formContainer}>
              <Typography className={classes.heading} gutterBottom>
                <FormattedMessage id="pages.form.heading_en" />
              </Typography>
              <Box height="1rem"></Box>
              <Box>
                {/* <Typography className={classes.text}>
                  <FormattedMessage id="pages.form.subheading" />
                </Typography> */}

                <form onSubmit={formik.handleSubmit}>
                  <Box mt="2rem">
                    <StyledTextFieldTiac
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label={"Email address"}
                      required
                      id="email"
                      name="email"
                      value={formik.values.email}
                      autoFocus
                      autoComplete="email"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        setLoadingEmailData(true);
                        handleEmailChange(e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <Alert severity="error">{formik.errors.email}</Alert>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ marginTop: "2rem" }}
                  >
                    <Box style={{ width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          You are *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          required
                          name="choice"
                          id="choice"
                          value={formik.values.choice}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={fetchedProfile ? true : false}
                        >
                          <MenuItem value={"claimant"}>
                            <Typography className={classes.text}>
                              Claimant
                            </Typography>
                          </MenuItem>
                          <MenuItem value={"claimant_lawyer"}>
                            <Typography className={classes.text}>
                              Claimant's Counsel
                            </Typography>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box height="0.5rem" />
                  {profileTypeError && (
                    <Alert severity="error">{profileTypeError}</Alert>
                  )}
                  <Box height="2rem"></Box>
                  <Grid container justify="center">
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ background: "#2F80ED" }}
                        disabled={
                          loadingEmailData || profileTypeError ? true : false
                        }
                      >
                        <Typography className={classes.btnTextWhite}>
                          <FormattedMessage id="buttons.proceed" />
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs />
        </Grid>
      </Box>
    );
  }
}
