import { isHotStar, isMuthoot, isStar } from "Routes/Routes";
import React from "react";
import NewArbitratorSingleDisputeHotstar from "./ArbitratorHotstar/NewArbitratorSingleDispute";
import ArbitratorSingleDispute from "./ArbitratorSingleDispute";
import DisputeDetailsMuthoot from "features/disputes/MuthootDisputes/DisputeDetailsMuthoot";

export default function ArbitratorContianer({ activeDispute, profile, activeCase }) {
  if (isHotStar || isStar) {
    return (
      <NewArbitratorSingleDisputeHotstar
        activeDispute={activeDispute}
        profile={profile}
      />
    );
  } else if(isMuthoot) {
    return (
      <DisputeDetailsMuthoot
        activeDispute={activeDispute} 
        activeCase={activeCase}
        profile={profile} 
      />
    )
  }
  return (
    <ArbitratorSingleDispute activeDispute={activeDispute} profile={profile} />
  );
}
