import { Box, Typography } from "@material-ui/core";
import { format, isSameDay, subDays } from "date-fns";
import RecievedMessage from "features/Chat/RecievedMessage";
import SentMessage from "features/Chat/SentMessage";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRocketChatUserFullName } from "slices/chatGroupsSlice";
import { replaceUserNameWithName } from "utils/chatUtils";

const getChatTime = (timestamp) => {
  return format(timestamp, "h:mm aaaa");
};

export default function Message({
  message: {
    msg: content,
    u: { username: from, _id: id, name },
    file,
    attachments,
    ts,
    t,
    mentions,
  },
  domId,
}) {
  const userId = useSelector((state) => state.login.chatId);
  // const [username, setUsername] = useState("User");

  // useEffect(() => {
  //   if (t) {
  //     getRocketChatUserFullName(content).then((res) => {
  //       if (res?.data?.name) setUsername(res.data.name);
  //     }).catch((error)=>{
  //       console.log(error.response.data)
  //       setUsername(content)
  //     });
  //   }
  // }, [t, content]);

  // const arr = msg.split("_");
  // const content =
  //   t && arr.length >= 3 && msg.includes("-") ? arr[0] + " " + arr[1] : msg;

  const message = replaceUserNameWithName(content,mentions);

  const parsedTimestamp = getChatTime(ts.$date);
  return (
    <Box style={{ color: "#1E1E1E" }}>
      {t ? (
        <></>
        // <Box display="flex" justifyContent="center">
        //   <Box
        //     textAlign="center"
        //     display="inline-block"
        //     style={{
        //       borderRadius: "0.5rem",
        //       background: "rgba(112, 124, 151, 0.1)",
        //       padding: "0.4rem",
        //       margin: "1rem 0",
        //     }}
        //   >
        //     <Typography variant="subtitle1">
        //       {username} {t === "au" ? "added" : "removed"}
        //     </Typography>
        //   </Box>
        // </Box>
      ) : userId === id ? (
        <SentMessage
          content={message}
          domId={domId}
          file={attachments?.[0]}
          attachments={attachments}
          parsedTimestamp={parsedTimestamp}
          from={from}
        />
      ) : (
        <RecievedMessage
          content={message}
          from={from}
          domId={domId}
          file={attachments?.[0]}
          attachments={attachments}
          parsedTimestamp={parsedTimestamp}
          name={name}
        />
      )}
    </Box>
  );
}
