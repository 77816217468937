import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import instance from "api/globalaxios";
import { useDispatch } from "react-redux";
import { formatDate } from "utils";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import CaseDisplay from "./CaseDisplay";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  overview: {
    fontSize: "24px",
    lineHeight: "28px",
  },
  box: { textAlign: "left", margin: "1rem 0 0 0" },
  paper: {
    padding: "1.1rem 1rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "0.8rem 0.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 0",
    },
  },
  paperselected: {
    padding: "1.1rem 1rem",
    cursor: "pointer",
    background: "#c6c8cb",
    [theme.breakpoints.down("sm")]: {
      padding: "0.8rem 0.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 0",
    },
  },
  casesHeading: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default function Overview(isSmallScreen) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  let o = sessionStorage.getItem("cases");
  let c = sessionStorage.getItem("casesid");
  const [open, setOpen] = useState(o && o == "open" ? true : false);
  const [close, setClose] = useState(o && o == "closed" ? true : false);
  const [openCase, setOpenCase] = useState(c ? true : false);
  const [caseId, setCaseId] = useState(c ? c : null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, setTotalpages] = useState();

  function apiFunction(type, pageNo) {
    return async () => {
      try {
        const response = await instance.get(
          `/api/rvsubadmin/?type=${type}&page=${pageNo}`
        );
        setdata(response.data.results);
        let count = response.count;
        let t = parseInt(count / 100);
        let r = count % 100;
        let total = t + (r > 0 ? 1 : 0);
        if (isNaN(total)) {
          setTotalpages(1);
        } else {
          setTotalpages(total);
        }
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const getOpenDisputes = () => {
    setLoading(true);
    dispatch(apiFunction("open", 1));
  };

  const getCloseDisputes = () => {
    setLoading(true);
    dispatch(apiFunction("close", 1));
  };

  React.useEffect(() => {
    if (open) {
      getOpenDisputes();
    } else if (close) {
      getCloseDisputes();
    }
  }, [open, close]);

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    if (openCase) {
      return (
        <CaseDisplay
          setOpenCase={setOpenCase}
          isSmallScreen={isSmallScreen}
          caseId={caseId}
          mode={"RV"}
        />
      );
    }

    return (
      <>
        <Grid container justify="center">
          <Grid item md={10} sm={11} xs={11}>
            <Box className={classes.box}>
              <Typography className={classes.overview}>Overview</Typography>
            </Box>
            <Grid
              container
              justify="flex-start"
              style={{ margin: "1rem 0 0 0" }}
            >
              <Grid item md={3} sm={3} xs={5}>
                <Paper
                  className={
                    o && o == "open" ? classes.paperselected : classes.paper
                  }
                  onClick={() => {
                    setOpen(true);
                    setClose(false);
                    getOpenDisputes();
                    sessionStorage.setItem("cases", "open");
                  }}
                >
                  <Typography className={classes.casesHeading}>
                    Open cases
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1} sm={1} xs={1}></Grid>{" "}
              <Grid item md={3} sm={3} xs={5}>
                <Paper
                  className={
                    o && o == "closed" ? classes.paperselected : classes.paper
                  }
                  onClick={(e) => {
                    setClose(true);
                    setOpen(false);
                    getCloseDisputes();
                    sessionStorage.setItem("cases", "closed");
                  }}
                >
                  <Typography className={classes.casesHeading}>
                    Closed cases
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justify="center">
          {open && (
            <Grid item sm={10} xs={11}>
              <Box height="4rem"></Box>
              <Grid container justify="flex-start">
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    CASE ID
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    CLAIMANT
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    RESPONDENT
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    START DATE
                  </Typography>
                </Grid>
              </Grid>
              <Box height="0.4rem"></Box>
              {data.map((dispute) => {
                const arr = dispute.name.split("vs");
                const complainant = arr[0];
                const respondent = arr[1];
                return (
                  <>
                    <Paper
                      style={{ padding: "10px 0", cursor: "pointer" }}
                      onClick={() => {
                        setOpenCase(true);
                        setCaseId(dispute.id);
                        sessionStorage.setItem("casesid", dispute.id);
                      }}
                    >
                      <Grid container justify="flex-start">
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {dispute.id}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {complainant}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {respondent}
                          </Typography>
                        </Grid>

                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {formatDate(dispute.created)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Box height="0.6rem"></Box>
                  </>
                );
              })}
              <Grid container>
                <Grid item>
                  <IconButton
                    disabled={currentPage == 1}
                    onClick={(e) => {
                      setCurrentPage(currentPage - 1);
                      apiFunction("open", currentPage - 1);
                    }}
                  >
                    <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                  </IconButton>
                  <IconButton
                    disabled={currentPage >= totalpages}
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                      apiFunction("open", currentPage + 1);
                    }}
                  >
                    <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                  </IconButton>
                </Grid>
              </Grid>
              <Box height="5rem" />
            </Grid>
          )}
        </Grid>
        <Grid container justify="center">
          {close && (
            <Grid item sm={10} xs={11}>
              <Box height="4rem"></Box>
              <Grid container justify="flex-start">
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    CASE ID
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    CLAIMANT
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    RESPONDENT
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    START DATE
                  </Typography>
                </Grid>
              </Grid>
              <Box height="0.4rem"></Box>
              {data.map((dispute) => {
                const arr = dispute.name.split("vs");
                const complainant = arr[0];
                const respondent = arr[1];
                return (
                  <>
                    <Paper
                      style={{ padding: "10px 0", cursor: "pointer" }}
                      onClick={(e) => {
                        setOpenCase(true);
                        setCaseId(dispute.id);
                        sessionStorage.setItem("casesid", dispute.id);
                      }}
                    >
                      <Grid container justify="center">
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {dispute.id}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {complainant}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {respondent}
                          </Typography>
                        </Grid>

                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.blueheadingtext}>
                            {formatDate(dispute.created)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Box height="0.6rem"></Box>
                  </>
                );
              })}
              <Grid container>
                <Grid item>
                  <IconButton
                    disabled={currentPage == 1}
                    onClick={(e) => {
                      setCurrentPage(currentPage - 1);
                      apiFunction("close", currentPage - 1);
                    }}
                  >
                    <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                  </IconButton>
                  <IconButton
                    disabled={currentPage >= totalpages}
                    onClick={(e) => {
                      setCurrentPage(currentPage + 1);
                      apiFunction("close", currentPage + 1);
                    }}
                  >
                    <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                  </IconButton>
                </Grid>
              </Grid>
              <Box height="5rem" />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}
