import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDispute } from "slices/disputeSlice";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { tiacdoc } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  btntext: {
    color: "#2F80ED",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    textTransform: "none",
  },
  paper: {
    padding: "2rem",
    textAlign: "center",
  },
  generate: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    lineHeight: "36px",
    color: "#4F4F4F",
  },
}));

export default function FileContainer() {
  let { disputeId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.profile, shallowEqual);

  const [dispute, setDispute] = useState();
  const [loading, setLoading] = useState(true);
  const [choice, setChoice] = useState();

  useEffect(() => {
    dispatch(getDispute(disputeId)).then((res) => {
      setDispute(res.results[0]);
      setLoading(false);
    });
  }, []);

  if (loading) return <CircularCenteredLoader />;
  return (
    <Grid container justify="center">
      <Grid item lg={10} md={10} sm={11} xs={11} className={classes.root}>
        <Grid container spacing={1}>
          <Grid item>
            <KeyboardArrowLeftIcon
              style={{ color: "#2F80ED", marginTop: "4px" }}
            />
          </Grid>
          <Grid item>
            <Typography
              className={classes.btntext}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/");
              }}
            >
              Dashboard
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.btntext}>|</Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classes.btntext}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (
                  profile.profile.profile_type == "sub_admin_tiac_rv" ||
                  profile.profile.profile_type == "case_manager"
                ) {
                  sessionStorage.setItem("tiac_overview_caseid", dispute.id);
                  history.push(`/`);
                } else history.push(`/disputes/${dispute.id}/`);
              }}
            >
              {dispute.name}
            </Typography>
          </Grid>
        </Grid>
        <Box height="3rem" />
        <Grid container justify="center">
          <Grid item sm={8} xs={10}>
            <Paper className={classes.paper}>
              <Typography className={classes.generate}>
                Generate a document
              </Typography>
              <Grid container justify="center">
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-2simple-select-label">
                      Generate
                    </InputLabel>
                    <Select
                      labelId="demo-2simple-select-label"
                      id="demo-2simple-select"
                      value={choice}
                      // label=""
                      onChange={(e) => setChoice(e.target.value)}
                    >
                      {/* <MenuItem value={"Procedural issue"}>Terms of Reference </MenuItem>
                      <MenuItem value={"Procedural issue"}>
                      Procedural issue
                      </MenuItem> */}
                      <MenuItem
                        value={"Terms of Reference and Procedural Order"}
                        onClick={() => {
                          tiacdoc(
                            dispute.id,
                            dispute.tiac_custom_dispute_id
                            // activeDispute.id,
                            // activeDispute.tiac_custom_dispute_id
                          );
                        }}
                      >
                        Terms of Reference and Procedural Order{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box height="5rem" />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
