import { Box, Button, Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { isDigipub, isHotStar, isMuthoot, isNbf, isRv, isStar, isViacom, isWebnyay } from "Routes/Routes";
import { backgrounds } from "contexts/BackgroundContext";
import { addHours, addMinutes, format, isAfter, isBefore } from "date-fns";
import Calender from "features/common/Calendar";
import CircularLoader from "features/common/CircularCenteredLoader";
import Modal from "features/common/Modal";
import EventDisplay from "features/globalCalendar/NewEventDisplay";
import { useBodyBackgroundChange } from "hooks";
import querystring from "query-string";
import React from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getAllMeetingsOfUser,
  getAllMeetingsOfUserDpcgcAdmin,
  getAllMeetingsOfUserHotstarAdmin,
} from "slices/appointmentSlice";
import {
  getDisputes,
  getDisputesDpcgcAdmin,
  getDisputesHotstarAdmin,
} from "slices/disputeSlice";

const parseDate = (date) => {
  return new Date(date);
};

const selectAllow = (eventInfo) => {
  let currentDate = new Date();
  if (isAfter(eventInfo.start, currentDate)) {
    return isAfter(eventInfo.start, currentDate);
  } else {
    return false;
  }
  // return true;
};

const transformFunction = (eventData) => {
  let {
    meeting: { start, end, name, dispute, id: meetingId },
    id: appointmentId,
    name: appointmentName,
  } = eventData;

  let isPastEvent = isBefore(parseDate(start), new Date());

  if (isWebnyay || isHotStar || isStar || isMuthoot || isNbf || isViacom || isDigipub) {
    name = "Grievance Hearing";
  } else if (isRv) {
    name = "Case Hearing";
  }

  let title = isPastEvent ? `${name} (past event)` : `${name}`;

  return {
    title,
    start: parseDate(start),
    end: parseDate(end),
    display: isPastEvent ? "block" : "auto",
    meetingStartAt: format(new Date(start), "d MMMM y, h:mm a"),
    isPastEvent,
    dispute,
    meetingId,
    appointmentId,
    appointmentName,
    backgroundColor: isPastEvent ? "grey" : "#3788d8",
    borderColor: isPastEvent ? "grey" : "#3788d8",
  };
};

export default function ViewCalenderContainer() {
  const location = useLocation();
  const history = useHistory();
  const arr = location.pathname.split("/");
  const userId = arr[2];
  let company = sessionStorage.getItem("company");

  useBodyBackgroundChange(backgrounds.WHITE);

  const { disputeId, group: groupName } = querystring.parse(location.search);
  const routeState = location.state;
  const [addEventOpen, setOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [eventInfo, setEventInfo] = React.useState({});
  const [eventDetails, setEventDetails] = React.useState({});
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const dispatch = useDispatch();

  const handleEventDetailsClose = () => {
    setDetailsOpen(false);
  };

  const handleAddEventClose = () => {
    setOpen(false);
  };

  const onSelect = (info) => {
    setEventInfo(info);
    setOpen(true);
  };

  const handleAddEventClick = () => {
    const currentDate = new Date();

    setEventInfo({
      start: addMinutes(currentDate, 5),
      end: addHours(currentDate, 1),
    });

    setOpen(true);
  };

  const onEventClick = (info) => {
    setEventDetails(info);
    setDetailsOpen(true);
  };

  React.useEffect(() => {
    if (company == "hotstar") {
      dispatch(getAllMeetingsOfUserHotstarAdmin(userId));
      dispatch(getDisputesHotstarAdmin());
    } else if (company == "dpcgc") {
      dispatch(getAllMeetingsOfUserDpcgcAdmin(userId));
      dispatch(getDisputesDpcgcAdmin());
    } else {
      dispatch(getAllMeetingsOfUser(userId));
      dispatch(getDisputes());
    }
    // dispatch(getAllMeetingsOfUser(userId));
    //   dispatch(getDisputes());
  }, []);

  const {
    appointments: { allUserMeetings, loading: appointmentLoading },
    disputes: { disputes, loading: disputeLoading },
    groups: { groups },
  } = useSelector((state) => state, shallowEqual);

  let sources = [{ events: allUserMeetings }];

  let loading = disputeLoading || appointmentLoading;

  const childProps = {
    transformFunction,
    sources,
    selectable: true,
    onSelect,
    onEventClick,
    selectAllow,
    handleAddEventClick,
  };

  if (loading) return <CircularLoader />;

  return (
    <React.Fragment>
      <Box height="0.8rem"></Box>
      <Grid container justify="left">
        <Grid sm={7} xs={7} item>
          <Grid container justify="flex-start">
            <Grid item style={{ padding: "0 20px" }}>
              <Button
                onClick={() => {
                  // history.push("/disputes");
                  history.goBack();
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Calender {...childProps} />
      {/* {
        profileType === "secretariat" &&
        <Modal open={addEventOpen} handleClose={handleAddEventClose}>
        <AddEventForm
          groups={groups}
          eventInfo={eventInfo}
          disputeId={disputeId}
          handleAddEventClose={handleAddEventClose}
          disputes={disputes}
          groupName={groupName}
          routeState={routeState}
          sources={sources}
        />
      </Modal>
      } */}

      <Modal open={detailsOpen} handleClose={handleEventDetailsClose}>
        <EventDisplay
          eventDetails={eventDetails}
          handleEventDetailsClose={handleEventDetailsClose}
          profileType={profileType}
          sources={sources}
        />
      </Modal>
    </React.Fragment>
  );
}
