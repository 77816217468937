import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import { blockInvalidChar } from "features/TempTiacFormV2/ClaimantPage/questions";
import React, { useState } from "react";
import { getRealhost } from "slices/disputeSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1.5rem 2rem",
    borderRadius: "8px",
    cursor: "pointer",
  },
  paper2: {
    padding: "1.5rem 2rem",
    borderRadius: "8px",
  },
  headingNew: {
    fontSize: "16px",
    lineHeight: "36px",
    display: "flex",
    alignItems: "center",
    texttransform: "capitalize",
    color: "#4F4F4F",
  },
  bigbtn: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#FFFFFF",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

export default function CreateNewLogin({ profiles, opened, profile }) {
  const classes = useStyles();

  const [expand, setExpand] = useState(opened);
  const [close, setClose] = useState(false);
  const [openSnackBar1, setOpenSnackBar1] = React.useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const handleCloseSnackBar2 = () => {
    setOpenSnackBar2(false);
  };
  const [openSnackBar3, setOpenSnackBar3] = useState(false);
  const handleCloseSnackBar3 = () => {
    setOpenSnackBar3(false);
  };
  const [openSnackBar4, setOpenSnackBar4] = useState(false);
  const handleCloseSnackBar4 = () => {
    setOpenSnackBar4(false);
  };

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [profileType, setProfileType] = useState("");

  const onFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onProfileChange = (e) => {
    setProfileType(e.target.value);
  };
  const onSubmit = () => {
    if (
      !firstname ||
      !lastname ||
      !email ||
      !profileType ||
      firstname.trim() == "" ||
      lastname.trim() == ""
    ) {
      setOpenSnackBar3(true);
      // alert("enter details")
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        setOpenSnackBar4(true);
        // alert("enter email correct")
      } else {
        createGuestSignUp2(profileType)
          .then(() => {
            setClose(true);
            setOpenSnackBar2(true);
          })
          .catch((error) => {
            console.log("error", error);
            if (
              error?.response?.data?.user &&
              error?.response?.data?.user?.email
            ) {
              alert(error.response.data.user.email[0]);
            }
            if (error?.response?.data?.phone_number) {
              alert(error.response.data.phone_number[0]);
            }
          });
      }
    }
  };

  async function createGuestSignUp2(profile_type) {
    let data = {
      profile_type: profile_type,
      group: profile.group,
      user: {
        email: email,
        password: "Test@123",
        first_name: firstname,
        last_name: lastname,
      },
    };
    if (phone.trim() != "") {
      data["phone_number"] = phone;
    }
    return await instance.post("/customsignup/?host=" + getRealhost(), data, {
      headers: {
        "content-type": "application/json",
      },
    });
  }

  return (
    <>
      {!close && (
        <>
          <Grid container justify="center" direction="row">
            <Grid item md={7} sm={9} xs={11}>
              <Paper
                className={expand ? classes.paper2 : classes.paper}
                onClick={() => {
                  setExpand(true);
                }}
              >
                <Grid container justify="flex-start">
                  <Grid item>
                    <Typography className={classes.headingNew}>
                      + Create login details
                    </Typography>
                  </Grid>
                </Grid>
                {expand && (
                  <>
                    <Box height="1rem"></Box>
                    <Grid container justify="space-evenly" spacing={3}>
                      <Grid item sm={5} xs={12}>
                        <TextField
                          required
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          variant="outlined"
                          fullWidth={true}
                          value={firstname}
                          onChange={onFirstNameChange}
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <TextField
                          required
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          variant="outlined"
                          fullWidth={true}
                          value={lastname}
                          onChange={onLastNameChange}
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <TextField
                          id="phone"
                          name="phone"
                          label="Phone Number"
                          variant="outlined"
                          type="number"
                          fullWidth={true}
                          value={phone}
                          onKeyDown={blockInvalidChar}
                          onChange={onPhoneChange}
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <TextField
                          required
                          id="email"
                          name="email"
                          label="Email ID"
                          variant="outlined"
                          fullWidth={true}
                          value={email}
                          onChange={onEmailChange}
                        />
                      </Grid>

                      <Grid item sm={5} xs={12}>
                        <FormControl
                          required
                          fullWidth={true}
                          variant="outlined"
                          disabled={profiles.length === 0}
                        >
                          <InputLabel id="against">Profile type</InputLabel>
                          <Select
                            id="profile_type"
                            name="profile_type"
                            value={profileType}
                            onChange={onProfileChange}
                            label="Profile type"
                          >
                            {profiles.map((profile) => (
                              <MenuItem key={profile.type} value={profile.type}>
                                {profile.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box height="2rem"></Box>
                    <Button
                      variant="contained"
                      // color="primary"
                      style={{ background: "#2F80ED" }}
                      size="large"
                      className={classes.bigbtn}
                      onClick={onSubmit}
                    >
                      Create
                    </Button>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Box height="1rem"></Box>
        </>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Problem in creating guest profile."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Problem in creating guest profile.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar2}
        onClose={handleCloseSnackBar2}
        message="Guest profile is created."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Profile is created.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar3}
        onClose={handleCloseSnackBar3}
        message="Please enter all the mandatory details."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="warning">
          Please enter all the mandatory details.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar4}
        onClose={handleCloseSnackBar4}
        message="Please enter valid email address."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter valid email address.
        </Alert>
      </Snackbar>
      {expand && (
        <CreateNewLogin profiles={profiles} opened={false} profile={profile} />
      )}
    </>
  );
}
