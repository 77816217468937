import { Backdrop, Divider, Fade } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseModalButton from "Admin/Common/CloseModalButton";
import React, { useState } from "react";
import EditProfileRow from "./EditProfileRow";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      padding: theme.spacing(3, 4, 3),
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      padding: theme.spacing(3, 4, 3),
    },
  },
  editprofile: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
}));

export default function EditProfile({
  openmodal,
  handleCloseModal,
  profileData,
  profileType,
  dispute_edit,
  refresh,
}) {
  const classes = useStyles();

  const isMultiple = profileData.length ? true : false;

  const body = (
    <div className={classes.paper}>
      <CloseModalButton onClick={handleCloseModal} />
      <Typography className={classes.editprofile}>Edit Profile</Typography>
      <Box height="1.5rem"></Box>
      {isMultiple ? (
        profileData.map((profile) => {
          if (
            profile.profile ? profile.profile.profile_type == profileType : true
          )
            return (
              <>
                <EditProfileRow
                  profileData={profile}
                  dispute_edit={dispute_edit}
                  refresh={refresh}
                />
                <Box height="1rem"></Box>
                <Divider />
                <Box height="1rem"></Box>
              </>
            );
        })
      ) : (
        <>
          <EditProfileRow
            profileData={profileData}
            dispute_edit={dispute_edit}
            refresh={refresh}
          />
          <Box height="1rem"></Box>
          <Divider />
          <Box height="1rem"></Box>
        </>
      )}
    </div>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
