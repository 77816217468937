import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Popup from "reactjs-popup";

export default function POPUP({
  opener,
  onClose,
  heading,
  message,
  mainButton,
  onMainButton,
}) {
  return (
    <>
      <Popup
        open={opener}
        closeOnDocumentClick
        closeOnEscape
        onClose={onClose}
        position="center center"
        modal
        nested
        contentStyle={{
          background: "white",
          width: "358px",
        }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.45)" }}
      >
        <Box style={{ padding: "1.3rem 2rem" }}>
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "20px",
              color: "#333333",
              "font-weight": "500",
              "line-height": "24.2px",
              "letter-spacing": "0.15%",
            }}
          >
            {heading}
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              color: "#828282",
              "font-weight": "400",
              "line-height": "28px",
            }}
          >
            {message}
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1.3rem 2rem" }}>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={onClose}
              >
                CANCEL
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={onMainButton}
              >
                {mainButton}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </>
  );
}
