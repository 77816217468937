import React from "react";
import Upload from "./Upload";
import UploadOtt from "./UploadOtt";
import UploadBarAndBench from "./UploadBnB";
import { useSelector } from "react-redux";

export default function UploadContainer({ profile }) {
  const activeCase = useSelector((state) => state.cases.activeCase);
  const activeDispute = useSelector((state) => state.disputes.activeDispute);
  // const profile = useSelector((state) => state.profile.profile);

  // if(activeDispute.name.split(" ")[3] == "Shemaroo" && activeCase.type == "claimant"){
  // }

  if (
    activeDispute.name.split(" ")[activeDispute.name.split(" ").length - 1] ==
      "Shemaroo" &&
    activeCase.type == "claimant"
  ) {
    return (
      <UploadOtt
        activeCase={activeCase}
        activeDispute={activeDispute}
        profile={profile}
      />
    );
  } else if (
    activeDispute.name.split(" ")[activeDispute.name.split(" ").length - 1] ==
      "Bench" &&
    activeCase.type == "claimant"
  ) {
    return (
      <UploadBarAndBench
        activeCase={activeCase}
        activeDispute={activeDispute}
        profile={profile}
      />
    );
  } else {
    return (
      <>
        <Upload
          activeCase={activeCase}
          activeDispute={activeDispute}
          profile={profile}
        />
      </>
    );
  }
}
