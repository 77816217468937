import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormattedMessage from "features/common/TranslatedMessage";
import blackpenSvg from "images/blackpen.svg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDispute } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    padding: "0 15px",
    textAlign: "center",
    background: "#D7E1EE",
    borderRadius: "4px",
  },
  item: {
    marginTop: theme.spacing(2),
    flex: "1",
    // width: "600px",
    borderRadius: "0.3rem",
    resize: "none",
    padding: theme.spacing(1),
    fontSize: "16px",
  },
  text: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
}));

export default function Summary({ summary, caseId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [summaryText, setSummaryText] = useState(summary);

  const handleChange = (e) => {
    setSummaryText(e.target.value);
  };

  const onSubmit = () => {
    dispatch(
      updateDispute({
        id: caseId,
        summery: summaryText,
      })
    );
    setEdit(false);
  };

  const element = (
    <>
      {" "}
      <TextareaAutosize
        data-testid={`1234213421343421`}
        data-question-type="textarea"
        required
        label="Required"
        onChange={handleChange}
        className={classes.item}
        value={summaryText}
        rowsMin={1}
        rowsMax={4}
        maxLength={2000}
      />
    </>
  );

  return (
    <Paper className={classes.paper}>
      <Box height="15px"></Box>
      <Grid container justify="space-between">
        <Grid item style={{ textAlign: "left" }}>
          <Typography className={classes.text}>Summary (300 words)</Typography>
        </Grid>
        {!edit && (
          <Grid item>
            <img
              src={blackpenSvg}
              alt="pen image"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setEdit(true);
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container>
        {!edit ? (
          <Grid item style={{ textAlign: "left" }}>
            <Typography className={classes.text}>{summaryText}</Typography>
          </Grid>
        ) : (
          <Grid item md={12} sm={12} xs={12}>
            <Box display="flex" justifyContent="start">
              {element}
            </Box>
            <Box height="1rem"></Box>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              <FormattedMessage id="buttons.save" />
            </Button>
          </Grid>
        )}
      </Grid>
      <Box height="15px"></Box>
    </Paper>
  );
}
