import {
  ARBITRATOR,
  ARBITRATOR_RV,
  MEDIATOR,
  SECRETARIAT,
} from "app/constants";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import React, { Fragment, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { isDev } from "Routes/Routes";
import { getDisputesForPagination } from "slices/disputeSlice";
import DisputesList from "./DisputesList";

const getActiveAndResolved = (disputes) => {
  if (!disputes) {
    return 0;
  }
  let newDisputes = { active: [], resolved: [] };
  disputes.forEach((singleDispute) => {
    if (singleDispute.status === "complete") {
      newDisputes.resolved.push(singleDispute);
    } else {
      newDisputes.active.push(singleDispute);
    }
  });

  return newDisputes;
};

export default function DisputesListContainerPagination() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { disputesType } = useParams();
  const pathArray = pathname.split("/");
  const role = pathArray[pathArray.length - 2];

  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(true);

  const [cases, setCases] = useState();
  const [disputes, setDisputes] = useState();
  const [respondent, setRespondent] = useState();
  const [claimant, setClaimant] = useState();
  const [totalpages, setTotalpages] = useState();

  React.useEffect(() => {
    setLoading(true);
    dispatch(getDisputesForPagination(currentPage, disputesType, role)).then(
      (res) => {
        let count = res.count;
        let t = parseInt(count / 100);
        let r = count % 100;
        let total = t + (r > 0 ? 1 : 0);
        setTotalpages(total);
        setDisputes(res.results);
        setLoading(false);
      }
    );
    // dispatch(getCasesForPagination(currentPage)).then((response) => {
    //   setLoading(false);
    //   setCases(response.results);
    // });
  }, [disputesType]);

  const getUserDataAnotherPage = (pageNo) => {
    setLoading(true);
    dispatch(getDisputesForPagination(pageNo, disputesType, role)).then(
      (res) => {
        // let count = res.count;
        // let t = parseInt(count / 100);
        // let r = count % 100;
        // let total = t + (r > 0 ? 1 : 0);
        // setTotalpages(total);
        setDisputes(res.results);
        setLoading(false);
      }
    );
    // dispatch(getCasesForPagination(pageNo)).then((response) => {
    //   setCases(response.results);
    // });
  };

  // React.useEffect(() => {
  //   if (!isEmpty(cases) && !isEmpty(disputes)) {
  //     let { respondentDisputes, claimaintDisputes } = getDisputesCount(
  //       cases,
  //       disputes
  //     );
  //     setRespondent(respondentDisputes);
  //     setClaimant(claimaintDisputes);
  //     setLoading(false);
  //   }
  // }, [cases, disputes]);

  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === MEDIATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === SECRETARIAT;

  const formattedDisputes =
    role === ARBITRATOR ||
    role === MEDIATOR ||
    role === ARBITRATOR_RV ||
    role === SECRETARIAT
      ? getActiveAndResolved(disputes)
      : disputes;

  const location = useLocation();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);
  const [showGuide, setShowGuide] = useState(
    (location.pathname == "/disputes/claimant/active" ||
      location.pathname == "/disputes/claimant/resolved") &&
      (user.id == 473 || user.id == 214) &&
      !isDev
  );

  const exit = (t) => {
    setShowGuide(false);
  };

  const start = () => {};

  const steps = [
    {
      element: ".grievancesstep1",
      intro: "Click on the grievance you wish to view.",
    },
    // {
    //   element: ".grievancesstep2",
    //   intro: "View details of your grievance.",
    // },
    // {
    //   element: ".grievancesstep3",
    //   intro: "View details of your grievance.",
    // },
  ];

  if (loading) {
    return <CircularCenteredLoader />;
  }
  return (
    <Fragment>
      <Steps
        enabled={showGuide}
        steps={steps}
        initialStep={0}
        onExit={exit}
        onStart={start}
        options={{
          hideNext: false,
          hidePrev: true,
          keyboardNavigation: true,
          exitOnEsc: true,
          exitOnOverlayClick: false,
          showBullets: false,
          showStepNumbers: false,
          disableInteraction: true,
        }}
      />
      <DisputesList
        profileType={profileType}
        disputes={disputes}
        // disputes={
        //   role === RESPONDENT
        //     ? respondent
        //     : role === CLAIMANT
        //     ? claimant
        //     : formattedDisputes
        // }
        role={role}
        disputesType={disputesType}
        isProfessionalAccount={isProfessionalAccount}
        totalNoOfPages={totalpages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getUserDataAnotherPage={getUserDataAnotherPage}
        profile={profile.profile}
      />
    </Fragment>
  );
}
