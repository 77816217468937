import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import bluepenSvg from "images/bluepen.svg";
import React, { useState } from "react";
import Assign from "./Assign";
import CreateLoginDetails from "./CreateLoginDetails";
import EditProfile from "./EditProfile";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "35px",
    textAlign: "center",
    borderRadius: "4px",
    // width: "425px",
    // height: "360px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
    [theme.breakpoints.down("lg")]: {
      padding: "15px",
    },
  },
  headingblue: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  bluetext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#3F51B5",
    textDecorationLine: "underline",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  text: {
    // fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  space: {
    height: "1rem",
    [theme.breakpoints.down("lg")]: {
      height: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "3rem",
    },
  },
  space2: {
    height: "9.3rem",
    [theme.breakpoints.down("md")]: {
      height: "8rem",
    },
  },
}));

export default function SRB({
  groData: initialGroData,
  caseId,
  companyId,
  mode,
  lawyerDataRV,
  casefiles,
  index,
  refresh,
  allProfiles,
}) {
  const classes = useStyles();

  const groData = initialGroData;
  const [claimantlawyerdata, setClaimantLawyerData] = useState([]);

  React.useEffect(() => {
    lawyerDataRV.map((item) => {
      if (item.respondent_lawyer) {
      } else {
        setClaimantLawyerData(item.claimant_lawyer);
      }
    });
  }, []);

  const [openmodal, setOpenmodal] = useState(false);

  const handleOpenModal = () => {
    setOpenmodal(true);
  };

  const handleCloseModal = () => {
    setOpenmodal(false);
    refresh();
  };

  const [openmodal2, setOpenmodal2] = useState(false);

  const handleOpenModal2 = () => {
    setOpenmodal2(true);
  };

  const handleCloseModal2 = () => {
    setOpenmodal2(false);
  };

  const [openmodal3, setOpenmodal3] = useState(false);

  const handleOpenModal3 = () => {
    setOpenmodal3(true);
  };

  const handleCloseModal3 = () => {
    setOpenmodal3(false);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.headingblue}>
            {/* {" "}
            CLAIMANT LAWYER(S) */}
            {typeof index === "number"
              ? "CLAIMANT LAWYER " + (index + 1)
              : " CLAIMANT LAWYER(S)"}
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={bluepenSvg}
            alt="pen image"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              if (groData) handleOpenModal3();
              else alert("There are no profiles to edit");
            }}
          />
        </Grid>
      </Grid>
      <Box height="1rem"></Box>
      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            Assign
          </Typography>
        </Grid>
      </Grid>
      {typeof index === "number" &&
      claimantlawyerdata.includes(groData.profile.id) ? (
        // groData.map((item) => {

        // if (claimantlawyerdata.includes(item.profile.id))
        // return (
        <>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.blackheading}>
                {groData.user.first_name} {groData.user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Email: {groData.user.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Phone: {groData.profile.phone_number}{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Address:{" "}
                {groData.profile.address ? groData.profile.address : "--"}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.space}></Box>
        </>
      ) : (
        // );
        // })
        <>
          <Box className={classes.space2}></Box>
        </>
      )}
      {openmodal && (
        <Assign
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          caseId={caseId}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
          groData={groData}
          casefiles={casefiles}
          caseType_RV={"claimant"}
          allProfiles={allProfiles}
        />
      )}

      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal2}
          >
            + Create login details
          </Typography>
        </Grid>
      </Grid>
      {openmodal2 && (
        <CreateLoginDetails
          openmodal={openmodal2}
          handleCloseModal={handleCloseModal2}
          caseId={caseId}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
          companyId={companyId}
        />
      )}
      {openmodal3 && (
        <EditProfile
          openmodal={openmodal3}
          handleCloseModal={handleCloseModal3}
          profileData={groData}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
        />
      )}
    </Paper>
  );
}
