import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import IconTwo from "images/facebook.svg";
import IconThree from "images/linkedIn.svg";
import IconOne from "images/email.svg";
import IconFour from "images/twitter.svg";
import IconFive from "images/whatsapp.svg";

const data = [
  { icon: IconOne, to: "mailto:admin@webnyay.in" },
  { icon: IconTwo, to: "https://www.facebook.com/Webnyay/" },
  { icon: IconThree, to: "https://www.linkedin.com/company/webnyay/" },
  { icon: IconFour, to: "https://twitter.com/webnyay" },
  { icon: IconFive, to: "https://wa.me/919958867718" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#333",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  title: {
    flexGrow: 1,
    "& img": {
      width: "20rem",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "17rem",
      },
    },
  },
  headingBook: {
    color: "#f2f2f2",
    fontSize: "12px",
    fontWeight: "bold",
    textDecoration: "underline",
    marginTop: "15px",
  },
  boldText: {
    color: "#f2f2f2",
    fontSize: "12px",
    fontWeight: "bold",
  },
  text: {
    color: "#f2f2f2",
    fontSize: "10px",
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item sm={9} xs={10}>
        <Grid container spacing={4} style={{ marginTop: "30px" }}>
          <Grid item md={5} sm={12}>
            <div className={classes.title}>
              <Link to="/">
                <img src="/Logo_Colour.png" alt="" />
              </Link>
            </div>
            <Typography className={classes.boldText}>
              Have questions, write to us at admin@webnyay.in
            </Typography>
            <Box height="1rem" />
            <Grid container spacing={3}>
              {data.map((singleData, index) => {
                return (
                  <Grid item key={index}>
                    <a target="__blank" href={singleData.to}>
                      <img
                        width="26px"
                        src={singleData.icon}
                        alt="social_icons"
                      />
                    </a>
                  </Grid>
                );
              })}
            </Grid>
            <Box height="1rem" />
            <Hidden smDown>
              <Typography className={classes.text}>
                &copy; 2020 Copyright. all rights reserved.
              </Typography>
            </Hidden>
          </Grid>
          <Grid item md={7} sm={12}>
            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=qjfY1zkcekWz6XUWzxZ2X7QtQAXgGixEsmvvze2zb0ZUNTg4OUFOM0NOTDRPMlNYWjBLRzVJUU5KUC4u">
              <Typography className={classes.headingBook}>
                BOOK A DEMO WITH US
              </Typography>
            </a>
            <Box height="3rem" />
            <Grid container spacing={2}>
              <Grid item>
                <a
                  href="https://app.webnyay.in/files/termsAndConditions.pdf"
                  target="__blank"
                >
                  <Typography className={classes.boldText}>
                    Terms of Use
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <a
                  href="https://app.webnyay.in/files/wenyayPrivacyPolicy.pdf"
                  target="__blank"
                >
                  <Typography className={classes.boldText}>
                    Privacy Policy
                  </Typography>
                </a>
              </Grid>
              {/* <Grid item>
                <a href="https://www.webnyay.in/static/arbRules-d806c11670eb12afa8ba637c816b3f0d.pdf" target="__blank">
                  <Typography className={classes.boldText}>
                    Webnyay Arbitration Rules
                  </Typography>
                </a>
              </Grid> */}
              <Grid item>
                <a href="/contact">
                  <Typography className={classes.boldText}>
                    Contact Us
                  </Typography>
                </a>
              </Grid>
            </Grid>
            <Box height="1rem" />
            <Typography className={classes.text}>
              Webnyay provides online dispute resolution services at your
              direction. We are not a law firm or a substitute for any advocate
              or law firm. Communications between you and Webnyay are protected
              by our Privacy Policy, but not by any attorney-client privilege or
              as work product. We cannot provide any kind of advice,
              explanation, opinion, or recommendation about your legal rights,
              remedies, defenses, options, selection of forms or strategies.
              Your access to our website is subject to our Terms and Conditions
            </Typography>
            <Box height="1rem" />
            <Hidden mdUp>
              <Typography className={classes.text}>
                &copy; 2020 Copyright. all rights reserved.
              </Typography>
            </Hidden>
          </Grid>
        </Grid>
        <Box height="5rem" />
      </Grid>
    </Grid>
  );
}
