import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Overview from "./Overview";
import Analytics from "./Analytics";

const OverviewAnalyticsHelper = ({ classes }) => {
  const [sortby, setSortby] = useState("Mar 21");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [choice, setChoice] = useState("overview");

  return (
    <Box width="100%">
      <Grid container>
        <Grid item xl={4} lg={5} md={6} sm={8} xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid
              item
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (choice != "overview") setChoice("overview");
              }}
            >
              <Typography
                className={
                  choice == "overview" ? classes.blackheading : classes.grey
                }
              >
                Overview
              </Typography>
            </Grid>
            <Grid
              item
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (choice != "analytics") setChoice("analytics");
              }}
            >
              <Typography
                className={
                  choice == "analytics" ? classes.blackheading : classes.grey
                }
              >
                Analytics
              </Typography>
            </Grid>
            <Grid item>
              <Button
                aria-haspopup="true"
                endIcon={<ArrowDropDownIcon />}
                className={classes.sortbtn}
                onClick={handleClick}
              >
                <Typography className={classes.blackheading}>
                  {sortby}
                </Typography>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem
                  style={sortby == "Mar 21" ? { background: "#BDBDBD" } : {}}
                >
                  <Typography className={classes.menutext}>Mar 21</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {choice != "overview" && <Box height={50}></Box>}
      <Box width="100%">
        {choice == "overview" ? <Overview classes={classes} /> : <Analytics />}
      </Box>
    </Box>
  );
};

export default OverviewAnalyticsHelper;
