import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { formatDate } from "utils";
import downloadIcon from "images/downloadCompliance.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  heading: {
    color: "#000000",
    fontSize: "28px",
    lineHeight: "33px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
  box: {
    height: "5rem",
  },
  paper: {
    padding: "0.7rem",
  },
  paperTransparent: {
    padding: "0.7rem",
    background: "none",
    border: "none",
    boxShadow: "none",
  },
  text: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  textHeading: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#333333",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    textAlign: "center",
  },
}));

export default function ComplianceReportAnonymous({
  reports,
  downloadReport,
  yearlist,
  showYearList,
}) {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item md={7} sm={11} xs={12} style={{ textAlign: "center" }}>
        <Box className={classes.box} />
        <Typography className={classes.heading}>
          Published Compliance Reports
        </Typography>
        <Box height="2rem" />
        {!showYearList && (
          <>
            <Grid container justify="center" spacing={2}>
              <Grid item md={11} sm={11} xs={12}>
                <Paper className={classes.paperTransparent}>
                  <Grid container>
                    <Grid item xs>
                      <Typography className={classes.textHeading}>
                        FROM
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.textHeading}>
                        TO
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.textHeading}>
                        PUBLISHED ON
                      </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "right" }}></Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2}>
              {reports.map((report) => {
                return (
                  <Grid item md={11} sm={11} xs={12} key={report.id}>
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid item xs>
                          <Typography className={classes.text}>
                            {formatDate(new Date(report.from_date))}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography className={classes.text}>
                            {formatDate(new Date(report.to_date))}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography className={classes.text}>
                            {formatDate(report.created)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          onClick={() => {
                            downloadReport(report.id);
                          }}
                          style={{ textAlign: "right", cursor: "pointer" }}
                        >
                          <img src={downloadIcon} />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        {showYearList &&
          yearlist.map((year, index) => (
            <a
              key={index}
              href={`/compliancereports?year=${year}`}
              target="_blank"
            >
              <Typography
                className={classes.text}
                style={{ marginBottom: "0.5rem" }}
              >
                {year}
              </Typography>
            </a>
          ))}
      </Grid>
    </Grid>
  );
}
