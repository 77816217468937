import { isMuthoot } from "Routes/Routes";
import styles from "./Disputes.module.css";
import React from "react";
import { useTheme } from "@material-ui/core";

export const ExpandButtonOnHover = ({
  history,
  vector,
  vector_text,
  buttonText,
  link,
}) => {
  const theme = useTheme();
  
  return (
    <a
      href="javascript:;"
      className={styles.animate_button}
      onClick={() => {
        history.push(link);
      }}
      style={isMuthoot ? {backgroundColor: theme.palette.primary.main} : null}
    >
      <span className={styles.animate_icon}>
        <img src={vector} alt={vector_text} />
      </span>
      <span className={styles.animate_text}>{buttonText}</span>
    </a>
  );
};
