import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";
import { formatDateDIFC } from "Admin/DIFC/RVTiac/ADRP/ADRPProfile";
import { Alert } from "features/SnackBar/SnackbarAlert";
import FileImage from "images/fileIcon.svg";
import DotsImage from "images/threeDots.svg";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { isMSME, isSebi } from "Routes/Routes";
import { postActivity } from "slices/activitySlice";
import InfoIcon from "../../images/InfoIcon.svg";
import EditPermissions from "./EditPermissions";
import { deleteFile, DownloadFile, UpdateFile } from "./FileApi";
import SendForSign from "./SendforSign";

export default function FileView({
  file,
  disputeId,
  classes,
  reload,
  profile,
  allProfiles,
  parent_folder,
  selectedFiles,
  handleChangeOnFileCheckbox,
  unSelectAll,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  useEffect(() => {
    if (
      file &&
      file.uploaded_by.id != profile.user.id &&
      file.permission.indexOf(profile.user.id) == -1
    ) {
      setButtonsEnabled(false);
    }
    if (
      profile.profile_type == "sub_admin_tiac_rv" ||
      profile.profile_type == "case_manager" ||
      profile.profile_type == "admin"
    ) {
      setButtonsEnabled(true);
    }
  }, []);

  const [openmodal, setOpenmodal] = useState(false);
  const handleOpenEdit = () => {
    if (
      profile.user.id == file.uploaded_by.id ||
      profile?.profile_type === "case_manager" ||
      profile?.profile_type === "sub_admin_tiac_rv"
    ) {
      setOpenmodal(true);
    } else alert("only owner can edit permissions");
  };
  const handleCloseModalAndRefresh = () => {
    reload();
    setOpenmodal(false);
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenmodal(false);
    setAnchorEl(null);
  };

  const [openmodalSignature, setOpenmodalSignature] = useState(false);
  const handleOpenSignature = () => {
    if (
      profile.profile_type == "sub_admin_tiac_rv" ||
      profile.profile_type == "case_manager"
    )
      setOpenmodalSignature(true);
    else alert("only admin and case manager can use this functionality");
  };
  const handleCloseSignature = () => {
    setOpenmodalSignature(false);
    setAnchorEl(null);
  };

  let arr = file.name.split(".");
  let extension = "." + arr[arr.length - 1];
  let localName = file.name;
  localName = localName.replace(extension, "");
  const [nameWithoutExtension, setNameWithoutExtension] = useState(localName);

  const [rename, setRename] = useState(false);
  const handleFileNameChange = (e) => {
    setNameWithoutExtension(e.target.value);
  };
  const inputRef = useRef(null);
  const handleOnBlur = (e) => {
    setRename(false);
    if (nameWithoutExtension != localName) {
      UpdateFile(file.id, {
        name:
          arr.length > 1
            ? nameWithoutExtension + extension
            : nameWithoutExtension,
      }).then(() => {
        reload();
      });
    }
  };

  const handleRename = () => {
    if (buttonsEnabled) {
      handleClose();
      setRename(true);
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 100);
    } else {
      alert("You don't have edit permissions.");
    }
  };

  const handleDelete = () => {
    if (buttonsEnabled) {
      handleClose();
      let p = [...file.view_permission];
      p.push(file.uploaded_by.id);
      deleteFile(file.id).then(() => {
        dispatch(
          postActivity(
            "deleted a file",
            file.size,
            true,
            file.name,
            disputeId,
            p
          )
        );
        reload();
        unSelectAll();
      });
    } else {
      alert("You don't have delete permissions.");
    }
  };

  const handleDownload = (event) => {
    event.stopPropagation();
    DownloadFile(file.id)
      .then((res) => {
        handleOpenSnackBarForDownload();
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [snackBarForDownload, setsnackBarForDownload] = useState(false);
  const handleOpenSnackBarForDownload = () => {
    setsnackBarForDownload(true);
  };
  const handleCloseSnackBarDownload = () => {
    setsnackBarForDownload(false);
  };

  const SnackBarStyles = {
    boxShadow: "none",
    borderRadius: 0,
    textWrap: "wrap",
    color: "#696871",
    border: "1px solid #AAC6ED",
    background: "#E7EFFA",
  };

  const isSelected = selectedFiles?.includes(`${file.id}`);

  return (
    <>
      <Grid
        item
        container
        className={classes.filesHover}
        spacing={1}
        onDoubleClick={handleDownload}
        // onClick={handleDownload}
        data-tip
        data-for="download-guide"
        // onClick={(e)=>{console.log('row');handleChangeOnFileCheckbox(file.id)}}
        justify="space-between"
      >
        <Grid item sm={profile.profile_type === "case_manager" ? 6 : 8} xs={8}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              id={file.id}
              color="primary"
              className={
                isSelected ? classes.visibility : classes.visibilityHidden
              }
              style={{ padding: "5px", height: "fit-content" }}
              checked={isSelected}
              onChange={handleChangeOnFileCheckbox}
            />
            <Typography
              className={`${classes.tableRowContent} ${classes.no_selection}`}
            >
              <span style={{ verticalAlign: "middle" }}>
                <img src={FileImage} alt="file" />
              </span>
              <span style={{ marginLeft: "1rem" }}>
                {!rename ? (
                  <span
                    className={classes.fileFolderName}
                    onClick={handleDownload}
                  >
                    {file.name}
                  </span>
                ) : (
                  <input
                    ref={inputRef}
                    value={nameWithoutExtension}
                    style={{ width: "70%" }}
                    onChange={handleFileNameChange}
                    onBlur={handleOnBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleOnBlur();
                      }
                    }}
                  />
                )}
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item sm={2} xs={4}>
          <Typography
            className={`${classes.tableRowContent} ${classes.no_selection}`}
          >
            {file.uploaded_by.first_name} {file.uploaded_by.last_name}
          </Typography>
        </Grid>
        {profile.profile_type === "case_manager" && (
          <Grid item sm={2} xs={4}>
            <Typography
              className={`${classes.tableRowContent} ${classes.no_selection}`}
            >
              {formatDateDIFC(file.modified)}
            </Typography>
          </Grid>
        )}
        <Grid item sm={2} xs={4}>
          <Typography
            className={`${classes.tableRowContent} ${classes.no_selection}`}
          >
            {formatDateDIFC(file.created)}
            <IconButton
              onClick={handleClick}
              aria-controls={`three-dots-menu${file.id}`}
              aria-haspopup="true"
              style={{ padding: 0, float: "right" }}
            >
              <img src={DotsImage} alt="options" />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
      {/* <CustomReactTooltip id='download-guide'>
        <Typography style={{color:'white',lineHeight:'14px',fontSize:'0.8rem'}}>
          Double click to download
        </Typography>
      </CustomReactTooltip> */}
      <Menu
        aria-controls={`three-dots-menu${file.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleRename}
            >
              Rename
            </Typography>
            {(((isSebi || isMSME) && profile.profile_type === "admin") ||
              !(isSebi || isMSME)) && (
              <Typography
                className={classes.filesandfolder}
                onClick={handleDelete}
              >
                Delete
              </Typography>
            )}
            {(profile.user.id == file.uploaded_by.id ||
              profile?.profile_type === "case_manager" ||
              profile?.profile_type === "sub_admin_tiac_rv") && (
              <Typography
                className={classes.filesandfolder}
                onClick={handleOpenEdit}
              >
                Edit permissions
              </Typography>
            )}
            {(profile.profile_type == "sub_admin_tiac_rv" ||
              profile.profile_type == "case_manager") && (
              <Typography
                className={classes.filesandfolder}
                onClick={handleOpenSignature}
              >
                Send for Sign
              </Typography>
            )}
            <Typography
              className={classes.filesandfolder}
              onClick={handleDownload}
            >
              Download
            </Typography>
          </Box>
        </Box>
      </Menu>
      {openmodal && (
        <EditPermissions
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          handleCloseModalRefresh={handleCloseModalAndRefresh}
          files={[file]}
          folders={[]}
          allProfiles={allProfiles}
          parent_folder={parent_folder}
          disputeId={disputeId}
          profile={profile}
          viewPermission={file.view_permission}
          editPermission={file.permission}
        />
      )}
      <SendForSign
        openmodal={openmodalSignature}
        handleCloseModal={handleCloseSignature}
        file={file}
        allProfiles={allProfiles}
        parent_folder={parent_folder}
        disputeId={disputeId}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        open={snackBarForDownload}
        onClose={handleCloseSnackBarDownload}
      >
        <Alert
          onClose={handleCloseSnackBarDownload}
          severity="info"
          style={SnackBarStyles}
          iconMapping={{
            info: (
              <img
                src={InfoIcon}
                alt="info"
                style={{ height: "48px", width: "48px" }}
              />
            ),
          }}
        >
          <AlertTitle style={{ color: "black", fontWeigt: 700 }}>
            Information
          </AlertTitle>
          Download is in progress...
        </Alert>
      </Snackbar>
    </>
  );
}
