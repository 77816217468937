import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "3rem 4rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1.5rem 2rem",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "1rem 1.5rem",
      },
    },
    heading: {
      color: "#4F4F4F",
      fontSize: 32,
      fontWeight: "400",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
      },
    },
    text: {
      color: "#4F4F4F",
      fontSize: 16,
      fontWeight: "400",
      textAlign: "center",
      marginTop: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    underlinedText: {
      color: "#2F80ED",
      fontSize: 16,
      fontWeight: "400",
      textAlign: "center",
      marginTop: "1rem",
      cursor: "pointer",
      textDecoration: "underline",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
  };
});

const ForgotPasswordResultDIFC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justify="center">
      <Grid item xl={5} md={7} sm={11} xs={11}>
        <Paper className={classes.paper}>
          <Grid container justify="center">
            <Grid item xs={10}>
              <Typography className={classes.heading}>
                Check your email
              </Typography>
              <Typography className={classes.text}>
                We sent a password reset link to your registered email address
              </Typography>
            </Grid>
          </Grid>
          <Typography
            className={classes.underlinedText}
            onClick={() => {
              history.push("/");
            }}
          >
            Back to login
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordResultDIFC;
