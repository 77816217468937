import {
  ADJUDICATION,
  ARBITRATION,
  CLAIMANT_COMPLETE,
  MEDIATION,
  NEGOTIATION,
  NEW,
  RESPONDENT_COMPLETE,
  RESPONDENT_INPROGRESS,
} from "app/constants";
import { compareAsc, isAfter, isBefore, subMonths, subWeeks } from "date-fns";
import { formatDate } from "utils";

export const calculatePercentage = (count, totalCount) => {
  return (count / totalCount) * 100;
};

const getLabel = (value) => {
  switch (value) {
    case NEW:
      return "Dispute Created";
    case RESPONDENT_COMPLETE:
      return "Respondent Details Filled";
    case CLAIMANT_COMPLETE:
      return "Claimant Details filled";
    case "terms_complete":
      return "Terms Complete";
    case "price_agreement_complete":
      return "Price Agreement Complete";
    case "professional_upload":
      return "Professional Upload";
    case "webnyay_upload":
      return "Webnyay Upload";
    case "award_upload":
      return "Award Upload";
    case "complete":
      return "Complete";
    case "paid":
      return "Paid";
    case "closed":
      return "Closed";
    case MEDIATION:
      return "Mediation";
    case NEGOTIATION:
      return "Negotiation";
    case ARBITRATION:
      return "Arbitration";
    case ADJUDICATION:
      return "Adjudication";
    case RESPONDENT_INPROGRESS:
      return "Respondent in progress";
    case "":
      return "None";
  }
};

export const createDisputeStatusMap = (disputes) => {
  const statusMap = new Map();

  disputes.forEach((dispute) => {
    if (statusMap.has(dispute.status)) {
      const currentKey = statusMap.get(dispute.status);
      statusMap.set(dispute.status, currentKey + 1);
    } else {
      statusMap.set(dispute.status, 1);
    }
  });

  return statusMap;
};

export const createDisputeModeMap = (disputes) => {
  const modeMap = new Map();

  disputes.forEach((dispute) => {
    if (modeMap.has(dispute.flow_type)) {
      const currentKey = modeMap.get(dispute.flow_type);
      modeMap.set(dispute.flow_type, currentKey + 1);
    } else {
      modeMap.set(dispute.flow_type, 1);
    }
  });

  return modeMap;
};

export const createDisputeCountMap = (disputes) => {
  const countMap = new Map();

  disputes.forEach((dispute) => {
    const dateKey = formatDate(dispute.order_date);

    if (countMap.has(dateKey)) {
      const currentKey = countMap.get(dateKey);
      countMap.set(dateKey, currentKey + 1);
    } else {
      countMap.set(dateKey, 1);
    }
  });

  return countMap;
};

export const transformDisputeStatusPieData = (statusMap, totalCount) => {
  const percentageMap = new Map();

  const percentageArray = [];

  statusMap.forEach((value, key) => {
    percentageMap.set(key, calculatePercentage(value, totalCount));
    percentageArray.push({ value: Math.floor(value), name: getLabel(key) });
  });

  return percentageArray;
};

export const transformDisputeToBarData = (countMap) => {
  const barGraphData = [];

  countMap.forEach((value, key) => {
    barGraphData.push({
      date: key,
      disputeCount: value,
    });
  });

  return barGraphData.sort((a, b) => {
    return compareAsc(Date.parse(a.date), Date.parse(b.date));
  });
};

const timeValues = ["week", "month", "quarter", "half", "all"];

const getProperTimeValue = (time) => {
  if (timeValues.includes(time)) {
    return time;
  } else {
    return "week";
  }
};
export const evaluatePastTime = (time) => {
  const currentDate = new Date();

  switch (getProperTimeValue(time)) {
    case "week":
      return subWeeks(currentDate, 1);
    case "month":
      return subMonths(currentDate, 1);
    case "quarter":
      return subMonths(currentDate, 3);
    case "half":
      return subMonths(currentDate, 6);
    case "all":
      return null;
  }
};

export const filterDispute = (disputes, time) => {
  const fromDate = evaluatePastTime(time);
  const nowDate = new Date();
  let filteredDisputes;
  if (fromDate) {
    filteredDisputes = disputes.filter((dispute) => {
      const parsedDate = new Date(dispute.order_date);

      return isAfter(parsedDate, fromDate) && isBefore(parsedDate, nowDate);
    });
  }

  return fromDate ? filteredDisputes : disputes;
};
