import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "center",
    marginTop: "20rem",
    [theme.breakpoints.down("sm")]: {
        marginTop:"5rem"
      },
  },
  text:{
      fontSize:"1.5rem",
      lineHeight:"4rem",
      [theme.breakpoints.down("sm")]: {
        fontSize:"1rem",
        lineHeight:"2rem",
      },
  }
}));

export default function Maintenance() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.box}>
      <Typography className={classes.text}>
        We are currently under maintenance. Please try again after sometime.
      </Typography>
      <Button
        startIcon={<ArrowBackIosIcon />}
        onClick={() => {
          history.goBack();
        }}
      >
        <FormattedMessage id="buttons.back" />
      </Button>
    </Box>
  );
}
