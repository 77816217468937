import set from 'lodash/set';

function getFieldValue(value, fieldsInput, name) {
    let object = {};
    const parentKey = name?.split(".")[0];
    set(object, name, value);

    return (name.includes(".") ? { [parentKey]: { ...fieldsInput[parentKey], ...object[parentKey] } } : { [name]: value });
}

export default getFieldValue;