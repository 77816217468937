import { InputAdornment, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { NBF_LOGIN } from "app/constants";
import classNames from "classnames";
import GoogleAuth from "features/common/GooglAuth";
import LinkedinAuth from "features/common/LinkedinAuth";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { setLogOut, setTokenAsync } from "slices/loginSlice";
import * as Yup from "yup";
import React from "react";

const LoginSchema = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Email Required"),
  password: Yup.string().required("Password Required"),
  // .min(6, "Password Too Short!")
  // .max(20, "Password Too Long!")
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 2rem",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loginButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#000",
    color: "white",
    border: "1px solid transparent",

    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #000",
      color: "#000",
    },
  },
  signupButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#E50014",
    color: "white",
    border: "1px solid transparent",

    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #E50014",
      color: "#E50014",
    },
  },
  heading: {
    fontSize: "1.5rem",
    // lineHeight: "5rem",
    textTransform: "capitalize",
    color: "#E50014",
    // whiteSpace: "nowrap",
    textAlign: "center",
  },
  secondaryText: {
    color: "#000000",
    // whiteSpace: "nowrap",
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
  signinText: {
    fontSize: "1.5rem",
    lineHeight: "2.2rem",
    textTransform: "capitalize",
    color: "black",
  },
  title: {
    flexGrow: 1,
    "& img": {
      width: "10rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: "7rem",
      },
    },
  },
}));

const Signin = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  localStorage.setItem(NBF_LOGIN, true);

  const { state: routeState } = useLocation();
  const apiErrors = useSelector((state) => state.login.errors);
  const hasErrors = useSelector((state) => state.login.hasErrors);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(setLogOut());
      let { email, password } = values;
      dispatch(setTokenAsync(email, password)).then(() => {
        if (routeState) {
          history.push(`${routeState.from.pathname}`);
        } else {
          history.push("/");
        }
      });
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  React.useEffect(() => {
    dispatch(setLogOut()); // to remove login error alert
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs />
          <Grid item xs className={classes.title}>
            <img src="/NBF_Logo_1.png" alt="" />
          </Grid>
          <Grid item xs />
        </Grid>
        <Typography
          className={classes.heading}
          wrap={true}
          wordrwap={true}
          component="h1"
          variant="h5"
        >
          News Broadcasters Federation
        </Typography>
        <Box height="0.8"></Box>
        <Typography className={classes.secondaryText} variant="caption">
          Professional News Broadcasting Standards Organisation
          (Self-regulatory)
        </Typography>
        <Box height="0.8"></Box>
        <Typography
          className={classNames(classes.secondaryText, classes.boldText)}
          variant="caption"
        >
          Grievance Management Process
        </Typography>
        <Box height="2rem"></Box>

        <Typography className={classes.signinText}>Sign in </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="filled"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.email && formik.touched.email && (
            <Alert severity="error">{formik.errors.email}</Alert>
          )}
          {hasErrors && apiErrors.email && (
            <Alert severity="error">{apiErrors.email[0]}</Alert>
          )}
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={passwordType}
            id="password"
            value={formik.password}
            autoComplete="current-password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType === "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          {hasErrors && apiErrors.password && (
            <Alert severity="error">{apiErrors.password[0]}</Alert>
          )}
          <Box
            display="flex"
            justifyContent="end"
          >
            <Link to="/forgot-password">
              <Typography>
                <FormattedMessage id="auth.forgot" />
              </Typography>
            </Link>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.loginButton}
          >
            <FormattedMessage id="auth.login" />
          </Button>
          {hasErrors && apiErrors.non_field_errors && (
            <Alert severity="error">{apiErrors.non_field_errors[0]}</Alert>
          )}

          <Box height="1rem"></Box>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <GoogleAuth />
            </Grid>
            <Grid item md={6} xs={12}>
              <LinkedinAuth />
            </Grid>
          </Grid>

          <Box height="2rem"></Box>
          <Box textAlign="center">
            {" "}
            <Typography className={classes.secondaryText} variant="caption">
              <FormattedMessage id="auth.noAccount" />
            </Typography>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.signupButton}
            onClick={() => history.push("/signup")}
          >
            <FormattedMessage id="auth.signup" />
          </Button>
        </form>
        <Typography
          className={classes.secondaryText}
          style={{ fontStyle: "italic" }}
          variant="caption"
        >
          *Powered by Webnyay Beta
        </Typography>
      </Paper>

      <Box height="2rem"></Box>
    </Container>
  );
};

export default Signin;
