import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  ARBITRATOR_COMPLETE,
  CLAIMANT_ADVOCATE_COMPLETE,
  RESPONDENT_ADVOCATE_COMPLETE,
  RESPONDENT_COMPLETE,
} from "app/constants";
import { parseISO } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import isEmpty from "lodash/isEmpty";
import matchSorter from "match-sorter";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDispute } from "slices/disputeSlice";

const getTitle = (rvStatus) => {
  switch (rvStatus) {
    case CLAIMANT_ADVOCATE_COMPLETE:
      return "Find your lawyer";
    case RESPONDENT_COMPLETE:
      return "Find Respondent";
    case RESPONDENT_ADVOCATE_COMPLETE:
      return "Find your lawyer";
    case ARBITRATOR_COMPLETE:
      return "Find Arbitrator";
  }
};

export default function UserVerifyForm({
  profileFetched,
  setNotEmpty,
  rvStatus,
  profiles,
  activeDispute,
  activeCase,
  step,
  tiac,
  reload,
}) {
  const dispatch = useDispatch();
  const [showNext, setShowNext] = useState(
    !activeDispute.rv_claimant_lawyer_step_complete &&
      activeCase.lawyer_rv.length > 0 &&
      step == 0
      ? true
      : !activeDispute.rv_arbitrator_step_complete &&
        activeDispute.professionals.length > 0 &&
        step == 2
      ? true
      : !activeDispute.rv_respondent_lawyer_step_complete &&
        activeCase.lawyer_rv.length > 0 &&
        step == 5
      ? true
      : false
  );

  // const [showNext, setShowNext] = useState(
  //   activeDispute.rv_claimant_lawyer_step_complete && activeCase.lawyer_rv.length > 0
  //     ? true
  //     : activeDispute.rv_respondent_lawyer_step_complete &&
  //       activeCase.lawyer_rv.length > 0
  //     ? true
  //     : activeDispute.rv_arbitrator_step_complete &&
  //       activeDispute.professionals.length > 0
  //     ? true
  //     : false
  // );

  // switch (rvStatus) {
  //   case CLAIMANT_ADVOCATE_COMPLETE:
  //     if (activeCase.lawyer_rv.length > 0) {
  //       setShowNext(true);
  //     }
  //     break;
  //   case RESPONDENT_COMPLETE:
  //     break;
  //   case RESPONDENT_ADVOCATE_COMPLETE:
  //     if (activeCase.lawyer_rv.length > 0) {
  //       setShowNext(true);
  //     }
  //     break;
  //   case ARBITRATOR_COMPLETE:
  //     if (activeDispute.professionals.length > 0) {
  //       setShowNext(true);
  //     }
  // }

  const onSubmit = async (e) => {
    setLoading(true);
    // let profile = await getProfileByPhonenumber(values.phone);
    if (isEmpty(selectedProfile)) {
      profileFetched({});
    } else {
      profileFetched({
        ...selectedProfile,
        birth_date: parseISO(selectedProfile.birth_date),
      });
      setNotEmpty();
    }
    setLoading(false);
  };

  const onNext = () => {
    switch (step) {
      case 0:
        if (activeCase.lawyer_rv.length > 0) {
          console.log("laywer exist so proceed");
          dispatch(
            updateDispute({
              id: activeDispute.id,
              rv_claimant_lawyer_step_complete: true,
            })
          );
        }
        break;
      case RESPONDENT_COMPLETE:
        return "Find Respondent";
      case 1:
        if (activeCase.lawyer_rv.length > 0) {
          console.log("laywer exist so proceed");
          dispatch(
            updateDispute({
              id: activeDispute.id,
              // rv_respondent_lawyer_step_complete: true,
            })
          );
        }
        break;
      case 2:
        if (activeDispute.professionals.length > 0) {
          console.log("PRfoessionals exist so proceed");
          dispatch(
            updateDispute({
              id: activeDispute.id,
              rv_arbitrator_step_complete: true,
            })
          );
        }
        break;
      case 5:
        if (tiac) {
          dispatch(
            updateDispute({
              id: activeDispute.id,
              rv_respondent_lawyer_step_complete: true,
            })
          ).then(() => {
            reload();
          });
        } else if (activeCase.lawyer_rv.length > 0) {
          console.log("laywer exist so proceed");
          dispatch(
            updateDispute({
              id: activeDispute.id,
              rv_respondent_lawyer_step_complete: true,
            })
          );
        }

        break;
    }
  };

  const [loading, setLoading] = React.useState(false);
  const [selectedProfile, setSelectedProfile] = React.useState({});

  return (
    <Box p="2rem 0" textAlign="center">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={12} md={6}>
          <Box p="0rem 2rem">
            <Typography variant="h4" gutterBottom>
              {getTitle(rvStatus)}
            </Typography>

            <Autocomplete
              id="combo-box-demo"
              fullWidth
              autoComplete={true}
              options={profiles}
              onChange={(event, value) => {
                setSelectedProfile(value);
              }}
              filterOptions={(options, state) => {
                return matchSorter(options, state.inputValue, {
                  keys: [
                    (item) => `${item.user.first_name} ${item.user.last_name}`,
                    "phone_number",
                  ],
                });
              }}
              onInputChange={(event) => {}}
              getOptionLabel={(option) => option.user.first_name}
              renderOption={(option, state) => {
                return (
                  <React.Fragment>
                    {option.user.first_name} {option.user.last_name}
                    <Box display="inline-block" width="1rem"></Box>
                    <Typography variant="caption">
                      {option.phone_number}
                    </Typography>
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<FormattedMessage id="pages.intro.search" />}
                  variant="outlined"
                />
              )}
            />

            <Box height="2rem"></Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              onClick={onSubmit}
            >
              {isEmpty(selectedProfile) ? (
                <FormattedMessage id="buttons.create" />
              ) : (
                <FormattedMessage id="buttons.search" />
              )}
            </Button>
            <Box height="6rem"></Box>
            {(showNext || tiac) && (
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={onNext}
                  >
                    {tiac && activeCase.lawyer_rv.length < 2 ? "Skip" : "Next"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>
  );
}
