import React, { useState } from "react";
import Moment from "moment";
import {
  Container,
  Box,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  renameUserFile,
  deleteUserFile,
  addUserFile,
  downloadFile,
  downloadFileAdminDpcgc,
  downloadFileAdminHotstar,
} from "slices/disputeSlice";
import { useDispatch } from "react-redux";
// import fileType from "file-type";
import mime from "mime-types";
import GetAppIcon from "@material-ui/icons/GetApp";
import ClearIcon from "@material-ui/icons/Clear";
import { DropzoneDialog } from "material-ui-dropzone";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";

import "./styles.css";

// import "react-keyed-file-browser/dist/react-keyed-file-browser.css";

import FileBrowser, { Icons, FileRenderers } from "react-keyed-file-browser";

const transformFiles = (files) => {
  const transformedFiles = [];

  files.forEach((file) => {
    transformedFiles.push({
      key: file.path,
      modified: Date.parse(file.modified),
      id: file.id,
      size: file.size,
    });
  });
  return transformedFiles;
};

export default ({ files: fetchedFiles }) => {
  const useStyles = makeStyles(() => ({
    icon: {
      fontSize: "3rem",
    },
  }));
  const [open, setOpen] = React.useState(false);

  let company = sessionStorage.getItem("company");

  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    let transformedFiles = transformFiles(fetchedFiles);
    setFiles(transformedFiles);
  }, [fetchedFiles]);

  // const addFileToServer = async (file, name) => {
  //   const fileBody = {
  //     dispute: activeDispute.id,
  //     path: 123,
  //     file,
  //     profile: profile.id,
  //     type: mime.lookup(file.name),
  //     name,
  //     size: file.size,
  //   };
  //   dispatch(addUserFile(fileBody));
  // };

  // const deleteFile = async (id) => {
  //   dispatch(deleteUserFile(id, activeDispute.id));
  // };

  // const renameFile = async (id, newKey) => {
  //   dispatch(renameUserFile(id, activeDispute.id, newKey));
  // };

  // const handleCreateFolder = (key) => {
  //   // files = [...files, { key }];
  //   setFiles([...files, { key }]);
  // };
  // const handleCreateFiles = async (uplaodedFiles, prefix) => {
  //   const fileToBeUploaded = uplaodedFiles[0];
  //   let fileName = prefix
  //     ? `${prefix}${fileToBeUploaded.name}`
  //     : fileToBeUploaded.name;
  //   await addFileToServer(uplaodedFiles[0], fileName);
  // };

  // const handleRenameFolder = (oldKey, newKey) => {
  //   const newFiles = [];
  //   files.map((file) => {
  //     if (file.key.substr(0, oldKey.length) === oldKey) {
  //       newFiles.push({
  //         ...file,
  //         key: file.key.replace(oldKey, newKey),
  //         modified: +Moment(),
  //       });
  //     } else {
  //       newFiles.push(file);
  //     }
  //   });
  //   setFiles(newFiles);
  // };

  // const handleRenameFile = async (oldKey, newKey) => {
  //   let oldFile;
  //   let newFile;
  //   files.forEach((file) => {
  //     if (file.key === oldKey) {
  //       oldFile = file;
  //     }
  //     if (file.key === newKey) {
  //       newFile = file;
  //     }
  //   });
  //   await renameFile(oldFile.id, newKey);
  // };
  // const handleDeleteFolder = (folderKey) => {
  //   const newFiles = [];
  //   files.map((file) => {
  //     if (file.key.substr(0, folderKey.length) !== folderKey) {
  //       newFiles.push(file);
  //     }
  //   });
  //   setFiles(newFiles);
  // };
  // const handleDeleteFile = async (fileKey) => {
  //   let selectedFile = files.find((file) => {
  //     return file.key === fileKey[0];
  //   });
  //   await deleteFile(selectedFile.id);
  // };

  const classes = useStyles();
  return (
    <Container>
      <Box height="3rem"> </Box>
      <FileBrowser
        files={files}
        icons={Icons.FontAwesome(4)}
        // onCreateFolder={handleCreateFolder}
        // onCreateFiles={handleCreateFiles}
        // onMoveFolder={handleRenameFolder}
        // onMoveFile={handleRenameFile}
        // onRenameFolder={handleRenameFolder}
        // onRenameFile={handleRenameFile}
        // onDeleteFolder={handleDeleteFolder}
        // onDeleteFile={handleDeleteFile}
        detailRenderer={(data) => {
          const selectedFile = files.find((file) => file.key === data.file.key);
          return (
            <Box
              display="flex"
              p="1.5rem"
              alignItems="center"
              border="1px solid grey"
              borderRadius="0.2rem"
            >
              <Typography>{data.file.name}</Typography>
              <Box width="3rem"></Box>
              <IconButton
                onClick={async () => {
                  if (company == "hotstar") {
                    downloadFileAdminHotstar(selectedFile.id);
                  } else if (company == "dpcgc") {
                    downloadFileAdminDpcgc(selectedFile.id);
                  } else {
                    downloadFile(selectedFile.id);
                  }
                }}
              >
                {" "}
                <GetAppIcon />
              </IconButton>
              <Box width="1rem"></Box>
              <IconButton onClick={data.close}>
                {" "}
                <ClearIcon />
              </IconButton>
            </Box>
          );
        }}
      />

      {/* <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          files.forEach((file) => {
            addFileToServer(file, file.name);
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
      /> */}
      <Box display="flex" justifyContent="center">
        {/* <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon className={classes.icon} />
        </IconButton> */}
      </Box>
    </Container>
  );
};
