import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { downloadDoc } from "utils/apituils";
import Avatar from "features/Chat/Avatar";
import classNames from "classnames";
import { chatUrl } from "api/globalaxios";

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: "pointer",
  },
  iconContainer: {
    background: "rgba(255,255,255,0.25)",
    borderRadius: "6px",
  },
  audio: {},
}));

export default function DocTemplate({
  file: { title, title_link, audio_url },
  position,
  from,
  domId,
  classes: { root, time },
  parsedTimestamp,
  name
}) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent={position ? position : "flex-end"}
      m="2rem 0"
      id={domId}
    >
      {from && (
        <React.Fragment>
          <Avatar value={name[0].toUpperCase()}  name={name}/>
          <Box width="1rem"></Box>
        </React.Fragment>
      )}

      {audio_url ? (
        <AudioMessage
          audio_url={audio_url}
          root={root}
          time={time}
          parsedTimestamp={parsedTimestamp}
        />
      ) : (
        <Box textAlign="right">
          <Box
            display="flex"
            className={classNames(root, classes.pointer)}
            p="2rem 1rem"
            onClick={() => {
              try{
                let arr = title_link.split("/")
                downloadDoc('/ufs/AmazonS3:Uploads/'+arr[2]+"/"+arr[3]);
              }
              catch{}
            }}
          >
            <Box p="0.25rem 0.3rem" className={classes.iconContainer}>
              <InsertDriveFileOutlinedIcon style={{ fontSize: "1.5rem" }} />
            </Box>
            <Box width="1rem"></Box>
            <Typography variant="subtitle1" style={{ wordBreak: 'break-all' }}>{title}</Typography>
          </Box>
          <Typography variant="caption" className={time}>
            {parsedTimestamp}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const AudioMessage = ({ audio_url, parsedTimestamp, root, time }) => {
  return (
    <Box display="flex" flexDirection="Column" textAlign="end">
      <audio controls src={`${chatUrl}${audio_url}`} style={{maxHeight: "100%",
          maxWidth: "100%",
          margin: 'auto',
          objectFit: 'contain'
}}/>
      <Typography variant="caption" className={time}>
        {parsedTimestamp}
      </Typography>
    </Box>
  );
};
