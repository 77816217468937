import { Box, Button, Drawer, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout, setLogOut } from "slices/loginSlice";
import WebLogo from "images/TiacWebLogo.png";
import { makeStyles } from "@material-ui/core/styles";
import { isHotStar, isMuthoot, isStar, isTiac } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  title1: {
    // flexGrow: 2,

    "& img": {
      width: "10rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: "7rem",
      },
    },
  },
}));

const Tiac_HambergerMenu = ({
  open,
  handleDrawerClose,
  children,
  isProfessionalAccount,
  profileType,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const token = useSelector((state) => state.login.token);

  const isLoggedin = !!token;

  const dashboardOptions = [
    {
      value: "active",
      to: `/disputes/${profileType}/active`,
    },
    {
      value: "resolved",
      to: `/disputes/${profileType}/resolved`,
    },
  ];

  const ProfileOptions = [
    {
      value: "Profile",
      to: `/profile`,
    },
    {
      value: "Logout",
      handleClick: () => {
        sessionStorage.clear();
        dispatch(logout());
        dispatch(setLogOut());
        if (isHotStar || isStar || isMuthoot) history.push("/");
        else history.push("/login");
      },
    },
  ];

  const unauthenticatedOptions = [
    {
      value: "Login",
      handleClick: () => {
        history.push("/login");
      },
    },
  ];
  console.log("professional accounttttt", isProfessionalAccount);

  return (
    <Drawer
      open={open}
      anchor="left"
      variant="temporary"
      onClick={handleDrawerClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        p="1rem 5rem 0 0.1rem"
        alignItems="start"
      >
        {!isProfessionalAccount ? (
          <>
            <Button
              color="inherit"
              onClick={() => {
                history.push("/");
              }}
            >
              <FormattedMessage id="nav.dashboard" />
            </Button>

            {isTiac && (
              <div className={classes.title1}>
                <a href="https://webnyaya.com" target="_blank">
                  <img
                    // className={classes.vectorImage}
                    src={WebLogo}
                    alt=""
                    align="right"
                  />
                </a>
              </div>
            )}
          </>
        ) : (
          <React.Fragment>
            <Button color="inherit" disabled>
              <FormattedMessage id="nav.dashboard" />
            </Button>
            <Nestedmenu items={dashboardOptions} />
          </React.Fragment>
        )}
      </Box>
      <Box style={{ borderBottom: "1px solid #b2b2b2", width: "100%" }}></Box>
      <Box
        display="flex"
        flexDirection="column"
        p="1rem 5rem 0 0.1rem"
        alignItems="start"
      >
        <Button color="inherit" disabled>
          <FormattedMessage id="user.user" />
        </Button>
        <Nestedmenu
          items={isLoggedin ? ProfileOptions : unauthenticatedOptions}
        />
      </Box>
    </Drawer>
  );
};

const Nestedmenu = ({ items }) => {
  const history = useHistory();

  return (
    <React.Fragment>
      {items.map((item) => {
        return (
          <Box p="0 0 0 1rem">
            <Button
              color="inherit"
              onClick={() => {
                if (item.to) {
                  history.push(item.to);
                } else {
                  item.handleClick();
                }
              }}
            >
              <Typography> {item.value}</Typography>
            </Button>
          </Box>
        );
      })}
    </React.Fragment>
  );
};

export default Tiac_HambergerMenu;
