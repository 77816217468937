import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    "font-weight": "bold",
    "font-size": "14px",
    "line-height": "16px",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  text: {
    "font-size": "16px",
    "line-height": "19px",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

export default function ClientsList({
  data,
  setShowSubAdmin,
  setId,
  setCompany,
  setMode,
  client,
  setType,
}) {
  const classes = useStyles();

  return (
    <>
      <Paper
        style={{
          background: "none",
          border: "none",
          boxShadow: "none",
          padding: "10px 20px",
        }}
      >
        <Grid container justify="flex-start">
          <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>NAME</Typography>
          </Grid>
          <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>MODE</Typography>
          </Grid>
          <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>OPEN</Typography>
          </Grid>
          <Grid item sm={2} xs={2} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>CLOSED</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box height="0.5rem" />
      {data.map((item) => {
        if (item.id) {
          return (
            <>
              <Paper
                style={{ padding: "18px 20px" }}
                // onClick={(e) => {
                //   sessionStorage.setItem("id", item.id);
                //   sessionStorage.setItem("company", item.company);
                //   sessionStorage.setItem("mode", item.mode);
                //   try {
                //     if (client) {
                //       sessionStorage.setItem("clientsStep", 2);
                //     }
                //   } catch (e) {
                //   }
                //   setId(item.id);
                //   setCompany(item.company);
                //   setShowSubAdmin(true);
                //   setMode(item.mode);

                //   // setOpenGrievanceRedressal(false);
                // }}
              >
                <Grid container justify="flex-start">
                  <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {item.company}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {item.mode}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={3}
                    xs={3}
                    style={{ textAlign: "left", cursor: "pointer" }}
                    onClick={(e) => {
                      sessionStorage.setItem("id", item.id);
                      sessionStorage.setItem("company", item.company);
                      sessionStorage.setItem("mode", item.mode);
                      try {
                        if (client) {
                          sessionStorage.setItem("clientsStep", 2);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                      setId(item.id);
                      setCompany(item.company);
                      setShowSubAdmin(true);
                      setMode(item.mode);
                      setType(true);

                      // setOpenGrievanceRedressal(false);
                    }}
                  >
                    <Typography className={classes.text}>
                      {item.open}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    xs={2}
                    style={{ textAlign: "left", cursor: "pointer" }}
                    onClick={(e) => {
                      sessionStorage.setItem("id", item.id);
                      sessionStorage.setItem("company", item.company);
                      sessionStorage.setItem("mode", item.mode);
                      try {
                        if (client) {
                          sessionStorage.setItem("clientsStep", 2);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                      setId(item.id);
                      setCompany(item.company);
                      setShowSubAdmin(true);
                      setMode(item.mode);
                      setType(false);

                      // setOpenGrievanceRedressal(false);
                    }}
                  >
                    <Typography className={classes.text}>
                      {item.closed}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Box height="0.5rem" />
            </>
          );
        } else {
          return (
            <>
              <Paper
                style={{ padding: "18px 20px" }}
                // onClick={(e) => {
                //   sessionStorage.removeItem("id");
                //   sessionStorage.setItem("company", item.company);
                //   sessionStorage.setItem("mode", item.mode);
                //   try {
                //     if (client) {
                //       sessionStorage.setItem("clientsStep", 2);
                //     }
                //   } catch (e) {
                //     console.log(e);
                //   }
                //   setId();
                //   setCompany(item.company);
                //   setShowSubAdmin(true);
                //   setMode(item.mode);
                //   // setOpenGrievanceRedressal(false);
                // }}
              >
                <Grid container justify="flex-start">
                  <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {item.company}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {item.mode}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={3}
                    xs={3}
                    style={{ textAlign: "left", cursor: "pointer" }}
                    onClick={(e) => {
                      sessionStorage.removeItem("id");
                      sessionStorage.setItem("company", item.company);
                      sessionStorage.setItem("mode", item.mode);
                      try {
                        if (client) {
                          sessionStorage.setItem("clientsStep", 2);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                      setId();
                      setCompany(item.company);
                      setShowSubAdmin(true);
                      setMode(item.mode);
                      setType(true);
                      // setOpenGrievanceRedressal(false);
                    }}
                  >
                    <Typography className={classes.text}>
                      {item.open}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    xs={2}
                    style={{ textAlign: "left", cursor: "pointer" }}
                    onClick={(e) => {
                      sessionStorage.removeItem("id");
                      sessionStorage.setItem("company", item.company);
                      sessionStorage.setItem("mode", item.mode);
                      try {
                        if (client) {
                          sessionStorage.setItem("clientsStep", 2);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                      setId();
                      setCompany(item.company);
                      setShowSubAdmin(true);
                      setMode(item.mode);
                      setType(false);
                      // setOpenGrievanceRedressal(false);
                    }}
                  >
                    <Typography className={classes.text}>
                      {item.closed}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Box height="0.5rem" />
            </>
          );
        }
      })}
    </>
  );
}
