import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ReplyIcon from "@material-ui/icons/Reply";
import instance from "api/globalaxios";
import Axios from "axios";
import { format } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import DisputeRow from "features/disputesList/DisputeRow";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  isCDR,
  isDigipub,
  isHotStar,
  isMuthoot,
  isNSE,
  isRv,
  isStar,
  isVikalp,
} from "Routes/Routes";
import searchRafiki from "../../images/hotstardisputeslistsearch.svg";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
    // background: "#4F4F4F",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff",
    padding: "1rem",
    borderRadius: "0.3rem",
  },
  header2: {
    // background: theme.palette.primary.main,
    background: "#4F4F4F",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff",
    padding: "1rem",
    borderRadius: "0.3rem",
  },
  heading: {
    textTransform: "capitalize",
    color: "#fff",
    margin: "1.5rem 2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  icon: {
    fontSize: "3rem",
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  paper: {
    // padding: "0.5rem",
    // cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    // color: "#333333",
    padding: "1rem",
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    // whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      lineHeight: "1.2rem",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
  text: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  searchbarBox: {
    fontSize: "20px",
    borderRadius: "16px",
    background: "white",
    wordWrap: "",
    border: "none",
  },
  searchbar: {
    border: "none",
    width: "100%",
    color: "#828282",
    fontSize: "15px",
    margin: "0.7rem 2rem",
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      margin: "0.7rem 0 0.7rem 0.5rem",
    },
  },
}));

export default function DisputesList({
  disputes,
  role,
  disputesType,
  isProfessionalAccount,
  profileType,
  totalNoOfPages,
  setTotalNoOfPages,
  currentPage,
  setCurrentPage,
  getUserDataAnotherPage,
  profile,
}) {
  const classes = useStyles(0);
  let history = useHistory();
  const dispatch = useDispatch();
  let data;
  if (isCDR || isVikalp) {
    data = [
      {
        title: <FormattedMessage id="dispute.disputeId" />,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
      },
      {
        title: <FormattedMessage id="dispute.mode" />,
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
      },
      {
        title: <FormattedMessage id="dispute.viewStatementOfClaim" />,
      },
    ];
  } else if (isHotStar || isStar || isMuthoot) {
    data = [
      {
        title: <FormattedMessage id="dispute.GrievanceId" />,
      },
      {
        title: "Grievance Name",
      },
      {
        title: <FormattedMessage id="dispute.hotstarstartDate" />,
      },
      {
        title: <FormattedMessage id="dispute.registrationDate" />,
      },
      {
        title: <FormattedMessage id="dispute.viewGrievance" />,
      },
    ];
  } else if (isRv) {
    data = [
      {
        title: <FormattedMessage id="dispute.CaseId" />,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
      },
    ];
  } else if (isDigipub) {
    data = [
      {
        title: <FormattedMessage id="dispute.GrievanceId" />,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
      },
      {
        title: <FormattedMessage id="dispute.viewGrievance" />,
      },
    ];
  } else if (isNSE) {
    data = [
      {
        title: <FormattedMessage id="dispute.CaseId" />,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
      },
      {
        title: <FormattedMessage id="dispute.mode" />,
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
      },
      {
        title: <FormattedMessage id="dispute.viewCase" />,
      },
    ];
  } else {
    data = [
      {
        title: <FormattedMessage id="dispute.GrievanceId" />,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
      },
      {
        title: <FormattedMessage id="dispute.mode" />,
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
      },
      {
        title: <FormattedMessage id="dispute.viewGrievance" />,
      },
    ];
  }

  const [searchText, setSearchText] = useState("");
  const [dispute, setDisputes] = useState([]);

  const [loading, setLoading] = useState(true);
  const [cancelToken, setCancelToken] = useState();

  function search(value) {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    let x = Axios.CancelToken.source();
    setCancelToken(x);

    if (value.trim() != "") {
      let ar = value.trim().split("-");
      let date = new Date(ar[1] + "/" + ar[0] + "/" + ar[2]);
      if (isValidDate(date)) {
        dispatch(gethotstardatabydate(date, x));
        setLoading(false);
      } else if (isValidNumber(value.trim())) {
        dispatch(gethotstardatabyDisputeId(value.trim(), x));
        setLoading(false);
      } else if (isValidMonth(value.trim().toLowerCase())) {
        dispatch(gethotstardatabyMonth(value.trim().toLowerCase(), x));
        setLoading(false);
      } else {
        dispatch(gethotstardatabyname(value.trim(), x));
        setLoading(false);
      }
    } else {
      setDisputes(null);
      setLoading(true);
    }
  }

  function isValidMonth(value) {
    if (
      value == "january" ||
      value == "february" ||
      value == "march" ||
      value == "april" ||
      value == "may" ||
      value == "june" ||
      value == "july" ||
      value == "august" ||
      value == "september" ||
      value == "october" ||
      value == "november" ||
      value == "december"
    ) {
      return true;
    }
    return false;
  }

  function isValidNumber(value) {
    return !isNaN(value);
  }

  function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }
  let firstname = "";
  let lastname = "";
  function gethotstardatabyname(name, cancelToken) {
    let type = "";
    // type = disputesType == "active" ? "open" : "closed";
    if (role == "claimant") {
      type = disputesType == "active" ? "openclaimant" : "closedclaimant";
    } else if (role == "respondent") {
      type = disputesType == "active" ? "openrespondent" : "closedrespondent";
    } else {
      type = disputesType == "active" ? "open" : "closed";
    }
    return async (dispatch) => {
      try {
        const answer_array = name.split(" ");
        firstname = answer_array[0];
        lastname = answer_array[1];
        if (firstname != "" && (lastname == "" || !lastname)) {
          const response2 = await instance.get(
            `/api/HotstarsearchView1/${firstname}/${null}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputes(response2.data);

          return response2.data;
        } else {
          const response3 = await instance.get(
            `/api/HotstarsearchView1/${firstname}/${lastname}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputes(response3.data);
          return response3.data.profiles;
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabydate(date, cancelToken) {
    return async (dispatch) => {
      let type = "";
      // type = disputesType == "active" ? "open" : "closed";
      if (role == "claimant") {
        type = disputesType == "active" ? "openclaimant" : "closedclaimant";
      } else if (role == "respondent") {
        type = disputesType == "active" ? "openrespondent" : "closedrespondent";
      } else {
        type = disputesType == "active" ? "open" : "closed";
      }
      try {
        const response = await instance.get(
          `/api/HotstarsearchbydateView/${format(
            date,
            "dd-MM-yyyy"
          )}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputes(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabyDisputeId(value, cancelToken) {
    return async (dispatch) => {
      let type = "";
      // type = disputesType == "active" ? "open" : "closed";
      if (role == "claimant") {
        type = disputesType == "active" ? "openclaimant" : "closedclaimant";
      } else if (role == "respondent") {
        type = disputesType == "active" ? "openrespondent" : "closedrespondent";
      } else {
        type = disputesType == "active" ? "open" : "closed";
      }
      try {
        const response = await instance.get(
          `/api/HotstarsearchViewByDisputeId/${value}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputes(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabyMonth(value, cancelToken) {
    return async (dispatch) => {
      let type = "";
      // type = disputesType == "active" ? "open" : "closed";
      if (role == "claimant") {
        type = disputesType == "active" ? "openclaimant" : "closedclaimant";
      } else if (role == "respondent") {
        type = disputesType == "active" ? "openrespondent" : "closedrespondent";
      } else {
        type = disputesType == "active" ? "open" : "closed";
      }
      var months = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ];
      let index = months.indexOf(value);
      try {
        const response = await instance.get(
          `/api/HotstarsearchbyMonth/${index}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputes(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  return (
    <Container>
      {(isHotStar || isStar || isMuthoot) &&
        (profileType == "arbitrator" || profileType == "vendor") && (
          <Box
            style={{ textAlign: "center", marginTop: "30px" }}
            className={classes.searchbarBox}
          >
            <Grid container>
              <Grid item md={11} sm={11} xs={10}>
                <input
                  type="text"
                  placeholder="Search by complainant name, date (dd-mm-yyyy) or grievance id"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    search(e.target.value);
                  }}
                  onBlur={(e) => {
                    // search();
                    e.target.placeholder =
                      "Search by complainant name, date (dd-mm-yyyy) or grievance id";
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // search();
                    }
                  }}
                  className={classes.searchbar}
                  onFocus={(e) => (e.target.placeholder = "")}
                />
              </Grid>
              <Grid item md={1} sm={1} xs={2} style={{ textAlign: "center" }}>
                <IconButton
                  onClick={() => {
                    // search();
                  }}
                  style={{ paddidng: "0" }}
                >
                  <img
                    src={searchRafiki}
                    alt="Search"
                    style={{ height: "14px" }}
                  ></img>
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        )}
      <Box height="3rem"></Box>
      {!isProfessionalAccount && (
        <Link to="/disputes" style={{ textDecoration: "none" }}>
          <Button startIcon={<ArrowBackIosIcon />}>
            <Typography variant="body1">
              <FormattedMessage id={`nav.dashboard`} />
            </Typography>
          </Button>
        </Link>
      )}

      <Box height="1rem"></Box>

      {(isHotStar || isStar || isMuthoot) && profileType == "arbitrator" && (
        <>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AssessmentIcon />}
                onClick={() => {
                  history.push("/analytics");
                }}
              >
                <FormattedMessage id="buttons.analytics" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<EventIcon />}
                onClick={() => {
                  history.push("/events");
                }}
              >
                <FormattedMessage id="buttons.calendar" />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <Box height="1rem"></Box>
      <Box className="grievancesstep1">
        <Box
          className={
            disputesType == "active" ? classes.header : classes.header2
          }
        >
          {!isCDR && !isVikalp && !isHotStar && !isStar && !isMuthoot && (
            <Hidden smDown>
              <Box textAlign="end">
                <Typography
                  variant="body2"
                  style={{ textTransform: "uppercase" }}
                >
                  <FormattedMessage id={`dispute.${disputesType}`} />{" "}
                  <FormattedMessage id={`dispute.cases`} />
                </Typography>{" "}
              </Box>
            </Hidden>
          )}

          <Box display="flex" alignItems="center">
            {!isHotStar && !isStar && !isMuthoot && <ReplyIcon className={classes.icon} />}
            <Typography className={classes.heading} variant="h3">
              {(() => {
                if (role === "arbitrator") {
                  return "Hello";
                } else {
                  if (isRv) {
                    return <FormattedMessage id={`dispute.claimantRV`} />;
                  } else if (isCDR || isVikalp) {
                    return disputesType == "active"
                      ? "Open Disputes"
                      : "Closed Disputes";
                  } else if (isHotStar || isStar || isMuthoot)  {
                    return disputesType == "active"
                      ? "Open Grievances"
                      : "Closed Grievances";
                  } else if (isNSE) {
                    return disputesType == "active"
                      ? "Previous Cases"
                      : "Previous Cases";
                  }
                  return <FormattedMessage id={`dispute.${role}`} />;
                }
              })()}
              {/* {role} */}
            </Typography>
          </Box>
        </Box>
        <Box height="1.5rem"></Box>
      </Box>

      {isRv ? (
        <div className={classes.paper}>
          <Grid container justify="flex-start">
            <Grid item sm={4} xs={4}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[0].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[1].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[2].title}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : isDigipub ? (
        <div className={classes.paper}>
          <Grid container justify="flex-start">
            <Grid item sm={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[0].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[1].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[2].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[3].title}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.paper}>
          <Grid container justify="flex-start">
            <Grid item sm={2} xs={2}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[0].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[1].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2} xs={2}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[2].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[3].title}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2} xs={2}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                  {data[4].title}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      )}

      <Box height="1rem"></Box>
      <Box>
        {!isEmpty(disputes) &&
          searchText.trim() == "" &&
          disputes.map((dispute) => {
            return (
              <DisputeRow
                profileType={profileType}
                key={dispute.id}
                dispute={dispute}
                profile={profile}
              />
            );
          })}
        {(isHotStar || isStar || isMuthoot) &&
          !isEmpty(dispute) &&
          searchText.trim() != "" &&
          !loading &&
          dispute.map((dispute) => {
            return (
              <DisputeRow
                profileType={profileType}
                key={dispute.id}
                dispute={dispute}
              />
            );
          })}
      </Box>
      <Grid container>
        <Grid item>
          <IconButton
            disabled={currentPage == 1}
            onClick={(e) => {
              setCurrentPage(currentPage - 1);
              getUserDataAnotherPage(currentPage - 1);
            }}
          >
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
          </IconButton>
          <IconButton
            disabled={currentPage >= totalNoOfPages}
            onClick={(e) => {
              setCurrentPage(currentPage + 1);
              getUserDataAnotherPage(currentPage + 1);
            }}
          >
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </IconButton>
        </Grid>
      </Grid>
    </Container>
  );
}
