import { isDev } from 'Routes/Routes';
import { NDML_FORM_URL, NDML_FORM_URL_DEV } from 'app/constants';
import React, {useEffect, useRef} from 'react';

function PaymentForm({responseFromApi}) {
  const keyPair = ['messageType', 'merchantId', 'serviceId', 'orderId', 'customerId', 'transactionAmount', 'currencyCode',  'requestDateTime', 'successUrl', 'failUrl', 'additionalField1', 'additionalField2', 'additionalField3', 'additionalField4', 'additionalField5'];

  const messageValues = responseFromApi?.message?.split('|');
  const keyValues = {};

  if (messageValues.length === keyPair.length) {

    for (let i = 0; i < keyPair.length; i++) {
      keyValues[keyPair[i]] = messageValues[i];
    }

    // Add the CheckSum
    keyValues['checksum'] = responseFromApi.token;

  } else {
    console.error("The number of values in 'message' doesn't match the 'keyPair' length.");
  }

  const formRef = useRef(null);

  useEffect(() => {
    // Submit the form when the component mounts
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);

  const ndmlUrl = isDev ? NDML_FORM_URL_DEV : NDML_FORM_URL;

  return (
    <div>
      <form
        action={ndmlUrl}
        method="post"
        ref={formRef}
      >
        {Object.keys(keyValues).map((fieldName,index) => {
            return(
                <input
                    type="hidden"
                    name={fieldName}
                    value={keyValues[fieldName]}
                />
            )
        })}
      </form>
    </div>
  );
}

export default PaymentForm;
