import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

const timezonesSlice = createSlice({
  name: "timezones",
  initialState: {
    loading: false,
    hasErrors: false,
    timezonesList: [],
    dataFetched: false,
  },
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    stopProgress: (state) => {
      state.loading = false;
    },
    setSuccessFull: (state, action) => {
      state.timezonesList = action.payload;
      state.loading = false;
      state.hasErrors = false;
      state.dataFetched = true;
    },
    setError: (state, action) => {
      state.loading = false;
      state.hasErrors = action.payload || true;
    },
    setDataFetched: (state, action) => {
      state.dataFetched = action.payload;
    },
  },
});

export const { setProgress, setSuccessFull, setError, setDataFetched } =
  timezonesSlice.actions;

export default timezonesSlice.reducer;

export function getTimeZones() {
  return async (dispatch, getState) => {
    const { timezones } = getState();

    // Check if data is already fetched
    if (timezones.dataFetched) {
      return;
    }

    // Check if data is in localStorage
    const cachedTimezones = localStorage.getItem("timezones");
    if (cachedTimezones) {
      dispatch(setSuccessFull(JSON.parse(cachedTimezones)));
      return;
    }

    dispatch(setProgress());
    try {
      const response = await instance.get("/timezones/");
      dispatch(setSuccessFull(response.data));
      localStorage.setItem("timezones", JSON.stringify(response.data));
    } catch (error) {
      dispatch(setError(error.response));
    }
  };
}

export function changeTimeZone(timezone) {
  return async (dispatch) => {
    try {
      await instance.post("/timezones/change/", { timezone });
    } catch (error) {
    }
  };
}
