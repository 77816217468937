import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React from "react";
import { useParams } from "react-router-dom";
import TiacCalculator from "./TiacCalculator";

export default function Calculator() {
  //   const {id} = useParams()
  //   if(id == 1){
  // }

  // return <CircularCenteredLoader />;
  return <TiacCalculator />;
}
