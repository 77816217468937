import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ClientsList from "./Common/ClientsList";
import SubAdminOverview from "./Overview/SubAdminOverview";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  sortbybtn: {
    cursor: "pointer",
    border: "none",
    background: "#E0E0E0",
    "box-shadow":
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    "border-radius": "4px",
  },
  sortbytext: {
    padding: "10px 30px 10px 10px",
    "font-size": "12px",
    "line-height": "0px",
    "text-align": "center",
    "text-transform": "uppercase",
    color: "#454545",
  },
}));

export default function Clients({ profile, isSmallScreen }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  let t = sessionStorage.getItem("clientsStep");
  let c = sessionStorage.getItem("company");
  let i = sessionStorage.getItem("id");
  let m = sessionStorage.getItem("mode");

  const [showSubAdmin, setShowSubAdmin] = useState(t ? true : false);
  const [id, setId] = useState(i ? i : null);
  const [company, setCompany] = useState(c ? c : null);
  const [mode, setMode] = useState(m ? m : null);

  const [loading, setLoading] = useState(true);

  const [finaldata, setFinaldata] = useState([]);

  const [order, setOrder] = React.useState("increase");
  const [sortby, setSortby] = useState("name");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [type, setType] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNameSort = () => {
    setSortby("name");
    let result = finaldata;
    if (order == "increase") {
      result.sort(function (a, b) {
        var nameA = a.company.toUpperCase();
        var nameB = b.company.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      result.sort(function (a, b) {
        var nameA = a.company.toUpperCase();
        var nameB = b.company.toUpperCase();
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    setFinaldata(result);
    setAnchorEl(null);
  };
  const handleOpenSort = () => {
    setSortby("open");
    let result = finaldata;
    if (order == "increase") {
      result.sort(function (a, b) {
        var nameA = a.open;
        var nameB = b.open;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      result.sort(function (a, b) {
        var nameA = a.open;
        var nameB = b.open;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    setFinaldata(result);
    setAnchorEl(null);
  };
  const handleClosedSort = () => {
    setSortby("closed");
    let result = finaldata;
    if (order == "increase") {
      result.sort(function (a, b) {
        var nameA = a.closed;
        var nameB = b.closed;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      result.sort(function (a, b) {
        var nameA = a.closed;
        var nameB = b.closed;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    setFinaldata(result);
    setAnchorEl(null);
  };
  const handleChangeRadio = (event) => {
    setOrder(event.target.value);
    if (sortby == "name") {
      let result = finaldata;
      if (event.target.value == "increase") {
        result.sort(function (a, b) {
          var nameA = a.company.toUpperCase();
          var nameB = b.company.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else {
        result.sort(function (a, b) {
          var nameA = a.company.toUpperCase();
          var nameB = b.company.toUpperCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
      }
      setFinaldata(result);
      setAnchorEl(null);
    } else if (sortby == "open") {
      let result = finaldata;
      if (event.target.value == "increase") {
        result.sort(function (a, b) {
          var nameA = a.open;
          var nameB = b.open;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else {
        result.sort(function (a, b) {
          var nameA = a.open;
          var nameB = b.open;
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
      }
      setFinaldata(result);
      setAnchorEl(null);
    } else if (sortby == "closed") {
      let result = finaldata;
      if (event.target.value == "increase") {
        result.sort(function (a, b) {
          var nameA = a.closed;
          var nameB = b.closed;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else {
        result.sort(function (a, b) {
          var nameA = a.closed;
          var nameB = b.closed;
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
      }
      setFinaldata(result);
      setAnchorEl(null);
    }
  };

  React.useEffect(() => {
    dispatch(apiFunction()).then((res) => {});
  }, []);

  function apiFunction() {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/companybyplateformandgroup/?platform_type=grievance`
        );
        let data = response.data.data;
        let result = [];
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            let doit = false;
            let item = {};
            item.company = key;
            // console.log(key + " -> " + data[key]);
            if (key == "webnyay") {
              doit = true;
            }
            if (doit) {
              for (var k in data[key]) {
                if (data[key].hasOwnProperty(k)) {
                  let it = {};
                  it.company = k;
                  // console.log(k + " -> " + data[key][k]);
                  for (var l in data[key][k]) {
                    if (l == "open") {
                      it.open = data[key][k][l];
                    } else if (l == "closed") {
                      it.closed = data[key][k][l];
                    } else if (l == "company_id") {
                      it.id = data[key][k][l];
                    }
                    it.mode = "GR";
                    if (data[key][k].hasOwnProperty(l)) {
                      // console.log(l + " -> " + data[key][k][l]);
                    }
                  }
                  result.push(it);
                }
              }
            } else {
              for (var k in data[key]) {
                if (k == "open") {
                  item.open = data[key][k];
                } else if (k == "closed") {
                  item.closed = data[key][k];
                }
                item.mode = "GR";
                if (data[key].hasOwnProperty(k)) {
                  // console.log(k + " -> " + data[key][k]);
                }
              }
            }
            if (!doit && key != 'vikalp') result.push(item);
          }
        }
        const response2 = await instance.get(
          `/api/companybyplateformandgroup/?platform_type=cdr`
        );
        data = response2.data.data;
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            let doit = false;
            let item = {};
            item.company = key;
            // console.log(key + " -> " + data[key]);
            if (key == "webnyay") {
              doit = true;
            }
            if (doit) {
              for (var k in data[key]) {
                if (data[key].hasOwnProperty(k)) {
                  let it = {};
                  it.company = k;
                  // console.log(k + " -> " + data[key][k]);
                  for (var l in data[key][k]) {
                    if (l == "open") {
                      it.open = data[key][k][l];
                    } else if (l == "closed") {
                      it.closed = data[key][k][l];
                    } else if (l == "company_id") {
                      it.id = data[key][k][l];
                    }
                    it.mode = "CDR";
                    if (data[key][k].hasOwnProperty(l)) {
                      // console.log(l + " -> " + data[key][k][l]);
                    }
                  }
                  result.push(it);
                }
              }
            } else {
              for (var k in data[key]) {
                if (k == "open") {
                  item.open = data[key][k];
                } else if (k == "closed") {
                  item.closed = data[key][k];
                }
                item.mode = "CDR";
                if (data[key].hasOwnProperty(k)) {
                  // console.log(k + " -> " + data[key][k]);
                }
              }
            }
            if (!doit && (item.open > 0 || item.closed > 0)) result.push(item);
          }
        }
        const response3 = await instance.get(
          `/api/companybyplateformandgroup/?platform_type=rv`
        );
        data = response3.data.data;
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            let doit = false;
            let item = {};
            item.company = key;
            // console.log(key + " -> " + data[key]);
            if (key == "webnyay") {
              doit = true;
            }
            if (doit) {
              for (var k in data[key]) {
                if (data[key].hasOwnProperty(k)) {
                  let it = {};
                  it.company = k;
                  // console.log(k + " -> " + data[key][k]);
                  for (var l in data[key][k]) {
                    if (l == "open") {
                      it.open = data[key][k][l];
                    } else if (l == "closed") {
                      it.closed = data[key][k][l];
                    } else if (l == "company_id") {
                      it.id = data[key][k][l];
                    }
                    it.mode = "RV";
                    if (data[key][k].hasOwnProperty(l)) {
                      // console.log(l + " -> " + data[key][k][l]);
                    }
                  }
                  result.push(it);
                }
              }
            } else {
              for (var k in data[key]) {
                if (k == "open") {
                  item.open = data[key][k];
                } else if (k == "closed") {
                  item.closed = data[key][k];
                }
                item.mode = "RV";
                if (data[key].hasOwnProperty(k)) {
                  // console.log(k + " -> " + data[key][k]);
                }
              }
            }
            if (!doit && (item.open > 0 || item.closed > 0)) result.push(item);
          }
        }
        const resp = await instanceHotstar.get(
          `/api/companybyplateformandgroup/?platform_type=grievance`
        );
        let u = [];
        result.map((resitem, index) => {
          if (resitem.company != "hotstar") {
            // u.push(resitem);
          } else {
            let temp = resitem;
            temp.open = resp.data.data.hotstar.open;
            temp.closed = resp.data.data.hotstar.closed;
            // u.push(temp);
          }
        });
        const resDpcgc = await instanceDPCGC.get(
          `/api/companybyplateformandgroup/?platform_type=grievance`
        );
        let dpcgc = {
          company: "dpcgc",
          mode: "GR",
          open: resDpcgc.data.data.dpcgc.open,
          closed: resDpcgc.data.data.dpcgc.closed,
        };
        result.push(dpcgc);
        result.sort(function (a, b) {
          var nameA = a.company.toUpperCase();
          var nameB = b.company.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setFinaldata(result);
        setLoading(false);
        return result;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    if (showSubAdmin) {
      return (
        <SubAdminOverview
          profile={profile}
          isSmallScreen={isSmallScreen}
          company={company}
          id={id}
          setShowSubAdmin={setShowSubAdmin}
          mode={mode}
          type={type}
        />
      );
    } else {
      return (
        <div className={classes.root}>
          <Grid container justify="flex-start" direction="column">
            <Grid item>
              <Grid
                container
                justify="space-between"
                direction="row"
                style={{ padding: "10px" }}
              >
                <Grid item>
                  <Typography className={classes.heading}>Clients</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.username}>
                    {profile.user.first_name} {profile.user.last_name}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="2rem"></Box>
              <Grid container justify="center">
                <Grid item sm={7} xs={11}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <button
                        type="button"
                        className={classes.sortbybtn}
                        onClick={handleClick}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                      >
                        <Typography className={classes.sortbytext}>
                          Sort by
                        </Typography>
                      </button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          wrap="wrap"
                          style={{ padding: "5px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <input
                                type="radio"
                                id="increase"
                                name="order"
                                value="increase"
                                onChange={handleChangeRadio}
                                style={{
                                  transform: "scale(1.5)",
                                  margin: "6px 3px 0px 6px",
                                }}
                                checked={order == "increase" ? true : false}
                              />
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography>
                                  <label
                                    for="increase"
                                    style={{
                                      fontSize: "16px",
                                      color: "#454545",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Inc.
                                  </label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <input
                                type="radio"
                                id="decrease"
                                name="order"
                                value="decrease"
                                onChange={handleChangeRadio}
                                style={{
                                  transform: "scale(1.5)",
                                  margin: "6px 3px 0px 6px",
                                }}
                                checked={order == "decrease" ? true : false}
                              />
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography>
                                  <label
                                    for="decrease"
                                    style={{
                                      fontSize: "16px",
                                      color: "#454545",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Dec.
                                  </label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box height="0.7rem"></Box>
                        <Divider />
                        <MenuItem
                          onClick={(e) => {
                            handleNameSort(e);
                          }}
                        >
                          Name
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleOpenSort(e);
                          }}
                        >
                          Open
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleClosedSort(e);
                          }}
                        >
                          Closed
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  <Box height="4rem"></Box>
                  <ClientsList
                    data={finaldata}
                    setShowSubAdmin={setShowSubAdmin}
                    setId={setId}
                    setCompany={setCompany}
                    setMode={setMode}
                    client={true}
                    setType={setType}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
