import React, {useState, useRef, useEffect} from "react";
import {
    Box,
    IconButton,
    Chip,
    List,
    ListItem,
    ListItemText,
    makeStyles
  } from "@material-ui/core";
import { DateRange, ExpandLess, ExpandMore } from "@material-ui/icons";
import DateRangePickerNew from "./DateRange";

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'absolute',
        background: 'white',
        border: '1px solid #ccc',
        padding: theme.spacing(2),
        boxShadow: theme.shadows[5],
        zIndex: 1,
        marginTop: theme.spacing(1), // This ensures the box appears below the chip
        maxHeight: '200px', // Fixed height for the box
        overflowY: 'auto', // Make the box scrollable
      },
      boxCalendar: {
        position: 'absolute',
        background: 'white',
        border: '1px solid #ccc',
        boxShadow: theme.shadows[5],
        zIndex: 1,
        marginTop: theme.spacing(1), // This ensures the box appears below the chip
        overflowY: 'auto', // Make the box scrollable
      },
    channelList: {
        listStyleType: 'none',
        padding: 0,
    },
    channelItem: {
        marginBottom: theme.spacing(1),
        cursor: 'pointer'
    },
    chip: {
        margin: theme.spacing(1),
    },
    chipWrap: {
        marginLeft: '10px'
    },
    chipContainer: {
        position: 'relative', // Important for absolute positioning of the box
    },
    selectedItem: {
        backgroundColor: theme.palette.action.selected,
    },
  }));

export default function CustomChip({icon, label, boxValues, filterName, handleChangeFilter, isObjectArray}) {
    const classes = useStyles();
    const [boxOpen, setBoxOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const boxRef = useRef();

    const handleOpenBox = () => {
        setBoxOpen(!boxOpen);
    };

    const handleUpdateValue = (filterName, value) => {
        if (handleChangeFilter) {
            handleChangeFilter(filterName, value);
        }
        setBoxOpen(false);
    }

    const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
          setBoxOpen(false);
        }
      };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={classes.chipContainer}>
            <Chip
                label={
                <Box display="flex" alignItems="center">
                    <IconButton size="small" aria-label="tick">
                        {icon}
                    </IconButton>
                    <Box component="span" mx={1}>
                        {label}
                    </Box>
                    <IconButton size="small" aria-label="close" onClick={handleOpenBox}>
                        {!boxOpen ?<ExpandMore /> : <ExpandLess/>}
                    </IconButton>
                </Box>
                }
                variant="outlined"
                className={classes.chipWrap}
            />
            {boxOpen && boxValues && boxValues.length > 0 && filterName !== 'time_frame' && (
                <Box className={classes.box} ref={boxRef}>
                    <List className={classes.channelList}>
                        {boxValues?.map((value, index) => (
                            <ListItem 
                                key={index} 
                                className={`${classes.channelItem} ${selectedTags.includes(value) ? classes.selectedItem : ''}`}
                                onClick={filterName === 'time_frame'  ? () => handleUpdateValue(filterName, value.id)  : ()=> handleUpdateValue(filterName, value)}
                            >
                                <ListItemText primary={isObjectArray ? value.name : value} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
            {boxOpen && boxValues && boxValues.length > 0 && filterName === 'time_frame' &&
                <Box className={classes.boxCalendar} ref={boxRef}>
                    <DateRangePickerNew boxValues={boxValues} handleUpdateValue={handleUpdateValue}/>
                </Box>
            }
        </div>
    );
}  
