import {
  Box,
  Collapse,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Up from "images/up.svg";
import Down from "images/down.svg";
import { content } from "./featuresStaticData";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding:"10px",
    },
  },
  featured: {
    color: "#70c8ce",
    fontSize: "50px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  blueheading: {
    fontSize: "1.5rem",
    color: "#3f51b5",
    fontWeight: "bold",
    letterSpacing: "0.01em",
    wordBreak: "break-word",
  },
  text: {
    fontSize: "1.5rem",
    color: "#333333",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
  },
}));

const Expand = ({ heading, text, classes }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Grid
        container
        justify="space-between"
        onClick={(e) => {
          setShow(!show);
        }}
        style={{ cursor: "pointer", margin: "5px 0" }}
      >
        <Grid item sm={11}>
          <Typography className={classes.blueheading}>{heading}</Typography>
        </Grid>
        <Grid item sm={1} style={{ textAlign: "right",marginLeft:"auto" }}>
          <img src={show ? Up : Down} alt="Arrow Down" />
        </Grid>
      </Grid>
      <Collapse in={show} timeout={600}>
        <p
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Collapse>
    </>
  );
};

export default function Features() {
  const classes = useStyles();

  console.log(content);
  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item ms={9} sm={10} xs={11}>
        <Box height="1rem" />
        <Grid container>
          <Grid item md={4} sm={4}>
            <Typography className={classes.featured}>Features</Typography>{" "}
          </Grid>
          <Grid item md={8} sm={8}>
            {content.map((item, index) => {
              const title = Object.keys(item)[0];
              const value = item[title];
              return (
                <>
                  <Expand heading={title} text={value} classes={classes} />
                  {index < content.length - 1 && (
                    <Divider style={{ background: "black", height: "2px" }} />
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
        <Box height="1rem" />
      </Grid>
    </Grid>
  );
}
