import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import FolderImage from "images/folderIcon.svg";
import UploadFolder from "./UploadFolder";
import UploadFile from "./UploadFile";
import { formatDate } from "utils";
import { DownloadSelectedFiles, UploadFolders } from "./FileApi";
import FolderView from "./FolderView";
import FileView from "./FileView";
import { postActivity } from "slices/activitySlice";
import { useDispatch } from "react-redux";
import { Add, Close } from "@material-ui/icons";
import { useSnackbar } from "contexts/SnackbarContext";
import EditPermissions from "./EditPermissions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
  },
  heading: {
    color: "black",
    fontSize: "1.5rem",
    fontWeight: "700",
    fontFamily: `Satoshi-Bold,'Inter',sans-serif`,
  },
  uploadbtn: {
    background: "white",
    fontFamily: `Satoshi-Bold,'Inter',sans-serif`,
    // padding: "1rem 2rem",
    // cursor: "pointer",
  },
  uploadbtntext: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    textTransform: "none",
  },
  menuwidth: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "130px",
    },
  },
  files: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  filesHover: {
    padding: "0.6rem 1rem",
    cursor: "pointer",
    background: "white",
    "&:hover": {
      background: "#E0E0E0",
      "& $visibilityHidden": {
        visibility: "visible",
      },
      "& $fileFolderName": {
        textDecoration: "underline",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  filesandfolder: {
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: `Satoshi-Medium,'Inter',sans-serif`,
    padding: "0.6rem 1.5rem",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  tableHeaderContent: {
    fontFamily: "Satoshi-Bold",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  tableRowContent: {
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  no_selection: {
    "user-select": "none",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-ms-user-select": "none",
  },
  fileFolderName: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  visibilityHidden: {
    visibility: "hidden",
  },
  visibility: {
    visibility: "visible",
  },
}));

export default function Files({
  disputeId,
  folder_id,
  allFiles,
  allFolders,
  reload,
  allProfiles,
  profile,
  parent_folder,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [filteredFolders, setFilteredFolders] = useState([]);

  useEffect(() => {
    const localFilteredFiles = allFiles.filter(file => selectedFiles.includes(`${file.id}`));
    setFilteredFiles(localFilteredFiles)

  }, [selectedFiles, allFiles])

  useEffect(() => {
    const localFilteredFolders = allFolders.filter(folder => selectedFolders.includes(`${folder.id}`));
    setFilteredFolders(localFilteredFolders)
  }, [selectedFolders, allFolders])

  useEffect(() => {
    if (
      parent_folder &&
      parent_folder.uploaded_by.id != profile.user.id &&
      parent_folder.edit_permission.indexOf(profile.user.id) == -1
    ) {
      setButtonsEnabled(false);
    }
    if (
      profile.profile_type == "sub_admin_tiac_rv" ||
      profile.profile_type == "case_manager" ||
      profile.profile_type == "admin"
    ) {
      setButtonsEnabled(true);
    }
  }, [parent_folder]);
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [folderName, setFolderName] = useState("New Folder");

  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };
  const inputRef = useRef(null);

  const handleOnBlur = (e) => {
    if (folderName == "New Folder") {
      setShowNewFolderInput(false);
      setFolderName("New Folder");
    } else {
      UploadFolders(
        disputeId,
        folderName,
        folder_id,
        parent_folder ? parent_folder.view_permission : [],
        parent_folder ? parent_folder.edit_permission : []
      ).then((result) => {
        dispatch(
          postActivity(
            "uploaded a folder",
            0,
            false,
            folderName,
            disputeId,
            parent_folder ? parent_folder.view_permission : [],
            null,
            result?.data?.id
          )
        );
        setShowNewFolderInput(false);
        setFolderName("New Folder");
        reload();
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (buttonsEnabled) setAnchorEl(event.currentTarget);
    else alert("You don't have edit permissions.");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElNew, setAnchorElNew] = useState(null);
  const handleClickNew = (event) => {
    if (buttonsEnabled) setAnchorElNew(event.currentTarget);
    else alert("You don't have edit permissions.");
  };
  const handleCloseNew = () => {
    setAnchorElNew(null);
  };
  const createNewFolder = () => {
    handleCloseNew();
    setShowNewFolderInput(true);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };
  const [openmodalUploadFolder, setOpenmodalUploadFolder] = useState(false);
  const handleOpenUploadFolderModal = () => {
    setOpenmodalUploadFolder(true);
  };
  const handleCloseUploadFolderModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFolder(false);
    setAnchorEl(null);
  };
  const handleCloseUploadFolderModal = () => {
    setOpenmodalUploadFolder(false);
    setAnchorEl(null);
  };
  const [openmodalUploadFile, setOpenmodalUploadFile] = useState(false);
  const handleOpenUploadFileModal = () => {
    setOpenmodalUploadFile(true);
  };
  const handleCloseUploadFileModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFile(false);
    setAnchorEl(null);
  };
  const handleCloseUploadFileModal = () => {
    setOpenmodalUploadFile(false);
    setAnchorEl(null);
  };

  const [openmodal, setOpenmodal] = useState(false);
  const handleOpenEdit = () => {
    if (
      // profile.user.id == file.uploaded_by.id ||
      profile?.profile_type === "case_manager" ||
      profile?.profile_type === "sub_admin_tiac_rv"
    ) {
      setOpenmodal(true);
    } else alert("only owner can edit permissions");
  };
  const handleCloseModalAndRefresh = () => {
    reload();
    setOpenmodal(false);
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenmodal(false);
    setAnchorEl(null);
  };

  const handleChangeOnFileCheckbox = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      selectedFiles.push(event.target.id);
      setSelectedFiles([...selectedFiles]);
    } else {
      setSelectedFiles(() => {
        return selectedFiles.filter((x) => {
          return x !== event.target.id;
        });
      });
    }
  };

  const handleChangeOnFolderCheckbox = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      selectedFolders.push(event.target.id);
      setSelectedFolders([...selectedFolders]);
    } else {
      setSelectedFolders(() => {
        return selectedFolders.filter((x) => {
          return x !== event.target.id;
        });
      });
    }
  };

  // const handleChangeOnFolderCheckbox= (checkboxId,e) => {
  //   e.stopPropagation();
  //   const element = document.getElementById(checkboxId);
  //   const isCheckboxChecked = element.checked;
  //   const isSelected = selectedFolders?.includes(checkboxId);
  //   console.log(isSelected,selectedFolders)
  //   if(!isSelected){
  //     selectedFolders.push(checkboxId);
  //     setSelectedFolders([...selectedFolders]);
  //   }
  //   else{
  //     setSelectedFolders(()=>{
  //       return selectedFolders.filter((x)=>{
  //         return x !== checkboxId
  //       })
  //     })
  //   }
  // }

  const unSelectAll = () => {
    setSelectedFiles([]);
    setSelectedFolders([]);
  };

  const handleDownloadMultipleFiles = () => {
    const data = {
      folder_ids: selectedFolders,
      file_ids: selectedFiles,
    };
    DownloadSelectedFiles(data)
      .then((res) => {
        const urls = res?.data?.urls;
        if (urls && urls.length > 0) {
          urls.forEach((url) => {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
          });
          showSnackbar("Download initiated...", "info");
          unSelectAll();
        } else {
          showSnackbar("Files not found!", "warning");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.heading}>Files</Typography>
      <Box height="2rem" />
      <Grid container spacing={2} alignItems="center">
        {selectedFiles.length === 0 && selectedFolders.length === 0 ? (
          <>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                aria-controls="upload-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={`${classes.menuwidth}`}
                startIcon={<Add />}
              >
                Upload
                {/* <Typography className={classes.uploadbtntext}>Upload</Typography> */}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                aria-controls="new-folder"
                aria-haspopup="true"
                onClick={handleClickNew}
                className={`${classes.uploadbtn} ${classes.menuwidth}`}
                startIcon={<Add />}
              >
                New
                {/* <Typography className={classes.uploadbtntext}>New</Typography> */}
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Typography style={{ fontSize: "1.2rem", color: "black" }}>
              <IconButton onClick={unSelectAll}>
                <Close />
              </IconButton>
              {selectedFiles.length + selectedFolders.length} Selected
            </Typography>
            <Grid item xs></Grid>
            <Grid item>
              <Button
                color="primary"
                style={{ background: "white" }}
                onClick={handleOpenEdit}
                disabled={allFiles.length === 0 && allFolders.length === 0}
              >
                Edit Permissions
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                style={{ background: "white" }}
                onClick={handleDownloadMultipleFiles}
                disabled={allFiles.length === 0 && allFolders.length === 0}
              >
                Download
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Popover
        id="upload-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleOpenUploadFileModal}
            >
              Files
            </Typography>
            <Typography
              className={classes.filesandfolder}
              onClick={handleOpenUploadFolderModal}
            >
              Folder
            </Typography>
          </Box>
        </Box>
      </Popover>
      <Menu
        id="new-folder"
        anchorEl={anchorElNew}
        keepMounted
        open={Boolean(anchorElNew)}
        onClose={handleCloseNew}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={createNewFolder}
            >
              Folder
            </Typography>
          </Box>
        </Box>
      </Menu>
      <Box height="3rem" />
      <Grid container>
        <Grid
          container
          item
          style={{ background: "#4a3bff0d" }}
          className={classes.files}
          spacing={1}
          justify="space-between"
        >
          <Grid
            item
            sm={profile.profile_type === "case_manager" ? 6 : 8}
            xs={8}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                color="primary"
                style={{ padding: "5px", visibility: "hidden" }}
              />
              <Typography className={classes.tableHeaderContent}>
                Name
              </Typography>
            </div>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Typography className={classes.tableHeaderContent}>
              Uploaded by
            </Typography>
          </Grid>
          {profile.profile_type === "case_manager" && (
            <Grid item sm={2} xs={4}>
              <Typography className={classes.tableHeaderContent}>
                Modified on
              </Typography>
            </Grid>
          )}
          <Grid item sm={2} xs={4}>
            <Typography className={classes.tableHeaderContent}>
              Uploaded on
            </Typography>
          </Grid>
        </Grid>
        {/* folders and files */}
        {showNewFolderInput && (
          <Grid container className={classes.filesHover} spacing={1}>
            <Grid item lg={7} md={7} sm={7} xs={5}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  color="primary"
                  style={{ padding: "5px", visibility: "hidden" }}
                  // onChange={handleSelectAll}
                />
                <Typography className={classes.tableRowContent}>
                  <span style={{ verticalAlign: "middle" }}>
                    <img src={FolderImage} alt="folder" />
                  </span>
                  <span style={{ marginLeft: "1rem" }}>
                    <input
                      ref={inputRef}
                      value={folderName}
                      style={{ width: "70%" }}
                      onChange={handleFolderNameChange}
                      onBlur={handleOnBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleOnBlur();
                        }
                      }}
                    />
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={3}>
              <Typography className={classes.tableRowContent}>
                {profile.user.first_name} {profile.user.last_name}
              </Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={3}>
              <Typography className={classes.tableRowContent}>
                {formatDate(new Date())}
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
          </Grid>
        )}
        {allFolders.map((folder) => {
          return (
            <FolderView
              folder={folder}
              key={folder.id}
              disputeId={disputeId}
              classes={classes}
              buttonsEnabled={buttonsEnabled}
              reload={reload}
              profile={profile}
              allProfiles={allProfiles}
              parent_folder={parent_folder}
              selectedFolders={selectedFolders}
              handleChangeOnFolderCheckbox={handleChangeOnFolderCheckbox}
              unSelectAll={unSelectAll}
            />
          );
        })}
        {allFiles.map((file) => {
          return (
            <FileView
              file={file}
              key={file.id}
              disputeId={disputeId}
              classes={classes}
              buttonsEnabled={buttonsEnabled}
              reload={reload}
              profile={profile}
              allProfiles={allProfiles}
              parent_folder={parent_folder}
              selectedFiles={selectedFiles}
              handleChangeOnFileCheckbox={handleChangeOnFileCheckbox}
              unSelectAll={unSelectAll}
            />
          );
        })}
      </Grid>
      <Box height="4rem" />

      {/* folder and files atop */}
      {openmodalUploadFolder && (
        <UploadFolder
          openmodal={openmodalUploadFolder}
          handleCloseModal={handleCloseUploadFolderModal}
          disputeId={disputeId}
          folder_id={folder_id}
          allProfiles={allProfiles}
          handleCloseModalRefresh={handleCloseUploadFolderModalAndRefresh}
          parent_folder={parent_folder}
          profile={profile}
        />
      )}
      {openmodalUploadFile && (
        <UploadFile
          openmodal={openmodalUploadFile}
          handleCloseModal={handleCloseUploadFileModal}
          disputeId={disputeId}
          folder_id={folder_id}
          allProfiles={allProfiles}
          handleCloseModalRefresh={handleCloseUploadFileModalAndRefresh}
          parent_folder={parent_folder}
          profile={profile}
        />
      )}
      {openmodal && (
        <EditPermissions
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          handleCloseModalRefresh={handleCloseModalAndRefresh}
          files={filteredFiles}
          folders={filteredFolders}
          allProfiles={allProfiles}
          parent_folder={parent_folder}
          disputeId={disputeId}
          profile={profile}
          viewPermission={[]}
          editPermission={[]}
        />
      )}
    </Box>
  );
}
