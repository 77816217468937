const CONFIG_FIELD_SELECT_TYPE = "select";
const CONFIG_FIELD_INPUT_BASE = "input";
const CONFIG_FIELD_RADIO_TYPE = 'radio';
const CONFIG_FIELD_UPLOAD_TYPE = 'upload'

function CLAIMANT_DETAILS_PAGE(apiResp, proceed){
    return ({
        claimantDetails: {
            title: "Claimant Details",
            intlKey: "tiac.claimant",
            proceed: proceed,
            text: true,
            fields: [
                {
                    name: "claimant_email",
                    intlKey: "user.email",
                    errorIntlKey: "tiac.invalidemail",
                    title: "1. Email Address:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'example@example.com',
                    required: true,
                    proceed,
                    text: true,
                    number: 1, 
                    email: true,
                },
                {
                    name: "claimant_name",
                    intlKey: "user.fullname",
                    title: "2. Full name:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    text: true,
                    errorIntlKey: "tiac.requiredfullname",
                    number: 2
                },
                {
                    name: "claimant_nationality",
                    intlKey: "user.nationalityclaimant",
                    title: "3. Nationality:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    text: true,
                    errorIntlKey: "tiac.requirednationality",
                    number: 3
                },
                {
                    name: "claimant_address",
                    intlKey: "user.address",
                    title: "4. Address:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    text: true,
                    errorIntlKey: "tiac.requiredappartment",
                    number: 4
                },
                {
                    name: "claimant_city",
                    intlKey: "user.city",
                    title: "City (Town)",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredcity",
                },
                {
                    name: "claimant_region",
                    intlKey: "user.regionandstate",
                    title: "Region and State",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredregion",
                },
                {
                    name: "claimant_postal_code",
                    intlKey: "user.postalcode",
                    title: "Postal code",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredpostal",
                },
                {
                    name: "claimant_country",
                    intlKey: "user.country",
                    title: "Country",
                    type: CONFIG_FIELD_SELECT_TYPE,
                    options: apiResp || [],
                    text: false,
                    errorIntlKey: "tiac.requiredcountry",
                },
                {
                    name: 'claimant_phone_number',
                    intlKey: "user.telephone",
                    title: '5. Telephone number:',
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'Please enter only numerals and a valid contact number',
                    required: true,
                    proceed,
                    text: true,
                    errorIntlKey: "tiac.requiredphone",
                    number: 5
                },
                {
                    name: "claimant_fax",
                    intlKey: "user.fax",
                    title: '6. Fax:',
                    type: CONFIG_FIELD_INPUT_BASE,
                    proceed,
                    text: true,
                    number: 6
                }
            ]
        },
    })
};

function CLAIMANT_COUNSEL_DETAILS_PAGE(apiResp, proceed){
    return ({
        claimantsCounselDetails: {
            title: "Legal representative of the Claimant (if applicable)",
            intlKey: "tiac.legalreprclaimant",
            proceed: proceed,
            text: true,
            fields: [
                {
                    name: "claimant_lawyer_email",
                    intlKey: "user.email",
                    title: "1. Email Address:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'example@example.com',
                    required: true,
                    proceed,
                    text: true,
                    errorIntlKey: "tiac.invalidemail",
                    number: 1,
                },
                {
                    name: "claimant_lawyer_name",
                    intlKey: "user.fullname",
                    title: "2. Full name:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    text: true,
                    errorIntlKey: "tiac.requiredfullname",
                    number: 2
                },
                {
                    name: "claimant_lawyer_address",
                    intlKey: "user.addressandstreet",
                    title: "4. Address:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    number: 3
                },
                {
                    name: "claimant_lawyer_city",
                    intlKey: "user.city",
                    title: "City (Town)",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredcity",
                },
                {
                    name: "claimant_lawyer_region",
                    intlKey: "user.regionandstate",
                    title: "Region and State",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredregion",
                },
                {
                    name: "claimant_lawyer_postal_code",
                    intlKey: "user.postalcode",
                    title: "Postal code",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredpostal",
                },
                {
                    name: "claimant_country",
                    intlKey: "user.country",
                    title: "Country",
                    type: CONFIG_FIELD_SELECT_TYPE,
                    options: apiResp || [],
                    text: false,
                    errorIntlKey: "tiac.requiredcountry",
                },
                {
                    name: 'claimant_lawyer_phone_number',
                    intlKey: "user.telephone",
                    title: '5. Telephone number:',
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'Please enter only numerals and a valid contact number',
                    required: true,
                    proceed,
                    text: true,
                    errorIntlKey: "tiac.requiredphone",
                    number: 4
                },
                {
                    name: "claimant_lawyer_fax",
                    intlKey: "user.fax",
                    title: '6. Fax:',
                    type: CONFIG_FIELD_INPUT_BASE,
                    proceed,
                    text: true,
                    number: 5
                }
            ]
        },
    })
};

function RESPONDENT_DETAILS_PAGE(apiResp, proceed){
    return ({
        respondentDetails: {
            title: "3. Respondent Details",
            intlKey: "tiac.respondent",
            proceed: proceed,
            text: false,
            fields: [
                {
                    name: "respondent_email",
                    intlKey: "user.email",
                    title: "3.1. Email Address:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'example@example.com',
                    required: true,
                    proceed,
                    text: false,
                    errorIntlKey: "tiac.invalidemail",
                    number: 1
                },
                {
                    name: "respondent_name",
                    intlKey: "user.fullname",
                    title: "3.2. Full name:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    text: false,
                    errorIntlKey: "tiac.requiredfullname",
                    number: 2
                },
                {
                    name: "respondent_nationality",
                    intlKey: "user.nationalityrespondent",
                    title: "3.3. Nationality:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    text: false,
                    errorIntlKey: "tiac.requirednationality",
                    number: 3
                },
                {
                    name: "respondent_address",
                    intlKey: "user.address",
                    title: "3.4. Address:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    errorIntlKey: "tiac.requiredappartment",
                    number: 4
                },
                {
                    name: "respondent_city",
                    intlKey: "user.city",
                    title: "City (Town)",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredcity",
                },
                {
                    name: "respondent_region",
                    intlKey: "user.regionandstate",
                    title: "Region and State",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredregion",
                },
                {
                    name: "respondent_postal_code",
                    intlKey: "user.postalcode",
                    title: "Postal code",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                    errorIntlKey: "tiac.requiredpostal",
                },
                {
                    name: "respondent_country",
                    intlKey: "user.country",
                    title: "Country",
                    type: CONFIG_FIELD_SELECT_TYPE,
                    options: apiResp || [],
                    text: false,
                    errorIntlKey: "tiac.requiredcountry",
                },
                {
                    name: 'respondent_phone_number',
                    intlKey: "user.telephone",
                    title: '3.5. Telephone number:',
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'Please enter only numerals and a valid contact number',
                    required: true,
                    proceed,
                    text: false,
                    errorIntlKey: "tiac.requiredphone",
                    number: 5
                },
                {
                    name: "respondent_fax",
                    title: '3.6. Fax:',
                    intlKey: "user.fax",
                    type: CONFIG_FIELD_INPUT_BASE,
                    proceed,
                    text: false,
                    number: 6
                }
            ]
        },
    })
};

function RESPONDENT_COUNSEL_DETAILS_PAGE(apiResp, proceed){
    return ({
        respondentCounselDetails: {
            title: "4. Legal representative of the Respondent (if applicable)",
            intlKey: "tiac.legalreprrespondent",
            proceed: proceed,
            text: false,
            fields: [
                {
                    name: "respondent_lawyer_email",
                    title: "1. Email Address:",
                    intlKey: "user.email",
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'example@example.com',
                    required: true,
                    proceed,
                    text: false,
                    errorIntlKey: "tiac.invalidemail",
                    number: 1

                },
                {
                    name: "respondent_lawyer_name",
                    intlKey: "user.fullname",
                    title: "2. Full name:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    text: false,
                    number: 2
                },
                {
                    name: "respondent_lawyer_address",
                    intlKey: "user.address",
                    title: "3. Address:",
                    type: CONFIG_FIELD_INPUT_BASE,
                    required: true,
                    proceed,
                    number: 3
                },
                {
                    name: "respondent_lawyer_city",
                    intlKey: "user.city",
                    title: "City (Town)",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                },
                {
                    name: "respondent_lawyer_region",
                    intlKey: "user.regionandstate",
                    title: "Region and State",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                },
                {
                    name: "respondent_lawyer_postal_code",
                    intlKey: "user.postalcode",
                    title: "Postal code",
                    type: CONFIG_FIELD_INPUT_BASE,
                    text: false,
                },
                {
                    name: "respondent_lawyer_country",
                    intlKey: "user.country",
                    title: "Country",
                    type: CONFIG_FIELD_SELECT_TYPE,
                    options: apiResp || [],
                    text: false,

                },
                {
                    name: 'respondent_lawyer_phone_number',
                    intlKey: "user.telephone",
                    title: '4. Telephone number:',
                    type: CONFIG_FIELD_INPUT_BASE,
                    subTitle: 'Please enter only numerals and a valid contact number',
                    required: true,
                    proceed,
                    text: false,
                    number: 4
                },
                {
                    name: "respondent_lawyer_fax",
                    intlKey: "user.fax",
                    title: '5. Fax:',
                    type: CONFIG_FIELD_INPUT_BASE,
                    proceed,
                    text: false,
                    number: 5
                }
            ]
        },
    })
};

function ADDITIONAL_DETAILS_PAGE(apiResp) {
    let resp=[];
    for(let i=0;i <apiResp?.length;i++){
        resp[i]= {
            name: `newanswer${i+1}`+'-'+apiResp?.[i]?.id,
            title: apiResp?.[i]?.name,
            type: CONFIG_FIELD_INPUT_BASE,
            required: true,
            text: false,
            number: "7." + (i+1) + ".",
            intlKey: `tiac.additional${i+1}`,
            errorIntlKey: `tiac.required`
        }
    }
    return ({
        additionalPageDetails: {
            title: "7. Additional information",
            intlKey: "tiac.additionalheading",
            text: false,
            fields: resp
        },
    })
};

export default {
    CLAIMANT_DETAILS_PAGE,
    CLAIMANT_COUNSEL_DETAILS_PAGE,
    RESPONDENT_DETAILS_PAGE,
    RESPONDENT_COUNSEL_DETAILS_PAGE,
    ADDITIONAL_DETAILS_PAGE,
    CONFIG_FIELD_SELECT_TYPE,
    CONFIG_FIELD_INPUT_BASE,
    CONFIG_FIELD_RADIO_TYPE,
    CONFIG_FIELD_UPLOAD_TYPE
}