import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import {
  ARBITRATOR,
  ARBITRATOR_RV,
  MEDIATOR,
  SECRETARIAT,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import DashboardDropDown from "features/header/DashboardDropdown";
import HambergerMenu from "features/header/HamburgerMenu";
import LanguageMenu from "features/header/LanguageMenu";
import ProfileDropdown from "features/header/ProfileDropdown";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { isDev, isHotStar, isMuthoot, isStar, isTiacStatic } from "Routes/Routes";
import DevSelectPlatform from "./DevSelectPlatform";
import WebLogo from "images/TiacWebLogo.png";
import { removeSessionStorage } from "features/tiac_header_waiting/TiacAppHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    "& img": {
      width: "10rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: "7rem",
      },
    },
  },
}));

const AppHeader = ({ profile }) => {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.token);
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === MEDIATOR ||
    profileType === SECRETARIAT;

  const loggedIn = token !== "" ? true : false;
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let MenuItems;
  if (loggedIn) {
    MenuItems = (
      <Fragment>
        <Fragment>
          {isProfessionalAccount && !profile.profile.is_tiac_profile ? (
            <DashboardDropDown profileType={profileType} />
          ) : (
            <Button
              color="inherit"
              onClick={() => {
                history.push("/");
                removeSessionStorage();
              }}
            >
              <FormattedMessage id="nav.dashboard" />

              {/* Dashboard */}
            </Button>
          )}
        </Fragment>

        <Box width="2rem"></Box>

        <ProfileDropdown />
      </Fragment>
    );
  } else {
    MenuItems = (
      <Button
        color="inherit"
        onClick={() => {
          history.push("/login");
        }}
      >
        <FormattedMessage id="auth.login" />
        {/* Login */}
      </Button>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={handleDrawerOpen}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.title}>
            {!isHotStar && !isStar && !isMuthoot && (
              <Link to="/">
                <img
                  src={isTiacStatic ? WebLogo : "/Logo_Colour.png"}
                  alt=""
                  onClick={(e) => {
                    if (!isHotStar && !isStar && !isMuthoot) removeSessionStorage();
                  }}
                />
              </Link>
            )}
          </div>
          <Box width="2rem"></Box>
          {isDev && <DevSelectPlatform />}
          {profileType != "admin" && profileType != "sub_admin" && (
            <>
              <LanguageMenu />
            </>
          )}

          <Box width="2rem"></Box>

          <Hidden smDown>{MenuItems}</Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <HambergerMenu
          open={open}
          handleDrawerClose={handleDrawerClose}
          isProfessionalAccount={isProfessionalAccount}
          profileType={profileType}
        />
      </Hidden>
    </div>
  );
};

export default AppHeader;
