import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import Axios from "axios";
import FormattedMessage from "features/common/TranslatedMessage";
import {
  blockInvalidChar,
  getProfileTiacDispute,
  getTempDetails,
  StyledTextFieldTiac,
} from "features/TempTiacFormV3/ClaimantPage/questions";
import { updateDisputeTiac } from "features/TempTiacFormV3/UploadPage/questions";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCountries } from "slices/profileSlice";
import { Tiaccreateprofile } from "slices/userSlice";
import * as Yup from "yup";
import SaveMyProgress from "../TiacRFAForm/SaveMyProgress";
import {claimantPageConstants} from '../constants/index';
import ConfigRow from '../constants/ConfigRow';
import getFieldValue from "../constants/getFieldValue";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },
  select: {
    "&:after": {
      borderBottomColor: "#2F80ED",
    },
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // "font-size": "18px",
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    color: "#2C3345",
    padding: "1rem",
    fontFamily: `'Inter',sans-serif`,
  },

  text2: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },
  text3: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

const SignupSchemaRequired = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage id="tiac.invalidemail" />)
    .required(<FormattedMessage id="tiac.requiredemail" />),
  full_name: Yup.string().required(
    <FormattedMessage id="tiac.requiredfullname" />
  ),
  address: Yup.string().required(
    <FormattedMessage id="tiac.requiredappartment" />
  ),
  city: Yup.string().required(<FormattedMessage id="tiac.requiredcity" />),
  region: Yup.string().required(<FormattedMessage id="tiac.requiredregion" />),
  postal_code: Yup.string().required(
    <FormattedMessage id="tiac.requiredpostal" />
  ),
  phone_number: Yup.string().required(
    <FormattedMessage id="tiac.requiredphone" />
  ),
});

var signUpData = {};

export async function saveTempDetailsClaimant(data) {
  return await instance.post(`/tiacsavetempdetails/`, {
    dispute_id: data.dispute_id,
    claimant_lawyer_email: data.claimant_lawyer_email,
    claimant_lawyer_name: data.claimant_lawyer_name,
    claimant_lawyer_address: data.claimant_lawyer_address,
    claimant_lawyer_city: data.claimant_lawyer_city,
    claimant_lawyer_region: data.claimant_lawyer_region,
    claimant_lawyer_postal_code: data.claimant_lawyer_postal_code,
    claimant_lawyer_country: data.claimant_lawyer_country,
    claimant_lawyer_phone_number: data.claimant_lawyer_phone_number,
    claimant_lawyer_fax: data.claimant_lawyer_fax,
  });
}

export default function ClaimantLawyerPage({
  proceed,
  setProceed,
  setActiveStep,
  submitDetailsSignal,
  checkSaveMyProgressAndResumeLater,
  setcheckSaveMyProgressAndResumeLater,
  showSaveCheckBox,
  handleOnClickCheckBox,
  afterPasswordSubmitDetailsSignal,
  setIsAfterScreenVisible,
}) {
  const [fieldsInput, setFieldsInput] = useState({});
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();
  const [loading, setLoading] = useState(false);
  const {CLAIMANT_COUNSEL_DETAILS_PAGE} = claimantPageConstants;

  let language = localStorage.getItem("i8_lang");

  function getProfiles(email, cancelToken) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/tiacanonymousemailautofill/?email=${email}`,
          { cancelToken: cancelToken.token }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const getAutofillProfiles = (email) => {
    try {
      // let email = e.traget.value;
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email &&
          res.results[0].profile_type == "lawyer_rv"
        ) {
          let profile = res.results[0];
          profile["name"] =
            res.results[0].user.first_name +
            " " +
            res.results[0].user.last_name;
          setFetchedProfile(profile);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = useCallback(debounce(getAutofillProfiles, 500), []);

  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [allCountries, setallCountries] = useState([]);
  const [country, setCountry] = useState("-");
  const [countryError, setcountryError] = useState();

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  let id = localStorage.getItem("dispute_id");
  let localstorageEmail = localStorage.getItem("email");
  useEffect(() => {
    if (localstorageEmail && !id) {
      setFieldsInput({...fieldsInput, claimant_lawyer_email: localstorageEmail})
      handleEmailChange(localstorageEmail);
    }
  }, [id, localstorageEmail]);

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  useEffect(() => {
    if (id)
      getTempDetails(id).then((res) => {
        let fetchTempProfile = {};
        if (res && res.data) {
          setFieldsInput({...res.data, claimant_lawyer_email: localstorageEmail});
        }
      });
  }, [id]);
  
  React.useEffect(() => {
    if (!proceed) {
      getDisputeTiac(localstorageEmail, id)
        .then((res) => {
          if (res.data.results[0].id) {
            getProfileTiacDispute(
              localstorageEmail,
              id,
              "claimant_lawyer"
            ).then((res) => {
              if (res.data.user) {
                // setFetchedProfile(res.data);
                // autoFill(res.data);
              }
            });
          } else history.push("/RFA2");
        })
        .catch(() => {
          history.push("/RFA2");
        });
    }
    // if (id && proceed)
    //   getProfileTiacDispute(localstorageEmail, id, "claimant_lawyer").then(
    //     (res) => {
    //       setFetchedProfile(res.data);
    //       autoFill(res.data);
    //     }
    //   );
  }, []);

  async function TiaccreateprofileDisputeId(data) {
    return await instance.post("/tiaccreateprofile/", data);
  }

  useEffect(() => {
    if (submitDetailsSignal) {
      let {
        claimant_lawyer_name,
        claimant_lawyer_address,
        claimant_lawyer_city,
        claimant_lawyer_region,
        claimant_lawyer_postal_code,
        claimant_lawyer_phone_number,
        claimant_lawyer_email,
        claimant_lawyer_fax,
      } = fieldsInput;

      signUpData = {};
      if (location.pathname.toUpperCase() == "/RFA2") {
        if (claimant_lawyer_name) signUpData["full_name"] = claimant_lawyer_name;
        if (claimant_lawyer_address) signUpData["address"] = claimant_lawyer_address;
        if (claimant_lawyer_city) signUpData["city"] = claimant_lawyer_city;
        if (claimant_lawyer_region) signUpData["region"] = claimant_lawyer_region;
        if (claimant_lawyer_postal_code) signUpData["postal_code"] = claimant_lawyer_postal_code;
        // if (phone_number) signUpData["phone_number"] = phone_number;
        if (claimant_lawyer_fax) signUpData["fax"] = claimant_lawyer_fax;
        if (claimant_lawyer_email) signUpData["email"] = claimant_lawyer_email;
        if (country && country != "-") signUpData["country"] = country;
        Tiaccreateprofile(signUpData, "lawyer_rv")
          .then((res) => {
            let tempData = {
              dispute_id: res.data.id,
              email: claimant_lawyer_email,
              full_name: claimant_lawyer_name,
              address: claimant_lawyer_address,
              city: claimant_lawyer_city,
              region: claimant_lawyer_region,
              postal_code: claimant_lawyer_postal_code,
              country: country,
              phone_number: claimant_lawyer_phone_number,
              fax: claimant_lawyer_fax,
            };

            saveTempDetailsClaimant(tempData).then(() => {
              localStorage.setItem("email", claimant_lawyer_email);
              localStorage.setItem("dispute_id", res.data.id);
              localStorage.setItem("full_name", claimant_lawyer_name);
              localStorage.setItem("phone_number", claimant_lawyer_phone_number);
              updateDisputeTiac(
                {
                  id: res.data.id,
                  rv_claimant_lawyer_step_complete: false,
                },
                localstorageEmail
              ).then(() => {
                history.push("/resumerfa2");
              });
            });
          })
          .catch((error) => {
            console.log("er", error.response);
            alert(error?.response?.data?.message);
          });
      } else if (!proceed) {
        let tempData = {
          dispute_id: id,
          email: claimant_lawyer_email,
          full_name: claimant_lawyer_name,
          address: claimant_lawyer_address,
          city: claimant_lawyer_city,
          region: claimant_lawyer_region,
          postal_code: claimant_lawyer_postal_code,
          country: country,
          phone_number: claimant_lawyer_phone_number,
          fax: claimant_lawyer_fax,
        };
        claimant_lawyer_phone_number = "" + claimant_lawyer_phone_number;
        if (
          claimant_lawyer_email?.trim() == "" ||
          claimant_lawyer_name?.trim() == "" ||
          claimant_lawyer_address?.trim() == "" ||
          claimant_lawyer_city?.trim() == "" ||
          claimant_lawyer_region?.trim() == "" ||
          claimant_lawyer_postal_code?.trim() == "" ||
          country == "-" ||
          claimant_lawyer_phone_number?.trim() == ""
        ) {
          updateDisputeTiac(
            {
              id: id,
              rv_claimant_lawyer_step_complete: false,
            },
            localstorageEmail
          ).then(() => {
            saveTempDetailsClaimant(tempData).then(() => {
              history.push("/resumerfa2?random=" + Math.random());
            });
          });
        } else {
          saveTempDetailsClaimant(tempData).then(() => {
            history.push("/resumerfa2?random=" + Math.random());
          });
        }
      } else {
        let data = {
          email: localstorageEmail,
          profile_type: "lawyer_rv",
          for: "claimant",
          dispute_id: id,
        };
        TiaccreateprofileDisputeId(data)
          .then((res) => {
            let tempData = {
              dispute_id: res.data.id,
              email: claimant_lawyer_email,
              full_name: claimant_lawyer_name,
              address: claimant_lawyer_address,
              city: claimant_lawyer_city,
              region: claimant_lawyer_region,
              postal_code: claimant_lawyer_postal_code,
              country: country,
              phone_number: claimant_lawyer_phone_number,
              fax: claimant_lawyer_fax,
            };
            claimant_lawyer_phone_number = "" + claimant_lawyer_phone_number;
            if (
              claimant_lawyer_email?.trim() == "" ||
              claimant_lawyer_name?.trim() == "" ||
              claimant_lawyer_address?.trim() == "" ||
              claimant_lawyer_city?.trim() == "" ||
              claimant_lawyer_region?.trim() == "" ||
              claimant_lawyer_postal_code?.trim() == "" ||
              country == "-" ||
              claimant_lawyer_phone_number?.trim() == ""
            ) {
              updateDisputeTiac(
                {
                  id: id,
                  rv_claimant_lawyer_step_complete: false,
                },
                localstorageEmail
              ).then((resp) => {
                saveTempDetailsClaimant(tempData).then(() => {
                  history.push("/resumerfa2?random=" + Math.random());
                });
              });
            } else {
              saveTempDetailsClaimant(tempData).then(() => {
                history.push("/resumerfa2?random=" + Math.random());
              });
            }
          })
          .catch((error) => {
            alert(error?.response?.data?.message);
          });
      }
    }
  }, [submitDetailsSignal]);

  useEffect(() => {
    if (afterPasswordSubmitDetailsSignal) {
      let {
        claimant_lawyer_name,
        claimant_lawyer_address,
        claimant_lawyer_city,
        claimant_lawyer_region,
        claimant_lawyer_postal_code,
        claimant_lawyer_phone_number,
        claimant_lawyer_email,
        claimant_lawyer_fax,
      } = fieldsInput;
      let data = {
        email: localstorageEmail,
        profile_type: "lawyer_rv",
        for: "claimant",
        dispute_id: id,
      };
      TiaccreateprofileDisputeId(data)
        .then((res) => {
          let tempData = {
            dispute_id: res.data.id,
            email: claimant_lawyer_email,
            full_name: claimant_lawyer_name,
            address: claimant_lawyer_address,
            city: claimant_lawyer_city,
            region: claimant_lawyer_region,
            postal_code: claimant_lawyer_postal_code,
            country: country,
            phone_number: claimant_lawyer_phone_number,
            fax: claimant_lawyer_fax,
          };
          claimant_lawyer_phone_number = "" + claimant_lawyer_phone_number;
          if (
            claimant_lawyer_email?.trim() == "" ||
            claimant_lawyer_name?.trim() == "" ||
            claimant_lawyer_address?.trim() == "" ||
            claimant_lawyer_city?.trim() == "" ||
            claimant_lawyer_region?.trim() == "" ||
            claimant_lawyer_postal_code?.trim() == "" ||
            country == "-" ||
            claimant_lawyer_phone_number?.trim() == ""
          ) {
            updateDisputeTiac(
              {
                id: id,
                rv_claimant_lawyer_step_complete: false,
              },
              localstorageEmail
            ).then((resp) => {
              saveTempDetailsClaimant(tempData).then(() => {
                setIsAfterScreenVisible(true);
              });
            });
          } else {
            saveTempDetailsClaimant(tempData).then(() => {
              setIsAfterScreenVisible(true);
            });
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.message);
        });
    }
  }, [afterPasswordSubmitDetailsSignal]);

  function checkFieldError(fieldName, condition, errorText, hasError) {
    if (condition) {
        setFieldsInput((prevFieldsInput) => ({
            ...prevFieldsInput,
            error: {
                ...prevFieldsInput.error,
                [fieldName]: errorText,
            }
        }));
        return [...hasError, fieldName]
    }

    return hasError;
  }

  function handleSubmitDetails(){
    //field validation
    let hasError = [];
    hasError = checkFieldError("claimant_lawyer_email", !fieldsInput.claimant_lawyer_email, "Invalid email", hasError);
    hasError = checkFieldError("claimant_lawyer_name", !fieldsInput.claimant_lawyer_name, "Full name is required", hasError);
    hasError = checkFieldError("claimant_lawyer_address", !fieldsInput.claimant_lawyer_address, "Apartment and Street is required", hasError);
    hasError = checkFieldError("claimant_lawyer_city", !fieldsInput.claimant_lawyer_city, "City is required", hasError);
    hasError = checkFieldError("claimant_lawyer_region", !fieldsInput.claimant_lawyer_region, "Region and State is required", hasError);
    hasError = checkFieldError("claimant_lawyer_postal_code", !fieldsInput.claimant_lawyer_postal_code, "Postal code is required", hasError);
    hasError = checkFieldError("claimant_lawyer_phone_number", !fieldsInput.claimant_lawyer_phone_number, "Phone Number is required", hasError);
    hasError = checkFieldError("claimant_country", !fieldsInput.claimant_country, "Country is required", hasError);

    if (hasError.length) return false;
    let updatedData = {
      "dispute_id": id,
      "claimant_lawyer_email": fieldsInput.claimant_lawyer_email,
      "claimant_lawyer_name": fieldsInput.claimant_lawyer_name,
      "claimant_lawyer_address": fieldsInput.claimant_lawyer_address,
      "claimant_lawyer_city": fieldsInput.claimant_lawyer_city,
      "claimant_lawyer_region": fieldsInput.claimant_lawyer_region,
      "claimant_lawyer_postal_code": fieldsInput.claimant_lawyer_postal_code,
      "claimant_lawyer_phone_number": fieldsInput.claimant_lawyer_phone_number,
      "claimant_lawyer_fax": fieldsInput.claimant_lawyer_fax,
    }
    if (!proceed) {
      localStorage.setItem("full_name", fieldsInput.claimant_lawyer_name);
      localStorage.setItem("phone_number", fieldsInput.claimant_lawyer_phone_number);
      updateDisputeTiac(
        {
          id: id,
          rv_claimant_lawyer_step_complete: true,
        },
        localstorageEmail
      ).then(() => {
        saveTempDetailsClaimant(updatedData).then(() => {
          setActiveStep(2);
        });
      });
    } else if (location.pathname == "/resumerfa2") {
      TiaccreateprofileDisputeId(updatedData)
        .then((res) => {
          let tempData = {
            dispute_id: res.data.id,
            email: fieldsInput.claimant_lawyer_email,
            full_name: fieldsInput.claimant_lawyer_name,
            address: fieldsInput.claimant_lawyer_address,
            city: fieldsInput.claimant_lawyer_city,
            region: fieldsInput.claimant_lawyer_region,
            postal_code: fieldsInput.claimant_lawyer_postal_code,
            country: fieldsInput.claimant_lawyer_country,
            phone_number: fieldsInput.claimant_lawyer_phone_number,
            fax: fieldsInput.claimant_lawyer_fax,
          };
          localStorage.setItem("full_name", fieldsInput.claimant_lawyer_name);
          localStorage.setItem("phone_number", fieldsInput.claimant_lawyer_phone_number);
          updateDisputeTiac(
            {
              id: res.data.id,
              rv_claimant_choose_step_complete: true,
            },
            localstorageEmail
          ).then(() => {
            saveTempDetailsClaimant(tempData).then(() => {
              if (proceed) setActiveStep(1);
              else setActiveStep(2);
            });
          });
        })
        .catch((error) => {
          alert(error?.response?.data?.message);
          setLoading(false);
        });
      } else {
          let tempData = {
            email: fieldsInput.claimant_lawyer_email,
            full_name: fieldsInput.claimant_lawyer_name,
            address: fieldsInput.claimant_lawyer_address,
            city: fieldsInput.claimant_lawyer_city,
            region: fieldsInput.claimant_lawyer_region,
            postal_code: fieldsInput.claimant_lawyer_postal_code,
            country: fieldsInput.claimant_lawyer_country,
            phone_number: fieldsInput.claimant_lawyer_phone_number,
            fax: fieldsInput.claimant_lawyer_fax,
          };
          Tiaccreateprofile(tempData, "lawyer_rv")
            .then((res) => {
              let tempData = {
                dispute_id: res.data.id,
                claimant_lawyer_email: fieldsInput.claimant_lawyer_email,
                claimant_lawyer_name: fieldsInput.claimant_lawyer_name,
                claimant_lawyer_address: fieldsInput.claimant_lawyer_address,
                claimant_lawyer_city: fieldsInput.claimant_lawyer_city,
                claimant_lawyer_region: fieldsInput.claimant_lawyer_region,
                claimant_lawyer_postal_code: fieldsInput.claimant_lawyer_postal_code,
                claimant_lawyer_country: fieldsInput.claimant_lawyer_country,
                claimant_lawyer_phone_number: fieldsInput.claimant_lawyer_phone_number,
                claimant_lawyer_fax: fieldsInput.claimant_lawyer_fax,
              };
              updateDisputeTiac(
                {
                  id: res.data.id,
                  rv_claimant_choose_step_complete: true,
                },
                localstorageEmail
              );
              saveTempDetailsClaimant(tempData).then(() => {
                localStorage.setItem("email", fieldsInput.claimant_lawyer_email);
                localStorage.setItem("dispute_id", res.data.id);
                localStorage.setItem("full_name", fieldsInput.claimant_lawyer_name);
                localStorage.setItem("phone_number", fieldsInput.claimant_lawyer_phone_number);
                history.push("/resumerfa2?find_active_step=true");
              });
            })
            .catch((error) => {
              console.log("er", error.response);
              alert(error?.respons?.data?.message);
              setLoading(false);
            });
        }
  }
  
  function handleFieldChange(value, name) {
    setFieldsInput((prevFieldsInput) => ({
        ...prevFieldsInput,
        ...(getFieldValue(value, fieldsInput, name))
    }));
  }

  return (
    <>
      <ConfigRow
        fieldsInput={fieldsInput}
        cards={CLAIMANT_COUNSEL_DETAILS_PAGE(allCountries, proceed)}
        onChange={handleFieldChange}
        classes={classes}
      />
       <Grid container justify="center" spacing={3}>
        {location.pathname.toUpperCase() == "/RFA2" && (
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setProceed(false);
              }}
            >
              <Typography className={classes.btnTextBlack}>
                <FormattedMessage id="tiac.back" />
              </Typography>
            </Button>
          </Grid>
        )}
        <Grid item>
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              style={{ background: "#2F80ED" }}
              onClick={handleSubmitDetails}
              disabled={loading}
            >
              <Typography className={classes.btnTextWhite}>
                <FormattedMessage id="buttons.proceed" />
              </Typography>
            </Button>
          </Box>
        </Grid>
        {showSaveCheckBox && (
          <Grid item>
            <SaveMyProgress
              checkSaveMyProgressAndResumeLater={
                checkSaveMyProgressAndResumeLater
              }
              setcheckSaveMyProgressAndResumeLater={
                setcheckSaveMyProgressAndResumeLater
              }
              handleOnClickCheckBox={handleOnClickCheckBox}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
