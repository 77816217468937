import {
    Box,
    Button,
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
  } from "@material-ui/core";
  import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
  import { ARBITRATION, ARBITRATOR, IO_INPROGRESS, MD_INPROGRESS, MEDIATOR, RESPONDENT, RESPONDENT_INPROGRESS } from "app/constants";
  import FormattedMessage from "features/common/TranslatedMessage";
  import React, { useState } from "react";
  import { useHistory } from "react-router-dom";
  import { DocGenerator } from "utils";
  import { downloadLegalDoc, updateDispute, updateMuthootStatus } from "slices/disputeSlice";
  import { formatDate } from "utils";
//   import { getClaimantDetails } from "../ArbitratorHotstar/NewArbitratorSingleDispute";
  import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { getClaimantDetails } from "features/singleDispute/ArbitratorHotstar/NewArbitratorSingleDispute";
import { BorderBottom, KeyboardBackspace } from "@material-ui/icons";
import instance from "api/globalaxios";
import { format } from "date-fns";
import { useBackground } from "contexts/BackgroundContext";
import { calc_days } from "./DisputesMuthoot";
import downloadIcon from '../../../images/download-02.svg';
import { updateCase } from "slices/casesSlice";
import { useDispatch } from "react-redux";
import ShowProgress from "./ShowProgress";
  
  const useStyles = makeStyles((theme) => ({
		root: {
			marginTop: "2rem",
			[theme.breakpoints.down("sm")]: {
				marginTop: "1.5rem",
			},
			[theme.breakpoints.down("xs")]: {
				marginTop: "1rem",
			},
		},
    text: {
      fontFamily: `"Inter", "sans-serif"`,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    blue: {
      fontFamily: `"Inter", "sans-serif"`,
      color: "#2F80ED",
      lineHeight: "24px",
    },
    fonts: {
      lineHeight: "24px",
      fontSize: "16px",
      fontFamily: `"Inter", "sans-serif"`,
    },
    item: {
      "&:hover": {
        background: "#EEEEEE",
      },
    },
    calenderBtn: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#2F80ED",
      textTransform: "none",
      borderRadius: "2px",
      border: "1px solid #2F80ED",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
      width: "100%",
      maxWidth: "100%",
      "&:hover": {
        background: "#2F80ED",
        color: "#FFFFFF",
        border: "1px solid #2F80ED",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
			hov: {
				"&:hover": {
					color: "#333333",
				},
			},
			btntext: {
				color: theme.palette.primary.main,
				fontFamily: "Satoshi-Bold",
				textTransform: "none",
			},
    },
		claimantDetailsContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			padding: "2rem",
			borderRadius: '4px',
			backgroundColor: '#f4f8ff',
		},
		claimantDetailsHeading: {
			marginBottom: "1rem",
			fontSize:'1.5rem',
			lineHeight:'1.8rem',
			color:'black'
		},
		claimantDetailsStatus: {
			borderRadius:'4px',
			padding:'8px',
			width:'fit-content',
			lineHeight:1,
		},
		claimantDetailsRow: {
			padding: '10px 0',
			borderBottom: '1px solid #d7dbe2',
			display: 'flex',
			gap: '10px',
			[theme.breakpoints.between("sm",'md')]: {
				display: 'block',
      },
		},
		disputeStatusContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			padding: "0.8rem 1rem 0.8rem 3rem",
		},
		disputeStatusHeading: {
			marginBottom: "0.5rem",
			fontSize:'1.3rem',
			lineHeight:'1.8rem',
			color:'black'
		},
		button: {
			color: 'black',
			"&:disabled": {
				backgroundColor: '#0000001f',
				color: '#00000042',
			}
		}
  }));

	export async function getClaimantAnswerData(id) {
		return await instance({
			method: "get",
			url: "/claimantanswerdata/" + id + "/",
		});
	}

	export async function getCaseFiles(id) {
		return await instance({
			method: "get",
			url: "api/casefiles/" + id + "/",
		});
	}

  export default function DisputeDetailsMuthoot({
    activeDispute,
    activeCase,
    profile,
  }) {
    const classes = useStyles();
    const history = useHistory();
		const {setBackground} = useBackground();
    const dispatch = useDispatch();

		const IS_MEDIATOR = profile?.profile_type === MEDIATOR;
    const IS_ARBITRATOR = profile?.profile_type === ARBITRATOR;
    const IS_ADMIN = profile?.profile_type === 'sub_admin';
		const MUTHOOT_STATUS = activeDispute?.muthoot_status;
		const IS_END_USER = profile?.profile_type === 'general';
		const IS_PRINCIPAL_NODAL = activeCase?.type === 'nodal';
  
    const [loading, setLoading] = React.useState(true);
    const [claimant, setClaimant] = React.useState("");
		const [claimantAnswers, setClaimantAnswers] = React.useState('');
		const [timeLines, setTimeLines] = React.useState('');
		const [isPrincipalNodalResponded, setIsPrincipalNodalResponded] = useState(false);
    React.useEffect(() => {
			setBackground('white');
      getClaimantDetails(activeDispute.id).then((res) => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.profile_type == "general") {
            setClaimant(element);
            setLoading(false);
            break;
          }
        }
      });
			getClaimantAnswerData(activeDispute?.id).then((res) => {
				const tempObject = res.data.reduce(
					(obj, item) => Object.assign(obj, { [item.question_text]: {question_id:item.question_id, question: item.question_text, answer:item.answer_value} }), {}
				);
				setClaimantAnswers(tempObject);
			})
			getCaseFiles(activeDispute?.id).then((res)=>{
				const caseFiles = res.data;
				const tempObj = {
					created: activeDispute.created,
					gro: caseFiles.find(file => file.type === 'respondent')?.review_complete_date || caseFiles.find(file => file.type === 'nodal')?.review_complete_date || undefined,
					io: caseFiles.find(file => file.type === 'io')?.review_complete_date || undefined,
					md: caseFiles.find(file => file.type === 'md')?.review_complete_date || undefined,
				}
				setTimeLines(tempObj);
				setIsPrincipalNodalResponded(caseFiles.find(file => file.type === 'nodal')?.review_complete_date ? true : false);
			})
    }, []);

    const download = (e) => {
      e.stopPropagation();
      DocGenerator(activeDispute.id, "claimant");
    };

		const handleDownloadResponse = () => {
			const profile_type = activeDispute?.forward_md ? 'md' : activeDispute?.forward ? 'io' : MUTHOOT_STATUS === 'GRO_Accept_nodal' ? 'nodal' : 'respondent';
			DocGenerator(activeDispute.id, profile_type);
		}

		const handleAcceptAndReject = async (disputeId, status) => {
			const data = {
				status : status
			}
			dispatch(updateMuthootStatus(disputeId,data));
		}

		function ComplainantDetails() {
			return (
				<Paper className={classes.claimantDetailsContainer}>
					<Typography className={classes.claimantDetailsHeading}>
						{activeDispute.name.split("&")[0]}
					</Typography>
					<Typography className={classes.claimantDetailsStatus} style={activeDispute.status === 'closed' ? {backgroundColor: '#caf4d1'} : {backgroundColor:'#ffefeb'}}>
						{activeDispute.status === 'closed' ? 'Complaint Resolved' : 'Complaint in progress'}
					</Typography>
					<Box className={classes.claimantDetailsRow}>
						<Grid
							container
							justify="space-between"
							spacing={1}
						>
							<Grid item xs={6}>
								<Typography className={classes.boldText}>
									Complaint ID
								</Typography>
							</Grid>
							<Grid item xs={6} style={{textAlign:'right'}}>
								<Typography>
									{activeDispute?.id}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.claimantDetailsRow}>
						<Grid
							container
							justify="space-between"
							spacing={1}
						>
							<Grid item xs={6}>
								<Typography className={classes.boldText}>
									Email
								</Typography>
							</Grid>
							<Grid item xs={6} style={{textAlign:'right'}}>
								<Typography>
									{claimant?.user.email}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.claimantDetailsRow}>
						<Grid
							container
							justify="space-between"
							spacing={1}
						>
							<Grid item xs={6}>
								<Typography className={classes.boldText}>
									Phone No.
								</Typography>
							</Grid>
							<Grid item xs={6} style={{textAlign:'right'}}>
								<Typography>
									{claimant?.phone_number ? claimant?.phone_number : '---'}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.claimantDetailsRow}>
						<Grid
							container
							justify="space-between"
							spacing={1}
						>
							<Grid item xs={6}>
								<Typography className={classes.boldText}>
									Type
								</Typography>
							</Grid>
							<Grid item xs={6} style={{textAlign:'right'}}>
								<Typography style={{textTransform: 'capitalize'}}>
									{activeDispute?.grievance_type ? activeDispute?.grievance_type : '---'}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.claimantDetailsRow}>
						<Grid
							container
							justify="space-between"
							spacing={1}
						>
							<Grid item xs={6}>
								<Typography className={classes.boldText}>
									Loan Account No.
								</Typography>
							</Grid>
							<Grid item xs={6} style={{textAlign:'right'}}>
								<Typography>
									{claimantAnswers['Please enter your loan account number with Muthoot Finance']?.answer}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.claimantDetailsRow}>
						<Grid
							container
							justify="space-between"
							spacing={1}
						>
							<Grid item xs={6}>
								<Typography className={classes.boldText}>
									Loan Type
								</Typography>
							</Grid>
							<Grid item xs={6} style={{textAlign:'right'}}>
								<Typography>
									{claimantAnswers["Loan Type"]?.answer}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.claimantDetailsRow}>
						<Grid
							container
							justify="space-between"
							spacing={1}
						>
							<Grid item xs={6}>
								<Typography className={classes.boldText}>
									Branch
								</Typography>
							</Grid>
							<Grid item xs={6} style={{textAlign:'right'}}>
								<Typography>
									{claimantAnswers['Please add the name of the Muthoot Finance Branch where you encountered difficulty.']?.answer}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.claimantDetailsRow} style={{border:'none',marginTop:'0.5rem'}}>
						<Button
							variant="outlined"
							fullWidth
							style={{color:'black', backgroundColor: 'white'}}
							startIcon={<img src={downloadIcon} style={{width:'1.3rem'}} alt=""/>}
              onClick={download}
						>
							Download Complaint
						</Button>
						<Box style={{height:'1rem'}}/>
						{IS_END_USER && (
							<Button
								className={classes.button}
								variant="outlined"
								fullWidth
								style={{backgroundColor: 'white'}}
								startIcon={<img src={downloadIcon} style={{width:'1.3rem'}} alt=""/>}
								onClick={handleDownloadResponse}
								disabled={activeDispute.status !== 'closed'}
							>
								Download Response
							</Button>
						)

						}
					</Box>
				</Paper>
			);
		}

		function TrackProgress() {
			return (
				<Paper className={classes.disputeStatusContainer}>
					<Typography className={classes.disputeStatusHeading}>
						{activeDispute.status === 'closed' ? 'Resolved' : 'In Progress'}
					</Typography>
					<Box style={{display:'flex',alignItems:'center',gap:'1rem'}}>
						<Typography style={{color:'#4b4e56',fontFamily: 'Satoshi-Bold'}}>
							Updated
						</Typography>
						<Typography>
							{format(new Date(activeDispute?.modified), "dd MMMM, y, h:mm a")}
						</Typography>
					</Box>
					<Box>
						<ShowProgress activeDispute={activeDispute} MUTHOOT_STATUS={MUTHOOT_STATUS} IS_END_USER={IS_END_USER} timeLines={timeLines} isPrincipalNodalResponded={isPrincipalNodalResponded}/>
					</Box>
				</Paper>
			);
		}

		const handleClickRecordResponse = () => {
			dispatch(
				updateCase({ proposed_flow: ARBITRATION, id: activeCase.id })
			)
			.then((response) => {
				let disputePayload = {
					id: activeDispute.id,
					status: IS_ARBITRATOR ? IO_INPROGRESS : IS_MEDIATOR ? MD_INPROGRESS : RESPONDENT_INPROGRESS,
					flow_type: ARBITRATION,
				};
				dispatch(updateDispute(disputePayload));
			})
			.catch((err) => {
				console.log(err);
			});
		}
  
    if (loading) return <CircularCenteredLoader />;
  
    return (
      <Grid container justify="center" style={{backgroundColor: 'white', height:'100%'}}>
				<Grid item lg={11} md={10} sm={11} xs={11} className={classes.root}>
					<Button
						disableElevation
						disableFocusRipple
						disableRipple
						disableTouchRipple
						style={{ backgroundColor: "transparent", cursor: "default" }}
					>
						<Grid container spacing={1}>
							<Grid item>
								<Typography
									className={classes.btntext}
									style={{ color: "black", cursor: 'pointer' }}
									onClick={() => {
										history.goBack();
									}}
								>
									<KeyboardBackspace />
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									className={`${classes.btntext} ${classes.hov}`}
									style={{ cursor: "pointer" }}
									onClick={() => {
										history.push("/");
									}}
								>
									Dashboard
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									className={classes.btntext}
									style={{ color: "black" }}
								>
									/
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									className={`${classes.btntext} ${classes.hov}`}
									style={{ cursor: "pointer" }}
									onClick={() => {
										history.push(`/disputes/${activeDispute.id}/`);
									}}
								>
									{activeDispute.name}
								</Typography>
							</Grid>
						</Grid>
					</Button>
					<Grid container style={{justifyContent:'space-between', gap:'2rem', alignItems:'center'}}>
            <Grid item xs>
              <Typography className="lineClamp" style={{fontFamily:'Satoshi-Bold'}}>{activeDispute?.description}</Typography>
            </Grid>
						<Grid item style={{display:'flex',gap:'1rem'}}>
							<Box style={{display:'flex',alignItems:'center',gap:'1rem'}}>
								<Typography>
									{format(new Date(activeDispute?.created), "dd MMMM, y, h:mm a")}
								</Typography>
								<Typography>
									({calc_days(activeDispute?.created)===0 ? 'Today' : calc_days(activeDispute?.created) + ' day ago'})
								</Typography>
							</Box>
							{!IS_ADMIN && !IS_END_USER && (
								<>
									{IS_MEDIATOR ? (
										<>
											<Button
												variant="contained"
												color="primary"
												style={{padding:'5px 15px'}}
												disabled={MUTHOOT_STATUS === 'MD_IO' || MUTHOOT_STATUS === 'MD_GRO'}
												onClick={() => handleAcceptAndReject(activeDispute.id,'Agree with GRO')}
											>
												{(MUTHOOT_STATUS === 'MD_GRO') ? 'Agreed with GRO' : 'Agree with GRO'}
											</Button>
											<Button
												className={classes.button}
												variant="outlined"
												// style={{color: 'black'}}
												disabled={MUTHOOT_STATUS === 'MD_IO' || MUTHOOT_STATUS === 'MD_GRO'}
												onClick={() => handleAcceptAndReject(activeDispute.id,'Agree with IO')}
											>
												{(MUTHOOT_STATUS === 'MD_IO') ? 'Agreed with IO' : 'Agree with IO'}
											</Button>
										</>
									) : (
										<>
											<Button
												variant="contained"
												color="primary"
												style={{padding:'5px 15px'}}
												disabled={activeDispute.status === 'closed' || activeCase?.case_status === 'review_complete' || MUTHOOT_STATUS === 'GRO_Accept' || MUTHOOT_STATUS === 'GRO_Accept_nodal' || ((MUTHOOT_STATUS === 'Forward' || MUTHOOT_STATUS === 'Forward_nodal') && !IS_ARBITRATOR) ||
													(IS_ARBITRATOR && (MUTHOOT_STATUS === 'IO_Reject' || MUTHOOT_STATUS === 'Forward_MD'))
												}
												onClick={() => handleAcceptAndReject(activeDispute.id,'Accept')}
											>
												{(MUTHOOT_STATUS === 'GRO_Accept' || MUTHOOT_STATUS === 'GRO_Accept_nodal' || (IS_ARBITRATOR && MUTHOOT_STATUS === 'Forward_MD')) ? 'Accepted' : 'Accept'}
											</Button>
											<Button
												className={classes.button}
												variant="outlined"
												// style={{color: 'black'}}
												disabled={activeDispute.status === 'closed' || activeCase?.case_status === 'review_complete' || MUTHOOT_STATUS === 'GRO_Accept' || MUTHOOT_STATUS === 'GRO_Accept_nodal' || ((MUTHOOT_STATUS === 'Forward' || MUTHOOT_STATUS === 'Forward_nodal') && !IS_ARBITRATOR) ||
													(IS_ARBITRATOR && (MUTHOOT_STATUS === 'IO_Reject' || MUTHOOT_STATUS === 'Forward_MD'))
												}
												onClick={() => handleAcceptAndReject(activeDispute.id,'Reject')}
											>
												{((!IS_ARBITRATOR && (MUTHOOT_STATUS === 'Forward' || MUTHOOT_STATUS === 'Forward_nodal')) || (IS_ARBITRATOR && MUTHOOT_STATUS === 'IO_Reject')) ? 'Rejected' : 'Reject'}
											</Button>
										</>
									)}
									<Button
										className={classes.button}
										variant="outlined"
										// style={{color: 'black'}}
										disabled={MUTHOOT_STATUS === null || activeCase?.case_status === 'review_complete' ||
											(IS_PRINCIPAL_NODAL && (MUTHOOT_STATUS === 'GRO_Accept' || MUTHOOT_STATUS === 'Forward')) ||
											(!IS_PRINCIPAL_NODAL && (MUTHOOT_STATUS === 'GRO_Accept_nodal' || MUTHOOT_STATUS === 'Forward_nodal')) ||
											(IS_ARBITRATOR && MUTHOOT_STATUS === 'Forward') || (IS_MEDIATOR && MUTHOOT_STATUS === 'Forward_MD')
										}
										onClick={handleClickRecordResponse}
									>
										Record Response
									</Button>
								</>
							)}
						</Grid>
					</Grid>
					<Box style={{height:'2rem'}}/>
					<Grid container spacing={2}>
						<Grid item lg={6} md={6} sm={6} xs={12} style={{ display: "flex" }}>
							<ComplainantDetails />
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12} style={{ display: "flex" }}>
							<TrackProgress />
						</Grid>
					</Grid>
				</Grid>
      </Grid>
    );
  }
  