import React from "react";

import SingleDisputeDetails from "../SingleDisputeDetails";
import ClaimantWaiting from "./ClaimantWaiting";
import { RESPONDENT_INPROGRESS } from "app/constants";

export default function ClaimantFlowSteps({
  activeDispute,
  activeCase,
  profile,
}) {
  function getStepContent(activeDispute, activeCase, profile) {
    switch (activeDispute.status) {
      case "new":
        if (activeCase.case_status !== "review_complete") {
          return (
            <SingleDisputeDetails
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          );
        } else {
          return <ClaimantWaiting disputeName={activeDispute.name} />;
        }
      case RESPONDENT_INPROGRESS:
        return <ClaimantWaiting disputeName={activeDispute.name} />;
    }
  }

  return (
    <React.Fragment>
      {getStepContent(activeDispute, activeCase, profile)}
    </React.Fragment>
  );
}
