import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ProfileType } from "Admin/Sebi/ViewProfileRow";
import React, { useState } from "react";
import { getCountries } from "slices/profileSlice";


const useStyles = makeStyles(() => ({
  boldtext: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
  text: {
    fontWeight: "500",
    fontSize: "1.2rem",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
}));

export default function EditProfileRow({ profileData }) {
  const classes = useStyles();
  const haveProfile = profileData.profile ? true : false;

  const firstname = profileData.user.first_name;
  const lastname = profileData.user.last_name;
  const phone = haveProfile
    ? profileData.profile.phone_number
    : profileData.phone_number;

  const email = profileData.user.email;
  const address = haveProfile
    ? profileData.profile.address
    : profileData.address;

  const city = haveProfile ? profileData.profile.city : profileData.city;

  const region = haveProfile ? profileData.profile.region : profileData.region;

  const code = haveProfile
    ? profileData.profile.postal_code
    : profileData.postal_code;

  const profileType = haveProfile
    ? profileData.profile.profile_type
    : profileData.profile_type;

  const [country, setCountry] = useState(
    haveProfile ? profileData.profile.country : profileData.country
  );
  // const [country, setCountry] = useState("-");
  const [allCountries, setallCountries] = useState([]);

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  allCountries.map((item) => {
    if (item.id == country) {
      setCountry(item.name);
    }
  });

  return (
    <>
      <Grid container justify="center">
        <Grid item md={9} sm={12} xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                First Name :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{firstname}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Last Name :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{lastname}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Phone Number :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{phone}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Email : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{email}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Apartment and Street :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{address}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> City : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{city}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Region : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{region}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Postal_Code :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{code}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Country : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{country}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Profile Type :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
              <ProfileType profileType={profileType}/>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
