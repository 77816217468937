import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { default as Alert, default as MuiAlert } from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import { CLAIMANT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  isCDR,
  isDigipub,
  isHotStar,
  isMuthoot,
  isNbf,
  isStar,
  isViacom,
  isVikalp,
  isWebnyay,
} from "Routes/Routes";
import { createCaseAnonymous } from "slices/casesSlice";
import { getCompanyProductsAnonymous } from "slices/companySlice";
import { createDisputeAnonymous, getRealhost } from "slices/disputeSlice";
import * as Yup from "yup";
import CreateCompany from "./CreateCompany";

function Aler(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },

  formContainer: {
    padding: "2rem",
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

const Intro = ({ name, phoneNo, email, id, companies, getCompanies }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const caseLoading = useSelector((state) => state.cases.loading);
  const disputeLoading = useSelector((state) => state.disputes.loading);
  const profile = useSelector((state) => state.profile, shallowEqual);
  let history = useHistory();
  const [error, setError] = useState();

  const [companyProducts, setCompanyProducts] = React.useState([]);
  const [createCompanyModal, setCreateCompanyModal] = useState(false);
  const CreateCompanyModalOpen = () => {
    setCreateCompanyModal(true);
  };
  const CreateCompanyModalClose = (company_id) => {
    setCreateCompanyModal(false);
    getCompanies();
    formik.setFieldValue("company", company_id);
    formik.setFieldValue("company_product", null);
    formik.setFieldValue("company_product_type", null);
    setCompanyProducts([]);
    getCompanyProductsAnonymous(company_id).then((response) => {
      setCompanyProducts(response);
    });
  };

  const handleCreateCompanyCloseModal = () => {
    formik.setFieldValue("company", null);
    formik.setFieldValue("company_product", null);
    formik.setFieldValue("company_product_type", null);
    setCreateCompanyModal(false);
  };

  // debounced submit handler for general user as claimant
  const debouncedOnSubmit = debounce((values) => {
    sessionStorage.setItem("email", values.email);
    let userT, profileT;
    dispatch(getUserProfile(values.email)).then((res) => {
      if (res.profiles.length < 1) {
        // alert("No profile found.");
        createGuestSignUpAnonymous(values)
          .then((response) => {
            console.log("response", response.data);
            // setProfile(response.data);
            // setUser(response.data.user);
            profileT = response.data;
            userT = response.data.user;
            let name =
              userT.first_name +
              " " +
              userT.last_name +
              " vs " +
              companies.find((c) => c.id == values.company).name;
            let description = name;
            let disputeBody = {
              name: name,
              company_product: values.company_product,
              description: description,
              platform_type:
                isHotStar || isStar || isMuthoot || isWebnyay || isViacom || isDigipub
                  ? "grievance"
                  : isCDR || isVikalp
                  ? "cdr"
                  : isNbf
                  ? "nbf"
                  : "rv",
            };

            let createCaseBody = {
              summary: description,
              description: description,
              profile: profileT.id,
              type: CLAIMANT,
            };

            dispatch(createDisputeAnonymous(disputeBody, values.email))
              .then((response) => {
                createCaseBody.dispute = response;
                sessionStorage.setItem("disputeId", response);
                return dispatch(
                  createCaseAnonymous(createCaseBody, values.email)
                );
              })
              .then((response) => {
                let respondentCaseBody = {
                  summary: createCaseBody.summary,
                  description: createCaseBody.description,
                  type: "respondent",
                  dispute: createCaseBody.dispute,
                };

                return dispatch(
                  createCaseAnonymous(respondentCaseBody, values.email)
                );
              })
              .then((response) => {
                history.push(`/dispute`);
              })
              // .then(() => {
              //   dispatch(testApi());
              //   dispatch(testApi2());
              // })
              .catch((err) => {
                //console.log(err);
              });
          })
          .catch((error) => {
            console.log("error.response.data", error.response.data);
            if (error?.response?.data?.email) {
              setError(error.response.data.email[0]);
            } else if (error?.response?.data?.phone_number) {
              setError(error.response.data.phone_number[0]);
            }
          });
      } else {
        if (res.profiles.length > 1) {
          alert("Multiple profiles");
          return 0;
        } else {
          // setProfile(res.profiles[0]);
          // setUser(res.profiles[0].user);
          profileT = res.profiles[0];
          userT = res.profiles[0].user;
          let name =
            userT.first_name +
            " " +
            userT.last_name +
            " vs " +
            companies.find((c) => c.id == values.company).name;
          let description = name;
          let disputeBody = {
            name: name,
            company_product: values.company_product,
            description: description,
            platform_type:
              isHotStar || isStar || isMuthoot || isWebnyay || isViacom || isDigipub
                ? "grievance"
                : isCDR || isVikalp
                ? "cdr"
                : isNbf
                ? "nbf"
                : "rv",
          };

          let createCaseBody = {
            summary: description,
            description: description,
            profile: profileT.id,
            type: CLAIMANT,
          };

          dispatch(createDisputeAnonymous(disputeBody, values.email))
            .then((response) => {
              createCaseBody.dispute = response;
              sessionStorage.setItem("disputeId", response);
              return dispatch(
                createCaseAnonymous(createCaseBody, values.email)
              );
            })
            .then((response) => {
              let respondentCaseBody = {
                summary: createCaseBody.summary,
                description: createCaseBody.description,
                type: "respondent",
                dispute: createCaseBody.dispute,
              };

              return dispatch(
                createCaseAnonymous(respondentCaseBody, values.email)
              );
            })
            .then((response) => {
              history.push(`/dispute`);
            })
            .catch((err) => {
              //console.log(err);
            });
        }
      }
    });
  }, 1000);

  function getUserProfile(email) {
    return async () => {
      try {
        const response = await instance.get(
          `/api/userappointmentprofilelistanonymous/${null}/${email}/${null}`
        );
        return response.data;
      } catch (error) {
        //console.log(error);
      }
    };
  }

  async function createGuestSignUpAnonymous(values) {
    var arr,
      first_name,
      last_name,
      name = values.name;
    arr = name.split(" ");
    first_name = arr[0];
    const index = arr.indexOf(first_name);
    if (index > -1) {
      arr.splice(index, 1);
    }
    last_name = arr.join(" ");
    let phone = "" + values.phone;
    return await instance.post(
      "/anonymouscreateuser/",
      {
        phone_number: phone.trim() == "" ? null : phone,
        vua_id: values.vua_id,
        profile_type: "general",
        group: getRealhost(),
        user: {
          email: values.email,
          password: "Test@123",
          first_name: first_name,
          last_name: last_name,
        },
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  }

  const DisputeSchema = Yup.object().shape(
    {
      email: Yup.string().required("Email is required").email("Invalid email"),
      // phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
      company: Yup.number()
        .required("Adversary is required")
        .typeError("Please select a company from the dropdown."),
      company_product_type: Yup.string()
        .required()
        .typeError("Please select an option from the dropdown"),
      company_product: Yup.number()
        .required()
        .typeError("Please select an option from the dropdown"),
      name: Yup.string().required("Name is required"),
      // phone: Yup.string().when(["email", "vua_id"], {
      //   is: (email, vua_id) => !email && !vua_id,
      //   then: Yup.string().required("Please enter one of the three fields"),
      // }),
      // email: Yup.string()
      //   .when(["phone", "vua_id"], {
      //     is: (phone, vua_id) => !phone && !vua_id,
      //     then: Yup.string().required("Please enter one of the three fields"),
      //   })
      //   .email("Invalid email"),
      // vua_id: Yup.string().when(["phone", "email"], {
      //   is: (phone, email) => !phone && !email,
      //   then: Yup.string().required("Please enter one of the three fields"),
      // }),
    },
    [
      // ["phone", "email"],
      // ["phone", "vua_id"],
      // ["email", "vua_id"],
    ]
  );

  const formik = useFormik({
    initialValues: {
      name:
        profile?.profile?.user?.first_name +
          profile?.profile?.user?.last_name || "",
      email: profile?.profile?.user?.email || "",
      phone: profile?.profile?.phone_number || "",
      vua_id: id ? id : "",
      company: null,
      company_product_type: null,
      company_product: null,
    },
    onSubmit: debouncedOnSubmit,
    validationSchema: DisputeSchema,
  });

  return (
    <Box p="2rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10} lg={6} md={8}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                // onClick={testFunc}
              >
                <FormattedMessage id="pages.intro.vikalpintro" />
              </Typography>
              <Box height="1rem"></Box>
              <Box p="0rem 2rem">
                <form onSubmit={formik.handleSubmit} data-testid="intro-form">
                  <TextField
                    id="name"
                    name="name"
                    label={"Name *"}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.errors.name && formik.touched.name ? (
                    <Alert severity="error">{formik.errors.name}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>
                  <TextField
                    id="email"
                    name="email"
                    label={"Email Address *"}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <Alert severity="error">{formik.errors.email}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>
                  <TextField
                    name="phone"
                    id="phone"
                    label={"Phone Number"}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.errors.phone && formik.touched.phone ? (
                    <Alert severity="error">{formik.errors.phone}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>
                  <TextField
                    id="vua_id"
                    name="vua_id"
                    label={"VUA ID"}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.vua_id}
                  />
                  {formik.errors.vua_id && formik.touched.vua_id ? (
                    <Alert severity="error">{formik.errors.vua_id}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>
                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    data-testid="respondent-select"
                  >
                    <InputLabel id="against">Adversary *</InputLabel>
                    <Select
                      id="company"
                      name="company"
                      value={formik.values.company}
                      onChange={(e) => {
                        formik.setFieldValue("company_product", null);
                        formik.setFieldValue("company_product_type", null);
                        formik.handleChange(e);
                        companies.map((com) => {
                          if (com.id == e.target.value) {
                            // setCompanyName(com.name);
                          }
                        });
                        setCompanyProducts([]);
                        getCompanyProductsAnonymous(e.target.value).then(
                          (response) => {
                            setCompanyProducts(response);
                          }
                        );
                        if (e.target.value == 0) {
                          CreateCompanyModalOpen();
                        }
                      }}
                      labelId="against"
                      label="Adversary"
                    >
                      {companies.map((company, index) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.company && formik.touched.company ? (
                    <Alert severity="error">{formik.errors.company}</Alert>
                  ) : null}

                  <Box height="1rem"></Box>

                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    disabled={companyProducts.length === 0}
                    data-testid="company-product-select"
                  >
                    <InputLabel id="against">Filed by *</InputLabel>
                    <Select
                      id="company_product_type"
                      name="company_product_type"
                      value={formik.values.company_product_type}
                      onChange={(e) => {
                        formik.setFieldValue("company_product", null);
                        formik.handleChange(e);
                      }}
                      labelId="company_product_type"
                      label="Company"
                    >
                      {companyProducts
                        .map((item) => item.type)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )
                        .map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formik.errors.company_product_type &&
                  formik.touched.company_product_type ? (
                    <Alert severity="error">
                      {formik.errors.company_product_type}
                    </Alert>
                  ) : null}

                  <Box height="1rem"></Box>

                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    disabled={companyProducts.length === 0}
                  >
                    <InputLabel id="against">Category *</InputLabel>
                    <Select
                      id="company_product"
                      name="company_product"
                      value={formik.values.company_product}
                      onChange={formik.handleChange}
                      labelId="company_product"
                      label="category"
                      data-testid="company-product-type-select"
                    >
                      {companyProducts
                        .filter(
                          (product) =>
                            product.type === formik.values.company_product_type
                        )
                        .map((product, index) => (
                          <MenuItem
                            key={product.id}
                            value={product.id}
                            data-testid={`company-product-type-item-${index}`}
                          >
                            {product.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formik.errors.company_product &&
                  formik.touched.company_product ? (
                    <Alert severity="error">
                      {formik.errors.company_product}
                    </Alert>
                  ) : null}
                  {error && <Alert severity="error">{error}</Alert>}
                  <Box height="1rem"></Box>
                  <Box display="flex" justifyContent="center">
                    {caseLoading || disputeLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        data-testid="submit-intro-claimant"
                        disabled={caseLoading || disputeLoading}
                      >
                        <FormattedMessage id="buttons.submit" />
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          </Paper>
          <Box height="4rem"></Box>
        </Grid>
        <Grid item xs />
      </Grid>
      <CreateCompany
        openmodal={createCompanyModal}
        handleCloseModal={CreateCompanyModalClose}
        closeModal={handleCreateCompanyCloseModal}
      />
    </Box>
  );
};

export default Intro;
