import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import plusImage from "images/plusrounded.svg";
import rvnotificationOn from "images/rvnotificationOn.svg";
import rvnotificationOff from "images/rvnotificationOff.svg";
import { useDropzone } from "react-dropzone";
import { getFolder, UpdateFolder, UploadFiles, UploadFolders } from "./FileApi";
import { useDispatch } from "react-redux";
import { postActivity } from "slices/activitySlice";
import CustomReactTooltip from "features/CustomToolTip/CustomToolTip";

async function myCustomFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    files.push(file);
  }
  return files;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    position: "absolute",
    left: "50%",
    top: "45%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      top: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      // top: "45%",
    },
  },
  firstText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  selected: {
    background: "#F2F2F2",
    padding: "0.3rem 1.3rem",
  },
  unselected: {
    padding: "0.3rem 1.3rem",
    cursor: "pointer",
  },
  choiceText: {
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    color: "#4F4F4F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  fieldsText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    marginTop: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.3rem",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  inputfield: {
    width: "100%",
    height: "3rem",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    "&:focus": {
      outline: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  dropbox: {
    width: "100%",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "1rem 2px",
    textAlign: "center",
  },
  alertSelected: {
    padding: "0.3rem",
    background: "#E0E0E0",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  alertUnSelected: {
    padding: "0.3rem",
    cursor: "pointer",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  cancel: {
    textDecoration: "underline",
    fontSize: "20px",
    fontWeight: "400",
    color: "#2F80ED",
    lineHeight: "36px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "26px",
    },
  },
  add: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: "36px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  addbtn: {
    background: "#2F80ED",
    marginLeft: "3rem",
    width: "120px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      marginLeft: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
      marginLeft: "1rem",
    },
  },
  permissionsTable: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  permissionsTableRows: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  box: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "20rem",
    cursor: "all-scroll",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "15rem",
    },
  },
  notify: {
    fontSize: "20px",
    color: "#4F4F4F",
    lineHeight: "36px",
    fontFamily: "Roboto",
    textAlign: "center",
    marginTop: "10px",
    textTransform: "capitalize",
  },
  alertOnHeading: {
    fontSize: 8,
    lineHeight: "14px",
    fontWeight: 600,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
  alertOnText: {
    fontSize: 8,
    lineHeight: "14px",
    fontWeight: 300,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
}));

export default function UploadFolder({
  openmodal,
  handleCloseModal,
  disputeId,
  folder_id,
  allProfiles,
  handleCloseModalRefresh,
  parent_folder,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [choice, setChoice] = useState("details");
  const [folderName, setFolderName] = useState("");
  const [alert, setAlert] = useState("on");

  const [uploadedFolderName, setUploadedFolderName] = useState("");
  const [loading, setLoading] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    getFilesFromEvent: (event) => myCustomFileGetter(event),
  });
  const [filesPreview, setFilesPreview] = useState(false);
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFilesPreview(true);
      let t = acceptedFiles[0].webkitRelativePath.split("/");
      setUploadedFolderName(t[0]);
    }
  }, [acceptedFiles]);

  const handleNameChange = (e) => {
    setFolderName(e.target.value);
  };

  const [viewPermissions, setViewPermissions] = useState(
    parent_folder ? parent_folder.view_permission : []
  );
  const [editPermissions, setEditPermissions] = useState(
    parent_folder ? parent_folder.edit_permission : []
  );

  const updatePermissionParentFolder = (parent) => {
    let prevViewPermissions = parent.view_permission;
    let prevDeletePermissions = parent.edit_permission;
    viewPermissions.forEach((element) => {
      if (prevViewPermissions.indexOf(element) == -1) {
        prevViewPermissions.push(element);
      }
    });
    editPermissions.forEach((element) => {
      if (prevDeletePermissions.indexOf(element) == -1) {
        prevDeletePermissions.push(element);
      }
    });
    UpdateFolder(parent.id, {
      view_permission: prevViewPermissions,
      // edit_permission: prevDeletePermissions,
    });
    if (parent.parent_folder) {
      getFolder(parent.parent_folder.id).then((response) => {
        updatePermissionParentFolder(response.data[0]);
      });
    }
  };

  function arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }
  let uploadedFiles = 0;

  const handleUploadFolder = () => {
    // build file and folder structure
    setLoading(true);
    let rootFolder = { files: [], folders: [], folder_name: "root" };
    let size = 0;
    acceptedFiles.forEach((item) => {
      let parent_folder = rootFolder;
      let arr = item.webkitRelativePath.split("/");
      for (let i = 1; i < arr.length; i++) {
        const element = arr[i];
        if (i != arr.length - 1) {
          // folder
          let t = { files: [], folders: [], folder_name: element };
          let exist = false;
          let exist_index = -1;
          for (let p = 0; p < parent_folder.folders.length; p++) {
            const e = parent_folder.folders[p];
            if (e.folder_name == element) {
              exist = true;
              exist_index = p;
              break;
            }
          }
          if (!exist) {
            parent_folder.folders.push(t);
            parent_folder = t;
          } else {
            parent_folder = parent_folder.folders[exist_index];
          }
        } else {
          // file
          parent_folder.files.push(item);
          size += item.size;
        }
      }
    });

    // upload files and folders
    if (parent_folder) {
      if (
        arrayEquals(parent_folder.view_permission, viewPermissions) &&
        arrayEquals(parent_folder.edit_permission, editPermissions)
      ) {
        // no need to change folder permissions
      } else {
        updatePermissionParentFolder(parent_folder);
      }
    } else {
      // no need to change folder permissions
    }

    UploadFolders(
      disputeId,
      folderName,
      folder_id,
      viewPermissions,
      editPermissions
    )
      .then((res) => {
        dispatch(
          postActivity(
            "uploaded a folder",
            size,
            false,
            folderName,
            disputeId,
            viewPermissions,
            null,
            res?.data?.id
          )
        );
        if (acceptedFiles.length == 0) {
          handleCloseModalRefresh();
        } else {
          let f_id = res.data.id;
          let parent = rootFolder;

          uploadFolderRecursive(parent, f_id);
        }
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const uploadFolderRecursive = (parent, parent_folder_id) => {
    uploadFiles(parent, parent_folder_id);
    for (let index = 0; index < parent.folders.length; index++) {
      const p = parent.folders[index];
      UploadFolders(
        disputeId,
        p.folder_name,
        parent_folder_id,
        viewPermissions,
        editPermissions
      ).then((r) => {
        uploadFolderRecursive(p, r.data.id);
      });
    }
  };

  const uploadFiles = (parent, parent_folder_id) => {
    for (let index = 0; index < parent.files.length; index++) {
      const file = parent.files[index];
      UploadFiles(
        disputeId,
        parent_folder_id,
        file,
        viewPermissions,
        editPermissions
      )
        .then((res) => {
          uploadedFiles += 1;
          if (uploadedFiles == acceptedFiles.length) {
            handleCloseModalRefresh();
          }
        })
        .catch((error) => {
          console.log("err", error);
        });
    }
  };

  const body = (
    <Box className={classes.paper}>
      <Typography className={classes.firstText}>Add folder</Typography>
      <Box height="1rem" />
      <Grid container>
        <Grid
          item
          className={
            choice == "details" ? classes.selected : classes.unselected
          }
          onClick={() => {
            if (choice != "details") setChoice("details");
          }}
        >
          <Typography className={classes.choiceText}>Details</Typography>
        </Grid>
        <Grid
          item
          className={
            choice == "permissions" ? classes.selected : classes.unselected
          }
          onClick={() => {
            if (choice != "permissions") setChoice("permissions");
          }}
        >
          <Typography className={classes.choiceText}>Permissions</Typography>
        </Grid>
      </Grid>
      {choice == "details" ? (
        <>
          <Box height="0.5rem" />
          <Grid container className={classes.container} spacing={2}>
            <Grid item sm={3} xs={4}>
              <Typography className={classes.fieldsText}>
                Name of the folder*
              </Typography>
            </Grid>
            <Grid item sm={9} xs={8}>
              <input
                type="text"
                placeholder="Enter folder name"
                autoFocus
                className={classes.inputfield}
                onChange={handleNameChange}
                value={folderName}
              />
            </Grid>
          </Grid>
          <Box height="1rem" />
          <Grid container className={classes.container} spacing={2}>
            <Grid item sm={3} xs={4}>
              <Typography className={classes.fieldsText}>
                Browse folder
              </Typography>
            </Grid>
            <Grid item sm={9} xs={8}>
              <Box>
                <div
                  {...getRootProps({
                    className: `dropzone ${classes.dropbox}`,
                  })}
                >
                  <input
                    {...getInputProps()}
                    directory=""
                    webkitdirectory=""
                    mozdirectory=""
                    type="file"
                  />
                  {!filesPreview ? (
                    <>
                      <img src={plusImage} alt="add" />
                    </>
                  ) : (
                    <Typography>{uploadedFolderName}</Typography>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box height="1rem" />
          <Grid container justify="flex-end" className={classes.container}>
            <Grid item className={classes.notify} justify="center">
              <Typography>Notify others?</Typography>
            </Grid>

            <Box width="1rem" />
            <Grid
              item
              className={
                alert == "on" ? classes.alertSelected : classes.alertUnSelected
              }
              onClick={() => {
                if (alert != "on") setAlert("on");
              }}
            >
              <img
                src={rvnotificationOn}
                alt="alert on"
                data-tip
                data-for="alerton"
              />
              <CustomReactTooltip id="alerton">
                <Box width="12rem">
                  <Typography className={classes.alertOnHeading}>
                    High-Priority Documents?
                  </Typography>
                  <Typography className={classes.alertOnText}>
                    Notify the Parties involved in this Arbitration by an Email
                    update
                  </Typography>
                </Box>
              </CustomReactTooltip>
            </Grid>
            <Grid
              item
              className={
                alert == "off" ? classes.alertSelected : classes.alertUnSelected
              }
              onClick={() => {
                if (alert != "off") setAlert("off");
              }}
              style={{ marginLeft: "1rem" }}
            >
              <img
                src={rvnotificationOff}
                alt="alert off"
                data-tip
                data-for="alertoff"
              />

              <CustomReactTooltip id="alertoff">
                <Box width="12rem">
                  <Typography className={classes.alertOnText}>
                    Upload documents on the platform without sending an Email
                    update to the Parties involved.
                  </Typography>
                </Box>
              </CustomReactTooltip>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box height="1rem" />
          <Grid container className={classes.container} justify="center">
            <Box
              style={{
                background: "#F2F2F2",
                width: "100%",
                border: "1px solid #E0E0E0",
              }}
            >
              <Box
                style={{
                  textAlign: "center",
                }}
                className={classes.box}
              >
                <Typography className={classes.permissionsTable}>
                  Permissions
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid container className={classes.container} justify="center">
            <Grid container style={{ width: "100%" }}>
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={5}
                xs={5}
                style={{
                  height: "-webkit-fill-available",
                  borderBottom: "1px solid #E0E0E0",
                  borderLeft: "1px solid #E0E0E0",
                  borderRight: "1px solid #E0E0E0",
                }}
              >
                <Box
                  style={{
                    background: "#F2F2F2",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box className={classes.box}>
                    <Typography className={classes.permissionsTable}>
                      Name
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                style={{
                  height: "-webkit-fill-available",
                  borderBottom: "1px solid #E0E0E0",
                  borderRight: "1px solid #E0E0E0",
                }}
              >
                <Box
                  style={{
                    background: "#F2F2F2",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box className={classes.box}>
                    <Typography className={classes.permissionsTable}>
                      Profile type
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                style={{
                  height: "-webkit-fill-available",
                  borderBottom: "1px solid #E0E0E0",
                  borderRight: "1px solid #E0E0E0",
                }}
              >
                <Box
                  style={{
                    background: "#F2F2F2",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    style={{
                      textAlign: "center",
                    }}
                    className={classes.box}
                  >
                    <Typography className={classes.permissionsTable}>
                      View
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                style={{
                  height: "-webkit-fill-available",
                  borderBottom: "1px solid #E0E0E0",
                  borderRight: "1px solid #E0E0E0",
                }}
              >
                <Box
                  style={{
                    background: "#F2F2F2",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    style={{
                      textAlign: "center",
                    }}
                    className={classes.box}
                  >
                    <Typography className={classes.permissionsTable}>
                      Edit
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.scroll}>
            {allProfiles.map((item) => {
              let checkedView = "";
              if (viewPermissions.indexOf(item.user.id) != -1) {
                checkedView = true;
              } else {
                checkedView = false;
              }
              const handleViewCheckbox = (e) => {
                checkedView = e.target.checked;
                if (e.target.checked) {
                  let arr = [...viewPermissions];
                  arr.push(item.user.id);
                  setViewPermissions(arr);
                } else {
                  let arr = [...viewPermissions];
                  let index = arr.indexOf(item.user.id);
                  if (index > -1) {
                    arr.splice(index, 1);
                    setViewPermissions(arr);
                    checkedEdit = false;
                    arr = [...editPermissions];
                    let indexEdit = arr.indexOf(item.user.id);
                    if (indexEdit > -1) {
                      arr.splice(indexEdit, 1);
                      setEditPermissions(arr);
                    }
                  }
                }
              };
              let checkedEdit = "";
              if (editPermissions.indexOf(item.user.id) != -1) {
                checkedEdit = true;
              } else {
                checkedEdit = false;
              }
              const handleEditCheckbox = (e) => {
                checkedEdit = e.target.checked;
                if (e.target.checked) {
                  let arr = [...editPermissions];
                  arr.push(item.user.id);
                  setEditPermissions(arr);
                  if (viewPermissions.indexOf(item.user.id) == -1) {
                    arr = [...viewPermissions];
                    arr.push(item.user.id);
                    setViewPermissions(arr);
                    checkedView = true;
                  }
                } else {
                  let arr = [...editPermissions];
                  const index = arr.indexOf(item.user.id);
                  if (index > -1) {
                    arr.splice(index, 1);
                    setEditPermissions(arr);
                  }
                }
              };
              return (
                <Grid
                  container
                  className={classes.container}
                  justify="center"
                  key={item.user.id}
                >
                  <Grid container>
                    <Grid
                      item
                      xl={5}
                      lg={5}
                      md={5}
                      sm={5}
                      xs={5}
                      style={{
                        height: "-webkit-fill-available",
                        borderBottom: "1px solid #E0E0E0",
                        borderLeft: "1px solid #E0E0E0",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      <Box
                        style={{
                          background: "#F2F2F2",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box className={classes.box}>
                          <Typography className={classes.permissionsTableRows}>
                            {item.user.first_name} {item.user.last_name}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                      style={{
                        height: "-webkit-fill-available",
                        borderBottom: "1px solid #E0E0E0",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      <Box
                        style={{
                          background: "#F2F2F2",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box className={classes.box}>
                          <Typography className={classes.permissionsTableRows}>
                            {item.profile.profile_type}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{
                        height: "-webkit-fill-available",
                        borderBottom: "1px solid #E0E0E0",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      <Box
                        style={{
                          background: "#F2F2F2",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          style={{
                            textAlign: "center",
                          }}
                          className={classes.box}
                        >
                          <input
                            type="checkbox"
                            checked={checkedView}
                            onChange={handleViewCheckbox}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{
                        height: "-webkit-fill-available",
                        borderBottom: "1px solid #E0E0E0",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      <Box
                        style={{
                          background: "#F2F2F2",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          style={{
                            textAlign: "center",
                          }}
                          className={classes.box}
                        >
                          <input
                            type="checkbox"
                            checked={checkedEdit}
                            onChange={handleEditCheckbox}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </>
      )}
      <Box height="1rem" />
      <Grid container justify="flex-end" className={classes.container}>
        <Grid item>
          <Typography className={classes.cancel} onClick={handleCloseModal}>
            Cancel
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.addbtn}
            onClick={handleUploadFolder}
            disabled={folderName.trim() == "" || loading}
          >
            <Typography className={classes.add}>Add</Typography>
          </Button>
        </Grid>
      </Grid>
      <Box height="1rem" />
    </Box>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
