import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import instance from "api/globalaxios";
import Axios from "axios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FormattedMessage from "features/common/TranslatedMessage";
import ClaimantLawyerPage from "features/TempTiacForm/ClaimantLawyerPage";
import ClaimantPage from "features/TempTiacForm/ClaimantPage";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isTiac } from "Routes/Routes";
import * as Yup from "yup";
import { StyledTextFieldTiac } from "../ClaimantPage/questions";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      // height: "90%",
      order: 1,
      marginBottom: "2rem",
      align: "center",
    },
    [theme.breakpoints.down("xs")]: {
      // width: "90%",
      // height: "90%",
      order: 1,
      marginBottom: "2rem",
      align: "center",
    },
  },
  text: {
    fontSize: "18px",
    // fontWeight: "bold",
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  dropdown: {
    color: "white",
    padding: "16px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  heading: {
    textAlign: "left",
    color: "#454545",
    fontFamily: `'Inter',sans-serif`,
    fontSize: "36px",
    // fontWeight: "bold",
    lineHeight: "1.2",
    letterSpacing: "-0.00833em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
  },
}));

export function getProfiles(email, cancelToken) {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        `/api/tiacanonymousemailautofill/?email=${email}`,
        { cancelToken: cancelToken.token }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

const Schema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
});

export default function Form() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [proceed, setProceed] = useState(false);

  const profile = useSelector((state) => state.profile, shallowEqual);
  const [loading, setLoading] = useState(true);
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();

  const autofill = (fetchedProfile) => {
    if (fetchedProfile.profile_type == "general_rv") {
      formik.setFieldValue("choice", "claimant");
    } else if (fetchedProfile.profile_type == "lawyer_rv") {
      formik.setFieldValue("choice", "claimant_lawyer");
    } else {
      console.log(fetchedProfile.profile_type);
    }
  };

  useEffect(() => {
    if (fetchedProfile) autofill(fetchedProfile);
  }, [fetchedProfile]);

  const formik = useFormik({
    initialValues: {
      email: "",
      choice: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      console.log({ values });
      localStorage.setItem("email", values.email);
      setProceed(true);
    },
  });

  const handleEmailChange = (email) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email
        ) {
          setFetchedProfile(res.results[0]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  React.useEffect(() => {
    if (location.pathname.toUpperCase() == "/RFA") {
      localStorage.removeItem("dispute_id");
      localStorage.removeItem("email");
      setLoading(false);
    } else {
      getDisputeTiac(
        localStorage.getItem("email"),
        localStorage.getItem("dispute_id")
      ).then((res) => {
        if (res.data.results[0].created_by.profile_type == "general_rv") {
          formik.setFieldValue("choice", "claimant");
          setProceed(true);
          setLoading(false);
        } else {
          formik.setFieldValue("choice", "claimant_lawyer");
          setProceed(true);
          setLoading(false);
        }
      });
    }
  }, [location]);

  if (!isTiac) {
    history.push("/");
  }

  if (location.pathname.toUpperCase() != "/RFA" && loading)
    return <CircularCenteredLoader />;

  if (proceed) {
    if (formik.values.choice == "claimant") {
      return <ClaimantPage proceed={proceed} setProceed={setProceed} />;
    } else if (formik.values.choice == "claimant_lawyer") {
      return <ClaimantLawyerPage proceed={proceed} setProceed={setProceed} />;
    }
  } else {
    return (
      <Box p="2rem 0 7rem 0">
        <Grid container spacing={3}>
          <Grid item xs></Grid>
          <Grid item xs={5} md={4} sm={5}>
            {/* <img
              className={classes.vectorImage}
              src={TIAC}
              alt="tiac image"
              align="center"
            /> */}
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs></Grid>
          <Grid item xs={10} md={6} sm={10}>
            <Paper className={classes.formContainer}>
              <Typography className={classes.heading} gutterBottom>
                <FormattedMessage id="pages.form.heading" />
              </Typography>
              <Box height="1rem"></Box>
              <Box>
                <Typography className={classes.text}>
                  <FormattedMessage id="pages.form.subheading" />
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                  <Box mt="2rem">
                    <StyledTextFieldTiac
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label={
                        "Email address/Адрес электронной почты/Электрон почта манзили"
                      }
                      required
                      id="email"
                      name="email"
                      value={formik.values.email}
                      autoFocus
                      autoComplete="email"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleEmailChange(e.target.value);
                      }}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <Alert severity="error">{formik.errors.email}</Alert>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ marginTop: "2rem" }}
                  >
                    <Box style={{ width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          You are/Вы/Сиз *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          required
                          name="choice"
                          id="choice"
                          value={formik.values.choice}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={fetchedProfile}
                        >
                          <MenuItem value={"claimant"}>
                            <Typography className={classes.text}>
                              Claimant/истец/даъвогар
                            </Typography>
                          </MenuItem>
                          <MenuItem value={"claimant_lawyer"}>
                            <Typography className={classes.text}>
                              Claimant's Counsel/Представитель
                              истца/Даъвогарнинг вакили
                            </Typography>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box height="2rem"></Box>
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ background: "#2F80ED" }}
                    >
                      <Typography className={classes.btnTextWhite}>
                        <FormattedMessage id="buttons.proceed" />
                      </Typography>
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs />
        </Grid>
      </Box>
    );
  }
}
