import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 2rem",
  },
  secondaryText: {
    color: "#000000",
    whiteSpace: "initial",
    textAlign: "center",
  },
  title: {
    flexGrow: 1,
    "& img": {
      width: "11rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: "7rem",
      },
    },
  },
  guestbtn: {
    flexGrow: 1,
    background: "#101C50",
    borderRadius: "4px 4px 0px 0px",
  },
  typoguest: {
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    textAlign: "left",
  },
  loginbtn: {
    flexGrow: 1,
    background: "rgba(51, 51, 51, 0.06)",
    borderRadius: "4px 4px 0px 0px",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const Signin = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper className={classes.paper}>
        {/* <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs />
          <Grid item xs className={classes.title}>
            <img src="/Viacom_Logo-temp.png" alt="" />
          </Grid>
          <Grid item xs />
        </Grid>
        <Box height="0.8"></Box>

        <Typography className={classes.secondaryText} variant="caption">
          <FormattedMessage id="clientsSpecificPageMsg.secondaryTextHotstar" />
        </Typography> */}

        <Box height="0.8"></Box>
        <Box height="2rem"></Box>
        <Grid container justify="center" spacing={3}>
          <Grid item sm={10} xs={10} style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.guestbtn}
              onClick={() => {
                history.push("/intro");
              }}
            >
              <Grid container>
                <Grid item className={classes.typoguest}>
                  File your first grievance (Guest)
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <Box height="1rem"></Box>
        <Grid container justify="center" spacing={3}>
          <Grid item sm={10} xs={10} style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.loginbtn}
              onClick={() => {
                history.push("/login");
              }}
            >
              <Grid container>
                <Grid item className={classes.typoguest}>
                  {/* Sign in to your Viacom Grievance Redressal Account */}
                  Sign in to your Grievance Redressal Account
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <Box height="0.8"></Box>
        <Box height="2rem"></Box>
      </Paper>

      <Box height="2rem"></Box>
    </Container>
  );
};

export default Signin;
