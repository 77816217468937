import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backbtn: {
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  bigbtn: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "0.4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "15px",
    },
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export default function CaseDisplayProfileList({ profile, caseId, mode }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState(0);

  React.useEffect(() => {
    dispatch(getUserDriveSize(profile.profile.id)).then((res) => {
      setSize(bytesToSize(res.size));
      setLoading(false);
    });
  }, []);

  function getUserDriveSize(profileId) {
    return async () => {
      try {
        const response = await instance.get(
          `/api/getuserdiscsize/?profile=${profileId}&dispute=${caseId}`
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  }

  return (
    <>
      <Paper style={{ padding: "10px 0" }}>
        <Grid container justify="flex-start">
          <Grid item sm={3} xs={3}>
            <Typography className={classes.blueheadingtext}>
              {profile.user.first_name} {profile.user.last_name}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3}>
            {mode == "RV" ? (
              // profile.profile.profile_type == "general_rv" && (
              profile.profile.rv_profile ? (
                <Typography className={classes.blueheadingtext}>
                  {profile.profile.rv_profile}
                </Typography>
              ) : (
                <>
                  {profile.profile.profile_type == "arbitrator_rv" && (
                    <Typography className={classes.blueheadingtext}>
                      Arbitrator
                    </Typography>
                  )}
                  {profile.profile.profile_type == "lawyer_rv" && (
                    <Typography className={classes.blueheadingtext}>
                      Lawyer
                    </Typography>
                  )}
                  {profile.profile.profile_type == "case_manager" && (
                    <Typography className={classes.blueheadingtext}>
                      Case Manager
                    </Typography>
                  )}
                  {profile.profile.profile_type == "witness_rv" && (
                    <Typography className={classes.blueheadingtext}>
                      Witness
                    </Typography>
                  )}
                </>
              )
            ) : (
              // )
              <>
                {profile.profile.profile_type == "general" && (
                  <Typography className={classes.blueheadingtext}>
                    Claimant
                  </Typography>
                )}
                {profile.profile.profile_type == "vendor" && (
                  <Typography className={classes.blueheadingtext}>
                    Respondent
                  </Typography>
                )}
                {profile.profile.profile_type == "arbitrator" && (
                  <Typography className={classes.blueheadingtext}>
                    ADRP
                  </Typography>
                )}
                {profile.profile.profile_type == "case_manager" && (
                  <Typography className={classes.blueheadingtext}>
                    Case Manager
                  </Typography>
                )}
                {profile.profile.profile_type == "lawyer" && (
                  <Typography className={classes.blueheadingtext}>
                    Lawyer
                  </Typography>
                )}
                {profile.profile.profile_type == "witness" && (
                  <Typography className={classes.blueheadingtext}>
                    Witness
                  </Typography>
                )}
              </>
            )}
            {/* <Typography className={classes.blueheadingtext}>
              {profile.profile.profile_type}
            </Typography> */}
          </Grid>
          <Grid item sm={3} xs={3}>
            <Typography
              className={classes.blueheadingtext}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/events/${profile.profile.id}/`);
              }}
            >
              <u>VIEW</u>
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3}>
            <Typography
              className={classes.blueheadingtext}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/files/${profile.profile.id}/${caseId}`);
              }}
            >
              {size}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box height="0.6rem"></Box>
    </>
  );
}
