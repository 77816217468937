import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DocGenerator } from "utils";
import { useDispatch } from "react-redux";
import { updateDispute } from "slices/disputeSlice";
import Popup from "reactjs-popup";
import { isCDR, isHotStar, isMuthoot, isStar, isVikalp } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  disputeName: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default function ArbitratorCommonContent({ activeDispute, step }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );
  const isArbitrator = profileType === "arbitrator";

  const [isDisputeClosed, setIsDisputeClosed] = React.useState(
    activeDispute.status == "closed"
  );
  const [open2, setOpen2] = React.useState(false);

  const openpopup2 = () => {
    setOpen2(true);
  };

  const closeModal2 = () => {
    setOpen2(false);
  };

  const closeGrievence = () => {
    dispatch(updateDispute({ id: activeDispute.id, status: "closed" }));
    setIsDisputeClosed(true);
    setOpen2(false);
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="h2"
            className={classes.disputeName}
          >
            {activeDispute.name}
          </Typography>
          <Typography variant="h6" color="textSecondary" component="p">
            <FormattedMessage id="dispute.startDate" /> :{" "}
            {activeDispute.created.split("T")[0]}
          </Typography>
          <Box height="0.5rem"></Box>
        </Box>
        <Box display="flex">
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              DocGenerator(activeDispute.id, "claimant");
            }}
          >
            {isCDR || isVikalp ? (
              <FormattedMessage id="dispute.statementOfClaim1" />
            ) : (
              <FormattedMessage id="dispute.statementOfClaim" />
            )}
          </Button>
          <Box width="1rem"></Box>
          {activeDispute.flow_type != "mediation" &&
            activeDispute.flow_type != "negotiation" && (
              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  DocGenerator(activeDispute.id, "respondent");
                }}
              >
                {isCDR || isVikalp ? (
                  <FormattedMessage id="dispute.statementOfDefence1" />
                ) : (
                  <FormattedMessage id="dispute.statementOfDefence" />
                )}
              </Button>
            )}
        </Box>
      </Box>
      <Box flexGrow={1}></Box>
      <Hidden mdUp>
        <Box height="3rem"></Box>
      </Hidden>
      <Box>
        {activeDispute.status !== "complete" ? (
          <>
            <Box width="1rem"></Box>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    history.push(`/disputes/${activeDispute.id}/chat`);
                  }}
                  disabled={step < 1}
                >
                  <FormattedMessage id="common.goToHearingRooms" />
                </Button>
              </Grid>
              {(isHotStar || isStar || isMuthoot) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      openpopup2();
                    }}
                    disabled={step < 1 || isDisputeClosed}
                  >
                    <FormattedMessage id="dispute.closeDispute" />
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        ) : null}
      </Box>
      <Popup
        open={open2}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal2}
        position="center"
        modal
        nested
        contentStyle={{
          background: "white",
          width: "330px",
          height: "185px",
        }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
      >
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Typography
            style={{
              fontSize: "20px",
              color: "#333333",
              "font-weight": "bold",
              "line-height": "23px",
              "letter-spacing": "0.0015em",
            }}
          >
            Are you sure?
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontSize: "16px",
              color: "#828282",
              "line-height": "28px",
            }}
          >
            Are you sure you want to close this dispute?
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeModal2}
              >
                CANCEL
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeGrievence}
              >
                CLOSE
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </Box>
  );
}
