import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import { backgrounds } from "contexts/BackgroundContext";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useBodyBackgroundChange } from "hooks";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Files from "./Files";

export default function FilesContainer() {
  useBodyBackgroundChange(backgrounds.WHITE);
  const dispatch = useDispatch();
  let company = sessionStorage.getItem("company");

  const location = useLocation();
  const arr = location.pathname.split("/");
  const profileId = arr[2];
  const caseId = arr[3];

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    dispatch(getUserFiles(profileId));
  }, []);

  function getUserFiles(profileId) {
    return async (dispatch) => {
      try {
        if (caseId) {
          let response;
          if (company == "hotstar") {
            response = await instanceHotstar.get(
              `/api/rvdocumentperprofile/?profile=${profileId}&dispute=${caseId}`
            );
          } else if (company == "dpcgc") {
            response = await instanceDPCGC.get(
              `/api/rvdocumentperprofile/?profile=${profileId}&dispute=${caseId}`
            );
          } else {
            response = await instance.get(
              `/api/rvdocumentperprofile/?profile=${profileId}&dispute=${caseId}`
            );
          }
          setFiles(response.data);
          setLoading(false);
          return response.data;
        } else {
          let response;
          if (company == "hotstar") {
            response = await instanceHotstar.get(
              `/api/rvdocumentperprofile/?profile=${profileId}`
            );
          } else if (company == "dpcgc") {
            response = await instanceDPCGC.get(
              `/api/rvdocumentperprofile/?profile=${profileId}`
            );
          } else {
            response = await instance.get(
              `/api/rvdocumentperprofile/?profile=${profileId}`
            );
          }
          setFiles(response.data);
          setLoading(false);
          return response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) return <CircularCenteredLoader />;

  return <Files files={files} />;
}
