import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTyping, setMessages, addMessage } from "slices/chatSlice";
import { markGroupAsRead, updateGroups, updateStatus } from "slices/groupSlice";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
  scrollToLast,
  connectMessage,
  login,
  pongMessage,
  isUserStatus,
  subscribeUserStatus,
} from "utils/chatUtils";
import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { isEmptyArray } from "formik";
import { getChatAuthToken } from "utils/localStorage";
import { postActivityUserMentions } from "slices/activitySlice";

export default function useWebsocket(url, messageContainer) {
  const dispatch = useDispatch();
  const {
    typing: { value: typing },
    anchorMessage,
  } = useSelector((state) => state.chat);
  const { members } = useSelector((state) => state.groups);
  const [connected, setConnected] = useState(false);
  const [statusArray, setStatusArray] = useState([]);

  const { userId } = getChatAuthToken();

  let ws = useRef(null);

  useDeepCompareEffect(() => {
    !isEmpty(members) &&
      !isEmpty(statusArray) &&
      dispatch(updateStatus(statusArray, members));
  }, [statusArray, members]);

  useEffect(() => {
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      connectMessage(ws);
      login(ws);
      subscribeUserStatus(ws);

      // listening for messages
      ws.current.onmessage = (evt) => {
        // pongMessage(ws, evt);
        let message = JSON.parse(evt.data);

        // connected message reciever
        if (message.msg === "connected") {
          setConnected(true);
          // dispatch(setConnected(true));
        }

        if (message.msg === "ping") {
          pongMessage(ws, evt);
        }

        // fetch history reciever
        if (message.msg === "result" && message.id === "1") {
          let history = [...message.result.messages];
          history.reverse();
          dispatch(setMessages(history));
          const groupId = history[0]?.rid;
          if(history?.length > 0){
            markGroupAsRead(groupId);
          }

          scrollToLast(messageContainer);
        }

        //group  message stream reciever
        else if (
          message.msg === "changed" &&
          message.collection === "stream-room-messages"
        ) {
          let {
            fields: { args, eventName },
          } = message;
          const activeGroup = localStorage.getItem('activeGroup');
          const msgObj = {
            latest_msg: args[0],
            activeGroup_msg: false,
            is_sent_msg: false,
          }
          if(eventName === activeGroup){ // Recieving message for active group
            dispatch(addMessage(args[0]));
            scrollToLast(messageContainer);
            msgObj.activeGroup_msg = true;
            if(userId === args[0].u._id){ // If sent message
              msgObj.is_sent_msg = true;
              dispatch(updateGroups(msgObj));
              // create activity for mentioned users
              if(args[0]?.mentions?.length > 0){
                const mentionedUsers = args[0].mentions.map(user => user.username);
                const disputeId = localStorage.getItem('disputeId');
                dispatch(
                  postActivityUserMentions(
                    mentionedUsers,
                    disputeId,
                  )
                )
              }
            }
            else{ // If recieved message
              dispatch(updateGroups(msgObj));
            }
          }
          else{ // Message recieved for other groups
            let userMentions = 0;
            if(args[0]?.mentions?.length > 0){
              args[0].mentions.forEach(user => {
                if(user._id === userId)
                  userMentions++;
              });
            }
            msgObj.userMentions = userMentions; 
            dispatch(updateGroups(msgObj));
          }
        } else if (isUserStatus(message)) {
          console.log(message);
          let {
            fields: { args },
          } = message;
          setStatusArray(args);
        } else if (
          message.msg === "changed" &&
          message.collection === "stream-notify-room" &&
          message.id === "id"
        ) {
          // console.log(message);
          let {
            fields: { args },
          } = message;
          let groupId = message.fields.eventName.split("/")[0];
          ((typing && typing !== args[1]) || typing !== args[1]) &&
            dispatch(setTyping({ username: args[0], value: args[1], groupId }));
        }
        // rest undefined conditions
        else {
          // console.log(message);
        }
      };
    };

    return () => {
      ws.current.close();
    };
  }, []);

  return { connected, ws };
}
