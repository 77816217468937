import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button, Paper, TextField } from "@material-ui/core";
import { updateDispute } from "slices/disputeSlice";

import { useDispatch } from "react-redux";

import instance from "api/globalaxios";
import { Grid } from "@material-ui/core";
import { updateProfile } from "slices/profileSlice";

const useStyles = makeStyles((theme) => ({
  boldtext: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
  text: {
    fontWeight: "500",
    fontSize: "1.2rem",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
}));

export default function EditProfileRow({ profileData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [haveProfile, setHaveProfile] = useState(
    profileData.profile ? true : false
  );

  const [id, setid] = useState(
    haveProfile ? profileData.profile.id : profileData.id
  );
  const [firstname, setFirstname] = useState(profileData.user.first_name);
  const [lastname, setLastname] = useState(profileData.user.last_name);
  const [phone, setPhone] = useState(
    haveProfile ? profileData.profile.phone_number : profileData.phone_number
  );
  const [email, setEmail] = useState(profileData.user.email);
  const [address, setAddress] = useState(
    haveProfile ? profileData.profile.address : profileData.address
  );
  const [profileType, setprofileType] = useState(
    haveProfile ? profileData.profile.profile_type : profileData.profile_type
  );

  return (
    <>
      <Grid container justify="center">
        <Grid item md={9} sm={12} xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                First Name :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{firstname}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Last Name :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{lastname}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Phone Number :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{phone}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Email : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{email}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Address : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{address}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Profile Type :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{profileType}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
