import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import ComplianceReportYearWiseList from "./ComplianceReportYearWiseList";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Inter',sans-serif`,
  },
  heading: {
    color: "#4F4F4F",
    fontSize: "28px",
    lineHeight: "33px",
    fontFamily: 'inherit',
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
  sub_heading: {
    color: '#333',
    fontWeight: 500,
    marginLeft: '1.7rem',
    fontFamily: 'inherit',
  },
  box: {
    height: "3rem",
  },
  paper: {
    padding: "1rem 1.9rem",
    border: '1px solid #E0E0E0',
    borderRadius: 0,
    boxShadow: 'none',
  },
  text: {
    fontFamily: 'inherit',
    fontSize: "1rem",
    color: "#333333",
    fontWeight: 400,
  },
  textHeading: {
    fontFamily: 'inherit',
    fontSize: "1rem",
    color: "#333333",
    fontWeight: 500,
  },
}));

export default function ComplianceReportYearWise({
  downloadReport,
  yearlist,
  getComplianceReports
}) {
  const classes = useStyles();
  
  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item md={10} sm={10} xs={10}>
        <Box className={classes.box} />
        <Typography className={classes.heading}>
          Published Compliance Reports
        </Typography>
        <Box height="1.4rem" />
        <Typography className={classes.sub_heading}>YEAR</Typography>
        <Box height="1.4rem" />
        <Grid container spacing={2}>
          {
            yearlist.map((year, index) => (
              <ComplianceReportYearWiseList
                year={year}
                index={index}
                getComplianceReports={getComplianceReports}
                downloadReport={downloadReport}
                classes={classes}
              />
            ))
          }
        </Grid>
        <Box className={classes.box} />
      </Grid>
    </Grid>
  );
}
