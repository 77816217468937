import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Box, Tooltip } from "@material-ui/core";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import "./calendar.css";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    height: "30rem",
    margin: "3rem auto",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
}));

export default function Calender({
  transformFunction,
  sources,
  selectable,
  onSelect,
  onEventClick,
  selectAllow,
  handleAddEventClick,
}) {
  // const currentDate = format(new Date(), "yyyy-MM-dd");
  // const limitDate = format(addYears(new Date(), 2), "yyyy-MM-dd");

  const location = useLocation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <FullCalendar
        themeSystem={"materia"}
        handleWindowResize={true}
        // validRange={{
        //   start: currentDate,
        //   end: limitDate,
        // }}

        customButtons={{
          custom1: {
            text: "Schedule",
            click: handleAddEventClick,
          },
        }}
        eventSources={sources}
        eventClick={onEventClick}
        selectable={selectable}
        eventDataTransform={transformFunction}
        selectAllow={selectAllow}
        // businessHours={{
        //   daysOfWeek: [1, 2, 3, 4, 5, 6],

        //   startTime: "09:00",
        //   endTime: "17:00",
        // }}

        dateClick={(info) => {
        }}
        height="100%"
        select={onSelect}
        // selectConstraint="businessHours"

        headerToolbar={{
          left: "title",
          center: "",
          right:
            location.pathname == "/events"
              ? "custom1 timeGridDay,listWeek,dayGridMonth today prev,next"
              : "timeGridDay,listWeek,dayGridMonth today prev,next",
        }}
        plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        navLinks={true}
      />
    </Box>
  );
}
