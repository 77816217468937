import instance from "api/globalaxios";
import { gethost } from "./disputeSlice";

export function getActivities() {
  return async (dispatch) => {
    return await instance({
      method: "get",
      url: `/getactivity/general/?host=${gethost()}`,
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getActivitiesByTab(tabname, pageNum=1) {
  return async (dispatch) => {
    return await instance({
      method: "get",
      url: `/getactivity/${tabname}/?host=${gethost()}&page=${pageNum}`,
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getActivityByTabAndId(tabname, pageNum = 1,  id) {
  return async (dispatch) => {
    return await instance({
      method: "get",
      url: `/getactivitybyid/${tabname}/?dispute_id=${id}&page=${pageNum}`,
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getActivity(id) {
  return async (dispatch) => {
    return await instance({
      method: "get",
      url: `/getactivitybyid/${id}/`,
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function postActivity(
  activityName,
  size,
  is_for_file,
  file_or_folder_name,
  disputeId,
  view_permission,
  file_id = null,
  folder_id = null
) {
  return async (dispatch) => {
    return await instance({
      method: "post",
      url: `/Postactivity/`,
      data: {
        activity_name: activityName,
        size: size,
        is_for_file: is_for_file,
        file_or_folder_name: file_or_folder_name,
        dispute: disputeId,
        view_permission: view_permission,
        file: file_id,
        folder: folder_id,
      },
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function postActivitySebi(
  activityName,
  uploadedBy,
  disputeId,
  category
) {
  return async (dispatch) => {
    return await instance({
      method: "post",
      url: `/Postactivity/`,
      data: {
        activity_name: activityName,
        uploaded_by: uploadedBy,
        dispute: disputeId,
        category: category,
      },
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function postActivityUserMentions(
  mentionedUsers,
  disputeId,
) {
  return async (dispatch) => {
    return await instance({
      method: "post",
      url: `chatroom/create_activity/`,
      data: {
        dispute_id: disputeId,
        usernames: mentionedUsers,
      },
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}
