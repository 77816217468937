import { makeStyles, Paper, Grid, Box, Typography } from "@material-ui/core";
import React from "react";

function ColouredBox({ type }) {
  // type 1-yellow 2-orange 3-red
  const classes = useStyles();
  return (
    <Box
      className={
        type == 1
          ? classes.boxYellow
          : type == 2
          ? classes.boxOrange
          : classes.boxRed
      }
    />
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "13px",
    lineHeight: "14px",
    color: "#000000",
    marginTop: "10px",
  },
  boxYellow: { background: "#F2C94C", width: 36, height: 36, margin: "0 2px" },
  boxOrange: { background: "#F2994A", width: 36, height: 36, margin: "0 2px" },
  boxRed: { background: "#FF665C", width: 36, height: 36, margin: "0 2px" },
  lastText: {
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    fontWeight: "600",
  },
  text2: {
    fontSize: "13px",
    lineHeight: "14px",
    color: "#000000",
    marginTop: "6px",
  },
}));
export default function SquareBoxChart() {
  const classes = useStyles();

  const range1 = 5000;
  const range2 = 25000;

  const data = [
    {
      id: "MH",
      state: "Maharashtra",
      value: [25001, 25010, 25012, 24000, 4000, 23000],
    },
    {
      id: "DL",
      state: "Delhi",
      value: [25001, 24010, 25012, 25100, 4000, 23000],
    },
    {
      id: "KA",
      state: "Karnataka",
      value: [25001, 24010, 25012, 24000, 25100, 2000],
    },
    {
      id: "HR",
      state: "Haryana",
      value: [25001, 24010, 24012, 24000, 25300, 3000],
    },
    {
      id: "OD",
      state: "Odisha",
      value: [25001, 24010, 24012, 24000, 4000, 33000],
    },
    {
      id: "AS",
      state: "Assam",
      value: [24001, 25010, 22012, 34000, 22000, 23000],
    },
    {
      id: "UP",
      state: "Uttar Pradesh",
      value: [24001, 22010, 22012, 22000, 46000, 46000],
    },
    {
      id: "GJ",
      state: "Gujarat",
      value: [21001, 21010, 15012, 26000, 43000, 1000],
    },
    {
      id: "TN",
      state: "Tamil Nadu",
      value: [2001, 2010, 25012, 34000, 14000, 23000],
    },
    {
      id: "PB",
      state: "Punjab",
      value: [1001, 5010, 1012, 26000, 4000, 23000],
    },
    {
      id: "WB",
      state: "West Bengal",
      value: [1001, 25010, 6012, 1000, 64000, 23000],
    },
    {
      id: "CH",
      state: "Chandigarh",
      value: [2001, 25010, 5012, 27000, 4000, 23000],
    },
    {
      id: "CT",
      state: "Chhattisgarh",
      value: [22001, 25010, 5012, 4000, 4000, 3000],
    },
    {
      id: "AR",
      state: "Arunachal Pradesh",
      value: [22001, 25010, 5012, 4000, 4000, 3000],
    },
    {
      id: "TS",
      state: "Telengana",
      value: [1001, 5010, 2012, 27000, 7000, 23000],
    },
    {
      id: "ML",
      state: "Meghalaya",
      value: [2001, 2010, 2012, 24000, 14000, 3000],
    },
    {
      id: "SK",
      state: "Sikkim",
      value: [2001, 21010, 25012, 24000, 4000, 23000],
    },
    {
      id: "RJ",
      state: "Rajasthan",
      value: [5001, 5010, 25012, 4000, 4000, 3000],
    },
    {
      id: "UK",
      state: "Uttarakhand",
      value: [5022, 25010, 5012, 26000, 14000, 3000],
    },
    {
      id: "HP",
      state: "Himachal Pradesh",
      value: [1001, 2010, 2012, 26000, 64000, 63000],
    },
    { id: "BR", state: "Bihar", value: [5001, 2110, 15012, 4000, 4000, 53000] },
    {
      id: "JH",
      state: "Jharkhand",
      value: [2001, 15010, 15012, 4000, 44000, 23000],
    },
    {
      id: "AP",
      state: "Andhra Pradesh",
      value: [25001, 1010, 25012, 4000, 4000, 2000],
    },
    {
      id: "MN",
      state: "Manipur",
      value: [2001, 25010, 15012, 2000, 14000, 2000],
    },
    { id: "GA", state: "Goa", value: [2001, 15010, 2012, 25600, 14000, 2000] },
    {
      id: "KL",
      state: "Kerala",
      value: [25001, 25010, 2012, 2000, 14000, 2000],
    },
    {
      id: "MP",
      state: "Madhya Pradesh",
      value: [15001, 15010, 25012, 2000, 4000, 2000],
    },
    {
      id: "TR",
      state: "Tripura",
      value: [15001, 25010, 15012, 24000, 4000, 3000],
    },
    {
      id: "MZ",
      state: "Mizoram",
      value: [2001, 2010, 2012, 24000, 44000, 3000],
    },
    {
      id: "NL",
      state: "Nagaland",
      value: [2001, 25010, 25012, 2000, 14000, 3000],
    },
    {
      id: "PY",
      state: "Puducherry",
      value: [15001, 2010, 25012, 2400, 4000, 3000],
    },
    {
      id: "LA",
      state: "Ladakh",
      value: [15001, 25010, 21012, 24000, 4000, 3000],
    },
    {
      id: "LD",
      state: "Lakshadweep",
      value: [2001, 2010, 25012, 2000, 4000, 23000],
    },
    {
      id: "JK",
      state: "Jammu and Kashmir",
      value: [25001, 2010, 2012, 24000, 4000, 3000],
    },
    {
      id: "DN",
      state: "Dadra and Nagar Haveli",
      value: [2001, 5010, 1012, 24000, 4000, 3000],
    },
    {
      id: "AN",
      state: "Andaman and Nicobar Islands",
      value: [2001, 2010, 22012, 2000, 24000, 3000],
    },
    {
      id: "DD",
      state: "Daman and Diu",
      value: [2001, 2010, 2012, 2000, 4000, 3000],
    },
    // { id: '-99', state: 'Andhra Pradesh', value: [56, 20, 5] },
  ];

  return (
    <Paper
      style={{
        overflow: "hidden",
        textAlign: "center",
        padding: "40px 20px 30px 20px",
        height: "-webkit-fill-available",
        maxHeight: "100%",
      }}
    >
      <Grid container justify="space-evenly">
        <Grid item>
          <Grid container justify="flex-start" spacing={1}>
            <Grid item>
              <ColouredBox type={1} />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>0 - {range1}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="flex-start" spacing={1}>
            <Grid item>
              <ColouredBox type={2} />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                {range1} - {range2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="flex-start" spacing={1}>
            <Grid item>
              <ColouredBox type={3} />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                {range2} {">"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box height="2rem" />
      {data.map((item) => {
        return (
          <Grid container spacing={1} key={item.id}>
            <Grid item md={4}>
              <Typography
                style={{ textAlign: "right" }}
                className={classes.text2}
              >
                {item.state}
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Grid container justify="center">
                <Grid item>
                  <ColouredBox
                    type={
                      item.value[0] > range2
                        ? 3
                        : item.value[0] > range1
                        ? 2
                        : 1
                    }
                  />
                </Grid>
                <Grid item>
                  <ColouredBox
                    type={
                      item.value[1] > range2
                        ? 3
                        : item.value[1] > range1
                        ? 2
                        : 1
                    }
                  />
                </Grid>
                <Grid item>
                  <ColouredBox
                    type={
                      item.value[2] > range2
                        ? 3
                        : item.value[2] > range1
                        ? 2
                        : 1
                    }
                  />
                </Grid>
                <Grid item>
                  <ColouredBox
                    type={
                      item.value[3] > range2
                        ? 3
                        : item.value[3] > range1
                        ? 2
                        : 1
                    }
                  />
                </Grid>
                <Grid item>
                  <ColouredBox
                    type={
                      item.value[4] > range2
                        ? 3
                        : item.value[4] > range1
                        ? 2
                        : 1
                    }
                  />
                </Grid>
                <Grid item>
                  <ColouredBox
                    type={
                      item.value[5] > range2
                        ? 3
                        : item.value[5] > range1
                        ? 2
                        : 1
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Box height="2rem" />
      <Grid container justify="center">
        <Grid item style={{ textAlign: "justify" }} md={8}>
          <Typography className={classes.lastText}>
            Left to right: Non-receipt / Delay | Trade related | Charges | Order
            execution | Primary market | Others{" "}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
