import {
    Box,
    Button,
    Chip,
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
  } from "@material-ui/core";
  import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
  import { RESPONDENT } from "app/constants";
  import FormattedMessage from "features/common/TranslatedMessage";
  import React, { useEffect, useState } from "react";
  import { useHistory } from "react-router-dom";
  import { DocGenerator } from "utils";
  import { downloadLegalDoc, gethost, removeOrAddTagFromDispute } from "slices/disputeSlice";
  import { formatDate } from "utils";
  import { getClaimantDetails } from "../ArbitratorHotstar/NewArbitratorSingleDispute";
  import CircularCenteredLoader from "features/common/CircularCenteredLoader";
  import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import instance from "api/globalaxios";
  
  const useStyles = makeStyles((theme) => ({
    text: {
      fontFamily: `"Inter", "sans-serif"`,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    blue: {
      fontFamily: `"Inter", "sans-serif"`,
      color: "#2F80ED",
      lineHeight: "24px",
    },
    fonts: {
      lineHeight: "24px",
      fontSize: "16px",
      fontFamily: `"Inter", "sans-serif"`,
    },
    item: {
      "&:hover": {
        background: "#EEEEEE",
      },
    },
    calenderBtn: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#2F80ED",
      textTransform: "none",
      borderRadius: "2px",
      border: "1px solid #2F80ED",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
      width: "100%",
      maxWidth: "100%",
      "&:hover": {
        background: "#2F80ED",
        color: "#FFFFFF",
        border: "1px solid #2F80ED",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    chipHover: {
        "& .MuiChip-deleteIcon": {
          display: "none"
        },
        "&:hover": {
          "& .MuiChip-deleteIcon": {
            display: "block"
          }
        }
      }
  }));

  const getStyles = (label) => {
    if (label.includes("Women")) {
      return {
        backgroundColor: '#fceff8',
        color: '#D534A8',
        marginRight: '9px',
        fontFamily: "Satoshi-Bold",
      };
    } else if (label.includes("Children")) {
      return {
        backgroundColor: '#effafc',
        color: '#34C2D5',
        marginRight: '9px',
        fontFamily: "Satoshi-Bold",
      };
    }else if (label.includes("LGBTQ")) {
        return {
          backgroundColor: '#fcefef',
          color: '#FF8933',
          marginRight: '9px',
          fontFamily: "Satoshi-Bold",
        };
    }else if (label.includes("Violence")) {
        return {
          backgroundColor: '#fff7ef',
          color: '#FF8933',
          marginRight: '9px',
          fontFamily: "Satoshi-Bold",
        };
    }else if (label.includes("Language")) {
      return {
        backgroundColor: '#ebf5ef',
        color: '#048839',
        marginRight: '9px',
        fontFamily: "Satoshi-Bold",
      };
    }else{
      return {
        backgroundColor: '#ebf5ef',
        color: '#048839',
        marginRight: '9px',
        fontFamily: "Satoshi-Bold",
      };
    }
};

  export default function DisputeDisplayStar({
    activeDispute,
    activeCase,
    profile,
  }) {
    const classes = useStyles();
    const history = useHistory();
    const [disputeResult, setDisputeResult] = useState({})
    const {disputeId} = useParams();
    const dispatch = useDispatch();

    function getDisputesForStar(disputeId) {
        return async (dispatch) => {
          return await instance({
            method: "get",
            url:
              `/disputelist/?dispute_id=${disputeId}` +
              "&host=" +
              gethost(),
          }).then(
            (response) => {
              setDisputeResult(response?.data?.results?.[0])
              return response.data;
            },
            (error) => {
              console.log(error);
              }
          );
        };
    }

    console.log(disputeResult,'disputeResult',activeDispute)
    useEffect(()=>{
       dispatch(getDisputesForStar(disputeId))
    },[disputeId])
  
    const [loading, setLoading] = React.useState(true);
    const [claimant_email, setClaimant_email] = React.useState("");
    React.useEffect(() => {
      getClaimantDetails(activeDispute.id).then((res) => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.profile_type == "general") {
            setClaimant_email(element.user.email);
            setLoading(false);
            break;
          }
        }
      });
    }, []);
  
    const [decisionFiles, setdecisionFiles] = React.useState(
      activeDispute.all_docs
    );
    const [finalDecision, setFinalDecision] = React.useState();
  
    React.useEffect(() => {
      decisionFiles.forEach((file) => {
        if (file.is_final) {
          setFinalDecision(file.id);
        }
      });
    }, [decisionFiles]);

    const handleRemoveOrAddTag = async (disputeId, tagId, type) => {
        const result = await dispatch(removeOrAddTagFromDispute(disputeId, tagId, type));
    }
  
    if (loading) return <CircularCenteredLoader />;
  
    return (
      <Container>
        <Box p="2rem 0">
          <Grid container>
            <Grid item md={2} sm={0} xs={0}></Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Paper
                style={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <Button
                  onClick={() => {
                    // history.push("/disputes");
                    history.goBack();
                  }}
                  size="large"
                  startIcon={<ArrowBackIosIcon />}
                >
                  <FormattedMessage id="buttons.back" />
                </Button>
              </Paper>
              <Paper style={{ padding: "3.5rem" }}>
                <Box className={classes.text}>
                  <Typography style={{ fontSize: "2rem", lineHeight: "36px" }}>
                    {disputeResult?.name?.split?.("&")?.[0]}
                  </Typography>
                  <Box height="1rem"></Box>
                </Box>
                <Grid container direction="row">
                  <Grid
                    item
                    xs
                    sm={3}
                    md={3}
                    style={{ padding: "10px 20px 10px 0" }}
                  >
                    <Typography
                      className={classes.blue}
                      onClick={() => {
                        DocGenerator(activeDispute.id, "claimant");
                      }}
                      style={{
                        textDecorationLine: "underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      VIEW GRIEVANCE
                    </Typography>
                  </Grid>
                  {activeDispute.flow_type !== "mediation" &&
                    activeCase.type !== "claimant" &&
                    activeCase.type === "respondent" && (
                      <Grid
                        item
                        xs
                        sm={3}
                        md={3}
                        style={{ padding: "10px 10px 10px 0" }}
                      >
                        <Typography
                          className={classes.blue}
                          onClick={() => {
                            DocGenerator(disputeResult?.id, RESPONDENT);
                          }}
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          VIEW RESPONSE
                        </Typography>
                      </Grid>
                    )}
                </Grid>
                <Box height="2rem"></Box>
                <Grid container className={classes.fonts}>
                  <Grid item xs={12} md={6}>
                    <span className={classes.blue}>Date of filing: </span>
                    {disputeResult?.created ? formatDate(disputeResult?.created): "--"}
                    <Box height="0.1rem"></Box>
                    <span className={classes.blue}>Registration Date: </span>
                    {disputeResult?.approved_date
                      ? formatDate(disputeResult?.approved_date)
                      : "--"}
                    <Box height="0.1rem"></Box>
                    <span className={classes.blue}>Contact: </span>
                    {claimant_email}
                  </Grid>
                  <Grid item md={1}></Grid>
                  <Grid item xs={12} md={5}>
                    <span className={classes.blue}>Grievance ID: </span>
                    {disputeResult?.id}
                    <Box height="0.1rem"></Box>
                    <span className={classes.blue}>Decision: </span>
                    {finalDecision ? (
                      <u
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: `"Inter", "sans-serif"`,
                          color: "#2F80ED",
                          lineHeight: "24px",
                        }}
                        onClick={() => {
                          downloadLegalDoc(finalDecision);
                        }}
                      >
                        <span className={classes.blue}>VIEW</span>
                      </u>
                    ) : (
                      "(In Progress)"
                    )}
                    <Box height="0.1rem"></Box>
                  </Grid>
                </Grid>
                <Box height="8rem"></Box>
                {disputeResult?.other_tags?.map((item, index) => (
                    <>
                        {Object.entries(item).map(([key, value]) => (
                            <Chip style={{marginRight: '9px', fontFamily: "Satoshi-Bold" }} label={key === 'channel_name' || key === 'show_name' ? value : key}/>                                
                        ))}
                    </>
                ))}
                <Box height="1rem"></Box>
                {disputeResult?.tags?.map((tag) => (
                    <Chip className={classes.chipHover} key={tag.id} label={tag.name} style={getStyles(tag.name)} onDelete={()=>handleRemoveOrAddTag(disputeId,tag.id,'remove')}/>
                ))}
              </Paper>
              <Box height="4rem"></Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
  