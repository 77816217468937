import { makeStyles, Paper, Switch, Typography } from "@material-ui/core";
import { scaleLinear, scaleQuantile, scaleQuantize } from "d3";
import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import ReactTooltip from "react-tooltip";

import INDIA_TOPO_JSON from "./Map/india.topo.json";

const useStyles = makeStyles({
  root: {
    width: "70px",
  },
  switchBase: {
    color: "#61A0FF",
    "&$checked": {
      color: "#FF665C",
      transform: "translateX(30px)",
    },
    "&$checked + $track": {
      backgroundColor: "#000",
      opacity: "1",
    },
  },
  checked: {},
  track: {},
});

const PROJECTION_CONFIG = {
  scale: 350,
  center: [82.9629, 22.5937], // always in [East Latitude, North Longitude]
};

// Variants
const COLOR_RANGE = ["#F2C94C", "#F2994A", "#FF665C"];

const DEFAULT_COLOR = "#61A0FF";
const RED_COLOR = "#F2994A";

const geographyStyle = {
  default: {
    outline: "none",
    stroke: "#eaf184",
    strokeWidth: 0.5,
  },
  hover: {
    fill: "#FF665C",
    transition: "all 250ms",
    outline: "none",
    stroke: "#eaf184",
    strokeWidth: 0.5,
  },
  pressed: {
    outline: "none",
  },
};
const focusgeographyStyle = {
  default: {
    outline: "none",
    fill: "#E42",
    stroke: "#eaf184",
    strokeWidth: 0.5,
    filter: "drop-shadow(rgba(0, 0, 0, 0.2) 0px 1px 0.8px)",
  },
  hover: {
    fill: "#FF665C",
    transition: "all 250ms",
    outline: "none",
    stroke: "#eaf184",
    strokeWidth: 0.5,
  },
  pressed: {
    outline: "none",
    stroke: "#eaf184",
    strokeWidth: 0.5,
    fill: "#E42",
  },
};
const geographyStyleOnHit = {
  default: {
    outline: "none",
    stroke: "#fff",
    strokeWidth: 0.5,
  },
  hover: {
    fill: "#61A0FF",
    transition: "all 250ms",
    outline: "none",
    stroke: "#fff",
    strokeWidth: 0.5,
  },
  pressed: {
    outline: "none",
    fill: "#61A0FF",
  },
};
const focusgeographyStyleOnHit = {
  default: {
    outline: "none",
    fill: "#61A0FF",
    stroke: "#fff",
    strokeWidth: 0.5,
  },
  hover: {
    fill: "#61A0FF",
    transition: "all 250ms",
    outline: "none",
    stroke: "#fff",
    strokeWidth: 0.5,
  },
  pressed: {
    outline: "none",
    stroke: "#fff",
    strokeWidth: 0.5,
    fill: "#61A0FF",
  },
};

export default function HeatMap({
  choosestep2,
  stateOnClick,
  activeState,
  states,
  getSelectedHeatState,
  hitToggle,
  setHitToggle,
}) {
  const classes = useStyles();

  const [tooltipContent, setTooltipContent] = useState("");

  // const [data, setData] = useState(getHeatMapData());
  const colorScale = scaleQuantize()
    .domain(states.map((d) => d.value[choosestep2 - 1]))
    .range(COLOR_RANGE);

  const colorScale2 = scaleQuantize()
    .domain(states.map((d) => d.value[0] + d.value[1] + d.value[2]))
    .range(COLOR_RANGE);

  const onMouseEnter = (geo, current = { value: "NA" }) => {
    // return () => {
    //   setTooltipContent(`
    //   ${geo.properties.name}: ${current.value} `);
    // };
    return () => {
      setTooltipContent(
        <>
          <Typography style={{ fontSize: "18px", color: "#000000" }}>
            {geo.properties.name}
          </Typography>
          <Typography style={{ fontSize: "12px", color: "#000000" }}>
            Complaints: {current.value[0]}
          </Typography>
          <Typography style={{ fontSize: "12px", color: "#000000" }}>
            Arbitrations: {current.value[1]}
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "#000000",
            }}
          >
            Appellate Arbitrations: {current.value[2]}
          </Typography>
        </>
      );
    };
  };

  const onMouseLeave = () => {
    setTooltipContent("");
  };

  const handleChange = (event) => {
    setHitToggle(event.target.checked);
  };

  const onFocus = (geo, current) => {
    stateOnClick({ states: current.value }, current.state, current.id);
    if (hitToggle) {
      if (current.id === activeState?.id) {
        getSelectedHeatState(current.id, focusgeographyStyleOnHit);
      } else {
        getSelectedHeatState(current.id, focusgeographyStyle);
      }
    }
  };

  return (
    <Paper
      style={{
        overflow: "hidden",
        height: "-webkit-fill-available",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      <div
        style={{
          padding: "20px 20px 0 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: "12px", color: "#000000" }}>
          Heat Map
        </Typography>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          checked={hitToggle}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      </div>
      <ReactTooltip
        backgroundColor="#fff"
        textColor="#000"
        borderColor="#000"
        border={true}
        style={{ opacity: "1 !important" }}
      >
        {tooltipContent}
      </ReactTooltip>

      <ComposableMap
        projectionConfig={PROJECTION_CONFIG}
        projection="geoMercator"
        width={200}
        height={200}
        viewBox="0 10 200 200"
        style={{ width: "100%" }}
      >
        <Geographies
          geography={INDIA_TOPO_JSON}
          style={{ filter: "drop-shadow(rgba(0, 0, 0, 0.2) 0px 1px 0.8px)" }}
          data-tip=""
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              const current = states.find((s) => s.id === geo.id);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  //   fill={current ? colorScale(current.value) : DEFAULT_COLOR}
                  // fill={hitToggle ? colorScale(current.value[choosestep2 - 1]) : DEFAULT_COLOR}
                  fill={
                    hitToggle
                      ? !choosestep2
                        ? colorScale2(
                            current.value[0] +
                              current.value[1] +
                              current.value[2]
                          )
                          ? colorScale2(
                              current.value[0] +
                                current.value[1] +
                                current.value[2]
                            )
                          : RED_COLOR
                        : colorScale(current.value[choosestep2 - 1])
                        ? colorScale(current.value[choosestep2 - 1])
                        : RED_COLOR
                      : DEFAULT_COLOR
                  }
                  style={
                    hitToggle
                      ? current.id === activeState?.id
                        ? focusgeographyStyleOnHit
                        : geographyStyleOnHit
                      : current.id === activeState?.id
                      ? focusgeographyStyle
                      : geographyStyle
                  }
                  onMouseEnter={onMouseEnter(geo, current)}
                  onMouseLeave={onMouseLeave}
                  onFocus={() => onFocus(geo, current)}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </Paper>
  );
}
