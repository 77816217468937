import React from "react";
import { Grid, makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "16px",
    color: "#000000",
  },
}));

export default function Line({ label, col, data, percentage }) {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="flex-start">
        <Grid item>
          <Typography className={classes.text}>
            {label}
            {" - "}
            {data}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12} sm={12}>
          <Box
            style={{
              height: "8px",
              width: `${percentage}%`,
              background: `${col}`,
              borderRadius: "12px",
            }}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
}
