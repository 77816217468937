import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import instance from "api/globalaxios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import CaseDisplay from "./SubAdminOverview/CaseDisplay";
import GrievanceRow from "./SubAdminOverview/GrievanceRow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  listheading: {
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  listtext: {
    "text-align": "left",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textTransform: "capitalize",
    color: "#454545",
    paddingBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  listtextblue: {
    cursor: "pointer",
    "text-align": "left",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textTransform: "capitalize",
    color: "#3F51B5",
    paddingBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  headingtribunal: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: "#454545",
    "text-align": "left",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  companylist: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
  text: {
    textAlign: "left",
    paddingLeft: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      paddingLeft: "20px",
    },
  },
}));

export default function Overview({ profile, isSmallScreen }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  let sessionShowCase = sessionStorage.getItem("subadmin_overview_showCase");
  let sessionCaseId = sessionStorage.getItem("caseid");

  const [loading, setLoading] = useState(sessionShowCase ? false : true);
  const [loadingGrievances, setLoadingGrievances] = useState(false);
  const [openCase, setOpenCase] = useState(sessionShowCase ? true : false);
  const [caseId, setCaseId] = useState(sessionCaseId ? sessionCaseId : null);
  const mode = "GR";

  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [tribunalList, setTribunalList] = useState([]);
  const [secretariatList, setSecretariatList] = useState([]);
  const [grievanceList, setGrievanceList] = useState([]);

  function apiFunction() {
    return async () => {
      try {
        let response = await instance.post("/api/disputeinfobygroupsubadmin/", {
          group: profile.group,
        });
        setCompany("nbf");
        setCompanyList(response.data.company_data);
        setTribunalList(response.data.arbitrator);
        setSecretariatList(response.data.secretariats);

        setLoading(false);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  React.useEffect(() => {
    dispatch(apiFunction()).then(() => {});

    // dispatch(apiClosedFunction()).then((res) => {

    // });
  }, []);
  const loadGrievances = (id) => {
    setLoadingGrievances(true);
    dispatch(apiFunction2(id)).then(() => {});
  };

  function apiFunction2(id) {
    return async () => {
      try {
        let response;
        response = await instance.get(`/api/disputebycompanyidsubadmin/${id}/`);
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function apiClosedFunction2(id) {
    return async () => {
      try {
        let response;
        response = await instance.get(
          `/api/closeddisputebycompanyidsubadmin/${id}/`
        );
        setGrievanceList(response.data.dispute_data);
        setLoadingGrievances(false);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const loadClosedGrievances = (id) => {
    setLoadingGrievances(true);
    dispatch(apiClosedFunction2(id)).then(() => {});
  };

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <div className={classes.root}>
        {!openCase ? (
          <Grid container justify="flex-start" direction="column">
            <Grid item>
              <Grid container justify="space-between" direction="row">
                <Grid item>
                  {company == "nbf" ? (
                    <Typography className={classes.heading}>
                      NBF (News Broadcasters Federation)
                    </Typography>
                  ) : (
                    <Typography
                      className={classes.heading}
                      style={{ textTransform: "capitalize" }}
                    >
                      {company}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box height="4rem"></Box>
              {!isSmallScreen() ? (
                <>
                  <Grid
                    container
                    justify="space-evenly"
                    direction="row"
                    alignItems="stretch"
                    style={{ display: "flex" }}
                    spacing={isSmallScreen() ? 1 : 3}
                  >
                    <Grid item sm={11} xs={11} style={{ height: "100%" }}>
                      <Paper
                        style={{
                          height: "100%",
                          padding: "20px 20px",
                          wordWrap: "break-word",
                        }}
                      >
                        <Grid
                          container
                          justify="flex-start"
                          spacing={isSmallScreen() ? 0 : 2}
                        >
                          <Grid
                            item
                            sm={4}
                            xs={4}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listheading}>
                              NAME
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            xs={2}
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            <Typography className={classes.listheading}>
                              OPEN CASES
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            <Typography className={classes.listheading}>
                              CLOSE CASES
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listheading}>
                              GRO (NAME)
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="1rem"></Box>
                        {companyList.map((item) => {
                          if (item.company_id != 51)
                            return (
                              <Grid
                                container
                                justify="flex-start"
                                spacing={isSmallScreen() ? 1 : 2}
                                key={item.company_id}
                              >
                                <Grid item sm={4} xs={4}>
                                  <Typography className={classes.listtext}>
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={2}
                                  xs={2}
                                  className={classes.companylist}
                                  onClick={() => {
                                    loadGrievances(item.company_id);
                                    sessionStorage.setItem(
                                      "company_id",
                                      item.company_id
                                    );
                                  }}
                                >
                                  <Typography className={classes.listtext}>
                                    {item.open}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={3}
                                  xs={3}
                                  className={classes.companylist}
                                  onClick={() => {
                                    loadClosedGrievances(item.company_id);
                                    sessionStorage.setItem(
                                      "company_id",
                                      item.company_id
                                    );
                                  }}
                                >
                                  <Typography className={classes.listtext}>
                                    {item.closed}
                                  </Typography>
                                </Grid>
                                <Grid item sm={3} xs={3}>
                                  <Typography className={classes.listtext}>
                                    {item.gro_name == "" ? "--" : item.gro_name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                        })}
                        <Box height="1rem"></Box>
                      </Paper>
                    </Grid>

                    <Grid item sm={10} xs={11} style={{ flex: "1" }}>
                      <Grid
                        container
                        direction="column"
                        // style={{ display: "flex", height: "100%" }}
                      >
                        <Grid item sm={6} xs={6}>
                          <Typography className={classes.headingtribunal}>
                            Tribunal
                          </Typography>
                        </Grid>
                        <Box height="1rem"></Box>
                        <Grid item style={{ flex: "1" }}>
                          <Paper style={{ height: "100%" }}>
                            <Grid
                              container
                              justify="space-between"
                              spacing={2}
                              style={{
                                textAlign: "left",
                                padding: " 10px 20px",
                              }}
                            >
                              <Grid item sm={10} xs={11}>
                                <Typography className={classes.listheading}>
                                  NAME
                                </Typography>
                              </Grid>
                              <Grid item sm={10} xs={11}>
                                <Typography
                                  className={classes.listheading}
                                ></Typography>
                              </Grid>
                            </Grid>
                            <Box height="1rem"></Box>
                            {tribunalList.map((item) => {
                              return (
                                <Grid
                                  container
                                  justify="space-between"
                                  spacing={isSmallScreen() ? 1 : 2}
                                  key={item.id}
                                >
                                  <Grid
                                    item
                                    md={7}
                                    sm={7}
                                    xs={7}
                                    style={{
                                      padding: " 0 28px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <Typography className={classes.listtext}>
                                      {item.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={5}
                                    xs={5}
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    <Typography
                                      className={classes.listtextblue}
                                      onClick={() => {
                                        history.push(`/events/${item.id}`);
                                      }}
                                    >
                                      View Calender
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Paper>
                        </Grid>
                        <Box height="0.6rem"></Box>
                        <Grid item>
                          <Grid container justify="space-between">
                            <Grid item sm={6} xs={6}>
                              <Typography className={classes.headingtribunal}>
                                Secretariat
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sm={6}
                              xs={6}
                              className={classes.listheading}
                            >
                              <Typography></Typography>
                            </Grid>
                          </Grid>
                          <Box height="1rem"></Box>
                          <Paper>
                            <Grid
                              container
                              justify="space-between"
                              spacing={2}
                              style={{ textAlign: "left", padding: " 0 20px" }}
                            >
                              <Grid item sm={10} xs={11}>
                                <Typography className={classes.listheading}>
                                  NAME
                                </Typography>
                              </Grid>
                              <Grid item sm={10} xs={11}>
                                <Typography
                                  className={classes.listheading}
                                ></Typography>
                              </Grid>
                            </Grid>
                            <Box height="1rem"></Box>
                            {secretariatList.map((item) => {
                              return (
                                <Grid
                                  container
                                  justify="space-between"
                                  spacing={isSmallScreen() ? 1 : 2}
                                  key={item.id}
                                >
                                  <Grid
                                    item
                                    md={7}
                                    sm={7}
                                    xs={7}
                                    style={{
                                      padding: " 0 28px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <Typography className={classes.listtext}>
                                      {item.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={5}
                                    xs={5}
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    <Typography
                                      className={classes.listtextblue}
                                      onClick={() => {
                                        history.push(`/events/${item.id}`);
                                      }}
                                    >
                                      View Calender
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Box height="1rem"></Box>
                  <Grid
                    container
                    // justify="space-evenly"
                    direction="column"
                    alignItems="center"
                    // style={{ display: "flex" }}
                    // spacing={isSmallScreen() ? 1 : 3}
                  >
                    <Grid item sm={10} xs={11}>
                      <Paper
                        style={{
                          height: "100%",
                          padding: "10px 20px",
                          wordWrap: "break-word",
                        }}
                      >
                        <Grid
                          container
                          justify="flex-start"
                          spacing={isSmallScreen() ? 0 : 2}
                        >
                          <Grid
                            item
                            sm={4}
                            xs={4}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listheading}>
                              NAME
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            xs={2}
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            <Typography className={classes.listheading}>
                              OPEN CASES
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            <Typography className={classes.listheading}>
                              CLOSE CASES
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.listheading}>
                              GRO (NAME)
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="1rem"></Box>
                        {companyList.map((item) => {
                          if (item.company_id != 51)
                            return (
                              <Grid
                                container
                                justify="flex-start"
                                spacing={isSmallScreen() ? 1 : 2}
                                key={item.company_id}
                              >
                                <Grid item sm={4} xs={4}>
                                  <Typography className={classes.listtext}>
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={2}
                                  xs={2}
                                  className={classes.companylist}
                                  onClick={() => {
                                    loadGrievances(item.company_id);
                                    sessionStorage.setItem(
                                      "company_id",
                                      item.company_id
                                    );
                                  }}
                                >
                                  <Typography className={classes.listtext}>
                                    {item.open}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={3}
                                  xs={3}
                                  className={classes.companylist}
                                  onClick={() => {
                                    loadClosedGrievances(item.company_id);
                                    sessionStorage.setItem(
                                      "company_id",
                                      item.company_id
                                    );
                                  }}
                                >
                                  <Typography className={classes.listtext}>
                                    {item.closed}
                                  </Typography>
                                </Grid>
                                <Grid item sm={3} xs={3}>
                                  <Typography className={classes.listtext}>
                                    {item.gro_name == "" ? "--" : item.gro_name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                        })}
                        <Box height="1rem"></Box>
                      </Paper>
                    </Grid>
                    <Box height="1rem"></Box>
                    <Grid item sm={10} xs={11}>
                      <Grid container>
                        <Grid item sm={6} xs={6}>
                          <Typography className={classes.headingtribunal}>
                            Tribunal
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={6}
                          className={classes.listheading}
                        >
                          <Typography></Typography>
                        </Grid>
                      </Grid>
                      <Box height="1rem"></Box>
                      <Grid
                        container
                        direction="column"
                        style={{
                          display: "flex",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <Grid item style={{ flex: "1" }}>
                          <Paper style={{ height: "100%", width: "100%" }}>
                            <Grid
                              container
                              // justify="space-between"
                              // spacing={1}
                              spacing={2}
                              style={{ textAlign: "left", padding: " 0 20px" }}
                            >
                              <Grid item sm={6} xs={6}>
                                <Typography className={classes.listheading}>
                                  NAME
                                </Typography>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <Typography
                                  className={classes.listheading}
                                ></Typography>
                              </Grid>
                            </Grid>
                            <Box height="1rem"></Box>
                            {tribunalList.map((item) => {
                              return (
                                <Grid
                                  container
                                  justify="space-between"
                                  // spacing={isSmallScreen() ? 1 : 2}
                                >
                                  <Grid
                                    item
                                    sm={7}
                                    xs={7}
                                    style={{
                                      padding: " 0 28px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <Typography className={classes.listtext}>
                                      {item.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={5}
                                    xs={5}
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    <Typography
                                      className={classes.listtextblue}
                                      onClick={() => {
                                        history.push(`/events/${item.id}`);
                                      }}
                                    >
                                      View Calender
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Paper>
                        </Grid>
                        <Box height="1rem"></Box>
                        <Grid item>
                          <Grid container justify="space-between">
                            <Grid item sm={3} xs={5}>
                              <Typography className={classes.headingtribunal}>
                                Secretariat
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={5}
                              className={classes.listheading}
                            >
                              <Typography></Typography>
                            </Grid>
                          </Grid>
                          <Box height="1rem"></Box>
                          <Paper>
                            <Grid
                              container
                              justify="space-between"
                              spacing={2}
                              style={{ textAlign: "left", padding: " 0 20px" }}
                            >
                              <Grid item sm={3} xs={5}>
                                <Typography className={classes.listheading}>
                                  NAME
                                </Typography>
                              </Grid>
                              <Grid item sm={3} xs={5}>
                                <Typography
                                  className={classes.listheading}
                                ></Typography>
                              </Grid>
                            </Grid>
                            <Box height="1rem"></Box>
                            {secretariatList.map((item) => {
                              return (
                                <Grid
                                  container
                                  justify="space-between"
                                  spacing={isSmallScreen() ? 1 : 2}
                                >
                                  <Grid
                                    item
                                    md={7}
                                    sm={7}
                                    xs={7}
                                    style={{
                                      padding: " 0 28px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <Typography className={classes.listtext}>
                                      {item.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={5}
                                    xs={5}
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    <Typography
                                      className={classes.listtextblue}
                                      onClick={() => {
                                        history.push(`/events/${item.id}`);
                                      }}
                                    >
                                      View Calender
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Box height="3rem"></Box>
              <Grid container justify="flex-start">
                <Grid item sm={2} xs={2}>
                  <Typography
                    className={[
                      classes.blueheading,
                      classes.text,
                      "main-class",
                    ].join(" ")}
                  >
                    CASE ID{" "}
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    CLAIMANT
                  </Typography>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography className={classes.blueheading}>
                    RESPONDENT
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={2} style={{ paddingLeft: "10px" }}>
                  <Typography className={classes.blueheading}>LEVEL</Typography>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.blueheading}>
                    START DATE
                  </Typography>
                </Grid>
              </Grid>
              <Box height="0.4rem"></Box>
              {loadingGrievances ? (
                <CircularCenteredLoader />
              ) : (
                grievanceList.map((dispute) => {
                  return (
                    <GrievanceRow
                      key={dispute.id}
                      dispute={dispute}
                      setOpenCase={setOpenCase}
                      setCaseId={setCaseId}
                    />
                  );
                })
              )}
            </Grid>
          </Grid>
        ) : (
          <CaseDisplay
            setOpenCase={setOpenCase}
            isSmallScreen={isSmallScreen}
            caseId={caseId}
            mode={mode}
          />
        )}
      </div>
    );
  }
}
