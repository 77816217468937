import React, { useState } from "react";
import DataAnalytics from "./DataAnalytics";

const STATES = {
  "Andaman & Nicobar Island": {
    data: [15, 10, 5],
  },
  "Andhra Pradesh": {
    data: [100, 53, 15],
  },
  "Arunanchal Pradesh": {
    data: [32, 10, 5],
  },
  Assam: {
    data: [56, 20, 5],
  },
  Bihar: {
    data: [54, 12, 4],
  },
  Chandigarh: {
    data: [56, 21, 15],
  },
  Chhattisgarh: {
    data: [150, 90, 20],
  },
  "Dadara & Nagar Haveli": {
    data: [10, 4, 1],
  },
  "Daman & Diu": {
    data: [10, 4, 1],
  },
  Delhi: {
    data: [100, 67, 21],
  },
  Goa: {
    data: [98, 54, 43],
  },
  Gujarat: {
    data: [78, 34, 12],
  },
  Haryana: {
    data: [78, 65, 15],
  },
  "Himachal Pradesh": {
    data: [23, 10, 1],
  },
  "Jammu & Kashmir": {
    data: [153, 32, 16],
  },
  Jharkhand: {
    data: [98, 76, 32],
  },
  Karnataka: {
    data: [213, 91, 56],
  },
  Kerala: {
    data: [234, 130, 32],
  },
  Lakshadweep: {
    data: [32, 10, 5],
  },
  "Madhya Pradesh": {
    data: [98, 32, 12],
  },
  Maharashtra: {
    data: [234, 13, 10],
  },
  Manipur: {
    data: [234, 20, 15],
  },
  Meghalaya: {
    data: [43, 11, 9],
  },
  Mizoram: {
    data: [243, 131, 58],
  },
  Nagaland: {
    data: [34, 20, 15],
  },
  Odisha: {
    data: [234, 32, 12],
  },
  Puducherry: {
    data: [15, 10, 5],
  },
  Punjab: {
    data: [123, 32, 21],
  },
  Rajasthan: {
    data: [230, 124, 32],
  },
  Sikkim: {
    data: [123, 65, 8],
  },
  "Tamil Nadu": {
    data: [15, 10, 5],
  },
  Telangana: {
    data: [56, 20, 5],
  },
  Tripura: {
    data: [32, 10, 5],
  },
  "Uttar Pradesh": {
    data: [100, 53, 15],
  },
  Uttarakhand: {
    data: [15, 10, 5],
  },
  "West Bengal": {
    data: [56, 20, 5],
  },
};

const HEATMAPSTATES = [
  { id: "AP", state: "Andhra Pradesh", value: [15, 10, 5] },
  { id: "AR", state: "Arunachal Pradesh", value: [100, 53, 15] },
  { id: "AS", state: "Assam", value: [32, 10, 5] },
  { id: "BR", state: "Bihar", value: [56, 20, 5] },
  { id: "CT", state: "Chhattisgarh", value: [54, 12, 4] },
  { id: "GA", state: "Goa", value: [56, 21, 15] },
  { id: "GJ", state: "Gujarat", value: [150, 90, 20] },
  { id: "HR", state: "Haryana", value: [10, 4, 1] },
  { id: "HP", state: "Himachal Pradesh", value: [10, 4, 1] },
  { id: "JH", state: "Jharkhand", value: [100, 67, 21] },
  { id: "KA", state: "Karnataka", value: [98, 54, 43] },
  { id: "KL", state: "Kerala", value: [78, 34, 12] },
  { id: "MP", state: "Madhya Pradesh", value: [78, 65, 15] },
  { id: "MH", state: "Maharashtra", value: [23, 10, 1] },
  { id: "MN", state: "Manipur", value: [213, 91, 56] },
  { id: "ML", state: "Meghalaya", value: [98, 76, 32] },
  { id: "MZ", state: "Mizoram", value: [153, 32, 16] },
  { id: "NL", state: "Nagaland", value: [234, 130, 32] },
  // { id: 'OR', state: 'Odisha', value: [32, 10, 5] },
  { id: "PB", state: "Punjab", value: [98, 32, 12] },
  { id: "RJ", state: "Rajasthan", value: [234, 13, 10] },
  { id: "SK", state: "Sikkim", value: [234, 20, 15] },
  { id: "TN", state: "Tamil Nadu", value: [43, 11, 9] },
  // { id: 'TG', state: 'Telangana', value: [243, 131, 58] },
  { id: "TR", state: "Tripura", value: [34, 20, 15] },
  // { id: 'UT', state: 'Uttarakhand', value: [234, 32, 12] },
  { id: "UP", state: "Uttar Pradesh", value: [15, 10, 5] },
  { id: "WB", state: "West Bengal", value: [123, 32, 21] },
  { id: "AN", state: "Andaman and Nicobar Islands", value: [556, 220, 85] },
  { id: "CH", state: "Chandigarh", value: [123, 65, 8] },
  { id: "DN", state: "Dadra and Nagar Haveli", value: [365, 250, 45] },
  { id: "DD", state: "Daman and Diu", value: [15, 10, 5] },
  { id: "DL", state: "Delhi", value: [56, 20, 5] },
  { id: "JK", state: "Jammu and Kashmir", value: [32, 10, 5] },
  { id: "LA", state: "Ladakh", value: [100, 53, 15] },
  { id: "LD", state: "Lakshadweep", value: [15, 10, 5] },
  { id: "PY", state: "Puducherry", value: [56, 20, 5] },
  { id: "TS", state: "Telangana", value: [45, 20, 6] },
  { id: "UK", state: "Uttarakhand", value: [56, 20, 5] },
  { id: "OD", state: "Odisha", value: [56, 20, 5] },
  { id: "-99", state: "Andhra Pradesh", value: [1, 1, 1] },
];

export default function DataAnalyticsContainer() {
  const [type, setType] = useState();
  const [choosestep2, setChoosestep2] = useState();

  const investor = [7642, 549, 201];
  const [activeState, setactiveState] = useState();
  const stateLists = STATES;
  const [data, setData] = useState([]);
  const stateOnClick = (data, name, id) => {
    let c = choosestep2;
    setChoosestep2();
    // setData(data.data);
    setData(data.states);
    setactiveState({ data, name, id });
    setChoosestep2(c);
  };

  return (
    <DataAnalytics
      type={type}
      setType={setType}
      choosestep2={choosestep2}
      setChoosestep2={setChoosestep2}
      data={activeState ? data : investor}
      // states={STATES}
      states={HEATMAPSTATES}
      stateLists={stateLists}
      stateOnClick={stateOnClick}
      activeState={activeState}
      setactiveState={setactiveState}
    />
  );
}
