import { Box, Button, Divider, makeStyles, Typography } from "@material-ui/core";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getFiles } from "slices/groupSlice";
import useDeepCompareEffect from "use-deep-compare-effect";
import { downloadDoc } from "utils/apituils";
import FilesIcon from '../../../images/Clipboard.svg';
import DownloadIcon from '../../../images/DownloadIcon.svg';

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

const useStyles = makeStyles((theme) => ({
  fileCard: {
    // borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
    background: '#fff',
    display: 'flex',
    margin: '10px',
    paddingRight: '20px'
  },
  filesContainer: {
    "&:last-child": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  greyColor: {
    color: theme.palette.common.grey1,
    paddingTop: "2rem",
    width: '50%'
  },
  imageWrapper: {
    borderRadius: '50%',
    width: '38px',
    justifyContent: 'center',
    alignItems: 'center',
    height:'38px',
    display: 'flex',
    background: '#F8F8F8'
  },
  downlodIconPdf: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%'
  }
}));

export default function Files({ handleOpen, filterAudioFiles, isRecordingOrFiles, setIsRecordingOrFiles }) {
  const { activeGroup, files: allFiles } = useSelector(
    (state) => state.groups,
    shallowEqual
  );
  let files = filterAudioFiles(allFiles);
  const messages = useSelector((state) => state.chat.messages);
  const dispatch = useDispatch();
  const classes = useStyles();

  const messagesWithFiles = messages.filter((message) => message.file);

  useDeepCompareEffect(() => {
    activeGroup && dispatch(getFiles(activeGroup));
  }, [activeGroup, messagesWithFiles]);

  return (
    <Box className="hearingroomstep6" >
      {/* <Box display="flex" className={classes.greyColor} p="0 0 0 1rem">
        <PermMediaIcon />
        <Box width="0.4rem"></Box>
        <Typography variant="subtitle1">Shared Media</Typography>
      </Box> */}
      <Box height="1rem"></Box>
      {!isRecordingOrFiles && 
      <Box className={classes.filesContainer}>
        {files.length > 0 &&
          files.map((file, index) => {
            return (
              <React.Fragment key={index}>
                {index < 4 && (
                  <Box
                    // onClick={() => {
                    //   downloadDoc(file.path);
                    // }}
                    key={file._id}
                    p="1rem 0 1rem 1rem"
                    className={classes.fileCard}
                    textAlign="start"
                  >
                    {/* <Typography variant="subtitle1">
                      {truncate(file.name, 30)}
                    </Typography>
                    <Typography variant="caption">{file.user.name}</Typography> */}
                     <div className={classes.imageWrapper}><img src={FilesIcon}/></div>
                    <div className={classes.pdfTextWrap}>
                      <Typography variant="subtitle1">
                        {truncate(file.name, 30)}
                      </Typography>
                      <Typography variant="caption">{file.user.name}</Typography>
                    </div>
                    <div className={classes.downlodIconPdf} onClick={() => {downloadDoc(file.path)}}><img src={DownloadIcon}/></div>
                  </Box>
                )}
              </React.Fragment>
            );
          })}
      </Box>
      }
      <Box height="0.5rem"></Box>
      {files.length > 4 && (
        <Box display="flex" justifyContent="center">
          <Button color="primary" onClick={handleOpen}>
            View More
          </Button>
        </Box>
      )}
    </Box>
  );
}
