import { Box } from "@material-ui/core";
import { CLAIMANT } from "app/constants";
import React, { Fragment } from "react";
import ClaimantFlowSteps from "./ClaimantFlowSteps";
export default function SingleDispute({
  profile,
  activeCase,
  activeDispute,
  companyFlows,
}) {
  let flows = companyFlows.filter((companyFlow) => {
    return companyFlow.company_product === activeDispute.company_product;
  });
  switch (activeCase.type) {
    case CLAIMANT:
      return (
        <Fragment>
          <Box height="2rem"></Box>
          <ClaimantFlowSteps
            profile={profile}
            activeCase={activeCase}
            activeDispute={activeDispute}
            flows={flows}
          />
        </Fragment>
      );
      break;

    default:
      return "Unknown step";
  }
}
