import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import {
  ARBITRATOR_COMPLETE,
  CLAIMANT,
  CLAIMANT_ADVOCATE_COMPLETE,
  NEW,
  RESPONDENT,
  RESPONDENT_ADVOCATE_COMPLETE,
  RESPONDENT_COMPLETE,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { DetailsSchema } from "schemas/DetailsSchema";
import { createCase, updateCase } from "slices/casesSlice";
import { updateDispute } from "slices/disputeSlice";
import { createProfileIfNot } from "slices/profileSlice";
import * as Yup from "yup";

const DetailsSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .min(6, "Password Too Short!")
    .max(20, "Password Too Long!")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
  phoneNumber: Yup.string()
    .required("phone number is required")
    ,
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const getTitle = (rvStatus) => {
  switch (rvStatus) {
    case CLAIMANT_ADVOCATE_COMPLETE:
      return "Insert your lawyer's details";
    case RESPONDENT_COMPLETE:
      return "Insert Respondent Details";
    case RESPONDENT_ADVOCATE_COMPLETE:
      return "Insert your lawyer's details";
    case ARBITRATOR_COMPLETE:
      return "Insert Arbitrator Details";
  }
};

const DetailsForm = ({
  activeDispute,
  caseFileType,
  activeCase,
  rvStatus,
  profileType,
  profileData: {
    user: { email = "", first_name = "", last_name = "" } = {},
    phone_number = "",
  },
  empty: profileEmpty,
  step
}) => {
  const dispatch = useDispatch();
  const caseLoading = useSelector((state) => state.cases.loading);
  const disputeLoading = useSelector((state) => state.disputes.loading);
  let history = useHistory();

  const [phoneError, setPhoneError] = useState();
  const onPhoneChange = (e) => { 
    setPhoneError()
    validatePhone(e.target.value)
      formik.setFieldValue('phoneNumber',e.target.value)
   }

  const validatePhone = (v) => {
    let ph = v;
    console.log("phone", ph);
    
    let countspace = (ph.match(/ /g) || []).length;
    if (countspace > 0) {
      if (countspace > 1) {
          setPhoneError("Invalid Mobile Number. Please only enter numbers.");
        return false
      } else {
        if (/^\d+$/.test(ph.split(" ")[1])) {
          return true;
        } else {
            setPhoneError("Invalid Mobile Number. Please only enter numbers.");
          return false;
        }
      }
    } else {
      if (/^\d+$/.test(ph)) {
        return true
      } else {
          setPhoneError("Invalid Mobile Number. Please only enter numbers.");
        return false;
      }
    }
  };

  const debouncedOnSubmit = debounce(async (values) => {
    const profileBody = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      phoneNumber: values.phoneNumber,
      profileType,
    };

    try {
      let profile = await dispatch(createProfileIfNot(profileBody));

      if (activeCase.type === CLAIMANT) {
        switch (step) {
          case 0:
            // update claimant lawyer and update dispute with rv status

            await dispatch(
              updateCase({ id: activeCase.id, lawyer: profile.id })
            );
            await dispatch(
              updateDispute({
                id: activeDispute.id,
                rv_status: CLAIMANT_ADVOCATE_COMPLETE,
                rv_claimant_lawyer_step_complete:true
              })
            );
            return;
          case 1:
            console.log("claimant lawyer");

            // create respondent  case and update dispute with rv status
            const name = `${activeDispute.name} ${profile.user.first_name} ${profile.user.last_name}`;

            const caseBody = {
              summary: name,
              description: name,
              type: RESPONDENT,
              profile: profile.id,
              dispute: activeDispute.id,
            };
            await dispatch(createCase(caseBody));
            await dispatch(
              updateDispute({
                id: activeDispute.id,
                rv_status: RESPONDENT_COMPLETE,
                rv_respondent_choose_step_complete:true,
                name,
                description: name,
              })
            );
            return;

          case 2:
            // update dispute with arbitrator and rv_status
            let ids = []
            ids.push(profile.id)
            await dispatch(
              updateDispute({
                id: activeDispute.id,
                rv_status: ARBITRATOR_COMPLETE,
                rv_arbitrator_step_complete:true,
                professionals: ids,
              })
            );
            return;
        }
      } else if (activeCase.type === RESPONDENT) {
        if (activeDispute.rv_status === ARBITRATOR_COMPLETE) {
          // update respondent case with lawyer and update dispute with rv_status
          await dispatch(updateCase({ id: activeCase.id, lawyer: profile.id }));
          await dispatch(
            updateDispute({
              id: activeDispute.id,
              rv_status: RESPONDENT_ADVOCATE_COMPLETE,
            })
          );
        }
      }

      // history.push(`/disputes/${activeDispute.id}`);
    } catch (err) {
      console.log(err);
    }
  }, 1000);

  const formik = useFormik({
    initialValues: {
      email: email,
      firstName: first_name,
      lastName: last_name,
      password: "Test@123",
      confirmPassword: "Test@123",
      phoneNumber: phone_number,
    },
    onSubmit: debouncedOnSubmit,
    validationSchema: DetailsSchema,
  });
  return (
    <Box p="2rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box p="0rem 2rem">
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" gutterBottom align="center">
                  {getTitle(rvStatus)}
                </Typography>
                <Box height="1rem"></Box>

                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.email}
                  required
                  fullWidth
                  id="email"
                  label={<FormattedMessage id="user.email" />}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.email && formik.touched.email && (
                  <Alert severity="error">{formik.errors.email}</Alert>
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.firstName}
                  required
                  fullWidth
                  id="firstName"
                  label={<FormattedMessage id="user.firstName" />}
                  name="firstName"
                  autoComplete="firstName"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <Alert severity="error">{formik.errors.firstName}</Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.lastName}
                  required
                  fullWidth
                  id="lastName"
                  label={<FormattedMessage id="user.lastName" />}
                  name="lastName"
                  autoComplete="lastName"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <Alert severity="error">{formik.errors.lastName}</Alert>
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="phoneNumber"
                  label={<FormattedMessage id="user.phoneNumber" />}
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onBlur={onPhoneChange}
                  onChange={onPhoneChange}
                  disabled={!profileEmpty || !!phone_number}
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <Alert severity="error">{formik.errors.phoneNumber}</Alert>
                )}
                {phoneError && <Alert severity="error">{phoneError}</Alert>}


                <Box height="1rem"></Box>

                <Box display="flex" justifyContent="center">
                  {caseLoading || disputeLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={caseLoading || disputeLoading}
                    >
                      {<FormattedMessage id="buttons.submit" />}
                    </Button>
                  )}
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};

export default DetailsForm;
