import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  companies: [],
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.companies = payload.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    setCompanyOfferings: (state) => {},
  },
});

export const { setProgress, setSucessfull, setError } = companiesSlice.actions;
export default companiesSlice.reducer;

export function getCompanies() {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/company/",
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data.results));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError);
      }
    );
  };
}

export function getCompaniesByGroupAnonymous(groupName) {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/companyanonymousall/",
      params: {
        group: groupName,
      },
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data.results));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError);
      }
    );
  };
}

export function getCompaniesByGroup(groupName) {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/companyall/",
      params: {
        group: groupName,
      },
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data.results));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError);
      }
    );
  };
}

export async function getCompanyProductsAnonymous(id) {
  return instance({
    method: "get",
    url: "/api/companyproductanonymous/",
    params: {
      company: id,
    },
  }).then(
    (response) => {
      console.log(response.data);

      return response.data.results;
    },
    (error) => {
      console.log(error);
      return error;
    }
  );
}

export async function getCompanyProducts(id) {
  return instance({
    method: "get",
    url: "/api/companyproduct/",
    params: {
      company: id,
    },
  }).then(
    (response) => {
      console.log(response.data);

      return response.data.results;
    },
    (error) => {
      console.log(error);
      return error;
    }
  );
}
