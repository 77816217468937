import { Box, Grid, Button, Paper, Hidden } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { getWindowDimension } from "utils/responsiveUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Overview from "./SuperAdmin/Overview";
import Clients from "./SuperAdmin/Clients";
import CreateAccount from "./SuperAdmin/CreateAccount";
import Drive from "./SuperAdmin/Drive";
import Calender from "./SuperAdmin/Calender";
import CaseManager from "./SuperAdmin/CaseManager";
import ADRP from "./SuperAdmin/ADRP";
import UserDetails from "./SuperAdmin/UserDetails";
import Permission from "./SuperAdmin/Permission";
import CreateMeeting from "./SuperAdmin/CreateMeeting";
import CreateSebiDispute from "./SuperAdmin/CreateSebiDispute";
import InvoiceList from "./Common/InvoiceList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minHeight: "95vh",
    // height: "100%",
    borderRadius: "1rem",
    // flexDirection: (props) => (props.smallScreen ? "column" : "row"),
  },
  sidebar: {
    background: "#2C2C2C",
    width: "23rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sidebarItem: {
    //#A4A6B3;
    fontSize: "16px",
    padding: "0.5rem 4rem",
    color: "#A4A6B3",
    letterSpacing: "0.2px",
  },
  selected: {
    backgroundColor: "rgba(159, 162, 180, 0.08)",
    // opacity: 0.08,
    // color:"#FFFFFF",
    borderLeft: "2px solid #FFFFFF;",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
    height: "auto",
  },
  secretariatName: {
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
}));

export default function SuperAdmin({ profile }) {
  const classes = useStyles();
  let history = useHistory();

  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  const isSmallScreen = () => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let t = sessionStorage.getItem("sidebarItem");
  const [selected, setSelected] = useState(isSmallScreen() ? 0 : t ? t : 1);
  const [step, setStep] = useState(0);

  const MobileView = ({ step }) => {
    switch (step) {
      case 0:
        return <Sidebar />;
      case 1:
        return <Body />;
      default:
        return null;
    }
  };

  const removeSessionStorage = () => {
    sessionStorage.removeItem("overviewStep");
    sessionStorage.removeItem("overviewG");
    sessionStorage.removeItem("overviewC");
    sessionStorage.removeItem("overviewR");
    sessionStorage.removeItem("company");
    sessionStorage.removeItem("mode");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("caseid");
    sessionStorage.removeItem("subAdminInSuperAdminOverviewStep");
    sessionStorage.removeItem("clientsStep");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("generaluserpageNo");
    sessionStorage.removeItem("generaluserTotalPageNo");
    sessionStorage.removeItem("company_id");
  };

  const RenderOptions = () => {
    if (selected == 1 || selected == 0) {
      sessionStorage.setItem("sidebarItem", 1);
      return (
        <Overview
          setSelected={setSelected}
          setStep={setStep}
          profile={profile}
          isSmallScreen={isSmallScreen}
        />
      );
    }
    if (selected == 2) {
      sessionStorage.setItem("sidebarItem", 2);
      return <Clients profile={profile} isSmallScreen={isSmallScreen} />;
    }
    if (selected == 3) {
      sessionStorage.setItem("sidebarItem", 3);
      return <CreateAccount profile={profile} />;
    }
    if (selected == 4) {
      sessionStorage.setItem("sidebarItem", 4);
      return <UserDetails profile={profile} />;
    }
    if (selected == 5) {
      sessionStorage.setItem("sidebarItem", 5);
      return <CaseManager profile={profile} />;
    }
    if (selected == 6) {
      sessionStorage.setItem("sidebarItem", 6);
      return <ADRP profile={profile} />;
    }
    if (selected == 7) {
      sessionStorage.setItem("sidebarItem", 7);
      return <Permission profile={profile} />;
    }
    if (selected == 8) {
      sessionStorage.setItem("sidebarItem", 8);
      return <Drive profile={profile} />;
    }
    if (selected == 9) {
      sessionStorage.setItem("sidebarItem", 9);
      return <Calender profile={profile} />;
    }
    if (selected == 10) {
      sessionStorage.setItem("sidebarItem", 10);
      return <CreateMeeting profile={profile} showExcelUpload={true}/>;
    }
    if (selected == 11) {
      sessionStorage.setItem("sidebarItem", 11);
      return <CreateSebiDispute profile={profile} />;
    }
    if (selected == 12) {
      sessionStorage.setItem("sidebarItem", 12);
      return <InvoiceList profile={profile} />
    }

  };

  const Sidebar = () => {
    return (
      <Box className={classes.sidebar}>
        <Box height="1rem"></Box>
        <Typography
          style={{
            padding: "15px 20px 30px 20px",
            fontSize: "18px",
            color: "rgba(238, 238, 238, 0.7)",
            fontWeight: "bold",
          }}
        >
          Dashboard
        </Typography>
        {/* <Hidden smDown>
          <Box height="2rem"></Box>
        </Hidden> */}

        <List style={{ paddingTop: "0" }}>
          {selected == 1 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(1);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Overview
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(1);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>Overview</Typography>
            </ListItem>
          )}
          {selected == 2 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(2);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Clients
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(2);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>Clients</Typography>
            </ListItem>
          )}
          {selected == 3 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(3);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Create Accounts
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(3);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Create Accounts
              </Typography>
            </ListItem>
          )}
          {selected == 4 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(4);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                User Details
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(4);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                User Details
              </Typography>
            </ListItem>
          )}
          {selected == 5 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(5);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Case Managers
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(5);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Case Managers
              </Typography>
            </ListItem>
          )}
          {selected == 6 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(6);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                ADRPs
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(6);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>ADRPs</Typography>
            </ListItem>
          )}
          {selected == 7 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(7);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Permissions
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(7);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Permissions
              </Typography>
            </ListItem>
          )}
          {selected == 8 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(8);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Drive
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(8);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>Drive</Typography>
            </ListItem>
          )}
          {selected == 9 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(9);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Calender
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(9);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>Calender</Typography>
            </ListItem>
          )}
          {selected == 10 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(10);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Create Meeting
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(10);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>Create Meeting</Typography>
            </ListItem>
          )}
           {selected == 11 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(11);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Create Sebi Dispute
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(11);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>Create Sebi Dispute</Typography>
            </ListItem>
          )}
          {selected == 12 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(12);
                removeSessionStorage();
                setStep(1);
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Invoices
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(12);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>Invoices</Typography>
            </ListItem>
          )}
        </List>
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box className={classes.body2}>
        <Hidden mdUp>
          <Paper
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
              display: "flex",
              justifyContent: "flex-start",
              padding: "15px 20px",
            }}
          >
            <Button
              size="large"
              onClick={(e) => {
                setStep(0);
                setSelected(0);
                history.push("/admin");
              }}
              startIcon={<ArrowBackIosIcon />}
            >
              <FormattedMessage id="buttons.back" />
            </Button>
          </Paper>
        </Hidden>
        <Box className={classes.body}>
          <RenderOptions />
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        {isSmallScreen() ? (
          <MobileView step={step} />
        ) : (
          <>
            <Sidebar />
            <Body />
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
