import React from "react";
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heading: {
    "font-weight": "bold",
    "font-size": "14px",
    "line-height": "16px",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  text: {
    "font-size": "16px",
    "line-height": "19px",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

export default function ClientsList({ data }) {
  const classes = useStyles();

  return (
    <>
      <Paper
        style={{
          background: "none",
          border: "none",
          boxShadow: "none",
          padding: "18px 20px",
        }}
      >
        <Grid container justify="flex-start">
          <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>NAME</Typography>
          </Grid>
          <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>LEVEL</Typography>
          </Grid>
          <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>OPEN</Typography>
          </Grid>
          <Grid item sm={2} xs={2} style={{ textAlign: "left" }}>
            <Typography className={classes.heading}>CLOSED</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box height="0.5rem" />
      {data.map((item) => {
        if (item.company_id != 51)
          return (
            <>
              <Paper style={{ padding: "18px 20px" }}>
                <Grid container justify="flex-start">
                  <Grid item sm={4} xs={4} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {/* {item.mode} */}
                      {"--"}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {item.open}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} xs={2} style={{ textAlign: "left" }}>
                    <Typography className={classes.text}>
                      {item.closed}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Box height="0.5rem" />
            </>
          );
      })}
    </>
  );
}
