import {
  Backdrop,
  Box,
  Button,
  Fade,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { createCompany } from "./Helper";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "80%",
      padding: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      height: "80%",
      padding: "0.5rem",
    },
  },
  heading: {
    fontSize: "2rem",
  },
  buttonWrap: {
    display: "flex",
    justifyContent: "center",
  }
}));

const Schema = Yup.object().shape({
  company_name: Yup.string().required("Company Name is required"),
  email: Yup.string().required("Email is required").email("Invalid email"),
  phone: Yup.number().required("Phone Number is required"),
});

export default function CreateCompany({ openmodal, handleCloseModal, closeModal }) {
  const classes = useStyles();
  const [error, setError] = useState();

  const formik = useFormik({
    initialValues: {
      company_name: "",
      name: "",
      email: "",
      phone: "",
    },
    // onSubmit: debouncedOnSubmit,
    onSubmit: (values) => {
      console.log("values", values);
      if (!values.company_name.trim()) {
        formik.setErrors({ company_name: "Company Name is required" });
      } else {
        createCompany(
          values.company_name,
          values.name,
          values.email,
          values.phone
        )
          .then((res) => {
            console.log("res", res);
            handleCloseModal(res.data.id);
          })
          .catch((error) => {
            console.log("error.response.data", error.response.data);
            if (error?.response?.data?.email) {
              setError(error.response.data.email[0]);
            } else if (error?.response?.data?.phone_number) {
              setError(error.response.data.phone_number[0]);
            }
          });
      }
    },
    validationSchema: Schema,
  });

  useEffect(()=>{
    setError('')
  },[formik.values.email])

  const body = (
    <Box className={classes.paper}>
      <Typography className={classes.heading}>Company Details</Typography>
      <Box height="1rem"></Box>
      <form onSubmit={formik.handleSubmit} data-testid="intro-form">
        <TextField
          id="company_name"
          name="company_name"
          label={"Company Name *"}
          variant="outlined"
          fullWidth={true}
          className={classes.item}
          onChange={formik.handleChange}
          value={formik.values.company_name}
        />
        {formik.errors.company_name && formik.touched.company_name ? (
          <Alert severity="error">{formik.errors.company_name}</Alert>
        ) : null}
        <Box height="1rem"></Box>
        <TextField
          id="name"
          name="name"
          label={"Name"}
          variant="outlined"
          fullWidth={true}
          className={classes.item}
          onChange={formik.handleChange}
          value={formik.values.name}
        />
        {formik.errors.name && formik.touched.name ? (
          <Alert severity="error">{formik.errors.name}</Alert>
        ) : null}
        <Box height="1rem"></Box>
        <TextField
          id="email"
          name="email"
          label={"Email *"}
          variant="outlined"
          fullWidth={true}
          className={classes.item}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.errors.email && formik.touched.email ? (
          <Alert severity="error">{formik.errors.email}</Alert>
        ) : null}
        <Box height="1rem"></Box>
        <TextField
          id="phone"
          name="phone"
          label={"Phone Number *"}
          variant="outlined"
          fullWidth={true}
          type="number"
          className={classes.item}
          onChange={formik.handleChange}
          value={formik.values.phone}
        />
        {formik.errors.phone && formik.touched.phone ? (
          <Alert severity="error">{formik.errors.phone}</Alert>
        ) : null}
        {error && <Alert severity="error">{error}</Alert>}
        <Box height="1rem"></Box>
        <div className={classes.buttonWrap}>
          <Box margin="10px" display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              data-testid="submit-intro-claimant"
            >
              Submit
            </Button>
          </Box>
          <Box margin="10px" display="flex" justifyContent="center">
            <Button
              onClick={closeModal}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </Box>
        </div>
      </form>
    </Box>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
