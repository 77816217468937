import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAnswers } from "slices/answerSlice";
import { getUserFiles } from "slices/disputeSlice";
import { getQuestions } from "slices/questionSlice";
import DetailsReview from "./DetailsReview";

export default function DetailsReviewContainer({ profile }) {
  let dispatch = useDispatch();

  let {
    // questions: { questions },
    // answers: { answers },
    disputes: { activeDispute, userFiles: files },
    cases: { activeCase },
  } = useSelector((state) => state, shallowEqual);

  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState();

  // let loading = isEmpty(questions) || isEmpty(answers);

  const [questionsloading, setQuestionsloading] = useState(true);
  const [answerlaoding, setAnswerlaoding] = useState(true);

  const [userFiles, setUserFiles] = useState([]);

  const getQuestionsAnswers = () => {
    dispatch(getQuestions(activeDispute.company_product, activeCase.type)).then(
      (res) => {
        setQuestions(res.results);
        setQuestionsloading(false);
      }
    );
    dispatch(getAnswers(activeCase.id)).then((res) => {
      setAnswers(res.results);
      setAnswerlaoding(false);
    });
  };

  useEffect(() => {
    getQuestionsAnswers();
    dispatch(getUserFiles(activeDispute.id));
  }, []);

  React.useEffect(() => {
    let f = [];
    files.forEach((file) => {
      if (file.is_attachment) {
        f.push(file);
      }
    });
    setUserFiles(f);
  }, [files]);

  let childProps = {
    activeCase,
    activeDispute,
    questions,
    answers,
    profile,
    userFiles,
    getQuestionsAnswers,
  };

  return (
    <React.Fragment>
      {!questionsloading && !answerlaoding ? (
        <DetailsReview {...childProps} />
      ) : (
        <CircularProgress />
      )}
    </React.Fragment>
  );
}
