import DateFnsUtils from "@date-io/date-fns";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, parseISO } from "date-fns";
import { NumberFieldRange } from "features/intro/AnonymousDisputeFlow/Question/Question";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(2),
    width: "100%",
    borderRadius: "0.3rem",
    resize: "none",
    padding: theme.spacing(1),
    fontSize: "1.5rem",
  },
  container: {
    width: 700,
  },
  number: {
    width: "3em",
  },
}));

const Question = ({ question, answerValues, index }) => {
  const radioName = Math.floor(Math.random() * 1000000).toString();

  const classes = useStyles();
  let element;

  const [booleanValue, setBooleanValue] = React.useState(
    answerValues.has(question.id)
      ? answerValues.get(question.id) === "true"
      : false
  );

  const [value, setValue] = React.useState(
    answerValues.has(question.id) ? answerValues.get(question.id) : ""
  );

  const [dateValue, setDataValue] = React.useState(
    answerValues.has(question.id) && question.type === "date"
      ? answerValues.get(question.id) !== null
        ? parseISO(answerValues.get(question.id))
        : null
      : setupInitialDate()
  );

  // if(question.type === "date"){
  // }

  function setupInitialDate() {
    let date = new Date();
    if (question.type === "date") {
      setDateInAnswer(date);
    }
    return date;
  }

  const handleDate = (event) => {
    let value = event;
    setDataValue(value);
    setDateInAnswer(value);
  };

  function setDateInAnswer(date) {
    answerValues.set(question.id, format(date, "yyyy-MM-dd"));
  }

  const [selectedDate, handleDateChange] = React.useState(new Date());

  const [timeValue, setTimeValue] = React.useState(
    answerValues.has(question.id) && question.type === "time"
      ? console.log("setAnswermm: ", answerValues.get(question.id))
      : setupInitialTime()
  );

  function setupInitialTime() {
    let time = new Date(2018, 11, 24, 0, 0, 0, 0);
    if (question.type === "time") {
      setTimeInAnswer(time);
    }
    return time;
  }

  const handleTime = (event) => {
    let value = event;
    setTimeValue(value);
    setTimeInAnswer(value);
  };

  function setTimeInAnswer(time) {
    answerValues.set(question.id, format(time, "HH:mm:ss"));
  }

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
    answerValues.set(question.id, event.target.value);
  };

  const handleChangeDropdown = (event) => {
    setValue(event.target.value);
    answerValues.set(question.id, event.target.value);
  };

  const handleChangeChecked = (event) => {
    let checked = event.target.checked;
    setBooleanValue(checked);
    answerValues.set(question.id, checked);
  };

  let numericRexEx = /^[0-9\b]+$/;

  const handleNumericValue = (event) => {
    let currentValue = event.target.value;

    if (currentValue === "" || numericRexEx.test(currentValue)) {
      setValue(currentValue);
      answerValues.set(question.id, currentValue);
    }
  };

  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [hour, setHour] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const handleHourChange = (e) => {
    setHour(e.target.value);
    answerValues.set(
      question.id,
      e.target.value + ":" + minutes + ":" + seconds
    );
  };
  const handleMinuteChange = (e) => {
    setMinutes(e.target.value);
    answerValues.set(question.id, hour + ":" + e.target.value + ":" + seconds);
  };
  const handleSecondChange = (e) => {
    setSeconds(e.target.value);
    answerValues.set(question.id, hour + ":" + minutes + ":" + e.target.value);
  };

  let prev_range = answerValues.has(question.id)
    ? answerValues.get(question.id)
      ? answerValues.get(question.id)
      : ""
    : "";
  let arr = prev_range.toString().split(/[: ]/);

  const [hour1, setHour1] = React.useState(arr[0] ? arr[0] : 0);
  const [minutes1, setMinutes1] = React.useState(arr[1] ? arr[1] : 0);
  const [seconds1, setSeconds1] = React.useState(arr[2] ? arr[2] : 0);

  const [hour2, setHour2] = React.useState(arr[4] ? arr[4] : 0);
  const [minutes2, setMinutes2] = React.useState(arr[5] ? arr[5] : 0);
  const [seconds2, setSeconds2] = React.useState(arr[6] ? arr[6] : 0);

  React.useEffect(() => {
    if (question.type === "time_range") {
      answerValues.set(
        question.id,
        hour1 +
          ":" +
          minutes1 +
          ":" +
          seconds1 +
          " to " +
          hour2 +
          ":" +
          minutes2 +
          ":" +
          seconds2
      );
    }
  }, [hour1, minutes1, seconds1, hour2, minutes2, seconds2]);

  if (question.type === "boolean") {
    element = (
      <>
        {" "}
        <Switch
          data-testid={`claimant-question-${index}`}
          checked={booleanValue}
          onChange={handleChangeChecked}
          color="primary"
          name="checked"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </>
    );
  } else if (question.type === "simple") {
    element = (
      <>
        {" "}
        <TextareaAutosize
          data-testid={`claimant-question-${index}`}
          data-question-type="textarea"
          required
          label="Required"
          onChange={handleChangeRadio}
          className={classes.item}
          value={value}
          rowsMin={1}
          rowsMax={4}
          maxLength={2000}
        />
      </>
    );
  } else if (question.type === "number") {
    element = (
      <>
        <OutlinedInput
          data-testid={`claimant-question-${index}`}
          id="outlined-adornment-amount"
          name="outlined-adornment-amount"
          startAdornment={<InputAdornment position="start">₹</InputAdornment>}
          value={value}
          onChange={handleNumericValue}
        />
      </>
    );
  } else if (question.type === "radio" || question.type === "dropdown") {
    element = (
      <>
        <RadioGroup
          // data-testid={`claimant-question-${index}`}
          // aria-label="gender"
          // name="gender1"
          name={radioName}
          value={value}
          onChange={handleChangeRadio}
          className={classes.item}
        >
          <Box display="flex" flexDirection="column" alignItems="start">
            {JSON.parse(question.extra_args)["options"].map((value, index) => (
              <Box display="flex" style={{ margin: "0.5rem 0" }} key={index}>
                <input
                  type="radio"
                  id={index}
                  name={radioName}
                  value={value}
                  onChange={handleChangeRadio}
                  style={{ transform: "scale(1.5)", margin: "6px 3px 0px 6px" }}
                />
                <Box width="0.6rem"></Box>
                <Box alignItems="start" style={{ textAlign: "left" }}>
                  <Typography>
                    <label
                      for={index}
                      style={{
                        fontSize: "16px",
                        color: "#454545",
                        cursor: "pointer",
                      }}
                    >
                      {value}
                    </label>
                  </Typography>
                </Box>
              </Box>

              // <FormControlLabel
              //   value={value}
              //   control={<Radio color="primary" />}
              //   label={value}
              //   key={index}
              // />
            ))}
          </Box>
        </RadioGroup>
      </>
    );
  } else if (question.type === "dropdown_toBeCorrect") {
    element = (
      <>
        <InputLabel>Select</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          onChange={handleChangeDropdown}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </>
    );
  } else if (question.type === "date") {
    const today = new Date();
    const date = new Date();
    date.setDate(date.getDate() - 30);
    element = (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            minDate={date}
            maxDate={today}
            format="dd/MM/yyyy"
            value={dateValue}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={handleDate}
          />
        </MuiPickersUtilsProvider>
      </>
    );
  } else if (question.type === "time") {
    element = (
      <>
        <input
          className={classes.number}
          type="number"
          min="1"
          max="5"
          value={hour}
          onChange={handleHourChange}
        ></input>
        :
        <input
          className={classes.number}
          type="number"
          min="0"
          max="59"
          value={minutes}
          onChange={handleMinuteChange}
        ></input>
        :
        <input
          className={classes.number}
          type="number"
          min="0"
          max="59"
          value={seconds}
          onChange={handleSecondChange}
        ></input>
      </>
    );
  } else if (question.type === "time_range") {
    element = (
      <>
        <Typography style={{ lineHeight: "24px", fontSize: "14px" }}>
          {"From: "}
        </Typography>
        <Box width="0.8rem"></Box>
        <NumberFieldRange value={hour1} setValue={setHour1} maxValue={5} />
        :
        <NumberFieldRange
          value={minutes1}
          setValue={setMinutes1}
          maxValue={59}
        />
        :
        <NumberFieldRange
          value={seconds1}
          setValue={setSeconds1}
          maxValue={59}
        />
        <Box width="1.6rem"></Box>
        <Typography style={{ lineHeight: "24px", fontSize: "14px" }}>
          {"To: "}
        </Typography>
        <Box width="0.8rem"></Box>
        <NumberFieldRange value={hour2} setValue={setHour2} maxValue={5} />
        :
        <NumberFieldRange
          value={minutes2}
          setValue={setMinutes2}
          maxValue={59}
        />
        :
        <NumberFieldRange
          value={seconds2}
          setValue={setSeconds2}
          maxValue={59}
        />
      </>
    );
  }

  return (
    <Box>
      <Box textAlign="start">
        <Typography style={{ lineHeight: "24px", fontSize: "16px" }}>
          {question.name}
        </Typography>
      </Box>
      <Box height="0.8rem"></Box>
      <Box display="flex" justifyContent="start">
        {element}
      </Box>
    </Box>
  );
};
export default Question;
