import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Url } from "api/globalaxios";
import axios from "axios";
import { useSnackbar } from "contexts/SnackbarContext";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useState } from "react";
import { isLoggedIn } from "slices/loginSlice";

async function CreateAxioData(company_id, files, messaging_service) {
  const token = isLoggedIn();
  const data = new FormData();
  data.append("company_id", company_id);
  data.append("file", files[0]);
  data.append("messaging_service", messaging_service);
  return await axios.post(Url + "createaxiodata/", data, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "3rem",
    color: "#E0E0E0",
  },
  heading: {
    fontSize: "2.2rem",
    lineHeight: "36px",
    textAlign: "center",
  },
  firstheading: {
    margin: "2rem 0 0rem 0",
    fontSize: "16px",
    lineHeight: "36px",
    color: "#333333",
  },
  paper: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  box: {
    background: "#3F51B5",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
  },
  submit: {
    color: "#FFFFFF",
  },
  // text: {
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: "3rem",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     fontSize: "1.8rem",
  //   },
  // }
  btn: {
    flexGrow: 1,
    background: "#3F51B5",
    border: "1px solid #3F51B5",
    borderRadius: "4px",
    color: "#FFFFFF",
    height: "100%",
    textAlign: "left",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2c387e",
      color: "#FFFFFF",
      border: "1px solid transparent",
    },
  },
  hoverButton: {
    flexGrow: 1,
    background: "#3F51B5",
    border: "1px solid #3F51B5",
    borderRadius: "4px",
    color: "#FFFFFF",
    height: "100%",
    textAlign: "left",
    textTransform: "none",
    "&:hover": {
      background: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

export const UploadDialog = ({ open, setOpen, addFile }) => {
  const classes = useStyles();
  return (
    <Box textAlign="center">
      <Paper
        className={classes.paper}
        style={{ padding: "3rem", maxWidth: "100%" }}
      >
        <Box>
          <IconButton
            className={classes.icon}
            aria-label="delete"
            // color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddCircleOutlineIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Paper>

      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          files.forEach((file) => {
            addFile(file);
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
        acceptedFiles={[".xls", ".xlsx", "application/vnd.ms-excel"]}
      />
    </Box>
  );
};

export const renderFiles = (files, deleteFile) => {
  return files.length !== 0
    ? files.map((file, index) => {
        return (
          <Box key={index} textAlign="center">
            <Paper style={{ padding: "2rem", maxWidth: "100%" }}>
              <Typography variant="subtitle2">{file.name}</Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Paper>
          </Box>
        );
      })
    : null;
};

function UploadExcel() {
  const { showSnackbar } = useSnackbar();

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messaging_service, setMessaging_service] = useState("");
  const handleRadioChange = (e) => {
    setMessaging_service(e.target.value);
  };
  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };
  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };

  const onSubmit = async (e) => {
    if (messaging_service) {
      setLoading(true);
      // 5 - axios
      CreateAxioData(5, files, messaging_service)
        .then((res) => {
          console.log("res", res);
          if (res?.data?.status === "done") {
            showSnackbar(
              "Processsed. \nUsers created: " +
                res.data?.["users created"] +
                "\nProblem in rows: " +
                res.data?.["problem in rows"],
              "success"
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
          console.log("err?.response?.data", err?.response?.data);
          showSnackbar(err?.response?.data?.error, "error");
        });
      setLoading(false);
      setFiles([]);
    } else {
      showSnackbar("Messaging service not chosen", "error");
    }
  };

  return (
    <>
      <Typography>Upload Excel Sheet</Typography>
      {renderFiles(files, deleteFile)}
      {files.length === 0 && (
        <UploadDialog open={open} setOpen={setOpen} addFile={addFile} />
      )}
      <Box height="1.5rem"></Box>
      <Typography className={classes.text}>
        Please choose messaging service
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-messaging"
          value={messaging_service}
          onChange={handleRadioChange}
          name="radio-buttons-messaging"
        >
          <FormControlLabel
            value="whatsapp"
            control={<Radio />}
            label={<Typography className={classes.text}>Whatsapp</Typography>}
          />
          <FormControlLabel
            value="sms"
            control={<Radio />}
            label={<Typography className={classes.text}>SMS</Typography>}
          />
        </RadioGroup>
      </FormControl>
      <Box height="1.5rem"></Box>
      <Grid container justify="center">
        <Grid item style={{ display: "flex" }}>
          <DebouncedButton
            variant="contained"
            className={`${classes.btn}`}
            size="large"
            onClickHandler={onSubmit}
            disabled={loading || files.length === 0}
            loading={loading}
            value={<FormattedMessage id="buttons.submit" />}
            debounceTime={1000}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UploadExcel;
