import { createMuiTheme } from "@material-ui/core/styles";
import Robotottf from "assets/fonts/Roboto/Roboto-Bold.ttf";

const robotoRegular = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Roboto'),
    local('Roboto-Regular'),
    url(${Robotottf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3f51b5",
      // mainGradient: "linear-gradient(91.33deg, #60A9F6 0%, #2A8BF2 100%)",
      mainGradient: "#60A9F6",
    },
    secondary: {
      main: "#6FCF97",
    },
    secondPrimary: "linear-gradient(325.78deg, #2A8BF2 14.76%, #7CB8F7 87.3%)",

    common: {
      black: "#000",
      white: "#E5E5E5",
      grey: "#FAFBFF",
      grey1: "#707C97",
      yellow: "#FBD56F",
      red: "#FA8282",
      green: "#33B669",
      lightBlue: "#D3E5EB",
    },
    border: "rgba(112, 124, 151, 0.1)",
  },
  typography: {
    color: "#454545",
    subtitle1: {},
    subtitle2: {
      textTransform: "capitalize",
    },
    caption: {
      color: "#949DB1",
    },
    h5: {
      color: "#949DB1",
      fontWeight: "bold",
      fontSize: "2rem",
      lineHeight: "2.1rem",
    },
    h2: {
      color: "#454545",
      fontWeight: "bold",
    },
    body1: {
      color: "#454545",
      fontStyle: "normal",
      fontWeight: "normal",
      // fontSize: "1.3rem",
    },
    body2: {
      fontSize: "1.1rem",
    },
    h3: {
      fontSize: "3rem",
      lineHeight: "3rem",
      fontWeight: "bold",
      color: "#454545",
    },
    h6: {
      color: "#949DB1",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [robotoRegular],
        body: {
          backgroundColor: "#fff",
          fontFamily: "Roboto",
        },
        html: {
          scrollBehavior: "smooth",
        },
      },
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
