import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { RESPONDENT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";
import { isCDR, isHotStar, isMuthoot, isStar, isVikalp } from "Routes/Routes";
import { downloadLegalDoc } from "slices/disputeSlice";
import { DocGenerator } from "utils";

const useStyles = makeStyles((theme) => ({
  topText: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
  disputeheading: {
    fontSize: "26px",
    lineHeight: "36px",
    paddingTop: "35px",
    paddingLeft: "35px",
    paddingRight: "600px",
  },
  text: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    // background:"grey"
  },
  blue: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    color: "#3f51b5",
    lineHeight: "24px",
  },
  fonts: {
    lineHeight: "24px",
    fontSize: "16px",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
  },
  greenbutton: {
    backgroundColor: "#6FCF97",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    cursor: "pointer",
  },
  yellowbutton: {
    backgroundColor: "#F2C94C",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    cursor: "pointer",
  },
  item: {
    "&:hover": {
      // cursor: 'pointer',
      background: "#EEEEEE",
    },
  },
  closedbtn: {
    background: "#E0E0E0",
    color: "white",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  closed: {
    background: "#E0E0E0",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  calenderBtn: {
    fontSize: "16px",
    color: "#4F4F4F",
    textTransform: "none",
    borderRadius: "2px",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  joinbtn: {
    background: "#3f51b5",
    fontSize: "20px",
    color: "white",
    borderRadius: "2px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));
export default function DisputeDisplayHotstar({
  activeDispute,
  activeCase,
  profile,
}) {
  const classes = useStyles();
  const history = useHistory();

  // const [hearingDate, setHearingDate] = React.useState("--");

  // React.useEffect(() => {
  //   if (hearingDate == "--") {
  //     dispatch(getAllMeetings2());
  //   }
  // }, [hearingDate]);

  const [decisionFiles, setdecisionFiles] = React.useState(
    activeDispute.all_docs
  );
  const [finalDecision, setFinalDecision] = React.useState();

  const [isDisputeClosed, setIsDisputeClosed] = React.useState(
    activeDispute.status == "closed"
  );

  React.useEffect(() => {
    decisionFiles.forEach((file) => {
      if (file.is_final) {
        setFinalDecision(file.id);
      }
    });
  }, [decisionFiles]);

  return (
    <Container>
      <Box p="2rem 0">
        <Grid container>
          <Grid item md={2} sm={0} xs={0}></Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper
              style={{ background: "none", border: "none", boxShadow: "none" }}
            >
              <Button
                onClick={() => {
                  // history.push("/disputes/claimant/active");
                  // history.goBack();
                  activeDispute.status == "closed"
                    ? history.push(`/disputes/${activeCase.type}/resolved`)
                    : history.push(`/disputes/${activeCase.type}/active`);
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Paper>
            <Paper style={{ padding: "3.5rem" }}>
              {/* {(profile.profile_type == "general" ||
                profile.profile_type == "arbitrator" ||
                profile.profile_type == "guest_general" ||
                profile.profile_type == "guest_arbitrator") && (
                <Box width="15rem" className={classes.text}>
                  <Typography style={{ fontSize: "28px", lineHeight: "36px" }}>
                    {activeDispute.name}
                  </Typography>
                  <Box height="1rem"></Box>
                  <Typography
                    className={classes.blue}
                    onClick={() => {
                      DocGenerator(activeDispute.id, "claimant");
                    }}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    VIEW GRIEVANCE
                  </Typography>
                  <Box height="2rem"></Box>
                </Box>
              )} */}

              <Box className={classes.text}>
                <Typography style={{ fontSize: "2rem", lineHeight: "36px" }}>
                  {activeDispute.name}
                </Typography>
                <Box height="1rem"></Box>
              </Box>
              <Grid container direction="row">
                <Grid
                  item
                  xs
                  sm={3}
                  md={3}
                  style={{ padding: "10px 10px 10px 0" }}
                >
                  <Typography
                    className={classes.blue}
                    onClick={() => {
                      DocGenerator(activeDispute.id, "claimant");
                    }}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {isCDR || isVikalp ? (
                      <FormattedMessage id="dispute.statementOfClaim1" />
                    ) : (
                      "VIEW GRIEVANCE"
                    )}
                  </Typography>
                </Grid>
                {activeDispute.flow_type != "mediation" &&
                  activeDispute.flow_type != "negotiation" &&
                  !isHotStar && !isStar && !isMuthoot && (
                    <Grid
                      item
                      xs
                      sm={3}
                      md={3}
                      style={{ padding: "10px 10px 10px 0" }}
                    >
                      <Typography
                        className={classes.blue}
                        onClick={() => {
                          DocGenerator(activeDispute.id, RESPONDENT);
                        }}
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        <FormattedMessage id="dispute.statementOfDefence1" />
                      </Typography>
                    </Grid>
                  )}
                {activeDispute.flow_type != "mediation" &&
                  (isHotStar || isStar || isMuthoot) &&
                  activeCase.type != "claimant" &&
                  activeCase.type == "respondent" && (
                    <Grid
                      item
                      xs
                      sm={3}
                      md={3}
                      style={{ padding: "10px 10px 10px 0" }}
                    >
                      <Typography
                        className={classes.blue}
                        onClick={() => {
                          DocGenerator(activeDispute.id, RESPONDENT);
                        }}
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        VIEW RESPONSE
                      </Typography>
                    </Grid>
                  )}
              </Grid>
              <Box height="2rem"></Box>

              <Grid container className={classes.fonts}>
                <Grid item xs={12} md={6}>
                  {isCDR || isVikalp ? (
                    <a className={classes.blue}>Dispute ID </a>
                  ) : (
                    <a className={classes.blue}>Grievance ID </a>
                  )}

                  {activeDispute.id}
                  {/* {activeDispute.approved ? activeDispute.id : "--"} */}
                  <Box height="0.1rem"></Box>
                  {isCDR ||
                    (isVikalp && (
                      <>
                        <a className={classes.blue}>Mode </a>
                        {activeDispute.flow_type == "arbitration"
                          ? "Arbitration"
                          : activeDispute.flow_type == "mediation"
                          ? "Mediation"
                          : "Negotiation"}
                        {/* {isDisputeClosed ? "--" : hearingDate} */}
                        <Box height="0.1rem"></Box>
                      </>
                    ))}
                  <a className={classes.blue}>Decision </a>
                  {finalDecision ? (
                    <u
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                        color: "#2F80ED",
                        lineHeight: "24px",
                      }}
                      onClick={() => {
                        downloadLegalDoc(finalDecision);
                      }}
                    >
                      <a className={classes.blue}>VIEW</a>
                    </u>
                  ) : (
                    "(In Progress)"
                  )}
                  <Box height="0.1rem"></Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="row" justify="space-evenly">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ padding: "10px" }}
                    >
                      <Button
                        className={classes.calenderBtn}
                        variant="outlined"
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push("/events");
                        }}
                      >
                        <FormattedMessage id="buttons.calendar" />
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ padding: "10px" }}
                    >
                      <Button
                        variant="outlined"
                        className={classes.calenderBtn}
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push(`/disputes/${activeDispute.id}/files`);
                        }}
                      >
                        <FormattedMessage id="common.files" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Box display="flex" justifyContent="center">
                  <Button
                    style={{ margin: "0.5rem 0 0 0" }}
                    color="primary"
                    size="large"
                    startIcon={<VisibilityIcon />}
                    onClick={() => {
                      DocGenerator(activeDispute.id, "claimant");
                    }}
                  >
                    <FormattedMessage id="dispute.statementOfClaim" />
                  </Button>
                </Box>
                <Box display="flex" justifyContent="center">
                  {activeDispute.flow_type !== MEDIATION && (
                    <Button
                      style={{ margin: "0 0 0.5rem 0" }}
                      color="primary"
                      size="large"
                      startIcon={<VisibilityIcon />}
                      onClick={() => {
                        DocGenerator(activeDispute.id, RESPONDENT);
                      }}
                    >
                      <FormattedMessage id="dispute.statementOfDefence" />
                    </Button>
                  )}
                </Box> */}
            </Paper>
            <Box height="4rem"></Box>
            {!isHotStar && !isStar && !isMuthoot && (
              <Box display="flex" justifyContent="center">
                {isDisputeClosed ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.closedbtn}
                    // style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px" }}
                  >
                    <FormattedMessage id="common.goToHearingRooms" />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    // color="primary"
                    className={classes.joinbtn}
                    style={{
                      background: "#3f51b5",
                      fontSize: "1.2rem",
                      borderRadius: "2px",
                      color: "white",
                    }}
                    onClick={() => {
                      history.push(`/disputes/${activeDispute.id}/chat`);
                    }}
                  >
                    <FormattedMessage id="common.goToHearingRooms" />
                  </Button>
                )}
              </Box>
            )}
            <Box height="3rem"></Box>
          </Grid>
        </Grid>
      </Box>
      <Box height="5rem"></Box>
    </Container>
  );
}
