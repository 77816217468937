import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import CaseDisplay from "./SubAdminOverview/CaseDisplay";
import CaseDisplaySebi from "Admin/Sebi/CaseDisplay";

const useStyles = makeStyles((theme) => ({
  backbtn: {
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
      marginLeft: "20px",
    },
  },
  paperOpen: {
    padding: "20px 0",
    width: "100%",
    borderRadius: "8px",
  },
  headingOpen: {
    "font-weight": "500",
    "font-size": "20px",
    "line-height": "23px",
    "text-align": "center",
    "letter-spacing": "0.4px",
    color: "#454545",
  },
  numberOpen: {
    "font-weight": "500",
    "font-size": "40px",
    "line-height": "47px",
    "text-align": "center",
    "letter-spacing": "1px",
    color: "#2C2C2C",
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default function RV({ setOpenRV, openRVCount, isSmallScreen, profile }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  let r = sessionStorage.getItem("subAdminInSuperAdminOverviewStep");
  let i = sessionStorage.getItem("caseid");
  let s = sessionStorage.getItem('isSebiDispute');
  const [openCase, setOpenCase] = useState(r ? true : false);
  const [caseId, setCaseId] = useState(i ? i : null);
  const [isSebiDispute, setIsSebiDispute] = useState(s?true:false);

  const [finaldata, setFinaldata] = useState([]);

  React.useEffect(() => {
    dispatch(apiFunction()).then((res) => {});
  }, []);

  function apiFunction() {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/companybyplateformandgroup/?platform_type=rv`
        );

        setFinaldata(response.data.disputes);
        setLoading(false);
        return response.data.disputes;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    if (openCase) {
      return isSebiDispute ? (
      <CaseDisplaySebi
      setOpenCase={setOpenCase}
      isSmallScreen={isSmallScreen}
      caseId={caseId}
      mode={"RV"}
      profile={profile}
      />
      ) : (
      <CaseDisplay
      setOpenCase={setOpenCase}
      isSmallScreen={isSmallScreen}
      caseId={caseId}
      mode={"RV"}
      />
      );
    }
    return (
      <>
        <Grid container justify="flex-start" spacing={3} direction="row">
          <Grid container justify="flex-start">
            <Grid item style={{ padding: "0 10px" }}>
              <Button
                onClick={() => {
                  setOpenRV(false);
                  sessionStorage.removeItem("overviewR");
                  sessionStorage.setItem("overviewStep", 1);
                }}
                className={classes.backbtn}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Box height="3rem"></Box>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Paper className={classes.paperOpen}>
              <Typography className={classes.headingOpen}>RV</Typography>
              <Box height="0.5rem"></Box>
              <Typography className={classes.numberOpen}>
                {openRVCount}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={10} md sm={11} xs={12}>
            <Box height="4rem"></Box>
            {/* <ClientsList data={finaldata}/> */}
            <Box height="3rem"></Box>
            <Grid container justify="flex-start">
              <Grid item sm={3} xs={3}>
                <Typography className={classes.blueheading}>G. ID</Typography>
              </Grid>
              <Grid item sm={3} xs={3}>
                <Typography className={classes.blueheading}>
                  CLAIMANT
                </Typography>
              </Grid>
              <Grid item sm={3} xs={3}>
                <Typography
                  className={classes.blueheading}
                  style={{ lineBreak: "anywhere" }}
                >
                  RESPONDENT
                </Typography>
              </Grid>
              {/* <Grid item sm={2} xs={2}>
                <Typography className={classes.blueheading}>LEVEL</Typography>
              </Grid> */}
              <Grid item sm={3} xs={3}>
                <Typography className={classes.blueheading}>
                  START DATE
                </Typography>
              </Grid>
            </Grid>
            <Box height="0.4rem" />
            {finaldata.map((dispute) => {
              const split_by = dispute.name.includes(" v. ") ? "v." : "vs";
              const arr = dispute.name.split(split_by);
              const complainant = arr[0];
              const respondent = arr[1];
              return (
                <>
                  <Paper
                    style={{ padding: "10px 0", cursor: "pointer" }}
                    onClick={(e) => {
                      setOpenCase(true);
                      setCaseId(dispute.id);
                      sessionStorage.setItem(
                        "subAdminInSuperAdminOverviewStep",
                        2
                      );
                      sessionStorage.setItem("caseid", dispute.id);
                      if(dispute.description === "NSE" || dispute.description === "NSDL"){
                        setIsSebiDispute(true);
                        sessionStorage.setItem('isSebiDispute', true);
                      }
                    }}
                  >
                    <Grid container justify="flex-start">
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.blueheadingtext}>
                          {dispute.id}
                        </Typography>
                      </Grid>
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.blueheadingtext}>
                          {complainant}
                        </Typography>
                      </Grid>
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.blueheadingtext}>
                          {respondent}
                        </Typography>
                      </Grid>
                      {/* <Grid item sm={3} xs={3}>
                        <Typography className={classes.blueheadingtext}>
                          {level}
                        </Typography>
                      </Grid> */}
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.blueheadingtext}>
                          {formatDate(dispute.created)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Box height="0.6rem"></Box>
                </>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  }
}
