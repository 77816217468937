import React from "react";
import UserVerifyForm from "./UserVerifyForm";
import FullPageSpinner from "features/common/FullPageSpinner";
import { getProfiles } from "slices/profileSlice";
import { GENERAL } from "app/constants";

export default function UserVerifyFormContainer({
  profileFetched,
  setNotEmpty,
  claimantFormValues
}) {
  const [loading, setLoading] = React.useState(true);
  const [profiles, setProfiles] = React.useState([]);

  React.useEffect(() => {
    getProfiles(GENERAL).then((data) => {
      setProfiles(data);
      setLoading(false);
    });
  }, []);

  if (loading) return <FullPageSpinner />;

  const childProps = { profileFetched, setNotEmpty, profiles ,claimantFormValues};

  return <UserVerifyForm {...childProps} />;
}
