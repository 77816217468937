import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CreateNewLogin from "./CreateAccount/CreateNewLogin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
}));

export default function CreateAccount({ profile }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [profiles, setProfiles] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    dispatch(getCompaniesAndProfiles()).then(() => {});
  }, []);

  function getCompaniesAndProfiles() {
    return async (dispatch) => {
      try {
        const response = await instance.get(`/api/compniesandprofiletypes/`);
        let d = response.data;
        let Lprofiles = d.ProfileTypeChoices;
        let Lclients = d.company;
        const responseH = await instanceHotstar.get(
          `/api/compniesandprofiletypes/`
        );
        responseH.data.company.map((item) => {
          if (item[0] == 44) {
            Lclients.push(item);
          }
        });
        const responseDpcgc = await instanceDPCGC.get(
          `/api/compniesandprofiletypes/`
        );
        responseDpcgc.data.company.map((item) => {
          item.push({ platform: "dpcgc" });
          Lclients.push(item);
        });
        Lclients.sort(function (a, b) {
          var nameA = a[1].toUpperCase();
          var nameB = b[1].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setProfiles(Lprofiles);
        setClients(Lclients);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <div className={classes.root}>
        <Grid container justify="flex-start" direction="column">
          <Grid item>
            <Grid
              container
              justify="space-between"
              direction="row"
              style={{ padding: "10px" }}
            >
              <Grid item>
                <Typography className={classes.heading}>
                  Create Accounts
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.username}>
                  {profile.user.first_name} {profile.user.last_name}
                </Typography>
              </Grid>
            </Grid>
            <Box height="5rem"></Box>
            <CreateNewLogin
              profiles={profiles}
              clients={clients}
              opened={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
