import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ClientsList from "../Common/ClientsList";

const useStyles = makeStyles((theme) => ({
  backbtn: {
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
      marginLeft: "20px",
    },
  },
  paperOpen: {
    padding: "20px 0",
    width: "100%",
    borderRadius: "8px",
  },
  headingOpen: {
    "font-weight": "500",
    "font-size": "20px",
    "line-height": "23px",
    "text-align": "center",
    "letter-spacing": "0.4px",
    color: "#454545",
  },
  numberOpen: {
    "font-weight": "500",
    "font-size": "40px",
    "line-height": "47px",
    "text-align": "center",
    "letter-spacing": "1px",
    color: "#2C2C2C",
  },
}));

export default function CDR({
  setOpenCDR,
  openCDRCount,
  setShowSubAdmin,
  setId,
  setCompany,
  setMode,
  setType,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [finaldata, setFinaldata] = useState([]);

  React.useEffect(() => {
    dispatch(apiFunction()).then((res) => {});
  }, []);

  function apiFunction() {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/companybyplateformandgroup/?platform_type=cdr`
        );
        let data = response.data.data;
        let result = [];
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            let doit = false;
            let item = {};
            item.company = key;
            // console.log(key + " -> " + data[key]);
            if (key == "webnyay") {
              doit = true;
            }
            if (doit) {
              for (var k in data[key]) {
                if (data[key].hasOwnProperty(k)) {
                  let it = {};
                  it.company = k;
                  // console.log(k + " -> " + data[key][k]);
                  for (var l in data[key][k]) {
                    if (l == "open") {
                      it.open = data[key][k][l];
                    } else if (l == "closed") {
                      it.closed = data[key][k][l];
                    } else if (l == "company_id") {
                      it.id = data[key][k][l];
                    }
                    it.mode = "CDR";
                    if (data[key][k].hasOwnProperty(l)) {
                      // console.log(l + " -> " + data[key][k][l]);
                    }
                  }
                  result.push(it);
                }
              }
            } else {
              for (var k in data[key]) {
                if (k == "open") {
                  item.open = data[key][k];
                } else if (k == "closed") {
                  item.closed = data[key][k];
                }
                item.mode = "CDR";
                if (data[key].hasOwnProperty(k)) {
                  // console.log(k + " -> " + data[key][k]);
                }
              }
            }
            if (!doit && (item.open > 0 || item.closed > 0)) result.push(item);
          }
        }

        setFinaldata(result);
        setLoading(false);
        return result;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <>
        <Grid container justify="flex-start" spacing={3} direction="row">
          <Grid container justify="flex-start">
            <Grid item style={{ padding: "0 10px" }}>
              <Button
                onClick={() => {
                  setOpenCDR(false);
                  sessionStorage.removeItem("overviewC");
                  sessionStorage.setItem("overviewStep", 1);
                }}
                className={classes.backbtn}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Box height="3rem"></Box>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Paper className={classes.paperOpen}>
              <Typography className={classes.headingOpen}>CDR</Typography>
              <Box height="0.5rem"></Box>
              <Typography className={classes.numberOpen}>
                {openCDRCount}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={9} md sm={11} xs={12}>
            <Box height="4rem"></Box>
            <ClientsList
              data={finaldata}
              setOpenCDR={setOpenCDR}
              setShowSubAdmin={setShowSubAdmin}
              setId={setId}
              setCompany={setCompany}
              setMode={setMode}
              setType={setType}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
