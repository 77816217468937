import React from "react";
import { Grid, makeStyles, Typography, Paper, Chip, Checkbox, FormControlLabel } from "@material-ui/core";
import { RedoOutlined } from "@material-ui/icons";
import { DocGenerator } from "utils";
import { removeOrAddTagFromDispute } from "slices/disputeSlice"; 
import DownloadSvg from "images/download-02.svg";
import { formatDateWithTime } from "utils";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    value: {
      border: "none",
      fontSize: "0.9rem",
      fontWeight: 700,
      lineHeight: "17px",
      display: 'flex',
      textTransform: "capitalize",
      "&:hover": {
        background: "#F8F7F7",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      },
    },
    valuePaper: {
        padding: "1.5rem 1.5rem 1.5rem 0rem",
        cursor: "pointer",
        fontSize: '16px',
        display: 'flex',
        fontFamily: 'Satoshi-Medium',
        flexDirection: 'column',
        boxShadow: "none",
        width: "100%",
        border: "1px solid #E0E0E0",
        "&:hover": {
          background: "#F8F7F7",
        },
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
        },
      },
      disputeRowWrapper: {
        display: 'flex',
        marginBottom : '15px',
        justifyContent: 'center'
      },
      tagWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '16px',
        fontWeight: '500',
        fontSize: '14px',
        fontFamily: "Satoshi-Medium",
        gap: '9px',
      },
      chipWrap: {
        marginRight: '9px'
      },
      chipHover: {
        "& .MuiChip-deleteIcon": {
          display: "none"
        },
        "&:hover": {
          "& .MuiChip-deleteIcon": {
            display: "block"
          }
        }
      }, 
      tableIndivdual: {
        display: 'flex',
        justifyContent: 'center'
      }
}));

export default function StarDisputeRow({
    id,
    name,
    filingDate,
    registrationDate,
    tags,
    otherTags,
    type,
    addDispute,
    removeDispute,
    reload,
    setSelectedComponent
}){
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const getStyles = (label) => {
        if (label.includes("Women")) {
          return {
            backgroundColor: '#fceff8',
            color: '#D534A8',
            fontFamily: "Satoshi-Bold",
          };
        } else if (label.includes("Children")) {
          return {
            backgroundColor: '#effafc',
            color: '#34C2D5',
            fontFamily: "Satoshi-Bold",
          };
        }else if (label.includes("LGBTQ")) {
            return {
              backgroundColor: '#fcefef',
              color: '#FF8933',
              fontFamily: "Satoshi-Bold",
            };
        }else if (label.includes("Violence")) {
            return {
              backgroundColor: '#fff7ef',
              color: '#FF8933',
              fontFamily: "Satoshi-Bold",
            };
        }else if (label.includes("Language")) {
          return {
            backgroundColor: '#ebf5ef',
            color: '#048839',
            fontFamily: "Satoshi-Bold",
          };
        }else{
          return {
            backgroundColor: '#ebf5ef',
            color: '#048839',
            fontFamily: "Satoshi-Bold",
          };
        }
    };

    const handleChangeOnCheckbox = (event) => {
      if (event.target.checked) {
        addDispute(event.target.id);
      } else {
        removeDispute(event.target.id);
      }
    };

    const gridItems = [
        { label: id, key: 'id', sm: 1 },
        { label: name?.split("&")[0], key: 'name', sm: 2 },
        { label: formatDateWithTime(filingDate), key: 'filingDate', sm:3 },
        { label:  registrationDate ? formatDateWithTime(registrationDate) : "----" , key: 'registrationDate', sm: 3 }
    ];

    const onRowClick = () => {
      // setSelectedComponent('DisputePage')
      history.push(`/disputes/${id}`);
    };

    const download = (event) => {
        event.stopPropagation();
        DocGenerator(id, "claimant");
    };

    const handleRemoveOrAddTag = async (disputeId, tagId, type) => {
      const result = await dispatch(removeOrAddTagFromDispute(disputeId, tagId, type));
      reload();
    }

    return(
        <>
            <Paper className={classes.valuePaper} square onClick={onRowClick}>
                <div className={classes.disputeRowWrapper}>
                  {type === "serious" || type === "frivolous" || type === "registered" ? (
                    <Grid item sm={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={id}
                            onChange={handleChangeOnCheckbox}
                            style={{ color: "#6b6a6a", padding: 0, marginLeft: '20px' }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        }
                      />
                      </Grid>
                    ) :  null
                  }
                    {gridItems?.map((item) => (
                        <Grid item xs sm={item.sm} key={item.key} className={classes.tableIndivdual}>
                            <Typography className={classes.value}>
                                {item.label}
                            </Typography>
                        </Grid>
                    ))}
                    <Grid item xs sm={2} className={classes.tableIndivdual}>
                        <Typography className={classes.value} >
                            <RedoOutlined style={{transform: "rotate(-30deg)"}}/>
                            <span style={{ marginLeft: "0.6rem" }}>
                                <img src={DownloadSvg} alt="" onClick={download}/>
                            </span>
                        </Typography>
                    </Grid>
                </div>
                <Grid container>
                  <Grid item xs={false} sm={type === "serious" || type === "frivolous" ? 2 : 1}></Grid>
                  <Grid item xs={12} sm={type === "serious" || type === "frivolous" ? 10 : 11}>
                    <div className={classes.tagWrap}>
                        {otherTags?.map((item, index) => (
                            <>
                              {Object.entries(item).map(([key, value]) => (
                                    <Chip style={{fontFamily: "Satoshi-Bold" }} label={key === 'channel_name' || key === 'show_name' ? value : key}/>                                
                                  ))}
                            </>
                        ))}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={false} sm={type === "serious" || type === "frivolous" ? 2 : 1}></Grid>
                  <Grid item xs={12} sm={type === "serious" || type === "frivolous" ? 10 : 11}>
                  <div className={classes.tagWrap}>
                      {tags?.map((tag) => {
                          return(
                              <Chip className={classes.chipHover} label={tag.name} key={tag.id} style={getStyles(tag.name)} onDelete={()=>handleRemoveOrAddTag(id,tag.id,'remove')} />
                          )
                      })}
                  </div>
                  </Grid>
                </Grid>
            </Paper>
        </>
    )
}