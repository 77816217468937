import { CircularProgress } from "@material-ui/core";
import { isCDR, isHotStar, isMuthoot, isStar, isVikalp } from "Routes/Routes";
import {
  ARBITRATION,
  CLAIMANT_COMPLETE,
  COMPLETE,
  IO_COMPLETE,
  IO_INPROGRESS,
  MD_COMPLETE,
  MD_INPROGRESS,
  RESPONDENT_COMPLETE,
  RESPONDENT_INPROGRESS,
  REVIEW_COMPLETE,
} from "app/constants";
import DisputeDisplay from "features/singleDispute/DisputeDisplay";
import RespondentFlowSelection from "features/singleDispute/RespondentFlowSteps/RespndentFlowSelection";
import RespondentSelectionStatus from "features/singleDispute/RespondentFlowSteps/RespondentSelectionStatus";
import React from "react";
import { useDispatch } from "react-redux";
import { MuthootCaseResolved, updateDispute } from "slices/disputeSlice";
import DisputeDisplayHotstar from "../DisputeDisplay/DisputeDisplayHotstar";
import DisputeDisplayHotstarNew from "../DisputeDisplay/DisputeDisplayHotstarNew";
import SingleDisputeDetails from "../SingleDisputeDetails";
import DisputeDisplayStar from "../DisputeDisplay/DisputeDisplayStar";
import DisputeDetailsMuthoot from "features/disputes/MuthootDisputes/DisputeDetailsMuthoot";

export default function RespondentFlowSteps({
  activeDispute,
  activeCase,
  profile,
  flows,
}) {
  console.log("activeDispute3:", activeDispute);
  console.log("activeCase3:", activeCase);
  let dispatch = useDispatch();
  function getStepContent() {
    switch (activeDispute.status) {
      case "new":
        if(isMuthoot){
          return (
            <DisputeDetailsMuthoot
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          )
        }else {
          return (
            <RespondentFlowSelection
              activeDispute={activeDispute}
              activeCase={activeCase}
              flows={flows}
              profile={profile}
            />
          );
        }
      case RESPONDENT_INPROGRESS:
      case IO_INPROGRESS:
      case MD_INPROGRESS:
        if (activeCase.case_status !== REVIEW_COMPLETE) {
          return <SingleDisputeDetails profile={profile} />;
        } else {
          //Update dispute to claimant complete in case of arbitration
          if (activeDispute.flow_type === ARBITRATION) {
            dispatch(
              updateDispute({
                id: activeDispute.id,
                status: activeDispute?.status === IO_INPROGRESS ? IO_COMPLETE : activeDispute?.status === MD_INPROGRESS ? MD_COMPLETE : CLAIMANT_COMPLETE,
              })
            ).then((response) => {
              if(isMuthoot){
                dispatch(MuthootCaseResolved(activeDispute.id));
              }
            })
            .catch((err) => {
              console.log(err);
            });
          } else {
            // its adjudication. Not tasks for respondent .Wait for claimant to respond
            dispatch(
              updateDispute({
                id: activeDispute.id,
                status: RESPONDENT_COMPLETE,
              })
            );
          }
          return <CircularProgress />;
        }

      case RESPONDENT_COMPLETE:
        //if (activeDispute.flow_type === MEDIATION) {
        return (
          <RespondentSelectionStatus flowType={activeCase.proposed_flow} />
        );
      //} else {

      ///}
      //}

      // if (activeCase.proposed_flow === ARBITRATION) {
      //   if (activeCase.case_status !== REVIEW_COMPLETE) {
      //     return <SingleDisputeDetails />;
      //   }
      // }
      // return (
      //   <RespondentDecisionStatus
      //     flowType={activeCase.proposed_flow}
      //     name={CLAIMANT}
      //     id={activeDispute.id}
      //   />
      // );
      case COMPLETE:
        return "Case is complete";


      case CLAIMANT_COMPLETE:
      case IO_COMPLETE:
      case MD_COMPLETE:
      default:
        if (isHotStar ) {
          return (
            <DisputeDisplayHotstarNew
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          );
        }else if(isStar){
          return(
            <DisputeDisplayStar
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          )
        } else if (isMuthoot) {
          return (
            <DisputeDetailsMuthoot
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            /> 
          )
        } else if (isHotStar || isStar || isMuthoot || isCDR || isVikalp) {
          return (
            <DisputeDisplayHotstar
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          );
        } else {
          return (
            <DisputeDisplay
              activeDispute={activeDispute}
              activeCase={activeCase}
            />
          );
        }
    }
  }

  return (
    <React.Fragment>
      {getStepContent(activeDispute, activeCase, profile)}
    </React.Fragment>
  );
}
