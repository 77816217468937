import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ComplianceReport from "./ComplianceReport";

export default function ComplianceReportContainer() {
  const dispatch = useDispatch();
  const [reports, setreports] = useState();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');

  function getComplianceReports() {
    return async (dispatch) => {
      return await instance({
        method: "get",
        url: "/publishedcomplaincereports/",
      }).then(
        (response) => {
          
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    };
  }

  function downloadReportApi(id) {
    return async (dispatch) => {
      return await instance({
        method: "get",
        url: "/compliancereporturl/" + id+ "/",
      }).then(
        (response) => {
          window.open(response.data.url, "_blank");
          // window.location.href = response.data.url;
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    };
  }

  function toggleScheduleReport(set) {
    return async (dispatch) => {
      return await instance({
        method: "get",
        url: "/toggleschedulecompliancereport/" + set + '/',
      }).then(
        (response) => {
          setStatus(response.data.status)
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    };
  }

  function getScheduleComplianceReportsStatus() {
    return async (dispatch) => {
      return await instance({
        method: "get",
        url: "/schedulecompliancereportstatus/",
      }).then(
        (response) => {
          setStatus(response.data.status)
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    };
  }

  const downloadReport = (id) => {
    dispatch(downloadReportApi(id));
  };

  const turnOnSchedule = () => {
    dispatch(toggleScheduleReport('on'))
  };

  const turnOffSchedule = () => {
    dispatch(toggleScheduleReport('of'))
  };

  React.useEffect(() => {
    dispatch(getComplianceReports()).then((reports) => {
      setreports(reports.reports);
      setLoading(false);
    });
    dispatch(getScheduleComplianceReportsStatus())
  }, []);

  if (loading) return <CircularCenteredLoader />;
  return (
    <ComplianceReport
      reports={reports}
      downloadReport={downloadReport}
      turnOnSchedule={turnOnSchedule}
      turnOffSchedule={turnOffSchedule}
      status={status}
    />
  );
}
