import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    makeStyles,
    Menu,
    Paper,
    RadioGroup,
    Typography,
    IconButton,
    Checkbox,
  } from "@material-ui/core";
  import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
  import instance from "api/globalaxios";
  import Axios from "axios";
  import { format } from "date-fns";
  import CircularCenteredLoader from "features/common/CircularCenteredLoader";
  import DeletedPopup from "features/common/Popup/DeletedPopup";
  import DonePopup from "features/common/Popup/DonePopup";
  import POPUP from "features/common/Popup/Popup";
  import { StyledRadio } from "features/TempTiacFormV2/ArbitratorPage/questions";
  import AnalyticsVector from "images/Hotstar/Analytics.svg";
  import CalenderVector from "images/Hotstar/Calender.svg";
  import debounce from "lodash.debounce";
  import React, { useCallback, useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  import { useHistory } from "react-router-dom";
  import { typeChoices } from "../DisputesContainerHotstar";
  import DisputeRow from "../HotstarDisputes/DisputeRow";
  import { ExpandButtonOnHover } from "../HotstarDisputes/ExpandButtonOnHover";
  import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
  import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
  import { isMuthoot } from "Routes/Routes";
import Tabs from "features/Tabs/Tabs";
import { Add, DateRangeOutlined } from "@material-ui/icons";
import DropdownButton from "Components/DropdownButton";
import DisputeRowMuthoot from "./DisputeRowMuthoot";
import { DatePickerCalendar, DateRangePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { enGB } from "date-fns/locale";
import Popup from "reactjs-popup";
import { ARBITRATOR, MEDIATOR } from "app/constants";
  
  export async function changeDisputeState(disputeId, state) {
    return await instance.get(
      `/changedisputestatehotstar/${disputeId}/${state}/`
    );
  }
  export async function deleteDisputeapi(disputeId) {
    return await instance.delete(`/deletedisputehotstar/${disputeId}/`);
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "3rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1.5rem",
      },
    },
    sidebar: {
      padding: "1rem",
      userSelect: "none",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    content: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "3rem",
      },
    },
    bigheading: {
      color: isMuthoot ? 'black' : "#4F4F4F",
      // fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "36px",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    searchbar: {
      border: "none",
      background: "#DADBDB",
      width: "-webkit-fill-available",
      color: "#828282",
      fontSize: "14px",
      outline: "none",
      padding: "0rem 1.5rem",
      height: "42px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        height: "34px",
        padding: "0rem 0.7rem",
      },
    },
    typeButton: {
      textTransform: "none",
      border: "1px solid #4F4F4F",
      borderRadius: "0",
      padding: "0 1.5rem",
      color: "#4F4F4F",
      backgroundColor: 'white',
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      height: "42px",
  
      [theme.breakpoints.down("xs")]: {
        height: "34px",
        fontSize: "14px",
      },
    },
    typeButtonBlue: {
      textTransform: "none",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      background: isMuthoot ? theme.palette.primary.main : "#2F80ED",
      borderRadius: "0",
      padding: "0 1.5rem",
      color: "#fff",
      // fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      height: "42px",
      "&:hover": {
        background: "#FFFFFF",
        color: isMuthoot ? theme.palette.primary.main : "#2F80ED",
        border: "1px solid",
        borderColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        height: "34px",
        fontSize: "14px",
      },
    },
    typechoices: {
      color: "#333333",
      fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      textTransform: "capitalize",
      paddingRight: "5px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    typeFormControl: {
      margin: 0,
      "&:hover": {
        background: "#E0E0E0",
      },
    },
    spaceBox: {
      height: "4rem",
      [theme.breakpoints.down("sm")]: {
        height: "3rem",
      },
      [theme.breakpoints.down("xs")]: {
        height: "2rem",
      },
    },
    griditem: {
      padding: "8px",
      [theme.breakpoints.down("xs")]: {
        padding: "4px",
      },
    },
    title: { 
      fontFamily: 'Satoshi-Bold',
      fontSize: "14px",
      lineHeight: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      },
    },
    value: {
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      "&:hover": {
        background: "#E0E0E0",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      },
    },
    titlePaper: {
      padding: "1.5rem",
      boxShadow: "none",
      width: "100%",
      backgroundColor: "initial",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    valuePaper: {
      padding: "1.5rem",
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      border: "1px solid #E0E0E0",
      "&:hover": {
        background: "#E0E0E0",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    menuwidth: {
      width: "200px",
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "130px",
      },
    },
    alginCenter: {
      textAlign: "center",
    },
    tabsContainer: {
      width:'70%',
      flexWrap: 'nowrap',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        flexWrap: 'wrap',
      },
    },
    selected: {
      padding: "0.8rem 1.2rem",
      cursor: 'pointer',
      fontSize: '1.2rem',
      fontFamily: 'Satoshi-Medium',
      minWidth: 'fit-content',
      width: '16.6%',
      textAlign: 'center',
      whiteSpace: 'pre-line',
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    unselected: {
      fontSize: '1.2rem',
      padding: "0.8rem 1.2rem",
      cursor: "pointer",
      fontFamily: 'Satoshi-Medium',
      minWidth: 'fit-content',
      width: '16.6%',
      textAlign: 'center',
      whiteSpace: 'pre-line',
      borderBottom: '2px solid black',
    },
  }));
  
  // Side Bar ......
  const SideBar = ({ history }) => {
    return (
      <Grid container spacing={1}>
        <Grid item md={12} sm={3} xs={6}>
          <ExpandButtonOnHover
            history={history}
            vector={AnalyticsVector}
            vector_text={"Analytics img"}
            buttonText={"Analytics"}
            link={"/analytics"}
          />
        </Grid>
        <Grid item md={12} sm={3} xs={6}>
          <ExpandButtonOnHover
            history={history}
            vector={CalenderVector}
            vector_text={"Calender img"}
            buttonText={"Calender"}
            link={"/events"}
          />
        </Grid>
      </Grid>
    );
  };
  
  export function isValidMonth(value) {
    if (
      value == "january" ||
      value == "february" ||
      value == "march" ||
      value == "april" ||
      value == "may" ||
      value == "june" ||
      value == "july" ||
      value == "august" ||
      value == "september" ||
      value == "october" ||
      value == "november" ||
      value == "december"
    ) {
      return true;
    }
    return false;
  }
  export function isValidNumber(value) {
    return !isNaN(value);
  }
  export function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  export function calc_days(date1, date2 = null) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const dateObj1 = new Date(date1);
    const dateObj2 = date2 ? new Date(date2) : new Date();
    const timeDifference = dateObj2 - dateObj1;
    const dayDifference = Math.round(timeDifference / ONE_DAY);
    return dayDifference;
  }
  
  export default function DisputesMuthoot({
    count,
    disputesLoading,
    setType,
    type,
    disputes,
    reload,
    totalPages,
    currentPage,
    setCurrentPage,
    getUserDataAnotherPage,
    region,
    setRegion,
    days,
    setDays,
    profile,
  }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const VENDOR_REGION = profile?.profile?.region;
    const IS_MEDIATOR = profile?.profile?.profile_type === MEDIATOR;
    const IS_ARBITRATOR = profile?.profile?.profile_type === ARBITRATOR;
    const IS_ADMIN = profile?.profile?.profile_type === 'sub_admin';
    const IS_VENDOR = profile?.profile?.profile_type === 'vendor';
  
    const [anchorType, setAnchorType] = React.useState(null);
    const handleClickType = (event) => {
      setAnchorType(event.currentTarget);
    };
    const handleCloseType = () => {
      setAnchorType(null);
    };
  
    const handleTypeChange = (newType) => {
      setType(newType);
      setSelectedDisputes([]);
      handleCloseType();
      setSearchText("");
      sessionStorage.setItem("hotstar-type", newType);
      reload(newType);
    };
  
    const [selectedDisputes, setSelectedDisputes] = useState([]);
    const addDispute = (id) => {
      let res = [];
      selectedDisputes.forEach((i) => {
        res.push(i);
      });
      res.push(id);
      setSelectedDisputes(res);
    };
    const removeDispute = (id) => {
      let res = [];
      selectedDisputes.forEach((i) => {
        res.push(i);
      });
      let index = res.indexOf(id);
      if (index > -1) {
        res.splice(index, 1);
      }
      setSelectedDisputes(res);
    };
  
    const [searchText, setSearchText] = useState("");
    const [disputesBySearch, setDisputesBySearch] = useState(disputes);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [cancelToken, setCancelToken] = useState();
    let firstname = "";
    let lastname = "";
  
    const debouceSearch = useCallback(debounce(search, 500), [disputes]);
  
    useEffect(() => {
      setDisputesBySearch(disputes);
    }, [disputes]);
  
    function search(value, type) {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
  
      if (value.trim() != "") {
        let new_value = value.replaceAll('/','-');
        let ar = new_value.trim().split("-");
        let date = new Date(ar[1] + "/" + ar[0] + "/" + ar[2]);
        // if (isValidDate(date)) {
        //   dispatch(gethotstardatabydate(date, x, type));
        //   setLoadingSearch(false);
        // } else if (isValidNumber(value.trim())) {
        //   dispatch(gethotstardatabyDisputeId(value.trim(), x, type));
        //   setLoadingSearch(false);
        // } else if (isValidMonth(value.trim().toLowerCase())) {
        //   dispatch(gethotstardatabyMonth(value.trim().toLowerCase(), x, type));
        //   setLoadingSearch(false);
        // } else {
        //   dispatch(gethotstardatabyname(value.trim(), x, type));
        //   setLoadingSearch(false);
        // }
        if (isValidDate(date)) {
          dispatch(getMuthootdatabydate(date, x, type, region));
          setLoadingSearch(false);
        } else if (isValidNumber(new_value.trim())) {
          dispatch(getMuthootdatabyDisputeId(new_value.trim(), x, type, region, days));
          setLoadingSearch(false);
        } else {
          dispatch(getMuthootdatabyname(new_value.trim(), x, type, region, days));
          setLoadingSearch(false);
        }
      } else {
        setDisputesBySearch(disputes);
        setLoadingSearch(true);
      }
    }
  
    function gethotstardatabyname(name, cancelToken, type) {
      return async (dispatch) => {
        try {
          const answer_array = name.split(" ");
          firstname = answer_array[0];
          lastname = answer_array[1];
          if (firstname != "" && (lastname == "" || !lastname)) {
            const response2 = await instance.get(
              `/api/HotstarsearchView1/${firstname}/${null}/?type=${type}`,
              { cancelToken: cancelToken.token }
            );
            setDisputesBySearch(response2.data);
  
            return response2.data;
          } else {
            const response3 = await instance.get(
              `/api/HotstarsearchView1/${firstname}/${lastname}/?type=${type}`,
              { cancelToken: cancelToken.token }
            );
            setDisputesBySearch(response3.data);
            return response3.data.profiles;
          }
        } catch (error) {
          console.log(error);
        }
      };
    }
  
    function gethotstardatabydate(date, cancelToken, type) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `/api/HotstarsearchbydateView/${format(
              date,
              "dd-MM-yyyy"
            )}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response.data);
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }
  
    function gethotstardatabyDisputeId(value, cancelToken, type) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `/api/HotstarsearchViewByDisputeId/${value}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response.data);
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }
  
    function gethotstardatabyMonth(value, cancelToken, type) {
      return async (dispatch) => {
        var months = [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ];
        let index = months.indexOf(value);
        try {
          const response = await instance.get(
            `/api/HotstarsearchbyMonth/${index}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response.data);
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }

    function getMuthootdatabyname(name, cancelToken, type, region, days) {
      return async (dispatch) => {
        try {
          const answer_array = name.split(" ");
          firstname = answer_array[0];
          lastname = answer_array[1];
          if (firstname != "" && (lastname == "" || !lastname)) {
            const response2 = await instance.get(
              `/api/MuthootsearchView/${firstname}/${null}/?type=${type}&region=${region}&days=${days}`,
              { cancelToken: cancelToken.token }
            );
            console.log(response2.data);
            setDisputesBySearch(response2.data);
  
            return response2.data;
          } else {
            const response3 = await instance.get(
              `/api/MuthootsearchView/${firstname}/${lastname}/?type=${type}&region=${region}&days=${days}`,
              { cancelToken: cancelToken.token }
            );
            setDisputesBySearch(response3.data);
            return response3.data.profiles;
          }
        } catch (error) {
          console.log(error);
        }
      };
    }

    function getMuthootdatabydate(date, cancelToken, type=null, region=null) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `api/muthootsearchbydateView/${format(
              date,
              "dd-MM-yyyy"
            )}/?type=${type}&region=${region}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response.data);
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }

    function getMuthootdatabyDisputeId(value, cancelToken, type, region, days) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `/api/muthootsearchbydisputeidView/${value}/?type=${type}&region=${region}&days=${days}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response.data);
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }
  
    const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
    const handleClickDelete = () => {
      setOpenDeletePopup(true);
    };
    const handleCloseDelete = () => {
      setOpenDeletePopup(false);
    };
    const handleDelete = () => {
      handleCloseDelete();
  
      let total = selectedDisputes.length;
      let count = 0;
      selectedDisputes.forEach((disputeid) => {
        deleteDisputeapi(disputeid)
          .then((res) => {
            count += 1;
            if (count == total) {
              reload();
              setSelectedDisputes([]);
              handleOpenDeletedGifPopup();
            }
          })
          .catch((error) => {
            console.log("error.response.data", error.response.data);
          });
      });
      if (count == total) handleOpenDeletedGifPopup();
    };
    const [openFrivolousPopup, setopenFrivolousPopup] = React.useState(false);
    const handleClickMarkAsFrivolous = () => {
      setopenFrivolousPopup(true);
    };
    const handleCloseFrivolous = () => {
      setopenFrivolousPopup(false);
    };
    const handleFrivolous = () => {
      handleCloseFrivolous();
      let total = selectedDisputes.length;
      let count = 0;
      selectedDisputes.forEach((disputeid) => {
        changeDisputeState(disputeid, "frivolous")
          .then((res) => {
            count += 1;
            if (count == total) {
              reload();
              setSelectedDisputes([]);
              handleOpenFrivolousGifPopup();
            }
          })
          .catch((error) => {
            console.log("error.response.data", error.response.data);
            if (error?.response?.data?.status == "not done") {
              alert("This dispute is already frivolous: " + disputeid);
            }
          });
      });
      if (count == total) handleOpenFrivolousGifPopup();
    };
    const [openSeriousPopup, setopenSeriousPopup] = React.useState(false);
    const handleClickMarkAsSerious = () => {
      setopenSeriousPopup(true);
    };
    const handleCloseSerious = () => {
      setopenSeriousPopup(false);
    };
    const handleSerious = () => {
      handleCloseSerious();
      let total = selectedDisputes.length;
      let count = 0;
      selectedDisputes.forEach((disputeid) => {
        changeDisputeState(disputeid, "serious")
          .then((res) => {
            count += 1;
            if (count == total) {
              reload();
              setSelectedDisputes([]);
              handleOpenSeriousGifPopup();
            }
          })
          .catch((error) => {
            console.log("error.response.data", error.response.data);
            if (error?.response?.data?.status == "not done") {
              alert("This dispute is already serious: " + disputeid);
            }
          });
      });
      if (count == total) handleOpenSeriousGifPopup();
    };
    const [openDeletedGifPopup, setOpenDeletedGifPopup] = React.useState(false);
    const handleOpenDeletedGifPopup = () => {
      setOpenDeletedGifPopup(true);
    };
    const handleCloseDeletedGifPopup = () => {
      setOpenDeletedGifPopup(false);
    };
    const [openSeriousGifPopup, setOpenSeriousGifPopup] = React.useState(false);
    const handleOpenSeriousGifPopup = () => {
      setOpenSeriousGifPopup(true);
    };
    const handleCloseSeriousGifPopup = () => {
      setOpenSeriousGifPopup(false);
    };
    const [openFrivolousGifPopup, setOpenFrivolousGifPopup] =
      React.useState(false);
    const handleOpenFrivolousGifPopup = () => {
      setOpenFrivolousGifPopup(true);
    };
    const handleCloseFrivolousGifPopup = () => {
      setOpenFrivolousGifPopup(false);
    };

    const tabs = [
      {
        id: "totalcases",
        label: "Total Complaints\n " + count.registered.total,
      },
      {
        id: "closed",
        label: "Closed\n " + count.registered.closed,
      },
      {
        id: "registered",
        label: "Open\n " + count.registered.registered,
      },
      {
        id: "Loans_and_Advances",
        label: "Loans and Advances\n " + count.received.Loans_and_Advances,
      },
      {
        id: "Para_Banking",
        label: "Para Banking\n " + count.received.Para_Banking,
      },
      {
        id: "Other_Products_and_Services",
        label: "Others\n " + count.received.Other_Products_and_Services,
      },
      
    ];

    const [currentTab, setCurrentTab] = useState(type);
    const [daysButtonText, setDaysButtonText] = useState(null);
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const handleCloseDatePicker = () => {
      setOpenDatePicker(false);
    }

    const handleTabChange = (newValue) => {
      setCurrentTab(newValue);
      setType(newValue);
      setSelectedDisputes([]);
      setSearchText("");
      sessionStorage.setItem("muthoot-type", newValue);
      setCurrentPage(1);
      // reload(newValue, region, days);
      // setPagination({
      //   page: 1,
      //   count: activities?.count,
      //   next: activities?.next,
      //   previous: activities?.previous,
      // });
    };

    const handleClickRegionOption = (selectedOption) => {
      const value = selectedOption?.value || selectedOption?.title;
      setRegion(value === region ? null : value);
    }

    const handleClickDateOption = (selectedOption) => {
      const value = selectedOption?.value || selectedOption?.title;
      if(value !== days){
        if(value === 'selectDate'){
          setOpenDatePicker(true);
        }
        else{
          setDays(value);
          setDaysButtonText(selectedOption.title);
        }
      }
      else{
        setDays(null);
        setDaysButtonText(null);
      }
    }

    const handleSelectDate = (value) => {
      setDaysButtonText(format(new Date(value), "dd-MM-y"));
      setDays(calc_days(value));
      handleCloseDatePicker();
    }

    const regionOptions = {
      'north' : [{
        title: 'BIHAR',
        value: 'BIHAR',
      },
      {
        title: 'CHANDIGARH',
        value: 'CHANDIGARH',
      },
      {
        title: 'DELHI',
        value: 'DELHI',
      },
      {
        title: 'HARYANA',
        value: 'HARYANA',
      },
      {
        title: 'HIMACHAL PRADESH',
        value: 'HIMACHAL PRADESH',
      },
      {
        title: 'JAMMU & KASHMIR',
        value: 'JAMMU & KASHMIR',
      },
      {
        title: 'JHARKHAND',
        value: 'JHARKHAND',
      },
      {
        title: 'PUNJAB',
        value: 'PUNJAB',
      },
      {
        title: 'RAJASTHAN',
        value: 'RAJASTHAN',
      },
      {
        title: 'UTTAR PRADESH',
        value: 'UTTAR PRADESH',
      },
      {
        title: 'UTTARAKHAND',
        value: 'UTTARAKHAND',
      }],
      'south' : [{
        title: 'ANDAMAN AND NICOBAR',
        value: 'ANDAMAN AND NICOBAR',
      },
      {
        title: 'ANDHRA PRADESH',
        value: 'ANDHRA PRADESH',
      },
      {
        title: 'KARNATAKA',
        value: 'KARNATAKA',
      },
      {
        title: 'KERALA',
        value: 'KERALA',
      },
      {
        title: 'PONDICHERRY(UT)',
        value: 'PONDICHERRY(UT)',
      },
      {
        title: 'TAMIL NADU',
        value: 'TAMIL NADU',
      },
      {
        title: 'TELANGANA',
        value: 'TELANGANA',
      }],
      'east' : [{
        title: 'ARUNACHAL PRADESH',
        value: 'ARUNACHAL PRADESH',
      },
      {
        title: 'ASSAM',
        value: 'ASSAM',
      },
      {
        title: 'MANIPUR',
        value: 'MANIPUR',
      },
      {
        title: 'ODISHA',
        value: 'ODISHA',
      },
      {
        title: 'SIKKIM',
        value: 'SIKKIM',
      },
      {
        title: 'TRIPURA',
        value: 'TRIPURA',
      },
      {
        title: 'WEST BENGAL',
        value: 'WEST BENGAL',
      }],
      'west' : [{
        title: 'CHHATTISGARH',
        value: 'CHHATTISGARH',
      },
      {
        title: 'DADRA & NAGAR HAVELI',
        value: 'DADRA & NAGAR HAVELI',
      },
      {
        title: 'DAMAN & DIU',
        value: 'DAMAN & DIU',
      },
      {
        title: 'GOA',
        value: 'GOA',
      },
      {
        title: 'GUJARAT',
        value: 'GUJARAT',
      },
      {
        title: 'MADHYA PRADESH',
        value: 'MADHYA PRADESH',
      },
      {
        title: 'MAHARASHTRA',
        value: 'MAHARASHTRA',
      }],
      'ahmedabad' : [{
        title: 'AHMEDABAD',
        value: 'AHMEDABAD',
      }]
    }

    const allRegionsOptions = Object.values(regionOptions).flat();

    const dateOptions = [
      {
        title: 'Last 1 day',
        value: 1,
      },
      {
        title: 'Last 7 days',
        value: 7,
      },
      {
        title: 'Last 1 Month',
        value: 30,
      },
      {
        title: 'Last 3 Month',
        value: 60,
      },
      {
        title: 'Select Custom Date',
        value: 'selectDate',
        endIcon: <DateRangeOutlined style={{color:'#343434',fontSize:'1.2rem'}}/>
      },
    ];
    
  
    return (
      <>
        <Grid container className={classes.root}>
          <Grid container justify="space-between" alignItems="center" style={{gap: '1rem'}}>
            <Tabs
              tabs={tabs}
              handleClickTab={handleTabChange}
              choice={currentTab}
              tabsClasses={{
                selected: classes.selected,
                unselected: classes.unselected,
              }}
              tabsContainerClass={(IS_VENDOR || IS_ADMIN)?classes.tabsContainer:{width:'100%'}}
            />
            {(IS_VENDOR || IS_ADMIN) && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add/>}
                  onClick={() => {
                    history.push("/intro");
                  }}
                >
                  CREATE TICKET
                </Button>
              </Box>
            )}
          </Grid>
          <Box className={classes.spaceBox} />
          {disputesLoading ? (
            <CircularCenteredLoader />
          ) : (
            <>
              <Grid container>
                <Grid
                  item
                  className={classes.griditem}
                  style={{ paddingLeft: 0, flex: 1, }}
                >
                  <input
                    type="text"
                    placeholder="Search by Complainant's Name, Date (DD-MM-YYYY) or G.ID"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      debouceSearch(e.target.value, type);
                    }}
                    onBlur={(e) => {
                      e.target.placeholder =
                        "Search by Complainant's Name, Date (DD-MM-YYYY) or G.ID";
                    }}
                    className={classes.searchbar}
                    onFocus={(e) => (e.target.placeholder = "")}
                  />
                </Grid>
                <Grid item className={classes.griditem}>
                  <DropdownButton
                    buttonText={region ? region : 'Region'}
                    options={(VENDOR_REGION) ? regionOptions[VENDOR_REGION] : allRegionsOptions }
                    classes={classes}
                    handleClickOption={handleClickRegionOption}
                    selectedOption={region}
                  />
                </Grid>
                <Grid item className={classes.griditem}>
                  <DropdownButton 
                    buttonText={daysButtonText? daysButtonText : 'Date'}
                    classes={classes}
                    options={dateOptions}
                    handleClickOption={handleClickDateOption}
                    selectedOption={days}
                  />
                </Grid>
                {/* Show these buttons on selecting disputes only */}
                {selectedDisputes.length > 0 &&
                (type == "serious" || type == "frivolous") ? (
                  <>
                    <Grid item className={classes.griditem}>
                      <Button
                        className={classes.typeButton}
                        onClick={
                          type == "serious"
                            ? handleClickMarkAsFrivolous
                            : handleClickMarkAsSerious
                        }
                      >
                        {`Mark as ${
                          type == "serious" ? "frivolous" : "serious"
                        }`}
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              {/* Disputes list starts from here */}
              <Box className={classes.spaceBox}></Box>
              <Grid container style={{ rowGap: "8px" }}>
                <Paper className={classes.titlePaper} square>
                  <Grid container spacing={2} justifyContent="flex-start">
                  <>
                    <Grid item xs sm={1}>
                      <div style={{display:'flex',alignItems:'center'}}>
                        {(type === "serious" || type === "frivolous") && ( 
                          <Checkbox
                            color="primary"
                            style={{padding:'5px',visibility:'hidden'}}
                            // onChange={handleSelectAll}
                          />
                        )}
                        <Typography className={classes.title}>
                          G. ID
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs sm={2}>
                      <Typography className={classes.title}>
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs sm={4}>
                      <Typography className={classes.title}>
                        COMPLAINT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item xs sm={1}>
                      <Typography className={classes.title} style={{textAlign:'center'}}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs sm={1}>
                      <Typography className={classes.title} style={{textAlign:'center'}}>
                        Complaint Category
                      </Typography>
                    </Grid>
                    <Grid item xs sm={1}>
                      <Typography className={classes.title} style={{textAlign:'center'}}>
                        Days Passed
                      </Typography>
                    </Grid>
                    <Grid item xs sm={2}>
                      <Typography className={classes.title} style={{textAlign: 'left'}}>
                        Date & Time
                      </Typography>
                    </Grid>
                  </>
                  </Grid>
                </Paper>
                {disputesBySearch.length !== 0 ? (
                  disputesBySearch.map((dispute) => {
                    return (
                      <DisputeRowMuthoot
                        id={dispute.id}
                        name={dispute.name}
                        filingDate={dispute.created}
                        registrationDate={dispute.approved_date}
                        type={type}
                        history={history}
                        addDispute={addDispute}
                        removeDispute={removeDispute}
                        reload={reload}
                        complaint_category={dispute.summery}
                        status={dispute.muthoot_status === 'MD_IO' ? 'MD-IO' : dispute.muthoot_status === 'MD_GRO' ? 'MD-GRO' : dispute.forward ? dispute.forward_md ? 'MD' : 'IO' : 'GRO'}
                        action_due={calc_days(dispute.created)}
                        complaint_details={dispute.description}
                      />
                    );
                  })
                ) : (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box textAlign="center">
                        <Typography
                          style={{
                            fontFmaily: '"Inter","sans-serif"',
                            fontWeight: 500,
                            fontSize: "initial",
                          }}
                        >
                          Dispute does not exist.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Box height="10px"></Box>
              {disputesBySearch.length !== 0 && !searchText && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: `Roboto,'sans-serif'`,
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      <IconButton
                        disabled={currentPage == 1}
                        onClick={(e) => {
                          setCurrentPage(currentPage - 1);
                          getUserDataAnotherPage(currentPage - 1);
                        }}
                      >
                        <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                      </IconButton>
                      {`${currentPage} / ${totalPages}`}
                      <IconButton
                        disabled={currentPage >= totalPages}
                        onClick={(e) => {
                          setCurrentPage(currentPage + 1);
                          getUserDataAnotherPage(currentPage + 1);
                        }}
                      >
                        <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <POPUP
          opener={openDeletePopup}
          onClose={handleCloseDelete}
          heading="Delete grievances"
          message="Are you sure you would like to delete the selected grievance(s)?"
          mainButton="DELETE"
          onMainButton={handleDelete}
        />
        <DeletedPopup
          opener={openDeletedGifPopup}
          onClose={handleCloseDeletedGifPopup}
          text="Deleted!"
        />
        <POPUP
          opener={openSeriousPopup}
          onClose={handleCloseSerious}
          heading="Move grievances"
          message="Are you sure you would like to move the selected grievance(s) to serious?"
          mainButton="MOVE"
          onMainButton={handleSerious}
        />
        <DonePopup
          opener={openSeriousGifPopup}
          onClose={handleCloseSeriousGifPopup}
          text="Moved to serious"
        />
        <POPUP
          opener={openFrivolousPopup}
          onClose={handleCloseFrivolous}
          heading="Move grievances"
          message="Are you sure you would like to move the selected grievance(s) to frivolous?"
          mainButton="MOVE"
          onMainButton={handleFrivolous}
        />
        <DonePopup
          opener={openFrivolousGifPopup}
          onClose={handleCloseFrivolousGifPopup}
          text="Moved to frivolous"
        />
        <Popup
          open={openDatePicker}
          onClose={handleCloseDatePicker}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          <DatePickerCalendar 
            locale={enGB} 
            // date={isValidDate(daysButtonText) ? new Date(daysButtonText) : new Date()}
            onDateChange={handleSelectDate} 
          />  
        </Popup>
      </>
    );
  }
  