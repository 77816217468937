import {
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isHotStar, isMuthoot, isStar } from "Routes/Routes";
import { forgotPassword } from "slices/loginSlice";
import * as Yup from "yup";
import React from "react";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
});

const useStyles = makeStyles((theme) => {
  return {
    form: {
      width: "80%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2rem",
    },

    loginButton: {
      margin: theme.spacing(3, 0, 2),
    },
    hoverButton: {
      background: "#3F51B5",
      color: "#FFFFFF",
      border: "1px solid transparent",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#3F51B5",
        border: "1px solid #3F51B5",
      },
    },
  };
});

export default function PasswordReset() {
  const history = useHistory();
  const [error, setError] = React.useState();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      let result = await dispatch(forgotPassword(values.email));
      if (result.status === "OK") {
        history.push("/forgot-password-confirm");
      } else {
        setError(result.reason);
      }
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="filled"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label={<FormattedMessage id="user.email" />}
            name="email"
            autoComplete="email"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              setError();
            }}
          />
          {formik.errors.email && formik.touched.email && (
            <Alert severity="error">{formik.errors.email}</Alert>
          )}
          {error && <Alert severity="error">{error}</Alert>}
          <Box height="1rem"></Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={`${classes.loginButton} ${(isHotStar || isStar || isMuthoot) && classes.hoverButton}`}
          >
            {<FormattedMessage id="auth.paswordReset" />}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
