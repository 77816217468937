import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import React from "react";
import { overviewData, trackingData } from "./MockData";
import TrackingRowData from "./Overview/TrackingRowData";

const Overview = ({ classes }) => {
  const { total, p1, p2, p3 } = overviewData;

  return (
    <>
      <Grid container>
        <Grid item xl={4} lg={5} md={6} sm={8} xs={12}>
          <Grid
            container
            className={classes.totalnoticebox}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography className={classes.noticeboxblacktext}>
                Total Notices sent
              </Typography>
              <Typography className={classes.noticeboxbluetext}>
                {total}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.noticeboxblacktext}>
                Bucket 1
              </Typography>
              <Typography className={classes.noticeboxbluetext}>
                {p1}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.noticeboxblacktext}>
                Bucket 2
              </Typography>
              <Typography className={classes.noticeboxbluetext}>
                {p2}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.noticeboxblacktext}>
                Bucket 3
              </Typography>
              <Typography className={classes.noticeboxbluetext}>
                {p3}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box height="3.5rem" />
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.blackheading}>Tracking</Typography>
          <Box className={classes.trackbox}>
            <Grid
              container
              className={classes.trackheadergrid}
              justify="space-between"
              spacing={1}
            >
              <Grid item sm={1} xs={3}>
                <Typography className={classes.noticeboxblacktext}>
                  Loan Id
                </Typography>
              </Grid>
              <Grid item sm={1} xs={3}>
                <Typography className={classes.noticeboxblacktext}>
                  Notice ID
                </Typography>
              </Grid>
              <Grid item sm={2} xs={4}>
                <Typography className={classes.noticeboxblacktext}>
                  Recipient Name
                </Typography>
              </Grid>
              <Grid item sm={1} xs={2}>
                <Typography className={classes.noticeboxblacktext}>
                  Type
                </Typography>
              </Grid>
              <Hidden xsDown>
                <Grid item sm xs>
                  <Typography className={classes.noticeboxblacktext}>
                    SMS
                  </Typography>
                </Grid>
                <Grid item sm xs>
                  <Typography className={classes.noticeboxblacktext}>
                    WhatsApp
                  </Typography>
                </Grid>
                <Grid item sm xs>
                  <Typography className={classes.noticeboxblacktext}>
                    Email
                  </Typography>
                </Grid>
                <Grid item sm xs>
                  <Typography className={classes.noticeboxblacktext}>
                    Mail Delivery
                  </Typography>
                </Grid>
                <Grid item sm xs>
                  <Typography className={classes.noticeboxblacktext}>
                    Notice Template
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
            <Box height="1rem" />
            {trackingData.map((item, index) => (
              <TrackingRowData item={item} classes={classes} key={index} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
