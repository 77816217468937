import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import FormattedMessage from "features/common/TranslatedMessage";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import instance from "api/globalaxios";
import Axios from "axios";
import {
  blockInvalidChar,
  getProfileTiacDispute,
  getTempDetails,
  StyledTextFieldTiac,
} from "features/TempTiacForm/ClaimantPage/questions";
import { getCountries } from "slices/profileSlice";
import { updateDisputeTiac } from "features/TempTiacForm/UploadPage/questions";

// import "intro.js/introjs.css";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // "font-size": "18px",
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    color: "#2C3345",
    padding: "1rem",
    fontFamily: `'Inter',sans-serif`,
  },

  text2: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },
  text3: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

const SignupSchemaRequired = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  full_name: Yup.string().required("Full name is required"),
});
const SignupSchemaNotRequired = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
});

var signUpData = {};
const RespLawyerPage = () => {
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();

  let id = localStorage.getItem("dispute_id");
  let localstorageEmail = localStorage.getItem("email");

  function autoFill(fetchedProfile) {
    formik.setFieldTouched("email", false);
    formik.setFieldTouched("full_name", false);
    formik.setFieldTouched("phone_number", false);
    formik.setFieldTouched("address", false);
    formik.setFieldTouched("city", false);
    formik.setFieldTouched("region", false);
    formik.setFieldTouched("postal_code", false);
    formik.setFieldValue("email", fetchedProfile.user.email);
    formik.setFieldValue(
      "full_name",
      fetchedProfile.name
        ? fetchedProfile.name
        : fetchedProfile.user.first_name + " " + fetchedProfile.user.last_name
    );
    formik.setFieldValue(
      "phone_number",
      fetchedProfile.phone_number ? fetchedProfile.phone_number : ""
    );
    formik.setFieldValue("fax", fetchedProfile.fax ? fetchedProfile.fax : "");
    formik.setFieldValue(
      "address",
      fetchedProfile.address ? fetchedProfile.address : ""
    );
    formik.setFieldValue(
      "city",
      fetchedProfile.city ? fetchedProfile.city : ""
    );
    formik.setFieldValue(
      "region",
      fetchedProfile.region ? fetchedProfile.region : ""
    );
    formik.setFieldValue(
      "postal_code",
      fetchedProfile.postal_code ? fetchedProfile.postal_code : ""
    );
    setCountry(fetchedProfile.country ? fetchedProfile.country : "-");
    setFetchedProfile();
  }
  const [dispute, setDispute] = useState();

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  useEffect(() => {
    getTempDetails(id).then((res) => {
      if (res && res.data) {
        if (res.data.respondent_lawyer_email) {
          formik.setFieldTouched("email", false);
          formik.setFieldValue("email", res.data.respondent_lawyer_email);
        }
        if (res.data.respondent_lawyer_name) {
          formik.setFieldTouched("full_name", false);
          formik.setFieldValue("full_name", res.data.respondent_lawyer_name);
        }
        if (res.data.respondent_lawyer_phone_number) {
          formik.setFieldTouched("phone_number", false);
          formik.setFieldValue(
            "phone_number",
            res.data.respondent_lawyer_phone_number
          );
        }
        if (res.data.respondent_lawyer_fax) {
          formik.setFieldValue("fax", res.data.respondent_lawyer_fax);
        }
        if (res.data.respondent_lawyer_address) {
          formik.setFieldTouched("address", false);
          formik.setFieldValue("address", res.data.respondent_lawyer_address);
        }
        if (res.data.respondent_lawyer_city) {
          formik.setFieldTouched("city", false);
          formik.setFieldValue("city", res.data.respondent_lawyer_city);
        }
        if (res.data.respondent_lawyer_region) {
          formik.setFieldTouched("region", false);
          formik.setFieldValue("region", res.data.respondent_lawyer_region);
        }
        if (res.data.respondent_lawyer_postal_code) {
          formik.setFieldTouched("postal_code", false);

          formik.setFieldValue(
            "postal_code",
            res.data.respondent_lawyer_postal_code
          );
        }
        if (res.data.respondent_lawyer_country) {
          setCountry(
            res.data.respondent_lawyer_country
              ? res.data.respondent_lawyer_country
              : "-"
          );
        }
      }
    });
  }, [id]);

  React.useEffect(() => {
    getDisputeTiac(localstorageEmail, id)
      .then((res) => {
        if (res.data.results[0].id) {
          setDispute(res.data.results[0]);
          // if (res.data.results[0].rv_respondent_lawyer_step_complete)
          getProfileTiacDispute(
            localstorageEmail,
            id,
            "respondent_lawyer"
          ).then((res) => {
            if (res.data.user) {
              setFetchedProfile(res.data);
              autoFill(res.data);
            }
          });
        } else history.push("/RFA");
      })
      .catch((e) => {
        history.push("/RFA");
      });
  }, []);

  function getProfiles(email, cancelToken) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/tiacanonymousemailautofill/?email=${email}`,
          { cancelToken: cancelToken.token }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const handleEmailChange = (email) => {
    try {
      // let email = e.traget.value;
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email &&
          res.results[0].profile_type == "lawyer_rv"
        ) {
          setFetchedProfile(res.results[0]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  const [allCountries, setallCountries] = useState([]);
  const [country, setCountry] = useState("-");

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  let history = useHistory();

  async function TiaccreateprofileDisputeId() {
    let data = {
      email: formik.values.email,
      profile_type: "lawyer_rv",
      for: "respondent",
      dispute_id: id,
    };
    if (formik.values.full_name.trim() != "") {
      data.full_name = formik.values.full_name;
    }
    if (formik.values.address.trim() != "") {
      data.address = formik.values.address;
    }
    if (formik.values.city.trim() != "") {
      data.city = formik.values.city;
    }
    if (formik.values.region.trim() != "") {
      data.region = formik.values.region;
    }
    if (formik.values.postal_code.trim() != "") {
      data.postal_code = formik.values.postal_code;
    }
    if (country && country != "-") {
      data.country = country;
    } else {
      data.country = null;
    }

    let ph = "" + formik.values.phone_number;
    if (ph.trim() != "") {
      data.phone_number = ph;
    }
    if (formik.values.fax.trim() != "") {
      data.fax = formik.values.fax;
    }
    return await instance.post("/tiaccreateprofile/", data);
  }

  async function saveTempDetailsRespondentLawyer(data) {
    return await instance.post(`/tiacrespondentlawyerdetails/`, {
      dispute_id: data.dispute_id,
      email: data.email,
      full_name: data.full_name,
      address: data.address,
      city: data.city,
      region: data.region,
      postal_code: data.postal_code,
      country: data.country,
      phone_number: data.phone_number,
      fax: data.fax,
    });
  }

  const formik = useFormik({
    initialValues: {
      full_name: "",

      address: "",
      city: "",
      region: "",
      postal_code: "",
      phone_number: "",
      email: "",
      fax: "",
    },

    validationSchema: SignupSchemaNotRequired,

    onSubmit: async (values) => {
      let {
        full_name,
        address,
        city,
        region,
        postal_code,
        phone_number,
        email,
        fax,
      } = values;

      signUpData = values;
      let tempData = {
        dispute_id: id,
        email: email,
        full_name: full_name,
        address: address,
        city: city,
        region: region,
        postal_code: postal_code,
        country: country == "-" ? null : country,
        phone_number: phone_number,
        fax: fax,
      };
      updateDisputeTiac(
        {
          id: id,
          rv_respondent_lawyer_step_complete: true,
        },
        localstorageEmail
      );
      saveTempDetailsRespondentLawyer(tempData).then(() => {
        history.push("/tiac_upload");
      });

      // if (formik.values.email.trim() == "") {
      //   // removeArbitrators(
      //   //   localstorageEmail,
      //   //   localStorage.getItem("dispute_id"),
      //   //   true
      //   // );
      //   history.push("/tiac_upload");
      // } else {

      // }
    },
  });

  const handleOnBack = () => {
    history.push("/tiac_respondent");
  };

  async function handleSaveAsDraft() {
    // if (formik.values.email.trim() == "") {
    //   // removeArbitrators(
    //   //   localstorageEmail,
    //   //   localStorage.getItem("dispute_id"),
    //   //   true
    //   // );
    //   alert("saved");
    // } else {

    // }
    let tempData = {
      dispute_id: id,
      email: formik.values.email,
      full_name: formik.values.full_name,
      address: formik.values.address,
      city: formik.values.city,
      region: formik.values.region,
      postal_code: formik.values.postal_code,
      country: country == "-" ? null : country,
      phone_number: formik.values.phone_number,
      fax: formik.values.fax,
    };
    saveTempDetailsRespondentLawyer(tempData);
    alert("saved");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10} md={8}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" className={classes.text}>
                <FormattedMessage id="tiac.heading4" />
              </Typography>
              <Box height="1rem"></Box>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} justify="flex-start">
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      4.1. Email address/Адрес электронной почты/Электрон почта
                      манзили:
                      {/* <span className={classes.required}>*</span> */}
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.email}
                        autoFocus
                        fullWidth
                        id="email"
                        // label={<FormattedMessage id="user.email" />}
                        name="email"
                        autoComplete="email"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleEmailChange(e.target.value);
                        }}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <Alert severity="error">{formik.errors.email}</Alert>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      {fetchedProfile && (
                        <Typography
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            autoFill(fetchedProfile);
                          }}
                        >
                          Autofill details
                        </Typography>
                      )}
                    </Grid>
                    <Box height="1rem"></Box>

                    <Typography className={classes.text2}>
                      example@example.com
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      4.2. Full name/Название организации (Ф.И.О. если
                      представитель физическое лицо)/Тўлиқ номи (Ф.И.Ш. агар
                      вакил жисмоний шахс бўлса):{" "}
                      {/* <span className={classes.required}>*</span> */}
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        // required
                        variant="outlined"
                        margin="normal"
                        value={formik.values.full_name}
                        fullWidth
                        id="full_name"
                        // label={<FormattedMessage id="user.email" />}
                        name="full_name"
                        autoComplete="full_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.full_name && formik.touched.full_name && (
                        <Alert severity="error">
                          {formik.errors.full_name}
                        </Alert>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem" }}
                  >
                    <Typography className={classes.text1}>
                      4.3. Address/Адрес/Манзил:
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.address}
                        fullWidth
                        id="address"
                        // label={<FormattedMessage id="user.email" />}
                        name="address"
                        autoComplete="address"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Typography className={classes.text2}>
                      Apartment and Street/Название дома и улицы/Ҳонадон ва кўча
                      номи
                    </Typography>
                    <Grid container style={{ padding: "0 1rem" }}>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingRight: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          variant="outlined"
                          margin="normal"
                          value={formik.values.city}
                          fullWidth
                          id="city"
                          // label={<FormattedMessage id="user.email" />}
                          name="city"
                          autoComplete="city"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <Typography className={classes.text3}>
                          Сity (Town)/Город/Шаҳар
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingLeft: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          variant="outlined"
                          margin="normal"
                          value={formik.values.region}
                          fullWidth
                          id="region"
                          // label={<FormattedMessage id="user.email" />}
                          name="region"
                          autoComplete="region"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <Typography className={classes.text3}>
                          Region and State/Название региона(области) и
                          страны/Вилоят ва мамлакат номи
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.postal_code}
                        fullWidth
                        id="postal_code"
                        // label={<FormattedMessage id="user.email" />}
                        name="postal_code"
                        autoComplete="postal_code"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Typography className={classes.text2}>
                      Postal code/Почтовый индекс/Почта индекси
                    </Typography>
                    <Box height="2rem"></Box>
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={12}
                      style={{ padding: "1rem" }}
                    >
                      <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel> */}
                        <Select
                          labelId="demo-country-select-label"
                          id="demo-country-select"
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                          }}
                        >
                          <MenuItem value={"-"}>---</MenuItem>
                          {allCountries.map((country) => {
                            return (
                              <MenuItem key={country.id} value={country.id}>
                                {country.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Typography className={classes.text2}>
                      Country/Страна/Мамлакат
                    </Typography>
                  </Grid>
                  <Box height="2rem" />
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem", marginTop: "1.5rem" }}
                  >
                    <Typography className={classes.text1}>
                      4.4. Telephone number/Номер телефона/Телефон рақами
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <Grid item md={5} sm={10} xs={12}>
                        <StyledTextFieldTiac
                          // required
                          type="number"
                          variant="outlined"
                          margin="normal"
                          value={formik.values.phone_number}
                          fullWidth
                          id="phone_number"
                          // label="(000) 000-0000"
                          // label={<FormattedMessage id="user.email" />}
                          name="phone_number"
                          autoComplete="phone_number"
                          onKeyDown={blockInvalidChar}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <Box height="1rem"></Box>
                      </Grid>

                      <Typography className={classes.text3}>
                        Please enter only numerals and a valid contact
                        number/Пожалуйста, введите номер с кодом и только
                        цифры/Илтимос, телефон рақамни код ва фақат рақамлар
                        билан киритинг
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem", marginTop: "1.5rem" }}
                  >
                    <Typography className={classes.text1}>
                      4.5. Fax/Факс/Факс
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.fax}
                        fullWidth
                        id="fax"
                        // label={<FormattedMessage id="user.email" />}
                        name="fax"
                        autoComplete="fax"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box height="4rem"></Box>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Button variant="outlined" onClick={handleOnBack}>
                      <Typography className={classes.btnTextBlack}>
                        Back
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ background: "#2F80ED" }}
                      >
                        <Typography className={classes.btnTextWhite}>
                          <FormattedMessage id="buttons.proceed" />
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleSaveAsDraft();
                      }}
                    >
                      <Typography className={classes.btnTextBlack}>
                        Save as draft
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};

export default RespLawyerPage;
