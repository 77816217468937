import { createSlice } from "@reduxjs/toolkit";
// import instance from "api/globalaxios";
// import instance, { instanceDPCGC} from "api/globalaxios";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  chatgroup: {},
};

const chatGroupSlice = createSlice({
  name: "chatgroup",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.chatgroup = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { setProgress, setSucessfull, setError } = chatGroupSlice.actions;

export default chatGroupSlice.reducer;

export function createChatGroup(dispute__id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/api/chatgroup/" + dispute__id + "/",
      });

      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}
export function createChatGroupfordev(dispute__id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/api/chatgroupfordev/" + dispute__id + "/",
      });

      dispatch(setSucessfull(response.data));
      console.log(" responseeee", response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}
export function createDPCCChatGroup(dispute__id) {
  console.log("inside createDpcgcChatGroup");
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instanceDPCGC({
        method: "put",
        url: "/api/chatgroup/" + dispute__id + "/",
      });

      dispatch(setSucessfull(response.data));
      console.log("daaaataaaa", response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}

export function createRvChatGroup(dispute__id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/api/rvchatgroup/" + dispute__id + "/",
      });

      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}
export function createRvChatGroupfordev(dispute__id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/api/rvchatgroupfordev/" + dispute__id + "/",
      });

      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}

export async function getRocketChatUserFullName(username){
  return await instance.get(`/getrocketuserfullname/${username}/`)
}
