import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  getFiles,
  getFolder,
  getFolders,
  UpdateFile,
  UpdateFolder,
} from "./FileApi";
import NotifyOthers from "./NotifyOthers";
import {
  isArb,
  isDifc,
  isKilumaya,
  isMSME,
  isSebi,
  isTiac,
} from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    position: "absolute",
    left: "50%",
    top: "45%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      top: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      // top: "45%",
    },
  },
  firstText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `Satoshi-Bold,'Inter',sans-serif`,
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  selected: {
    background: "#F2F2F2",
    padding: "0.3rem 1.3rem",
  },
  unselected: {
    padding: "0.3rem 1.3rem",
    cursor: "pointer",
  },
  choiceText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  fieldsText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    marginTop: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.3rem",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  inputfield: {
    width: "100%",
    height: "3rem",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    "&:focus": {
      outline: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  dropbox: {
    width: "100%",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "1rem 2px",
    textAlign: "center",
  },
  alertSelected: {
    padding: "0.3rem",
    background: "#E0E0E0",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  alertUnSelected: {
    padding: "0.3rem",
    cursor: "pointer",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  cancel: {
    textDecoration: "underline",
    fontSize: "20px",
    fontWeight: "400",
    color: "#2F80ED",
    lineHeight: "36px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "26px",
    },
  },
  add: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: "36px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  addbtn: {
    background: "#2F80ED",
    marginLeft: "3rem",
    width: "120px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      marginLeft: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
      marginLeft: "1rem",
    },
  },
  permissionsTable: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `Satoshi-Medium,'Inter',sans-serif`,
    color: "black",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  permissionsTableRows: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: `Satoshi-Medium,'Inter',sans-serif`,
    color: "black",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  box: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "20rem",
    cursor: "all-scroll",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "15rem",
    },
  },
}));

export default function EditPermissions({
  openmodal,
  handleCloseModal,
  handleCloseModalRefresh,
  disputeId,
  files,
  folders,
  allProfiles,
  parent_folder,
  profile,
  viewPermission,
  editPermission,
}) {
  const classes = useStyles();

  const [viewPermissions, setViewPermissions] = useState(viewPermission);
  const [editPermissions, setEditPermissions] = useState(editPermission);
  const [alert, setAlert] = useState("on");

  async function updatePermissionParentFolder(parent) {
    const prevViewPermissions = parent.view_permission;
    const prevDeletePermissions = parent.edit_permission;
    viewPermissions.forEach((element) => {
      if (!prevViewPermissions.includes(element)) {
        prevViewPermissions.push(element);
      }
    });
    editPermissions.forEach((element) => {
      if (!prevDeletePermissions.includes(element)) {
        prevDeletePermissions.push(element);
      }
    });
    UpdateFolder(parent.id, {
      view_permission: prevViewPermissions,
    });
    if (parent.parent_folder) {
      getFolder(parent.parent_folder.id).then((response) => {
        updatePermissionParentFolder(response.data[0]);
      });
    }
    return 0;
  }

  function updatePermissionChildFolderAndFiles(parent) {
    getFiles(disputeId, parent.id).then((res) => {
      res.data.forEach((element) => {
        UpdateFile(element.id, {
          view_permission: viewPermissions,
          permission: editPermissions,
        });
      });
    });
    getFolders(disputeId, parent.id).then((res) => {
      res.data.forEach((element) => {
        UpdateFolder(element.id, {
          view_permission: viewPermissions,
          edit_permission: editPermissions,
        });
        updatePermissionChildFolderAndFiles(element);
      });
    });
  }

  function arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  async function updateFilePermission(file_id) {
    return UpdateFile(
      file_id,
      {
        view_permission: viewPermissions,
        permission: editPermissions,
      },
      alert
    );
  }

  const handleChangePermissions = () => {
    console.log("viewPermissions", viewPermissions);
    console.log("editPermissions", editPermissions);
    files.map((file) => handleChangePermissionsFile(file));
    folders.map((folder) => handleChangePermissionsFolder(folder));
  };

  const handleChangePermissionsFile = (file) => {
    if (parent_folder) {
      if (
        arrayEquals(parent_folder.view_permission, viewPermissions) &&
        arrayEquals(parent_folder.edit_permission, editPermissions)
      ) {
        // no need to change folder permissions
      } else {
        updatePermissionParentFolder(parent_folder).then((r) => {
          updateFilePermission(file.id).then(() => {
            handleCloseModalRefresh();
          });
        });
      }
    } else {
      updateFilePermission(file.id).then(() => {
        handleCloseModalRefresh();
      });
    }
  };

  const handleChangePermissionsFolder = (folder) => {
    if (parent_folder) {
      if (
        arrayEquals(parent_folder.view_permission, viewPermissions) &&
        arrayEquals(parent_folder.edit_permission, editPermissions)
      ) {
        // no need to change folder permissions
      } else {
        const prevViewPermissions = parent_folder.view_permission;
        const prevDeletePermissions = parent_folder.edit_permission;
        viewPermissions.forEach((element) => {
          if (!prevViewPermissions.includes(element)) {
            prevViewPermissions.push(element);
          }
        });
        editPermissions.forEach((element) => {
          if (!prevDeletePermissions.includes(element)) {
            prevDeletePermissions.push(element);
          }
        });
        UpdateFolder(parent_folder.id, {
          view_permission: prevViewPermissions,
        }).then(() => {
          UpdateFolder(
            folder.id,
            {
              view_permission: viewPermissions,
              edit_permission: editPermissions,
            },
            alert
          ).then(() => {
            updatePermissionParentFolder(parent_folder);
            updatePermissionChildFolderAndFiles(folder);
            handleCloseModalRefresh();
          });
        });
      }
    } else {
      UpdateFolder(
        folder.id,
        {
          view_permission: viewPermissions,
          edit_permission: editPermissions,
        },
        alert
      ).then(() => {
        updatePermissionChildFolderAndFiles(folder);
        handleCloseModalRefresh();
      });
    }
  };

  const body = (
    <Box className={classes.paper}>
      <Typography className={classes.firstText}>Permissions</Typography>
      <Box height="1rem" />
      <Grid container className={classes.container} justify="center">
        <Box
          style={{
            background: "#F2F2F2",
            width: "100%",
            border: "1px solid #E0E0E0",
          }}
        >
          <Box
            style={{
              textAlign: "center",
            }}
            className={classes.box}
          >
            <Typography className={classes.permissionsTable}>
              Permissions
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container className={classes.container} justify="center">
        <Grid container style={{ width: "100%" }}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            style={{
              height: "-webkit-fill-available",
              borderBottom: "1px solid #E0E0E0",
              borderLeft: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Box
              style={{
                background: "#F2F2F2",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className={classes.box}>
                <Typography className={classes.permissionsTable}>
                  Name
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            style={{
              height: "-webkit-fill-available",
              borderBottom: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Box
              style={{
                background: "#F2F2F2",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className={classes.box}>
                <Typography className={classes.permissionsTable}>
                  Profile type
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xl={
              ((isSebi || isMSME) && profile.profile_type === "admin") ||
              !(isSebi || isMSME)
                ? 2
                : 4
            }
            lg={
              ((isSebi || isMSME) && profile.profile_type === "admin") ||
              !(isSebi || isMSME)
                ? 2
                : 4
            }
            md={
              ((isSebi || isMSME) && profile.profile_type === "admin") ||
              !(isSebi || isMSME)
                ? 2
                : 4
            }
            sm={
              ((isSebi || isMSME) && profile.profile_type === "admin") ||
              !(isSebi || isMSME)
                ? 2
                : 4
            }
            xs={
              ((isSebi || isMSME) && profile.profile_type === "admin") ||
              !(isSebi || isMSME)
                ? 2
                : 4
            }
            style={{
              height: "-webkit-fill-available",
              borderBottom: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Box
              style={{
                background: "#F2F2F2",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                style={{
                  textAlign: "center",
                }}
                className={classes.box}
              >
                <Typography className={classes.permissionsTable}>
                  View
                </Typography>
              </Box>
            </Box>
          </Grid>
          {(((isSebi || isMSME) && profile.profile_type === "admin") ||
            !(isSebi || isMSME)) && (
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              style={{
                height: "-webkit-fill-available",
                borderBottom: "1px solid #E0E0E0",
                borderRight: "1px solid #E0E0E0",
              }}
            >
              <Box
                style={{
                  background: "#F2F2F2",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  style={{
                    textAlign: "center",
                  }}
                  className={classes.box}
                >
                  <Typography className={classes.permissionsTable}>
                    Edit
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box className={classes.scroll}>
        {allProfiles.map((item) => {
          let checkedView = "";
          checkedView = viewPermissions.includes(item.user.id) ? true : false;
          const handleViewCheckbox = (e) => {
            checkedView = e.target.checked;
            if (e.target.checked) {
              const arr = [...viewPermissions];
              arr.push(item.user.id);
              setViewPermissions(arr);
            } else {
              let arr = [...viewPermissions];
              const index = arr.indexOf(item.user.id);
              if (index > -1) {
                arr.splice(index, 1);
                setViewPermissions(arr);
                checkedEdit = false;
                arr = [...editPermissions];
                const indexEdit = arr.indexOf(item.user.id);
                if (indexEdit > -1) {
                  arr.splice(indexEdit, 1);
                  setEditPermissions(arr);
                }
              }
            }
          };
          let checkedEdit = "";
          checkedEdit = editPermissions.includes(item.user.id) ? true : false;
          const handleEditCheckbox = (e) => {
            checkedEdit = e.target.checked;
            if (e.target.checked) {
              let arr = [...editPermissions];
              arr.push(item.user.id);
              setEditPermissions(arr);
              if (!viewPermissions.includes(item.user.id)) {
                arr = [...viewPermissions];
                arr.push(item.user.id);
                setViewPermissions(arr);
                checkedView = true;
              }
            } else {
              const arr = [...editPermissions];
              const index = arr.indexOf(item.user.id);
              if (index > -1) {
                arr.splice(index, 1);
                setEditPermissions(arr);
              }
            }
          };
          return (
            <Grid
              container
              className={classes.container}
              justify="center"
              key={item.user.id}
            >
              <Grid container>
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={5}
                  sm={5}
                  xs={5}
                  style={{
                    height: "-webkit-fill-available",
                    borderBottom: "1px solid #E0E0E0",
                    borderLeft: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    style={{
                      background: "#F2F2F2",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box className={classes.box}>
                      <Typography className={classes.permissionsTableRows}>
                        {item.user.first_name} {item.user.last_name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  style={{
                    height: "-webkit-fill-available",
                    borderBottom: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    style={{
                      background: "#F2F2F2",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box className={classes.box}>
                      <Typography className={classes.permissionsTableRows}>
                        {item.profile.profile_type}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={
                    ((isSebi || isMSME) && profile.profile_type === "admin") ||
                    !(isSebi || isMSME)
                      ? 2
                      : 4
                  }
                  lg={
                    ((isSebi || isMSME) && profile.profile_type === "admin") ||
                    !(isSebi || isMSME)
                      ? 2
                      : 4
                  }
                  md={
                    ((isSebi || isMSME) && profile.profile_type === "admin") ||
                    !(isSebi || isMSME)
                      ? 2
                      : 4
                  }
                  sm={
                    ((isSebi || isMSME) && profile.profile_type === "admin") ||
                    !(isSebi || isMSME)
                      ? 2
                      : 4
                  }
                  xs={
                    ((isSebi || isMSME) && profile.profile_type === "admin") ||
                    !(isSebi || isMSME)
                      ? 2
                      : 4
                  }
                  style={{
                    height: "-webkit-fill-available",
                    borderBottom: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    style={{
                      background: "#F2F2F2",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      style={{
                        textAlign: "center",
                      }}
                      className={classes.box}
                    >
                      <input
                        type="checkbox"
                        checked={checkedView}
                        onChange={handleViewCheckbox}
                      />
                    </Box>
                  </Box>
                </Grid>
                {(((isSebi || isMSME) && profile.profile_type === "admin") ||
                  !(isSebi || isMSME)) && (
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    style={{
                      height: "-webkit-fill-available",
                      borderBottom: "1px solid #E0E0E0",
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <Box
                      style={{
                        background: "#F2F2F2",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box
                        style={{
                          textAlign: "center",
                        }}
                        className={classes.box}
                      >
                        <input
                          type="checkbox"
                          checked={checkedEdit}
                          onChange={handleEditCheckbox}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <NotifyOthers alert={alert} setAlert={setAlert} />
      <Box height="1rem" />
      <Grid
        container
        justify="flex-end"
        className={classes.container}
        spacing={2}
      >
        <Grid item>
          {isSebi || isMSME || isTiac || isDifc || isArb || isKilumaya ? (
            <Button
              color="black"
              variant="outlined"
              onClick={handleCloseModal}
              style={{ border: "1px solid" }}
            >
              Cancel
            </Button>
          ) : (
            <Typography className={classes.cancel} onClick={handleCloseModal}>
              Cancel
            </Typography>
          )}
        </Grid>
        <Grid item>
          {isSebi || isMSME || isTiac || isDifc || isArb || isKilumaya ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleChangePermissions}
              style={{ padding: "5px 15px" }}
            >
              Change
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.addbtn}
              onClick={handleChangePermissions}
            >
              <Typography className={classes.add}>Change</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      <Box height="1rem" />
    </Box>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
