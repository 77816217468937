import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import instance from "api/globalaxios";
import Axios from "axios";
import {
  isValidDate,
  isValidMonth,
  isValidNumber,
} from "features/disputes/HotstarDisputes/Disputes";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useState } from "react";
import Chart from "react-google-charts";
import { FormattedMessage } from "react-intl";
import { DateRangePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isDigipub, isHotStar, isMuthoot, isStar } from "Routes/Routes";
import {
  ComplianceCompanyReportDocxGeneratorHotstar,
  ComplianceCompanyReportPdfGeneratorHotstar,
  Level1ReportGeneratorHotstar,
} from "utils/apituils";
import searchRafiki from "../../images/hotstardisputeslistsearch.svg";
import "./Analytics.css";
import DisputeRow from "./DisputeRow";
import arrowDownImage from "./down-arrow.svg";
import PieChart from "./NewPieChart";
import Tabl from "./Table";
import debounce from "lodash.debounce";
import arrowUpImage from "./up-arrow.svg";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem",
    // cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "3.6rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.8rem",
    },
  },
  value: {
    // fontSize: 14,
    border: "none",
    padding: "0.5rem",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
  searchbarBox: {
    fontSize: "20px",
    borderRadius: "16px",
    background: "white",
    wordWrap: "",
    border: "none",
  },
  searchbar: {
    border: "none",
    width: "100%",
    color: "#828282",
    fontSize: "15px",
    margin: "0.7rem 2rem",
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      margin: "0.7rem 0 0.7rem 0.5rem",
    },
  },
  generatebox: {
    background: "#F2F2F2",
    borderRadius: "4px",
    padding: "1rem 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  blackgenerate: {
    fontSize: "16px",
    color: "#000000",
  },
  boxCheckboxes: {
    background: "#ffffff",
    borderRadius: "4px",
    padding: "2rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  blackcheckbox: {
    fontFamily: `Roboto,Helvetica,Arial`,
    fontSize: "14px",
    color: "#454545",
  },
  blackselectdate: {
    fontSize: "12px",
    color: "#4F4F4F",
  },
  input1: {
    maxWidth: "45%",
    outline: "none",
    border: "none",
    color: "#454545",
    fontSize: "12px",
  },
  pdf: {
    color: "#3F51B5",
    textDecoration: "underline",
    fontSize: "20px",
    lineHeight: "36px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  reportbtn: {
    background: "#3F51B5",
    borderRadius: "2px",
    boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.24)`,
    width: "100%",
    padding: "10px 40px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
  compliancereport: {
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

const colors = ["#3F51B5", "#D9D7D7", "#454545"];

export default function Analytics({
  start_Date,
  set_start,
  end_Date,
  set_end,
  Nodc,
  NodSRO,
  TimeTakenCom,
  TimeTakenSRO,
  TimeTakenCompanyandSRO,
  averageTimeCompany,
  averageTimeSRO,
  averageTimeCompanySRO,
  companyActionTaken,
  SROActionTaken,
  UserId,
}) {
  const classes = useStyles(0);

  const Checkbox = (props) => (
    <input type="checkbox" style={{ margin: "15px" }} {...props} />
  );

  const [noOfDisputes, setNoOfDisputes] = useState(true);
  const handleNoOfDisputes = (event) => {
    setNoOfDisputes(event.target.checked);
  };

  const [action, setAction] = useState(true);
  const handleAction = (event) => {
    setAction(event.target.checked);
  };

  const [timeTaken, setTimeTaken] = useState(true);
  const handleTimeTaken = (event) => {
    setTimeTaken(event.target.checked);
  };

  const [feedback, setFeedback] = useState(true);
  const handleFeedback = (event) => {
    setFeedback(event.target.checked);
  };

  const [displayCompany, setdisplayCompany] = useState(false);
  const [countCompany, setcountCompany] = useState(0);

  const [displaySRO, setdisplaySRO] = useState(false);
  const [countSRO, setcountSRO] = useState(0);

  const [displayCompanySRO, setdisplayCompanySRO] = useState(false);
  const [countCompanySRO, setcountCompanySRO] = useState(0);
  const [rating, setrating] = useState(4);

  const [searchText, setSearchText] = useState("");
  const [disputes, setDisputesBySearch] = useState([]);
  const [loading, setLoadingSearch] = useState(true);
  let firstname = "";
  let lastname = "";

  const [cancelToken, setCancelToken] = useState();

  const debouceSearch = useCallback(debounce(search, 500), [disputes]);

  function search(value, type) {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    let x = Axios.CancelToken.source();
    setCancelToken(x);

    if (value.trim() != "") {
      let ar = value.trim().split("-");
      let date = new Date(ar[1] + "/" + ar[0] + "/" + ar[2]);
      if (isValidDate(date)) {
        dispatch(gethotstardatabydate(date, x, type));
        setLoadingSearch(false);
      } else if (isValidNumber(value.trim())) {
        dispatch(gethotstardatabyDisputeId(value.trim(), x, type));
        setLoadingSearch(false);
      } else if (isValidMonth(value.trim().toLowerCase())) {
        dispatch(gethotstardatabyMonth(value.trim().toLowerCase(), x, type));
        setLoadingSearch(false);
      } else {
        dispatch(gethotstardatabyname(value.trim(), x, type));
        setLoadingSearch(false);
      }
    } else {
      setDisputesBySearch(disputes);
      setLoadingSearch(true);
    }
  }

  function gethotstardatabyname(name, cancelToken, type) {
    return async (dispatch) => {
      try {
        const answer_array = name.split(" ");
        firstname = answer_array[0];
        lastname = answer_array[1];
        if (firstname != "" && (lastname == "" || !lastname)) {
          const response2 = await instance.get(
            `/api/HotstarsearchView1/${firstname}/${null}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response2.data);

          return response2.data;
        } else {
          const response3 = await instance.get(
            `/api/HotstarsearchView1/${firstname}/${lastname}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response3.data);
          return response3.data.profiles;
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabydate(date, cancelToken, type) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/HotstarsearchbydateView/${format(
            date,
            "dd-MM-yyyy"
          )}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputesBySearch(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabyDisputeId(value, cancelToken, type) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/HotstarsearchViewByDisputeId/${value}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputesBySearch(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabyMonth(value, cancelToken, type) {
    return async (dispatch) => {
      var months = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ];
      let index = months.indexOf(value);
      try {
        const response = await instance.get(
          `/api/HotstarsearchbyMonth/${index}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputesBySearch(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  // React.useEffect(() => {
  //   if (searchText != "") {
  //     //Check if there are any previous pending requests
  //     if (typeof cancelToken != typeof undefined) {
  //       cancelToken.cancel("Operation canceled due to new request.");
  //     }
  //     //Save the cancel token for the current request
  //     let x = Axios.CancelToken.source();
  //     setCancelToken(x);

  //     dispatch(gethotstardata(searchText, x));
  //     setLoading(false);
  //   } else {
  //     setDisputes(null);
  //     setLoading(true);
  //   }
  // }, [searchText]);

  let arrActionsTakenSRO = [
    ["Element", "value", { role: "style" }],

    [
      "Taken down",
      !isEmpty(SROActionTaken) ? SROActionTaken[0].value : 0,
      "#3F51B5",
    ],
    [
      "Redacted",
      !isEmpty(SROActionTaken) ? SROActionTaken[1].value : 0,
      "#454545",
    ],
    [
      "Apologised",
      !isEmpty(SROActionTaken) ? SROActionTaken[2].value : 0,
      "#D9D7D7",
    ],
    [
      "Warning",
      !isEmpty(SROActionTaken) ? SROActionTaken[3].value : 0,
      "#3F51B5",
    ],
    [
      "Dismissed",
      !isEmpty(SROActionTaken) ? SROActionTaken[4].value : 0,
      "#454545",
    ],
  ];

  var opti = {
    legend: { position: "none" },
    bar: { groupWidth: "95%" },
    width: "90%",
    vAxis: { format: "0" },
  };

  const toggleSRO = () => {
    let t = displaySRO;
    setdisplaySRO(!t);
  };

  const ColorValues = ({ colors, data }) => {
    return (
      <div className="compnayLevelRightPnale">
        {data.map((singleData, index) => {
          return (
            <Box
              className="companyColor"
              display="flex"
              mb="0.7rem"
              key={singleData.name}
            >
              <Box
                height="1rem"
                width="1rem"
                style={{ backgroundColor: colors[index] }}
              ></Box>
              <Box width="0.3rem"></Box>
              <Typography>{singleData.name}</Typography>
              <Box className="countMargin">
                <br />
                <b>{singleData.value}</b>
              </Box>
            </Box>
          );
        })}
      </div>
    );
  };
  const dispatch = useDispatch();

  // function gethotstardata(movie, cancelToken) {
  //   movie = movie.toLowerCase();
  //   return async (dispatch) => {
  //     try {
  //       const response = await instance.get(
  //         `/api/HotstarsearchView/${movie}/`,
  //         { cancelToken: cancelToken.token }
  //       );
  //       setDisputes(response.data.dispute_id);

  //       return response.data;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // }

  function DisputesList() {
    const classes = useStyles(0);
    const history = useHistory();
    const data = [
      {
        title: <FormattedMessage id="dispute.disputeId" />,
      },
      {
        title: <FormattedMessage id="name" />,
      },

      {
        title: <FormattedMessage id="dispute.startDate" />,
      },
    ];

    return (
      <Container>
        {!isEmpty(disputes) ? (
          <React.Fragment>
            <Box height="0.5rem"></Box>
            <div className={classes.paper}>
              <Grid container justify="flex-start">
                {data.map(({ title }) => {
                  return (
                    <Grid sm={4} xs={4} key={title}>
                      <Box className={classes.contentContainer}>
                        <div>
                          <Typography className={classes.title}>
                            {title}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              {!loading &&
                disputes.map((dispute) => {
                  return <DisputeRow key={dispute.id} dispute={dispute} />;
                })}
            </div>
          </React.Fragment>
        ) : (
          <Grid container justify="center">
            <Grid item>
              <Box textAlign="center">
                <Typography
                  style={{
                    fontFmaily: '"Inter","sans-serif"',
                    fontWeight: 500,
                    fontSize: "initial",
                    marginTop: "2rem",
                  }}
                >
                  Dispute does not exist.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }

  function SRO() {
    if (displaySRO) {
      document.getElementById("icon2").src = arrowUpImage;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {noOfDisputes && (
            <div class="outer short  small-width">
              <h4>Number of grievances raised</h4>
              <p>
                Total{" "}
                <b>
                  {!isEmpty(NodSRO) ? NodSRO[0].value + NodSRO[1].value : 0}
                </b>
              </p>
              <Box className="pie">
                <PieChart data={NodSRO} colors={colors} />
              </Box>
              <Box className="piecolor">
                <ColorValues data={NodSRO} colors={colors} />
              </Box>
            </div>
          )}
          {timeTaken && (
            <div class="timeTakenCard">
              <div class="companyTimeTaken">
                <section>Time taken to close the grievance</section>
                <br />
                Average time taken{" "}
                <b>{isNaN(averageTimeSRO) ? 0 : `${averageTimeSRO} days`}</b>
              </div>
              <div className="space-btm my-custom-scrollbar">
                <Tabl data={TimeTakenSRO} />
              </div>
              <div class="companyExpand">
                <p>EXPAND LIST</p>
              </div>
            </div>
          )}
          {action && (
            <div class="outer short">
              <div class="companyTimeTaken">
                <section>Actions recommended</section>
                <br />
                Actions recommended
              </div>
              <div class="ActionsTaken">
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={arrActionsTakenSRO}
                  options={opti}
                />
              </div>
            </div>
          )}
          <Box height="2rem" />
          <Box>
            <Grid container justify="center" spacing={6}>
              <Grid
                item
                onClick={(e) => {
                  if (start_Date && end_Date) {
                    ComplianceCompanyReportPdfGeneratorHotstar(
                      start_Date,
                      end_Date
                    );
                  } else {
                    alert("Please Select Appropriate Dates");
                  }
                }}
              >
                <Typography className={classes.pdf}>Download .PDF</Typography>
              </Grid>
              <Grid
                item
                onClick={(e) => {
                  if (start_Date && end_Date) {
                    ComplianceCompanyReportDocxGeneratorHotstar(
                      start_Date,
                      end_Date
                    );
                  } else {
                    alert("Please setlect Dates");
                  }
                }}
              >
                <Typography className={classes.pdf}>Download .DOCX</Typography>
              </Grid>
            </Grid>
            <Box height="1rem" />
            <Grid container justify="center">
              <Grid item md>
                <Button
                  className={classes.reportbtn}
                  onClick={(e) => {
                    if (start_Date && end_Date) {
                      Level1ReportGeneratorHotstar(start_Date, end_Date);
                    } else {
                      alert("Please Select Appropriate Dates");
                    }
                  }}
                >
                  <span className={classes.compliancereport}>
                    GENERATE COMPLIANCE REPORT
                  </span>
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box height="5rem" />
          {/* <div class="buttons">
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceSROReportPdfGenerator(start_Date, end_Date);
                } else {
                  alert("Please Select Appropriate Dates");
                }
              }}
              value="Download .PDF"
              style={{
                background: "#3F51B5",
                color: "white",
                cursor: "pointer",
              }}
            />
            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  ComplianceSROReportDocxGenerator(start_Date, end_Date);
                } else {
                  alert("Please setlect Dates");
                }
              }}
              value="Download .DOCX"
              style={{
                background: " #454545",
                color: "white",
                cursor: "pointer",
              }}
            />

            <input
              type="submit"
              onClick={(e) => {
                if (start_Date && end_Date) {
                  Level3ReportGenerator(start_Date, end_Date);
                } else {
                  alert("Please Select Appropriate Dates");
                }
              }}
              value="Generate Compliance Report"
              style={{
                background: "#3F51B5",
                color: "white",
                cursor: "pointer",
                width: "70%",
              }}
            />
          </div> */}
        </div>
      );
    } else {
      if (countSRO != 0) {
        document.getElementById("icon2").src = arrowDownImage;
        return null;
      } else {
        setcountSRO(1);
        return null;
      }
    }
  }

  return (
    <Grid container justify="center">
      <Grid item md={10} sm={11} xs={11}>
        {(isHotStar || isStar || isMuthoot) && (
          <>
            <Box
              style={{ textAlign: "center", marginTop: "30px" }}
              className={classes.searchbarBox}
            >
              <Grid container>
                <Grid
                  item
                  md={11}
                  sm={11}
                  xs={10}
                  style={{ alignSelf: "center" }}
                >
                  <input
                    type="text"
                    className={classes.searchbar}
                    placeholder="Search by title of content"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      debouceSearch(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={1} sm={1} xs={2} style={{ textAlign: "center" }}>
                  <IconButton onClick={() => {}} style={{ paddidng: "0" }}>
                    <img
                      src={searchRafiki}
                      alt="Search"
                      style={{ height: "14px" }}
                    ></img>
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            {!loading && <DisputesList />}
          </>
        )}
        <Box height="4rem" />
      </Grid>
      <Grid item md={9} sm={10} xs={10}>
        <Box className={classes.generatebox}>
          <Typography className={classes.blackgenerate}>
            Generate compliance report for:
          </Typography>
        </Box>
        <Box className={classes.boxCheckboxes}>
          <Grid container spacing={1} justify="center">
            <Grid item md={3} sm={6} xs={12}>
              <label>
                <Checkbox
                  name="cht"
                  checked={noOfDisputes}
                  onChange={handleNoOfDisputes}
                />
                <span className={classes.blackcheckbox}>
                  Number of grievances
                </span>
              </label>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <label>
                <Checkbox
                  name="cht"
                  checked={timeTaken}
                  onChange={handleTimeTaken}
                />
                <span className={classes.blackcheckbox}>Time taken</span>
              </label>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <label>
                <Checkbox name="cht" checked={action} onChange={handleAction} />
                <span className={classes.blackcheckbox}>
                  Action taken/recommended
                </span>
              </label>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DateRangePicker
                startDate={start_Date}
                endDate={end_Date}
                onStartDateChange={set_start}
                onEndDateChange={set_end}
                maximumDate={new Date()}
                // minimumLength={1}
                format="dd/MM/yyyy"
              >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                  <>
                    <div className="date-range1">
                      <div class="date-stye1">
                        <Typography className={classes.blackselectdate}>
                          Select Dates
                        </Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <img
                          src="/calender.png"
                          alt=""
                          class="calendericonLeft"
                        />
                        <div class="date-left1">
                          <input
                            {...startDateInputProps}
                            placeholder="Start date"
                          />
                          -
                          <input
                            {...endDateInputProps}
                            placeholder="End date"
                            style={{ textAlign: "center" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </DateRangePicker>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={9} sm={10} xs={10}>
        <div class="outer company asdf outer-box1" onClick={toggleSRO}>
          <div class="companytext">
            <p>Company level</p>
          </div>
          <div class="companyicon">
            <span>
              <img id="icon2" src={arrowDownImage} />
            </span>
          </div>
        </div>
        <SRO />
      </Grid>
    </Grid>
  );
}
