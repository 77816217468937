import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import querystring from "query-string";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getPaymentLinkObj } from "./SebiPaymentForm";

const useStyles = makeStyles((theme) => ({
  nextButton: {
    marginTop: "2rem",
    background: "#2F80ED",
    color: "white",
    textTransform: "none",
  },
  payment_heading: {
    textAlign: "left",
    fontSize: "1.4rem",
  },
  payment_value: {
    textAlign: "right",
    fontSize: "1.4rem",
  },
}));

function roundedAmount(amount) {
    return Math.round(amount * 100) / 100;
  }

  function calculate18Percent(amount) {
    return roundedAmount(amount * 0.18);
  }

  function TableRow({ heading_name, amount, classes }) {
    return (
      <Grid container justify="center">
        <Grid item xs={4}>
          <Typography className={classes.payment_heading}>
            {heading_name} :
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.payment_value}>{amount}</Typography>
        </Grid>
      </Grid>
    );
  }

function PaymentBreakdown() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const {
    disputeId,
    userid,
    amount,
    payment_link_obj_id,
  } = querystring.parse(location.search);

  const handleClickNext = () => {
    history.push(`/sebi-payment-process${location.search}`);
  };

  useEffect(() => {
    getPaymentLinkObj(payment_link_obj_id).then((res) => {
      // verify details
      if (
        !(
          !res?.data?.disabled &&
          res?.data?.amount === amount &&
          res?.data?.disputeId === disputeId &&
          res?.data?.userid === userid
        )
      ) {
        history.push("/sebi-payment-invalid-link");
      }
      if (res?.data?.breakdown_page_shown) {
        handleClickNext();
      }
    });
  }, [payment_link_obj_id]);

  const igst_amount = calculate18Percent(amount);
  const total_amount = roundedAmount(parseInt(amount) + igst_amount);



  return (
    <Grid container justify="center" style={{ marginTop: "5rem" }}>
      <Grid item md={7} sm={9} xs={11} style={{ textAlign: "center" }}>
        <Typography variant="h3" style={{ marginBottom: "2rem" }}>
          Payment Breakdown
        </Typography>
        <TableRow heading_name="Case Fee" amount={amount}  classes={classes}/>
        <TableRow heading_name="IGST" amount={igst_amount}  classes={classes}/>
        <TableRow heading_name="Total Amount" amount={total_amount}  classes={classes}/>

        <Button
          variant="contained"
          color="primary"
          className={classes.nextButton}
          onClick={handleClickNext}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
}

export default PaymentBreakdown;
