import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import RecordingsModal from "features/Chat/RecordingModal";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  downloadRecording,
  getDPCGCRecordings,
  getRecordings,
} from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  fileCard: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
  },
  filesContainer: {
    "&:last-child": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  greyColor: {
    color: theme.palette.common.grey1,
    paddingTop: "2rem",
    width: '50%' 
  },
}));

export default function Recordings({isRecordingOrFiles, setIsRecordingOrFiles}) {
  const { activeGroup } = useSelector((state) => state.groups, shallowEqual);

  let { disputeId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open2, setOpen2] = React.useState(false);
  const {
    disputes: { recordings: recordings },
  } = useSelector((state) => state, shallowEqual);

  let company = sessionStorage.getItem("company");
  React.useEffect(() => {
    if (company == "dpcgc") {
      dispatch(getDPCGCRecordings(disputeId));
    } else {
      dispatch(getRecordings(disputeId));
    }
  }, []);

  const createFileName = (FileName) => {
    let nameArray = FileName.split("_");
    return nameArray[nameArray.length - 1];
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  function countLen() {
    let x = 0;
    recordings.map((recording) => {
      if (recording.file_name.includes(activeGroup.toLowerCase())) {
        x = x + 1;
      }
    });
    return x;
  }

  var recordingLength = countLen();

  return (
    <Box className="hearingroomstep5">
      {/* <Box display="flex" className={classes.greyColor} p="0 0 0 1rem">
        <VideoLibraryIcon />
        <Box width="0.4rem"></Box>
        <Typography variant="subtitle1">Recordings</Typography>
      </Box> */}
      <Box height="1rem"></Box>
      {isRecordingOrFiles && 
        <Box className={classes.filesContainer}>
          {recordings.length > 0 &&
            recordings.map((recording) => {
              if (recording.file_name.includes(activeGroup.toLowerCase())) {
                recordingLength = recordingLength - 1;
                return (
                  <React.Fragment>
                    {recordingLength < 4 && (
                      <Box
                        onClick={async () => {
                          downloadRecording(recording.id);
                        }}
                        key={recording.file_name}
                        p="1rem 0 1rem 1rem"
                        className={classes.fileCard}
                        textAlign="start"
                      >
                        <Typography variant="subtitle1">
                          {/* {truncate(recording.file_name, 30)} */}
                          {createFileName(recording.file_name)}
                        </Typography>
                      </Box>
                    )}
                  </React.Fragment>
                );
              }
          })}
      </Box>}
      <Box height="0.5rem"></Box>
      {countLen() > 4 && (
        <Box display="flex" justifyContent="center">
          <Button color="primary" onClick={handleOpen2}>
            View More
          </Button>
        </Box>
      )}
      <RecordingsModal
        open={open2}
        handleClose={handleClose2}
        files={recordings}
      />
    </Box>
  );
}
