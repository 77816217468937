import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { CLAIMANT, RESPONDENT } from "app/constants";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createCase } from "slices/casesSlice";
import { createDispute, updateDispute } from "slices/disputeSlice";
import { getProfiles } from "slices/profileSlice";
import SetProfile from "./CreateDispute/SetProfile";
import { isMSME, isSebi } from "Routes/Routes";
import { getCompanyProducts } from "slices/companySlice";

const useStyles = makeStyles((theme) => ({
  createdispute: {
    fontSize: "24px",
    lineHeight: "28px",
  },
  box: { textAlign: "left", margin: "1rem 0 1rem 0" },
}));

export default function CreateDispute() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile, shallowEqual);

  const [company_product, setCompany_product] = useState();

  const [claimant, setClaimant] = useState();
  const [respondent, setrespondent] = useState();
  const [claimantLawyer, setClaimantLawyer] = useState([]);
  const [respondentLawyer, setRespondentLawyer] = useState([]);
  const [arbitrator, setArbitrator] = useState([]);

  const [claimantLoading, setClaimantLoading] = useState(true);
  const [respondentLoading, setRespondentLoading] = useState(true);
  const [claimantLawyerLoading, setClaimantLawyerLoading] = useState(true);
  const [respondentLawyerLoading, setRespondentLawyerLoading] = useState(true);
  const [arbitratorLoading, setArbitratorLoading] = useState(true);

  const [profilesClaimant, setProfilesClaimant] = useState([]);
  const [profilesRespondent, setProfilesRespondent] = useState([]);
  const [profilesClaimantLawyer, setProfilesClaimantLawyer] = useState([]);
  const [profilesRespondentLawyer, setProfilesRespondentLawyer] = useState([]);
  const [profilesArbitrator, setProfilesArbitrator] = useState([]);

  React.useEffect(() => {
    getCompanyProducts(profile.company).then((res) => {
      setCompany_product(res?.[0]?.id);
    });
  }, []);

  let split_by = "vs";
  if (isSebi || isMSME) {
    split_by = "v.";
  }

  React.useEffect(() => {
    getProfiles("general_rv").then((res) => {
      setProfilesClaimant(res);
      setProfilesRespondent(res);
      setClaimantLoading(false);
      setRespondentLoading(false);
    });
    getProfiles("lawyer_rv").then((res) => {
      setProfilesClaimantLawyer(res);
      setProfilesRespondentLawyer(res);
      setClaimantLawyerLoading(false);
      setRespondentLawyerLoading(false);
    });
    getProfiles("arbitrator_rv").then((res) => {
      setProfilesArbitrator(res);
      setArbitratorLoading(false);
    });
  }, []);

  const submit = () => {
    if (claimant && respondent) {
      if (claimant.id == respondent.id) {
        alert("Select different profile for claimant and respondent");
      } else {
        let name =
          claimant.user.first_name +
          " " +
          claimant.user.last_name +
          " " +
          split_by +
          " " +
          respondent.user.first_name +
          " " +
          respondent.user.last_name;
        let description = name;
        let professionals = [];
        arbitrator.forEach((element) => {
          professionals.push(element.id);
        });
        let claimantLawyers = [];
        claimantLawyer.forEach((element) => {
          claimantLawyers.push(element.id);
        });
        let respondentLawyers = [];
        respondentLawyer.forEach((element) => {
          respondentLawyers.push(element.id);
        });
        let disputeBody = {
          name: name,
          description: description,
          platform_type: "rv",
          company_product: company_product,
          professionals: professionals,
          rv_claimant_choose_step_complete: true,
          rv_claimant_lawyer_step_complete: true,
          rv_respondent_choose_step_complete: true,
          rv_respondent_lawyer_step_complete: true,
          rv_arbitrator_step_complete: true,
          rv_tiac_payment_status: "complete",
          tiac_dispute_step: "form_complete",
        };
        dispatch(createDispute(disputeBody)).then((response) => {
          let createCaseBody = {
            summary: description,
            description: description,
            profile: claimant.id,
            dispute: response,
            type: CLAIMANT,
            lawyer_rv: claimantLawyers,
          };
          dispatch(createCase(createCaseBody)).then(() => {
            let respondentCaseBody = {
              summary: description,
              description: description,
              profile: respondent.id,
              dispute: response,
              type: RESPONDENT,
              lawyer_rv: respondentLawyers,
            };
            dispatch(createCase(respondentCaseBody)).then((res) => {
              let created_date = new Date(res.created);
              let cust_id = response + "/" + created_date.getFullYear();
              dispatch(
                updateDispute({
                  id: response,
                  rv_respondent_choose_step_complete: true,
                  approved: true,
                  approved_date: new Date(res.created),
                  tiac_custom_dispute_id: cust_id,
                })
              );
              setTimeout(() => {
                alert("dispute created");
              }, 500);
            });
          });
        });
      }
    } else {
      alert("Please select mandatory fields : Claimant and Respondent");
    }
  };

  if (
    claimantLoading ||
    respondentLoading ||
    claimantLawyerLoading ||
    respondentLawyerLoading ||
    arbitratorLoading
  ) {
    return <CircularCenteredLoader />;
  }

  return (
    <Grid container justify="center">
      <Grid item md={10} sm={11} xs={11}>
        <Box className={classes.box}>
          <Typography className={classes.createdispute}>
            Create Dispute
          </Typography>
        </Box>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="general_rv"
              multiple={false}
              heading="Claimant"
              setProfile={setClaimant}
              profile={claimant}
              profiles={profilesClaimant}
              setProfiles={setProfilesClaimant}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="general_rv"
              multiple={false}
              heading="Respondent"
              setProfile={setrespondent}
              profile={respondent}
              profiles={profilesRespondent}
              setProfiles={setProfilesRespondent}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="lawyer_rv"
              multiple={true}
              heading="Claimant Lawyer"
              setProfile={setClaimantLawyer}
              profile={claimantLawyer}
              profiles={profilesClaimantLawyer}
              setProfiles={setProfilesClaimantLawyer}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="lawyer_rv"
              multiple={true}
              heading="Respondent Lawyer"
              setProfile={setRespondentLawyer}
              profile={respondentLawyer}
              profiles={profilesRespondentLawyer}
              setProfiles={setProfilesRespondentLawyer}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="arbitrator_rv"
              multiple={true}
              heading="Arbitrator"
              setProfile={setArbitrator}
              profile={arbitrator}
              profiles={profilesArbitrator}
              setProfiles={setProfilesArbitrator}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Box height="2rem" />
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={() => {
            submit();
          }}
        >
          <Typography>Create</Typography>
        </Button>
        <Box height="5rem" />
      </Grid>
    </Grid>
  );
}
