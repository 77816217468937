import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EventIcon from "@material-ui/icons/Event";
import {
  CLAIMANT,
  GENERAL,
  GENERAL_RV,
  RESPONDENT,
  VENDOR,
  ADVOCATE,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import Dispute from "features/disputes/Dispute";
import DisputeNumberCard from "features/disputes/partials/DisputeNumberCard";
import { rvFilter } from "features/disputes/utils";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isCDR, isDev, isHotStar, isMuthoot, isStar, isVikalp } from "Routes/Routes";
import { getWindowDimension } from "utils/responsiveUtils";
import { useStyles } from "./styles";

const getName = (profile, user) => {
  return (
    (profile.profile_type === GENERAL && user.first_name) ||
    (profile.profile_type === ADVOCATE && user.first_name) ||
    (profile.profile_type === VENDOR && user.first_name) ||
    (profile.profile_type === GENERAL_RV && user.first_name)
  );
};

const Disputes = ({
  disputes,
  cases,
  profile,
  user,
  claimaintDisputes,
  respondentDisputes,
  isRV,
  count,
}) => {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();

  let {
    profile: { profile_type },
  } = profile;

  const onClick = (e) => {
    if (isRV) {
      history.push(`/intro-rv`);
    } else {
      history.push(`/intro`);
    }
  };
  const filteredDisputes = isRV ? rvFilter(disputes, cases) : disputes;
  let latestDispute;
  if (isHotStar || isStar || isMuthoot) {
    if (filteredDisputes[0]) {
      latestDispute = filteredDisputes.find(
        (dispute) => dispute.approved === true
      );
    }
  } else {
    latestDispute = filteredDisputes[0];
  }
  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  const isSmallScreen = () => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showGuide, setShowGuide] = useState(
    location.pathname == "/disputes" &&
      (user.id == 473 || user.id == 214) &&
      !isDev
  );

  const exit = (t) => {
    setShowGuide(false);
  };

  const steps = [
    {
      element: ".mainstep1",
      intro: "Hi! Welcome to your grievance redressal dashboard!",
      title: "Welcome",
    },
    {
      element: ".mainstep2",
      intro: "1. File a new grievance here",
    },
    {
      element: ".mainstep6",
      intro: "2. Schedule any meetings here",
    },
    {
      element: ".mainstep3",
      intro: "3. View details of your most active grievance",
    },
    {
      element: ".mainstep4",
      intro: "4. Other grievances filed by you",
    },
    {
      element: ".mainstep5",
      intro: "5. Need to check details of a closed grievance? Click here.",
      title: "Thank you!",
    },
  ];

  return (
    <>
      <Steps
        enabled={showGuide}
        steps={steps}
        initialStep={0}
        onExit={exit}
        options={{
          hideNext: false,
          hidePrev: true,
          keyboardNavigation: true,
          exitOnEsc: true,
          exitOnOverlayClick: false,
          showBullets: false,
          showStepNumbers: false,
          disableInteraction: true,
        }}
      />
      <Grid container spacing={3}>
        <Grid item md={1} xs={0}></Grid>
        <Grid item xs={12} md={10}>
          <Box className={classes.container}>
            <Grid container justify="space-between">
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h3" align="center" className="mainstep1">
                  <FormattedMessage id="common.hi" />,{" "}
                  {getName(profile.profile, user)}
                </Typography>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                {/* <div className={classes.buttonContainer}> */}
                <Box height="2rem"></Box>
                <Grid
                  container
                  justify="center"
                  spacing={isSmallScreen() ? 2 : 4}
                >
                  {!isCDR &&
                    !isVikalp &&
                    !((isHotStar || isStar || isMuthoot) && profile_type == "general") && (
                      // < Grid item sm xs={6} md={3}  >
                      <Button
                        style={{ margin: "1rem 0.6rem" }}
                        variant="outlined"
                        color="primary"
                        startIcon={<AssessmentIcon />}
                        onClick={() => {
                          history.push("/analytics");
                        }}
                      >
                        <FormattedMessage id="buttons.analytics" />
                      </Button>
                      // </Grid>
                    )}

                  {/* <Box width="0.8rem"></Box> */}

                  {/* < Grid item sm xs={6} md={3}> */}
                  <Button
                    style={{ margin: "1rem 0.6rem" }}
                    variant="outlined"
                    color="primary"
                    startIcon={<EventIcon />}
                    onClick={() => {
                      history.push("/events");
                    }}
                    className="mainstep6"
                  >
                    <FormattedMessage id="buttons.calendar" />
                  </Button>
                  {/* </Grid> */}
                  {/* <Box width="0.8rem"></Box> */}
                  {/* < Grid item sm xs={6} md={4} > */}
                  {(profile_type === GENERAL ||
                    (profile_type === VENDOR && isCDR) ||
                    isRV) && (
                    <Button
                      style={{ margin: "1rem 0.6rem" }}
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={onClick}
                      data-testid="create-new-dispute"
                      className="mainstep2"
                    >
                      <FormattedMessage id="buttons.createNewDispute" />
                    </Button>
                  )}
                  {/* </Grid> */}
                </Grid>
                {/* </div> */}
              </Grid>
            </Grid>

            {!isEmpty(latestDispute) && (
              <Dispute
                dispute={latestDispute}
                cases={cases}
                profile={profile.profile}
              />
            )}

            <Box height="1rem"></Box>

            <Box display="flex">
              {(() => {
                if (profile_type === GENERAL) {
                  return (
                    <>
                      <DisputeNumberCard
                        type={CLAIMANT}
                        disputesMap={count.claimant}
                      />
                      {(isCDR || isVikalp) && (
                        <>
                          <Box width="1rem" />
                          <DisputeNumberCard
                            type={RESPONDENT}
                            disputesMap={count.respondent}
                          />
                        </>
                      )}
                    </>
                  );
                } else if (profile_type === VENDOR) {
                  return (
                    <>
                      {(isCDR || isVikalp) && (
                        <>
                          <DisputeNumberCard
                            type={CLAIMANT}
                            disputesMap={count.claimant}
                          />
                          <Box width="1rem" />
                        </>
                      )}
                      <DisputeNumberCard
                        type={RESPONDENT}
                        disputesMap={count.respondent}
                      />
                    </>
                  );
                } else if (profile_type === ADVOCATE) {
                  return (
                    <>
                      {(isCDR || isVikalp) && (
                        <>
                          <DisputeNumberCard
                            type={CLAIMANT}
                            disputesMap={count.claimant}
                          />
                          <Box width="1rem" />
                        </>
                      )}
                      <DisputeNumberCard
                        type={RESPONDENT}
                        disputesMap={count.respondent}
                      />
                    </>
                  );
                }
              })()}
            </Box>
          </Box>
        </Grid>
        <Grid item md={1} xs={0}></Grid>
      </Grid>
    </>
  );
};
export default Disputes;
