import { Box, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import instance from "api/globalaxios";
import { format } from "date-fns";
import Tabs from "features/Tabs/Tabs";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { formatDate } from "utils";
import SendgridEmailUpdate from "./SendgridLogs/SendgridEmailUpdate";

const useStyles = makeStyles((theme) => ({
  emailLogHeading: {
    fontSize: "1.25rem",
    padding: '2rem 0 0',
  },
  tableHeader: {
    fontSize: '1.15rem',
    fontFamily: 'Satoshi-Bold',
  },
  tableRow: {
    padding:'8px', 
    borderBottom: '1px solid #E5E5E5',
    '&:hover' : {
      background: '#f7f7f7',
    }
  },
  activityTime: {
    color: "#5A5A5A",
    lineHeight: "1.5rem",
  },
  red: {
    color: '#F2994A',
  },
  green: {
    color: '#27AE60',
  },
  blue: {
    color: '#4aa6f2'
  }
}));

export default function DetailsAndUpdates({dispute, allAssignedProfiles}){
  const classes = useStyles();
	const [currentTab, setCurrentTab] = useState("emailLogs");
	// const [emailLogs, setEmailLogs] = useState({
  //   "dispute_id": "92faa5a4-e169-4567-acc1-fb160b595f35",
  //   "email_logs": [
  //     {
  //       "email": "sakshi.pandey@webnyay.ai",
  //       "entries": [
  //         {
  //             "x_message_id": "4bJwBmTzR0KELoFb5SacTQ",
  //             "subject": "Upload Relevant Documents | Webnyay ODR",
  //             "events": [
  //                 {
  //                     "event": "processed",
  //                     "timestamp": "2024-03-04T12:17:58Z",
  //                     "reason": null
  //                 },
  //                 {
  //                     "event": "delivered",
  //                     "timestamp": "2024-03-04T12:18:02Z",
  //                     "reason": null
  //                 }
  //             ]
  //         },
  //         {
  //             "x_message_id": "BDs4aNB3TjSvJX3zJBAGAg",
  //             "subject": "Upload Relevant Documents | Webnyay ODR",
  //             "events": [
  //                 {
  //                     "event": "delivered",
  //                     "timestamp": "2024-03-04T12:43:02Z",
  //                     "reason": null
  //                 },
  //                 {
  //                     "event": "processed",
  //                     "timestamp": "2024-03-04T12:42:57Z",
  //                     "reason": null
  //                 }
  //             ]
  //         },
  //         {
  //             "x_message_id": "KpIcUZwoQda4VBApqFtHEA",
  //             "subject": "Upload Relevant Documents | Webnyay ODR",
  //             "events": [
  //                 {
  //                     "event": "open",
  //                     "timestamp": "2024-03-06T09:47:29Z",
  //                     "reason": null
  //                 },
  //                 {
  //                     "event": "open",
  //                     "timestamp": "2024-03-06T09:32:29Z",
  //                     "reason": null
  //                 },
  //                 {
  //                     "event": "delivered",
  //                     "timestamp": "2024-03-06T07:53:03Z",
  //                     "reason": null
  //                 },
  //                 {
  //                     "event": "processed",
  //                     "timestamp": "2024-03-06T07:52:58Z",
  //                     "reason": null
  //                 }
  //             ]
  //         },
  //         {
  //             "x_message_id": "r7fFB9MqRhCU88yICn43gg",
  //             "subject": "Upload Relevant Documents | Webnyay ODR",
  //             "events": [
  //                 {
  //                     "event": "delivered",
  //                     "timestamp": "2024-03-06T11:12:38Z",
  //                     "reason": null
  //                 },
  //                 {
  //                     "event": "open",
  //                     "timestamp": "2024-03-06T11:12:49Z",
  //                     "reason": null
  //                 },
  //                 {
  //                     "event": "processed",
  //                     "timestamp": "2024-03-06T11:12:33Z",
  //                     "reason": null
  //                 }
  //             ]
  //         }
  //       ]
  //     },
  //   ]
  // });
	const [emailLogs, setEmailLogs] = useState({});
  const [loading, setLoading] = useState(false);
  const allProfileEmails = allAssignedProfiles.map((item)=>{
		return item?.user?.email;
	})

	useEffect(()=>{
		getEmailLogs();
	},[])

	const updatesCategories = [
    {
      id: "emailLogs",
      label: "Email",
    },
    {
      id: "others",
      label: "Others",
    },
  ];

	const handleTabChange = (newValue) => {
    setCurrentTab(newValue);
  };

	const getEmailLogs = async () => {
		try {
      setLoading(true);
      let data = new FormData();
      data = {
        dispute_id: dispute?.id,
        emails: allProfileEmails,
      }
			const response = await instance.post(
				`/api/sendgrid-event/`,
        data,
			);
			setEmailLogs(response.data);
			return response.data;
		} catch (error) {
			console.log(error);
		} finally {
      setLoading(false);
    }
	}

	const tabContent = (selectedTab) => {
		switch(selectedTab){
			case 'emailLogs' : {
				return (
					<>
						<Typography className={classes.emailLogHeading}>Email logs</Typography>
            {loading ? (
              <CircularCenteredLoader/>
            ) : (
              <Box>
                <Box style={{ padding: "1rem" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={3} xs={3}>
                      <Typography className={classes.tableHeader} style={{padding:'8px'}}>
                        Email Id
                      </Typography>
                    </Grid>
                    <Grid item container sm={9} xs={9}>
                      <Grid container spacing={2} style={{padding:'8px'}}>
                        <Grid item sm={6} xs={6}>
                          <Typography className={classes.tableHeader}>
                            Subject
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.tableHeader}>
                            Mail Delivery
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.tableHeader}>
                            Status
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Paper container style={{ border: '1px solid #E5E5E5', maxHeight: '60vh', overflow: 'auto' }}>
                  {emailLogs?.email_logs?.map((element) => {
                    return (
                      <Box className={classes.tableRow} key={element?.email}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                        >
                          <Grid item sm={3} xs={3}>
                            <Typography
                              style={{ cursor: "pointer" }}
                            >
                              {element?.email}
                            </Typography>
                          </Grid>
                          <Grid item sm={9} xs={9} style={{padding:0}}>
                            {element?.entries?.map((entry,index)=>{
                              return (
                                <SendgridEmailUpdate 
                                  entry={entry}
                                  index={index}
                                  classes={classes}
                                />
                              )
                            })}
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Paper>
              </Box>
            )}
						
					</>
				)
			}
			case 'others' : {
				return (
					<>
						<Typography
							style={{
								fontSize: "16px",
								color: "#828282",
								"line-height": "28px",
								textAlign: "left",
								paddingTop: " 1.3rem",
							}}
						>
							Claimant Status :
							<br />
							{dispute.rv_arbitrator_step_complete
								? "Arbitrator choose step : Complete"
								: "Arbitrator choose step : Incomplete"}
							<br />
							{dispute.rv_claimant_lawyer_step_complete
								? "Counsel choose step : Complete"
								: "Counsel choose step : Incomplete"}
							<br />
							{dispute.rv_respondent_choose_step_complete
								? "Respondent choose step : Complete"
								: "Respondent choose step : Incomplete"}
							<br />
						</Typography>
						<Typography
							style={{
								fontSize: "16px",
								color: "#828282",
								"line-height": "28px",
								textAlign: "left",
								paddingTop: " 1.3rem",
							}}
						>
							Respondent Status :
							<br />
							{dispute.rv_respondent_lawyer_step_complete
								? "Counsel choose step : Complete"
								: "Counsel choose step : Incomplete"}
						</Typography>
					</>
				)
			}
			default : {
				return <h1>Wrong choice!</h1>
			}
		}
	}

  return (
		<>
			<Box style={{ padding: "1rem 1.3rem" }}>
				<Tabs
					tabs={updatesCategories}
					choice={currentTab}
					handleClickTab={handleTabChange}
				/>
				{tabContent(currentTab)}
			</Box>
		</>
	)
}