import DateFnsUtils from "@date-io/date-fns";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  InputAdornment,
  Link,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Alert } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, subYears } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { isRv } from "Routes/Routes";
import { logout, sendOtpAsync, setTokenAsync } from "slices/loginSlice";
import { register } from "slices/userSlice";
import * as Yup from "yup";
import "../signup/otp.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.webnyay.in/">
        Webnyay.in
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

var signUpData = {};
var popUpBox = false;
var otpServerResponse = "";
var t1 = null;
var t2 = null;
var timerRunning = false;
var temp = 0;

const OtpButton = () => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const closeModal = () => {
    setOpen(false);
    temp = 0;
  };
  let history = useHistory();

  const otpSubmit = async () => {
    if (otpServerResponse && otp) {
      let email = signUpData.email;
      let phoneNumber = signUpData.phoneNumber;
      let password = signUpData.password;
      let birthDate = signUpData.birthDate;
      let gender = signUpData.gender;
      let firstName = signUpData.firstName;
      let lastName = signUpData.lastName;

      let profile_type = isRv ? "general_rv" : "general";

      function handleSignupResponse(signUpResponse) {
        if (signUpResponse.id) {
        } else {
          if (signUpResponse.user && signUpResponse.phone_number) {
            alert(
              signUpResponse.user.email[0] +
                "\n and \n" +
                signUpResponse.phone_number[0]
            );
            setOpen(false);
          } else if (signUpResponse.user) {
            alert(signUpResponse.user.email[0]);
            setOpen(false);
          } else if (signUpResponse.phone_number) {
            alert(signUpResponse.phone_number[0]);
            setOpen(false);
          } else if (signUpResponse.data.error) {
            alert(signUpResponse.data.error);
          } else {
            setOpen(false);
          }
        }
      }

      let signUpResponse = await dispatch(
        register({
          email,
          phoneNumber,
          profile_type,
          password,
          birthDate: format(birthDate, "yyyy-MM-dd"),
          gender,
          firstName,
          lastName,
          otp,
        })
      );
      handleSignupResponse(signUpResponse);
      await dispatch(setTokenAsync(signUpData.email, signUpData.password));
      history.push("/disputes");
      popUpBox = false;
    } else {
      alert("empty otp!!");
    }
  };

  class Timer extends React.Component {
    state = {
      minutes: 4,
      seconds: 59,
    };

    constructor(props) {
      super(props);
      this.sendOTPAgain = this.sendOTPAgain.bind(this);
      t2 = new Date();
      var dif = t2 - t1;
      var Seconds_from_T1_to_T2 = parseInt(dif / 1000);
      this.state.minutes =
        this.state.minutes - parseInt(Seconds_from_T1_to_T2 / 60);
      this.state.seconds =
        this.state.seconds - parseInt(Seconds_from_T1_to_T2 % 60);

      if (this.state.minutes < 0 || this.state.seconds < 0) {
        this.state.minutes = 0;
        this.state.seconds = 0;
      }
    }
    componentDidMount() {
      this.myInterval = setInterval(() => {
        const { minutes, seconds } = this.state;
        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1,
          }));
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(this.myInterval);
            timerRunning = false;
            if (temp == 0) {
              this.componentDidMount();
              temp++;
              setOtp("0");
              setOtp(otp);
            }
          } else {
            this.setState(({ minutes }) => ({
              minutes: minutes - 1,
              seconds: 59,
            }));
          }
        }
      }, 1000);
    }
    componentWillUnmount() {
      clearInterval(this.myInterval);
    }

    sendOTPAgain = async () => {
      let email = signUpData.email;
      let firstName = signUpData.firstName;
      let phoneNumber = signUpData.phoneNumber;
      otpServerResponse = await dispatch(
        sendOtpAsync(email, firstName, phoneNumber)
      );

      t1 = new Date();
      this.state.minutes = 4;
      this.state.seconds = 59;
      timerRunning = true;

      this.componentDidMount();
      temp = 0;
      setOtp("");
    };

    render() {
      const { minutes, seconds } = this.state;
      return (
        <div className="timer" style={{ color: "red" }}>
          {minutes === 0 && seconds === 0 ? (
            <br />
          ) : (
            <p>
              Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </p>
          )}
          <div
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              style={{
                width: "60%",
                maxWidth: "30rem",
                height: "2.7rem",
                right: "0.3rem",
              }}
              className="proceedButton"
              variant="contained"
              color="primary"
              onClick={otpSubmit}
            >
              {"Signup"}
            </Button>
            <Button
              style={{
                width: "40%",
                maxWidth: "25rem",
                height: "2.7rem",
                left: "0.3rem",
              }}
              variant="contained"
              color="primary"
              onClick={this.sendOTPAgain}
              disabled={timerRunning}
            >
              {"Resend OTP"}
            </Button>
          </div>
        </div>
      );
    }
  }

  const closeModal2 = () => {
    popUpBox = false;
    setOtp("");
    temp = 0;
  };

  return (
    <div>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        {"OTP"}
      </Button>
      <Popup
        open={popUpBox && open}
        // trigger={}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal2}
        className="my-popup"
        position="center"
        modal
        nested
        contentStyle={{ textAlign: "center", background: "white" }}
        overlayStyle={{ background: "" }}
      >
        <div className="modal">
          <button
            type="button"
            className="close"
            onClick={closeModal}
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 className="font-related">
            One Time Password (OTP) has been sent to your registered Email ID
            and Phone Number
            <TextField
              className="textf"
              variant="filled"
              margin="normal"
              value={otp}
              required
              fullWidth
              autoFocus
              placeholder="One Time Password (OTP)*"
              type="number"
              onChange={(e) => setOtp(e.target.value)}
            />
            <Timer></Timer>
            <br />
          </h4>
        </div>
      </Popup>
    </div>
  );
};

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
  phoneNumber: Yup.string()
    .required("phone number is required")
    .min(10, "Invalid phone number")
    .max(10, "Invalid phone number"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    // .min(6, "Password Too Short!")
    // .max(20, "Password Too Long!")
    .min(6, "Password Too Short! Minimum Length - 6")
    .max(15, "Password Too Long! Maximum Length - 15")
    .matches(/^(.*[A-Z].*)/, "Must Contain an Upper Case Alphabet")
    .matches(/^(.*[a-z].*)/, "Must Contain a Lower Case Alphabet")
    .matches(/^(.*[0-9].*)/, "Must Contain a number")
    .matches(/^(.*\W.*)/, "Must Contain a special character")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
  gender: Yup.string()
    .oneOf(["male", "female"], "")
    .required("Please select your gender"),
  birthDate: Yup.date()
    .required("birthdate is required")
    .max(subYears(new Date(), 18), "You should be 18 years or older"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default () => {
  const userErrors = useSelector((state) => state.user.errors);
  const hasErrors = useSelector((state) => state.user.hasErrors);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  function handleSignupResponse(signUpResponse) {
    if (signUpResponse.id) {
    } else {
      if (signUpResponse.user && signUpResponse.phone_number) {
        alert(
          signUpResponse.user.email[0] +
            "\n and \n" +
            signUpResponse.phone_number[0]
        );
      } else if (signUpResponse.user) {
        alert(signUpResponse.user.email[0]);
      } else if (signUpResponse.phone_number) {
        alert(signUpResponse.phone_number[0]);
      } else if (signUpResponse.data.error) {
        alert(signUpResponse.data.error);
      } else {
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      gender: "male",
      birthDate: Date("yyyy-MM-dd"),
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      let {
        email,
        phoneNumber,
        password,
        birthDate,
        gender,
        firstName,
        lastName,
      } = values;

      signUpData = values;

      if (!isEmpty(signUpData)) {
        otpServerResponse = await dispatch(
          sendOtpAsync(email, firstName, phoneNumber)
        );
        if (!otpServerResponse?.success) {
          handleSignupResponse(otpServerResponse);
        } else {
          popUpBox = true;
          t1 = new Date();
          timerRunning = true;
        }
      }
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <FormattedMessage id="auth.signup" />
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label={<FormattedMessage id="user.email" />}
            name="email"
            autoComplete="email"
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.email && formik.touched.email && (
            <Alert severity="error">{formik.errors.email}</Alert>
          )}
          {Object.keys(formik.errors).length == 0 &&
            hasErrors &&
            userErrors.email[0] &&
            formik.touched.email && (
              <Alert severity="error">{userErrors.email[0]}</Alert>
            )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="phoneNumber"
            label={<FormattedMessage id="user.phoneNumber" />}
            type="number"
            id="phoneNumber"
            value={formik.values.phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.phoneNumber && formik.touched.phoneNumber && (
            <Alert severity="error">{formik.errors.phoneNumber}</Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            value={formik.firstName}
            required
            fullWidth
            id="firstName"
            label={<FormattedMessage id="user.firstName" />}
            name="firstName"
            autoComplete="firstName"
            // autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <Alert severity="error">{formik.errors.firstName}</Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            value={formik.lastName}
            required
            fullWidth
            id="lastName"
            label={<FormattedMessage id="user.lastName" />}
            name="lastName"
            autoComplete="lastName"
            // autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <Alert severity="error">{formik.errors.lastName}</Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={<FormattedMessage id="auth.password" />}
            type={passwordType}
            id="password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label={<FormattedMessage id="auth.confirmPassword" />}
            type={passwordType}
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <Alert severity="error">{formik.errors.confirmPassword}</Alert>
          )}

          {/* <RadioGroup
            aria-label="gender"
            id="gender"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
          >
            <Typography variant="subtitle1">Gender</Typography>
            <Box display="flex">
              <FormControlLabel
                value="female"
                control={<Radio color="primary" />}
                label="Female"
              />
              <Box width="2rem"></Box>
              <FormControlLabel
                value="male"
                control={<Radio color="primary" />}
                label="Male"
              />
            </Box>
          </RadioGroup>
          {formik.errors.gender && formik.touched.gender && (
            <Alert severity="error">{formik.errors.gender}</Alert>
          )} */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="birthDate"
              name="birthDate"
              label={<FormattedMessage id="user.birthdate" />}
              format="dd/MM/yyyy"
              data-test="birthDate"
              value={formik.values.birthDate}
              onChange={(value) => {
                const event = {
                  persist: () => {},
                  target: {
                    type: "change",
                    name: "birthDate",
                    value,
                  },
                };
                formik.handleChange(event);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          {formik.errors.birthDate && (
            <Alert severity="error">{formik.errors.birthDate}</Alert>
          )}
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {<FormattedMessage id="auth.signup" />}
          </Button> */}

          <br />
          <Box height="2rem"></Box>
          <OtpButton></OtpButton>
        </form>
      </div>
      <Box mt={8}>{/* <Copyright /> */}</Box>
      <Box height="2rem"></Box>
    </Container>
  );
};
