import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import EditIcon from "@material-ui/icons/Edit";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import instance from "api/globalaxios";
import FormattedMessage from "features/common/TranslatedMessage";
import { DropzoneDialog } from "material-ui-dropzone";
import React from "react";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { updateDispute, uploadDispute } from "slices/disputeSlice";
import { formatDate } from "utils";
import { gethost } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    padding: "2rem 0",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  paper: {
    textAlign: "center",
    padding: "3rem 1rem",
  },
  heading: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#000",
  },
  iconContainer: {
    background: "rgba(112, 124, 151, 0.15)",
    display: "inline-block",
    borderRadius: "0.3rem",
  },
  icon: {
    color: "rgba(112, 124, 151, 0.7)",
    fontSize: "6rem",
  },
}));

export default function ArbitratorUpload({
  dispute: initialDispute,
  award_type,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const [dispute, setDispute] = React.useState(initialDispute);

  const onClickProceed = () => {
    dispatch(uploadDispute(files, dispute)).then((resp) =>
      dispatch(
        updateDispute({ id: dispute.id, arbitrator_status: "webnyay_upload" })
      )
    );
  };

  const shouldPreview = files.length > 0;

  const [decisionFiles, setdecisionFiles] = React.useState(dispute.all_docs);

  const reload = () => {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/dispute/${dispute.id}` + "?host=" + gethost()
        );
        setDispute(response.data);
        setdecisionFiles(response.data.all_docs);
      } catch (error) {
        console.log(error);
      }
    };
  };

  const [open2, setOpen2] = React.useState(false);
  const [fileShare, setfileShare] = React.useState(null);

  const openpopup2 = (file) => {
    setOpen2(true);
    setfileShare(file);
  };
  const closeModal2 = () => {
    setOpen2(false);
  };

  const shareDecision = (file) => {
    return async (dispatch) => {
      try {
        const response = await instance.put(
          "/finaldecession/" + file.id + "/",
          { dispute_id: dispute.id },
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
  };

  // const handleShareClick = (e, file) => {
  //   e.stopPropagation();
  //   dispatch(shareDecision(file)).then((response) => {
  //     dispatch(reload());
  //   });
  //   setOpen2(false);
  // };

  const handleShareClick = () => {
    // e.stopPropagation();
    dispatch(shareDecision(fileShare)).then((response) => {
      dispatch(reload());
    });
    setOpen2(false);
  };

  return (
    <Box className={classes.container}>
      {decisionFiles.length > 0 &&
        decisionFiles.map((file) => {
          return (
            <React.Fragment>
              <Box
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   if (file.s3path) downloadLegalDoc(file.id);
                // }}
              >
                <Paper style={{ padding: "1rem 1.5rem", fontSize: "16px" }}>
                  <Grid container spacing={1}>
                    <Grid item sm={8} xs={8}>
                      <Typography
                        style={{ color: "#333333", textAlign: "left" }}
                      >
                        {file.name}
                      </Typography>
                    </Grid>
                    <Grid item sm={3} xs={3}>
                      <Typography
                        style={{ color: "#828282", textAlign: "left" }}
                      >
                        {formatDate(file.created)}
                      </Typography>
                    </Grid>
                    <Grid item sm={1} xs={1}>
                      {file.is_final ? (
                        <Typography
                          style={{
                            color: "rgb(33 155 17)",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          <u>Shared</u>
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            color: "#2F80ED",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                          // onClick={(event) => handleShareClick(event, file)}
                          onClick={() => {
                            openpopup2(file);
                          }}
                        >
                          <u>Share</u>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
                <Box height="1rem"></Box>
              </Box>
              <Popup
                open={open2}
                closeOnDocumentClick
                closeOnEscape
                onClose={closeModal2}
                position="center"
                modal
                nested
                contentStyle={{
                  background: "white",
                  width: "330px",
                  height: "185px",
                }}
                overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
              >
                <Box style={{ padding: "1rem 1.3rem" }}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      color: "#333333",
                      "font-weight": "bold",
                      "line-height": "23px",
                      "letter-spacing": "0.0015em",
                    }}
                  >
                    Are you sure?
                  </Typography>
                  <Box height="0.5rem"></Box>
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "#828282",
                      "line-height": "28px",
                    }}
                  >
                    Are you sure you want to share this decision with the other
                    parties?
                  </Typography>
                  <Box height="0.7rem"></Box>
                </Box>
                <Box style={{ padding: "1rem 1.3rem" }}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#828282",
                          "line-height": "16px",
                          "letter-spacing": "0.02em",
                          "font-weight": "500",
                          cursor: "pointer",
                        }}
                        onClick={closeModal2}
                      >
                        CANCEL
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#2F80ED",
                          "line-height": "16px",
                          "letter-spacing": "0.02em",
                          "font-weight": "500",
                          cursor: "pointer",
                        }}
                        // onClick={closeGrievence}
                        onClick={handleShareClick}
                      >
                        SHARE
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Popup>
            </React.Fragment>
          );
        })}
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.heading}>
          {award_type}
        </Typography>
        <Box height="1rem"></Box>
        <Box className={classes.iconContainer}>
          <IconButton
            disabled={shouldPreview}
            aria-label="delete"
            onClick={() => {
              !shouldPreview && setOpen(true);
            }}
          >
            {shouldPreview ? (
              <DescriptionOutlinedIcon className={classes.icon} />
            ) : (
              <PostAddOutlinedIcon className={classes.icon} />
            )}
          </IconButton>
        </Box>
        <Box height="1rem"></Box>
        {shouldPreview &&
          files.map((file) => {
            return (
              <Box>
                <p>{file.name}</p>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <EditIcon style={{ fontSize: "2.5rem" }} />
                </IconButton>
              </Box>
            );
          })}
      </Paper>
      <Box height="1.5rem"></Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onClickProceed}
        disabled={files.length < 1}
      >
        <FormattedMessage id="buttons.submit" />
      </Button>
      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={async (files) => {
          setFiles(files);

          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/msword",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />
    </Box>
  );
}
