import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Alert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import { DropzoneDialog } from "material-ui-dropzone";
import mime from "mime-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRealhost } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },

  text: {
    // color: "#2C3345",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    // color: "#2C3345",
    padding: "1rem",
    // fontFamily: `'Inter',sans-serif`,
    [theme.breakpoints.down("sm")]: {
      textalign: "justify",
    },
  },

  text2: {
    "font-size": "18px",
    // fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  icon: {
    fontSize: "3rem",
    color: "#E0E0E0",
  },
  paper: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  submit: {
    color: "#FFFFFF",
  },

  btn: {
    flexGrow: 1,
    background: "#3F51B5",
    border: "1px solid #3F51B5",
    borderRadius: "4px",
    color: "#FFFFFF",
    height: "80%",
    textAlign: "left",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },

  radioHeading: {
    fontSize: "18px",
    color: "#2C3345",
    // fontFamily: `'Inter',sans-serif`,
    lineHeight: "40px",
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

const UploadDialog = ({ open, setOpen, addFile }) => {
  const classes = useStyles();
  return (
    <Box textAlign="center" style={{ padding: "0 1rem" }}>
      <Paper
        className={classes.paper}
        style={{ padding: "3rem", maxWidth: "100%" }}
      >
        <Box>
          <IconButton
            className={classes.icon}
            aria-label="delete"
            // color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddCircleOutlineIcon className={classes.icon} />
          </IconButton>
        </Box>
        <Typography>Upload document</Typography>
      </Paper>

      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          files.forEach((file) => {
            addFile(file);
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
        //  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/msword",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />
    </Box>
  );
};

const renderFiles = (files, deleteFile) => {
  return files.length !== 0
    ? files.map((file, index) => {
        return (
          <Box key={index} textAlign="center" style={{ padding: "0 1rem" }}>
            <Paper style={{ padding: "2rem", maxWidth: "100%" }}>
              <Typography
                variant="subtitle2"
                style={{ wordBreak: "break-all" }}
              >
                {file.name}
              </Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Paper>
          </Box>
        );
      })
    : null;
};

async function getDisputeTiac(email, dispute_id) {
  return await instance.get(
    `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
  );
}
export async function getCaseFile(email, disputeId) {
  return await instance({
    method: "get",
    url: "/api/tiaccasefileanonymous/",
    params: {
      email: email,
      id: disputeId,
    },
  });
}
export async function getQuestionsTiac(email) {
  return await instance({
    method: "get",
    url: "/tiacanonymousquestions",
    params: {
      email: email,
      group: getRealhost()
    },
  });
}

export async function updateDisputeTiac(disputePayload, email) {
  return await instance({
    method: "patch",
    url: `/api/tiacdisputeanonymous/${disputePayload.id}/?id=${disputePayload.id}&email=${email}`,
    data: disputePayload,
  });
}

const UploadPage = ({ setActiveStep, submitDetailsSignal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const [ref, setRef] = useState(1);
  let id = localStorage.getItem("dispute_id");
  let email = localStorage.getItem("email");
  const [dispute, setDispute] = useState();
  const [casefile, setCasefile] = useState();

  React.useEffect(() => {
    getDisputeTiac(email, id)
      .then((res) => {
        if (res.data.results[0].id) {
          setDispute(res.data.results[0]);
          res.data.results[0].all_docs.map((item) => {
            if (item.is_rfa_rv) {
              if (item.award_type === "submission_report") {
                setQuestionFileUploaded1(true);
                setAnswer1(item);
                setbuttonvalue1("SAVED");
              } else if (item.award_type === "arbitration_petition") {
                setQuestionFileUploaded2(true);
                setAnswer2(item);
                setbuttonvalue2("SAVED");
              } else if (item.award_type === "msme_certificate") {
                setQuestionFileUploaded3(true);
                setAnswer3(item);
                setbuttonvalue3("SAVED");
              }
            }
          });
        } else history.push("/RFA");
      })
      .catch((e) => {
        history.push("/RFA");
      });
  }, [ref]);

  React.useEffect(() => {
    getCaseFile(email, id)
      .then((res) => {
        setCasefile(res.data.results[0]);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, []);

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const [files1, setFiles1] = React.useState([]);
  const [files2, setFiles2] = React.useState([]);
  const [files3, setFiles3] = React.useState([]);

  const [QuestionFileUploaded1, setQuestionFileUploaded1] = useState(false);
  const [QuestionFileUploaded2, setQuestionFileUploaded2] = useState(false);
  const [QuestionFileUploaded3, setQuestionFileUploaded3] = useState(false);

  const [answer1, setAnswer1] = useState();
  const [answer2, setAnswer2] = useState();
  const [answer3, setAnswer3] = useState();

  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);

  const [buttonvalue1, setbuttonvalue1] = React.useState("SAVE");
  const [buttonvalue2, setbuttonvalue2] = React.useState("SAVE");
  const [buttonvalue3, setbuttonvalue3] = React.useState("SAVE");

  const [RadioError, setRadioError] = useState();
  const [Error1, setError1] = useState();
  const [Error2, setError2] = useState();
  const [Error3, setError3] = useState();

  const addFile1 = (file) => {
    files1.push(file);
    setFiles1(files1);
  };
  const addFile2 = (file) => {
    files2.push(file);
    setFiles2(files2);
  };
  const addFile3 = (file) => {
    files3.push(file);
    setFiles3(files3);
  };

  const deleteFile1 = (index) => {
    let localFiles = [...files1];
    localFiles.splice(index, 1);
    setFiles1(localFiles);
  };
  const deleteFile2 = (index) => {
    let localFiles = [...files2];
    localFiles.splice(index, 1);
    setFiles2(localFiles);
  };
  const deleteFile3 = (index) => {
    let localFiles = [...files3];
    localFiles.splice(index, 1);
    setFiles3(localFiles);
  };

  async function uploadAndUpdateCase(files, award_type) {
    let id = localStorage.getItem("dispute_id");
    const data = new FormData();
    var file = files[0];
    data.append(file.name, file);
    const fileBody = {
      dispute_id: id,
      path: 123,
      file,
      // profile: profile.id,
      type: mime.lookup(file.name),
      name: file.name,
      size: file.size,
      is_attachment: true,
    };
    const data2 = new FormData();
    Object.keys(fileBody).forEach((key) => {
      data2.append(key, fileBody[key]);
    });
    return await instance({
      method: "post",
      url:
        "/tiacupload/" +
        id +
        "/?award_type=" +
        award_type +
        "&email=" +
        email +
        "&group=" +
        getRealhost(),
      data: data2,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  async function deleteUpload(docid, disputeid, email) {
    return await instance({
      method: "delete",
      url: "/tiacupload/" + disputeid + "/?email=" + email + "&id=" + docid,
    });
  }
  const formik = useFormik({
    initialValues: {
      firstans: "",
      secondans: "",
      thirdans: "",
    },
    onSubmit: async (values) => {
      if (!QuestionFileUploaded2 || !QuestionFileUploaded1) {
        if (!QuestionFileUploaded1) {
          setError1("Required Field");
        }
        if (!QuestionFileUploaded2) {
          setError2("Required Field");
        }
      }
      updateDisputeTiac(
        {
          id: id,
          tiac_dispute_step:
            "additional_info_complete",
        },
        email
      ).then(()=>{
        history.push("/tiac_waitingscreen");
      })
    },
  });

  const onSave = (files, questionUpload) => {
    let award_type = "";
    switch (questionUpload) {
      case 1:
        setLoading1(true);
        award_type = "submission_report";
        break;
      case 2:
        setLoading2(true);
        award_type = "arbitration_petition";
        break;
      case 3:
        setLoading3(true);
        award_type = "msme_certificate";
        break;
      default:
        break;
    }
    uploadAndUpdateCase(files, award_type).then((response) => {
      switch (questionUpload) {
        case 1:
          setLoading1(false);
          if (files.length !== 0) {
            setbuttonvalue1("SAVED");
            setQuestionFileUploaded1(true);
            setError1(null);
            setFiles1([]);
          }
          break;
        case 2:
          setLoading2(false);
          if (files.length !== 0) {
            setbuttonvalue2("SAVED");
            setQuestionFileUploaded2(true);
            setError2(null);
            setFiles2([]);
          }
          break;
        case 3:
          setLoading3(false);
          if (files.length !== 0) {
            setbuttonvalue3("SAVED");
            setQuestionFileUploaded3(true);
            setError3(null);
            setFiles3([]);
          }
          break;
        default:
          break;
      }
      setRef(Math.random());
    });
  };

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3} justify="center">
        {/* <Grid item xs></Grid> */}
        <Grid item xs={11}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" className={classes.text}>
                <FormattedMessage id="tiac.heading5_msme" />
              </Typography>
              <Box height="1rem" />
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} justify="flex-start">
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      5.1. Please attach the copy of the receipt of submission
                      from Samaadhan portal.{" "}
                      <span className={classes.required}>*</span>
                    </Typography>

                    {!QuestionFileUploaded1 && files1.length === 0 && (
                      <UploadDialog
                        open={open1}
                        setOpen={setOpen1}
                        addFile={addFile1}
                      />
                    )}
                    {answer1 && (
                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={7}>
                          <Typography
                            style={{ fontSize: "14px", wordBreak: "break-all" }}
                          >
                            {answer1.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            onClick={() => {
                              deleteUpload(answer1.id, id, email).then(
                                (res) => {
                                  setAnswer1();
                                  setQuestionFileUploaded1(false);
                                  setbuttonvalue1("SAVE");
                                  updateDisputeTiac(
                                    {
                                      id: id,
                                      tiac_dispute_step: null,
                                    },
                                    email
                                  );
                                }
                              );
                            }}
                          >
                            DELETE
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {!QuestionFileUploaded1 && renderFiles(files1, deleteFile1)}
                    {Error1 && (
                      <Box style={{ padding: "1rem" }}>
                        <Alert severity="error">{Error1}</Alert>
                      </Box>
                    )}
                    <Box height="1.5rem"></Box>

                    <Box style={{ textAlign: "center" }}>
                      <DebouncedButton
                        size="large"
                        variant={
                          buttonvalue1 == "SAVE" ? "outlined" : "contained"
                        }
                        color="primary"
                        onClickHandler={(e) => {
                          onSave(files1, 1);
                          e.preventDefault();

                          // onChange={validate}
                        }}
                        disabled={
                          loading1 ||
                          QuestionFileUploaded1 ||
                          files1.length == 0
                        }
                        loading={loading1}
                        value={buttonvalue1}
                        // onChange={validate}
                        debounceTime={1000}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      5.2. Arbitration Petition{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    {!QuestionFileUploaded2 && files2.length === 0 && (
                      <UploadDialog
                        open={open2}
                        setOpen={setOpen2}
                        addFile={addFile2}
                      />
                    )}
                    {answer2 && (
                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={7}>
                          <Typography
                            style={{ fontSize: "14px", wordBreak: "break-all" }}
                          >
                            {answer2.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            onClick={() => {
                              deleteUpload(answer2.id, id, email).then(
                                (res) => {
                                  setAnswer2();
                                  setQuestionFileUploaded2(false);
                                  setbuttonvalue2("SAVE");
                                  updateDisputeTiac(
                                    {
                                      id: id,
                                      tiac_dispute_step: null,
                                    },
                                    email
                                  );
                                }
                              );
                            }}
                          >
                            DELETE
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {!QuestionFileUploaded2 && renderFiles(files2, deleteFile2)}
                    {Error2 && (
                      <Box style={{ padding: "1rem" }}>
                        <Alert severity="error">{Error2}</Alert>
                      </Box>
                    )}
                    <Box height="1.5rem"></Box>

                    <Box style={{ textAlign: "center" }}>
                      <DebouncedButton
                        size="large"
                        variant={
                          buttonvalue2 == "SAVE" ? "outlined" : "contained"
                        }
                        color="primary"
                        onClickHandler={(e) => {
                          onSave(files2, 2);
                          e.preventDefault();
                        }}
                        disabled={
                          loading2 ||
                          QuestionFileUploaded2 ||
                          files2.length == 0
                        }
                        loading={loading2}
                        value={buttonvalue2}
                        debounceTime={1000}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      5.3. MSME Certificate of Applicant.
                    </Typography>

                    {!QuestionFileUploaded3 && files3.length === 0 && (
                      <UploadDialog
                        open={open3}
                        setOpen={setOpen3}
                        addFile={addFile3}
                      />
                    )}
                    {answer3 && (
                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={7}>
                          <Typography
                            style={{ fontSize: "14px", wordBreak: "break-all" }}
                          >
                            {answer3.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            onClick={() => {
                              deleteUpload(answer3.id, id, email).then(
                                (res) => {
                                  setAnswer3();
                                  setQuestionFileUploaded3(false);
                                  setbuttonvalue3("SAVE");
                                  updateDisputeTiac(
                                    {
                                      id: id,
                                      tiac_dispute_step: null,
                                    },
                                    email
                                  );
                                }
                              );
                            }}
                          >
                            DELETE
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {!QuestionFileUploaded3 && renderFiles(files3, deleteFile3)}
                    {Error3 && (
                      <Box style={{ padding: "1rem" }}>
                        <Alert severity="error">{Error3}</Alert>
                      </Box>
                    )}
                    <Box height="1.5rem"></Box>

                    <Box style={{ textAlign: "center" }}>
                      <DebouncedButton
                        size="large"
                        variant={
                          buttonvalue3 == "SAVE" ? "outlined" : "contained"
                        }
                        color="primary"
                        onClickHandler={(e) => {
                          onSave(files3, 3);
                          e.preventDefault();

                          // onChange={validate}
                        }}
                        disabled={
                          loading3 ||
                          QuestionFileUploaded3 ||
                          files3.length == 0
                        }
                        loading={loading3}
                        value={buttonvalue3}
                        // onChange={validate}
                        debounceTime={1000}
                      />
                    </Box>
                  </Grid>
                  <Box height="1.5rem"></Box>
                </Grid>
                <Box height="3rem"></Box>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ background: "#2F80ED" }}
                      >
                        <Typography className={classes.btnTextWhite}>
                          <FormattedMessage id="buttons.proceed" />
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs /> */}
      </Grid>
    </Box>
  );
};

export default UploadPage;
