import React from "react";
import Header from "./Header";
import Landing from "./Landing";
import Demo from "./Demo";
import Features from "./Features";
import WhatSetsUsApart from "./WhatSetsUsApart";
import Footer from "./Footer";

export default function RvHomePage() {
  return (
    <>
      <Header />
      <Landing />
      <Demo />
      <Features />
      <WhatSetsUsApart />
      <Footer />
    </>
  );
}
