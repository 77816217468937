import { isArbitrationConference, isDifc, isRv } from "Routes/Routes";

export const createGroupName = (groupName) => {
  // console.log("groupName......", groupName);
  const nameArray = groupName.split("_");
  let name = "";

  nameArray.slice(0, nameArray.length - 1).forEach((element) => {
    name = `${name} ${element}`;
  });
  if (name.includes("dev")) {
    name = name.replace("dev", "");
  }
  if (name.includes("arbitration")) {
    if (isDifc) return "Joint Meeting Room";
    if (isArbitrationConference) return "Discussion";
    return "Main Room";
  } else if (name.includes("ADRP-breakout")) {
    if (!isRv) return "SRO-breakout";
    if (isDifc) return "Judges-Breakout";
    if (isArbitrationConference) return "Announcements";
    return name;
  } else if (name.includes("claimant-breakout")) {
    if (isDifc) return "Claimant Private";
    return name;
  } else if (name.includes("claimant-mediation")) {
    if (isDifc) return "Claimant & Mediator";
    return name;
  } else if (name.includes("respondent-breakout")) {
    if (isDifc) return "Defendant Private";
    return name;
  } else if (name.includes("respondent-mediation")) {
    if (isDifc) return "Defendant & Mediator";
    return name;
  }

  return name;
};
