import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import instance from "api/globalaxios";
import MuiAlert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1.5rem 3rem",
    borderRadius: "8px",
    cursor: "pointer",
  },
  paper2: {
    padding: "1.5rem 3rem",
    borderRadius: "8px",
  },
  headingNew: {
    fontSize: "16px",
    lineHeight: "36px",
    display: "flex",
    alignItems: "center",
    texttransform: "capitalize",
    color: "#4F4F4F",
  },
  bigbtn: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#FFFFFF",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GenerateInvoice = ({ opened, profile, registeredDisputesList }) => {
  const classes = useStyles();

  const [expand, setExpand] = useState(opened);
  const [registeredDispute, setregisteredDispute] = useState("");
  const [party, setParty] = useState("");
  const [partyList, setPartyList] = useState([]);
  const [currency, setCurrency] = useState("");
  const [costAmount, setCostAmount] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedDispute, setSelectedDispute] = useState();

  const [openSnackBar1, setOpenSnackBar1] = React.useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };

  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const handleCloseSnackBar2 = () => {
    setOpenSnackBar2(false);
  };

  const [openSnackBar3, setOpenSnackBar3] = useState(false);
  const handleCloseSnackBar3 = () => {
    setOpenSnackBar3(false);
  };

  const [openSnackBar4, setOpenSnackBar4] = useState(false);
  const handleCloseSnackBar4 = () => {
    setOpenSnackBar4(false);
  };

  const currencyList = [
    {
      toShow: "Rs",
      toSend: "INR",
    },
  ];

  const onRegisterDisputeChange = (e) => {
    setregisteredDispute(e.target.value);
    setLoading(true);
    setParty("");
    setPartyList([]);
    const dispute = registeredDisputesList.find(
      (dispute) => dispute.tiac_custom_dispute_id === e.target.value
    );
    setSelectedDispute(dispute);
    const parties = [];
    getDisputeDetails(dispute.id)
      .then((res) => {
        res.data.dispute_data.claimant.forEach((profile) => {
          const name = `${profile.user.first_name} ${profile.user.last_name}`;
          const id = profile.user.id;
          parties.push({ name, id });
        });
        res.data.dispute_data.respondent.forEach((profile) => {
          const name = `${profile.user.first_name} ${profile.user.last_name}`;
          const id = profile.user.id;
          parties.push({ name, id });
        });
        setPartyList(parties);
        setLoading(false);
      })
      .catch(() => {
        setPartyList(parties);
        setLoading(false);
      });
  };
  const onPartyChange = (e) => {
    setParty(e.target.value);
  };
  const onCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };
  const onCostAmountChange = (e) => {
    setCostAmount(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const digitsAfterDecimalPoint = (number) => {
    number = parseInt(number);
    if (Number.isInteger(number)) {
      return 0;
    } else {
      return number.toString().split(".")[1].length;
    }
  };

  const onSubmit = () => {
    if (
      !registeredDispute ||
      !party ||
      !currency ||
      !costAmount ||
      costAmount.trim() == "" ||
      description.trim() == ""
    ) {
      setOpenSnackBar3(true);
    } else {
      if (digitsAfterDecimalPoint(costAmount) > 2) {
        setOpenSnackBar4(true);
      } else {
        const mii = selectedDispute?.description;
        const stage =
          selectedDispute?.flow_type === "mediation"
            ? "conciliation"
            : "arbitration";
        let data = {
          amount: costAmount,
          description: description,
          disputeId: registeredDispute,
          userid: party,
          stage: stage,
          mii: mii,
        };
        sendPaymentLinkSebi(data)
          .then((res) => {
            setregisteredDispute("");
            setParty("");
            setPartyList([]);
            setCurrency("");
            setCostAmount("");
            setDescription("");
            setOpenSnackBar2(true);
          })
          .catch((error) => {
            if (error.response.data.user && error.response.data.user.email) {
              alert(error.response.data.user.email[0]);
            }
            if (error.response.data.phone_number) {
              alert(error.response.data.phone_number[0]);
            }
          });
      }
    }
  };

  return (
    <div id="paymentFrm" className="container">
      <Grid container justify="center" direction="row">
        <Grid item md={7} sm={9} xs={11}>
          <Paper
            className={expand ? classes.paper2 : classes.paper}
            onClick={() => {
              setExpand(true);
            }}
          >
            <Grid container justify="flex-start">
              <Grid item>
                <Typography className={classes.headingNew}>
                  Generate Invoice
                </Typography>
              </Grid>
            </Grid>
            {expand && (
              <>
                <Box height="1rem"></Box>
                <Grid container spacing={3}>
                  <Grid item sm={12} xs={12}>
                    <FormControl required fullWidth={true} variant="outlined">
                      <InputLabel id="registerdisputes">
                        Select registered dispute
                      </InputLabel>
                      <Select
                        lableId="registerdisputes"
                        id="registered_disputes"
                        name="registered_disputes"
                        value={registeredDispute}
                        onChange={onRegisterDisputeChange}
                        label="Select registered dispute *"
                        IconComponent={() => (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            style={{ marginRight: "8px" }}
                          />
                        )}
                      >
                        {registeredDisputesList.map((dispute) => (
                          <MenuItem
                            key={dispute.id}
                            value={dispute.tiac_custom_dispute_id}
                          >
                            {`${
                              dispute.tiac_custom_dispute_id
                                ? dispute.tiac_custom_dispute_id
                                : ""
                            } - ${dispute.name}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <FormControl required fullWidth={true} variant="outlined">
                      <InputLabel id="party">Select Party</InputLabel>
                      <Select
                        id="party"
                        name="party"
                        value={party}
                        onChange={onPartyChange}
                        label="Select Party *"
                        IconComponent={() =>
                          loading ? (
                            <CircularProgress
                              style={{
                                marginRight: "12px",
                                display: "flex",
                                color: "#2F80ED",
                              }}
                              size="2rem"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              style={{ marginRight: "8px" }}
                            />
                          )
                        }
                      >
                        {partyList?.map((party, index) => (
                          <MenuItem key={index} value={party.id}>
                            {party.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <FormControl required fullWidth={true} variant="outlined">
                      <InputLabel id="currency">Select currency</InputLabel>
                      <Select
                        id="currency"
                        name="currency"
                        value={currency}
                        onChange={onCurrencyChange}
                        label="Select currency *"
                        IconComponent={() => (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            style={{ marginRight: "8px" }}
                          />
                        )}
                      >
                        {currencyList.map((cur, index) => (
                          <MenuItem key={index} value={cur.toSend}>
                            {cur.toShow}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <TextField
                      required
                      id="cost_amount"
                      name="cost_amount"
                      label="Enter cost amount"
                      variant="outlined"
                      fullWidth={true}
                      value={costAmount}
                      onChange={onCostAmountChange}
                      type="number"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <TextField
                      required
                      multiline={true}
                      rows={5}
                      id="description"
                      name="description"
                      label="Add Description"
                      variant="outlined"
                      fullWidth={true}
                      value={description}
                      onChange={onDescriptionChange}
                    />
                  </Grid>
                </Grid>
                <Box height="2rem"></Box>
                <Button
                  variant="contained"
                  style={{
                    background: "#2F80ED",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={onSubmit}
                >
                  Send
                </Button>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Problem in creating guest profile."
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Problem in creating guest profile.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar2}
        onClose={handleCloseSnackBar2}
        message="Invoice generated successfully."
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Invoice generated successfully.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar3}
        onClose={handleCloseSnackBar3}
        message="Please enter all the mandatory details."
      >
        <Alert onClose={handleCloseSnackBar1} severity="warning">
          Please enter all the mandatory details.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar4}
        onClose={handleCloseSnackBar4}
        message="Please enter cost amount upto 2 decimal points."
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter cost amount upto 2 decimal points.
        </Alert>
      </Snackbar>
    </div>
  );
};

async function sendPaymentLinkSebi(data) {
  return await instance.post("/surepay/send_payment_link/", data);
}

async function getDisputeDetails(id) {
  return await instance.get(`/api/tiacrvdisputedetailsbyid/${id}/`);
}

export default GenerateInvoice;
