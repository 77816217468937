import instance from "api/globalaxios";

export async function DownloadFile(file_id) {
  return await instance.get(`rvfileurl/${file_id}/`);
}

export async function getFile(file_id) {
  return await instance.get(`getfilebyid/${file_id}/`);
}

export async function deleteFile(file_id) {
  return await instance.delete(`filedeleteupdate/${file_id}/`);
}

export async function getFiles(dispute_id, parent_folder_id) {
  return await instance.get(
    `getfiles/${parent_folder_id == 0 ? null : parent_folder_id}/${dispute_id}/`
  );
}

export async function UploadFiles(
  dispute_id,
  parent_folder_id,
  file,
  viewPermissions,
  deletePermissions,
  alert = "off"
) {
  const data = new FormData();
  data.append("file", file);
  data.append("name", file.name);
  data.append("path", "sd");
  data.append("type", file.type == "" ? "text/plain" : file.type);
  data.append("size", file.size);
  data.append("dispute", dispute_id);
  viewPermissions.forEach((item) => data.append("view_permission", item));
  deletePermissions.forEach((item) => data.append("permission", item));
  if (parent_folder_id != 0) data.append("parent_folder", parent_folder_id);
  return await instance.post(`uploadfile/?alert=${alert}`, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export async function UpdateFile(file_id, data, alert = "off") {
  return await instance.put(
    `filedeleteupdate/${file_id}/?alert=${alert}`,
    data
  );
}

export async function getFolder(folder_id) {
  return await instance.get(`getfolderbyid/${folder_id}/`);
}

export async function deleteFolder(folder_id) {
  return await instance.delete(`folderdeleteupdate/${folder_id}/`);
}

export async function getFolders(dispute_id, parent_folder_id) {
  return await instance.get(
    `getfolders/${
      parent_folder_id == 0 ? null : parent_folder_id
    }/${dispute_id}/`
  );
}

export async function UploadFolders(
  dispute_id,
  folder_name,
  parent_folder_id,
  viewPermissions,
  editPermissions,
  alert = "off"
) {
  return await instance.post(`uploadfolder/?alert=${alert}`, {
    name: folder_name,
    dispute: dispute_id,
    parent_folder: parent_folder_id == 0 ? null : parent_folder_id,
    view_permission: viewPermissions,
    edit_permission: editPermissions,
  });
}

export async function getProfilesOfDispute(dispute_id) {
  return await instance.get(`api/tiacrvprofileedetailsbyid/${dispute_id}/`);
}

export async function UpdateFolder(folder_id, data, alert = "off") {
  console.log("alert in function", alert);
  return await instance.put(
    `folderdeleteupdate/${folder_id}/?alert=${alert}`,
    data
  );
}

export async function DownloadSelectedFiles(data) {
  return await instance.post(`selectedfilesurl/`,data);
}