import {
  Box,
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import instance from "api/globalaxios";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { isDev } from "Routes/Routes";
import * as Yup from "yup";
import { StyledTextFieldTiac } from "../ClaimantPage/questions";
import { getRealhost } from "slices/disputeSlice";

const Schema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password Too Short!")
    .max(20, "Password Too Long!")
    .min(6, "Password Too Short! Minimum Length - 6")
    .max(15, "Password Too Long! Maximum Length - 15")
    .matches(/^(.*[A-Z].*)/, "Must Contain an Upper Case Alphabet")
    .matches(/^(.*[a-z].*)/, "Must Contain a Lower Case Alphabet")
    .matches(/^(.*[0-9].*)/, "Must Contain a number")
    .matches(/^(.*\W.*)/, "Must Contain a special character")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
});

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#454545",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

export function setPasswordApi(email, password, profileType) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "tiacsetpassword/",
        data: {
          email: email,
          password: password,
          profile_type: profileType,
          isDev: isDev,
          group : getRealhost()
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
    }
  };
}

const SetPassword = ({
  checkSaveMyProgressAndResumeLater,
  email,
  profileType,
  setAfterPasswordSubmitDetailsSignal,
}) => {
  const classes = useStyles();
  const refPassword = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (checkSaveMyProgressAndResumeLater && refPassword.current) {
      refPassword.current.scrollIntoView({ behavior: "smooth" });
      formik.setFieldValue("password", "");
      formik.setFieldValue("confirmPassword", "");
      formik.setFieldTouched("password", false);
      formik.setFieldTouched("confirmPassword", false);
    }
  }, [checkSaveMyProgressAndResumeLater]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(setPasswordApi(email, formik.values.password, profileType)).then(
        (res) => {
          console.log(res);
          localStorage.setItem("dispute_id", res.id);
          setAfterPasswordSubmitDetailsSignal(true);
        }
      );
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <Box ref={refPassword}>
      {checkSaveMyProgressAndResumeLater && (
        <Grid container justify="center">
          <Grid item xs={10}>
            <Typography className={classes.text}>
              In order to be able to resume this form later, please enter your
              email and choose a password.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <StyledTextFieldTiac
                variant="outlined"
                margin="normal"
                fullWidth
                label={"Email address"}
                required
                id="email"
                name="email"
                autoComplete="email"
                value={email}
                disabled={true}
                inputProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
                InputLabelProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
              />
              <StyledTextFieldTiac
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={"Password"}
                type={passwordType}
                id="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={tooglePasswordType}
                      style={{ cursor: "pointer" }}
                    >
                      {passwordType == "password" ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
              />
              {formik.errors.password && formik.touched.password && (
                <Alert severity="error">{formik.errors.password}</Alert>
              )}
              <StyledTextFieldTiac
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={"Confirm Password"}
                type={passwordType}
                id="confirmPassword"
                value={formik.values.confirmPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  style: { fontFamily: `'Inter',sans-serif` },

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={tooglePasswordType}
                      style={{ cursor: "pointer" }}
                    >
                      {passwordType == "password" ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <Alert severity="error">
                    {formik.errors.confirmPassword}
                  </Alert>
                )}
              <Grid container justify="center">
                <Button
                  type="submit"
                  variant="contained"
                  style={{ background: "#2F80ED" }}
                  disabled={loading}
                >
                  <Typography className={classes.btnTextWhite}>Save</Typography>
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
      <Box height="4rem" /> {/* last box to scroll into view*/}
    </Box>
  );
};

export default SetPassword;
