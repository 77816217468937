import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import instance from "api/globalaxios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import ClientsList from "./Common/ClientsList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  sortbybtn: {
    cursor: "pointer",
    border: "none",
    background: "#E0E0E0",
    "box-shadow":
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    "border-radius": "4px",
  },
  sortbytext: {
    padding: "10px 30px 10px 10px",
    "font-size": "12px",
    "line-height": "0px",
    "text-align": "center",
    "text-transform": "uppercase",
    color: "#454545",
  },
}));

export default function Clients({ profile }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showSubAdmin, setShowSubAdmin] = useState(false);

  const [loading, setLoading] = useState(true);

  const [finaldata, setFinaldata] = useState([]);

  const [order, setOrder] = React.useState("increase");
  const [sortby, setSortby] = useState("name");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNameSort = () => {
    setSortby("name");
    let result = finaldata;
    if (order == "increase") {
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    setFinaldata(result);
    setAnchorEl(null);
  };
  const handleOpenSort = () => {
    setSortby("open");
    let result = finaldata;
    if (order == "increase") {
      result.sort(function (a, b) {
        var nameA = a.open;
        var nameB = b.open;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      result.sort(function (a, b) {
        var nameA = a.open;
        var nameB = b.open;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    setFinaldata(result);
    setAnchorEl(null);
  };
  const handleClosedSort = () => {
    setSortby("closed");
    let result = finaldata;
    if (order == "increase") {
      result.sort(function (a, b) {
        var nameA = a.closed;
        var nameB = b.closed;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      result.sort(function (a, b) {
        var nameA = a.closed;
        var nameB = b.closed;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    setFinaldata(result);
    setAnchorEl(null);
  };
  const handleChangeRadio = (event) => {
    setOrder(event.target.value);
    if (sortby == "name") {
      let result = finaldata;
      if (event.target.value == "increase") {
        result.sort(function (a, b) {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else {
        result.sort(function (a, b) {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
      }
      setFinaldata(result);
      setAnchorEl(null);
    } else if (sortby == "open") {
      let result = finaldata;
      if (event.target.value == "increase") {
        result.sort(function (a, b) {
          var nameA = a.open;
          var nameB = b.open;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else {
        result.sort(function (a, b) {
          var nameA = a.open;
          var nameB = b.open;
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
      }
      setFinaldata(result);
      setAnchorEl(null);
    } else if (sortby == "closed") {
      let result = finaldata;
      if (event.target.value == "increase") {
        result.sort(function (a, b) {
          var nameA = a.closed;
          var nameB = b.closed;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else {
        result.sort(function (a, b) {
          var nameA = a.closed;
          var nameB = b.closed;
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
      }
      setFinaldata(result);
      setAnchorEl(null);
    }
  };

  React.useEffect(() => {
    dispatch(apiFunction()).then(() => {});
  }, []);

  function apiFunction() {
    return async () => {
      try {
        let response = await instance.post("/api/disputeinfobygroupsubadmin/", {
          group: profile.group,
        });
        setFinaldata(response.data.company_data);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    if (showSubAdmin) {
      return 1;
    } else {
      return (
        <div className={classes.root}>
          <Grid container justify="flex-start" direction="column">
            <Grid item>
              <Grid container justify="space-between" direction="row">
                <Grid item>
                  <Typography className={classes.heading}>Clients</Typography>
                </Grid>
                <Grid item>
                  {/* <Typography className={classes.username}>{profile.user.first_name}{" "}{profile.user.last_name}</Typography> */}
                </Grid>
              </Grid>
              <Box height="2rem"></Box>
              <Grid container justify="center">
                <Grid item sm={10} xs={11}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <button
                        type="button"
                        className={classes.sortbybtn}
                        onClick={handleClick}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                      >
                        <Typography className={classes.sortbytext}>
                          Sort by
                        </Typography>
                      </button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Grid container spacing={2} direction="row">
                          <Grid item>
                            <Box display="flex">
                              <input
                                type="radio"
                                id="increase"
                                name="order"
                                value="increase"
                                onChange={handleChangeRadio}
                                style={{
                                  transform: "scale(1.5)",
                                  margin: "6px 3px 0px 6px",
                                }}
                                checked={order == "increase" ? true : false}
                              />
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography>
                                  <label
                                    for="increase"
                                    style={{
                                      fontSize: "16px",
                                      color: "#454545",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Inc.
                                  </label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <input
                                type="radio"
                                id="decrease"
                                name="order"
                                value="decrease"
                                onChange={handleChangeRadio}
                                style={{
                                  transform: "scale(1.5)",
                                  margin: "6px 3px 0px 6px",
                                }}
                                checked={order == "decrease" ? true : false}
                              />
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography>
                                  <label
                                    for="decrease"
                                    style={{
                                      fontSize: "16px",
                                      color: "#454545",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Dec.
                                  </label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box height="0.7rem"></Box>
                        <Divider />
                        <MenuItem
                          onClick={(e) => {
                            handleNameSort(e);
                          }}
                        >
                          Name
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleOpenSort(e);
                          }}
                        >
                          Open
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleClosedSort(e);
                          }}
                        >
                          Closed
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  <Box height="4rem"></Box>
                  <ClientsList
                    data={finaldata}
                    setShowSubAdmin={setShowSubAdmin}
                    client={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
