import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  flashButton: {
    animation: "$flash 1s ease-in-out 3", // 3 iterations
  },
  "@keyframes flash": {
    "0%, 100%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
    },
  },
}));

function FlashButton({ onClick, buttonText }) {
  const classes = useStyles();

  return (
    <Button
      id="flashingButton"
      onClick={onClick}
      className={classes.flashButton}
      variant="contained"
      color="primary"
      size="large"
      style={{
        width: "100%",
        background: "#2F80ED",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "36px",
        letterSpacing: "0.4px",
      }}
    >
      <Typography>{buttonText}</Typography>
    </Button>
  );
}

export default FlashButton;
