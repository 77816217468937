import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import WebnyayColor from 'images/webnyay.svg';
import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    flexShrink: "0",
    padding: "2rem 0 2rem 2rem",
    [theme.breakpoints.down("sm")]: { padding: "1rem 0" },
  },
  UnderlinedText: {
    color: "#4F4F4F",
    fontWeight: "400",
    fontSize: 12,
    textDecoration: "underline",
    cursor: "pointer",
    textTransform: "uppercase",
  },
  text: {
    color: "#4F4F4F",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: "36px",
    textTransform: "uppercase",
  },
  logo: {
    width: "auto",
    height: "3rem",
  },
}));

export default function DifcFooter({ profile }) {
  const classes = useStyles();
  const location = useLocation();
  const pathNameArray = location.pathname.split("/");
  const isChat = pathNameArray[pathNameArray.length - 1] === "chat";
  return (
    <Fragment>
      {!isChat && (
        <Box bgcolor="#E2E2E2" textAlign="center" className={classes.footer}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={5} xs={12}>
              <Grid container justify="flex-start" alignItems="center">
                <Grid item md={4} sm={6} xs>
                  <a href="https://webnyay-my.sharepoint.com/:f:/g/personal/ishita_webnyay_ai/EtL6BfaeMTFFiTgIJB-AIggBBGc8xbl-qTnetyUDdLdTUg?e=GP5lny" target="_blank" rel="noopener noreferrer">
                    <Typography className={classes.UnderlinedText}>
                      Resources
                    </Typography>
                  </a>
                </Grid>
                <Grid item md={4} sm={6} xs>
                  <a href="mailto:techsupport@webnyay.ai">
                    <Typography className={classes.UnderlinedText}>
                      Help Support
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={7} xs={12}>
              <Grid container alignItems="center" style={{justifyContent:'end'}}>
                {/* <Grid item md={4} sm={6} xs={6}>
                  <Typography className={classes.text}>
                    Knowledge Partner
                  </Typography>
                  <img
                    src={MiddleSexUniversity}
                    alt=""
                    className={classes.logo}
                  />
                </Grid> */}
                <Grid item md={4} sm={6} xs={6}>
                  <Typography className={classes.text}>
                    TECHNOLOGY Partner
                  </Typography>
                  <Link to="/">
                    <img src={WebnyayColor} alt="" className={classes.logo} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}
