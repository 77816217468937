import React, { useState } from 'react';
import { Button, Modal, Typography, makeStyles, ListItem, ListItemText, List, TextField, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: 8,
    maxWidth: 600,
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  list: {
    maxHeight: 200,
    overflow: 'auto',
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    alignItems: 'center'
  },
  closeBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: '20px'
  }
}));

const CustomModal = ({open, handleClose, selectedDisputes, message, setMessage, onSubmit}) => {
  const classes = useStyles();

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className={classes.paper}>
        <Typography variant="h6" id="modal-title" className={classes.title}>
          Bulk Response
        </Typography>
        <Typography variant="body1" id="modal-description">
          Please review the selected disputes and provide your response below.
        </Typography>
        <List className={classes.list}>
          {selectedDisputes.map((dispute, index) => (
            <ListItem key={index} dense>
              <ListItemText primary={dispute} />
            </ListItem>
          ))}
        </List>
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={handleMessageChange}
          className={classes.textField}
        />
        <Box className={classes.buttonContainer}>
          <Typography 
            onClick={handleClose}
            className={classes.closeBtn}>
              Close
          </Typography>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Submit
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default CustomModal;
