import { Button, makeStyles } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { linkedinOauthurl } from "api/globalaxios";
import React from "react";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {},
  button: {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 2px rgba(0,0,0,0.6)",
    transition: "all 0.5s",
    "&:hover": {
      // color: "#DB4437",
      // border: "1px solid #DB4437",
      // backgroundColor: "transparent",
      boxShadow: "1px 1px 10px rgba(0,0,0,0.6)",
    },
  },
}));

const requestProfile = () => {
  const width = 450,
    height = 730,
    left = window.screen.width / 2 - width / 2,
    top = window.screen.height / 2 - height / 2;

  window.open(
    linkedinOauthurl,
    "_self",
    "Linkedin",
    "menubar=no,location=no,resizable=no,scrollbars=no,status=no"
  );
};

export default function LinkedinAuth() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLoginClick = () => {};

  return (
    <div className={classes.container}>
      <Button
        variatn="contained"
        color="error"
        className={classes.button}
        onClick={requestProfile}
        fullWidth
        startIcon={
          <LinkedInIcon
            style={{ height: "2rem", width: "2rem", color: "#2867B2" }}
          />
        }
      >
        Linkedin sign in
      </Button>
    </div>
  );
}
