import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { DocGenerator } from "utils";
import DotsImage from "images/threeDots.svg";
import DownloadSvg from "images/download.svg";

import { formatDate } from "utils";
import DonePopup from "features/common/Popup/DonePopup";
import POPUP from "features/common/Popup/Popup";
import DeletedPopup from "features/common/Popup/DeletedPopup";
import { changeDisputeState, deleteDisputeapi } from "./Disputes";

const useStyles = makeStyles((theme) => ({
  value: {
    border: "none",
    fontFamily: `"Inter",sans-serif`,
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "400",
    textTransform: "capitalize",
    "&:hover": {
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      lineHeight: "1.2rem",
    },
  },
  valuePaper: {
    padding: "1.5rem",
    cursor: "pointer",
    boxShadow: "none",
    width: "100%",
    border: "1px solid #E0E0E0",
    "&:hover": {
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  menuwidth: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "130px",
    },
  },
  alginCenter: {
    textAlign: "center",
  },
}));

const DisputeRow = ({
  id,
  name,
  filingDate,
  registrationDate,
  type,
  history,
  addDispute,
  removeDispute,
  reload,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickOnThreeDots = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseOnThreeDots = () => {
    setAnchorEl(null);
  };
  const download = (event) => {
    event.stopPropagation();
    DocGenerator(id, "claimant");
  };
  const handleChangeOnCheckbox = (event) => {
    if (event.target.checked) {
      addDispute(event.target.id);
    } else {
      removeDispute(event.target.id);
    }
  };

  const onRowClick = () => {
    history.push(`/disputes/${id}`);
  };

  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const handleClickDelete = () => {
    setOpenDeletePopup(true);
    handleCloseOnThreeDots();
  };
  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };
  const handleDelete = () => {
    handleCloseDelete();
    deleteDisputeapi(id)
      .then((res) => {
        handleOpenDeletedGifPopup();
      })
      .catch((error) => {
        console.log("error.response.data", error.response.data);
      });
  };
  const [openFrivolousPopup, setopenFrivolousPopup] = React.useState(false);
  const handleClickMarkAsFrivolous = () => {
    setopenFrivolousPopup(true);
    handleCloseOnThreeDots();
  };
  const handleCloseFrivolous = () => {
    setopenFrivolousPopup(false);
  };
  const handleFrivolous = () => {
    handleCloseFrivolous();
    changeDisputeState(id, "frivolous")
      .then((res) => {
        handleOpenFrivolousGifPopup();
      })
      .catch((error) => {
        console.log("error.response.data", error.response.data);
        if (error?.response?.data?.status == "not done") {
          alert("This dispute is already frivolous: " + id);
        }
      });
  };
  const [openSeriousPopup, setopenSeriousPopup] = React.useState(false);
  const handleClickMarkAsSerious = () => {
    setopenSeriousPopup(true);
    handleCloseOnThreeDots();
  };
  const handleCloseSerious = () => {
    setopenSeriousPopup(false);
  };
  const handleSerious = () => {
    handleCloseSerious();
    changeDisputeState(id, "serious")
      .then((res) => {
        handleOpenSeriousGifPopup();
      })
      .catch((error) => {
        console.log("error.response.data", error.response.data);
        if (error?.response?.data?.status == "not done") {
          alert("This dispute is already serious: " + id);
        }
      });
  };
  const [openDeletedGifPopup, setOpenDeletedGifPopup] = React.useState(false);
  const handleOpenDeletedGifPopup = () => {
    setOpenDeletedGifPopup(true);
  };
  const handleCloseDeletedGifPopup = () => {
    setOpenDeletedGifPopup(false);
    reload();
  };
  const [openSeriousGifPopup, setOpenSeriousGifPopup] = React.useState(false);
  const handleOpenSeriousGifPopup = () => {
    setOpenSeriousGifPopup(true);
  };
  const handleCloseSeriousGifPopup = () => {
    setOpenSeriousGifPopup(false);
    reload();
  };
  const [openFrivolousGifPopup, setOpenFrivolousGifPopup] =
    React.useState(false);
  const handleOpenFrivolousGifPopup = () => {
    setOpenFrivolousGifPopup(true);
  };
  const handleCloseFrivolousGifPopup = () => {
    setOpenFrivolousGifPopup(false);
    reload();
  };

  return (
    <>
      <Paper className={classes.valuePaper} square onClick={onRowClick}>
        <Grid container spacing={2}>
          {type === "serious" || type === "frivolous" ? (
            <>
              <Grid item xs sm={1} className={classes.alginCenter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={id}
                      onChange={handleChangeOnCheckbox}
                      style={{ color: "#6b6a6a", padding: 0 }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                />
              </Grid>
              <Grid item xs sm={1}>
                <Typography className={classes.value}>
                  {id ? id : "----"}
                </Typography>
              </Grid>
              <Grid item xs sm={3}>
                <Typography className={classes.value}>
                  {name.split("&")[0]}
                </Typography>
              </Grid>
              <Grid item xs sm={2}>
                <Typography className={classes.value}>
                  {formatDate(filingDate)}
                </Typography>
              </Grid>
              <Grid item xs sm={2}>
                <Typography className={classes.value}>
                  {registrationDate ? formatDate(registrationDate) : "----"}
                </Typography>
              </Grid>
              <Grid item xs sm={2}>
                <Typography className={classes.value} onClick={download}>
                  VIEW GRIEVANCE
                  <span style={{ marginLeft: "0.6rem" }}>
                    <img src={DownloadSvg} alt="" />
                  </span>
                </Typography>
              </Grid>
              <Grid item xs sm={1} style={{ textAlign: "center" }}>
                <IconButton
                  onClick={handleClickOnThreeDots}
                  // onMouseEnter={handleClick}
                  aria-controls={`three-dots-menu${id}`}
                  aria-haspopup="true"
                  style={{ padding: 0 }}
                >
                  <img src={DotsImage} alt="options" />
                </IconButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs sm={1}>
                <Typography className={classes.value}>
                  {id ? id : "----"}
                </Typography>
              </Grid>
              <Grid item xs sm={4}>
                <Typography className={classes.value}>
                  {name.split("&")[0]}
                </Typography>
              </Grid>
              <Grid item xs sm={2}>
                <Typography className={classes.value}>
                  {formatDate(filingDate)}
                </Typography>
              </Grid>
              <Grid item xs sm={2}>
                <Typography className={classes.value}>
                  {registrationDate ? formatDate(registrationDate) : "----"}
                </Typography>
              </Grid>
              <Grid item xs sm={3}>
                <Typography className={classes.value} onClick={download}>
                  VIEW GRIEVANCE
                  <span style={{ marginLeft: "0.6rem" }}>
                    <img src={DownloadSvg} alt="" />
                  </span>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
      <Menu
        aria-controls={`three-dots-menu${id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOnThreeDots}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Typography
            className={classes.value}
            onClick={handleClickDelete}
            style={{
              padding: "1rem 1.5rem",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Delete
          </Typography>
          <Typography
            className={classes.value}
            onClick={
              type == "serious"
                ? handleClickMarkAsFrivolous
                : handleClickMarkAsSerious
            }
            style={{
              padding: "1rem 1.5rem",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            {type === "serious" ? "Mark as frivolous" : "Mark as serious"}
          </Typography>
        </Box>
      </Menu>
      <POPUP
        opener={openDeletePopup}
        onClose={handleCloseDelete}
        heading="Delete grievances"
        message="Are you sure you would like to delete the selected grievance(s)?"
        mainButton="DELETE"
        onMainButton={handleDelete}
      />
      <DeletedPopup
        opener={openDeletedGifPopup}
        onClose={handleCloseDeletedGifPopup}
        text="Deleted!"
      />
      <POPUP
        opener={openSeriousPopup}
        onClose={handleCloseSerious}
        heading="Move grievances"
        message="Are you sure you would like to move the selected grievance(s) to serious?"
        mainButton="MOVE"
        onMainButton={handleSerious}
      />
      <DonePopup
        opener={openSeriousGifPopup}
        onClose={handleCloseSeriousGifPopup}
        text="Moved to serious"
      />
      <POPUP
        opener={openFrivolousPopup}
        onClose={handleCloseFrivolous}
        heading="Move grievances"
        message="Are you sure you would like to move the selected grievance(s) to frivolous?"
        mainButton="MOVE"
        onMainButton={handleFrivolous}
      />
      <DonePopup
        opener={openFrivolousGifPopup}
        onClose={handleCloseFrivolousGifPopup}
        text="Moved to frivolous"
      />
    </>
  );
};

export default DisputeRow;
