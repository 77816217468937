import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import Lines from "./DonutGraph/Lines";

import AN from "./Map/state-wise/andamannicobar.json";
import AP from "./Map/state-wise/andhrapradesh.json";
import AR from "./Map/state-wise/arunachalpradesh.json";
import AS from "./Map/state-wise/assam.json";
import BR from "./Map/state-wise/bihar.json";
import CH from "./Map/state-wise/chandigarh.json";
import CT from "./Map/state-wise/chhattisgarh.json";
import DN from "./Map/state-wise/dadranagarhaveli.json";
import DL from "./Map/state-wise/delhi.json";
import GA from "./Map/state-wise/goa.json";
import GJ from "./Map/state-wise/gujarat.json";
import HR from "./Map/state-wise/haryana.json";
import HP from "./Map/state-wise/himachalpradesh.json";
import JK from "./Map/state-wise/jammukashmir.json";
import JH from "./Map/state-wise/jharkhand.json";
import KA from "./Map/state-wise/karnataka.json";
import KL from "./Map/state-wise/kerala.json";
import LA from "./Map/state-wise/ladakh.json";
import LD from "./Map/state-wise/lakshadweep.json";
import MP from "./Map/state-wise/madhyapradesh.json";
import MH from "./Map/state-wise/maharashtra.json";
import MN from "./Map/state-wise/manipur.json";
import ML from "./Map/state-wise/meghalaya.json";
import MZ from "./Map/state-wise/mizoram.json";
import NL from "./Map/state-wise/nagaland.json";
import OD from "./Map/state-wise/odisha.json";
import PY from "./Map/state-wise/puducherry.json";
import PB from "./Map/state-wise/punjab.json";
import RJ from "./Map/state-wise/rajasthan.json";
import SK from "./Map/state-wise/sikkim.json";
import TN from "./Map/state-wise/tamilnadu.json";
import TS from "./Map/state-wise/telangana.json";
import TR from "./Map/state-wise/tripura.json";
import {
  default as UK,
  default as UT,
} from "./Map/state-wise/uttarakhand.json";
import UP from "./Map/state-wise/uttarpradesh.json";
import WB from "./Map/state-wise/westbengal.json";

const useStyles = makeStyles({
  root: {
    width: "70px",
  },
  switchBase: {
    color: "#61A0FF",
    "&$checked": {
      color: "#FF665C",
      transform: "translateX(30px)",
    },
    "&$checked + $track": {
      backgroundColor: "#000",
      opacity: "1",
    },
  },
  checked: {},
  track: {},
});

const PROJECTION_JSON = {
  AN: {
    PROJECTION_CONFIG: {
      scale: 250,
      center: [95.6, 8.8937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: AN,
  },
  AP: {
    PROJECTION_CONFIG: {
      scale: 280,
      center: [83.3629, 14.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: AP,
  },
  AR: {
    PROJECTION_CONFIG: {
      scale: 470,
      center: [96.35, 26.9937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 30",
    MAP: AR,
  },
  AS: {
    PROJECTION_CONFIG: {
      scale: 400,
      center: [94.8629, 24.9937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: AS,
  },
  BR: {
    PROJECTION_CONFIG: {
      scale: 380,
      center: [87.9629, 24.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: BR,
  },
  CH: {
    PROJECTION_CONFIG: {
      scale: 0,
      center: [82.9629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 200 200",
    MAP: CH,
  },
  CT: {
    PROJECTION_CONFIG: {
      scale: 400,
      center: [83.9629, 20.8937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 49",
    MAP: CT,
  },
  DN: {
    PROJECTION_CONFIG: {
      scale: 0,
      center: [82.9629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 200 200",
    MAP: DN,
  },
  DL: {
    PROJECTION_CONFIG: {
      scale: 1600,
      center: [77.5629, 28.2], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 25",
    MAP: DL,
  },
  GA: {
    PROJECTION_CONFIG: {
      scale: 1600,
      center: [74.5629, 14.9937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 30",
    MAP: GA,
  },
  GJ: {
    PROJECTION_CONFIG: {
      scale: 400,
      center: [73.5629, 21.4937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: GJ,
  },
  HR: {
    PROJECTION_CONFIG: {
      scale: 570,
      center: [77.5629, 28.9], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 40",
    MAP: HR,
  },
  HP: {
    PROJECTION_CONFIG: {
      scale: 580,
      center: [78.9629, 31.2], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: HP,
  },
  JK: {
    PROJECTION_CONFIG: {
      scale: 500,
      center: [76.9529, 32.9], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: JK,
  },
  JH: {
    PROJECTION_CONFIG: {
      scale: 450,
      center: [87.5629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: JH,
  },
  KA: {
    PROJECTION_CONFIG: {
      scale: 280,
      center: [79.3629, 13.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: KA,
  },
  KL: {
    PROJECTION_CONFIG: {
      scale: 400,
      center: [78.3629, 9.4937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: KL,
  },
  LA: {
    PROJECTION_CONFIG: {
      scale: 0,
      center: [82.9629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 200 200",
    MAP: LA,
  },
  LD: {
    PROJECTION_CONFIG: {
      scale: 0,
      center: [82.9629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 200 200",
    MAP: LD,
  },
  MP: {
    PROJECTION_CONFIG: {
      scale: 300,
      center: [80.9629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: MP,
  },
  MH: {
    PROJECTION_CONFIG: {
      scale: 280,
      center: [79.9629, 17.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: MH,
  },
  MN: {
    PROJECTION_CONFIG: {
      scale: 750,
      center: [94.66, 24], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 30",
    MAP: MN,
  },
  ML: {
    PROJECTION_CONFIG: {
      scale: 900,
      center: [92.19, 24.9937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 30",
    MAP: ML,
  },
  MZ: {
    PROJECTION_CONFIG: {
      scale: 700,
      center: [93.9, 22.7], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: MZ,
  },
  NL: {
    PROJECTION_CONFIG: {
      scale: 750,
      center: [95.26, 25.4], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 30",
    MAP: NL,
  },
  OD: {
    PROJECTION_CONFIG: {
      scale: 320,
      center: [86.9629, 18.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: OD,
  },
  PY: {
    PROJECTION_CONFIG: {
      scale: 0,
      center: [82.9629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 200 200",
    MAP: PY,
  },
  PB: {
    PROJECTION_CONFIG: {
      scale: 570,
      center: [76.9629, 30.5], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: PB,
  },
  RJ: {
    PROJECTION_CONFIG: {
      scale: 240,
      center: [77.629, 25.2], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: RJ,
  },
  SK: {
    PROJECTION_CONFIG: {
      scale: 980,
      center: [89.5629, 26.9937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 28",
    MAP: SK,
  },
  TN: {
    PROJECTION_CONFIG: {
      scale: 350,
      center: [80.3629, 9.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: TN,
  },
  TS: {
    PROJECTION_CONFIG: {
      scale: 400,
      center: [81.3629, 16.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: TS,
  },
  TR: {
    PROJECTION_CONFIG: {
      scale: 900,
      center: [92.9, 23.1], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 30",
    MAP: TR,
  },
  UT: {
    PROJECTION_CONFIG: {
      scale: 0,
      center: [82.9629, 22.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 200 200",
    MAP: UT,
  },
  UK: {
    PROJECTION_CONFIG: {
      scale: 580,
      center: [80.5629, 29.5], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 36",
    MAP: UK,
  },
  UP: {
    PROJECTION_CONFIG: {
      scale: 290,
      center: [83.5629, 26.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 41",
    MAP: UP,
  },
  WB: {
    PROJECTION_CONFIG: {
      scale: 340,
      center: [89.9629, 23.5937], // always in [East Latitude, North Longitude]
    },
    VIEWBOX: "0 10 50 40",
    MAP: WB,
  },
};

const allmarkers = {
  AN: {
    markers: [],
  },
  AP: {
    markers: [
      {
        markerOffset: -2,
        name: "Tirupati",
        coordinates: [79.4231711, 13.6316368],
        data: [2309, 2109, 1745, 1102, 987],
      },
    ],
  },
  AR: {
    markers: [
      {
        markerOffset: -2,
        name: "Khonsa",
        coordinates: [95.5061065, 26.9910099],
        data: [2109, 1209, 1345, 2102, 87],
      },
    ],
  },
  AS: {
    markers: [
      {
        markerOffset: -2,
        name: "Guwahati",
        coordinates: [91.69164600906831, 26.1578514],
        data: [2309, 109, 15, 102, 1987],
      },
    ],
  },
  BR: {
    markers: [
      {
        markerOffset: -2,
        name: "Patna",
        coordinates: [85.1235252, 25.6093239],
        data: [2409, 2100, 1700, 1100, 980],
      },
    ],
  },
  CH: {
    markers: [],
  },
  CT: {
    markers: [
      {
        markerOffset: -2,
        name: "Raipur",
        coordinates: [81.6336833, 21.2379469],
        data: [2300, 2209, 1645, 1101, 887],
      },
    ],
  },
  DN: {
    markers: [],
  },
  DL: {
    markers: [],
  },
  GA: {
    markers: [
      {
        markerOffset: -2,
        name: "Pondal",
        coordinates: [74.00975207575615, 15.4039033],
        data: [2709, 2309, 1645, 1202, 587],
      },
    ],
  },
  GJ: {
    markers: [
      {
        markerOffset: -2,
        name: "Ahmedabad",
        coordinates: [72.5797068, 23.0216238],
        data: [1309, 1109, 745, 102, 87],
      },
    ],
  },
  HR: {
    markers: [
      {
        markerOffset: -2,
        name: "Faridabad",
        coordinates: [77.3105561, 28.4031478],
        data: [2409, 2109, 1445, 1202, 187],
      },
    ],
  },
  HP: {
    markers: [
      {
        markerOffset: -2,
        name: "Shimla",
        coordinates: [77.1709729, 31.1041526],
        data: [2209, 2009, 1145, 602, 287],
      },
    ],
  },
  JK: {
    markers: [
      {
        markerOffset: -2,
        name: "Srinagar",
        coordinates: [74.8204443, 34.0747444],
        data: [2109, 2009, 1500, 1101, 907],
      },
    ],
  },
  JH: {
    markers: [
      {
        markerOffset: -2,
        name: "Ranchi",
        coordinates: [85.3250387, 23.3700501],
        data: [2309, 2109, 1745, 1102, 987],
      },
    ],
  },
  KA: {
    markers: [
      {
        markerOffset: -2,
        name: "Bengaluru",
        coordinates: [77.590082, 12.9767936],
        data: [1209, 1109, 1045, 602, 387],
      },
    ],
  },
  KL: {
    markers: [
      {
        markerOffset: -2,
        name: "Kochi",
        coordinates: [76.2674136, 9.931308],
        data: [2219, 2209, 2145, 2102, 187],
      },
    ],
  },
  LA: {
    markers: [
      {
        markerOffset: -2,
        name: "Shey",
        coordinates: [77.6397177, 34.0713377],
        data: [239, 109, 105, 102, 87],
      },
    ],
  },
  LD: {
    markers: [],
  },
  MP: {
    markers: [
      {
        markerOffset: -2,
        name: "Indore",
        coordinates: [75.8681996, 22.7203616],
        data: [309, 109, 45, 11, 9],
      },
    ],
  },
  MH: {
    markers: [
      {
        markerOffset: -2,
        name: "Mumbai",
        coordinates: [72.877426, 19.07609],
        data: [2309, 2109, 1745, 1102, 987],
      },
    ],
  },
  MN: {
    markers: [
      {
        markerOffset: -2,
        name: "Imphal",
        coordinates: [93.9364419, 24.7991162],
        data: [4209, 2109, 1345, 1202, 231],
      },
    ],
  },
  ML: {
    markers: [
      {
        markerOffset: -2,
        name: "Shillong",
        coordinates: [91.8825282, 25.5760446],
        data: [2309, 1209, 2345, 102, 87],
      },
    ],
  },
  MZ: {
    markers: [],
  },
  NL: {
    markers: [],
  },
  OD: {
    markers: [],
  },
  PY: {
    markers: [],
  },
  PB: {
    markers: [],
  },
  RJ: {
    markers: [],
  },
  SK: {
    markers: [],
  },
  TN: {
    markers: [],
  },
  TS: {
    markers: [],
  },
  TR: {
    markers: [],
  },
  UT: {
    markers: [],
  },
  UK: {
    markers: [],
  },
  UP: {
    markers: [
      {
        markerOffset: -2,
        name: "Meerut",
        coordinates: [77.7064, 28.9845],
        data: [2309, 2109, 1745, 1102, 987],
      },
      {
        markerOffset: -2,
        name: "Lucknow",
        coordinates: [80.9346001, 26.8381],
        data: [1309, 1109, 745, 102, 87],
      },
    ],
  },
  WB: {
    markers: [],
  },
};

export default function StateHeatMap({
  choosestep2,
  stateOnClick,
  activeState,
  states,
  selectedHeatstate,
}) {
  const classes = useStyles();

  const [tooltipContent, setTooltipContent] = useState("");

  const [markers, setMarkers] = useState([]);
  const [dataLines, setDataLines] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState();

  React.useEffect(() => {
    let id = selectedHeatstate.state;
    let mark = allmarkers[id].markers;
    setMarkers(mark);
    setDataLines();
    setSelectedMarker();
  }, [selectedHeatstate]);

  const onMouseEnter = (geo, current = { value: "NA" }) => {};

  const onMouseLeave = () => {
    // setTooltipContent('');
  };

  const onFocus = (geo, current) => {
    stateOnClick({ states: current.value }, current.state, current.id);
  };

  return (
    <Paper
      style={{
        overflow: "hidden",
        height: "-webkit-fill-available",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      <Grid container>
        <Grid item md={10} sm={10} xs={10}>
          <Typography
            style={{
              color: "#000000",
              fontSize: "18px",
              lineHeight: "20px",
              margin: "3rem 0 0.5rem 3rem",
            }}
          >
            {activeState.name}
          </Typography>
        </Grid>
        <Grid item md={10} sm={10} xs={10}>
          <Typography
            style={{
              color: "#828282",
              fontSize: "14px",
              lineHeight: "15px",
              margin: "0 0 0.5rem 3rem",
            }}
          >
            Hot spots
          </Typography>
        </Grid>
      </Grid>

      <ReactTooltip
        backgroundColor="#fff"
        textColor="#000"
        borderColor="#000"
        border={true}
        style={{ opacity: "1 !important" }}
      >
        {tooltipContent}
      </ReactTooltip>
      <ComposableMap
        projectionConfig={
          PROJECTION_JSON[selectedHeatstate.state].PROJECTION_CONFIG
        }
        projection="geoMercator"
        width={80}
        height={70}
        viewBox={PROJECTION_JSON[selectedHeatstate.state].VIEWBOX}
      >
        <Geographies geography={PROJECTION_JSON[selectedHeatstate.state].MAP}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const current = states.find((s) => s.id === geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={selectedHeatstate.color}
                  onMouseEnter={() => onMouseEnter(geo, current)}
                  onMouseLeave={onMouseLeave}
                />
              );
            })
          }
        </Geographies>
        {markers.map(({ name, coordinates, markerOffset, data }) => {
          return (
            <Marker
              key={name}
              coordinates={coordinates}
              onClick={() => {
                setDataLines(data);
                setSelectedMarker(name);
              }}
            >
              <g transform="translate(-20,-22)">
                <circle
                  cx={20}
                  cy={20}
                  r={3}
                  fill="transparent"
                  stroke="#F2994A"
                  strokeWidth={1}
                />
                <circle
                  cx={20}
                  cy={20}
                  r={2}
                  fill="transparent"
                  stroke="#FF665C"
                  strokeWidth={1}
                />
              </g>
              <text
                textAnchor="middle"
                y={markerOffset}
                style={{
                  fontFamily: "system-ui",
                  fill: "#ffffff",
                  fontSize: "0.5px",
                }}
              >
                {name}
              </text>
            </Marker>
          );
        })}
      </ComposableMap>
      {selectedMarker && (
        <Grid container>
          <Grid item md={10} sm={10} xs={10}>
            <Typography
              style={{
                color: "#000000",
                fontSize: "13px",
                lineHeight: "14px",
                margin: "1rem 0 0.5rem 3rem",
              }}
            >
              {selectedMarker}
            </Typography>
          </Grid>
          <Grid
            item
            md={7}
            sm={10}
            xs={10}
            style={{ margin: "1rem 0 0.5rem 3rem" }}
          >
            <Lines
              labels={["Closed", "Pending", "New", "Drafts", "Rejected"]}
              cols={["#9EF3B1", "#81F5FD", "#61A0FF", "#EEFF85", "#FF665C"]}
              data={dataLines}
            />
            <Box height="1rem" />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
