import {
  Backdrop,
  Button,
  Fade,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import CloseModalButton from "Admin/Common/CloseModalButton";
import instance from "api/globalaxios";
import { blockInvalidChar } from "features/TempTiacFormV2/ClaimantPage/questions";
import React, { useState } from "react";
import { getRealhost } from "slices/disputeSlice";
import { getCountries } from "slices/profileSlice";
import { getProfileTypeTitleDIFC } from "./Assign";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "80%",
      padding: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      height: "80%",
      padding: "0.5rem",
    },
  },
  createlogindetails: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
  caseId: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  profileType: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  box: {
    padding: "0.7rem",
  },
  box2: {
    cursor: "pointer",
    padding: "0.7rem",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
}));

export default function CreateLoginDetails({
  openmodal,
  handleCloseModal,
  caseId,
  profileType,
  companyId,
  dispute,
  handleCloseModalAfterProfileCreated,
  profile,
}) {
  const classes = useStyles();

  const [openSnackBar1, setOpenSnackBar1] = useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };
  const [openSnackBar3, setOpenSnackBar3] = useState(false);
  const handleCloseSnackBar3 = () => {
    setOpenSnackBar3(false);
  };
  const [openSnackBar4, setOpenSnackBar4] = useState(false);
  const handleCloseSnackBar4 = () => {
    setOpenSnackBar4(false);
  };
  const [openSnackBar5, setOpenSnackBar5] = useState(false);
  const handleCloseSnackBar5 = () => {
    setOpenSnackBar5(false);
  };
  const [allCountries, setallCountries] = useState([]);
  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  const [firstname, setFirstname] = useState("");
  const [nameOfOrganization, setNameOfOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postal_code, setpostal_code] = useState("");
  const [country, setCountry] = useState("-");

  const onFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onNameOfOrganizationChange = (e) => {
    setNameOfOrganization(e.target.value);
  };
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const onCityChange = (e) => {
    setCity(e.target.value);
  };
  const onRegionChange = (e) => {
    setRegion(e.target.value);
  };
  const onPostalCodeChange = (e) => {
    setpostal_code(e.target.value);
  };

  async function createProfile() {
    let data = {
      profile_type: profileType,
      address: address,
      dispute_id: caseId,
      city: city,
      region: region,
      postal_code: postal_code,
      country: country != "-" ? country : null,
      vua_id: nameOfOrganization,
      group: profile.group,
      user: {
        email: email,
        password: "Test@123",
        first_name: firstname,
      },
    };
    if (phone.trim() != "") {
      data["phone_number"] = phone;
    }
    return await instance.post("/customsignup/?host=" + getRealhost(), data, {
      headers: {
        "content-type": "application/json",
      },
    });
  }

  const onSubmit = () => {
    if (!firstname || !email || firstname.trim() == "" || email.trim() == "") {
      setOpenSnackBar3(true);
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        setOpenSnackBar4(true);
      } else {
        createProfile()
          .then((res) => {
            handleCloseModalAfterProfileCreated();
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              if (error.response.data.user && error.response.data.user.email) {
                alert(error.response.data.user.email[0]);
              }
              if (error.response.data.phone_number) {
                alert(error.response.data.phone_number[0]);
              }
            }
          });
      }
    }
  };

  const body = (
    <div className={classes.paper}>
      <CloseModalButton onClick={handleCloseModal} />
      <Typography className={classes.createlogindetails}>
        Create Login Details
      </Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.caseId}>Case Id : {dispute.id}</Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.profileType}>
        Profile Type : {getProfileTypeTitleDIFC(profileType)}
      </Typography>
      <Box height="1.5rem"></Box>
      <Grid container justify="space-evenly" spacing={3}>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Name of Representative"
            variant="outlined"
            fullWidth={true}
            value={firstname}
            onChange={onFirstNameChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="phone"
            name="phone"
            type="number"
            label="Phone Number"
            variant="outlined"
            fullWidth={true}
            value={phone}
            onKeyDown={blockInvalidChar}
            onChange={onPhoneChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email ID"
            variant="outlined"
            fullWidth={true}
            value={email}
            onChange={onEmailChange}
          />
        </Grid>
        {profileType === "general_rv" && (
          <Grid item md={5} sm={10} xs={12}>
            <TextField
              id="NameOfOrganization"
              name="NameOfOrganization"
              label="Name of Organization"
              variant="outlined"
              fullWidth={true}
              value={nameOfOrganization}
              onChange={onNameOfOrganizationChange}
            />
          </Grid>
        )}
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="Apartment and Street"
            name="Apartment and Street"
            label="Apartment and Street"
            variant="outlined"
            fullWidth={true}
            value={address}
            onChange={onAddressChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="city"
            name="city"
            label="Сity"
            variant="outlined"
            fullWidth={true}
            value={city}
            onChange={onCityChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="region"
            name="region"
            label="Region and State"
            variant="outlined"
            fullWidth={true}
            value={region}
            onChange={onRegionChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="postal_code"
            name="postal_code"
            label="Postal Code"
            variant="outlined"
            fullWidth={true}
            value={postal_code}
            onChange={onPostalCodeChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <FormControl fullWidth>
            <Select
              label="country"
              labelId="demo-country-select-label"
              id="demo-country-select"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <MenuItem value={"-"}>---</MenuItem>
              {allCountries.map((country) => {
                return (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box height="1rem"></Box>
          <Typography className={classes.text2}>Country</Typography>
        </Grid>
      </Grid>
      <Box height="2rem"></Box>
      <Button
        variant="contained"
        style={{ background: "#2F80ED", color: "#fff" }}
        size="large"
        className={classes.bigbtn}
        onClick={onSubmit}
      >
        Create
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Problem in creating guest profile."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Problem in creating guest profile.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar3}
        onClose={handleCloseSnackBar3}
        message="Please enter all the details."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar3} severity="warning">
          Please enter all the details.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar4}
        onClose={handleCloseSnackBar4}
        message="Please enter valid email address."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar4} severity="error">
          Please enter valid email address.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar5}
        onClose={handleCloseSnackBar5}
        message="Please enter valid phone number."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar5} severity="error">
          Please enter valid phone number.
        </Alert>
      </Snackbar>
    </div>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
