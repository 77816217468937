import React, { useReducer } from "react";
import DetailsForm from "features/singleDisputeRv/DetailsForm/NewDetailsForm";
import UserVerifyForm from "features/singleDisputeRv/UserVerifyForm/NewUserVerifyFormContainer";

const initialState = {
  fetched: false,
  profileDate: {},
  empty: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set":
      return { ...state, fetched: true, profileData: action.payload };
    case "notEmpty":
      return { ...state, empty: false };
  }
};

export default function NewDetails({
  activeDispute,
  caseFileType,
  rvStatus,
  profileType,
  activeCase,
  step,
  tiac,
  profile,
  reload,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fetched, profileData, empty } = state;

  const profileFetched = (data) => {
    dispatch({ type: "set", payload: data });
  };

  const setNotEmpty = () => {
    dispatch({ type: "notEmpty" });
  };

  const formProps = {
    activeDispute,
    caseFileType,
    rvStatus,
    profileData,
    empty,
    profileType,
    activeCase,
    step,
    tiac,
    profile,
  };

  console.log("here", fetched);

  if (fetched) return <DetailsForm {...formProps} />;
  else
    return (
      <UserVerifyForm
        activeDispute={activeDispute}
        activeCase={activeCase}
        profileFetched={profileFetched}
        setNotEmpty={setNotEmpty}
        rvStatus={rvStatus}
        step={step}
        tiac={tiac}
        reload={reload}
      />
    );
}
