import {
Checkbox,
FormControlLabel,
FormGroup,
withStyles,
} from "@material-ui/core";
import React from "react";

const BlueCheckbox = withStyles({
root: {
    color: "grey",
    "&$checked": {
color: "#2F80ED",
    },
},
checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SaveMyProgress = ({checkSaveMyProgressAndResumeLater,handleOnClickCheckBox}) => {
    
return (
    <FormGroup>
        <FormControlLabel
        control={
        <BlueCheckbox
            checked={checkSaveMyProgressAndResumeLater}
            onChange={handleOnClickCheckBox}
            />
        }
        label="Save my progress and resume later"></FormControlLabel>
    </FormGroup>
);
};

export default SaveMyProgress;
