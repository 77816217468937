import history from "app/history";
import BackgroundProvider from "contexts/BackgroundContext";
import FullPageSpinner from "features/common/FullPageSpinner";
import React from "react";
import { useDispatch } from "react-redux";
import { Router } from "react-router-dom";
import Routes from "Routes";
import { isLoggedIn } from "slices/loginSlice";
import { getProfile } from "slices/profileSlice";
import { getUser } from "slices/userSlice";
import "./App.css";
import SnackbarAlert from "features/SnackBar/SnackbarAlert";

function App() {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (isLoggedIn() !== "") {
      dispatch(getUser());
      dispatch(getProfile()).then((res) => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Router history={history}>
      <BackgroundProvider>
        <Routes />
        <SnackbarAlert />
      </BackgroundProvider>
    </Router>
  );
}

export default App;
