import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { RESPONDENT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useDispatch } from "react-redux";
import { isCDR, isHotStar, isDigipub, isNSE, isVikalp, isStar, isMuthoot } from "Routes/Routes";
import { createCase } from "slices/casesSlice";
import { DocGenerator, formatDate, LegalDocGet } from "utils";

export default function RespondentDispute({
  dispute,
  profile,
  classes,
  onClick,
  selectedCase,
  disputeStatus,
  claimantName,
  respondentName,
}) {
  const dispatch = useDispatch();

  const complaintOrDispute =
    isCDR || isVikalp ? "dispute" : isNSE ? "case" : "complaint";

  const actionClick = async (e) => {
    let name = dispute.name;
    let description = dispute.description;

    let createCaseBody = {
      summary: description,
      description: description,
      profile: profile.id,
      case_status: "in_progress",
      dispute: dispute.id,
      type: RESPONDENT,
    };
    await dispatch(createCase(createCaseBody));
  };

  function showViewOrActionForRespondent(selectedCase, actionClick, onClick) {
    if (selectedCase && selectedCase.id) {
      if (selectedCase.case_status === "complete") {
        return (
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ margin: "0.5rem 0" }}
            // style={{ width: "50%" }}
            onClick={async () => {
              LegalDocGet(selectedCase.dispute);
            }}
          >
            Award Download
          </Button>
        );
      } else {
        return (
          <Button
            style={{ margin: "0.5rem 0" }}
            variant="contained"
            color="primary"
            size="large"
            onClick={onClick}
            className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
          >
            <FormattedMessage id="buttons.proceed" />
          </Button>
        );
      }
    } else {
      return (
        <Button
          style={{ margin: "0.5rem 0" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={actionClick}
        >
          <FormattedMessage id="buttons.action" />
        </Button>
      );
    }
  }

  return (
    <Box textAlign="center" display="flex" justifyContent="center">
      <Paper className={classes.item}>
        <Card>
          <CardContent>
            <Box display="flex">
              <FiberManualRecordIcon style={{ color: "#6FCF79" }} />
              <Typography variant="body2">{disputeStatus}</Typography>
              <Box flexGrow={1}></Box>
              <Typography variant="body2">
                {isHotStar || isStar || isMuthoot || isDigipub ? (
                  <FormattedMessage id="dispute.latestGrievance" />
                ) : isNSE ? (
                  "Latest Case"
                ) : (
                  <FormattedMessage id="dispute.latestDispute" />
                )}
              </Typography>
            </Box>
            <Box height="1rem" />

            <Typography
              gutterBottom
              variant="body1"
              className={classes.dispute_heading}
            >
              {claimantName} has registered a {complaintOrDispute} against{" "}
              {respondentName} on {formatDate(dispute.order_date)}
            </Typography>

            <Box height="1rem" />
            <Box display="flex" justifyContent="center">
              <Button
                style={{ margin: "0.5rem 0" }}
                color="primary"
                size="large"
                startIcon={<VisibilityIcon />}
                onClick={() => {
                  DocGenerator(dispute.id, "claimant");
                }}
              >
                {isCDR || isVikalp ? (
                  <FormattedMessage id="dispute.statementOfClaim1" />
                ) : (
                  <FormattedMessage id="dispute.statementOfClaim" />
                )}
              </Button>
            </Box>
            <Box height="1rem"></Box>

            {showViewOrActionForRespondent(selectedCase, actionClick, onClick)}
            <Box width="1rem"></Box>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}
