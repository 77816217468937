import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    makeStyles,
    Menu,
    Paper,
    RadioGroup,
    Typography,
    IconButton,
    Drawer, List, ListItem, ListItemText, Collapse, ListItemIcon,
    Chip,
    useTheme,
    useMediaQuery
  } from "@material-ui/core";
  import { ExpandLess, ExpandMore, Dashboard, Report, CalendarToday, Person, AccessTimeOutlined, ChatBubbleOutline, GroupSharp } from '@material-ui/icons';
  import instance from "api/globalaxios";
  import Axios from "axios";
  import CircularCenteredLoader from "features/common/CircularCenteredLoader";
  import DeletedPopup from "features/common/Popup/DeletedPopup";
  import DonePopup from "features/common/Popup/DonePopup";
  import POPUP from "features/common/Popup/Popup";
  import { StyledRadio } from "features/TempTiacFormV2/ArbitratorPage/questions";
  import debounce from "lodash.debounce";
  import React, { useCallback, useEffect, useRef, useState } from "react";
  import { useDispatch } from "react-redux";
  import { useHistory } from "react-router-dom";
  import { typeChoices } from "../DisputesContainerHotstar";
import { DisputeBox } from "./DisputeBox";
import StarDisputeRow from "./StarDisputeRow";
import { getDisputesForStar, getStarChannelsName, getStarViolationCategory, gethost, bulkResponseStar } from "slices/disputeSlice";
import CustomChip from "./CustomChip";
import CustomModal from "./BulkResponseModal";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

export async function changeDisputeState(disputeId, state) {
  return await instance.get(
    `/changedisputestatehotstar/${disputeId}/${state}/`
  );
}
export async function deleteDisputeapi(disputeId) {
  return await instance.delete(`/deletedisputehotstar/${disputeId}/`);
}

export function isValidDate(dateObject) {
  return new Date(dateObject).toString() !== "Invalid Date";
}
const DISPUTE_TABLE_HEADER = [
  { label: "G. ID", size: 1 },
  { label: "Complainant", size: 2 },
  { label: "Date of Filing", size: 3 },
  { label: "Date of Registration", size: 3 },
  { label: "Actions", size: 2 },
];

const calendarDates= [{id: 'today', name: 'Today'},{id: 'week', name: 'This week'},{id: 'month', name: 'This Month'},{id: 'year', name: 'This Year'},{id: 'custom', name: 'Custom Range'}]

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "95vh",
    background: '#F1F4F9',
    fontFamily: 'Satoshi-Regular',
    [theme.breakpoints.down("sm")]: {
      padding: "3rem 1rem",
    },
  },
  sidebar: {
    background: "#000",
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    padding: "1.5rem 3.5rem 5rem 3rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem",
    },
  },
  griditem: {
    padding: "8px",
    marginBottom: '1rem',
    [theme.breakpoints.down("xs")]: {
      padding: "4px",
    },
  },
  searchbar: {
    border: "none",
    background: "#E9EEF5",
    width: "-webkit-fill-available",
    fontFamily: "Satoshi-Regular",
    color: "#828282",
    fontSize: "20px",
    outline: "none",
    padding: "0rem 1.5rem",
    height: "56px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      height: "42px",
      padding: "0rem 0.7rem",
    },
  },
  spaceBox: {
    height: "2rem",
    [theme.breakpoints.down("sm")]: {
      height: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "1rem",
    },
  },
  titlePaper: {
    padding: "1.5rem 0rem",
    background: '#fff',
    boxShadow: "none",
    width: "100%",
    fontSize: '16px',
    lineHeight: '24',
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  tableHeader: {
    height: '54px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  separateFilter: {
    border: '1px solid black',
    marginTop: '16px',
    marginBottom: '16px'
  },
  typeGrievance: {
    marginBottom: '19px',
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    fontFamily: 'Satoshi-Regular'
  },
  chip: {
    marginLeft: '10px',
    '& .MuiChip-label': {
      fontFamily: 'Satoshi-Bold',
    },
  },
  typeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom : '1rem',
    alignItems: 'center'
  },
  frivolousMove: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 500,
    alignItems: 'center'
  },
  draftBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#F2F2F2',
    fontSize: '16px',
    fontWeight: 500,
    width: '200px',
    height: '38px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  moveFrivolousBtn:{
    marginRight: '34px',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  active: {
    backgroundColor: "red"
  },
  listItemText: {
    color: '#fff'
  },
  listItemIcon: {
    color: '#fff'
  },
  listItemCollapseText: {
    color: '#8A99AF'
  },
  box: {
    position: 'absolute',
    background: 'white',
    border: '1px solid #ccc',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[5],
    zIndex: 1
  },
  channelList: {
    listStyleType: 'none',
    padding: 0,
  },
  channelItem: {
    marginBottom: theme.spacing(1),
  },
}));
  
const SideBar = ({ history, classes, handleBucketing, type }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const getTextClass = (primaryText) => {
    if (type === primaryText) {
      return classes.listItemText;
    }
    return classes.listItemCollapseText;
  };  

  return (
    <Drawer
      variant="permanent"
      anchor="left"
    >
      <Box style={{width: '100%', height: '100vh', background: '#000', paddingTop: '100px'}}>
        <List>
          <ListItem className={classes.listItemIcon} button onClick={handleClick}>
            <ListItemIcon className={classes.listItemIcon} >
              <Dashboard/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"  classes={{ primary: classes.listItemText }} />
            {open ? <ExpandLess sx={{ color: 'white' }}/> : <ExpandMore sx={{ color: 'white' }}/>}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemIcon></ListItemIcon><ListItemText primary="Registered" classes={{ primary: getTextClass('registered') }} onClick={()=>handleBucketing(0)}/>
              </ListItem>
              <ListItem button sx={{ pl: 4 }}>  
                <ListItemIcon></ListItemIcon><ListItemText primary="Serious" classes={{ primary: getTextClass('serious') }} onClick={()=>handleBucketing(2)}/>
              </ListItem>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemIcon></ListItemIcon><ListItemText primary="Frivolous" classes={{ primary: getTextClass('frivolous') }} onClick={()=>handleBucketing(3)}/>
              </ListItem>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemIcon></ListItemIcon><ListItemText primary="Closed" classes={{ primary: getTextClass('closed') }} onClick={()=>handleBucketing(1)}/>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => {history.push('/analytics')}}>
            <ListItemIcon className={classes.listItemIcon}>
              <Report />
            </ListItemIcon>
            <ListItemText primary="Monthly Reports" classes={{ primary: classes.listItemText }}/>
          </ListItem>
          <ListItem button onClick={() => {history.push('/events')}}>
            <ListItemIcon className={classes.listItemIcon}>
              <CalendarToday />
            </ListItemIcon>
            <ListItemText primary="Calendar" classes={{ primary: classes.listItemText }}/>
          </ListItem>
          <ListItem button onClick={() => {history.push('/profile')}}>
            <ListItemIcon className={classes.listItemIcon}>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Profile" classes={{ primary: classes.listItemText }}/>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default function DisputesStar({
    count,
    disputesLoading,
    setType,
    type,
    disputes,
    reload,
    totalPages,
    currentPage,
    setCurrentPage,
    getUserDataAnotherPage,
    setFilters,
    filters,
    profile,
    setTotalPages
  }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [disputesBySearch, setDisputesBySearch] = useState(disputes);
    const [selectedDisputes, setSelectedDisputes] = useState([]);
    const [cancelToken, setCancelToken] = useState();
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [channels, setChannels] = useState([]);
    const [violationCategoryList, setViolationCategoryList] = useState([])
    const [openSeriousFrivolousPopup,setOpenSeriousFrivolousPopup] = useState(false);
    const [openSeriousFrivolousGifPopup, setOpenSeriousFrivolousGifPopup] = useState(false);
    const [selectedComponent,setSelectedComponent] = useState('Dashboard');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const debouceSearch = useCallback(debounce(search, 500), [disputes]);

    const DISPUTES_COUNT = [
      {
        name: 'Registered',
        count: count?.registered?.registered
      },
      {
        name: 'Closed',
        count: count?.registered?.closed
      },
      {
        name: 'Serious',
        count: count?.received?.serious
      },
      {
        name: 'Frivolous',
        count: count?.received?.frivolous
      }
    ]

    useEffect(() => {
      dispatch(getStarChannelsName()).then((res) => {
        setChannels(res?.channels);
      });
      dispatch(getStarViolationCategory()).then((res) => {
        setViolationCategoryList(res?.results);
      });
    }, []);

    useEffect(() => {
      setDisputesBySearch(disputes);
    }, [disputes]);
    
    const handleChangeFilter = (field, value) => {
      setFilters(prevFilters => {
        if (field === 'tags') {
          return {
            ...prevFilters,
            [field]: [...prevFilters[field], { id: value.id, name: value.name }]
          };
        } else {
          return {
            ...prevFilters,
            [field]: value
          };
        }
      });
    };

    const handleDeleteFilter = (key, tag) => {
      setFilters(prevFilters => {
        if (key === 'tags') {
          return {
            ...prevFilters,
            tags: prevFilters.tags.filter(t => t !== tag)
          };
        } else {
          return {
            ...prevFilters,
            [key]: ""
          };
        }
      });
    };    

    function search(value, type) {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      if (value.trim() != "") {
        let ar = value.trim().split("-");
        let date = new Date(ar[1] + "/" + ar[0] + "/" + ar[2]);
        if (isValidDate(date)) {
          handleChangeFilter('start_date', value)
          setLoadingSearch(false);
        } else {
          handleChangeFilter('claimant_name', value.trim());
          setLoadingSearch(false);
        }
      } else {
        setDisputesBySearch(disputes);
        setLoadingSearch(true);
      }
    }

    const updateDisputeByFilter = async (filters) => {
      const queryParams = new URLSearchParams();
      Object.keys(filters).forEach(key => {
        if (key === 'tags') {
          if (filters[key].length > 0) {
            filters[key].forEach(tag => queryParams.append('tags', tag.id));
          }
        } else if (filters[key] !== "" && filters[key] !== undefined) {
          queryParams.append(key, filters[key]);
        }
      });
      queryParams.append('host', gethost());
      queryParams.append('page', currentPage); 
      queryParams.append('dispute_type', type);
      try {
        const response = await instance.get(`/disputelist/?${queryParams.toString()}`);
        let count = response?.data?.count;
        let t = parseInt(count / 10);
        let r = count % 10;
        let total = t + (r > 0 ? 1 : 0);
        setTotalPages(total);
        setDisputesBySearch(response?.data?.results);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };

    // Use effect to call the API whenever filters change
    useEffect(() => {
      updateDisputeByFilter(filters);
    }, [filters]);

    const DisputeTableHeader = () => {
      const classes = useStyles();
    
      return (
        <Grid container style={{height:'54px'}} className={classes.tableHeader}>
          {(type === "serious" || type === "frivolous") && (
            <Grid item sm={1} style={{textAlign: 'center'}}>
            </Grid>
        )}
          {DISPUTE_TABLE_HEADER.map((header, index) => (
            <Grid item xs sm={header.size} key={index} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography className={classes.title}>
                {header.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      );
    };

    const handleBucketing = (typeIndex) => {
      setCurrentPage(1);
      setType(typeChoices[typeIndex]);
      setSelectedDisputes([]);
      setSearchText("");
      sessionStorage.setItem("hotstar-type", typeChoices[typeIndex]);
      reload(typeChoices[typeIndex]);
    };

    const addDispute = (id) => {
      let res = [];
      selectedDisputes.forEach((i) => {
        res.push(i);
      });
      res.push(id);
      setSelectedDisputes(res);
    };

    const removeDispute = (id) => {
      let res = [];
      selectedDisputes.forEach((i) => {
        res.push(i);
      });
      let index = res.indexOf(id);
      if (index > -1) {
        res.splice(index, 1);
      }
      setSelectedDisputes(res);
    };

    const clearAllFilters = () => {
      setFilters({
        dispute_type: '',
        start_date: '',
        end_date: '',
        time_frame: '',
        claimant_name: '',
        tags: '',
        channel_name: '',
        show_name: ''
      });
    };

    const handleSeriousFrivolousPopup = (type) => {
      setOpenSeriousFrivolousPopup(!openSeriousFrivolousPopup)
    }

    const handleOpenSeriousFrivolousGifPopup = () => {
      setOpenSeriousFrivolousGifPopup(!openSeriousFrivolousGifPopup)
    }

    const handleFrivolous = () => {
      setOpenSeriousFrivolousPopup(false)
      let total = selectedDisputes.length;
      let count = 0;
      selectedDisputes.forEach((disputeid) => {
        changeDisputeState(disputeid, type == "serious" ? "frivolous" : "serious")
          .then((res) => {
            count += 1;
            if (count == total) {
              reload();
              setSelectedDisputes([]);
              handleOpenSeriousFrivolousGifPopup();
            }
          })
          .catch((error) => {
            console.log("error.response.data", error.response.data);
            if (error?.response?.data?.status == "not done") {
              alert("This dispute is already frivolous: " + disputeid);
            }
          });
      });
      if (count == total) handleOpenSeriousFrivolousGifPopup();
    };

    const hasActiveFilters = Object.values(filters).some(value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== undefined && value !== '';
    });

    // const renderSelectedComponent = () => {
    //   switch (selectedComponent) {
    //     case 'Dashboard':
    //       return <Dashboard />;
    //     case 'DisputePage': 
    //       return <SingleDispute/>
    //     default:
    //       return <Dashboard />;
    //   }
    // };

    const renderDateRangeChip = () => {
      if (filters.start_date && filters.end_date) {
        return (
          <Chip
            key="date-range"
            onDelete={() => {
              handleDeleteFilter('start_date');
              handleDeleteFilter('end_date');
            }}
            label={`${filters.start_date} - ${filters.end_date}`}
            variant="outlined"
            className={classes.chip}
          />
        );
      }
      return null;
    };

    const handleBulkResponse = (selectedDisputes) => {
      setIsModalOpen(true);
    }

    const handleCloseModal = () => {
      setIsModalOpen(false);
      setMessage('');
    };

    const handleSubmitBulkResponse = async () => {
      const disputeBody = {
        disputes: selectedDisputes,
        answer: message,
      };
  
      try {
        const responseId = await dispatch(bulkResponseStar(disputeBody));
        console.log(`Response ID: ${responseId}`);
        handleCloseModal();
        setOpenSeriousFrivolousGifPopup(!openSeriousFrivolousGifPopup);
        setSelectedDisputes([])
      } catch (error) {
        console.error('Failed to submit bulk response', error);
      }
    };
  
    return(
      <>
        <Grid container className={classes.root}>
          <Grid item md={2} xs={12}>
            {!isMobile && <SideBar history={history} classes={classes} handleBucketing={handleBucketing} type={type}/>}
          </Grid>
          <Grid item md={10} xs={12} className={classes.content}>
            <Grid container spacing={3}>
              <Grid
                item
                xl={5}
                lg={10}
                md={9}
                sm={12}
                xs={12}
                className={classes.griditem}
              >
                <input
                  type="text"
                  placeholder="Search by Complainant's Name, Date (DD-MM-YYYY)"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    debouceSearch(e.target.value, type);
                  }}
                  onBlur={(e) => {
                    e.target.placeholder =
                      "Search by Complainant's Name, Date (DD-MM-YYYY)";
                  }}
                  className={classes.searchbar}
                  onFocus={(e) => (e.target.placeholder = "")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {DISPUTES_COUNT.map((disp, index) => {
                return(
                  <DisputeBox
                    count={disp.count}
                    headingText={disp.name}
                    handleClick={() => handleBucketing(index)}
                    key={index}
                  />
                )
              })}
            </Grid>
            <Box className={classes.spaceBox}></Box>
            <Grid container>
              <CustomChip icon={<AccessTimeOutlined/>} label="Recieved at" boxValues={calendarDates} filterName={'time_frame'} handleChangeFilter={handleChangeFilter} isObjectArray={true}/>
              <CustomChip icon={<AccessTimeOutlined/>} label="Channel" boxValues={channels} filterName={'channel_name'} handleChangeFilter={handleChangeFilter} isObjectArray={false}/>
              <CustomChip icon={<Person/>} label="Violation Category" boxValues={violationCategoryList} filterName={'tags'} handleChangeFilter={handleChangeFilter} isObjectArray={true}/>
              <CustomChip icon={<GroupSharp/>} label="Sent to groups" />
            </Grid>
            <div className={classes.separateFilter}></div>
            <Grid container>
              {Object.keys(filters)?.map((key) => {
                if (key === 'tags' && filters[key].length > 0) {
                  return filters[key].map((tag, index) => (
                    <Chip
                      key={`${key}-${tag}-${index}`}
                      onDelete={() => handleDeleteFilter(key, tag)}
                      label={tag.name}
                      variant="outlined"
                      className={classes.chip}
                    />
                  ));
                }else if (filters[key] && key !== 'tags' && key !== 'start_date' && key !== 'end_date') {
                  return (
                    <Chip
                      key={key}
                      onDelete={() => handleDeleteFilter(key)}
                      label={filters[key]}
                      variant="outlined"
                      className={classes.chip}
                    />
                  );
                }
                return null;
              })}
              {renderDateRangeChip()}
              {hasActiveFilters && (
                <Chip
                  label="Clear All"
                  onClick={clearAllFilters}
                  variant="outlined"
                  style={{ backgroundColor: '#050505', color: 'white' }} 
                  className={classes.chip}
                />
              )}
            </Grid>
            <Box className={classes.spaceBox} />
              {disputesLoading ? 
                  <CircularCenteredLoader/>
                  : 
                  <>
                    <Grid container className={classes.typeWrapper}>
                      <Typography className={classes.typeGrievance}>{type} Grievances</Typography>
                      {selectedDisputes.length > 0 && (type == "serious" || type == "frivolous" || type == 'registered') ? (
                        <div className={classes.frivolousMove}>
                         {type != 'registered' &&
                          <Typography 
                            onClick={()=>handleSeriousFrivolousPopup(type)}
                            className={classes.moveFrivolousBtn}>
                              {`Move to ${type == "serious" ? "frivolous" : "serious"}`}
                          </Typography>}
                          {profile?.profile?.profile_type === 'vendor' && <div className={classes.draftBtn} onClick={()=>handleBulkResponse(selectedDisputes)}>Draft bulk response</div>}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid container>
                      <Paper className={classes.titlePaper} square>
                        <Grid spacing={2} justifyContent="center" style={{background: '#F7F9FC'}}>
                          <DisputeTableHeader/>
                        </Grid>
                      </Paper>
                      {disputesBySearch.length !== 0 ? (
                        disputesBySearch.map((dispute) => {
                          return (
                              <StarDisputeRow
                                id={dispute.id}
                                name={dispute.name}
                                filingDate={dispute.created}
                                registrationDate={dispute.approved_date}
                                tags={dispute.tags}
                                otherTags={dispute.other_tags}
                                type={type}
                                history={history}
                                addDispute={addDispute}
                                removeDispute={removeDispute}
                                reload={reload}
                                setSelectedComponent={setSelectedComponent}
                              />
                          );
                        })
                      ):(
                        <Paper className={classes.titlePaper} square>
                          <Grid container style={{justifyContent: 'center'}}>
                              <Grid item>
                                <Box textAlign="center">
                                  <Typography
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "initial",
                                    }}
                                  >
                                    Dispute does not exist.
                                  </Typography>
                                </Box>
                              </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </Grid>
                    <Box height="10px"></Box>
                    {disputesBySearch.length !== 0 && !searchText && (
                      <Grid container justify="flex-end">
                        <Grid item>
                          <Typography>
                            <IconButton
                              disabled={currentPage == 1}
                              onClick={(e) => {
                                setCurrentPage(currentPage - 1);
                                getUserDataAnotherPage(currentPage - 1);
                              }}
                            >
                              <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                            </IconButton>
                            {`${currentPage} / ${totalPages}`}
                            <IconButton
                              disabled={currentPage >= totalPages}
                              onClick={(e) => {
                                setCurrentPage(currentPage + 1);
                                getUserDataAnotherPage(currentPage + 1);
                              }}
                            >
                              <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                            </IconButton>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                }
          </Grid>
        </Grid>
        <POPUP
          opener={openSeriousFrivolousPopup}
          onClose={()=>setOpenSeriousFrivolousPopup(false)}
          heading="Move grievances"
          message="Are you sure you would like to move the selected grievance(s) to frivolous?"
          mainButton="MOVE"
          onMainButton={handleFrivolous}
        />
        <DonePopup
          opener={openSeriousFrivolousGifPopup}
          onClose={()=>{setOpenSeriousFrivolousGifPopup(false)}}
          text="Updated"
        />
        <CustomModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          selectedDisputes={selectedDisputes}
          message={message}
          setMessage={setMessage}
          onSubmit = {handleSubmitBulkResponse}
        />
      </>
    )
}