import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import FormattedMessage from "features/common/TranslatedMessage";

import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import instance from "api/globalaxios";
import { Tiaccreateprofile } from "slices/userSlice";
import Axios from "axios";
import { getCountries } from "slices/profileSlice";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { updateDisputeTiac } from "features/TempTiacForm/UploadPage/questions";
// import "intro.js/introjs.css";

function checkIfNumber(event) {
  const regex = new RegExp(
    /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
  );

  return !event.key.match(regex) && event.preventDefault();
}
export const blockInvalidChar = (e) => checkIfNumber(e);

export const StyledTextFieldTiac = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2F80ED",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#2F80ED",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // "font-size": "18px",
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    color: "#2C3345",
    padding: "1rem",
    fontFamily: `'Inter',sans-serif`,
  },

  text2: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },
  text3: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

const SignupSchemaRequired = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  // phone_number: Yup.string()
  // .required("phone number is requiredd"),
  //   .min(10, "Invalid phone number")
  //   .max(10, "Invalid phone number"),
  full_name: Yup.string().required("Full name is required"),
  nationality: Yup.string().required("Nationality is required"),
  // lastName: Yup.string().required("Last name is required"),
  address: Yup.string().required("Apartment and Street is required"),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("Region and State is required"),
  postal_code: Yup.string().required(" Postal code is required"),
  phone_number: Yup.string().required(" Phone Number is required"),
});
const SignupSchemaNotRequired = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  full_name: Yup.string().required("Full name is required"),
  nationality: Yup.string().required("Nationality is required"),
  address: Yup.string().required("Apartment and Street is required"),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("Region and State is required"),
  postal_code: Yup.string().required(" Postal code is required"),
  phone_number: Yup.string().required(" Phone Number is required"),
});

var signUpData = {};

export async function getProfileTiacDispute(email, dispute_id, step) {
  return await instance.get(
    `/tiaccgetprofileofdispute/?email=${email}&id=${dispute_id}&step=${step}`
  );
}
export async function sendEmailSaveAsDraft(email) {
  return await instance.get(`/sendtemporarypasswordtiac/${email}/`);
}
export async function getTempDetails(dispute_id) {
  return await instance.get(`/tiactempdetails/?dispute_id=${dispute_id}`);
}

export async function saveTempDetailsClaimant(data) {
  return await instance.post(`/tiacclaimantdetails/`, {
    dispute_id: data.dispute_id,
    email: data.email,
    full_name: data.full_name,
    nationality: data.nationality,
    address: data.address,
    city: data.city,
    region: data.region,
    postal_code: data.postal_code,
    country: data.country,
    phone_number: data.phone_number,
    fax: data.fax,
  });
}

export default function ClaimantPage({ proceed, setProceed }) {
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();

  function autoFill(fetchedProfile) {
    formik.setFieldTouched("email", false);
    formik.setFieldTouched("full_name", false);
    formik.setFieldTouched("nationality", false);
    formik.setFieldTouched("phone_number", false);
    formik.setFieldTouched("address", false);
    formik.setFieldTouched("city", false);
    formik.setFieldTouched("region", false);
    formik.setFieldTouched("postal_code", false);
    formik.setFieldValue("email", fetchedProfile.user.email);
    formik.setFieldValue(
      "full_name",
      fetchedProfile.name
        ? fetchedProfile.name
        : fetchedProfile.user.first_name + " " + fetchedProfile.user.last_name
    );
    formik.setFieldValue(
      "nationality",
      fetchedProfile.nationality ? fetchedProfile.nationality : ""
    );
    formik.setFieldValue(
      "phone_number",
      fetchedProfile.phone_number ? fetchedProfile.phone_number : ""
    );
    formik.setFieldValue("fax", fetchedProfile.fax ? fetchedProfile.fax : "");
    formik.setFieldValue(
      "address",
      fetchedProfile.address ? fetchedProfile.address : ""
    );
    formik.setFieldValue(
      "city",
      fetchedProfile.city ? fetchedProfile.city : ""
    );
    formik.setFieldValue(
      "region",
      fetchedProfile.region ? fetchedProfile.region : ""
    );
    formik.setFieldValue(
      "postal_code",
      fetchedProfile.postal_code ? fetchedProfile.postal_code : ""
    );
    setCountry(fetchedProfile.country ? fetchedProfile.country : "-");
    setcountryError();
    setFetchedProfile();
  }

  function getProfiles(email, cancelToken) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/tiacanonymousemailautofill/?email=${email}`,
          { cancelToken: cancelToken.token }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const handleEmailChange = (email) => {
    try {
      // let email = e.traget.value;
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email &&
          res.results[0].profile_type == "general_rv"
        ) {
          setFetchedProfile(res.results[0]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [allCountries, setallCountries] = useState([]);
  const [country, setCountry] = useState("-");
  const [countryError, setcountryError] = useState();

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);
  let id = localStorage.getItem("dispute_id");
  let localstorageEmail = localStorage.getItem("email");
  useEffect(() => {
    console.log({ localstorageEmail });
    if (localstorageEmail && !id) {
      formik.setFieldValue("email", localstorageEmail);
      handleEmailChange(localstorageEmail);
    }
  }, [id, localstorageEmail]);
  const profile = useSelector((state) => state.profile, shallowEqual);

  const [dispute, setDispute] = useState();

  const [isSecondStep, setIsSecondStep] = useState(false);

  const [isBackButttonDisabled, setIsBackButtonDisabled] = useState(
    location.pathname.toUpperCase() != "/RFA"
  );

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  useEffect(() => {
    if (id)
      getTempDetails(id).then((res) => {
        let fetchTempProfile = {};
        if (res && res.data) {
          if (res.data.claimant_email) {
            fetchTempProfile.user = { email: res.data.claimant_email };
          }
          if (res.data.claimant_name) {
            fetchTempProfile.name = res.data.claimant_name;
          }
          if (res.data.claimant_nationality) {
            fetchTempProfile.nationality = res.data.claimant_nationality;
          }
          if (res.data.claimant_phone_number) {
            fetchTempProfile.phone_number = res.data.claimant_phone_number;
          }
          if (res.data.claimant_fax) {
            fetchTempProfile.fax = res.data.claimant_fax;
          }
          if (res.data.claimant_address) {
            fetchTempProfile.address = res.data.claimant_address;
          }
          if (res.data.claimant_city) {
            fetchTempProfile.city = res.data.claimant_city;
          }
          if (res.data.claimant_region) {
            fetchTempProfile.region = res.data.claimant_region;
          }
          if (res.data.claimant_postal_code) {
            fetchTempProfile.postal_code = res.data.claimant_postal_code;
          }
          if (res.data.claimant_country) {
            fetchTempProfile.country = res.data.claimant_country;
          }
          if (fetchTempProfile.user) autoFill(fetchTempProfile);
        }
      });
  }, [id]);

  React.useEffect(() => {
    if (!proceed) {
      getDisputeTiac(localstorageEmail, id)
        .then((res) => {
          if (res.data.results[0].id) {
            setDispute(res.data.results[0]);
            if (res.data.results[0].created_by.profile_type == "lawyer_rv") {
              setIsSecondStep(true);
              setIsBackButtonDisabled(false);
            }
            // if (res.data.results[0].rv_claimant_choose_step_complete)
            getProfileTiacDispute(localstorageEmail, id, "claimant").then(
              (res) => {
                if (res.data.user) {
                  setFetchedProfile(res.data);
                  autoFill(res.data);
                }
              }
            );
          } else history.push("/RFA");
        })
        .catch((e) => {
          history.push("/RFA");
        });
    }
    if (id && proceed)
      getProfileTiacDispute(localstorageEmail, id, "claimant").then((res) => {
        setFetchedProfile(res.data);
        autoFill(res.data);
      });
  }, []);

  async function TiaccreateprofileDisputeId() {
    let data = {
      email: formik.values.email,
      profile_type: "general_rv",
      for: "claimant",
      dispute_id: id,
    };
    if (formik.values.full_name.trim() != "") {
      data.full_name = formik.values.full_name;
    }
    if (formik.values.nationality.trim() != "") {
      data.nationality = formik.values.nationality;
    }
    if (formik.values.address.trim() != "") {
      data.address = formik.values.address;
    }
    if (formik.values.city.trim() != "") {
      data.city = formik.values.city;
    }
    if (formik.values.region.trim() != "") {
      data.region = formik.values.region;
    }
    if (formik.values.postal_code.trim() != "") {
      data.postal_code = formik.values.postal_code;
    }
    if (country && country != "-") {
      data.country = country;
    } else {
      data.country = null;
    }
    let ph = "" + formik.values.phone_number;
    if (ph.trim() != "") {
      data.phone_number = ph;
    }
    if (formik.values.fax.trim() != "") {
      data.fax = formik.values.fax;
    }
    return await instance.post("/tiaccreateprofile/", data);
  }

  const formik = useFormik({
    initialValues: {
      full_name: "",
      nationality: "",
      address: "",
      city: "",
      region: "",
      postal_code: "",
      phone_number: "",
      email: "",
      fax: "",
    },

    validationSchema: proceed ? SignupSchemaRequired : SignupSchemaNotRequired,

    onSubmit: async (values) => {
      let {
        full_name,
        nationality,
        address,
        city,
        region,
        postal_code,
        phone_number,
        email,
        fax,
      } = values;

      signUpData = values;

      if (country == "-") {
        setcountryError("Country is required");
      } else {
        signUpData["country"] = country;

        if (!proceed) {
          let tempData = {
            dispute_id: id,
            email: email,
            full_name: full_name,
            nationality: nationality,
            address: address,
            city: city,
            region: region,
            postal_code: postal_code,
            country: country,
            phone_number: phone_number,
            fax: fax,
          };
          updateDisputeTiac(
            {
              id: id,
              rv_claimant_choose_step_complete: true,
            },
            localstorageEmail
          );
          saveTempDetailsClaimant(tempData).then(() => {
            history.push("/tiac_respondent");
          });
        } else if (location.pathname == "/old_tiac_form") {
          TiaccreateprofileDisputeId()
            .then((res) => {
              let tempData = {
                dispute_id: res.data.id,
                email: email,
                full_name: full_name,
                nationality: nationality,
                address: address,
                city: city,
                region: region,
                postal_code: postal_code,
                country: country,
                phone_number: phone_number,
                fax: fax,
              };
              updateDisputeTiac(
                {
                  id: res.data.id,
                  rv_claimant_choose_step_complete: true,
                },
                localstorageEmail
              );
              saveTempDetailsClaimant(tempData).then(() => {
                history.push("/tiac_claimant_lawyer");
              });
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        } else {
          signUpData["phone_number"] = "" + signUpData.phone_number;
          signUpData["address"] = "" + signUpData.address;
          signUpData["city"] = "" + signUpData.city;
          signUpData["region"] = "" + signUpData.region;
          signUpData["postal_code"] = "" + signUpData.postal_code;
          Tiaccreateprofile(signUpData, "general_rv")
            .then((res) => {
              let tempData = {
                dispute_id: res.data.id,
                email: email,
                full_name: full_name,
                nationality: nationality,
                address: address,
                city: city,
                region: region,
                postal_code: postal_code,
                country: country,
                phone_number: phone_number,
                fax: fax,
              };
              updateDisputeTiac(
                {
                  id: res.data.id,
                  rv_claimant_choose_step_complete: true,
                },
                localstorageEmail
              );
              saveTempDetailsClaimant(tempData).then(() => {
                localStorage.setItem("email", email);
                localStorage.setItem("dispute_id", res.data.id);
                localStorage.setItem("full_name", full_name);
                localStorage.setItem("phone_number", phone_number);
                history.push("/tiac_claimant_lawyer");
              });
            })
            .catch((error) => {
              console.log("er", error.response);
              alert(error.response.data.message);
            });
        }
      }
    },
  });

  const handleOnBack = () => {
    if (proceed) {
      setProceed(false);
    } else {
      if (isSecondStep) {
        history.push("/old_tiac_form");
      }
    }
  };

  async function handleSaveAsDraft(values) {
    if (proceed) {
      let {
        full_name,
        nationality,
        address,
        city,
        region,
        postal_code,
        phone_number,
        email,
        fax,
      } = values;

      signUpData = values;

      if (location.pathname == "/old_tiac_form") {
        TiaccreateprofileDisputeId()
          .then((res) => {
            let tempData = {
              dispute_id: res.data.id,
              email: email,
              full_name: full_name,
              nationality: nationality,
              address: address,
              city: city,
              region: region,
              postal_code: postal_code,
              country: country,
              phone_number: phone_number,
              fax: fax,
            };
            saveTempDetailsClaimant(tempData);
            alert("saved");
            updateDisputeTiac(
              {
                id: res.data.id,
                rv_claimant_choose_step_complete: false,
              },
              localstorageEmail
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      } else {
        signUpData["phone_number"] = "" + signUpData.phone_number;
        signUpData["address"] = "" + signUpData.address;
        signUpData["city"] = "" + signUpData.city;
        signUpData["region"] = "" + signUpData.region;
        signUpData["postal_code"] = "" + signUpData.postal_code;
        Tiaccreateprofile(signUpData, "general_rv")
          .then((res) => {
            let tempData = {
              dispute_id: res.data.id,
              email: email,
              full_name: full_name,
              nationality: nationality,
              address: address,
              city: city,
              region: region,
              postal_code: postal_code,
              country: country,
              phone_number: phone_number,
              fax: fax,
            };
            saveTempDetailsClaimant(tempData).then(() => {
              history.push("/old_tiac_form");
            });
            alert("saved");

            localStorage.setItem("email", email);
            localStorage.setItem("dispute_id", res.data.id);
            localStorage.setItem("full_name", full_name);
            localStorage.setItem("phone_number", phone_number);
            updateDisputeTiac(
              {
                id: res.data.id,
                rv_claimant_choose_step_complete: false,
              },
              localstorageEmail
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })

          .catch((error) => {
            console.log("er", error.response);
            alert(error.response.data.message);
          });
        // }
      }
    } else {
      if (formik.errors.email) {
        // alert("Please enter email.");
        alert("saved");
      } else {
        let {
          full_name,
          nationality,
          address,
          city,
          region,
          postal_code,
          phone_number,
          email,
          fax,
        } = values;
        let tempData = {
          dispute_id: id,
          email: email,
          full_name: full_name,
          nationality: nationality,
          address: address,
          city: city,
          region: region,
          postal_code: postal_code,
          country: country,
          phone_number: phone_number,
          fax: fax,
        };
        saveTempDetailsClaimant(tempData);
        alert("saved");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  }

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10} md={8}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" className={classes.text}>
                {proceed ? (
                  <FormattedMessage id="tiac.heading1" />
                ) : (
                  <FormattedMessage id="tiac.heading1_" />
                )}
              </Typography>
              <Box height="1rem"></Box>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} justify="flex-start">
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      {proceed ? "1" : "2"}.1. Email address/Адрес электронной
                      почты/Электрон почта манзили:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.email}
                        fullWidth
                        id="email"
                        autoFocus
                        name="email"
                        autoComplete="email"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleEmailChange(e.target.value);
                        }}
                        disabled={
                          (localstorageEmail && !id) ||
                          location.pathname == "/old_tiac_form"
                        }
                      />
                      {formik.errors.email && formik.touched.email && (
                        <Alert severity="error">{formik.errors.email}</Alert>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      {fetchedProfile && (
                        <Typography
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            autoFill(fetchedProfile);
                          }}
                        >
                          Autofill details
                        </Typography>
                      )}
                    </Grid>
                    <Box height="1rem"></Box>

                    <Typography className={classes.text2}>
                      example@example.com
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      {proceed ? "1" : "2"}.2. Full name/Название организации
                      (Ф.И.О. если истец физическое лицо)/Тўлиқ номи (Ф.И.Ш.
                      агар даъвогар жисмоний шахс бўлса):{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.full_name}
                        fullWidth
                        id="full_name"
                        // label={<FormattedMessage id="user.email" />}
                        name="full_name"
                        autoComplete="full_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.full_name && formik.touched.full_name && (
                        <Alert severity="error">
                          {formik.errors.full_name}
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem" }}
                  >
                    <Typography className={classes.text1}>
                      {proceed ? "1" : "2"}.3. Nationality/страна регистрации
                      организации (гражданство если истец физическое
                      лицо)/Ташкилот рўйхатдан ўтган давлат (фуқаролик агар
                      даъвогар жисмоний шахс бўлса):{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.nationality}
                        fullWidth
                        id="nationality"
                        // label={<FormattedMessage id="user.email" />}
                        name="nationality"
                        autoComplete="nationality"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.nationality &&
                        formik.touched.nationality && (
                          <Alert severity="error">
                            {formik.errors.nationality}
                          </Alert>
                        )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem" }}
                  >
                    <Typography className={classes.text1}>
                      {proceed ? "1" : "2"}.4. Address/Адрес/Манзил:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        // required
                        // variant="outlined"
                        // fullWidth={true}
                        // value={formik.address}
                        // onBlur={formik.handleBlur}
                        // onChange={formik.handleChange}
                        variant="outlined"
                        margin="normal"
                        value={formik.values.address}
                        fullWidth
                        id="address"
                        // label={<FormattedMessage id="user.email" />}
                        name="address"
                        autoComplete="address"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.address && formik.touched.address && (
                        <Alert severity="error">{formik.errors.address}</Alert>
                      )}
                    </Grid>
                    <Typography className={classes.text2}>
                      Apartment and Street/Название дома и улицы/Ҳонадон ва кўча
                      номи
                    </Typography>
                    <Grid container style={{ padding: "0 1rem" }}>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingRight: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          variant="outlined"
                          margin="normal"
                          value={formik.values.city}
                          fullWidth
                          id="city"
                          // label={<FormattedMessage id="user.email" />}
                          name="city"
                          autoComplete="city"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.city && formik.touched.city && (
                          <Alert severity="error">{formik.errors.city}</Alert>
                        )}
                        <Typography className={classes.text3}>
                          Сity (Town)/Город/Шаҳар
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingLeft: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          variant="outlined"
                          margin="normal"
                          value={formik.values.region}
                          fullWidth
                          id="region"
                          // label={<FormattedMessage id="user.email" />}
                          name="region"
                          autoComplete="region"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.region && formik.touched.region && (
                          <Alert severity="error">{formik.errors.region}</Alert>
                        )}
                        <Typography className={classes.text3}>
                          Region and State/Название региона(области) и
                          страны/Вилоят ва мамлакат номи
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.postal_code}
                        fullWidth
                        id="postal_code"
                        // label={<FormattedMessage id="user.email" />}
                        name="postal_code"
                        autoComplete="postal_code"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.postal_code &&
                        formik.touched.postal_code && (
                          <Alert severity="error">
                            {formik.errors.postal_code}
                          </Alert>
                        )}
                    </Grid>
                    {/* <Box height="1rem"></Box> */}
                    <Typography className={classes.text2}>
                      Postal code/Почтовый индекс/Почта индекси
                    </Typography>
                    <Box height="2rem"></Box>
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={12}
                      style={{ padding: "1rem" }}
                    >
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-country-select-label"
                          id="demo-country-select"
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            if (e.target.value == "-") {
                              setcountryError("Country is required");
                            } else {
                              setcountryError();
                            }
                          }}
                        >
                          <MenuItem value={"-"}>---</MenuItem>
                          {allCountries.map((country) => {
                            return (
                              <MenuItem key={country.id} value={country.id}>
                                {country.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {countryError && (
                        <Alert severity="error">{countryError}</Alert>
                      )}
                    </Grid>
                    {/* <Box height="1rem"></Box> */}
                    <Typography className={classes.text2}>
                      Country/Страна/Мамлакат
                    </Typography>
                  </Grid>
                  <Box height="2rem" />
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem", marginTop: "1.5rem" }}
                  >
                    <Typography className={classes.text1}>
                      {proceed ? "1" : "2"}.5. Telephone number/Номер
                      телефона/Телефон рақами{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <Grid item md={5} sm={10} xs={12}>
                        <StyledTextFieldTiac
                          variant="outlined"
                          margin="normal"
                          value={formik.values.phone_number}
                          type="number"
                          fullWidth
                          id="phone_number"
                          // label="(000) 000-0000"
                          // label={<FormattedMessage id="user.email" />}
                          name="phone_number"
                          autoComplete="phone_number"
                          onKeyDown={blockInvalidChar}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.phone_number &&
                          formik.touched.phone_number && (
                            <Alert severity="error">
                              {formik.errors.phone_number}
                            </Alert>
                          )}
                        <Box height="1rem"></Box>
                      </Grid>

                      <Typography className={classes.text3}>
                        Please enter only numerals and a valid contact
                        number/Пожалуйста, введите номер с кодом и только
                        цифры/Илтимос, телефон рақамни код ва фақат рақамлар
                        билан киритинг
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem", marginTop: "1.5rem" }}
                  >
                    <Typography className={classes.text1}>
                      {proceed ? "1" : "2"}.6. Fax/Факс/Факс
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.fax}
                        fullWidth
                        id="fax"
                        // label={<FormattedMessage id="user.email" />}
                        name="fax"
                        autoComplete="fax"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box height="4rem"></Box>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleOnBack}
                      // className={classes.guestbtn}
                      disabled={isBackButttonDisabled}
                    >
                      <Typography className={classes.btnTextBlack}>
                        Back
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    {/* {proceed && location.pathname != "/old_tiac_form" ? (
                      <Box display="flex" justifyContent="center">
                        <OtpButton></OtpButton>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="center">
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ background: "#2F80ED" }}
                          // className={classes.guestbtn}
                        >
                          <Typography className={classes.btnTextWhite}>
                            <FormattedMessage id="buttons.proceed" />
                          </Typography>
                        </Button>
                      </Box>
                    )} */}
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ background: "#2F80ED" }}
                        onClick={() => {
                          if (country == "-")
                            setcountryError("Country is required");
                        }}
                      >
                        <Typography className={classes.btnTextWhite}>
                          <FormattedMessage id="buttons.proceed" />
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleSaveAsDraft(formik.values);
                      }}
                      // disabled={proceed}
                    >
                      <Typography className={classes.btnTextBlack}>
                        Save as draft
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
}
