import { makeStyles } from "@material-ui/core";
import RVAdminDIFC from "Admin/DIFC/RVTiac/RVAdminDifc";
import RVAdmin from "Admin/RV/RVAdmin";
import RVAdminTiac from "Admin/RVTiac/RVAdminTiac";
import SebiAdmin from "Admin/Sebi/SebiAdmin";
import Admin from "Admin/SubAdmin";
import SuperAdmin from "Admin/SuperAdmin";
import DataAnalyticsContainer from "DataAnalytics/DataAnalyticsContainer";
import ContactUs from "RvHomePage/ContactUs";
import ContactUsRv from "RvHomePage/ContactUsRv";
import RvHomePage from "RvHomePage/RvHomePage";
import HomePageTiac from "RvHomePage_V2/RvHomePage";
import {
  ARBITRATOR,
  ARBITRATOR_RV,
  ARB_URL,
  BARANDBENCH_URL,
  CDR_URL,
  DEV_HOTSTAR_URL,
  DEV_WEBNYAY_URL,
  DIGIPUB_URL,
  HOTSTAR_URL,
  MEDIATOR,
  MOOT_URL,
  NBF_URL,
  KILUMAYA_URL,
  NSE_URL,
  SEBI_URL,
  SECRETARIAT,
  TIAC_URL,
  TIAC_URL_STATIC,
  VIACOM_URL,
  VIKALP_URL,
  WEBNYAY_URL,
  STAR_URL,
  Muthoot_URL,
  MSME_URL,
} from "app/constants";
import classNames from "classnames";
import { useBackground } from "contexts/BackgroundContext";
import Analytics from "features/Analytics";
import Calculator from "features/Calculator/Calculator";
import Chat from "features/Chat";
import JoinDyteLinkMeeting from "features/Chat/JoinDyteLinkMeeting";
import ZoomEnd from "features/Chat/Vc/ZoomEnd";
import ComplianceReportContainerAnonymous from "features/ComplianceReport/Anonymous/ComplianceReportContainerAnonymous";
import ComplianceReportContainer from "features/ComplianceReport/ComplianceReportContainer";
import ComplianceReportYearWiseContainer from "features/ComplianceReport/ComplianceReportYearWiseContainer";
import CredHomePage from "features/Cred/CredHomePage";
import LoanContainer from "features/Cred/Loan/LoanContainer";
import Halls from "features/DifcHalls/Halls";
import ForgotPassword from "features/ForgotPassword";
import ForgotPasswordResult from "features/ForgotPasswordResult";
import ForgotPasswordResultDIFC from "features/ForgotPasswordResult/ForgotPasswordResultDIFC";
import ResetPassword from "features/ResetPassword";
import ResetPasswordResult from "features/ResetPasswordResult";
import TempTiacAdditionalInfoPage from "features/TempTiacForm/AdditionalPage";
import TempTiacArbitratorPage from "features/TempTiacForm/ArbitratorPage";
import TempTiacClaimantLayerPage from "features/TempTiacForm/ClaimantLawyerPage";
import TempTiacClaimantPage from "features/TempTiacForm/ClaimantPage";
import TempTiacRespLawyerPage from "features/TempTiacForm/RespondentLawyerPage";
import TempTiacRespondentPage from "features/TempTiacForm/RespondentPage";
import TempForm from "features/TempTiacForm/TiacRFAForm";
import TempFinal from "features/TempTiacForm/Tiacfinal";
import TempTiacUploadPage from "features/TempTiacForm/UploadPage";
import TempFormV2 from "features/TempTiacFormV2/TiacRFAForm";
import TempMSMEForm from "features/TempMSMETiacFormV1/TiacRFAForm";
import AfterSetPasswordScreen from "features/TempTiacFormV2/TiacRFAForm/AfterSetPasswordScreen";
import OldFormContainer from "features/TempTiacFormV2/TiacRFAForm/OldFormContainer";
import OldFormContainerMSME from "features/TempMSMETiacFormV1/TiacRFAForm/OldFormContainer";
import TempFormV3 from "features/TempTiacFormV3/TiacRFAForm";
import AfterSetPasswordScreenNew from "features/TempTiacFormV3/TiacRFAForm/AfterSetPasswordScreen";
import OldFormContainerNew from "features/TempTiacFormV3/TiacRFAForm/OldFormContainer";
import WaitingScreen from "features/Tiacfinal/WaitingScreen";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import Maintenance from "features/common/Maintenance/Maintenance";
import Disputes from "features/disputes";
import DisputesHotstar from "features/disputes/DisputesContainerHotstar";
import DisputesContainerStar from "features/disputes/DisputesContainerStar";
import DisputesListContainerPagination from "features/disputesList/DisputesListContainerPagination";
import DisputesRv from "features/disputesRv";
import DisputesRvTiacContainer from "features/disputesRv/DisputesRvTiacContainer";
import Generate from "features/disputesRv/RvGenerateDoc/Generate";
import Fallback from "features/fallBack";
import FileViewer from "features/fileView";
import Files from "features/files";
import FilesAdmin from "features/filesAdmin/FilesContainer";
import FileContainerRv from "features/filesRV";
import FileContainerRvAdminViewOthers from "features/filesRVAdminViewOthers";
import Footer from "features/footer";
import DifcFooter from "features/footer/DifcFooter";
import HotstarFooter from "features/footer/HotstarFooter";
import GlobalCalendar from "features/globalCalendar";
import ViewCalenderContainer from "features/globalCalendar/ViewCalender";
import Header from "features/header";
import Intro from "features/intro";
import FrontPageHotstar from "features/intro/AnonymousDisputeFlow/FrontPageHotstar";
import FrontPageViacom from "features/intro/AnonymousDisputeFlow/FrontPageViacom";
import SingleDisputeContainer from "features/intro/AnonymousDisputeFlow/SingleDisputeContainer";
import IntroBeforeLogin from "features/intro/IntroContainerBeforeLogin";
import { SingleDisputeContainer as SingleDisputeContainerVikalp } from "features/intro/IntroVikalp/AnonymousDisputeFlow/SingleDisputeContainer";
import VikalpIntro from "features/intro/IntroVikalp/Container";
import IntroRv from "features/intro_rv";
import LinkedinCallback from "features/linkedinCallback";
import Login from "features/login";
import LoginDifc from "features/loginDifc";
import LoginNBF from "features/loginNBF";
import LoginSebi from "features/loginSebi";
import LoginTiac from "features/loginTiac";
import NotFound from "features/notFound";
import Profile from "features/profile";
import Signup from "features/signup";
import SignupHotstar from "features/signup/SignupHotstar";
import SignupRV from "features/signup/SignupRV";
import SingleDispute from "features/singleDispute";
import SingleDisputeRv from "features/singleDisputeRv";
import TiacAppHeader from "features/tiac_header_waiting";
import React, { Suspense, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { isLoggedIn } from "slices/loginSlice";
import SebiPaymentForm from "Admin/Sebi/Finance/SebiPaymentForm";
import SuccessPage from "Admin/Sebi/Finance/SuccessPage";
import FailPage from "Admin/Sebi/Finance/FailPage";
import IncorrectPaymentLink from "Admin/Sebi/Finance/IncorrectPaymentLink";
import PaymentBreakdown from "Admin/Sebi/Finance/PaymentBreakdown";
import backgroundImage from "images/difc_login_background.jpg";
import CreateDirectMeeting from "Admin/SuperAdmin/CreateDirectMeeting";
import PaytmHomepage from "features/Paytm/PaytmHomepage";
import PaytmComplianceReport from "features/Paytm/PaytmCompilanceReport";
import PaytmLogin from "features/Paytm/PaytmLogin";
import DisputesContainerMuthoot from "features/disputes/MuthootDisputes/DisputesContainerMuthoot";
import DisputesContainerMuthootUser from "features/disputes/MuthootDisputes/DisputesContainerMuthootUser";

const Zoom = React.lazy(() => import("features/Chat/Vc/ZoomStart"));

export const isLocalBackend = false;
// export const isLocalBackend = true;

export const isDev =
  window.location.host === DEV_WEBNYAY_URL ||
  window.location.host === DEV_HOTSTAR_URL;
// export const isDev = true;

export const isNbf =
  window.location.host === NBF_URL
    ? true
    : isDev && localStorage.getItem("dev") === "nbf";
// export const isNbf = true;

export const isHotStar =
  window.location.host === HOTSTAR_URL ||
  window.location.host === DEV_HOTSTAR_URL
    ? true
    : isDev && localStorage.getItem("dev") === "hotstar";
// export const isHotStar = true;

export const isStar =
  window.location.host === STAR_URL
    ? true
    : isDev && localStorage.getItem("dev") === "star";
// export const isStar = true;

export const isMuthoot =
  window.location.host === Muthoot_URL
    ? true
    : isDev && localStorage.getItem("dev") === "muthoot";
// export const isMuthoot = true;

export const isCDR =
  window.location.host === CDR_URL
    ? true
    : isDev && localStorage.getItem("dev") === "cdr";
// export const isCDR = true;

export const isViacom =
  window.location.host === VIACOM_URL
    ? true
    : isDev && localStorage.getItem("dev") === "viacom";
// export const isViacom = true;

export const isTiac =
  window.location.host === TIAC_URL
    ? true
    : isDev && localStorage.getItem("dev") === "tiac";
// export const isTiac =  true

export const isArb =
  window.location.host === ARB_URL
    ? true
    : isDev && localStorage.getItem("dev") === "arb";
// export const isArb =  true

export const isKilumaya =
  window.location.host === KILUMAYA_URL
    ? true
    : isDev && localStorage.getItem("dev") === "kilumaya";
// export const isKilumaya =  true

export const isSebi =
  window.location.host === SEBI_URL
    ? true
    : isDev && localStorage.getItem("dev") === "sebi";
// export const isSebi =  true

export const isMSME =
  window.location.host === MSME_URL
    ? true
    : isDev && localStorage.getItem("dev") === "msme";
// export const isMSME =  true

export const isDifc =
  window.location.host === MOOT_URL
    ? true
    : isDev && localStorage.getItem("dev") === "difc";
//export const isDifc = false;

// export const isArbitrationConference =
//   window.location.host === DIFC_URL
//     ? true
//     : isDev && localStorage.getItem("dev") === "arbitrationconference"
//     ? true
//     : false;
export const isArbitrationConference = false;

export const isTiacStatic =
  window.location.host === TIAC_URL_STATIC
    ? true
    : isDev && localStorage.getItem("dev") === "tiacstatic";
// export const isTiacStatic = true;

export const isRv =
  window.location.hostname === "resolvevirtually.com" ||
  window.location.hostname === "www.resolvevirtually.com" ||
  isTiac ||
  isArb ||
  isKilumaya ||
  isDifc ||
  isSebi ||
  isMSME ||
  isArbitrationConference
    ? true
    : isDev && localStorage.getItem("dev") === "rv";
// export const isRv = false;

export const isWebnyay =
  window.location.host === WEBNYAY_URL
    ? true
    : isDev && localStorage.getItem("dev") === "webnyay";
// export const isWebnyay = true;

export const isBarAndBench =
  window.location.host === BARANDBENCH_URL
    ? true
    : isDev && localStorage.getItem("dev") === "barandbench";
// export const isBarAndBench = true

export const isDigipub =
  window.location.host === DIGIPUB_URL
    ? true
    : isDev && localStorage.getItem("dev") === "digipub";
// export const isDigipub = true;

export const isNSE =
  window.location.host === NSE_URL
    ? true
    : isDev && localStorage.getItem("dev") === "nse";
// export const isNSE =  true

export const isVikalp =
  window.location.host === VIKALP_URL
    ? true
    : isDev && localStorage.getItem("dev") === "vikalp";
// export const isVikalp =  true

export const isPaytm = window.location.pathname?.includes("paytm")
  ? true
  : isDev && localStorage.getItem("dev") === "paytm"
  ? true
  : false;
// export const isPaytm =  true

export const isTempFormV2Tiac = true;
export const isOtpRequiredInTiac = false;

const useStyles = makeStyles((theme) => {
  return {
    lightBlue: {
      backgroundColor: "#F7F8FA",
    },
    white: {
      backgroundColor: "white",
    },
    default: {
      backgroundColor: "#eeeeee",
    },
  };
});

export function scrollPageToTop() {
  const body = document.querySelector("#root");
  body.scrollIntoView(
    {
      behavior: "auto",
    },
    500
  );
}

function Routes() {
  const classes = useStyles();
  const location = useLocation();

  const {
    profile: { profile_type: profileType },
  } = useSelector((state) => state.profile, shallowEqual);

  const profile = useSelector((state) => state.profile, shallowEqual);
  // console.log('profile timezone', profile?.profile?.timezone)
  // console.log('local date', new Date());
  // console.log('local timezone', getTimeZone());

  const { background, setBackground } = useBackground();

  useEffect(() => {
    if (
      isSebi ||
      isMSME ||
      isTiac ||
      isDifc ||
      isArb ||
      isKilumaya ||
      isPaytm ||
      isMuthoot
    ) {
      setBackground("lightBlue");
    }
  });

  const LoadingComponent = () => {
    const history = useHistory();
    if (profileType) {
      history.push("/");
    }
    return <CircularCenteredLoader />;
  };

  function LoginComponent() {
    if (isNbf) {
      return (
        <GuestRoute path="/login" exact>
          <LoginNBF />
        </GuestRoute>
      );
    } else if (isHotStar || isStar || isMuthoot) {
      return (
        <GuestRoute path="/login" exact>
          <FrontPageHotstar />
        </GuestRoute>
      );
    } else if (isTiac || isArb || isKilumaya) {
      return (
        <GuestRoute path="/login_tiac" exact>
          <LoginTiac />
        </GuestRoute>
      );
    } else if (isDifc || isArbitrationConference) {
      return (
        <GuestRoute path="/login_difc" exact>
          <LoginDifc />
        </GuestRoute>
      );
    } else if (isSebi || isMSME) {
      return (
        <GuestRoute path="/login_sebi" exact>
          <LoginSebi />
        </GuestRoute>
      );
    } else {
      return (
        <GuestRoute path="/login" exact>
          <Login />
        </GuestRoute>
      );
    }
  }

  useEffect(() => {
    scrollPageToTop();
  }, [location]);
  return (
    <React.Fragment>
      <div
        className={classNames("content", { [classes[background]]: true })}
        style={
          isDifc && isLoggedIn() === ""
            ? {
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
              }
            : null
        }
      >
        {(isTiac ||
          isArb ||
          isKilumaya ||
          isDifc ||
          ((isSebi || isMSME) && isLoggedIn() !== "") ||
          isArbitrationConference ||
          isPaytm ||
          isHotStar ||
          isStar ||
          isMuthoot) &&
          !(isTiacStatic && location.pathname === "/") &&
          location.pathname !== "/login_difc" && (
            <TiacAppHeader position="fixed" profile={profile} />
          )}
        {!(
          isRv &&
          (location.pathname === "/" || location.pathname === "/contact")
        ) &&
          location.pathname !== "/dataanalytics" &&
          !isTiac &&
          !isArb &&
          !isKilumaya &&
          !isSebi &&
          !isMSME &&
          !isDifc &&
          !isArbitrationConference &&
          !isPaytm &&
          !(isHotStar || isStar || isMuthoot) &&
          !(isTiacStatic && location.pathname === "/") && (
            <Header position="fixed" profile={profile} />
          )}

        <Switch>
          {conditionalAdmin(profileType, profile)}
          {conditionalSubAdmin(profileType, profile)}
          {LoginComponent()}
          {conditionalDashboard(profile.profile, profileType)}
          {disputesListRoutes(profileType)}
          {conditionalDispute(profile.profile, profileType)}
          {conditionalIntro(profileType)}
          {AnonymousDispute(profileType)}
          {FrontPageHotstar_(profileType)}
          {FrontPageRv(profileType, isRv)}
          {ContactUsPageRv(profileType, isRv)}
          {viewCalender(profileType)}
          {viewFiles(profileType)}
          {viewFiles2(profileType)}
          {signUp()}
          {viewFilesRV(profile)}
          {viewFilesRVAdminOrCaseManagerCaseDisplay(profile)}
          {viewFilesRVAdminOrCaseManagerDrive(profile)}
          {CredFlow(isTiacStatic)}
          {CredLoanFlow(isTiacStatic)}
          {conditionalRouteCitizenComplianceReport()}
          {conditionalRouteFinshotsComplianceReport()}
          {conditionalComplianceReport()}

          <PrivateRoute path="/dataanalytics" exact>
            <DataAnalyticsContainer />
          </PrivateRoute>

          <Route path="/linkedin-callback" exact>
            <LinkedinCallback />
          </Route>

          <Route path="/calculator" exact>
            <Calculator />
          </Route>

          <PrivateRoute path="/profile" exact>
            <Profile />
          </PrivateRoute>

          <PrivateRoute path="/events" exact>
            <GlobalCalendar />
          </PrivateRoute>

          <PrivateRoute path="/analytics" exact>
            <Analytics profile={profile} />
          </PrivateRoute>

          <PrivateRoute path="/fileview" exact>
            <FileViewer />
          </PrivateRoute>

          <PrivateRoute path="/publishedcompliancereport" exact>
            <ComplianceReportContainer />
          </PrivateRoute>

          <PrivateRoute path="/disputes/:disputeId/chat" exact>
            <Chat />
          </PrivateRoute>

          <PrivateRoute path="/zoom/start" exact>
            <Suspense fallback={<div></div>}>
              <Zoom profile={profile.profile} />
            </Suspense>
          </PrivateRoute>

          {(isDifc || isArbitrationConference) && (
            <PrivateRoute path="/halls" exact>
              <Halls profile={profile.profile} />
            </PrivateRoute>
          )}

          <PrivateRoute path="/disputes/:disputeId/files" exact>
            <Files />
          </PrivateRoute>

          <PrivateRoute path="/disputes/:disputeId/generatedoc" exact>
            <Generate />
          </PrivateRoute>

          <PrivateRoute path="/loading" exact>
            <LoadingComponent />
          </PrivateRoute>

          <GuestRoute path="/forgot-password" exact>
            <ForgotPassword />
          </GuestRoute>

          <GuestRoute path="/reset-password" exact>
            <ResetPassword />
          </GuestRoute>

          <GuestRoute path="/create-direct-meeting" exact>
            <CreateDirectMeeting />
          </GuestRoute>

          <Route path="/sebi-payment-process" exact>
            <SebiPaymentForm />
          </Route>

          <Route path="/sebi-payment-breakdown" exact>
            <PaymentBreakdown />
          </Route>

          <Route path="/sebi-payment-success" exact>
            <SuccessPage />
          </Route>

          <Route path="/sebi-payment-fail" exact>
            <FailPage />
          </Route>

          <Route path="/sebi-payment-invalid-link" exact>
            <IncorrectPaymentLink />
          </Route>

          <GuestRoute path="/forgot-password-confirm" exact>
            {isDifc || isArbitrationConference ? (
              <ForgotPasswordResultDIFC />
            ) : (
              <ForgotPasswordResult />
            )}
          </GuestRoute>

          <GuestRoute path="/reset-password-confirm" exact>
            <ResetPasswordResult />
          </GuestRoute>

          <Route path="/maintenance">
            <Maintenance />
          </Route>

          <Route path="/fallback" exact>
            <Fallback />
          </Route>
          <Route path="/contact" exact>
            <ContactUs />
          </Route>

          <Route path="/RFA" exact>
            {isMSME ? <TempMSMEForm /> : <TempFormV2 />}
          </Route>
          <Route path="/RFA2" exact>
            <TempFormV3 />
          </Route>
          <Route path="/resumerfa" exact>
            {isMSME ? <OldFormContainerMSME /> : <OldFormContainer />}
          </Route>
          <Route path="/resumerfa2" exact>
            <OldFormContainerNew />
          </Route>
          <Route path="/afterpassword" exact>
            <AfterSetPasswordScreen />
          </Route>
          <Route path="/afterpassword2" exact>
            <AfterSetPasswordScreenNew />
          </Route>
          <Route path="/old_tiac_form" exact>
            <TempForm />
          </Route>
          <Route path="/tiac_claimant" exact>
            <TempTiacClaimantPage />
          </Route>
          <Route path="/tiac_claimant_lawyer" exact>
            <TempTiacClaimantLayerPage />
          </Route>
          <Route path="/tiac_respondent" exact>
            <TempTiacRespondentPage />
          </Route>
          <Route path="/tiac_respondent_lawyer" exact>
            <TempTiacRespLawyerPage />
          </Route>
          <Route path="/tiac_upload" exact>
            <TempTiacUploadPage />
          </Route>
          <Route path="/tiac_arbitrator" exact>
            <TempTiacArbitratorPage />
          </Route>
          <Route path="/tiac_additionalinfo" exact>
            <TempTiacAdditionalInfoPage />
          </Route>
          <Route path="/tiac_final" exact>
            <TempFinal />
          </Route>
          <Route path="/tiac_waitingscreen" exact>
            <WaitingScreen />
          </Route>

          <PrivateRoute path="/disputes/:disputeId/end-zoom-meeting" exact>
            <ZoomEnd />
          </PrivateRoute>

          <Route path="/meeting/:disputeId" exact>
            <JoinDyteLinkMeeting preset_name="participant" />
          </Route>

          <Route path="/meeting/chatroom/:disputeId" exact>
            <JoinDyteLinkMeeting preset_name="guest" />
          </Route>

          <Route path="/paytm/login" exact>
            <PaytmLogin />
          </Route>

          <Route path="/paytm/compliances" exact>
            <PaytmHomepage />
          </Route>

          <Route path="/paytm/compliance/:id" exact>
            <PaytmComplianceReport />
          </Route>

          {
            // profile.loading ?
            //     <Redirect to={{pathname:'/loading'}} />:

            isLoggedIn() !== "" ? (
              <PrivateRoute path="/" exact>
                {!profileType ? (
                  <Redirect to={{ pathname: "/loading" }} />
                ) : profileType === "admin" ? (
                  <Redirect
                    to={{
                      pathname: "/admin",
                    }}
                  />
                ) : profileType === "sub_admin" && isMuthoot ? (
                  <Redirect
                    to={{
                      pathname: "/disputes",
                    }}
                  />
                ) : profileType === "sub_admin" ||
                  profileType === "sub_admin_rv" ? (
                  <Redirect
                    to={{
                      pathname: "/subadmin",
                    }}
                  />
                ) : profileType === "sub_admin_tiac_rv" &&
                  (isDifc || isArbitrationConference) ? (
                  <Redirect
                    to={{
                      pathname: "/subadmindifc",
                    }}
                  />
                ) : profileType === "sub_admin_tiac_rv" &&
                  (isTiac || isArb || isKilumaya) ? (
                  <Redirect
                    to={{
                      pathname: "/subadmintiac",
                    }}
                  />
                ) : profileType === "sub_admin_tiac_rv" &&
                  (isSebi || isMSME) ? (
                  <Redirect
                    to={{
                      pathname: "/subadminsebi",
                    }}
                  />
                ) : profileType === "case_manager" &&
                  (isDifc || isArbitrationConference) ? (
                  <Redirect to={{ pathname: "/casemanagerdifc" }} />
                ) : profileType === "case_manager" ? (
                  <Redirect to={{ pathname: "/casemanager" }} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/disputes",
                    }}
                  />
                )}
              </PrivateRoute>
            ) : isHotStar ||
              isStar ||
              isMuthoot ||
              isViacom ||
              (isRv &&
                !isTiac &&
                !isArb &&
                !isKilumaya &&
                !isSebi &&
                !isMSME &&
                !isDifc &&
                !isPaytm &&
                !isArbitrationConference) ? (
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            ) : isTiac || isArb || isKilumaya ? (
              <Redirect
                to={{
                  pathname: "/login_tiac",
                }}
              />
            ) : isSebi || isMSME ? (
              <Redirect
                to={{
                  pathname: "/login_sebi",
                }}
              />
            ) : isDifc || isArbitrationConference ? (
              <Redirect
                to={{
                  pathname: "/login_difc",
                }}
              />
            ) : isPaytm ? (
              <Redirect
                to={{
                  pathname: "/paytm/login",
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            )
          }

          <Route path="/*">
            <NotFound />
          </Route>
        </Switch>
      </div>

      {profileType !== "admin" &&
        profileType !== "sub_admin" &&
        profileType !== "sub_admin_rv" &&
        profileType !== "sub_admin_tiac_rv" &&
        profileType !== "case_manager" &&
        location.pathname !== "/dataanalytics" &&
        !(
          isRv &&
          (location.pathname === "/" ||
            location.pathname === "/contact" ||
            isTiac ||
            isArb ||
            isKilumaya ||
            isSebi ||
            isMSME)
        ) &&
        !(isDifc && location.pathname === "/login_difc") &&
        !(isTiacStatic && location.pathname === "/") &&
        !location.pathname.includes("/cred") &&
        !location.pathname.includes("/paytm") &&
        !(isHotStar || isStar || isMuthoot) &&
        !isArbitrationConference &&
        (isHotStar || isStar || isMuthoot ? (
          <HotstarFooter />
        ) : isDifc ? (
          <DifcFooter profile={profile.profile} />
        ) : (
          <Footer />
        ))}
    </React.Fragment>
  );
}

function CredFlow(isTiacStatic) {
  if (isTiacStatic)
    return (
      <Route path="/cred" exact>
        <CredHomePage />
      </Route>
    );
}

function CredLoanFlow(isTiacStatic) {
  if (isTiacStatic)
    return (
      <Route path="/cred/loan/:loan_id" exact>
        <LoanContainer />
      </Route>
    );
}

function signUp() {
  if (isRv) {
    return (
      <GuestRoute path="/signup" exact>
        <SignupRV />
      </GuestRoute>
    );
  } else if (isHotStar || isStar || isMuthoot) {
    return (
      <GuestRoute path="/signup" exact>
        <SignupHotstar />
      </GuestRoute>
    );
  } else {
    return (
      <GuestRoute path="/signup" exact>
        <Signup />
      </GuestRoute>
    );
  }
}

function viewFilesRV(profile) {
  if (
    profile.profile.is_tiac_profile ||
    profile.profile.profile_type === "admin"
  )
    return (
      <PrivateRoute path="/disputes/:disputeId/files/:folder_id" exact>
        <FileContainerRv />
      </PrivateRoute>
    );
}

function viewFilesRVAdminOrCaseManagerCaseDisplay(profile) {
  if (
    profile.profile.profile_type === "sub_admin_tiac_rv" ||
    profile.profile.profile_type === "case_manager"
  )
    return (
      <PrivateRoute
        path="/disputes/:disputeId/files/user/:user_id/:folder_id"
        exact
      >
        <FileContainerRvAdminViewOthers />
      </PrivateRoute>
    );
}
function viewFilesRVAdminOrCaseManagerDrive(profile) {
  if (
    profile.profile.profile_type === "sub_admin_tiac_rv" ||
    profile.profile.profile_type === "case_manager"
  )
    return (
      <PrivateRoute path="/files/user/:user_id/:folder_id" exact>
        <FileContainerRvAdminViewOthers />
      </PrivateRoute>
    );
}

function viewFiles(profileType) {
  if (
    profileType === "admin" ||
    profileType === "sub_admin_tiac_rv" ||
    profileType === "case_manager"
  ) {
    return (
      <PrivateRoute path={`/files/:profileId`} exact>
        <FilesAdmin />
      </PrivateRoute>
    );
  }
}

function viewFiles2(profileType) {
  if (
    profileType === "admin" ||
    profileType === "sub_admin" ||
    profileType === "sub_admin_rv" ||
    profileType === "sub_admin_tiac_rv" ||
    profileType === "case_manager"
  ) {
    return (
      <PrivateRoute path={`/files/:profileId/:caseId`} exact>
        <FilesAdmin />
      </PrivateRoute>
    );
  }
}

function viewCalender(profileType) {
  if (
    profileType === "admin" ||
    profileType === "sub_admin" ||
    profileType === "sub_admin_rv" ||
    profileType === "sub_admin_tiac_rv" ||
    profileType === "case_manager"
  ) {
    return (
      <PrivateRoute path={`/events/:profileId`} exact>
        <ViewCalenderContainer />
      </PrivateRoute>
    );
  }
}

function conditionalAdmin(profileType, profile) {
  if (profileType === "admin" && isWebnyay) {
    // admin can only login through webnyay
    return (
      <PrivateRoute path="/admin" exact>
        <SuperAdmin profile={profile.profile} />
      </PrivateRoute>
    );
  }
}

function conditionalSubAdmin(profileType, profile) {
  if (profileType === "sub_admin" && !isRv) {
    return (
      <PrivateRoute path="/subadmin" exact>
        <Admin profile={profile.profile} />
      </PrivateRoute>
    );
  } else if (profileType === "sub_admin_rv" && isRv) {
    return (
      <PrivateRoute path="/subadmin" exact>
        <RVAdmin />
      </PrivateRoute>
    );
  } else if (
    profileType === "sub_admin_tiac_rv" &&
    isRv &&
    (isDifc || isArbitrationConference)
  ) {
    return (
      <PrivateRoute path="/subadmindifc" exact>
        <RVAdminDIFC profile={profile.profile} />
      </PrivateRoute>
    );
  } else if (
    profileType === "sub_admin_tiac_rv" &&
    isRv &&
    (isTiac || isArb || isKilumaya)
  ) {
    return (
      <PrivateRoute path="/subadmintiac" exact>
        <RVAdminTiac profile={profile.profile} />
      </PrivateRoute>
    );
  } else if (profileType === "sub_admin_tiac_rv" && (isSebi || isMSME)) {
    return (
      <PrivateRoute path="/subadminsebi" exact>
        <SebiAdmin profile={profile.profile} />
      </PrivateRoute>
    );
  } else if (
    profileType === "case_manager" &&
    isRv &&
    (isDifc || isArbitrationConference)
  ) {
    return (
      <PrivateRoute path="/casemanagerdifc" exact>
        <RVAdminDIFC profile={profile.profile} />
      </PrivateRoute>
    );
  } else if (profileType === "case_manager" && isRv) {
    return (
      <PrivateRoute path="/casemanager" exact>
        <RVAdminTiac profile={profile.profile} />
      </PrivateRoute>
    );
  }
}

function disputesListRoutes(profileType) {
  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === MEDIATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === SECRETARIAT;
  if (isProfessionalAccount) {
    return (
      <PrivateRoute path={`/disputes/${profileType}/:disputesType`} exact>
        {/* <DisputesList /> */}
        <DisputesListContainerPagination />
      </PrivateRoute>
    );
  } else {
    const types = ["respondent", "claimant"];
    return types.map((type, index) => {
      return (
        <PrivateRoute
          key={index}
          path={`/disputes/${type}/:disputesType`}
          exact
        >
          <DisputesListContainerPagination />
        </PrivateRoute>
      );
    });
  }
}

function GuestRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() === "" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() !== "" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function AnonymousDispute(profileType = "_default") {
  if (isHotStar || isViacom || isStar || isMuthoot) {
    if (
      isLoggedIn() === "" ||
      (isMuthoot &&
        isLoggedIn() !== "" &&
        (profileType === "vendor" || profileType === "sub_admin"))
    ) {
      return (
        <Route path="/dispute" exact>
          <SingleDisputeContainer />
        </Route>
      );
    }
  }
  if (isVikalp) {
    return (
      <Route path="/dispute" exact>
        <SingleDisputeContainerVikalp />
      </Route>
    );
  }
}

function FrontPageRv(profileType = "_default", isRv) {
  if (
    isRv &&
    !isTiac &&
    !isArb &&
    !isKilumaya &&
    !isSebi &&
    !isMSME &&
    !isDifc &&
    !isArbitrationConference
  ) {
    if (isLoggedIn() === "") {
      return (
        <Route path="/" exact>
          <RvHomePage />
        </Route>
      );
    }
  } else if (isTiacStatic) {
    return (
      <Route path="/" exact>
        <HomePageTiac />
      </Route>
    );
  }
}

function ContactUsPageRv(profileType = "_default", isRv) {
  if (
    isRv &&
    !isTiac &&
    !isArb &&
    !isKilumaya &&
    !isSebi &&
    !isMSME &&
    !isDifc &&
    !isArbitrationConference
  ) {
    return (
      <Route path="/contact" exact>
        <ContactUsRv isLoggedIn={isLoggedIn} />
      </Route>
    );
  } else {
    return (
      <Route path="/contact" exact>
        <ContactUs />
      </Route>
    );
  }
}

function FrontPageHotstar_(profileType = "_default") {
  if (isHotStar || isStar || isMuthoot) {
    if (isLoggedIn() === "") {
      return (
        <Route path="/" exact>
          <FrontPageHotstar />
        </Route>
      );
    }
  } else if (isViacom) {
    if (isLoggedIn() === "") {
      return (
        <Route path="/" exact>
          <FrontPageViacom />
        </Route>
      );
    }
  }
}

function conditionalDispute(profile, profileType = "_default") {
  if (profileType) {
    const list = profileType.split("_");
    const isRvProfile = list[list.length - 1] === "rv";
    return (
      <PrivateRoute path="/disputes/:disputeId" exact>
        {isRvProfile ||
        (profile.group === "difc" && profileType === "mediator") ||
        ((isSebi || isMSME) &&
          (profileType === "mediator" || profileType === "witness")) ? (
          <SingleDisputeRv />
        ) : (
          <SingleDispute />
        )}
      </PrivateRoute>
    );
  }
}

function conditionalDashboard(profile, profileType = "_default") {
  if (profileType) {
    const list = profileType.split("_");
    const isRvProfile = list[list.length - 1] === "rv";
    let Dashboard;
    if (
      profile.is_tiac_profile &&
      profile.profile_type !== "sub_admin_tiac_rv" &&
      profile.profile_type !== "case_manager"
    ) {
      Dashboard = DisputesRvTiacContainer;
    } else if (
      isRvProfile ||
      (profile.profile_type !== "case_manager" && profile.is_tiac_profile)
    ) {
      Dashboard = DisputesRv;
    } else if (
      isHotStar &&
      (profile.profile_type === "vendor" ||
        profile.profile_type === "arbitrator")
    ) {
      Dashboard = DisputesHotstar;
    } else if (
      isStar &&
      (profile.profile_type === "vendor" ||
        profile.profile_type === "arbitrator")
    ) {
      Dashboard = DisputesContainerStar;
    } else if (
      isMuthoot &&
      (profile.profile_type === "vendor" ||
        profile.profile_type === "mediator" ||
        profile.profile_type === "arbitrator" ||
        profile.profile_type === "sub_admin")
    ) {
      Dashboard = DisputesContainerMuthoot;
    } else if (isMuthoot && profile.profile_type === "general") {
      Dashboard = DisputesContainerMuthootUser;
    } else {
      Dashboard = Disputes;
    }
    return (
      <PrivateRoute path="/disputes" exact>
        <Dashboard profile={profile} />
      </PrivateRoute>
    );
  }
}

function conditionalIntro(profileType = "_default") {
  if (isHotStar || isStar || isMuthoot) {
    if (isLoggedIn() !== "") {
      if (
        isMuthoot &&
        (profileType === "vendor" || profileType === "sub_admin")
      ) {
        return (
          <Route path="/intro" exact>
            <IntroBeforeLogin />
          </Route>
        );
      } else {
        return (
          <Route path="/intro" exact>
            <Intro />
          </Route>
        );
      }
    } else {
      return (
        <Route path="/intro" exact>
          <IntroBeforeLogin />
        </Route>
      );
    }
  } else if (isViacom) {
    if (isLoggedIn() !== "") {
      return (
        <Route path="/intro" exact>
          <Intro />
        </Route>
      );
    } else {
      return (
        <Route path="/intro" exact>
          <IntroBeforeLogin />
        </Route>
      );
    }
  } else if (isVikalp) {
    return (
      <Route path="/intro" exact>
        <VikalpIntro />
      </Route>
    );
  } else {
    if (profileType) {
      const list = profileType.split("_");
      const isRvProfile = list[list.length - 1] === "rv";
      return (
        // <PrivateRoute path="/intro" exact>
        //   {isRvProfile ? <IntroRv /> : <Intro />}
        // </PrivateRoute>
        <PrivateRoute path="/intro" exact>
          {isRvProfile ? <IntroRv /> : <Intro />}
        </PrivateRoute>
      );
    }
  }
}

function conditionalRouteCitizenComplianceReport() {
  if (isDigipub) {
    return (
      <Route path="/thecitizen/compliancereports" exact>
        <ComplianceReportContainerAnonymous company_name={"The Citizen"} />
      </Route>
    );
  }
}

function conditionalRouteFinshotsComplianceReport() {
  if (isDigipub) {
    return (
      <Route path="/finshots/compliancereports" exact>
        <ComplianceReportContainerAnonymous company_name={"Finshots"} />
      </Route>
    );
  }
}

function conditionalComplianceReport() {
  if (isHotStar || isMuthoot) {
    return (
      <Route path="/compliancereports" exact>
        <ComplianceReportYearWiseContainer />
      </Route>
    );
  }
}

export default Routes;
