import {
  Box,
  Button,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { getWindowDimension } from "utils/responsiveUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CreateDispute from "./CreateDispute";
import Overview from "./Overview";
import { removeSessionStorage } from "features/tiac_header_waiting/TiacAppHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minHeight: "95vh",
    // height: "100%",
    borderRadius: "1rem",
    // flexDirection: (props) => (props.smallScreen ? "column" : "row"),
  },
  sidebar: {
    background: "#2C2C2C",
    width: "23rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sidebarItem: {
    //#A4A6B3;
    fontSize: "16px",
    padding: "0.5rem 4rem",
    color: "#A4A6B3",
    letterSpacing: "0.2px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      padding: "0.5rem 2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: "0.4rem 0 0.4rem 3rem",
    },
  },
  selected: {
    backgroundColor: "rgba(159, 162, 180, 0.08)",
    // opacity: 0.08,
    // color:"#FFFFFF",
    borderLeft: "2px solid #FFFFFF;",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
}));

export default function RVAdmin() {
  const classes = useStyles();
  let history = useHistory();

  const [step, setStep] = useState(0);

  let s = sessionStorage.getItem("sidebarItem");
  const [selected, setSelected] = useState(
    getWindowDimension().width < 960 ? 0 : s ? s : 1
  );

  const Sidebar = () => {
    return (
      <Box className={classes.sidebar}>
        <Box height="1rem"></Box>
        <Typography
          style={{
            padding: "15px 20px 30px 20px",
            fontSize: "18px",
            color: "rgba(238, 238, 238, 0.7)",
            fontWeight: "bold",
          }}
        >
          Dashboard
        </Typography>

        <List style={{ paddingTop: "0" }}>
          {selected == 1 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(1);
                removeSessionStorage();
                setStep(1);
                history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Overview
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(1);
                removeSessionStorage();
                setStep(1);
                // history.push('/')
              }}
            >
              <Typography className={classes.sidebarItem}>Overview</Typography>
            </ListItem>
          )}
          {selected == 2 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(2);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Create Dispute
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(2);
                removeSessionStorage();
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Create Dispute
              </Typography>
            </ListItem>
          )}
        </List>
      </Box>
    );
  };

  const RenderOptions = () => {
    if (selected == 1 || selected == 0) {
      sessionStorage.setItem("sidebarItem", 1);
      return <Overview />;
    }
    if (selected == 2) {
      sessionStorage.setItem("sidebarItem", 2);
      return <CreateDispute />;
    }
  };

  const Body = () => {
    return (
      <Box className={classes.body2}>
        <Hidden mdUp>
          <Paper
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
              display: "flex",
              justifyContent: "flex-start",
              padding: "15px 20px",
            }}
          >
            <Button
              size="large"
              onClick={(e) => {
                setStep(0);
                setSelected(0);
              }}
              startIcon={<ArrowBackIosIcon />}
            >
              <FormattedMessage id="buttons.back" />
            </Button>
          </Paper>
        </Hidden>
        <Box className={classes.body}>
          <RenderOptions />
        </Box>
      </Box>
    );
  };

  const MobileView = ({ step }) => {
    switch (step) {
      case 0:
        return <Sidebar />;
      case 1:
        return <Body />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <>
          <Sidebar />
          <Body />
        </>
      </Hidden>
      <Hidden mdUp>
        <MobileView step={step} />
      </Hidden>
    </Box>
  );
}
