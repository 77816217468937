import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDisputesCount,
  getDisputesForPaginationHotstar,
  getDisputesForStar
} from "slices/disputeSlice";
import DisputesStar from "./StarDisputes/DisputesStar";

export const typeChoices = ["registered", "closed", "serious", "frivolous"];

export default function DisputesContainerStar() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  const [count, setCount] = useState({ received: {}, registered: {} });
  const [countLoading, setCountLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  let sessionType = sessionStorage.getItem("hotstar-type");

  const [type, setType] = useState(sessionType ? sessionType : typeChoices[2]);
  const [disputes, setDisputes] = useState([]);
  const [disputesLoading, setDisputesLoading] = useState(false);
  const [filters, setFilters] = useState({
    dispute_type: '',
    start_date: '',
    end_date: '',
    time_frame: '',
    claimant_name: '',
    tags: [],
    channel_name: '',
    show_name: ''
  });

  React.useEffect(() => {
    dispatch(getDisputesCount()).then((res) => {
      setCount(res);
      setCountLoading(false);
    });
    reload();
  }, []);


  const reload = (newType = type) => {
    // dispatch(getDisputesCount()).then((res) => {
    //   setCount(res);
    // });
    setDisputesLoading(true);
    setCurrentPage(1);
    dispatch(getDisputesForStar(1, newType)).then((res) => {
      let count = res.count;
      let t = parseInt(count / 10);
      let r = count % 10;
      let total = t + (r > 0 ? 1 : 0);
      setTotalPages(total);
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  const getUserDataAnotherPage = (pageNo) => {
    setDisputesLoading(true);
    dispatch(getDisputesForStar(pageNo, type)).then((res) => {
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  let childProps = {
    disputes,
    profile,
    user,
    count,
    countLoading,
    disputesLoading,
    setType,
    type,
    reload,
    totalPages,
    currentPage,
    setCurrentPage,
    getUserDataAnotherPage,
    setFilters,
    filters,
    setTotalPages
  };

  if (countLoading) return <CircularCenteredLoader />;
  return <DisputesStar {...childProps} />;
}
