import {
  Box,
  Button,
  Container,
  Hidden,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { MEDIATOR } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import ArbitratorCommonContent from "features/singleDispute/ArbitratorCommonContent";
import ArbitratorStaticComponent from "features/singleDispute/ArbitratorStaticComponent";
import ArbitratorUpload from "features/singleDispute/ArbitratorUpload";
import SecretariatReviewStatus from "features/singleDispute/SecretariatReviewStatus";
import UploadSignedDoc from "features/singleDispute/UploadSignedDoc";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateDispute } from "slices/disputeSlice";
import ClosedDispute from "../ArbitratorHotstar/ClosedDispute";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

// function getSteps() {
//   return [
//     "Conflict of interest",
//     //"Static data 2",
//     "Arbitrator Upload",
//     "In Review By Secretariat",
//     "Upload Signed Doc",
//     "Complete",
//   ];
// }

function getSteps() {
  return [
    <FormattedMessage id="pages.arbitrator.conflict" />,
    <FormattedMessage id="pages.arbitrator.upload" />,
    // <FormattedMessage id="pages.arbitrator.review" />,
    // <FormattedMessage id="pages.arbitrator.uploadSigned" />,
    <FormattedMessage id="pages.arbitrator.complete" />,
  ];
}

function getStep(arbitrator_status, status) {
  if (arbitrator_status === "agree") return 1;
  if (arbitrator_status === "webnyay_upload") return 1;
  if (status === "closed") return 5;
  if (status === "claimant_complete") return 0;
  //if (status === "price_agreement") return 1;

  if (status === "award_upload") return 3;
  if (status === "complete") return 4;
  // if (status === "paid") return 6;

  return 0;
}

export default function ArbitratorSingleDispute({ activeDispute, profile }) {
  const classes = useStyles();

  let history = useHistory();
  let dipatch = useDispatch();
  let activeStep = getStep(
    activeDispute.arbitrator_status,
    activeDispute.status
  );

  // let award_type = "Decision";
  let award_type = "Award";

  if (profile.profile_type === MEDIATOR) {
    award_type = "Mediator Upload";
  }
  function getStepContent(step, dispute, profile) {
    switch (step) {
      case 0:
        return (
          <ArbitratorStaticComponent
            onClickHandler={updateDisputeForProfessionalUpload}
          />
        );
      // case 1:
      //   return <ArbitratorStaticComponent />;
      case 1:
        return <ArbitratorUpload dispute={dispute} award_type={award_type} />;
      case 2:
        return <SecretariatReviewStatus status="wait" profile={profile} />;
      case 3:
        return <UploadSignedDoc dispute={dispute} profile={profile} />;

      case 4:
        return (
          <Box alignSelf="flex-end">
            <Box height="1rem" />
            <Button
              color="primary"
              variant="contained"
              size="large"
              className={classes.closeButton}
            >
              <FormattedMessage id="pages.arbitrator.closeDispute" />
            </Button>
          </Box>
        );
      case 5:
        return <ClosedDispute activeDispute={dispute} />;
      default:
        return "Unknown step";
    }
  }

  function updateDisputeForProfessionalUpload() {
    dipatch(
      updateDispute({
        id: activeDispute.id,
        arbitrator_status: "agree",
      })
    );
  }

  const steps = getSteps();
  return (
    <Container maxWidth="lg">
      <Box display="flex">
        <Box className={classes.root}>
          <Box height="6rem"></Box>
          <Hidden smDown>
            <Box>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                style={{ padding: 0, background: "none" }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Hidden>
          <Box height="2rem"></Box>
        </Box>
        <Hidden smDown>
          <Box width="4rem"></Box>
        </Hidden>
        <Box flexGrow={1}>
          <Box height="2rem"></Box>

          <Button
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              // history.push("/disputes");
              history.goBack();
            }}
          >
            <FormattedMessage id="buttons.back" />
          </Button>
          <Box height="2rem"></Box>
          <ArbitratorCommonContent
            activeDispute={activeDispute}
            step={activeStep}
          />
          <Box>{getStepContent(activeStep, activeDispute, profile)}</Box>
        </Box>
      </Box>
    </Container>
  );
}
