import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useBodyBackgroundChange } from "hooks";
import React from "react";
import { useParams } from "react-router-dom";
import { backgrounds } from "contexts/BackgroundContext";
import BlackPen from "images/blackpen.svg";
import Stepper from "./Stepper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem",
    },
  },
  loanbox: {
    border: "1px solid #ebebeb",
    borderRadius: 8,
    width: "fit-content",
    padding: "1rem 4rem 1rem 1.6rem",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 3rem 0.5rem 1.2rem",
    },
  },
  loanid: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blackheading: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  transparentBox: {
    border: "1px solid #F2F2F2",
    borderRadius: 4,
    padding: "2rem 3rem",
    height: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 2rem",
    },
  },
  transparentBoxHeading: {
    color: "#454545",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  transparentBoxText: {
    color: "#454545",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  penImg: {
    marginLeft: "3rem",
    height: 16,
    [theme.breakpoints.down("sm")]: {
      height: 14,
    },
    [theme.breakpoints.down("xs")]: {
      height: 14,
      float: "right",
      marginLeft: 0,
    },
  },
  greybg: {
    background: "#F2F2F2",
    border: "1px solid #BDBDBD",
    borderRadius: 4,
    padding: "0.5rem 1rem 0.7rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.2rem 0.6rem 0.3rem 0.6rem",
    },
  },
  greytext: {
    color: "#4B5563",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  greybgHeading: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  blackHeading: {
    color: "#333333",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  draftBlack: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 16,
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  underlinedBlue: {
    color: "#1D4ED8",
    textDecoration: "underline",
  },
  viewbtn: {
    border: "1px solid #1D4ED8",
    textTransform: "none",
    padding: "5px 25px",
  },
  viewbtntext: {
    color: "#1D4ED8",
    fontFamily: `'Poppins',sans-serif`,
    fontWeight: 500,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  greentext: {
    color: "#047857",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  orangetext: {
    color: "#F2994A",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  closeBntText: {
    color: "white",
    fontFamily: `'Poppins',sans-serif`,
    fontWeight: 500,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
}));

const LoanContainer = () => {
  useBodyBackgroundChange(backgrounds.WHITE);
  const classes = useStyles();
  const { loan_id } = useParams();

  const handleViewBtnClick = (e) => {};

  return (
    <Box className={classes.root}>
      <Box className={classes.loanbox}>
        <Typography className={classes.loanid}>Loan ID</Typography>
        <Typography className={classes.blackheading}>{loan_id}</Typography>
      </Box>
      <Grid container spacing={2} style={{ boxSizing: "border-box" }}>
        <Grid item sm={6} xs={12} style={{ boxSizing: "border-box" }}>
          <Box className={classes.transparentBox}>
            <Typography className={classes.transparentBoxHeading}>
              Recipient's Details
              <img src={BlackPen} alt="edit" className={classes.penImg} />
            </Typography>
            <Typography className={classes.transparentBoxText}>
              Rakesh Suri
            </Typography>
            <Typography className={classes.transparentBoxText}>
              Email: ramsingh12@gmail.com
            </Typography>
            <Typography className={classes.transparentBoxText}>
              Phone: 9650312556
            </Typography>
            <Typography className={classes.transparentBoxText}>
              Address: House No. 1241, Sector 18 C, Chandigarh
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12} style={{ boxSizing: "border-box" }}>
          <Box className={classes.transparentBox}>
            <Typography className={classes.transparentBoxHeading}>
              Summary (300 words)
              <img src={BlackPen} alt="edit" className={classes.penImg} />
            </Typography>
            <Typography className={classes.transparentBoxText}>
              ...add summary
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box height="4rem" />

      <Stepper />

      <Box height="8rem" />
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.greybg}
      >
        <Grid item xs>
          <Typography className={classes.greytext}>STEP 01</Typography>
          <Typography className={classes.greybgHeading}>
            Draft Notice
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.greytext}>Notice ID</Typography>
          <Typography className={classes.greybgHeading}>199028</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.draftBlack}>
            Drafted on: 15/12/2020
          </Typography>
          <Typography className={classes.draftBlack}>
            Drafted by:{" "}
            <span className={classes.underlinedBlue}>Lavanya Singh</span>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.blackHeading}>
            Draft complete
          </Typography>
        </Grid>
        <Grid item xs>
          <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
            <Button variant="outlined" className={classes.viewbtn}>
              <Typography className={classes.viewbtntext}>View</Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
      <Box height={25} />
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.greybg}
      >
        <Grid item xs>
          <Typography className={classes.greytext}>STEP 02</Typography>
          <Typography className={classes.greybgHeading}>1 Day</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.greytext}>Notice ID</Typography>
          <Typography className={classes.greybgHeading}>277889</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.draftBlack}>
            Drafted on: 16/12/2020
          </Typography>
          <Typography className={classes.draftBlack}>
            Drafted by:{" "}
            <span className={classes.underlinedBlue}>Lavanya Singh</span>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.blackHeading}>SMS sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 16/12/2022
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            WhatsApp sent
          </Typography>
          <Typography className={classes.greentext}>
            Delivered - 16/12/2020 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 16/12/2020 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>Email sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 16/12/2020 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 16/12/2020 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            Mail Delivery
          </Typography>
          <Typography className={classes.greentext}>
            Delivered - 16/12/2020 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Collected by - Anju Suri
          </Typography>
        </Grid>
        <Grid item xs>
          <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
            <Button variant="outlined" className={classes.viewbtn}>
              <Typography className={classes.viewbtntext}>View</Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
      <Box height={25} />
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.greybg}
      >
        <Grid item xs>
          <Typography className={classes.greytext}>STEP 03</Typography>
          <Typography className={classes.greybgHeading}>30 Days</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.greytext}>Notice ID</Typography>
          <Typography className={classes.greybgHeading}>227899</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.draftBlack}>
            Drafted on: 1/01/2021
          </Typography>
          <Typography className={classes.draftBlack}>
            Drafted by:{" "}
            <span className={classes.underlinedBlue}>Lavanya Singh</span>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.blackHeading}>SMS sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/01/2021
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            WhatsApp sent
          </Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/01/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 1/01/2021 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>Email sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/01/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 1/01/2021 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            Mail Delivery
          </Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/01/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Collected by - Manju Ram
          </Typography>
        </Grid>
        <Grid item xs>
          <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
            <Button variant="outlined" className={classes.viewbtn}>
              <Typography className={classes.viewbtntext}>View</Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
      <Box height={25} />
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.greybg}
      >
        <Grid item xs>
          <Typography className={classes.greytext}>STEP 04</Typography>
          <Typography className={classes.greybgHeading}>60 Days</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.greytext}>Notice ID</Typography>
          <Typography className={classes.greybgHeading}>435899</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.draftBlack}>
            Drafted on: 1/03/2021
          </Typography>
          <Typography className={classes.draftBlack}>
            Drafted by:{" "}
            <span className={classes.underlinedBlue}>Lavanya Singh</span>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.blackHeading}>SMS sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/03/2021
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            WhatsApp sent
          </Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/03/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 1/03/2021 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>Email sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/03/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 1/03/2021 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            Mail Delivery
          </Typography>
          <Typography className={classes.greentext}>
            Delivered - 1/03/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Collected by - Anju Suri
          </Typography>
        </Grid>
        <Grid item xs>
          <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
            <Button
              variant="outlined"
              className={classes.viewbtn}
              onClick={handleViewBtnClick}
            >
              <Typography className={classes.viewbtntext}>View</Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
      <Box height={25} />
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.greybg}
      >
        <Grid item xs>
          <Typography className={classes.greytext}>STEP 05</Typography>
          <Typography className={classes.greybgHeading}>90 Days</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.greytext}>Notice ID</Typography>
          <Typography className={classes.greybgHeading}>77820</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.draftBlack}>
            Drafted on: 21/08/2021
          </Typography>
          <Typography className={classes.draftBlack}>
            Drafted by:{" "}
            <span className={classes.underlinedBlue}>Lavanya Singh</span>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.blackHeading}>SMS sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 21/08/2021
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            WhatsApp sent
          </Typography>
          <Typography className={classes.greentext}>
            Delivered - 21/08/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 21/08/2021 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>Email sent</Typography>
          <Typography className={classes.greentext}>
            Delivered - 21/08/2021 | 9:58 PM
          </Typography>
          <Typography className={classes.greentext}>
            Read - 21/08/2021 | 10:58 PM
          </Typography>
          <Box height={15} />
          <Typography className={classes.blackHeading}>
            Mail Delivery
          </Typography>
          <Typography className={classes.orangetext}>
            Transit Gateway
          </Typography>
          <Typography className={classes.orangetext}>
            Estimated Delivery - 24/08/2021
          </Typography>
        </Grid>
        <Grid item xs>
          <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
            <Button variant="outlined" className={classes.viewbtn}>
              <Typography className={classes.viewbtntext}>View</Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
      <Box height="3rem" />
      <Box style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            background: "#1D4ED8",
            textTransform: "none",
            padding: "8px 15px",
          }}
        >
          <Typography className={classes.closeBntText}>Close Thread</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default LoanContainer;
