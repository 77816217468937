import {
  Box,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DuoIcon from "@material-ui/icons/Duo";
import { ARBITRATION } from "app/constants";
import { containerContext } from "features/Chat/ChatContainer";
import Files from "features/Chat/Files";
import FilesModal from "features/Chat/FilesModal";
import Recordings from "features/Chat/Recordings";
import Scheduler from "features/Chat/Scheduler";
import Search from "features/Chat/Search";
import FormattedMessage from "features/common/TranslatedMessage";
import React, { useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { isDifc, isMSME, isRv, isSebi, isWebnyay } from "Routes/Routes";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";

export default function ChatDoc({
  classes,
  setStep,
  smallScreen,
  selected,
  profileType,
}) {
  const [open, setOpen] = React.useState(false);
  const [isRecordingOrFiles, setIsRecordingOrFiles] = React.useState(true);
  const theme = useTheme();
  const activeDispute = useSelector((state) => state.disputes.activeDispute);
  const profile = useSelector((state) => state.profile, shallowEqual);

  const { showVC, setShowVC, setVcType } = useContext(containerContext);
  const { activeGroup, groups } = useSelector(
    (state) => state.groups,
    shallowEqual
  );

  const currentGroup = groups.filter((group) => group._id === activeGroup)[0];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterAudioFiles = (files) => {
    return files.filter((file) => file.typeGroup !== "audio");
  };

  const isArbitrator = activeDispute.flow_type === ARBITRATION;
  if (!currentGroup) return null;
  return (
    <Box className={classes.rightDoc}>
      <Box m="0 0 0 0" textAlign="center">
        <Box display="flex" justifyContent="flex-start">
          {smallScreen && (
            <IconButton
              onClick={() => {
                setStep(1);
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
        </Box>
        <Divider />
        <Search setStep={setStep} smallScreen={smallScreen} />
        <Divider />
        <Box height="0.7rem"></Box>
        <Scheduler selected={selected} profileType={profileType} profile={profile}/>
        <Box height="0.7rem"></Box>

        <Divider />
        {/* <Box height="0.7rem"></Box> */}
        <Box className="hearingroomstep4">
          <Box
            p="0 4rem 0 1rem"
            display="flex"
            className={classes.vcBox}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowVC(true);
              setVcType("dyte");
            }}
          >
            <Typography variant="subtitle1">
              <FormattedMessage id="pages.chat.dyte" />
            </Typography>
            <Box flexGrow={1}></Box>

            <Box justifySelf="flex-end">
              <DuoIcon style={{ color: theme.palette.primary.main }} />
            </Box>
          </Box>
        </Box>
        {/* <Box height="1rem" style={isDifc ? { display: "none" } : null} /> */}
        {(isRv || isWebnyay) && !isDifc && !isSebi && !isMSME ? (
          <Box
            p="0 4rem 0 1rem"
            display="flex"
            className={classes.vcBox}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowVC(true);
              setVcType("zoom");
            }}
          >
            <Typography variant="subtitle1">Join Zoom</Typography>
            <Box flexGrow={1}></Box>

            <Box justifySelf="flex-end">
              <DuoIcon style={{ color: theme.palette.primary.main }} />
            </Box>
          </Box>
        ) : null}

        {/* <Box height="0.7rem"></Box> */}
        <Divider />
        {/* <Box height="2rem"></Box> */}
        {/* <Recordings />
        <Divider /> */}
        <div className={classes.recordingFilesWrapper}>
          <Box
            display="flex"
            className={
              isRecordingOrFiles
                ? classes.isSelectedClassRecordingFiles
                : classes.notSelectedClassRecordingFiles
            }
            p="0 0 0 1rem"
            onClick={() => setIsRecordingOrFiles(true)}
          >
            <VideoLibraryIcon />
            <Box width="0.4rem"></Box>
            <Typography variant="subtitle1">Recordings</Typography>
          </Box>
          <Box
            display="flex"
            className={
              isRecordingOrFiles
                ? classes.notSelectedClassRecordingFiles
                : classes.isSelectedClassRecordingFiles
            }
            p="0 0 0 1rem"
            onClick={() => setIsRecordingOrFiles(false)}
          >
            <PermMediaIcon />
            <Box width="0.4rem"></Box>
            <Typography variant="subtitle1">Shared Media</Typography>
          </Box>
        </div>
        {isRecordingOrFiles ? (
          <Recordings
            isRecordingOrFiles={isRecordingOrFiles}
            setIsRecordingOrFiles={setIsRecordingOrFiles}
          />
        ) : (
          <Files
            handleOpen={handleOpen}
            isRecordingOrFiles={isRecordingOrFiles}
            filterAudioFiles={filterAudioFiles}
            setIsRecordingOrFiles={setIsRecordingOrFiles}
          />
        )}
        {/* <Box height="2rem"></Box> */}
        {/* <Files handleOpen={handleOpen} filterAudioFiles={filterAudioFiles} /> */}
        <FilesModal
          open={open}
          handleClose={handleClose}
          filterAudioFiles={filterAudioFiles}
        />
      </Box>
    </Box>
  );
}
