import React from "react";

import { Box, Container, makeStyles } from "@material-ui/core";

import {
  RESPONDENT,
  ADVOCATE_RV,
  RESPONDENT_ADVOCATE_COMPLETE,
} from "app/constants";
import NewDetails from "../Details/NewDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function RespondentStepperTiac({
  activeDispute,
  profile,
  activeCase,
  reload,
}) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box height="2rem"></Box>

      <NewDetails
        activeDispute={activeDispute}
        caseFileType={RESPONDENT}
        rvStatus={RESPONDENT_ADVOCATE_COMPLETE}
        profileType={ADVOCATE_RV}
        activeCase={activeCase}
        step={5}
        tiac={true}
        profile={profile}
        reload={reload}
      />

      <Box width="2rem"></Box>
    </Container>
  );
}
