import { Box } from "@material-ui/core";
import { ARBITRATOR, CLAIMANT, MEDIATOR, RESPONDENT } from "app/constants";
import React, { Fragment } from "react";
import { isMuthoot, isNbf } from "Routes/Routes";
import ArbitratorContianer from "./ArbitratorContianer";
import ClaimantFlowSteps from "./ClaimantFlowSteps";
import ClaimantFlowStepsNBF from "./ClaimantFlowSteps/ClaimantFlowStepsNBF";
import RespondentFlowSteps from "./RespondentFlowSteps/RespondentFlowSteps";

export default function SingleDispute({
  profile,
  activeCase,
  activeDispute,
  companyFlows,
}) {
  let flows = companyFlows.filter((companyFlow) => {
    return companyFlow.company_product === activeDispute.company_product;
  });

  if (
    (!isMuthoot && profile.profile_type === ARBITRATOR) ||
    (!isMuthoot && profile.profile_type === MEDIATOR) || 
    (isMuthoot && profile.profile_type === 'sub_admin')
  ) {
    return (
      <ArbitratorContianer activeDispute={activeDispute} profile={profile} activeCase={activeCase} />
    );
  }

  switch (activeCase.type) {
    case CLAIMANT:
      return (
        <Fragment>
          {!isMuthoot && <Box height="2rem"></Box>}
          {isNbf ? (
            <ClaimantFlowStepsNBF
              profile={profile}
              activeCase={activeCase}
              activeDispute={activeDispute}
              flows={flows}
            />
          ) : (
            <ClaimantFlowSteps
              profile={profile}
              activeCase={activeCase}
              activeDispute={activeDispute}
              flows={flows}
            />
          )}
        </Fragment>
      );
      break;

    case RESPONDENT:
    case 'io':
    case 'md':
    case 'nodal':
      return (
        <Fragment>
          {!isMuthoot && <Box height="2rem"></Box>}
          <RespondentFlowSteps
            profile={profile}
            activeCase={activeCase}
            activeDispute={activeDispute}
            flows={flows}
          />
        </Fragment>
      );
      break;

    default:
      return "Unknown step";
  }
}
