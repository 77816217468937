import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles, styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import debounce from "lodash.debounce";
import { Radio } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import Axios from "axios";
import FormattedMessage from "features/common/TranslatedMessage";
import {
  blockInvalidChar,
  getProfileTiacDispute,
  getTempDetails,
  StyledTextFieldTiac,
} from "features/TempTiacFormV2/ClaimantPage/questions";
import { updateDisputeTiac } from "features/TempTiacFormV2/UploadPage/questions";
import { editAnswerTiac, GetAnswersTiac } from "features/Tiacfinal/final";
import { useFormik } from "formik";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCountries } from "slices/profileSlice";
import * as Yup from "yup";
import { getRealhost } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },
  select: {
    "&:after": {
      borderBottomColor: "#2F80ED",
    },
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },

  text: {
    // "font-size": "18px",
    // color: "#2C3345",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    // color: "#2C3345",
    padding: "1rem",
    // fontFamily: `'Inter',sans-serif`,
    [theme.breakpoints.down("sm")]: {
      textalign: "justify",
    },
  },
  field: {
    background: "#F5F5F5",
  },
  field1: {
    background: "#FFFFFF",
  },

  text2: {
    "font-size": "18px",
    // fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },
  text3: {
    "font-size": "18px",
    // fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  paper: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  input1: {
    height: 180,
  },
  radioHeading: {
    fontSize: "18px",
    color: "#2C3345",
    // fontFamily: `'Inter',sans-serif`,
    lineHeight: "40px",
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  // .required("Email is required")
  // full_name: Yup.string().required("Full name is required"),
});

var signUpData = {};

const getOptions = () => {
  return { 1: "Yes/Да/Ҳа", 2: "No/Нет/Йўқ" };
};

const RadioCard = ({ option, selectedValue, handleChange }) => {
  // const detail = getOptionDetails(option);
  const options = getOptions();
  const classes = useStyles();

  let ran = Math.random();

  return (
    <Fragment>
      <Box display="flex" alignItems="flex-start">
        <StyledRadio
          checked={selectedValue === options[option]}
          onChange={handleChange}
          value={options[option]}
          color="primary"
          name={ran}
        />
        <Box mt="-2px">
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.radioHeading}
          >
            {options[option]}
          </Typography>
        </Box>
      </Box>

      <Box width="1rem"></Box>
    </Fragment>
  );
};
const getOptions2 = () => {
  return { 1: "1", 2: "3" };
};

export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#2F80ED",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

export function StyledRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const RadioCard2 = ({ option, selectedValue, handleChange, disabled }) => {
  // const detail = getOptionDetails(option);
  const options = getOptions2();
  const classes = useStyles();

  let ran = Math.random();

  return (
    <Fragment>
      <Box display="flex" alignItems="flex-start">
        <StyledRadio
          checked={selectedValue === options[option]}
          onChange={handleChange}
          value={options[option]}
          color="primary"
          name={ran}
          disabled={disabled}
        />
        <Box mt="-2px">
          <Typography
            variant="subtitle1"
            color="primary"
            style={disabled ? { color: "#a7a7a7" } : {}}
            className={classes.radioHeading}
          >
            {options[option]}
          </Typography>
        </Box>
      </Box>

      <Box width="1rem"></Box>
    </Fragment>
  );
};

async function getCaseFile(email, disputeId) {
  return await instance({
    method: "get",
    url: "/api/tiaccasefileanonymous/",
    params: {
      email: email,
      id: disputeId,
    },
  });
}
async function getQuestions(email) {
  return await instance({
    method: "get",
    url: "/tiacanonymousquestions",
    params: {
      email: email,
    },
  });
}

export async function removeArbitrators(email, disputeId, respLawyer = false) {
  return await instance({
    method: "get",
    url: "/tiacformremovearbi/" + disputeId + "/",
    params: {
      email: email,
      respLawyer: respLawyer,
    },
  });
}

const ArbitratorPage = ({
  setActiveStep,
  submitDetailsSignal,
  completedSteps,
}) => {
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();

  let history = useHistory();

  function autoFill(fetchedProfile) {
    formik.setFieldTouched("email", false);
    formik.setFieldTouched("full_name", false);
    setNameError();
    formik.setFieldTouched("nationality", false);
    formik.setFieldTouched("phone_number", false);
    formik.setFieldTouched("address", false);
    formik.setFieldTouched("city", false);
    formik.setFieldTouched("region", false);
    formik.setFieldTouched("postal_code", false);
    formik.setFieldValue("email", fetchedProfile.user.email);
    formik.setFieldValue(
      "full_name",
      fetchedProfile.name ? fetchedProfile.name : ""
    );
    formik.setFieldValue(
      "phone_number",
      fetchedProfile.phone_number ? fetchedProfile.phone_number : ""
    );
    formik.setFieldValue(
      "address",
      fetchedProfile.address ? fetchedProfile.address : ""
    );
    formik.setFieldValue(
      "city",
      fetchedProfile.city ? fetchedProfile.city : ""
    );
    formik.setFieldValue(
      "region",
      fetchedProfile.region ? fetchedProfile.region : ""
    );
    formik.setFieldValue(
      "postal_code",
      fetchedProfile.postal_code ? fetchedProfile.postal_code : ""
    );
    setCountry(fetchedProfile.country ? fetchedProfile.country : "-");
    setFetchedProfile();
  }

  function getProfiles(email, cancelToken) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/tiacanonymousemailautofill/?email=${email}`,
          { cancelToken: cancelToken.token }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const handleNameChange = (name) => {
    if (name.trim() == "") {
      setNameError("Required Field");
    } else {
      setNameError();
    }
  };

  const getAutofillProfiles = (email) => {
    try {
      if (email == "") {
        setEmailError("Required Field");
      } else {
        setEmailError();
      }
      // let email = e.traget.value;
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email &&
          res.results[0].profile_type == "arbitrator_rv"
        ) {
          let profile = res.results[0];
          profile["name"] =
            res.results[0].user.first_name +
            " " +
            res.results[0].user.last_name;
          setFetchedProfile(profile);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = useCallback(debounce(getAutofillProfiles, 500), []);

  let id = localStorage.getItem("dispute_id");
  let email = localStorage.getItem("email");

  const [casefile, setCasefile] = useState();

  const [answerSet, setAnswerSet] = useState(false);
  const [questionOne, setQuestionOne] = useState();
  const [questionTwo, setQuestionTwo] = useState();
  const [questionThree, setQuestionThree] = useState();
  const [questionFour, setQuestionFour] = useState();
  const [questionFive, setQuestionFive] = useState();
  const [questionSix, setQuestionSix] = useState();

  const [questionNew, setQuestionNew] = useState();

  const [answerOne, setanswerOne] = useState();
  const [answerTwo, setanswerTwo] = useState();
  const [answerThree, setanswerThree] = useState();
  const [answerFour, setanswerFour] = useState();
  const [answerFive, setanswerFive] = useState();
  const [answerSix, setanswerSix] = useState();

  const [answerNew, setanswerNew] = useState();

  React.useEffect(() => {
    getCaseFile(email, id)
      .then((res) => {
        setCasefile(res.data.results[0]);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, [email, id]);
  React.useEffect(() => {
    getQuestions(email).then(
      (res) => {
        setQuestionOne(
          res.data.results.find((item) => item.display_order_id == 61)
        );
        setQuestionTwo(
          res.data.results.find((item) => item.display_order_id == 62)
        );
        setQuestionThree(
          res.data.results.find((item) => item.display_order_id == 63)
        );
        setQuestionNew(
          res.data.results.find((item) => item.display_order_id == 64)
        );
        setQuestionFour(
          res.data.results.find((item) => item.display_order_id == 68)
        );
        setQuestionFive(
          res.data.results.find((item) => item.display_order_id == 69)
        );
        setQuestionSix(
          res.data.results.find((item) => item.display_order_id == 610)
        );
      },
      (error) => {
        console.log("err", error);
      }
    );
  }, [email]);
  const [numberofarbitrators, setNumberofarbitrators] = useState(false);

  const [dispute, setDispute] = useState();

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  useEffect(() => {
    getTempDetails(id).then((res) => {
      let fetchTempProfile = {};
      if (res && res.data) {
        if (res.data.arbitrator_email) {
          fetchTempProfile.user = { email: res.data.arbitrator_email };
        }
        if (res.data.arbitrator_name) {
          fetchTempProfile.name = res.data.arbitrator_name;
        }
        if (res.data.arbitrator_phone_number) {
          fetchTempProfile.phone_number = res.data.arbitrator_phone_number;
        }
        if (res.data.arbitrator_address) {
          fetchTempProfile.address = res.data.arbitrator_address;
        }
        if (res.data.arbitrator_city) {
          fetchTempProfile.city = res.data.arbitrator_city;
        }
        if (res.data.arbitrator_region) {
          fetchTempProfile.region = res.data.arbitrator_region;
        }
        if (res.data.arbitrator_postal_code) {
          fetchTempProfile.postal_code = res.data.arbitrator_postal_code;
        }
        if (res.data.arbitrator_country) {
          fetchTempProfile.country = res.data.arbitrator_country;
        }
        if (fetchTempProfile.user) {
          setCanEnterArbitratorDetails(true);
          autoFill(fetchTempProfile);
        }
      }
    });
  }, [autoFill, id]);

  React.useEffect(() => {
    getDisputeTiac(localStorage.getItem("email"), id)
      .then((res) => {
        if (res.data.results[0].id) {
          setDispute(res.data.results[0]);
          if (res.data.results[0].rv_arbitrator_step_complete)
            getProfileTiacDispute(
              localStorage.getItem("email"),
              id,
              "arbitrator"
            ).then((res) => {
              if (res.data.id) {
                // setFetchedProfile(res.data);
                // autoFill(res.data);
                setCanEnterArbitratorDetails(true);
              }
            });
        } else history.push("/RFA");
      })
      .catch((e) => {
        history.push("/RFA");
      });
  }, [history, id]);

  const classes = useStyles();

  const [RadioError1, setRadioError1] = useState();
  const [RadioError2, setRadioError2] = useState();
  const [RadioError3, setRadioError3] = useState();

  const [RadioErrorNew, setRadioErrorNew] = useState();

  const [emailError, setEmailError] = useState();
  const [nameError, setNameError] = useState();

  const [radioAnswer1, setRadioAnswer1] = useState();
  const [radioAnswer2, setRadioAnswer2] = useState();
  const [radioAnswer3, setRadioAnswer3] = useState();

  const [radioAnswerNew, setRadioAnswerNew] = useState();

  const [canEnterArbitratorDetails, setCanEnterArbitratorDetails] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [allCountries, setallCountries] = useState([]);
  const [country, setCountry] = useState("-");

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  const handleChangeRadio1 = (event) => {
    setRadioAnswer1(event.target.value);
    if (event.target.value == "Yes/Да/Ҳа") {
      setNumberofarbitrators(true);
      setRadioAnswerNew("");
      setRadioErrorNew();
    } else {
      formik.setFieldValue("secondagreemet", "");
      // formik.values.secondagreemet = " ";
      setNumberofarbitrators(false);
    }
    setRadioError1();
  };
  const handleChangeRadio2 = (event) => {
    setRadioAnswer2(event.target.value);
    if (event.target.value == "Yes/Да/Ҳа") {
      setCanEnterArbitratorDetails(true);
    } else {
      setCanEnterArbitratorDetails(false);
    }
    setRadioError2();
  };
  const handleChangeRadio3 = (event) => {
    setRadioAnswer3(event.target.value);
    setRadioError3();
  };
  const handleChangeRadioNew = (event) => {
    setRadioAnswerNew(event.target.value);
    setRadioErrorNew();
  };

  async function UploadAnswer(answer, casefile, question) {
    return await instance.post(
      `/api/tiacanonymousanswer/?email=${localStorage.getItem("email")}`,
      {
        question: question.id,
        case: casefile.id,
        value: answer,
      }
    );
  }

  async function TiaccreateprofileDisputeId() {
    setLoading(true);
    let data = {
      email: formik.values.email,
      profile_type: "arbitrator_rv",
      dispute_id: id,
      group : getRealhost()
    };
    if (formik.values.full_name.trim() != "") {
      data.full_name = formik.values.full_name;
    }
    if (formik.values.address.trim() != "") {
      data.address = formik.values.address;
    }
    if (formik.values.city.trim() != "") {
      data.city = formik.values.city;
    }
    if (formik.values.region.trim() != "") {
      data.region = formik.values.region;
    }
    if (formik.values.postal_code.trim() != "") {
      data.postal_code = formik.values.postal_code;
    }
    if (country && country != "-") {
      data.country = country;
    } else {
      data.country = null;
    }
    let ph = "" + formik.values.phone_number;
    if (ph.trim() != "") {
      data.phone_number = ph;
    }
    return await instance.post("/tiaccreateprofile/", data);
  }

  async function saveTempDetailsArbitrator(data) {
    return await instance.post(`/tiacarbitratordetails/`, {
      dispute_id: data.dispute_id,
      email: data.email,
      full_name: data.full_name,
      address: data.address,
      city: data.city,
      region: data.region,
      postal_code: data.postal_code,
      country: data.country,
      phone_number: data.phone_number,
      fax: data.fax,
    });
  }

  const formik = useFormik({
    initialValues: {
      firstagreement: "",
      secondagreemet: "",
      nominate: "",
      full_name: "",
      address: "",
      city: "",
      region: "",
      postal_code: "",
      phone_number: "",
      email: "",
      jobdesignation: "",
      specific: "",
      authorize: "",
    },
    validationSchema: SignupSchema,

    onSubmit: async (values) => {
      if (
        !radioAnswer1 ||
        !radioAnswer2 ||
        !radioAnswer3 ||
        (!radioAnswerNew && !numberofarbitrators) ||
        (canEnterArbitratorDetails &&
          (formik.values.email.trim() == "" ||
            formik.values.full_name.trim() == ""))
      ) {
        if (!radioAnswer1) setRadioError1("Required Field");
        if (!radioAnswer2) setRadioError2("Required Field");
        if (!radioAnswer3) setRadioError3("Required Field");
        if (!radioAnswerNew && !numberofarbitrators)
          setRadioErrorNew("Required Field");
        if (formik.values.email == "") setEmailError("Required Field");
        if (formik.values.full_name.trim() == "")
          setNameError("Required Field");
      } else {
        let {
          firstagreement,
          secondagreemet,
          nominate,
          full_name,
          address,
          city,
          region,
          postal_code,
          phonenumber,
          // email,
          jobdesignation,
          specific,
          authorize,
        } = values;

        signUpData = values;
        let tempData = {};
        if (canEnterArbitratorDetails) {
          tempData = {
            dispute_id: id,
            email: formik.values.email,
            full_name: formik.values.full_name,
            nationality: formik.values.nationality,
            address: formik.values.address,
            city: formik.values.city,
            region: formik.values.region,
            postal_code: formik.values.postal_code,
            country: country == "-" ? null : country,
            phone_number: formik.values.phone_number,
            fax: formik.values.fax,
          };
        } else {
          tempData = {
            dispute_id: id,
            email: "",
            full_name: "",
            nationality: "",
            address: "",
            city: "",
            region: "",
            postal_code: "",
            country: "",
            phone_number: "",
            fax: "",
          };
        }

        saveTempDetailsArbitrator(tempData)
          .then((res) => {
            if (answerOne) {
              editAnswerTiac(
                email,
                id,
                answerOne.id,
                radioAnswer1,
                answerOne.case,
                answerOne.question
              );
            } else {
              UploadAnswer(radioAnswer1, casefile, questionOne);
            }
            if (answerNew) {
              editAnswerTiac(
                email,
                id,
                answerNew.id,
                radioAnswerNew,
                answerNew.case,
                answerNew.question
              );
            } else {
              UploadAnswer(radioAnswerNew, casefile, questionNew);
            }
            if (answerTwo) {
              editAnswerTiac(
                email,
                id,
                answerTwo.id,
                signUpData.secondagreemet,
                casefile.id,
                questionTwo.id
              );
            } else {
              UploadAnswer(signUpData.secondagreemet, casefile, questionTwo);
            }
            if (answerThree) {
              editAnswerTiac(
                email,
                id,
                answerThree.id,
                radioAnswer2,
                casefile.id,
                questionThree.id
              );
            } else {
              UploadAnswer(radioAnswer2, casefile, questionThree);
            }
            if (answerFour) {
              editAnswerTiac(
                email,
                id,
                answerFour.id,
                signUpData.jobdesignation,
                casefile.id,
                questionFour.id
              );
            } else {
              UploadAnswer(signUpData.jobdesignation, casefile, questionFour);
            }
            if (answerFive) {
              editAnswerTiac(
                email,
                id,
                answerFive.id,
                signUpData.specific,
                casefile.id,
                questionFive.id
              );
            } else {
              UploadAnswer(signUpData.specific, casefile, questionFive);
            }
            if (answerSix) {
              editAnswerTiac(
                email,
                id,
                answerSix.id,
                radioAnswer3,
                casefile.id,
                questionSix.id
              );
            } else {
              UploadAnswer(radioAnswer3, casefile, questionSix);
            }
            if (completedSteps[4]) {
              updateDisputeTiac(
                {
                  id: id,
                  tiac_dispute_step: "arbitrator_complete",
                },
                email
              ).then(() => {
                setActiveStep(6);
              });
            } else {
              setActiveStep(6);
              localStorage.setItem("arbi_step", true);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            alert(error.response.data.message);
          });
      }
    },
  });

  useEffect(() => {
    if (submitDetailsSignal) {
      let tempData = {};
      if (canEnterArbitratorDetails) {
        tempData = {
          dispute_id: id,
          email: formik.values.email,
          full_name: formik.values.full_name,
          nationality: formik.values.nationality,
          address: formik.values.address,
          city: formik.values.city,
          region: formik.values.region,
          postal_code: formik.values.postal_code,
          country: country == "-" ? null : country,
          phone_number: formik.values.phone_number,
          fax: formik.values.fax,
        };
      } else {
        tempData = {
          dispute_id: id,
          email: "",
          full_name: "",
          nationality: "",
          address: "",
          city: "",
          region: "",
          postal_code: "",
          country: "",
          phone_number: "",
          fax: "",
        };
      }
      if (nameError || emailError) {
        localStorage.removeItem("arbi_step");
        if (completedSteps[4]) {
          updateDisputeTiac(
            {
              id: id,
              tiac_dispute_step: "upload_complete",
            },
            email
          ).then((resp) => {
            saveTempDetailsArbitrator(tempData)
              .then((res) => {
                if (answerOne) {
                  editAnswerTiac(
                    email,
                    id,
                    answerOne.id,
                    radioAnswer1,
                    answerOne.case,
                    answerOne.question
                  );
                } else {
                  UploadAnswer(radioAnswer1, casefile, questionOne);
                }
                if (answerNew) {
                  editAnswerTiac(
                    email,
                    id,
                    answerNew.id,
                    radioAnswerNew,
                    answerNew.case,
                    answerNew.question
                  );
                } else {
                  UploadAnswer(radioAnswerNew, casefile, questionNew);
                }
                if (answerTwo) {
                  editAnswerTiac(
                    email,
                    id,
                    answerTwo.id,
                    signUpData.secondagreemet,
                    casefile.id,
                    questionTwo.id
                  );
                } else {
                  UploadAnswer(
                    signUpData.secondagreemet,
                    casefile,
                    questionTwo
                  );
                }
                if (answerThree) {
                  editAnswerTiac(
                    email,
                    id,
                    answerThree.id,
                    radioAnswer2,
                    casefile.id,
                    questionThree.id
                  );
                } else {
                  UploadAnswer(radioAnswer2, casefile, questionThree);
                }
                if (answerFour) {
                  editAnswerTiac(
                    email,
                    id,
                    answerFour.id,
                    signUpData.jobdesignation,
                    casefile.id,
                    questionFour.id
                  );
                } else {
                  UploadAnswer(
                    signUpData.jobdesignation,
                    casefile,
                    questionFour
                  );
                }
                if (answerFive) {
                  editAnswerTiac(
                    email,
                    id,
                    answerFive.id,
                    signUpData.specific,
                    casefile.id,
                    questionFive.id
                  );
                } else {
                  UploadAnswer(signUpData.specific, casefile, questionFive);
                }
                if (answerSix) {
                  editAnswerTiac(
                    email,
                    id,
                    answerSix.id,
                    radioAnswer3,
                    casefile.id,
                    questionSix.id
                  );
                } else {
                  UploadAnswer(radioAnswer3, casefile, questionSix);
                }
                history.push("/resumerfa?random=" + Math.random());
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
                alert(error.response.data.message);
              });
          });
        } else {
          updateDisputeTiac(
            {
              id: id,
              tiac_dispute_step: null,
            },
            email
          ).then((resp) => {
            saveTempDetailsArbitrator(tempData)
              .then((res) => {
                if (answerOne) {
                  editAnswerTiac(
                    email,
                    id,
                    answerOne.id,
                    radioAnswer1,
                    answerOne.case,
                    answerOne.question
                  );
                } else {
                  UploadAnswer(radioAnswer1, casefile, questionOne);
                }
                if (answerNew) {
                  editAnswerTiac(
                    email,
                    id,
                    answerNew.id,
                    radioAnswerNew,
                    answerNew.case,
                    answerNew.question
                  );
                } else {
                  UploadAnswer(radioAnswerNew, casefile, questionNew);
                }
                if (answerTwo) {
                  editAnswerTiac(
                    email,
                    id,
                    answerTwo.id,
                    signUpData.secondagreemet,
                    casefile.id,
                    questionTwo.id
                  );
                } else {
                  UploadAnswer(
                    signUpData.secondagreemet,
                    casefile,
                    questionTwo
                  );
                }
                if (answerThree) {
                  editAnswerTiac(
                    email,
                    id,
                    answerThree.id,
                    radioAnswer2,
                    casefile.id,
                    questionThree.id
                  );
                } else {
                  UploadAnswer(radioAnswer2, casefile, questionThree);
                }
                if (answerFour) {
                  editAnswerTiac(
                    email,
                    id,
                    answerFour.id,
                    signUpData.jobdesignation,
                    casefile.id,
                    questionFour.id
                  );
                } else {
                  UploadAnswer(
                    signUpData.jobdesignation,
                    casefile,
                    questionFour
                  );
                }
                if (answerFive) {
                  editAnswerTiac(
                    email,
                    id,
                    answerFive.id,
                    signUpData.specific,
                    casefile.id,
                    questionFive.id
                  );
                } else {
                  UploadAnswer(signUpData.specific, casefile, questionFive);
                }
                if (answerSix) {
                  editAnswerTiac(
                    email,
                    id,
                    answerSix.id,
                    radioAnswer3,
                    casefile.id,
                    questionSix.id
                  );
                } else {
                  UploadAnswer(radioAnswer3, casefile, questionSix);
                }
                history.push("/resumerfa?random=" + Math.random());
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
                alert(error.response.data.message);
              });
          });
        }
      } else {
        saveTempDetailsArbitrator(tempData)
          .then((res) => {
            if (answerOne) {
              editAnswerTiac(
                email,
                id,
                answerOne.id,
                radioAnswer1,
                answerOne.case,
                answerOne.question
              );
            } else {
              UploadAnswer(radioAnswer1, casefile, questionOne);
            }
            if (answerNew) {
              editAnswerTiac(
                email,
                id,
                answerNew.id,
                radioAnswerNew,
                answerNew.case,
                answerNew.question
              );
            } else {
              UploadAnswer(radioAnswerNew, casefile, questionNew);
            }
            if (answerTwo) {
              editAnswerTiac(
                email,
                id,
                answerTwo.id,
                signUpData.secondagreemet,
                casefile.id,
                questionTwo.id
              );
            } else {
              UploadAnswer(signUpData.secondagreemet, casefile, questionTwo);
            }
            if (answerThree) {
              editAnswerTiac(
                email,
                id,
                answerThree.id,
                radioAnswer2,
                casefile.id,
                questionThree.id
              );
            } else {
              UploadAnswer(radioAnswer2, casefile, questionThree);
            }
            if (answerFour) {
              editAnswerTiac(
                email,
                id,
                answerFour.id,
                signUpData.jobdesignation,
                casefile.id,
                questionFour.id
              );
            } else {
              UploadAnswer(signUpData.jobdesignation, casefile, questionFour);
            }
            if (answerFive) {
              editAnswerTiac(
                email,
                id,
                answerFive.id,
                signUpData.specific,
                casefile.id,
                questionFive.id
              );
            } else {
              UploadAnswer(signUpData.specific, casefile, questionFive);
            }
            if (answerSix) {
              editAnswerTiac(
                email,
                id,
                answerSix.id,
                radioAnswer3,
                casefile.id,
                questionSix.id
              );
            } else {
              UploadAnswer(radioAnswer3, casefile, questionSix);
            }
            history.push("/resumerfa?random=" + Math.random());
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            alert(error.response.data.message);
          });
      }
    }
  }, [
    answerFive,
    answerFour,
    answerNew,
    answerOne,
    answerSix,
    answerThree,
    answerTwo,
    canEnterArbitratorDetails,
    casefile,
    completedSteps,
    country,
    email,
    emailError,
    formik.values.address,
    formik.values.city,
    formik.values.email,
    formik.values.fax,
    formik.values.full_name,
    formik.values.nationality,
    formik.values.phone_number,
    formik.values.postal_code,
    formik.values.region,
    history,
    id,
    nameError,
    questionFive,
    questionFour,
    questionNew,
    questionOne,
    questionSix,
    questionThree,
    questionTwo,
    radioAnswer1,
    radioAnswer2,
    radioAnswer3,
    radioAnswerNew,
    submitDetailsSignal,
  ]);

  React.useEffect(() => {
    if (
      questionOne &&
      questionTwo &&
      questionThree &&
      questionFour &&
      questionFive &&
      questionSix &&
      questionNew &&
      !answerSet
    )
      GetAnswersTiac(email, id).then((res) => {
        setAnswerSet(true);
        let t1 = res.data.results.find(
          (item) => item.question == questionOne.id
        );
        setRadioAnswer1(t1 ? t1.value : null);
        setNumberofarbitrators(t1 && t1.value == "Yes/Да/Ҳа" ? true : false);
        if (t1 && t1.value == "Yes/Да/Ҳа") {
          setRadioErrorNew();
        }
        setanswerOne(t1);
        let t2 = res.data.results.find(
          (item) => item.question == questionTwo.id
        );
        // setNumberofarbitrators(t2 ? t2.value : null);
        formik.setFieldValue("secondagreemet", t2 ? t2.value : "");
        setanswerTwo(t2);
        let t3 = res.data.results.find(
          (item) => item.question == questionThree.id
        );
        setRadioAnswer2(t3 ? t3.value : null);
        setanswerThree(t3);
        let t4 = res.data.results.find(
          (item) => item.question == questionFour.id
        );
        formik.setFieldValue("jobdesignation", t4 ? t4.value : "");
        setanswerFour(t4);
        let t5 = res.data.results.find(
          (item) => item.question == questionFive.id
        );
        formik.setFieldValue("specific", t5 ? t5.value : "");
        setanswerFive(t5);
        let t6 = res.data.results.find(
          (item) => item.question == questionSix.id
        );
        setRadioAnswer3(t6 ? t6.value : null);
        setanswerSix(t6);

        let t7 = res.data.results.find(
          (item) => item.question == questionNew.id
        );
        setRadioAnswerNew(t7 ? t7.value : null);
        setanswerNew(t7);
      });
  }, [
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    questionFive,
    questionSix,
    questionNew,
    answerSet,
    email,
    id,
    formik,
  ]);

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3} justify="center">
        {/* <Grid item xs></Grid> */}
        <Grid item xs={11}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" className={classes.text}>
                <FormattedMessage id="tiac.heading6" />
              </Typography>
              <Box height="1rem"></Box>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} justify="flex-start">
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.1. Is there any agreement on the number of
                      arbitrators?/Есть ли соглашение сторон в отношении числа
                      арбитров?/Томонлар ўртасида арбитрлар сони тўғрисида
                      келишув мавжудми?{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    {/* {formik.errors.firstagreement ? (
                                            <Alert severity="error">{formik.errors.firstagreement}</Alert>
                                        ) : null} */}
                    <Grid item md={5} sm={10} xs={12}>
                      <Box>
                        <RadioCard
                          option={1}
                          selectedValue={radioAnswer1}
                          handleChange={handleChangeRadio1}
                        />
                        {/* <Box height="1rem"></Box> */}
                        <RadioCard
                          option={2}
                          selectedValue={radioAnswer1}
                          handleChange={handleChangeRadio1}
                        />
                      </Box>
                      <Box style={{ padding: "0 1rem" }}>
                        {RadioError1 && (
                          <Alert severity="error">{RadioError1}</Alert>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.2. If there is an agreement, how many arbitrators?/Если
                      есть соглашение о числе арбитров, то укажите число
                      арбитров/Агар арбитрлар сони тўғрисида келишув бўлса,
                      арбитларнинг сонини кўрсатинг
                    </Typography>

                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        className={!numberofarbitrators ? classes.field : ""}
                        variant="outlined"
                        label="1,3,..."
                        type="number"
                        value={
                          numberofarbitrators
                            ? formik.values.secondagreemet
                            : ""
                        }
                        disabled={!numberofarbitrators}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          if (numberofarbitrators) {
                            formik.handleChange(e);
                          }
                        }}
                        margin="normal"
                        fullWidth
                        id="secondagreemet"
                        // label={<FormattedMessage id="user.email" />}
                        name="secondagreemet"
                        autoComplete="secondagreemet"
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.3. Do you wish to nominate someone to be an arbitrator
                      in this dispute?/Хотите ли Вы номинировать арбитра по
                      данному спору?/Ушбу низо учун арбитр номзодини кўрсатишни
                      хоҳлайсизми? <span className={classes.required}>*</span>
                    </Typography>
                    {/* {formik.errors.nominate ? (
                                            <Alert severity="error">{formik.errors.nominate}</Alert>
                                        ) : null} */}
                    <Grid item md={5} sm={10} xs={12}>
                      <Box>
                        <RadioCard
                          option={1}
                          selectedValue={radioAnswer2}
                          handleChange={handleChangeRadio2}
                        />
                        {/* <Box height="1rem"></Box> */}
                        <RadioCard
                          option={2}
                          selectedValue={radioAnswer2}
                          handleChange={handleChangeRadio2}
                        />
                      </Box>
                      {RadioError2 && (
                        <Box style={{ padding: "0 1rem" }}>
                          <Alert severity="error">{RadioError2}</Alert>
                        </Box>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.4. If there is no agreement on the number of
                      arbitrators, will you propose 1 or 3 arbitrators?/Если нет
                      соглашения о количестве арбитров, какое количество - 1 или
                      3 арбитра - Вы предлагаете?/Агар хакамлар сони бўйича
                      келишув бўлмаса, қандай сон - 1 еки 3 хакамни таклиф
                      қиласиз?{" "}
                      {!numberofarbitrators && (
                        <span className={classes.required}>*</span>
                      )}
                    </Typography>
                    <Grid item md={5} sm={10} xs={12}>
                      <Box>
                        <RadioCard2
                          option={1}
                          selectedValue={radioAnswerNew}
                          handleChange={handleChangeRadioNew}
                          disabled={numberofarbitrators}
                        />
                        {/* <Box height="1rem"></Box> */}
                        <RadioCard2
                          option={2}
                          selectedValue={radioAnswerNew}
                          handleChange={handleChangeRadioNew}
                          disabled={numberofarbitrators}
                        />
                      </Box>
                      <Box style={{ padding: "0 1rem" }}>
                        {RadioErrorNew && (
                          <Alert severity="error">{RadioErrorNew}</Alert>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.5. Email address/Адрес электронной почты/Электрон почта
                      манзили:{" "}
                      {canEnterArbitratorDetails && (
                        <span className={classes.required}>*</span>
                      )}
                    </Typography>

                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        className={
                          !canEnterArbitratorDetails ? classes.field : " "
                        }
                        variant="outlined"
                        margin="normal"
                        value={
                          canEnterArbitratorDetails ? formik.values.email : ""
                        }
                        fullWidth
                        id="email"
                        // label={<FormattedMessage id="user.email" />}
                        name="email"
                        autoComplete="email"
                        onBlur={formik.handleBlur}
                        disabled={!canEnterArbitratorDetails}
                        onChange={(e) => {
                          if (canEnterArbitratorDetails) {
                            formik.handleChange(e);
                            handleEmailChange(e.target.value);
                          }
                        }}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <Alert severity="error">{formik.errors.email}</Alert>
                      )}
                      {canEnterArbitratorDetails && emailError && (
                        <Alert severity="error">{emailError}</Alert>
                      )}
                    </Grid>

                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      {fetchedProfile && canEnterArbitratorDetails && (
                        <Typography
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            autoFill(fetchedProfile);
                          }}
                        >
                          Autofill details
                        </Typography>
                      )}
                    </Grid>
                    <Box height="1rem"></Box>

                    <Typography className={classes.text2}>
                      example@example.com
                    </Typography>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.6. Name of the nominated arbitrator/Имя арбитра/Арбитр
                      исм шарифи:{" "}
                      {canEnterArbitratorDetails && (
                        <span className={classes.required}>*</span>
                      )}
                    </Typography>

                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        className={
                          !canEnterArbitratorDetails ? classes.field : " "
                        }
                        variant="outlined"
                        value={
                          canEnterArbitratorDetails
                            ? formik.values.full_name
                            : ""
                        }
                        disabled={!canEnterArbitratorDetails}
                        onChange={(e) => {
                          if (canEnterArbitratorDetails) {
                            formik.handleChange(e);
                            handleNameChange(e.target.value);
                          }
                        }}
                        margin="normal"
                        fullWidth
                        id="full_name"
                        // label={<FormattedMessage id="user.email" />}
                        name="full_name"
                        autoComplete="full_name"
                        onBlur={formik.handleBlur}
                      />
                      {canEnterArbitratorDetails && nameError && (
                        <Alert severity="error">{nameError}</Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem" }}
                  >
                    <Typography className={classes.text1}>
                      6.7. Arbitrator`s address/Адрес арбитра/Арбитр манзили:
                    </Typography>
                    {/* <Grid container className={!canEnterArbitratorDetails ? classes.field : ' '}> */}
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        className={
                          !canEnterArbitratorDetails ? classes.field : " "
                        }
                        variant="outlined"
                        margin="normal"
                        value={
                          canEnterArbitratorDetails ? formik.values.address : ""
                        }
                        disabled={!canEnterArbitratorDetails}
                        fullWidth
                        id="address"
                        // label={<FormattedMessage id="user.email" />}
                        name="address"
                        autoComplete="address"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (canEnterArbitratorDetails) formik.handleChange(e);
                        }}
                      />
                    </Grid>
                    {/* </Grid> */}

                    <Typography className={classes.text2}>
                      Apartment and Street/Название дома и улицы/Ҳонадон ва кўча
                      номи
                    </Typography>
                    <Grid container style={{ padding: "0 1rem" }}>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingRight: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          className={
                            !canEnterArbitratorDetails ? classes.field : " "
                          }
                          variant="outlined"
                          margin="normal"
                          value={
                            canEnterArbitratorDetails ? formik.values.city : ""
                          }
                          disabled={!canEnterArbitratorDetails}
                          fullWidth
                          id="city"
                          // label={<FormattedMessage id="user.email" />}
                          name="city"
                          autoComplete="city"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            if (canEnterArbitratorDetails)
                              formik.handleChange(e);
                          }}
                        />
                        <Typography className={classes.text3}>
                          Сity (Town)/Город/Шаҳар
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingLeft: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          className={
                            !canEnterArbitratorDetails ? classes.field : " "
                          }
                          variant="outlined"
                          margin="normal"
                          value={
                            canEnterArbitratorDetails
                              ? formik.values.region
                              : ""
                          }
                          disabled={!canEnterArbitratorDetails}
                          fullWidth
                          id="region"
                          // label={<FormattedMessage id="user.email" />}
                          name="region"
                          autoComplete="region"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            if (canEnterArbitratorDetails)
                              formik.handleChange(e);
                          }}
                        />
                        <Typography className={classes.text3}>
                          Region and State/Название региона(области) и
                          страны/Вилоят ва мамлакат номи
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* <Grid container className={!canEnterArbitratorDetails ? classes.field : ' '}> */}
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        className={
                          !canEnterArbitratorDetails ? classes.field : " "
                        }
                        variant="outlined"
                        margin="normal"
                        value={
                          canEnterArbitratorDetails
                            ? formik.values.postal_code
                            : ""
                        }
                        disabled={!canEnterArbitratorDetails}
                        fullWidth
                        id="postal_code"
                        // label={<FormattedMessage id="user.email" />}
                        name="postal_code"
                        autoComplete="postal_code"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (canEnterArbitratorDetails) formik.handleChange(e);
                        }}
                      />
                    </Grid>
                    {/* </Grid> */}

                    <Typography className={classes.text2}>
                      Postal code/Почтовый индекс/Почта индекси
                    </Typography>
                    <Box height="2rem"></Box>
                    {/* <Grid container className={!canEnterArbitratorDetails ? classes.field : ' '}> */}
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={12}
                      style={{ padding: "1rem" }}
                    >
                      <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel> */}
                        <Select
                          className={`${
                            !canEnterArbitratorDetails ? classes.field : " "
                          } ${classes.select}`}
                          labelId="demo-country-select-label"
                          id="demo-country-select"
                          value={canEnterArbitratorDetails ? country : "-"}
                          onChange={(e) => {
                            if (canEnterArbitratorDetails)
                              setCountry(e.target.value);
                          }}
                          disabled={!canEnterArbitratorDetails}
                        >
                          <MenuItem value={"-"}>---</MenuItem>
                          {allCountries.map((country) => {
                            return (
                              <MenuItem key={country.id} value={country.id}>
                                {country.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* </Grid> */}

                    <Typography className={classes.text2}>
                      Country/Страна/Мамлакат
                    </Typography>
                  </Grid>
                  <Box height="2rem" />
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem", marginTop: "1.5rem" }}
                  >
                    <Typography className={classes.text1}>
                      6.8. Telephone number/Номер телефона/Телефон рақами
                    </Typography>

                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        className={
                          !canEnterArbitratorDetails ? classes.field : " "
                        }
                        type="number"
                        variant="outlined"
                        margin="normal"
                        value={
                          canEnterArbitratorDetails
                            ? formik.values.phone_number
                            : ""
                        }
                        disabled={!canEnterArbitratorDetails}
                        fullWidth
                        id="phone_number"
                        // label="(000) 000-0000"
                        // label={<FormattedMessage id="user.email" />}
                        name="phone_number"
                        autoComplete="phone_number"
                        onKeyDown={blockInvalidChar}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (canEnterArbitratorDetails) formik.handleChange(e);
                        }}
                      />
                      <Box height="1rem"></Box>
                    </Grid>

                    <Typography className={classes.text2}>
                      Please enter only numerals and a valid contact
                      number/Пожалуйста, введите номер с кодом и только
                      цифры/Илтимос, телефон рақамни код ва фақат рақамлар билан
                      киритинг
                    </Typography>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.9. Job designation (position of an arbitrator)/позиция
                      арбитра по месту работы/арбитрнинг иш лавозими:
                    </Typography>
                    {/* <Grid container className={!canEnterArbitratorDetails ? classes.field : ' '}> */}
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        className={
                          !canEnterArbitratorDetails ? classes.field : " "
                        }
                        variant="outlined"
                        margin="normal"
                        value={
                          canEnterArbitratorDetails
                            ? formik.values.jobdesignation
                            : ""
                        }
                        disabled={!canEnterArbitratorDetails}
                        fullWidth
                        id="jobdesignation"
                        // label={<FormattedMessage id="user.email" />}
                        name="jobdesignation"
                        autoComplete="jobdesignation"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (canEnterArbitratorDetails) formik.handleChange(e);
                        }}
                      />
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.10. Are there any specific conditions to be considered
                      when selecting the arbitrator(s)?/Ecть ли какие-то
                      требования к арбитру?/Арбитрга шартларингиз?
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.specific}
                        fullWidth
                        multiline
                        rows={3}
                        id="specific"
                        // label={<FormattedMessage id="user.email" />}
                        name="specific"
                        autoComplete="specific"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        // InputProps={{ classes: { input: classes.input1 } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      6.11. I hereby authorize TIAC to select and appoint the
                      arbitrator(s)/Я делегирую право назначить арбитра
                      Ташкентскому международному арбитражному центру/Мен арбитр
                      тайинлаш ҳуқуқини Тошкент халқаро арбитраж марказига
                      топшираман <span className={classes.required}>*</span>
                    </Typography>
                    <Grid item md={5} sm={10} xs={12}>
                      <Box>
                        <RadioCard
                          option={1}
                          selectedValue={radioAnswer3}
                          handleChange={handleChangeRadio3}
                        />
                        {/* <Box height="1rem"></Box> */}
                        <RadioCard
                          option={2}
                          selectedValue={radioAnswer3}
                          handleChange={handleChangeRadio3}
                        />
                      </Box>
                      {RadioError3 && (
                        <Box style={{ padding: "0 1rem" }}>
                          <Alert severity="error">{RadioError3}</Alert>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Box height="3rem"></Box>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ background: "#2F80ED" }}
                        disabled={loading}
                      >
                        <Typography className={classes.btnTextWhite}>
                          <FormattedMessage id="buttons.proceed" />
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs /> */}
      </Grid>
    </Box>
  );
};

export default ArbitratorPage;
