import React from "react";
import RespondentStepperTiac from "./RespondentStepper/RespondentStepperTiac";

export default function RespondentLawyerChooseStep({
  activeDispute,
  profile,
  activeCase,
  reload,
}) {
  return (
    <RespondentStepperTiac
      activeDispute={activeDispute}
      profile={profile}
      activeCase={activeCase}
      reload={reload}
    />
  );
}
