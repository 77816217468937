import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2rem 3rem",
      marginBottom: "1.2rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1.5rem 2rem",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "1rem 1.5rem",
      },
    },
    btnTextWhite: {
      color: "#fff",
      fontSize: "18px",
      lineHeight: "30px",
      fontWeight: "400",
      fontFamily: `'Inter',sans-serif`,
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    text: {
      color: "black",
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: `'Inter',sans-serif`,
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
  };
});

const HallTemplate = ({ link, text, btnText }) => {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item xl={5} md={7} sm={11}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item sm={6} xs={12}>
              <Typography className={classes.text}>{text}</Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Button
                  variant="contained"
                  style={{ width: "100%", background: "#2F80ED" }}
                >
                  <Typography className={classes.btnTextWhite}>
                    {btnText}
                  </Typography>
                </Button>
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HallTemplate;
