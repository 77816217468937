import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Rafiki from "images/rafiki_rv.svg";
import Circle from "images/circlemain.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#333",
    minWidth: "100px",
  },
  heading1: {
    fontWeight: "bold",
    fontSize: "54px",
    lineHeight: "48px",
    color: "#70c8ce",
  },
  heading2: {
    marginTop:"8px",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "64px",
    color: "#f66255",
  },
  heading3: {
    marginTop:"12px",
    fontWeight: "500",
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
    color: "#f2f2f2",
  },
  heading4: {
    marginTop:"12px",
    fontWeight: "500",
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
    color: "#f2f2f2",
  },
  grid: {
    backgroundImage: `url(${Circle})`,
    "background-repeat": "no-repeat",
    "background-position": "right",
    "background-size": "contain",
    textAlign: "right",
    padding: "52px",
    [theme.breakpoints.down("sm")]: {
      padding: "68px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "34px",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  textContainer: {
    paddingLeft: "6rem",
    paddingRight: "5rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
}));

export default function Landing() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      {/* <Box height="10rem" /> */}

      <Hidden smDown>
        <Box height="10rem" />
      </Hidden>
      <Hidden mdUp>
        <Box height="5rem" />
      </Hidden>
      <Grid container justify="space-between">
        <Grid item md={5} sm={12} className={classes.textContainer}>
          <Typography className={classes.heading1}>Webnyay RV</Typography>
          <Typography className={classes.heading2}>
            RV / Resolve Virtually
          </Typography>
          <Typography className={classes.heading3}>Webnyaya provides a cloud-based comprehensive arbitration and mediation management and hearing platform. </Typography>
          <Typography className={classes.heading3}>Start today with Webnyaya’s end-to-end platform for arbitrations, mediations, conciliations and adjudications.</Typography>
          <Typography className={classes.heading4}>Use Webnyaya’s platform for online, physical and hybrid arbitrations and mediations
</Typography>
        </Grid>
        <Grid item md={7} sm={12} xs={12} className={classes.grid}>
          <img src={Rafiki} alt="image" className={classes.img} />
        </Grid>
      </Grid>
      <Box height="6rem" />
    </Box>
  );
}
