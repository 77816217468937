import {
  Box,
  IconButton,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { formatDate } from "utils";
import downloadIcon from "images/download-02.svg";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useState } from "react";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

export default function ComplianceReportYearWiseList({
  year,
  index,
  getComplianceReports,
  downloadReport,
  classes
}) {
  let company = "Disney+ Hotstar";
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const handleClickYear = () => {
    setExpanded(!expanded);
    if(!expanded && reports.length === 0){
      setLoading(true);
      getComplianceReports(company, year).then((response) => {
        setReports(response.data.reports);
        setLoading(false);
      });
    }
  }

  return (
    <Grid item md={12} sm={12} xs={12} key={index}>
      <Paper className={classes.paper} onClick={handleClickYear}>
        <Grid container alignItems="center">
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography className={classes.text} style={{fontWeight: 600}}>{year}</Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} style={{textAlign:"end"}}>
            <IconButton
              style={{ marginRight: "auto", padding: "0 " }}
              aria-label="more"
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
      {expanded &&
        <>
          <Paper className={classes.paper}>
            <Grid container justify="center" spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <Grid container className={classes.paper} style={{border: 'none'}}>
                  <Grid item xs>
                    <Typography className={classes.textHeading}>
                      FROM
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.textHeading}>
                      TO
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.textHeading}>
                      PUBLISHED ON
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography className={classes.textHeading}>
                      DOWNLOAD
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {loading ? <CircularCenteredLoader/> : 
              reports.map((report) => {
                return (
                  <Grid item md={12} sm={12} xs={12} key={report.id}>
                    <Paper className={classes.paper}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography className={classes.text}>
                            {formatDate(new Date(report.from_date))}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography className={classes.text}>
                            {formatDate(new Date(report.to_date))}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography className={classes.text}>
                            {formatDate(report.created)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          onClick={() => {
                            downloadReport(report.id);
                          }}
                          style={{cursor: "pointer" }}
                        >
                          <img src={downloadIcon} alt="download" width='20px' height='20px'/>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
            <Box style={{height:'1.5rem'}}></Box>
          </Paper>
        </>
      }
    </Grid>
  )
}