import React from "react";
import Header from "./Header";
import Landing from "./Landing";
import Demo from "./Demo";
import Features from "./Features";
import WhatSetsUsApart from "./WhatSetsUsApart";
import Stats from './Stats';
import Footer from "./Footer";
import RecognizedBy from './RecognizedBy'

export default function RvHomePageTiac() {
  return (
    <>
      <Header />
      <Landing />
      {/* <Demo /> */}
      <Features />
      <Stats />
      <RecognizedBy />
      {/* <WhatSetsUsApart /> */}
      <Footer />
    </>
  );
}
