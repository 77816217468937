import React, { useEffect, useState } from "react";
import {
  decryptText,
  getCompaniesByGroupAnonymous,
  getQueryParams,
} from "./Helper";
import Intro from "./Intro";

const Container = () => {
  const queryNameEncrypted = getQueryParams("name", window.location.href);
  const queryPhoneNoEncrypted = getQueryParams("phoneNo", window.location.href);
  const queryEmailEncrypted = getQueryParams("email", window.location.href);
  const queryIdEncrypted = getQueryParams("id", window.location.href);

  const [companies, setCompanies] = useState([]);

  const getCompanies = () => {
    getCompaniesByGroupAnonymous("vikalp").then((res) => {
      let comp = res.data.results;
      comp.push({ id: 0, name: "Other" });
      setCompanies(comp);
    });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  if (
    !queryNameEncrypted &&
    !queryPhoneNoEncrypted &&
    !queryEmailEncrypted &&
    !queryIdEncrypted
  ) {
    return (
      <Intro
        name={""}
        phoneNo={""}
        email={""}
        id={""}
        companies={companies}
        getCompanies={getCompanies}
      />
    );
  }

  console.log("queryName", queryNameEncrypted);
  console.log("queryPhoneNo", queryPhoneNoEncrypted);
  console.log("queryEmail", queryEmailEncrypted);
  console.log("queryId", queryIdEncrypted);

  const name = decryptText(queryNameEncrypted);

  const phoneNo = decryptText(queryPhoneNoEncrypted);

  const email = decryptText(queryEmailEncrypted);

  const id = decryptText(queryIdEncrypted);

  return (
    <Intro
      name={name}
      phoneNo={phoneNo}
      email={email}
      id={id}
      companies={companies}
      getCompanies={getCompanies}
    />
  );
};

export default Container;
