import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ReactApexChart from 'react-apexcharts'
import Typography from '@material-ui/core/Typography'



export default function PieChart({ d, series, values }) {
	let id = 100;
	const chartData = {
		series: series,
		options: {
			chart: {
				id: id,
				// width: 900,
				type: "donut",
				// animations: {
				//   enabled: false,
				// },
			},
			plotOptions: {
				pie: {
					expandOnClick: false,
					donut: {
						labels: {
							show: true,
							name: {
								show: false,
							},
							value: {
								show: true,
								fontSize: "40px",
								fontFamily: "Inter,Helvetica, Arial, sans-serif",
								color: "#333333",
								// formatter: () => "" + data,
							},
							total: {
								show: true,
								label: "Total",
								fontSize: "40px",
								fontFamily: "Inter",
								color: "#333333",
								formatter: () => "" + d,
							},
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: false,
			},
			colors: ["#9EF3B1", "#FF665C", "#81F5FD", "#61A0FF", "#EEFF85"],
			labels: ["Sent", "Open", "New", "Drafts", "Rejected"],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
					},
				},
			],
		},
	};


	return (
		<Box >
			<Grid alignItems='center' spacing={2} container>
				<Grid item md={8} sm={12} xs={12}>
					<ReactApexChart
						options={chartData.options}
						series={chartData.series}
						type="donut"
					/>
				</Grid>
				<Grid item md={4} sm={4} xs={12}>

					<ColorValues colors={["#9EF3B1", "#FF665C", "#81F5FD", "#61A0FF", "#EEFF85"]} values={values} />
				</Grid>


			</Grid>
		</Box>

	)
}



const ColorValues = ({ colors, values }) => {
	return (
		<div >
			{values.map((singleData, index) => {
				return (

					<div style={{ display: 'flex', marginBottom: '0.7rem' }} >
						<div
							style={{
								height: "1.4rem",
								width: "1.4rem",
								marginRight: "0.7rem",
								backgroundColor: colors[index]
							}}
						></div>
						<Typography style={{fontFamily:"Inter"}} >
							{singleData}</Typography>
					</div>
				);
			})}
		</div>
	);
};