import React, { useEffect, useState } from "react";
import DetailsReview from "./DetailsReview";
import { CircularProgress } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";

import { getAnswersAnonymous } from "slices/answerSlice";
import { getQuestionsAnonymous } from "slices/questionSlice";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getUserFilesAnonymous } from "slices/disputeSlice";

export default function DetailsReviewContainer({ profile }) {
  let dispatch = useDispatch();
  let {
    questions: { questions },
    answers: { answers },
    disputes: { activeDispute, userFiles: files },
    cases: { activeCase },
  } = useSelector((state) => state, shallowEqual);

  let loading = isEmpty(questions) || isEmpty(answers);
  const [userFiles, setUserFiles] = useState([]);

  const getQuestionsAnswers = () => {
    dispatch(
      getQuestionsAnonymous(
        activeDispute.company_product,
        activeCase.type,
        profile.user.email
      )
    );
    dispatch(getAnswersAnonymous(activeCase.id, profile.user.email));
  };

  useEffect(() => {
    getQuestionsAnswers();
    dispatch(getUserFilesAnonymous(activeDispute.id, profile.user.email));
  }, []);

  React.useEffect(() => {
    let f = [];
    files.forEach((file) => {
      if (file.is_attachment) {
        f.push(file);
      }
    });
    setUserFiles(f);
  }, [files]);

  let childProps = {
    profile,
    activeCase,
    activeDispute,
    questions,
    answers,
    userFiles,
    getQuestionsAnswers,
  };
  return (
    <React.Fragment>
      {!loading ? <DetailsReview {...childProps} /> : <CircularProgress />}
    </React.Fragment>
  );
}
