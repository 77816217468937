// src/DateRangePicker.js
import React, { useState } from 'react';
import { DateRangePickerCalendar, START_DATE, END_DATE } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { enGB } from 'date-fns/locale';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dateRangePickerContainer: {
    display: 'flex',
    fontFamily: 'Satoshi-Regular',
  },
  dateRangePickerSidebar: {
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      marginBottom: '10px',
      borderBottom: '1px solid black',
      padding: '16px',
      display: 'flex'
    },
    '& label': {
      marginLeft: '8px',
    },
  },
  dateRangePickerCalendar: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  dateRangePickerFooter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  dateRangeInput: {
    border: '1px solid #ddd',
    borderRadius: '15px',
    padding: '4px 8px',
    margin: '0 5px',
    width: '110px',
    textAlign: 'center',
  },
  cancelButton: {
    padding: '4px 8px',
    border: 'none',
    borderRadius: '15px',
    cursor: 'pointer',
    marginLeft: '10px',
    backgroundColor: '#ddd',
    color: '#333',
  },
  applyButton: {
    padding: '4px 8px',
    border: 'none',
    borderRadius: '15px',
    cursor: 'pointer',
    marginLeft: '10px',
    backgroundColor: '#000',
    color: 'white',
  },
  '@global': {
    '.nice-dates-day': {
      height: '40px !important',
    },
    '.nice-dates-grid': {
      height: '240px !important'
    }
  },
});

const DateRangePickerNew = ({ boxValues, handleUpdateValue }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [selectedOption, setSelectedOption] = useState('custom');
  const classes = useStyles();

  const handleRadioChange = (e) => {
    const preset = e.target.value;
    setSelectedOption(preset);
    handlePresetClick(preset);
    handleUpdateValue('time_frame', preset)
  };

  const handlePresetClick = (preset) => {
    let start, end;
    const today = moment().startOf('day');

    switch (preset) {
      case 'today':
        start = end = today;
        break;
      case 'week':
        start = moment().startOf('week');
        end = today;
        break;
      case 'month':
        start = moment().startOf('month');
        end = today;
        break;
      case 'year':
        start = moment().startOf('year');
        end = today;
        break;
      default:
        start = end = null;
        break;
    }

    setStartDate(start ? start.toDate() : null);
    setEndDate(end ? end.toDate() : null);
  };

  const handleUpdateStartEndDate = () => {
    handleUpdateValue('start_date', moment(startDate).format('DD-MM-YYYY'))
    handleUpdateValue('end_date', moment(endDate).format('DD-MM-YYYY'))
    handleUpdateValue('time_frame', '')
  }

  return (
    <div className={classes.dateRangePickerContainer}>
      <div className={classes.dateRangePickerSidebar}>
        {boxValues.map((value, index) => (
          <div key={index}>
            <input
              type="radio"
              id={value.id}
              name="date-range"
              value={value.id}
              onChange={handleRadioChange}
              checked={selectedOption === value.id}
            />
            <label htmlFor={value.id}>{value.name}</label>
          </div>
        ))}
      </div>
      <div className={classes.dateRangePickerCalendar}>
        <DateRangePickerCalendar
          startDate={startDate}
          endDate={endDate}
          focus={focusedInput}
          onFocusChange={(newFocus) => setFocusedInput(newFocus || START_DATE)}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          locale={enGB}
        />
        <div className={classes.dateRangePickerFooter}>
          <input
            type="text"
            value={startDate ? startDate.toLocaleDateString() : ''}
            readOnly
            className={classes.dateRangeInput}
          />
          <span> - </span>
          <input
            type="text"
            value={endDate ? endDate.toLocaleDateString() : ''}
            readOnly
            className={classes.dateRangeInput}
          />
          <button className={classes.cancelButton}>Cancel</button>
          <button className={classes.applyButton} onClick={handleUpdateStartEndDate}>Apply</button>
        </div>
      </div>
    </div>
  );
};

export default DateRangePickerNew;
