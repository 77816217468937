import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  withStyles,
  Select,
} from "@material-ui/core";
import React from "react";
import FormattedMessage from "features/common/TranslatedMessage";

const BlueCheckbox = withStyles({
  root: {
    color: "grey",
    "&$checked": {
      color: "#2F80ED",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SaveMyProgress = ({
  checkSaveMyProgressAndResumeLater,
  handleOnClickCheckBox,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <BlueCheckbox
            checked={checkSaveMyProgressAndResumeLater}
            onChange={handleOnClickCheckBox}
          />
        }
        label={<FormattedMessage id="tiac.savemyprogress" />}
      ></FormControlLabel>
    </FormGroup>
  );
};

export default SaveMyProgress;
