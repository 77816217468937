import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import instance from "api/globalaxios";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import UploadExcel from "./CreateMeeting/UploadExcel";
import { DEV_WEBNYAY_URL, WEBNYAY_URL } from "app/constants";
import { isDev } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    padding: "1rem",
    borderRadius: "5px",
  },
  text: {
    fontSize: "16px",
    lineHeight: "26px",
    color: "#2C2C2C",
    textTransform: "capitalize",
  },
  createMeetingButton: {
    backgroundColor: "white",
  },
}));

export default function CreateMeeting({ profile, showExcelUpload }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [meetingUrl, setMeetingUrl] = useState();
  const [disputeId, setDisputeId] = useState(null);

  useEffect(() => {
    if (disputeId) {
      if (window.location.hostname == "localhost") {
        setMeetingUrl(`http://${window.location.host}/meeting/${disputeId}`);
      } else {
        setMeetingUrl(
          `https://${
            isDev ? DEV_WEBNYAY_URL : WEBNYAY_URL
          }/meeting/${disputeId}`
        );
      }
    }
  }, [disputeId]);

  async function createDyteMeeting() {
    try {
      setLoading(true);
      setErrorType(null);
      setDisputeId(null);
      setIsCopied(false);
      let response = await instance({
        method: "post",
        url: "/createdytemeeting",
        data: {
          disputeId: uuid(),
        },
      });
      setLoading(false);
      setDisputeId(response?.data?.data.dispute_id);
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        setLoading(false);
        return;
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" direction="column">
        <Grid item>
          <Grid
            container
            justify="space-between"
            direction="row"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography className={classes.heading}>
                Create Meeting
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.username}>
                {profile.user.first_name} {profile.user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Box height="5rem"></Box>
          <Grid
            container
            style={{ marginLeft: "20px" }}
            justify="center"
            spacing={4}
          >
            <Grid item md={3} sm={3} xs={7}>
              <Button
                variant="contained"
                className={classes.createMeetingButton}
                onClick={createDyteMeeting}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    style={{ width: 15, height: 15, marginRight: "0.5rem" }}
                  />
                )}
                <Typography
                  className={classes.text}
                  style={loading ? { color: "#878787" } : null}
                >
                  Create Meeting
                </Typography>
              </Button>
            </Grid>
            {(disputeId || errorType) && (
              <Grid item md={10} sm={10} xs={11}>
                <Paper className={classes.paper}>
                  {errorType ? (
                    <Typography>{errorType}</Typography>
                  ) : (
                    <Grid container justify="center">
                      <Grid item md={11} sm={11} xs={12}>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={meetingUrl}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={1} sm={1} xs>
                        <Typography
                          style={{
                            fontSize: "1.2rem",
                            padding: "6px",
                            color: isCopied ? "green" : null,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(meetingUrl);
                            setIsCopied(true);
                          }}
                        >
                          {isCopied ? "Copied!" : "Copy"}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </Grid>
            )}

            {showExcelUpload && (
              <Grid item md={10} sm={10} xs={11}>
                <UploadExcel />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
