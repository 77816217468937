import {
  ADVOCATE_RV,
  ARBITRATOR,
  ARBITRATOR_RV,
  GENERAL_RV,
  MEDIATOR,
  SECRETARIAT,
} from "app/constants";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useDisputes } from "hooks";
import isEmpty from "lodash/isEmpty";
import React, { Fragment, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDisputesCount } from "slices/disputeSlice";
import useDeepCompareEffect from "use-deep-compare-effect";
import Disputes from "./Disputes";

export default function DisputesContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  let {
    profile: { profile_type },
  } = profile;
  const isRV =
    profile_type === GENERAL_RV ||
    profile_type === ADVOCATE_RV ||
    profile_type === ARBITRATOR_RV;

  useDeepCompareEffect(() => {
    if (
      (!isEmpty(profile) && profile.profile.profile_type === ARBITRATOR) ||
      profile.profile.profile_type === MEDIATOR ||
      profile.profile.profile_type === SECRETARIAT
    ) {
      history.push(`/disputes/${profile.profile.profile_type}/active`);
    }
    if (!isEmpty(profile) && profile.profile.profile_type === "admin") {
      history.push(`/admin`);
    }
    if (!isEmpty(profile) && profile.profile.profile_type === "sub_admin") {
      history.push(`/subadmin`);
    }
    if (
      !isEmpty(profile) &&
      profile.profile.profile_type === "sub_admin_tiac_rv" &&
      profile.profile.group == "difc"
    ) {
      history.push(`/subadmindifc`);
    }
    if (!isEmpty(profile) && profile.profile.profile_type === "case_manager") {
      history.push(`/casemanager`);
    }
  }, [profile]);

  let { cases, disputes, respondent, claimant, loading } = useDisputes(isRV);
  const [count, setCount] = useState({ claimant: {}, respondent: {} });
  React.useEffect(() => {
    dispatch(getDisputesCount()).then((res) => {
      setCount(res);
    });
  }, []);

  let childProps = {
    cases,
    disputes,
    profile,
    user,
    respondentDisputes: respondent,
    claimaintDisputes: claimant,
    isRV,
    count,
  };

  return (
    <Fragment>
      {!isEmpty(profile) &&
      profile.profile.profile_type !== "arbitrator" &&
      profile.profile.profile_type !== "admin" &&
      profile.profile.profile_type !== "sub_admin" &&
      !loading ? (
        <Disputes {...childProps} />
      ) : (
        <CircularCenteredLoader />
      )}
    </Fragment>
  );
}
