import React, { useEffect, useState } from "react";
import Details from "features/singleDisputeRv/Details";
import DisputeDisplayRv from "features/singleDisputeRv/DisputeDisplayRv";

import { Box, Container, makeStyles } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
  RESPONDENT,
  ADVOCATE_RV,
  ARBITRATOR_COMPLETE,
  RESPONDENT_ADVOCATE_COMPLETE,
} from "app/constants";
import NewDetails from "../Details/NewDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function RespondentStepper({
  activeDispute,
  profile,
  activeCase,
}) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box height="2rem"></Box>

      {activeDispute.rv_respondent_lawyer_step_complete != true ? (
        <NewDetails
          activeDispute={activeDispute}
          caseFileType={RESPONDENT}
          rvStatus={RESPONDENT_ADVOCATE_COMPLETE}
          profileType={ADVOCATE_RV}
          activeCase={activeCase}
          step={5}
        />
      ) : (
        <DisputeDisplayRv activeDispute={activeDispute} profile={profile} />
      )}
      <Box width="2rem"></Box>
    </Container>
  );
}
