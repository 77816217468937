import {
  Box,
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import instance from "api/globalaxios";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { isDev } from "Routes/Routes";
import * as Yup from "yup";
import { StyledTextFieldTiac } from "../ClaimantPage/questions";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#454545",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

export function setPasswordApi(email, password, profileType) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "tiacsetpassword/",
        data: {
          email: email,
          password: password,
          profile_type: profileType,
          isDev: isDev,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
    }
  };
}

const SetPassword = ({
  checkSaveMyProgressAndResumeLater,
  email,
  profileType,
  setAfterPasswordSubmitDetailsSignal,
}) => {
  const classes = useStyles();
  const refPassword = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (checkSaveMyProgressAndResumeLater && refPassword.current) {
      refPassword.current.scrollIntoView({ behavior: "smooth" });
      formik.setFieldValue("password", "");
      formik.setFieldValue("confirmPassword", "");
      formik.setFieldTouched("password", false);
      formik.setFieldTouched("confirmPassword", false);
    }
  }, [checkSaveMyProgressAndResumeLater]);

  const intl = useIntl();
  // formatted msg not working with yup.matches
  const Schema = Yup.object().shape({
    password: Yup.string()
      .min(6, <FormattedMessage id="tiac.passwordtooshort" />)
      .max(15, <FormattedMessage id="tiac.passwordtoolong" />)
      .matches(
        /^(.*[A-Z].*)/,
        intl.formatMessage({ id: "tiac.passworduppercaserequired" })
      )
      .matches(
        /^(.*[a-z].*)/,
        intl.formatMessage({ id: "tiac.passwordlowercaserequired" })
      )
      .matches(
        /^(.*[0-9].*)/,
        intl.formatMessage({ id: "tiac.passwordnumberrequired" })
      )
      .matches(
        /^(.*\W.*)/,
        intl.formatMessage({ id: "tiac.passwordspecialrequired" })
      )
      .required(<FormattedMessage id="tiac.requiredpassword" />),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        <FormattedMessage id="tiac.passwordmustmatch" />
      )
      .required(<FormattedMessage id="tiac.requiredconfirmpassword" />),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(setPasswordApi(email, formik.values.password, profileType)).then(
        (res) => {
          console.log(res);
          localStorage.setItem("dispute_id", res.id);
          setAfterPasswordSubmitDetailsSignal(true);
        }
      );
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <Box ref={refPassword}>
      {checkSaveMyProgressAndResumeLater && (
        <Grid container justify="center">
          <Grid item xs={10}>
            <Typography className={classes.text}>
              <FormattedMessage id="tiac.savemyprogresshelptext" />
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <StyledTextFieldTiac
                variant="outlined"
                margin="normal"
                fullWidth
                label={intl.formatMessage({ id: "user.email" }) + " *"}
                id="email"
                name="email"
                autoComplete="email"
                value={email}
                disabled={true}
                inputProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
                InputLabelProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
              />
              <StyledTextFieldTiac
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={intl.formatMessage({ id: "tiac.password" }) + " *"}
                type={passwordType}
                id="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={tooglePasswordType}
                      style={{ cursor: "pointer" }}
                    >
                      {passwordType == "password" ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
              />
              {formik.errors.password && formik.touched.password && (
                <Alert severity="error">{formik.errors.password}</Alert>
              )}
              <StyledTextFieldTiac
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label={
                  intl.formatMessage({ id: "tiac.confirmpassword" }) + " *"
                }
                type={passwordType}
                id="confirmPassword"
                value={formik.values.confirmPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  style: { fontFamily: `'Inter',sans-serif` },

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={tooglePasswordType}
                      style={{ cursor: "pointer" }}
                    >
                      {passwordType == "password" ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontFamily: `'Inter',sans-serif` },
                }}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <Alert severity="error">
                    {formik.errors.confirmPassword}
                  </Alert>
                )}
              <Grid container justify="center" style={{ marginTop: "1rem" }}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ background: "#2F80ED" }}
                  disabled={loading}
                >
                  <Typography className={classes.btnTextWhite}>
                    <FormattedMessage id="tiac.save" />
                  </Typography>
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
      <Box height="4rem" /> {/* last box to scroll into view*/}
    </Box>
  );
};

export default SetPassword;
