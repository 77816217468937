import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import CategoriesBarChart from "./CategoriesBarChart";
import DonutGraph from "./DonutGraph";
import HeatMap from "./HeatMap";
import SquareBoxChart from "./SquareBoxChart";
import StateHeatMap from "./StateHeatMap";
import SummaryGraph from "./SummaryGraph";
import { series } from "./SummaryGraphData";

const useStyles = makeStyles((theme) => ({
  parent: {
    display: "grid",
    "grid-template-columns": "1.4fr 1fr",
    "grid-template-rows": "repeat(2, 1fr)",
    "grid-column-gap": "24px",
    "grid-row-gap": "24px",
  },
  div1: { "grid-area": "1 / 1 / 2 / 2" },
  div2: { "grid-area": "1 / 2 / 3 / 3" },
  div3: { "grid-area": "2 / 1 / 3 / 2" },
}));

export default function MapContainer({
  states,
  stateLists,
  stateOnClick,
  activeState,
  choosestep2,
  data,
}) {
  const classes = useStyles();
  const focusgeographyStyle = {
    default: {
      outline: "none",
      fill: "#E42",
    },
    hover: {
      fill: "#FF665C",
      outline: "none",
    },
    pressed: {
      outline: "none",
      fill: "#E42",
    },
  };
  const [selectedHeatstate, setSelectedHeatState] = useState();
  const getSelectedHeatState = (state, color) => {
    setSelectedHeatState({ state, color: focusgeographyStyle });
  };

  const [hitToggle, setHitToggle] = useState(false);

  if (hitToggle)
    return (
      <Grid
        container
        spacing={3}
        style={{
          marginTop: "0.5rem",
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <div className={classes.parent}>
            <div className={classes.div1}>
              <HeatMap
                states={states}
                stateOnClick={stateOnClick}
                choosestep2={choosestep2}
                activeState={activeState}
                getSelectedHeatState={getSelectedHeatState}
                hitToggle={hitToggle}
                setHitToggle={setHitToggle}
              />
            </div>
            <div className={classes.div2}>
              <SquareBoxChart />
            </div>
            {selectedHeatstate && (
              <div className={classes.div3}>
                <StateHeatMap
                  states={states}
                  stateOnClick={stateOnClick}
                  choosestep2={choosestep2}
                  activeState={activeState}
                  selectedHeatstate={selectedHeatstate}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    );

  return (
    <Grid
      container
      style={{
        marginTop: "0.5rem",
      }}
      spacing={3}
      justify="flex-start"
      alignItems="stretch"
    >
      <Grid item md={7} sm={12} xs={12}>
        <HeatMap
          states={states}
          stateOnClick={stateOnClick}
          choosestep2={choosestep2}
          activeState={activeState}
          getSelectedHeatState={getSelectedHeatState}
          hitToggle={hitToggle}
          setHitToggle={setHitToggle}
        />
      </Grid>
      {choosestep2 && (
        <>
          <Grid item md={5} sm={12} xs={12}>
            <DonutGraph
              data={data[choosestep2 - 1]}
              choosestep2={choosestep2}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <CategoriesBarChart />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <SummaryGraph series={series} formatY={true} />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <a href="#">
              <span
                style={{
                  padding: "10px 0px 0",
                  fontSize: "18px",
                  color: "#333333",
                  borderBottom: "1px solid",
                  margin: "0 15px",
                }}
              >
                Download.PDF
              </span>
            </a>
            <a href="#">
              <span
                style={{
                  padding: "10px 0px 0",
                  fontSize: "18px",
                  color: "#333333",
                  borderBottom: "1px solid",
                  margin: "0 15px",
                }}
              >
                Download.DOCX
              </span>
            </a>
          </Grid>
        </>
      )}
    </Grid>
  );
}
