import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import filterBlackSvg from "images/filter.svg";
import searchSvg from "images/search.svg";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import EditProfile from "../EditProfile";
import ViewProfile from "../ViewProfile";
import GeneralUserProfile from "./GeneralUserProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    // padding: "1.2rem 1.7rem",
    borderRadius: "8px",
    marginLeft: "20px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  paper2: {
    border: "1px solid #828282",
    boxSizing: "border-box",
    padding: "0.2rem 0.7rem",
    margin: "1rem 1.5rem",
  },
  paper3: {
    border: "1px solid #828282",
    boxSizing: "border-box",
    padding: "0.2rem 0.7rem",
    margin: "1rem 1.5rem",
    background: "none",
    border: "none",
    boxShadow: "none",
  },
  filter: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#828282",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.05em",
    color: "#2C2C2C",
    padding: "17px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
      overflowWrap: "anywhere",
    },
  },
  grid: {
    paddingLeft: "1.6rem",
    background: "#ECEEF8",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.3rem",
    },
  },
  blackoption: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2C2C2C",
  },
  blueoption: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2F80ED",
  },
  selectedoption: {
    borderBottom: "2px solid #2F80ED",
  },
  optiongrid: {
    cursor: "pointer",
  },
  sortby: {
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.05em",
    color: "#828282",
  },
  sortbyblackHeading: {
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
    color: "#2C2C2C",
  },
}));

export default function GeneralUsers({ profile }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [usersData, setUsersData] = useState([]);
  const [allusersData, setallUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const ref = useRef();

  let t = sessionStorage.getItem("tiac_general_users_totalPages");
  let c = sessionStorage.getItem("tiac_general_users_currentPage");
  const [totalNoOfPages, setTotalNoOfPages] = useState(t ? t : null);
  const [currentPage, setCurrentPage] = useState(c ? c : 1);
  const option1 = true;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorFilter, setAnchorFilter] = React.useState(null);

  const [searchData, setSearchData] = useState([]);
  const [hide, setHide] = useState(false);

  const [sortby, setsortby] = useState("default");
  const [users, setUsers] = useState("all");

  const [localSelect, setLocalSelect] = useState(false);
  const [selectall, setSelectall] = useState(false);

  const [profileData, setProfileData] = useState([]);
  const dispute_edit = false;

  console.log("profileData", profileData);

  React.useEffect(() => {
    if (selectall) setProfileData(usersData);
    else setProfileData([]);
  }, [selectall]);

  const addprofile = (profile) => {
    let res = [];
    profileData.forEach((i) => {
      res.push(i);
    });
    res.push(profile);
    if (res.length == usersData.length) {
      setLocalSelect(true);
    }
    setProfileData(res);
  };

  const removeprofile = (profile) => {
    let res = [];
    profileData.forEach((i) => {
      res.push(i);
    });
    let index = res.indexOf(profile);
    if (index > -1) {
      res.splice(index, 1);
    }
    setProfileData(res);
    setLocalSelect(false);
  };

  const handleLocalSelectChange = (e) => {
    setLocalSelect(e.target.checked);
    setSelectall(e.target.checked);
  };

  const handleDefaultSort = (e) => {
    setsortby(e.target.value);
    var result = [];
    if (users == "all") {
      result = [...allusersData];
    } else if (users == "active") {
      [...allusersData].forEach((item) => {
        if (item.user.is_active) result.push(item);
      });
    } else {
      [...allusersData].forEach((item) => {
        if (!item.user.is_active) result.push(item);
      });
    }
    setUsersData(result);
  };

  const handleFirstNameSort = (e) => {
    setsortby(e.target.value);
    var result = [];
    if (users == "all") {
      result = [...allusersData];
    } else if (users == "active") {
      [...allusersData].forEach((item) => {
        if (item.user.is_active) result.push(item);
      });
    } else {
      [...allusersData].forEach((item) => {
        if (!item.user.is_active) result.push(item);
      });
    }
    result.sort(function (a, b) {
      var nameA = a.user.first_name.toUpperCase();
      var nameB = b.user.first_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setUsersData(result);
  };

  const handleLastNameSort = (e) => {
    setsortby(e.target.value);
    var result = [];
    if (users == "all") {
      result = [...allusersData];
    } else if (users == "active") {
      [...allusersData].forEach((item) => {
        if (item.user.is_active) result.push(item);
      });
    } else {
      [...allusersData].forEach((item) => {
        if (!item.user.is_active) result.push(item);
      });
    }
    result.sort(function (a, b) {
      var nameA = a.user.last_name.toUpperCase();
      var nameB = b.user.last_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setUsersData(result);
  };

  const handleLastLoginSort = (e) => {
    setsortby(e.target.value);
    var result = [];
    if (users == "all") {
      result = [...allusersData];
    } else if (users == "active") {
      [...allusersData].forEach((item) => {
        if (item.user.is_active) result.push(item);
      });
    } else {
      [...allusersData].forEach((item) => {
        if (!item.user.is_active) result.push(item);
      });
    }
    result.sort(function (a, b) {
      var nameA = new Date(a.user.last_login);
      var nameB = new Date(b.user.last_login);
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setUsersData(result);
  };

  const handleAllUsers = (e) => {
    setUsers(e.target.value);
    var result = [...allusersData];
    setUsersData([]);
    setTimeout(() => {
      if (sortby == "default") {
        setUsersData(result);
      }
      if (sortby == "firstname") {
        result.sort(function (a, b) {
          var nameA = a.user.first_name.toUpperCase();
          var nameB = b.user.first_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
      if (sortby == "lastname") {
        result.sort(function (a, b) {
          var nameA = a.user.last_name.toUpperCase();
          var nameB = b.user.last_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
      if (sortby == "lastlogin") {
        result.sort(function (a, b) {
          var nameA = new Date(a.user.last_login);
          var nameB = new Date(b.user.last_login);
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
    }, 10);
  };

  const handleActiveUsers = (e) => {
    setUsers(e.target.value);
    var result = [];
    [...allusersData].forEach((item) => {
      if (item.user.is_active) {
        result.push(item);
      }
    });
    setUsersData([]);
    setTimeout(() => {
      if (sortby == "default") {
        setUsersData(result);
      }
      if (sortby == "firstname") {
        result.sort(function (a, b) {
          var nameA = a.user.first_name.toUpperCase();
          var nameB = b.user.first_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
      if (sortby == "lastname") {
        result.sort(function (a, b) {
          var nameA = a.user.last_name.toUpperCase();
          var nameB = b.user.last_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
      if (sortby == "lastlogin") {
        result.sort(function (a, b) {
          var nameA = new Date(a.user.last_login);
          var nameB = new Date(b.user.last_login);
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
    }, 10);
  };

  const handleInactiveUsers = (e) => {
    setUsers(e.target.value);
    var result = [];
    [...allusersData].forEach((item) => {
      if (!item.user.is_active) {
        result.push(item);
      }
    });
    setUsersData([]);
    setTimeout(() => {
      if (sortby == "default") {
        setUsersData(result);
      }
      if (sortby == "firstname") {
        result.sort(function (a, b) {
          var nameA = a.user.first_name.toUpperCase();
          var nameB = b.user.first_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
      if (sortby == "lastname") {
        result.sort(function (a, b) {
          var nameA = a.user.last_name.toUpperCase();
          var nameB = b.user.last_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
      if (sortby == "lastlogin") {
        result.sort(function (a, b) {
          var nameA = new Date(a.user.last_login);
          var nameB = new Date(b.user.last_login);
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsersData(result);
      }
    }, 10);
  };

  const searchHandler = (e) => {
    let search = e.target.value;
    if (search == "") {
      setSearchData([]);
    } else {
      let result = [];
      [...allusersData].forEach((item) => {
        let fullName =
          item.user.first_name.toLowerCase() +
          " " +
          item.user.last_name.toLowerCase();
        if (
          item.user.first_name.toLowerCase().includes(search.toLowerCase()) ||
          item.user.last_name.toLowerCase().includes(search.toLowerCase()) ||
          item.user.email.toLowerCase().includes(search.toLowerCase()) ||
          fullName.includes(search.toLowerCase())
        ) {
          result.push(item);
        }
      });
      result.sort(function (a, b) {
        var nameA = a.user.first_name.toUpperCase();
        var nameB = b.user.first_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      if (result.length > 0) {
        setSearchData(result);
        setHide(false);
      } else {
        setSearchData([]);
        setHide(true);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickFilter = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorFilter(null);
  };

  const refresh = () => {
    setLoading2(true);
    setsortby("default");
    setUsers("all");
    if (ref.current) ref.current.value = "";
    setSearchData([]);
    dispatch(getUserDataAnotherPage(currentPage)).then((result) => {
      setUsersData(result);
      setallUsersData(result);
    });
  };
  React.useEffect(() => {
    refresh();
  }, []);

  function getUserDataAnotherPage(pageNo) {
    return async () => {
      try {
        const response = await instance.get(
          `/api/profilesdetails/?page=${pageNo}&type=general_rv`
        );
        if (pageNo == 1) {
          sessionStorage.setItem(
            "tiac_general_users_totalPages",
            parseInt(response.data.count / 100) + 1
          );
          setTotalNoOfPages(parseInt(response.data.count / 100) + 1);
        }
        setLoading(false);
        setLoading2(false);
        let res = [];
        response.data.results.map((item) => {
          item["platform"] = "webnyay";
          res.push(item);
        });
        return res;
      } catch (error) {
        console.log(error);
        setLoading2(false);
        return [];
      }
    };
  }
  const [openmodal3, setOpenmodal3] = useState(false);

  const handleOpenModal3 = (e) => {
    e.stopPropagation();
    setOpenmodal3(true);
    handleClose();
    // setStopClickBackground(true);
  };

  const handleCloseModal3 = (e) => {
    e.stopPropagation();
    setOpenmodal3(false);
    refresh();
    // setStopClickBackground(false);
  };

  const [openmodal4, setOpenmodal4] = useState(false);

  const handleOpenModal4 = (e) => {
    e.stopPropagation();
    if (profileData.length > 0) setOpenmodal4(true);
    handleClose();
    // setStopClickBackground(true);
  };

  const handleCloseModal4 = (e) => {
    e.stopPropagation();
    setOpenmodal4(false);
    // setStopClickBackground(false);
  };

  const handleActivateUser = (e) => {
    e.stopPropagation();
    profileData.forEach((i, index) => {
      dispatch(activateUser(i, i.id)).then(() => {
        if (index == profileData.length - 1) {
          refresh();
          handleClose();
        }
      });
    });
  };
  const handleDeactivateUser = (e) => {
    e.stopPropagation();
    profileData.forEach((i, index) => {
      dispatch(deactivateUser(i, i.id)).then(() => {
        if (index == profileData.length - 1) {
          refresh();
          handleClose();
        }
      });
    });
  };

  function activateUser(item, id) {
    return async () => {
      try {
        let response;
        if (item.platform == "webnyay") {
          response = await instance.put(`/updateprofile/${id}/`, {
            is_active: 1,
          });
        } else {
        }
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function deactivateUser(item, id) {
    return async () => {
      try {
        let response;
        if (item.platform == "webnyay") {
          response = await instance.put(`/updateprofile/${id}/`, {
            is_active: 0,
          });
        } else {
        }

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <div className={classes.root}>
        <Grid container justify="flex-start" direction="column">
          <Grid item sm xs>
            <Grid
              container
              justify="space-between"
              direction="row"
              style={{ padding: "10px" }}
            >
              <Grid item>
                <Typography className={classes.heading}>
                  User Details - General Users
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.username}>
                  {profile.user.first_name} {profile.user.last_name}
                </Typography>
              </Grid>
            </Grid>
            <Box height="1rem"></Box>
            <Paper className={classes.paper3}>
              <Grid
                container
                justify="flex-start"
                style={{ borderBottom: "1px solid #2C2C2C" }}
                spacing={3}
              >
                <Grid
                  item
                  className={
                    option1 ? classes.selectedoption : classes.optiongrid
                  }
                >
                  <Typography
                    className={
                      option1 ? classes.blueoption : classes.blackoption
                    }
                  >
                    All
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Box height="1rem"></Box>
            <Grid container justify="flex-start">
              <Grid item md={12} sm={12} xs={12}>
                <Paper className={classes.paper}>
                  <Grid container justify="flex-start">
                    <Grid item style={{ textAlign: "left" }}>
                      <Paper
                        className={classes.paper2}
                        onClick={handleClickFilter}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        style={{ cursor: "pointer" }}
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <img src={filterBlackSvg} />
                          </Grid>
                          <Grid item>
                            <Typography className={classes.filter}>
                              Filter
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorFilter}
                        keepMounted
                        open={Boolean(anchorFilter)}
                        onClose={handleCloseFilter}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Grid container spacing={2} direction="row">
                          <Grid item>
                            <Box display="flex">
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography className={classes.sortby}>
                                  SORT BY:
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box height="0.7rem"></Box>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="default">Default</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="default"
                                  name="sortby"
                                  value="default"
                                  onChange={handleDefaultSort}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={sortby == "default" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="firstname">First Name</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="firstname"
                                  name="sortby"
                                  value="firstname"
                                  onChange={handleFirstNameSort}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={sortby == "firstname" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="lastname">Last Name</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="lastname"
                                  name="sortby"
                                  value="lastname"
                                  onChange={handleLastNameSort}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={sortby == "lastname" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="lastlogin">Last Login</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="lastlogin"
                                  name="sortby"
                                  value="lastlogin"
                                  onChange={handleLastLoginSort}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={sortby == "lastlogin" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box height="1rem"></Box>
                        <Divider />
                        <Box height="1rem"></Box>
                        <Grid container spacing={2} direction="row">
                          <Grid item>
                            <Box display="flex">
                              <Box width="0.6rem"></Box>
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography className={classes.sortby}>
                                  USERS:
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="all">All</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="all"
                                  name="users"
                                  value="all"
                                  onChange={handleAllUsers}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={users == "all" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="active">Active</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="active"
                                  name="users"
                                  value="active"
                                  onChange={handleActiveUsers}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={users == "active" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="space-between"
                          style={{ width: "186px", padding: "0 10px" }}
                        >
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <Typography
                                  className={classes.sortbyblackHeading}
                                >
                                  <label for="inactive">Inactive</label>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Box
                                alignItems="start"
                                style={{ textAlign: "left" }}
                              >
                                <input
                                  type="radio"
                                  id="inactive"
                                  name="users"
                                  value="inactive"
                                  onChange={handleInactiveUsers}
                                  style={{
                                    transform: "scale(1.5)",
                                    margin: "6px 3px 0px 6px",
                                  }}
                                  checked={users == "inactive" ? true : false}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Menu>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                      <Paper className={classes.paper2}>
                        <Grid container spacing={1}>
                          <Grid item sm={1} xs={2}>
                            <img src={searchSvg} alt="search" />
                          </Grid>
                          <Grid item sm={11} xs={10}>
                            <input
                              type="text"
                              placeholder="Search Users by Name or Email"
                              style={{ border: "none", width: "100%" }}
                              onChange={searchHandler}
                              ref={ref}
                            ></input>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-start" className={classes.grid}>
                    <Grid
                      item
                      md={1}
                      sm={1}
                      xs={1}
                      style={{ display: "flex" }}
                      // style={{ textAlign: "left" }}
                    >
                      <FormControlLabel
                        style={{ marginRight: "auto" }}
                        control={
                          <Checkbox
                            checked={localSelect}
                            onChange={handleLocalSelectChange}
                            name="selectall"
                            style={{
                              color: "#2F80ED",
                            }}
                          />
                        }
                        label=""
                      />
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={4}
                      xs={4}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        NAME
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        USER STATUS
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        MODE
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.blackheading}>
                        TOTAL CASES
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sm={1}
                      xs={1}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        disabled={profileData.length == 0}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleOpenModal3}>Edit</MenuItem>
                        {openmodal3 && (
                          <EditProfile
                            openmodal={openmodal3}
                            handleCloseModal={handleCloseModal3}
                            profileData={profileData}
                            profileType={"arbitrator"}
                            dispute_edit={dispute_edit}
                          />
                        )}
                        <MenuItem onClick={handleOpenModal4}>
                          View Profile
                        </MenuItem>
                        {openmodal4 && (
                          <ViewProfile
                            openmodal={openmodal4}
                            handleCloseModal={handleCloseModal4}
                            profileData={profileData}
                          />
                        )}
                        <MenuItem
                          onClick={handleActivateUser}
                          style={{
                            fontSize: "14px",
                            letterSpacing: "0.05em",
                            color: "#007F00",
                          }}
                        >
                          Activate User
                        </MenuItem>
                        <MenuItem
                          onClick={handleDeactivateUser}
                          style={{
                            fontSize: "14px",
                            letterSpacing: "0.05em",
                            color: "#007F00",
                          }}
                        >
                          Dectivate User
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  <Box height="1px"></Box>
                  {searchData.length > 0 || hide ? (
                    searchData.map((item) => {
                      return (
                        <React.Fragment key={item.id}>
                          <GeneralUserProfile
                            item={item}
                            name={
                              item.user.first_name + " " + item.user.last_name
                            }
                            status={item.user.is_active}
                            last_login={item.user.last_login}
                            open={item.open_dispute_count}
                            closed={item.close_dispute_count}
                            mode={item.dispute_modes}
                            id={item.id}
                            addprofile={addprofile}
                            removeprofile={removeprofile}
                            initialCheckbox={selectall}
                            refresh={refresh}
                          />
                          <Divider />
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <>
                      {loading2 ? (
                        <CircularCenteredLoader />
                      ) : (
                        usersData.map((item) => {
                          return (
                            <React.Fragment key={item.id}>
                              <GeneralUserProfile
                                item={item}
                                name={
                                  item.user.first_name +
                                  " " +
                                  item.user.last_name
                                }
                                status={item.user.is_active}
                                last_login={item.user.last_login}
                                open={item.open_dispute_count}
                                closed={item.close_dispute_count}
                                mode={item.dispute_modes}
                                id={item.id}
                                addprofile={addprofile}
                                removeprofile={removeprofile}
                                initialCheckbox={selectall}
                                refresh={refresh}
                              />
                              <Divider />
                            </React.Fragment>
                          );
                        })
                      )}
                    </>
                  )}
                  <Box height="0.5rem"></Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid container style={{ marginLeft: "28px" }}>
              <Grid item>
                <IconButton
                  disabled={currentPage == 1}
                  onClick={(e) => {
                    setsortby("default");
                    setUsers("all");
                    setCurrentPage(currentPage - 1);
                    sessionStorage.setItem(
                      "tiac_general_users_currentPage",
                      currentPage - 1
                    );
                    dispatch(getUserDataAnotherPage(currentPage - 1)).then(
                      (resWebnyay) => {
                        setUsersData(resWebnyay);
                        setallUsersData(resWebnyay);
                      }
                    );
                  }}
                >
                  <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                </IconButton>
                <IconButton
                  disabled={currentPage == totalNoOfPages}
                  onClick={(e) => {
                    setsortby("default");
                    setUsers("all");
                    setCurrentPage(currentPage + 1);
                    sessionStorage.setItem(
                      "tiac_general_users_currentPage",
                      currentPage + 1
                    );
                    dispatch(getUserDataAnotherPage(currentPage + 1)).then(
                      (resWebnyay) => {
                        setUsersData(resWebnyay);
                        setallUsersData(resWebnyay);
                      }
                    );
                  }}
                >
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
