import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from 'images/Logo2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#333",
  },
  title: {
    flexGrow: 1,
    "& img": {
      width: "14rem",
      height: "auto",
      padding: "21px 21px 9px 25px",
      [theme.breakpoints.down("sm")]: {
        width: "9rem",
        padding: "21px 21px 9px 15px",
      },
    },
  },
  redBtn: {
    background: "#f66255",
    border: "1px solid transparent",
    color: "#f2f2f2",
    borderRadius: "0.8rem",
    margin: "27px 17px 9px 25px",
    transition: "0.3s",
    "&:hover": {
      color: "#f66255",
      borderRadius: "0",
      border: "1px solid #f66255",
      background: "#333",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "27px 9px 9px 25px",
      padding: "3px 8px",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <Grid container justify="space-between">
        <Grid item>
          <div className={classes.title}>
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
        </Grid>
        <Grid item>
          {window.location.host == "localhost:3000" ? (
            <a href="http://localhost:3000" target="_blank">
              <Button
                className={classes.redBtn}
                variant="contained"
                onClick={() => {
                  localStorage.setItem("dev", "tiac");
                }}
              >
                LOG IN
              </Button>
            </a>
          ) : (
            <a href="https://tiac.webnyaya.com" target="_blank">
              <Button className={classes.redBtn} variant="contained">
                LOG IN
              </Button>
            </a>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
