import { isDifc, isMSME, isSebi } from "Routes/Routes";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { getProfilesOfDispute } from "features/filesRV/FileApi";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllMeetings } from "slices/appointmentSlice";
import { getCase } from "slices/casesSlice";
import {
  getMeetingTimeFormatwithDateFirst,
  getMeetingTimeFormatwithDateFirstDIFC
} from "slices/date";
import { getDispute } from "slices/disputeSlice";
import RespondentLawyerChooseStep from "./RespondentLawyerChooseStep";
import SingleDispute from "./SingleDisputeRv";
import SingleDisputeRvTiac from "./SingleDisputeRvTiac";

export default function SingleDisputeRvContainer() {
  let { disputeId } = useParams();

  const [activities, setActivities] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);

  const [loadingActivity, setLoadingActivity] = useState(true);
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [loadingHearings, setLoadingHearings] = useState(true);
  const [hearingDate, setHearingDate] = React.useState("---");

  const {
    profile: { profile: profile },
    disputes: { loading: disputeLoading, activeDispute: activeDispute },
    cases: { loading: caseLoading, activeCase: activeCase },
  } = useSelector((state) => state, shallowEqual);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getDispute(disputeId));
    dispatch(getCase(disputeId));
    // dispatch(getActivityByTabAndId('general',disputeId)).then((res) => {
    //   setActivities(res);
    //   setLoadingActivity(false);
    // });
    getProfiles();
    getMeetings();
  }, []);

  const getMeetings = () => {
    dispatch(getAllMeetings()).then((res) => {
      let data = res.appointments;
      let latestAppointment;
      data.forEach((appointment) => {
        console.log(appointment.meeting);
        if (appointment.meeting.dispute == disputeId) {
          if (latestAppointment) {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            let d2 = new Date(latestAppointment.meeting.start);
            if (d1 < d2 && d1 > d) {
              latestAppointment = appointment;
            } else {
            }
          } else {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            if (d1 > d) latestAppointment = appointment;
          }
        }
      });
      if (latestAppointment) {
        if (isDifc) {
          setHearingDate(
            getMeetingTimeFormatwithDateFirstDIFC(
              latestAppointment.meeting.start,
              profile?.timezone
            )
          );
        } else {
          setHearingDate(
            getMeetingTimeFormatwithDateFirst(
              latestAppointment.meeting.start,
              profile?.timezone
            )
          );
        }
      }
      setLoadingHearings(false);
    });
  };

  const getProfiles = () => {
    getProfilesOfDispute(disputeId)
      .then((res) => {
        let profiles = [];
        res.data.details.claimant.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Claimant";
          profiles.push(p);
        });
        res.data.details.claimant_lawyer.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Claimant's Counsel";
          profiles.push(p);
        });
        res.data.details.respondent.forEach((item) => {
          let p = item;
          p.profile.profile_type = isDifc ? "Defendant" : "Respondent";
          profiles.push(p);
        });
        res.data.details.respondent_lawyer.forEach((item) => {
          let p = item;
          p.profile.profile_type = isDifc
            ? "Defendant's Counsel"
            : "Respondent's Counsel";
          profiles.push(p);
        });
        res.data.details.professionals.forEach((item) => {
          let p = item;
          if (p.profile.profile_type == "arbitrator_rv") {
            p.profile.profile_type = "Arbitrator";
          } else if (p.profile.profile_type === "witness") {
            p.profile.profile_type = "Witness";
          } else if (p.profile.profile_type === "witness_rv") {
            p.profile.profile_type = "Barrister";
          } else if (p.profile.profile_type === "expert_rv") {
            p.profile.profile_type = "Solicitor";
          } else {
            p.profile.profile_type =
              isSebi || isMSME ? "Conciliator" : "Mediator";
          }
          profiles.push(p);
        });
        setAllProfiles(profiles);
        console.log("p", profiles);
        setLoadingProfiles(false);
      })
      .catch((error) => {});
  };

  const reload = () => {
    dispatch(getDispute(disputeId));
    dispatch(getCase(disputeId));
    // dispatch(getActivityByTabAndId('general',disputeId)).then((res) => {
    //   setActivities(res);
    //   setLoadingActivity(false);
    // });
    getProfiles();
  };

  const passObjects = {
    profile,
    activeDispute,
    activeCase,
    activities,
    allProfiles,
    reload,
    hearingDate,
    setActivities,
    disputeId,
  };
  const loading =
    loadingProfiles ||
    loadingHearings ||
    caseLoading ||
    disputeLoading ||
    isEmpty(activeDispute) ||
    isEmpty(activeCase);

  if (!loading) {
    if (profile.is_tiac_profile) {
      if (
        activeCase.type == "respondent" &&
        profile.profile_type == "general_rv" &&
        !activeDispute.rv_respondent_lawyer_step_complete
      ) {
        return <RespondentLawyerChooseStep {...passObjects} />;
      }
      return <SingleDisputeRvTiac {...passObjects} />;
    }
    return <SingleDispute {...passObjects} />;
  } else return <CircularCenteredLoader />;
}
