import React from "react";

import SingleDisputeDetails from "../SingleDisputeDetails";
import ClaimantWaiting from "./ClaimantWaiting";
import {
  RESPONDENT_COMPLETE,
  MEDIATION,
  ADJUDICATION,
  CLAIMANT_COMPLETE,
  COMPLETE,
  RESPONDENT_INPROGRESS,
} from "app/constants";
import ClaimantDecisionContainer from "features/singleDispute/ClaimantFlowSteps/ClaimantDecision/ClaimantDecisionContainer";
import ClaimandAdjudicationDecision from "features/singleDispute/ClaimantFlowSteps/ClaimantAdjudicationDecision/ClaimandAdjudicationDecision";

export default function ClaimantFlowSteps({
  activeDispute,
  activeCase,
  profile,
}) {
  function getStepContent(activeDispute, activeCase, profile) {
    switch (activeDispute.status) {
      case "new":
        if (activeCase.case_status !== "review_complete") {
          return (
            <SingleDisputeDetails
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          );
        } else {
          return <ClaimantWaiting disputeName={activeDispute.name} />;
        }
      case RESPONDENT_INPROGRESS:
        return <ClaimantWaiting disputeName={activeDispute.name} />;

      case RESPONDENT_COMPLETE:  //dsfsd
      if (activeDispute.flow_type === MEDIATION) {
        return (
          <ClaimantDecisionContainer
            activeDispute={activeDispute}
            activeCase={activeCase}
          />
        );
      } else if (activeDispute.flow_type === ADJUDICATION) {
        return (
          <ClaimandAdjudicationDecision
            activeDispute={activeDispute}
            activeCase={activeCase}
          />
        );
      } else {
        return <ClaimantWaiting disputeName={activeDispute.name} />;
      }

    }

  }

  return (
    <React.Fragment>
      {getStepContent(activeDispute, activeCase, profile)}
    </React.Fragment>
  );
}
