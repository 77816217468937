import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ComplianceReportAnonymous from "./ComplianceReportAnonymous";

export default function ComplianceReportContainerAnonymous({ company_name }) {
  const dispatch = useDispatch();
  const [reports, setreports] = useState();
  const [loading, setLoading] = useState(true);

  function getComplianceReports(company_name) {
    return async (dispatch) => {
      return await instance({
        method: "get",
        url: `/publishedcomplaincereportsanonymous/${company_name}/`,
      }).then(
        (response) => {
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    };
  }

  function downloadReportApi(id) {
    return async (dispatch) => {
      return await instance({
        method: "get",
        url: "/compliancereporturlanonymous/" + id + "/",
      }).then(
        (response) => {
          window.open(response.data.url, "_blank");
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    };
  }

  const downloadReport = (id) => {
    dispatch(downloadReportApi(id));
  };

  React.useEffect(() => {
    dispatch(getComplianceReports(company_name)).then((reports) => {
      setreports(reports.reports);
      setLoading(false);
    });
  }, []);

  if (loading) return <CircularCenteredLoader />;
  return (
    <ComplianceReportAnonymous
      reports={reports}
      downloadReport={downloadReport}
    />
  );
}
