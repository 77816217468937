import React, { Fragment, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMuthootBranches, getQuestionsAnonymous, resetQuestions } from "slices/questionSlice";
import Questions from "./Questions";
import { isMuthoot } from "Routes/Routes";

export default function QuestionsContainer({ profile }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getQuestionsAnonymous(
        activeDispute.company_product,
        activeCase.type,
        profile.user.email
      )
    );
    return () => {
        dispatch(resetQuestions());
      };
  }, []);

  const answerLoading = useSelector((state) => state.answers.loading);

  let {
    questions: { questions },
    disputes: { activeDispute },
    cases: { activeCase },
  } = useSelector((state) => state, shallowEqual);

  useEffect(()=>{
    if(isMuthoot && activeCase.type === 'claimant' && questions?.length > 0){
      const indexMuthootBranch = questions.findIndex((ques)=> ques.name === "Please add the name of the Muthoot Finance Branch where you encountered difficulty.");
      if(indexMuthootBranch > -1 && questions[indexMuthootBranch]['type'] === 'simple'){
        const url = `api/disputes/${activeDispute?.id}/branchesanonymous/`;
        dispatch(getMuthootBranches(url,indexMuthootBranch,JSON.parse(JSON.stringify(questions))));
      }
    }
  },[questions])

  return (
    <Fragment>
      {questions.length > 0 && (
        <Questions
          profile={profile}
          questions={questions}
          activeDispute={activeDispute}
          activeCase={activeCase}
          answerLoading={answerLoading}
        />
      )}
    </Fragment>
  );
}
