import { Button, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LanguageIcon from "@material-ui/icons/Language";
import { useIntlContext } from "contexts/IntlContext";
import React from "react";

const options = [
  "English - EN",
  "Hindi - HI",
  "Tamil - TM",
  "Telugu - TE",
  "Bengali - BN",
  // "Malayalam - Ml",
  // "Assamese - AS",
  // "Marathi - MA",
  // "Odia - od",
  // "Punjabi - PU",
];

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    locale,
    switchToEnglish,
    switchToHindi,
    switchToTamil,
    switchToTelugu,
    // switchToMalayalam,
    switchToBengali,
    // switchToAssamese,
    // switchToMarathi,
    // switchToOdia,
    // switchToPunjabi,
  } = useIntlContext();

  // Get selected index using locale instead of using component state since components are re-rendered when using Context API
  const selectedIndex = options.findIndex(
    (option) => locale === option.split("-")[1].toLowerCase().trim()
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    switch (index) {
      case 0:
        switchToEnglish();
        break;
      case 1:
        switchToHindi();
        break;
      case 2:
        switchToTamil();
        break;
      case 3:
        switchToTelugu();
        break;
      case 4:
        switchToBengali();
        break;
      // case 4:
      //   switchToMalayalam();
      //   break;
      // case 6:
      //   switchToAssamese();
      //   break;
      // case 6:
      //   switchToMarathi();
      //   break;
      // case 8:
      //   switchToOdia();
      //   break;
      // case 8:
      //   switchToPunjabi();
      //   break;

      default:
        break;
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="default"
        endIcon={<ArrowDropDownIcon />}
        startIcon={<LanguageIcon />}
      >
        {options[selectedIndex].split("-")[1]}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            // disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
