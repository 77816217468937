import React from "react";

function CloseModalButton({ onClick }) {
  return (
    <button
      type="button"
      style={{
        float: "right",
        cursor: "pointer",
        padding: "2px 5px",
        lineHeight: "20px",
        fontSize: "24px",
        background: "#fff",
        borderRadius: "18px",
        border: "1px solid #cfcece",
      }}
      onClick={onClick}
    >
      &times;
    </button>
  );
}

export default CloseModalButton;
