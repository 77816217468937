import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import Axios from "axios";
import FormattedMessage from "features/common/TranslatedMessage";
import { updateDisputeTiac } from "features/TempTiacFormV3/UploadPage/questions";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCountries } from "slices/profileSlice";
import { Tiaccreateprofile } from "slices/userSlice";
import * as Yup from "yup";
import SaveMyProgress, {
  StyledSelectTiac,
} from "../TiacRFAForm/SaveMyProgress";
import {claimantPageConstants} from '../constants/index';
import ConfigRow from '../constants/ConfigRow';
import getFieldValue from "../constants/getFieldValue";

function checkIfNumber(event) {
  const regex = new RegExp(
    /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown)/
  );
  return !event.key.match(regex) && event.preventDefault();
}
export const blockInvalidChar = (e) => checkIfNumber(e);

export const StyledTextFieldTiac = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2F80ED",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#2F80ED",
      },
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#2F80ED",
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },
  select: {
    "&:after": {
      borderBottomColor: "#2F80ED",
    },
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // "font-size": "18px",
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    color: "#2C3345",
    padding: "1rem",
    fontFamily: `'Inter',sans-serif`,
    display: 'flex'
  },

  text2: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },
  text3: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

var signUpData = {};

export async function getProfileTiacDispute(email, dispute_id, step) {
  return await instance.get(
    `/tiaccgetprofileofdispute/?email=${email}&id=${dispute_id}&step=${step}`
  );
}
export async function sendEmailSaveAsDraft(email) {
  return await instance.get(`/sendtemporarypasswordtiac/${email}/`);
}
export async function getTempDetails(dispute_id) {
  return await instance.get(`/tiactempdetails/?dispute_id=${dispute_id}`);
}

export async function saveTempDetailsClaimant(data) {
  return await instance.post(`/tiacsavetempdetails/`, {
    dispute_id: data.dispute_id,
    claimant_email: data.claimant_email,
    claimant_name: data.claimant_name,
    claimant_nationality: data.claimant_nationality,
    claimant_address: data.claimant_address,
    claimant_city: data.claimant_city,
    claimant_region: data.claimant_region,
    claimant_postal_code: data.claimant_postal_code,
    claimant_country: data.claimant_country,
    claimant_phone_number: data.claimant_phone_number,
    claimant_fax: data.claimant_fax,
  });
}

export default function ClaimantPage({
  proceed,
  setProceed,
  setActiveStep,
  submitDetailsSignal,
  checkSaveMyProgressAndResumeLater,
  setcheckSaveMyProgressAndResumeLater,
  showSaveCheckBox,
  handleOnClickCheckBox,
  afterPasswordSubmitDetailsSignal,
  setIsAfterScreenVisible,
}) {
  const [fieldsInput, setFieldsInput] = useState({});
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();
  const [loading, setLoading] = useState(false);
  const {CLAIMANT_DETAILS_PAGE} = claimantPageConstants;

  function getProfiles(email, cancelToken) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/tiacanonymousemailautofill/?email=${email}`,
          { cancelToken: cancelToken.token }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const getAutofillProfiles = (email) => {
    try {
      // let email = e.traget.value;
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email &&
          res.results[0].profile_type == "general_rv"
        ) {
          let profile = res.results[0];
          profile["name"] =
            res.results[0].user.first_name +
            " " +
            res.results[0].user.last_name;
          setFetchedProfile(profile);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = useCallback(debounce(getAutofillProfiles, 500), []);

  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [allCountries, setallCountries] = useState([]);
  const [country, setCountry] = useState("-");
  const [countryError, setcountryError] = useState();

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);
  let id = localStorage.getItem("dispute_id");
  let localstorageEmail = localStorage.getItem("email");
  let language = localStorage.getItem("i8_lang");
  useEffect(() => {
    if (localstorageEmail && !id) {
      setFieldsInput({...fieldsInput, claimant_email: localstorageEmail})
      handleEmailChange(localstorageEmail);
    }
  }, [id, localstorageEmail]);

  const profile = useSelector((state) => state.profile, shallowEqual);

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  useEffect(() => {
    if (id)
      getTempDetails(id).then((res) => {
        if (res && res.data) {
          setFieldsInput({...res.data, claimant_email: localstorageEmail});
        }
      });
  }, [id]);

  React.useEffect(() => {
    if (!proceed) {
      getDisputeTiac(localstorageEmail, id)
        .then((res) => {
          if (res.data.results[0].id) {
            getProfileTiacDispute(localstorageEmail, id, "claimant").then(
              (res) => {
                if (res.data.user) {
                  // setFetchedProfile(res.data);
                  // autoFill(res.data);
                }
              }
            );
          } else history.push("/RFA2");
        })
        .catch((e) => {
          history.push("/RFA2");
        });
    }
    // if (id && proceed)
    //   getProfileTiacDispute(localstorageEmail, id, "claimant").then((res) => {
    //     setFetchedProfile(res.data);
    //     autoFill(res.data);
    //   });
  }, []);

  async function TiaccreateprofileDisputeId(data) {
    return await instance.post("/tiaccreateprofile/", data);
  }

  useEffect(() => {
    if (submitDetailsSignal) {
      let {
        claimant_name,
        claimant_nationality,
        claimant_address,
        claimant_city,
        claimant_region,
        claimant_postal_code,
        claimant_phone_number,
        claimant_email,
        claimant_fax,
      } = fieldsInput;

      signUpData = {};
      if (location.pathname.toUpperCase() == "/RFA2") {
        if (claimant_name) signUpData["full_name"] = claimant_name;
        if (claimant_nationality) signUpData["nationality"] = claimant_nationality;
        if (claimant_address) signUpData["address"] = claimant_address;
        if (claimant_city) signUpData["city"] = claimant_city;
        if (claimant_region) signUpData["region"] = claimant_region;
        if (claimant_postal_code) signUpData["postal_code"] = claimant_postal_code;
        // if (phone_number) signUpData["phone_number"] = phone_number;
        if (claimant_fax) signUpData["fax"] = claimant_fax;
        if (claimant_email) signUpData["email"] = claimant_email;
        if (country && country != "-") signUpData["country"] = country;
        Tiaccreateprofile(signUpData, "general_rv")
          .then((res) => {
            let tempData = {
              dispute_id: res.data.id,
              email: claimant_email,
              full_name: claimant_name,
              nationality: claimant_nationality,
              address: claimant_address,
              city: claimant_city,
              region: claimant_region,
              postal_code: claimant_postal_code,
              country: country,
              phone_number: claimant_phone_number,
              fax: claimant_fax,
            };

            saveTempDetailsClaimant(tempData).then(() => {
              localStorage.setItem("email", claimant_email);
              localStorage.setItem("dispute_id", res.data.id);
              localStorage.setItem("full_name", claimant_name);
              localStorage.setItem("phone_number", claimant_phone_number);
              updateDisputeTiac(
                {
                  id: res.data.id,
                  rv_claimant_choose_step_complete: false,
                },
                claimant_email
              ).then(() => {
                history.push("/resumerfa2");
              });
            });
          })
          .catch((error) => {
            console.log("er", error.response);
            alert(error?.response?.data?.message);
          });
      } else if (!proceed) {
        let tempData = {
          dispute_id: id,
          email: claimant_email,
          full_name: claimant_name,
          nationality: claimant_nationality,
          address: claimant_address,
          city: claimant_city,
          region: claimant_region,
          postal_code: claimant_postal_code,
          country: country,
          phone_number: claimant_phone_number,
          fax: claimant_fax,
        };
        claimant_phone_number = "" + claimant_phone_number;
        if (
          claimant_email.trim() == "" ||
          claimant_name.trim() == "" ||
          claimant_nationality.trim() == "" ||
          claimant_address.trim() == "" ||
          claimant_city.trim() == "" ||
          claimant_region.trim() == "" ||
          claimant_postal_code.trim() == "" ||
          country == "-" ||
          claimant_phone_number.trim() == ""
        ) {
          updateDisputeTiac(
            {
              id: id,
              rv_claimant_choose_step_complete: false,
            },
            localstorageEmail
          ).then((resp) => {
            saveTempDetailsClaimant(tempData).then(() => {
              history.push("/resumerfa2?random=" + Math.random());
            });
          });
        } else {
          saveTempDetailsClaimant(tempData).then(() => {
            history.push("/resumerfa2?random=" + Math.random());
          });
        }
      } else {
        let data = {
          email: localstorageEmail,
          profile_type: "general_rv",
          for: "claimant",
          dispute_id: id,
        };
        TiaccreateprofileDisputeId(data)
          .then((res) => {
            let tempData = {
              dispute_id: res.data.id,
              email: claimant_email,
              full_name: claimant_name,
              nationality: claimant_nationality,
              address: claimant_address,
              city: claimant_city,
              region: claimant_region,
              postal_code: claimant_postal_code,
              country: country,
              phone_number: claimant_phone_number,
              fax: claimant_fax,
            };
            claimant_phone_number = "" + claimant_phone_number;
            if (
              claimant_email.trim() == "" ||
              claimant_name.trim() == "" ||
              claimant_nationality.trim() == "" ||
              claimant_address.trim() == "" ||
              claimant_city.trim() == "" ||
              claimant_region.trim() == "" ||
              claimant_postal_code.trim() == "" ||
              country == "-" ||
              claimant_phone_number.trim() == ""
            ) {
              updateDisputeTiac(
                {
                  id: id,
                  rv_claimant_choose_step_complete: false,
                },
                localstorageEmail
              ).then((res) => {
                saveTempDetailsClaimant(tempData).then(() => {
                  history.push("/resumerfa2?random=" + Math.random());
                });
              });
            } else {
              saveTempDetailsClaimant(tempData).then(() => {
                history.push("/resumerfa2?random=" + Math.random());
              });
            }
          })
          .catch((error) => {
            alert(error?.response?.data?.message);
          });
      }
    }
  }, [submitDetailsSignal]);

  useEffect(() => {
    if (afterPasswordSubmitDetailsSignal) {
      let values = fieldsInput;
      let {
        claimant_name,
        claimant_nationality,
        claimant_address,
        claimant_city,
        claimant_region,
        claimant_postal_code,
        claimant_phone_number,
        claimant_email,
        claimant_fax,
      } = fieldsInput;
      let data = {
        email: localstorageEmail,
        profile_type: "general_rv",
        for: "claimant",
        dispute_id: id,
      };
      TiaccreateprofileDisputeId(data)
        .then((res) => {
          let tempData = {
            dispute_id: res.data.id,
            email: claimant_email,
            full_name: claimant_name,
            nationality: claimant_nationality,
            address: claimant_address,
            city: claimant_city,
            region: claimant_region,
            postal_code: claimant_postal_code,
            country: country,
            phone_number: claimant_phone_number,
            fax: claimant_fax,
          };
          claimant_phone_number = "" + claimant_phone_number;
          if (
            claimant_email.trim() == "" ||
            claimant_name.trim() == "" ||
            claimant_nationality.trim() == "" ||
            claimant_address.trim() == "" ||
            claimant_city.trim() == "" ||
            claimant_region.trim() == "" ||
            claimant_postal_code.trim() == "" ||
            country == "-" ||
            claimant_phone_number.trim() == ""
          ) {
            updateDisputeTiac(
              {
                id: id,
                rv_claimant_choose_step_complete: false,
              },
              localstorageEmail
            ).then((res) => {
              saveTempDetailsClaimant(tempData).then(() => {
                setIsAfterScreenVisible(true);
              });
            });
          } else {
            saveTempDetailsClaimant(tempData).then(() => {
              setIsAfterScreenVisible(true);
            });
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.message);
        });
    }
  }, [afterPasswordSubmitDetailsSignal]);

  function checkFieldError(fieldName, condition, errorText, hasError) {
    if (condition) {
        setFieldsInput((prevFieldsInput) => ({
            ...prevFieldsInput,
            error: {
                ...prevFieldsInput.error,
                [fieldName]: errorText,
            }
        }));
        return [...hasError, fieldName]
    }

    return hasError;
  }

  function handleSubmitDetails(){
    //field validation
    let hasError = [];
    hasError = checkFieldError("claimant_email", !fieldsInput.claimant_email, "Invalid email", hasError);
    hasError = checkFieldError("claimant_name", !fieldsInput.claimant_name, "Full name is required", hasError);
    hasError = checkFieldError("claimant_nationality", !fieldsInput.claimant_nationality, "Nationality is required", hasError);
    hasError = checkFieldError("claimant_address", !fieldsInput.claimant_address, "Apartment and Street is required", hasError);
    hasError = checkFieldError("claimant_city", !fieldsInput.claimant_city, "City is required", hasError);
    hasError = checkFieldError("claimant_region", !fieldsInput.claimant_region, "Region and State is required", hasError);
    hasError = checkFieldError("claimant_postal_code", !fieldsInput.claimant_postal_code, "Postal code is required", hasError);
    hasError = checkFieldError("claimant_phone_number", !fieldsInput.claimant_phone_number, "Phone Number is required", hasError);
    hasError = checkFieldError("claimant_country", !fieldsInput.claimant_country, "Country is required", hasError);
    console.log(hasError,'hasErorr');

    if (hasError.length) return false;
    let updatedData = {
      "dispute_id": id,
      "claimant_email": fieldsInput.claimant_email,
      "claimant_name": fieldsInput.claimant_name,
      "claimant_nationality": fieldsInput.claimant_nationality,
      "claimant_address": fieldsInput.claimant_address,
      "claimant_city": fieldsInput.claimant_city,
      "claimant_region": fieldsInput.claimant_postal_code,
      // "claimant_phone_number": fieldsInput.claimant_phone_number,
      "claimant_country": fieldsInput.claimant_country,
      "claimant_fax": fieldsInput.claimant_fax,
    }
    if (!proceed) {
      localStorage.setItem("full_name", fieldsInput.claimant_name);
      localStorage.setItem("phone_number", fieldsInput.claimant_phone_number);
      updateDisputeTiac(
        {
          id: id,
          rv_claimant_choose_step_complete: true,
        },
        localstorageEmail
      ).then(() => {
        saveTempDetailsClaimant(updatedData).then(() => {
          setActiveStep(2);
        });
      });
    } else if (location.pathname == "/resumerfa2") {
      TiaccreateprofileDisputeId(updatedData)
        .then((res) => {
          let tempData = {
            dispute_id: res.data.id,
            email: fieldsInput.claimant_email,
            full_name: fieldsInput.claimant_name,
            nationality: fieldsInput.claimant_nationality,
            address: fieldsInput.claimant_address,
            city: fieldsInput.claimant_city,
            region: fieldsInput.claimant_region,
            postal_code: fieldsInput.claimant_postal_code,
            country: fieldsInput.claimant_country,
            // phone_number: fieldsInput.claimant_phone_number,
            fax: fieldsInput.claimant_fax,
          };
          localStorage.setItem("full_name", fieldsInput.claimant_name);
          localStorage.setItem("phone_number", fieldsInput.claimant_phone_number);
          updateDisputeTiac(
            {
              id: res.data.id,
              rv_claimant_choose_step_complete: true,
            },
            localstorageEmail
          ).then(() => {
            saveTempDetailsClaimant(tempData).then(() => {
              if (proceed) setActiveStep(1);
              else setActiveStep(2);
            });
          });
        })
        .catch((error) => {
          alert(error?.response?.data?.message);
          setLoading(false);
        });
      } else {
          let tempData = {
            email: fieldsInput.claimant_email,
            full_name: fieldsInput.claimant_name,
            nationality: fieldsInput.claimant_nationality,
            address: fieldsInput.claimant_address,
            city: fieldsInput.claimant_city,
            region: fieldsInput.claimant_region,
            postal_code: fieldsInput.claimant_postal_code,
            country: fieldsInput.claimant_country,
            // phone_number: fieldsInput.claimant_phone_number,
            fax: fieldsInput.claimant_fax,
          };
          Tiaccreateprofile(tempData, "general_rv")
            .then((res) => {
              let tempData = {
                dispute_id: res.data.id,
                claimant_email: fieldsInput.claimant_email,
                claimant_name: fieldsInput.claimant_name,
                claimant_nationality: fieldsInput.claimant_nationality,
                claimant_address: fieldsInput.claimant_address,
                claimant_city: fieldsInput.claimant_city,
                claimant_region: fieldsInput.claimant_region,
                claimant_postal_code: fieldsInput.claimant_postal_code,
                claimant_country: fieldsInput.claimant_country,
                // claimant_phone_number: fieldsInput.claimant_phone_number,
                claimant_fax: fieldsInput.claimant_fax,
              };
              updateDisputeTiac(
                {
                  id: res.data.id,
                  rv_claimant_choose_step_complete: true,
                },
                localstorageEmail
              );
              saveTempDetailsClaimant(tempData).then(() => {
                localStorage.setItem("email", fieldsInput.claimant_email);
                localStorage.setItem("dispute_id", res.data.id);
                localStorage.setItem("full_name", fieldsInput.claimant_name);
                localStorage.setItem("phone_number", fieldsInput.claimant_phone_number);
                history.push("/resumerfa2?find_active_step=true");
              });
            })
            .catch((error) => {
              console.log("er", error.response);
              alert(error?.response?.data?.message);
              setLoading(false);
            });
        }
  }

  function handleFieldChange(value, name) {
    setFieldsInput((prevFieldsInput) => ({
        ...prevFieldsInput,
        ...(getFieldValue(value, fieldsInput, name))
    }));
  }

  return (
    <>
        <ConfigRow
          fieldsInput={fieldsInput}
          cards={CLAIMANT_DETAILS_PAGE(allCountries, proceed)}
          onChange={handleFieldChange}
          classes={classes}
        />
       <Grid container justify="center" spacing={3}>
        {location.pathname.toUpperCase() == "/RFA2" && (
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setProceed(false);
              }}
            >
              <Typography className={classes.btnTextBlack}>
                <FormattedMessage id="tiac.back" />
              </Typography>
            </Button>
          </Grid>
        )}
        <Grid item>
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              style={{ background: "#2F80ED" }}
              onClick={handleSubmitDetails}
              disabled={loading}
            >
              <Typography className={classes.btnTextWhite}>
                <FormattedMessage id="buttons.proceed" />
              </Typography>
            </Button>
          </Box>
        </Grid>
        {showSaveCheckBox && (
          <Grid item>
            <SaveMyProgress
              checkSaveMyProgressAndResumeLater={
                checkSaveMyProgressAndResumeLater
              }
              setcheckSaveMyProgressAndResumeLater={
                setcheckSaveMyProgressAndResumeLater
              }
              handleOnClickCheckBox={handleOnClickCheckBox}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
