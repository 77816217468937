import {
    Box,
    Button,
    Grid,
    makeStyles,
    Paper,
    Typography,
  } from "@material-ui/core";
  import TextareaAutosize from "@material-ui/core/TextareaAutosize";
  import FormattedMessage from "features/common/TranslatedMessage";
  import blackpenSvg from "images/blackpen.svg";
  import React, { useState } from "react";
  import { useDispatch } from "react-redux";
  import { updateDispute } from "slices/disputeSlice";
  
  const useStyles = makeStyles((theme) => ({
    paper: {
     display: 'flex'
    },
    blackheading: {
      fontWeight: "bold",
      fontSize: "10px",
      lineHeight: "23px",
      letterSpacing: "0.4px",
      color: "#454545",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "15px",
      },
      display: 'flex'
    },
  }));
  
  export default function CaseAssignInput({ tiac_custom_dispute_id, caseId }) {
    const classes = useStyles();
    const dispatch = useDispatch();
  
    const [edit, setEdit] = useState(false);
    const [customDisputeId, setCustomDisputeId] = useState(tiac_custom_dispute_id);
  
    const handleChange = (e) => {
      setCustomDisputeId(e.target.value);
    };
  
    const onSubmit = () => {
      dispatch(
        updateDispute({
          id: caseId,
          tiac_custom_dispute_id: customDisputeId,
        })
      );
      setEdit(false);
    };
  
    const element = (
      <TextareaAutosize
        data-testid={`1234213421343421`}
        data-question-type="textarea"
        required
        label="Required"
        onChange={handleChange}
        className={classes.item}
        value={customDisputeId}
        rowsMin={1}
      />
    );
  
    return (
      <Paper className={classes.paper}>
        <Grid container>
          {!edit ? (
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.blackheading}>{customDisputeId}</Typography>
            </Grid>
          ) : (
            <Grid item md={12} sm={12} xs={12}>
              <Box display="flex" justifyContent="start">
                {element}
              </Box>
              <Box height="1rem"></Box>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                style={{ background: "#2F80ED" }}
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container justify="space-between">
          {!edit && (
            <Grid item>
              <img
                src={blackpenSvg}
                alt="pen image"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setEdit(true);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
  