import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import Axios from "axios";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCountries } from "slices/profileSlice";
import * as Yup from "yup";
import { getRealhost } from "slices/disputeSlice";
import {
  blockInvalidChar,
  getProfileTiacDispute,
  getTempDetails,
  RadioCard,
  StyledTextFieldTiac,
  UploadAnswer,
} from "../ClaimantPage/questions";
import { getCaseFile, getQuestionsTiac, updateDisputeTiac } from "../UploadPage/questions";
import { editAnswerTiac, GetAnswersTiac } from "../Tiacfinal/final";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },
  select: {
    "&:after": {
      borderBottomColor: "#2F80ED",
    },
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    // "font-size": "18px",
    // color: "#2C3345",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    // color: "#2C3345",
    padding: "1rem",
    // fontFamily: `'Inter',sans-serif`,
  },

  text2: {
    "font-size": "18px",
    // fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },
  text3: {
    "font-size": "18px",
    // fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    // fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

const SignupSchemaRequired = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email Address")
    .required("Email Address is required"),
  full_name: Yup.string().required("Name of the Entity is required"),
  nationality: Yup.string(),
  address: Yup.string().required("Apartment and Street is required"),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("Region and State is required"),
  postal_code: Yup.string().required("Postal code is required"),
  phone_number: Yup.string(),
});

var signUpData = {};
export default function RespondentPage({ setActiveStep, submitDetailsSignal }) {
  const [cancelToken, setCancelToken] = useState();
  const [fetchedProfile, setFetchedProfile] = useState();

  function autoFill(fetchedProfile) {
    formik.setFieldTouched("email", false);
    formik.setFieldTouched("full_name", false);
    formik.setFieldTouched("nationality", false);
    formik.setFieldTouched("phone_number", false);
    formik.setFieldTouched("address", false);
    formik.setFieldTouched("city", false);
    formik.setFieldTouched("region", false);
    formik.setFieldTouched("postal_code", false);
    formik.setFieldValue("email", fetchedProfile.user.email);
    formik.setFieldValue(
      "full_name",
      fetchedProfile.name ? fetchedProfile.name : ""
    );
    formik.setFieldValue(
      "nationality",
      fetchedProfile.nationality ? fetchedProfile.nationality : ""
    );
    formik.setFieldValue(
      "phone_number",
      fetchedProfile.phone_number ? fetchedProfile.phone_number : ""
    );
    formik.setFieldValue("fax", fetchedProfile.fax ? fetchedProfile.fax : "");
    formik.setFieldValue(
      "address",
      fetchedProfile.address ? fetchedProfile.address : ""
    );
    formik.setFieldValue(
      "city",
      fetchedProfile.city ? fetchedProfile.city : ""
    );
    formik.setFieldValue(
      "region",
      fetchedProfile.region ? fetchedProfile.region : ""
    );
    formik.setFieldValue(
      "postal_code",
      fetchedProfile.postal_code ? fetchedProfile.postal_code : ""
    );
    setCountry(fetchedProfile.country ? fetchedProfile.country : "-");
    setcountryError();
    setFetchedProfile();
  }

  function getProfiles(email, cancelToken) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/tiacanonymousemailautofill/?email=${email}`,
          { cancelToken: cancelToken.token }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const getAutofillProfiles = (email) => {
    try {
      // let email = e.traget.value;
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
      setFetchedProfile();
      dispatch(getProfiles(email, x)).then((res) => {
        if (
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results[0].user.email == email &&
          res.results[0].profile_type == "general_rv"
        ) {
          let profile = res.results[0];
          profile["name"] =
            res.results[0].user.first_name +
            " " +
            res.results[0].user.last_name;
          setFetchedProfile(profile);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = useCallback(debounce(getAutofillProfiles, 500), []);

  const classes = useStyles();

  let history = useHistory();
  const dispatch = useDispatch();

  const [allCountries, setallCountries] = useState([]);
  const [country, setCountry] = useState("-");
  const [countryError, setcountryError] = useState();
  const [questionRadio, setQuestionRadio] = useState();
  const [answerRadio, setAnswerRadio] = useState();
  const [RadioError, setRadioError] = useState();

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  let id = localStorage.getItem("dispute_id");
  let localstorageEmail = localStorage.getItem("email");
  const [dispute, setDispute] = useState();
  const [casefile, setCasefile] = useState();

  React.useEffect(() => {
    getQuestionsTiac(localstorageEmail).then(
      (res) => {
        setQuestionRadio(
          res.data.results.find((item) => item.display_order_id == 35)
        );
      },
      (error) => {
        console.log("err", error);
      }
    );
  }, [localstorageEmail]);

  useEffect(() => {
    if (questionRadio) {
      GetAnswersTiac(localstorageEmail, id).then((res) => {
        let t = res.data.results.find(
          (item) => item.question === questionRadio.id
        );
        setAnswerRadio(t);
        setSelectedValue(t ? t.value : null);
      });
    }
  }, [questionRadio, localstorageEmail, id]);

  async function getDisputeTiac(email, dispute_id) {
    return await instance.get(
      `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
    );
  }

  useEffect(() => {
    getTempDetails(id).then((res) => {
      let fetchTempProfile = {};
      if (res && res.data) {
        if (res.data.respondent_email) {
          fetchTempProfile.user = { email: res.data.respondent_email };
        }
        if (res.data.respondent_name) {
          fetchTempProfile.name = res.data.respondent_name;
        }
        if (res.data.respondent_nationality) {
          fetchTempProfile.nationality = res.data.respondent_nationality;
        }
        if (res.data.respondent_phone_number) {
          fetchTempProfile.phone_number = res.data.respondent_phone_number;
        }
        if (res.data.respondent_fax) {
          fetchTempProfile.fax = res.data.respondent_fax;
        }
        if (res.data.respondent_address) {
          fetchTempProfile.address = res.data.respondent_address;
        }
        if (res.data.respondent_city) {
          fetchTempProfile.city = res.data.respondent_city;
        }
        if (res.data.respondent_region) {
          fetchTempProfile.region = res.data.respondent_region;
        }
        if (res.data.respondent_postal_code) {
          fetchTempProfile.postal_code = res.data.respondent_postal_code;
        }
        if (res.data.respondent_country) {
          fetchTempProfile.country = res.data.respondent_country;
        }
        if (fetchTempProfile.user) autoFill(fetchTempProfile);
      }
    });
  }, [id]);

  React.useEffect(() => {
    getDisputeTiac(localstorageEmail, id)
      .then((res) => {
        if (res.data.results[0].id) {
          setDispute(res.data.results[0]);
          // if (res.data.results[0].rv_respondent_choose_step_complete)
          getProfileTiacDispute(localstorageEmail, id, "respondent").then(
            (res) => {
              if (res.data.user) {
                // setFetchedProfile(res.data);
                // autoFill(res.data);
              }
            }
          );
        } else history.push("/RFA");
      })
      .catch((e) => {
        history.push("/RFA");
      });
  }, [history, id, localstorageEmail]);

  React.useEffect(() => {
    getCaseFile(localstorageEmail, id)
      .then((res) => {
        setCasefile(res.data.results[0]);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, [localstorageEmail, id]);

  async function TiaccreateprofileDisputeId() {
    let data = {
      email: formik.values.email,
      profile_type: "general_rv",
      for: "respondent",
      dispute_id: id,
      group: getRealhost(),
    };
    if (formik.values.full_name.trim() != "") {
      data.full_name = formik.values.full_name;
    }
    if (formik.values.nationality.trim() != "") {
      data.nationality = formik.values.nationality;
    }
    if (formik.values.address.trim() != "") {
      data.address = formik.values.address;
    }
    if (formik.values.city.trim() != "") {
      data.city = formik.values.city;
    }
    if (formik.values.region.trim() != "") {
      data.region = formik.values.region;
    }
    if (formik.values.postal_code.trim() != "") {
      data.postal_code = formik.values.postal_code;
    }
    if (country && country != "-") {
      data.country = country;
    } else {
      data.country = null;
    }
    let ph = "" + formik.values.phone_number;
    if (ph.trim() != "") {
      data.phone_number = ph;
    }
    if (formik.values.fax.trim() != "") {
      data.fax = formik.values.fax;
    }
    return await instance.post("/tiaccreateprofile/", data);
  }

  async function saveTempDetailsRespondent(data) {
    return await instance.post(`/tiacrespondentdetails/`, {
      dispute_id: data.dispute_id,
      email: data.email,
      full_name: data.full_name,
      nationality: data.nationality,
      address: data.address,
      city: data.city,
      region: data.region,
      postal_code: data.postal_code,
      country: data.country,
      phone_number: data.phone_number,
      fax: data.fax,
    });
  }

  const formik = useFormik({
    initialValues: {
      full_name: "",
      nationality: "",
      address: "",
      city: "",
      region: "",
      postal_code: "",
      phone_number: "",
      email: "",
      fax: "",
    },

    validationSchema: SignupSchemaRequired,

    onSubmit: async (values) => {
      let {
        full_name,
        nationality,
        address,
        city,
        region,
        postal_code,
        phone_number,
        email,
        fax,
      } = values;

      signUpData = values;
      if (country == "-") {
        setcountryError("Country is required");
      }else if (!selectedValue) {
        setRadioError("Required Field");
      }
      else {
        if (answerRadio) {
          editAnswerTiac(
            localstorageEmail,
            id,
            answerRadio.id,
            selectedValue,
            casefile.id,
            questionRadio.id
          )
        } else {
          UploadAnswer(selectedValue, casefile, questionRadio)
        }
        let tempData = {
          dispute_id: id,
          email: email,
          full_name: full_name,
          nationality: nationality,
          address: address,
          city: city,
          region: region,
          postal_code: postal_code,
          country: country,
          phone_number: phone_number,
          fax: fax,
        };
        updateDisputeTiac(
          {
            id: id,
            rv_respondent_choose_step_complete: true,
          },
          localstorageEmail
        ).then(() => {
          saveTempDetailsRespondent(tempData).then(() => {
            setActiveStep(3);
          });
        });
      }
    },
  });

  useEffect(() => {
    if (submitDetailsSignal) {
      let {
        full_name,
        nationality,
        address,
        city,
        region,
        postal_code,
        phone_number,
        email,
        fax,
      } = formik.values;
      let tempData = {
        dispute_id: id,
        email: formik.values.email,
        full_name: formik.values.full_name,
        nationality: formik.values.nationality,
        address: formik.values.address,
        city: formik.values.city,
        region: formik.values.region,
        postal_code: formik.values.postal_code,
        country: country,
        phone_number: formik.values.phone_number,
        fax: formik.values.fax,
      };
      phone_number = "" + phone_number;
      if (
        email.trim() == "" ||
        full_name.trim() == "" ||
        nationality.trim() == "" ||
        address.trim() == "" ||
        city.trim() == "" ||
        region.trim() == "" ||
        postal_code.trim() == "" ||
        country == "-" ||
        phone_number.trim() == ""
      ) {
        updateDisputeTiac(
          {
            id: id,
            rv_respondent_choose_step_complete: false,
          },
          localstorageEmail
        ).then((resp) => {
          saveTempDetailsRespondent(tempData).then(() => {
            history.push("/resumerfa?random=" + Math.random());
          });
        });
      } else {
        saveTempDetailsRespondent(tempData).then(() => {
          history.push("/resumerfa?random=" + Math.random());
        });
      }
    }
  }, [
    country,
    formik.values,
    history,
    id,
    localstorageEmail,
    submitDetailsSignal,
  ]);

  const [selectedValue, setSelectedValue] = React.useState();

  const handleChange = (event) => {
    setRadioError();
    setSelectedValue(event.target.value);
  };

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3} justify="center">
        {/* <Grid item xs></Grid> */}
        <Grid item xs={11}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" className={classes.text}>
                <FormattedMessage id="tiac.heading3_msme" />
              </Typography>
              <Box height="1rem"></Box>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} justify="flex-start">
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      3.1. Name of the Entity:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.full_name}
                        fullWidth
                        autoFocus
                        id="full_name"
                        // label={<FormattedMessage id="user.email" />}
                        name="full_name"
                        autoComplete="full_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.full_name && formik.touched.full_name && (
                        <Alert severity="error">
                          {formik.errors.full_name}
                        </Alert>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      3.2. Email Address:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={5}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.email}
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="email"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleEmailChange(e.target.value);
                        }}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <Alert severity="error">{formik.errors.email}</Alert>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      {fetchedProfile && (
                        <Typography
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            autoFill(fetchedProfile);
                          }}
                        >
                          Autofill details
                        </Typography>
                      )}
                    </Grid>
                    <Box height="1rem"></Box>

                    <Typography className={classes.text2}>
                      example@example.com
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem", marginTop: "1.5rem" }}
                  >
                    <Typography className={classes.text1}>
                      3.3. Phone number:{" "}
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <Grid item md={5} sm={10} xs={12}>
                        <StyledTextFieldTiac
                          type="number"
                          variant="outlined"
                          margin="normal"
                          value={formik.values.phone_number}
                          fullWidth
                          id="phone_number"
                          name="phone_number"
                          autoComplete="phone_number"
                          onKeyDown={blockInvalidChar}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.phone_number &&
                          formik.touched.phone_number && (
                            <Alert severity="error">
                              {formik.errors.phone_number}
                            </Alert>
                          )}
                        <Box height="1rem"></Box>
                      </Grid>
                    </Grid>
                    <Typography
                      className={classes.text3}
                      style={{ padding: "0 1rem" }}
                    >
                      Please enter only numerals and a valid contact number
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0 1rem" }}
                  >
                    <Typography className={classes.text1}>
                      3.4. Registered Office Address:{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.address}
                        fullWidth
                        id="address"
                        // label={<FormattedMessage id="user.email" />}
                        name="address"
                        autoComplete="address"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.address && formik.touched.address && (
                        <Alert severity="error">{formik.errors.address}</Alert>
                      )}
                    </Grid>
                    <Typography className={classes.text2}>
                      Apartment and Street
                    </Typography>
                    <Grid container style={{ padding: "0 1rem" }}>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingRight: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          variant="outlined"
                          margin="normal"
                          value={formik.values.city}
                          fullWidth
                          id="city"
                          // label={<FormattedMessage id="user.email" />}
                          name="city"
                          autoComplete="city"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.city && formik.touched.city && (
                          <Alert severity="error">{formik.errors.city}</Alert>
                        )}

                        <Typography className={classes.text3}>
                          Сity (Town)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sm={10}
                        xs={12}
                        style={{ paddingLeft: "0.5rem" }}
                      >
                        <StyledTextFieldTiac
                          variant="outlined"
                          margin="normal"
                          value={formik.values.region}
                          fullWidth
                          id="region"
                          // label={<FormattedMessage id="user.email" />}
                          name="region"
                          autoComplete="region"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.region && formik.touched.region && (
                          <Alert severity="error">{formik.errors.region}</Alert>
                        )}
                        <Typography className={classes.text3}>
                          Region and State
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={10}
                      sm={10}
                      xs={12}
                      style={{ padding: "0 1rem" }}
                    >
                      <StyledTextFieldTiac
                        variant="outlined"
                        margin="normal"
                        value={formik.values.postal_code}
                        fullWidth
                        id="postal_code"
                        // label={<FormattedMessage id="user.email" />}
                        name="postal_code"
                        autoComplete="postal_code"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.postal_code &&
                        formik.touched.postal_code && (
                          <Alert severity="error">
                            {formik.errors.postal_code}
                          </Alert>
                        )}
                    </Grid>

                    <Typography className={classes.text2}>
                      Postal code
                    </Typography>
                    <Box height="2rem"></Box>
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={12}
                      style={{ padding: "1rem" }}
                    >
                      <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel> */}
                        <Select
                          labelId="demo-country-select-label"
                          id="demo-country-select"
                          value={country}
                          className={classes.select}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            if (e.target.value == "-") {
                              setcountryError("Country is required");
                            } else {
                              setcountryError();
                            }
                          }}
                        >
                          <MenuItem value={"-"}>---</MenuItem>
                          {allCountries.map((country) => {
                            return (
                              <MenuItem key={country.id} value={country.id}>
                                {country.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {countryError && (
                        <Box style={{ paddingTop: "0.5rem" }}>
                          <Alert severity="error">{countryError}</Alert>
                        </Box>
                      )}
                    </Grid>

                    <Typography className={classes.text2}>Country</Typography>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className={classes.text1}>
                      3.5. Is the Entity a registered MSME?{" "}
                      <span className={classes.required}>*</span>
                    </Typography>
                    <Grid item md={5} sm={10} xs={12}>
                      <Box>
                        <RadioCard
                          option={1}
                          selectedValue={selectedValue}
                          handleChange={handleChange}
                        />
                        <RadioCard
                          option={2}
                          selectedValue={selectedValue}
                          handleChange={handleChange}
                        />
                      </Box>
                      <Box style={{ padding: "0 1rem" }}>
                        {RadioError && (
                          <Alert severity="error">{RadioError}</Alert>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Box height="2rem" />


                </Grid>
                <Box height="4rem"></Box>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ background: "#2F80ED" }}
                        onClick={() => {
                          if (country == "-")
                            setcountryError("Country is required");
                        }}
                      >
                        <Typography className={classes.btnTextWhite}>
                          <FormattedMessage id="buttons.proceed" />
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs /> */}
      </Grid>
    </Box>
  );
}
