import { Box, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import Lines from "./DonutGraph/Lines";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export default function DonutGraph({ data: d, choosestep2 }) {
  const id = 100;
  const [data, setdata] = useState(d);
  let closed = Math.ceil((getRandomInt(35, 40) * d) / 100);
  let pending = Math.floor((getRandomInt(20, 25) * d) / 100);
  let new_ = Math.floor((getRandomInt(10, 15) * d) / 100);
  let drafts = Math.floor((getRandomInt(9, 14) * d) / 100);
  let rejected = d - closed - pending - new_ - drafts;

  const [series, setSeries] = useState([
    closed,
    pending,
    new_,
    drafts,
    rejected,
  ]);

  const theme = useTheme();
  const chartData = {
    series: series,
    options: {
      chart: {
        id: id,
        // width: 900,
        type: "donut",
        // animations: {
        //   enabled: false,
        // },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "40px",
                fontFamily: "Roboto,Helvetica, Arial, sans-serif",
                color: "#333333",
                // formatter: () => "" + data,
              },
              total: {
                show: true,
                label: "Total",
                fontSize: "40px",
                fontFamily: "Roboto,Helvetica, Arial, sans-serif",
                color: "#333333",
                formatter: () => "" + data,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#9EF3B1", "#81F5FD", "#61A0FF", "#EEFF85", "#FF665C"],
      labels: ["Closed", "Pending", "New", "Drafts", "Rejected"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  React.useEffect(() => {
    if (d != data) {
      setdata(d);
      let closed = Math.ceil((getRandomInt(35, 40) * d) / 100);
      let pending = Math.floor((getRandomInt(20, 25) * d) / 100);
      let new_ = Math.floor((getRandomInt(10, 15) * d) / 100);
      let drafts = Math.floor((getRandomInt(9, 14) * d) / 100);
      let rejected = d - closed - pending - new_ - drafts;
      setSeries([closed, pending, new_, drafts, rejected]);

      let newoptions = {
        chart: {
          id: id,
          // width: 900,
          type: "donut",
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  show: false,
                },
                // value: {
                //   show: true,
                //   label: "",
                //   fontSize: "30px",
                //   fontFamily: "Roboto,Helvetica, Arial, sans-serif",
                //   color: "#333333",
                //   formatter: () => "" + d,
                // },
                total: {
                  show: true,
                  label: "",
                  fontSize: "30px",
                  fontFamily: "Roboto,Helvetica, Arial, sans-serif",
                  color: "#333333",
                  formatter: () => "" + d,
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#9EF3B1", "#81F5FD", "#61A0FF", "#EEFF85", "#FF665C"],
        labels: ["Closed", "Pending", "New", "Drafts", "Rejected"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      };
      ApexCharts.exec(id, "updateOptions", newoptions);
    }
  }, [d, data, series]);

  return (
    <Paper
      style={{
        overflow: "hidden",
        textAlign: "center",
        padding: "3rem",
        height: "-webkit-fill-available",
        maxHeight: "100%",
      }}
    >
      <Box style={{ textAlign: "left" }}>
        <Typography style={{ fontSize: "20px", color: "#333333" }}>
          {choosestep2 == 1
            ? "Complaints"
            : choosestep2 == 2
            ? "Arbitrations"
            : "Appellate Arbitrations"}
        </Typography>
      </Box>
      <Grid container justify="center">
        <Grid item md={12} sm={12}>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="donut"
          />
        </Grid>
      </Grid>
      <Box height="3rem" />
      <Lines
        labels={chartData.options.labels}
        cols={chartData.options.colors}
        data={series}
      />
    </Paper>
  );
}
