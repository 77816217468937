import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCU2xqddQgbYZWB3LXoT7XG1fDco6AmfeE",
  authDomain: "webnyay-a7265.firebaseapp.com",
  databaseURL: "https://webnyay-a7265.firebaseio.com",
  projectId: "webnyay-a7265",
  storageBucket: "webnyay-a7265.appspot.com",
  messagingSenderId: "833799547715",
  appId: "1:833799547715:web:f6aa0ecfdbc023397c5df6",
  measurementId: "G-GCBEYZ7P04",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const firestore = firebase.firestore();

auth.useDeviceLanguage();

const googleProvider = new firebase.auth.GoogleAuthProvider();

// export const signInWithGoogle = () => {
//   return auth
//     .setPersistence(firebase.auth.Auth.Persistence.NONE)
//     .then(() => {
//       return auth.signInWithPopup(googleProvider);
//     })
//     .catch(function (error) {
//       // Handle Errors here.
//       const errorCode = error.code;
//       const errorMessage = error.message;
//     });
// };

export const signInWithGoogle = () => {
  return auth
    .setPersistence(firebase.auth.Auth.Persistence.NONE)
    .then(() => {
      return auth.signInWithRedirect(googleProvider);
    })
    .catch(function (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};

export function signInWithLinkedin(token) {
  return auth
    .setPersistence(firebase.auth.Auth.Persistence.NONE)
    .then(() => {
      return auth.signInWithCustomToken(token);
    })
    .catch((error) => {
      console.error(error);
    });
}
