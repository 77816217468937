import React from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import {
    Box,
    FormControl,
    Grid,
    Container,
    Button,
    makeStyles,
    MenuItem,
    Select,
    Table,
    Typography,
    IconButton,
  } from "@material-ui/core";
  import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "0.5rem",
      // cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    title: {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      border: "none",
      fontWeight: "bold",
      fontSize: "1.5rem",
      lineHeight: "3.6rem",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
        lineHeight: "2.8rem",
      },
    },
    value: {
      // fontSize: 14,
      border: "none",
      padding: "0.5rem",
      fontSize: "16px",
      lineHeight: "19px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
        lineHeight: "10px",
      },
    },
    contentContainer: {
      display: "flex",
      justifyContent: "center",
      textTransform: "capitalize",
    },
  }));

  export default function DisputeRow({ dispute }) {
    const classes = useStyles();
    const history = useHistory();
  
    const data = [
      {
        // title: <FormattedMessage id="dispute.disputeId" />,
        value: dispute.id,
      },
      {
        // title: <FormattedMessage id="dispute.title" />,
        value: dispute.name,
      },
      
      {
        // title: <FormattedMessage id="dispute.startDate" />,
        value: formatDate(dispute.created),
      },
    ];
    
    return (
      
      <React.Fragment>
            
        <Paper className={classes.paper}>
          <Grid container justify="flex-start ">
          <Box height="0.5rem"></Box>
         
          <Box height="1rem"></Box>
            {data.map(({value }) => {
              return (
                <Grid sm={4} xs={4} >
                  <Box className={classes.contentContainer}>
                    <div>
                     
                      <Typography className={classes.listtext}>
                        {value
                          ? value == "arbitration"
                            ? "Grievance"
                            : value
                          : "--"}
                      </Typography>
                    </div>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
        <Box height="0.6rem"></Box>
      </React.Fragment>
    );
  }
  
  

