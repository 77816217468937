import { Backdrop, Divider, Fade } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ViewProfileRow from "./ViewProfileRow";
import CloseModalButton from "Admin/Common/CloseModalButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      padding: theme.spacing(3, 3, 3),
    },
  },
  editprofile: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
}));

export default function ViewProfile({
  openmodal,
  handleCloseModal,
  profileData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isMultiple, setIsMultiple] = useState(
    profileData.length ? true : false
  );

  const body = (
    <div className={classes.paper}>
      <CloseModalButton onClick={handleCloseModal} />
      <Typography className={classes.editprofile}>View Profile</Typography>
      <Box height="2.5rem"></Box>
      {isMultiple ? (
        profileData.map((profile) => {
          return (
            <>
              <ViewProfileRow profileData={profile} />
              <Box height="1rem"></Box>
              <Divider />
              <Box height="1rem"></Box>
            </>
          );
        })
      ) : (
        <>
          <ViewProfileRow profileData={profileData} />
          <Box height="1rem"></Box>
          <Divider />
          <Box height="1rem"></Box>
        </>
      )}
    </div>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
