import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { backgrounds } from "contexts/BackgroundContext";
import FormattedMessage from "features/common/TranslatedMessage";
import { useBodyBackgroundChange } from "hooks";
import MailSentVectorSVG from "images/mail-sent/rafiki.svg";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  primaryText: {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "400",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center",
    },
  },
  space: {
    marginTop: "20%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  vectorImage: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "18rem",
    },
  },
  cen: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: "2rem",
    },
  },
}));

export default function WaitingScreen() {
  const classes = useStyles();

  useBodyBackgroundChange();
  return (
    // <div>WaitingScreen</div>
    <Fragment>
      <Grid container justify="center">
        <Grid
          item
          className={classes.item}
          lg={8}
          md={10}
          sm={11}
          xs={11}
          style={{ margin: "5rem 0" }}
        >
          <Grid container justify="center">
            <Grid item md={5} sm={6} xs={12}>
              <Typography className={`${classes.primaryText} ${classes.space}`}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.tiacwaiting1" />
              </Typography>
              <Typography className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.tiacwaiting2" />
              </Typography>
              <Typography className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.tiacwaiting3" />
              </Typography>
            </Grid>
            <Grid item md={5} sm={6} xs={12} className={classes.cen}>
              <img
                className={classes.vectorImage}
                src={MailSentVectorSVG}
                alt="mail sent vector image"
              />
            </Grid>
          </Grid>
          {/* <div className={classes.mainContainer}>
            <div className={classes.content}>
              <Typography className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.tiacwaiting1" />
              </Typography>
              <Typography className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.tiacwaiting2" />
              </Typography>
              <Typography className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.tiacwaiting3" />
              </Typography>
            </div>

            <img
              className={classes.vectorImage}
              src={MailSentVectorSVG}
              alt="mail sent vector image"
            />
          </div> */}
          <Box height="1.5rem"></Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
