import { Box, Grid, Button, Paper, Hidden } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { getWindowDimension } from "utils/responsiveUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDispatch } from "react-redux";
import Overview from "./SubAdmin/Overview";
import AnalyticsContainer from "features/Analytics/NewAnalyticsContainerSubAdmin";
import Clients from "./SubAdmin/Clients";
import Tribunals from "./SubAdmin/Tribunals";
import Secretariat from "./SubAdmin/Secretariat";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    // height: "95vh",
    // height: "100%",
    minHeight: "95vh",
    borderRadius: "1rem",
    // flexDirection: (props) => (props.smallScreen ? "column" : "row"),
  },
  sidebar: {
    background: "#2C2C2C",
    width: "23rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sidebarItem: {
    //#A4A6B3;
    fontSize: "16px",
    padding: "0.5rem 0.5rem 0.5rem 2rem",
    color: "#A4A6B3",
    letterSpacing: "0.2px",
  },
  selected: {
    backgroundColor: "rgba(159, 162, 180, 0.08)",
    // opacity: 0.08,
    // color:"#FFFFFF",
    borderLeft: "2px solid #FFFFFF;",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
    height: "auto",
  },
  secretariatName: {
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
}));

export default function Admin({ profile }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  const isSmallScreen = () => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let t = sessionStorage.getItem("subadmin_sidebar_item");
  const [selected, setSelected] = useState(t ? parseInt(t) : 1);
  const [step, setStep] = useState(0);

  const MobileView = ({ step }) => {
    switch (step) {
      case 0:
        return <Sidebar />;
      case 1:
        return <Body />;
      default:
        return null;
    }
  };

  const removeSessionStorage = () => {
    sessionStorage.removeItem("company_id");
    sessionStorage.removeItem("caseid");
    sessionStorage.removeItem("subadmin_overview_showCase");
  };

  const RenderOptions = () => {
    switch (selected) {
      case 1:
        sessionStorage.setItem("subadmin_sidebar_item", 1);
        return <Overview profile={profile} isSmallScreen={isSmallScreen} />;
      case 2:
        sessionStorage.setItem("subadmin_sidebar_item", 2);
        return <Clients profile={profile} />;
      case 3:
        sessionStorage.setItem("subadmin_sidebar_item", 3);
        return <Tribunals profile={profile} />;
      case 4:
        sessionStorage.setItem("subadmin_sidebar_item", 4);
        return <Secretariat profile={profile} />;
      case 5:
        sessionStorage.setItem("subadmin_sidebar_item", 5);
        return 5;
      case 6:
        sessionStorage.setItem("subadmin_sidebar_item", 6);
        return <AnalyticsContainer />;
    }
  };

  const Sidebar = () => {
    return (
      <Box className={classes.sidebar}>
        <Box height="1rem"></Box>
        <Typography
          style={{
            padding: "15px 20px 30px 20px",
            fontSize: "18px",
            color: "rgba(238, 238, 238, 0.7)",
            fontWeight: "bold",
          }}
        >
          Dashboard
        </Typography>
        {/* <Box height="2rem"></Box> */}

        <List style={{ paddingTop: "0" }}>
          <Divider />
          {selected == 1 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
                removeSessionStorage();
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Overview
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
                removeSessionStorage();
              }}
            >
              <Typography className={classes.sidebarItem}>Overview</Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 2 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
                removeSessionStorage();
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Clients
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
                removeSessionStorage();
              }}
            >
              <Typography className={classes.sidebarItem}>Clients</Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 3 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
                removeSessionStorage();
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Tribunals
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
                removeSessionStorage();
              }}
            >
              <Typography className={classes.sidebarItem}>Tribunals</Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 4 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(4);
                setStep(1);
                removeSessionStorage();
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Secretariat
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(4);
                setStep(1);
                removeSessionStorage();
              }}
            >
              <Typography className={classes.sidebarItem}>
                Secretariat
              </Typography>
            </ListItem>
          )}
          <Divider />
          {/* {selected == 5 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(5);
                setStep(1);
                removeSessionStorage();
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Settings
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(5);
                setStep(1);
                removeSessionStorage();
              }}
            >
              <Typography className={classes.sidebarItem}>Settings</Typography>
            </ListItem>
          )}
          <Divider /> */}
          {selected == 6 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(6);
                setStep(1);
                removeSessionStorage();
                if (!isSmallScreen()) history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Compliance Report
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(6);
                setStep(1);
                removeSessionStorage();
              }}
            >
              <Typography className={classes.sidebarItem}>
                Compliance Report
              </Typography>
            </ListItem>
          )}
          <Divider />
        </List>
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box className={classes.body2}>
        <Hidden mdUp>
          <Paper
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
              padding: "15px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              size="large"
              onClick={() => {
                setStep(0);
              }}
              startIcon={<ArrowBackIosIcon />}
            >
              <FormattedMessage id="buttons.back" />
            </Button>
          </Paper>
        </Hidden>
        <Box className={classes.body}>
          <RenderOptions />
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        {isSmallScreen() ? (
          <MobileView step={step} />
        ) : (
          <>
            <Sidebar />
            <Body />
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
