import {
  Backdrop,
  CircularProgress,
  Fade,
  Grid,
  Snackbar,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postActivitySebi } from "slices/activitySlice";
import { updateCase } from "slices/casesSlice";
import { updateDispute } from "slices/disputeSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function joinHearingRooms(id) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: "/joinchatgroup/" + id + "/",
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
    }
  };
}

export function sendCaseManagerAppointedEmailSebi(dispute_id, profile_id) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/sebi/send_case_manager_email/${dispute_id}/${profile_id}/`,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
    }
  };
}

export function sendConciliatorAppointedEmailSebi(dispute_id, profile_id) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/sebi/send_conciliator_email/${dispute_id}/${profile_id}/`,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
    }
  };
}

export function sendArbitratorAppointedEmailSebi(dispute_id) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/sebi/send_arbitrator_email/${dispute_id}/`,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
    }
  };
}

export function sendWitnessAppointedEmailSebi(dispute_id, profile_id) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/sebi/send_witness_email/${dispute_id}/${profile_id}`,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
    }
  };
}


const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "80%",
      padding: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      height: "80%",
      padding: "0.5rem",
    },
  },
  assign: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
  caseId: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  profileType: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  box: {
    padding: "0.7rem",
  },
  box2: {
    cursor: "pointer",
    padding: "0.7rem",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
  profileheading: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#25213B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  profileheading: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#25213B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
}));

export default function Assign({
  openmodal,
  handleCloseModal,
  caseId,
  profileType,
  casefile,
  caseType_RV,
  allAssignedPrifiles,
  dispute,
  prevLaw,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [profiles, setProfiles] = useState([]);

  const [previousProfessionalsId, setpreviousProfessionalsId] = useState([]);
  const [previousLawyers, setPreviousLawyers] = useState([]);
  const [previousCaseManagers, setPreviousCaseManagers] = useState([]);
  const [countAssigned, setCountAssigned] = useState(0);

  React.useEffect(() => {
    if (
      profileType == "arbitrator_rv" ||
      profileType == "lawyer" ||
      profileType == "witness" ||
      profileType == "case_manager" ||
      profileType == "mediator"
    )
      dispatch(disputeDetails());
    if (profileType == "lawyer_rv") {
      dispatch(CaseDetails());
    }
  }, []);

  function CaseDetails() {
    return async () => {
      try {
        const response = await instance.get(
          `/api/casefile/?host=rv&dispute__id=${caseId}`
        );
        response.data.results.map((item) => {
          if (item.id == casefile.id) {
            setPreviousLawyers(item.lawyer_rv);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  function disputeDetails() {
    return async () => {
      try {
        const response = await instance.get(
          `/api/tiacrvdisputedetailsbyid/${caseId}/`
        );
        let d = response.data;
        let res = [];
        let professionals = d.dispute_data.professionals;
        professionals.forEach((item) => {
          if (item.profile.profile_type != "lawyer_rv")
            res.push(item.profile.id);
        });
        let resC = [];
        d.dispute_data.case_manager_rv.forEach((item) => {
          resC.push(item.profile.id);
        });
        setPreviousCaseManagers(resC);
        setpreviousProfessionalsId(res);
        if (
          profileType == "arbitrator_rv" ||
          profileType == "lawyer" ||
          profileType == "witness" ||
          profileType == "mediator"
        ) {
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  const [openSnackBar1, setOpenSnackBar1] = useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };

  React.useEffect(() => {
    dispatch(getUsers());
  }, []);

  function getUsers() {
    return async () => {
      try {
        const response = await instance.get(
          `/api/userappointmentprofilelist/${null}/${null}/${profileType}`
        );
        setProfiles(response.data.profiles);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function getProfileTypeTitle(profileType) {
    if (profileType === "arbitrator_rv") {
      return "Arbitrator";
    } else if (profileType === "lawyer_rv") {
      return "Counsel";
    } else if (profileType === "mediator") {
      return "Conciliator";
    } else if (profileType === "witness") {
      return "Witness";
    } else {
      return "Case Manager";
    }
  }

  const assignUser = (profile) => {
    setCountAssigned(countAssigned + 1);
    if (
      profileType == "arbitrator" ||
      profileType == "arbitrator_rv" ||
      profileType == "lawyer" ||
      profileType == "witness" ||
      profileType == "witness_rv" ||
      profileType == "mediator"
    ) {
      let res = previousProfessionalsId;
      res.push(profile.id);
      dispatch(
        updateDispute({
          id: caseId,
          professionals: res,
        })
      ).then(() => {
        let activityName = `has been appointed as a ${getProfileTypeTitle(
          profileType
        )}`;
        if (profileType === "arbitrator_rv") {
          activityName = `has been appointed as an ${getProfileTypeTitle(
            profileType
          )}`;
        }
        dispatch(
          postActivitySebi(activityName, profile?.user?.id, caseId, "general")
        );
        setOpenSnackBar1(true);
        dispatch(joinHearingRooms(caseId));
        if (profileType == "mediator") {
          dispatch(sendConciliatorAppointedEmailSebi(caseId, profile.id));
        }
        if (profileType === 'witness'){
          dispatch(sendWitnessAppointedEmailSebi(caseId, profile.id))
        }
      });
      // dispatch(
      //   updateDispute({
      //     id: caseId,
      //     rv_arbitrator_step_complete: true,
      //   })
      // );
    } else if (profileType == "lawyer_rv") {
      if (caseType_RV == "claimant") {
        let id = casefile.id;
        let lawyersIds = previousLawyers;
        lawyersIds.push(profile.id);
        setPreviousLawyers(lawyersIds);
        dispatch(updateCase({ id: id, lawyer_rv: lawyersIds })).then(() => {
          dispatch(
            postActivitySebi(
              `has been appointed as a ${getProfileTypeTitle(profileType)}`,
              profile?.user?.id,
              caseId,
              "general"
            )
          );
          setOpenSnackBar1(true);
          dispatch(joinHearingRooms(caseId));
        });
        // dispatch(
        //   updateDispute({
        //     id: caseId,
        //     rv_claimant_lawyer_step_complete: true,
        //   })
        // );
      } else {
        let id = casefile.id;
        let lawyersIds = previousLawyers;
        lawyersIds.push(profile.id);
        setPreviousLawyers(lawyersIds);
        dispatch(updateCase({ id: id, lawyer_rv: lawyersIds })).then(() => {
          dispatch(
            postActivitySebi(
              `has been appointed as a ${getProfileTypeTitle(profileType)}`,
              profile?.user?.id,
              caseId,
              "general"
            )
          );
          setOpenSnackBar1(true);
          dispatch(joinHearingRooms(caseId));
        });
      }
    } else if (profileType == "case_manager") {
      let ids = previousCaseManagers;
      ids.push(profile.id);
      setPreviousCaseManagers(ids);
      dispatch(
        updateDispute({
          id: caseId,
          case_manager_rv: ids,
        })
      ).then(() => {
        dispatch(
          postActivitySebi(
            `has been appointed as a ${getProfileTypeTitle(profileType)}`,
            profile?.user?.id,
            caseId,
            "general"
          )
        );
        setOpenSnackBar1(true);
        dispatch(joinHearingRooms(caseId));
        dispatch(sendCaseManagerAppointedEmailSebi(caseId, profile.id));
      });
    }
  };

  const body = (
    <div className={classes.paper}>
      <Typography className={classes.assign}>Assign</Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.caseId}>
        Case Id : {dispute.approved ? dispute.tiac_custom_dispute_id : "---"}
      </Typography>
      <Box height="0.5rem"></Box>
      <Typography className={classes.profileType}>
        Profile Type :{" "}
        {profileType == "arbitrator_rv"
          ? "Arbitrator"
          : profileType == "lawyer_rv"
          ? "Counsel"
          : profileType == "mediator"
          ? "Conciliator"
          : "Case Manager"}
      </Typography>
      <Box height="1.5rem"></Box>
      <Grid container justify="center">
        <Grid item sm={10} xs={12}>
          <Box className={classes.box}>
            <Grid container justify="space-between">
              <Grid item sm xs style={{ textAlign: "left" }}>
                <Typography className={classes.profileheading}>Name</Typography>
              </Grid>
              <Grid item sm xs style={{ textAlign: "right" }}>
                <Typography className={classes.profileheading}>
                  Email
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {profiles.map((profile) => {
            if (
              (profileType == "lawyer_rv" &&
                !prevLaw.some((item) => item == profile.id)) ||
              !allAssignedPrifiles.some((item) => item.profile.id == profile.id)
            )
              return (
                <Box
                  key={profile.id}
                  className={classes.box2}
                  onClick={() => {
                    assignUser(profile);
                  }}
                >
                  <Grid container justify="space-between">
                    <Grid item sm xs style={{ textAlign: "left" }}>
                      <Typography className={classes.profile}>
                        {profile.user.first_name} {profile.user.last_name}
                      </Typography>
                    </Grid>
                    <Grid item sm xs style={{ textAlign: "right" }}>
                      <Typography className={classes.profile}>
                        {profile.user.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
          })}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Assigned"
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Assigned
        </Alert>
      </Snackbar>
    </div>
  );

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Modal
      open={openmodal}
      onClose={() => {
        if (profileType === "arbitrator_rv" && countAssigned > 0) {
          dispatch(sendArbitratorAppointedEmailSebi(caseId));
        }
        handleCloseModal();
      }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
