import { Box } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import DisplayContainer from "features/profile/DisplayContainer";
import React from "react";

export default function ProfileInfo({
  classes,
  profile: {
    user: { first_name, last_name, email },
    phone_number,
  },
}) {
  return (
    <Box>
      <DisplayContainer title="Account">
        <p className={classes.th}>
          <FormattedMessage id="user.firstName" />
        </p>
        <Box height="0.3rem" style={{width:"auto !important"}}></Box>

        <p className={classes.td}>{first_name}</p>
        <Box height="1rem"></Box>
        <p className={classes.th}>
          <FormattedMessage id="user.lastName" />
        </p>
        <Box height="0.3rem"></Box>

        <p className={classes.td}>{last_name}</p>
        <Box height="1rem"></Box>
        <p className={classes.th}>
          <FormattedMessage id="user.phoneNumber" />
        </p>
        <Box height="0.3rem"></Box>

        <p className={classes.td}>{phone_number}</p>
        <Box height="1rem"></Box>
        <p className={classes.th}>
          <FormattedMessage id="user.email" />
        </p>
        <Box height="0.3rem"></Box>

        <p className={classes.td}>{email}</p>
      </DisplayContainer>
    </Box>
  );
}
