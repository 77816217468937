import {
  Box,
  Button,
  Grid,
  makeStyles,
  Menu,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import FolderImage from "images/folder.svg";
import UploadFolder from "./UploadFolder";
import UploadFile from "./UploadFile";
import { formatDate } from "utils";
import { UploadFolders } from "./FileApi";
import FolderView from "./FolderView";
import FileView from "./FileView";
import { postActivity } from "slices/activitySlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
  },
  heading: {
    color: "#4F4F4F",
    fontSize: "28px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
  },
  uploadbtn: {
    background: "#E0E0E0",
    padding: "1rem 2rem",
    cursor: "pointer",
  },
  uploadbtntext: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    textTransform: "none",
  },
  menuwidth: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "130px",
    },
  },
  files: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  filesHover: {
    padding: "0.6rem 1rem",
    cursor: "pointer",
    background: "white",
    "&:hover": {
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  filesandfolder: {
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    padding: "0.6rem 1.5rem",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
}));

export default function Files({
  disputeId,
  folder_id,
  allFiles,
  allFolders,
  reload,
  allProfiles,
  profile,
  parent_folder,
  user_id,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  useEffect(() => {
    if (
      parent_folder &&
      parent_folder.uploaded_by.id != profile.user.id &&
      parent_folder.edit_permission.indexOf(profile.user.id) == -1
    ) {
      setButtonsEnabled(false);
    }
    if (
      profile.profile_type == "sub_admin_tiac_rv" ||
      profile.profile_type == "case_manager"
    ) {
      setButtonsEnabled(true);
    }
  }, [parent_folder]);
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [folderName, setFolderName] = useState("New Folder");

  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };
  const inputRef = useRef(null);

  const handleOnBlur = (e) => {
    if (folderName == "New Folder") {
      setShowNewFolderInput(false);
      setFolderName("New Folder");
    } else {
      UploadFolders(
        disputeId,
        folderName,
        folder_id,
        parent_folder ? parent_folder.view_permission : [],
        parent_folder ? parent_folder.edit_permission : []
      ).then((result) => {
        dispatch(
          postActivity(
            "uploaded a folder",
            0,
            false,
            folderName,
            disputeId,
            parent_folder ? parent_folder.view_permission : [],
            null,
            result?.data?.id
          )
        );
        setShowNewFolderInput(false);
        setFolderName("New Folder");
        reload();
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (buttonsEnabled) setAnchorEl(event.currentTarget);
    else alert("You don't have edit permissions.");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElNew, setAnchorElNew] = useState(null);
  const handleClickNew = (event) => {
    if (buttonsEnabled) setAnchorElNew(event.currentTarget);
    else alert("You don't have edit permissions.");
  };
  const handleCloseNew = () => {
    setAnchorElNew(null);
  };
  const createNewFolder = () => {
    handleCloseNew();
    setShowNewFolderInput(true);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };
  const [openmodalUploadFolder, setOpenmodalUploadFolder] = useState(false);
  const handleOpenUploadFolderModal = () => {
    setOpenmodalUploadFolder(true);
  };
  const handleCloseUploadFolderModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFolder(false);
    setAnchorEl(null);
  };
  const handleCloseUploadFolderModal = () => {
    setOpenmodalUploadFolder(false);
    setAnchorEl(null);
  };
  const [openmodalUploadFile, setOpenmodalUploadFile] = useState(false);
  const handleOpenUploadFileModal = () => {
    setOpenmodalUploadFile(true);
  };
  const handleCloseUploadFileModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFile(false);
    setAnchorEl(null);
  };
  const handleCloseUploadFileModal = () => {
    setOpenmodalUploadFile(false);
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.heading}>Files</Typography>
      <Box height="2rem" />
      {/* <Grid container spacing={2}>
        <Grid item>
          <Button
            aria-controls="upload-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={`${classes.uploadbtn} ${classes.menuwidth}`}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <Typography className={classes.uploadbtntext}>Upload</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            aria-controls="new-folder"
            aria-haspopup="true"
            onClick={handleClickNew}
            className={`${classes.uploadbtn} ${classes.menuwidth}`}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <Typography className={classes.uploadbtntext}>New</Typography>
          </Button>
        </Grid>
      </Grid> */}
      <Popover
        id="upload-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleOpenUploadFileModal}
            >
              Files
            </Typography>
            <Typography
              className={classes.filesandfolder}
              onClick={handleOpenUploadFolderModal}
            >
              Folder
            </Typography>
          </Box>
        </Box>
      </Popover>
      <Menu
        id="new-folder"
        anchorEl={anchorElNew}
        keepMounted
        open={Boolean(anchorElNew)}
        onClose={handleCloseNew}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={createNewFolder}
            >
              Folder
            </Typography>
          </Box>
        </Box>
      </Menu>
      <Box height="3rem" />
      <Grid
        container
        style={{ background: "#F3F4F6" }}
        className={classes.files}
        spacing={1}
      >
        <Grid item lg={7} md={7} sm={7} xs={5}>
          <Typography
            style={{
              color: "#4F4F4F",
              fontWeigt: "500",
              fontSize: "14px",
              fontFamily: `'Inter',sans-serif`,
            }}
          >
            Name
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            style={{
              color: "#333333",
              fontWeigt: "500",
              fontFamily: `'Inter',sans-serif`,
              fontSize: "14px",
              wordBreak: "break-all",
            }}
          >
            Uploaded by
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            style={{
              color: "#333333",
              fontWeigt: "500",
              fontSize: "14px",
              fontFamily: `'Inter',sans-serif`,
              wordBreak: "break-all",
            }}
          >
            Modified on
          </Typography>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
      </Grid>
      {/* folders and files */}
      {showNewFolderInput && (
        <Grid container className={classes.filesHover} spacing={1}>
          <Grid item lg={7} md={7} sm={7} xs={5}>
            <Typography
              style={{
                color: "#4F4F4F",
                fontWeigt: "500",
                fontFamily: `'Inter',sans-serif`,
                fontSize: "14px",
                wordBreak: "break-all",
              }}
            >
              <span>
                <img src={FolderImage} alt="folder" />
              </span>
              <span style={{ marginLeft: "1rem" }}>
                <input
                  ref={inputRef}
                  value={folderName}
                  style={{ width: "70%" }}
                  onChange={handleFolderNameChange}
                  onBlur={handleOnBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOnBlur();
                    }
                  }}
                />
              </span>
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={3}>
            <Typography
              style={{
                color: "#4F4F4F",
                fontWeigt: "500",
                fontFamily: `'Inter',sans-serif`,
                fontSize: "14px",
                wordBreak: "break-all",
              }}
            >
              {profile.user.first_name} {profile.user.last_name}
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={3}>
            <Typography
              style={{
                color: "#4F4F4F",
                fontWeigt: "500",
                fontFamily: `'Inter',sans-serif`,
                fontSize: "14px",
                wordBreak: "break-all",
              }}
            >
              {formatDate(new Date())}
            </Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        </Grid>
      )}
      {allFolders.map((folder) => {
        return (
          <FolderView
            folder={folder}
            key={folder.id}
            disputeId={disputeId}
            classes={classes}
            buttonsEnabled={buttonsEnabled}
            reload={reload}
            profile={profile}
            allProfiles={allProfiles}
            parent_folder={parent_folder}
            user_id={user_id}
          />
        );
      })}
      {allFiles.map((file) => {
        return (
          <FileView
            file={file}
            key={file.id}
            disputeId={disputeId}
            classes={classes}
            buttonsEnabled={buttonsEnabled}
            reload={reload}
            profile={profile}
            allProfiles={allProfiles}
            parent_folder={parent_folder}
          />
        );
      })}
      <Box height="4rem" />

      {/* folder and files atop */}
      {openmodalUploadFolder && (
        <UploadFolder
          openmodal={openmodalUploadFolder}
          handleCloseModal={handleCloseUploadFolderModal}
          disputeId={disputeId}
          folder_id={folder_id}
          allProfiles={allProfiles}
          handleCloseModalRefresh={handleCloseUploadFolderModalAndRefresh}
          parent_folder={parent_folder}
        />
      )}
      {openmodalUploadFile && (
        <UploadFile
          openmodal={openmodalUploadFile}
          handleCloseModal={handleCloseUploadFileModal}
          disputeId={disputeId}
          folder_id={folder_id}
          allProfiles={allProfiles}
          handleCloseModalRefresh={handleCloseUploadFileModalAndRefresh}
          parent_folder={parent_folder}
        />
      )}
    </Box>
  );
}
