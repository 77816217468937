import { Box, makeStyles } from "@material-ui/core";
import { ARBITRATOR_RV } from "app/constants";
import React from "react";
import HallTemplate from "./HallTemplate";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: theme.spacing(8),
    },
  };
});

const Halls = ({ profile }) => {
  const classes = useStyles();


  const hallsData = {
    main: {
      text: "Join the Main Hall with all participants, judges and partners",
      btnText: "Join Main Hall",
      link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_YWUzMzk3NjctMTNkYi00OWY2LTg0OWEtYWNmNDUzNmU1ZDZi%40thread.v2/0?context=%7b%22Tid%22%3a%22d7d837aa-1c39-457a-b3e9-7516cf16765f%22%2c%22Oid%22%3a%22f2b0b535-6339-4616-bc4b-c84d61839b56%22%7d",
    },
    judges: {
      text: "Join the Judges Breakout Hall with all other Judges of the Moot competition",
      btnText: "Join Breakout Hall",
      link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_Nzg4NDA3NDAtN2QyMi00MjQ0LTk4NjQtOGZlZDkyZDY4Nzg2%40thread.v2/0?context=%7b%22Tid%22%3a%22d7d837aa-1c39-457a-b3e9-7516cf16765f%22%2c%22Oid%22%3a%22f2b0b535-6339-4616-bc4b-c84d61839b56%22%7d",
    },
    student: {
      text: "Join the Student Breakout Hall with all other participants of the Moot competition",
      btnText: "Join Breakout Hall",
      link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_N2ZhMDhhZjQtMTlkOC00NjY0LWIxOGEtYzI1NmZhNmU4NzAw%40thread.v2/0?context=%7b%22Tid%22%3a%22d7d837aa-1c39-457a-b3e9-7516cf16765f%22%2c%22Oid%22%3a%22f2b0b535-6339-4616-bc4b-c84d61839b56%22%7d",
    },
  };

  return (
    <Box className={classes.root}>
      <HallTemplate
        text={hallsData.main.text}
        btnText={hallsData.main.btnText}
        link={hallsData.main.link}
      />
      {profile.profile_type === ARBITRATOR_RV ? (
        <HallTemplate
          text={hallsData.judges.text}
          btnText={hallsData.judges.btnText}
          link={hallsData.judges.link}
        />
      ) : (
        <HallTemplate
          text={hallsData.student.text}
          btnText={hallsData.student.btnText}
          link={hallsData.student.link}
        />
      )}
    </Box>
  );
};

export default Halls;
