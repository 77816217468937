import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Rafiki from "images/rafiki_rv_contact.svg";
import Circle from "images/circlecontact.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#3f51b5",
  },
  heading1: {
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#f2f2f2",
  },
  heading2: {
    fontWeight: "bolder",
    fontSize: "24px",
    lineHeight: "30px",
    color: "#f2f2f2",
  },
  text: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#f2f2f2",
  },
  text1: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#f2f2f2",
    fontWeight:"bold"
  },
  write: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "30px",
    color: "#f2f2f2",
  },
  grid: {
    backgroundImage: `url(${Circle})`,
    "background-repeat": "no-repeat",
    "background-position": "right",
    "background-size": "contain",
    textAlign: "right",
    padding: "102px",
    [theme.breakpoints.down("sm")]: {
      padding: "68px",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
}));

export default function Landing() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <Box height="4rem" />
      <Grid container justify="space-between">
        <Grid
          item
          md={7}
          sm={12}
          style={{ paddingLeft: "6rem", paddingRight: "1rem" }}
        >
          <Typography className={classes.heading1}>Contact Us</Typography>
          <Box height="3rem" />
          <Typography className={classes.heading2}>ADDRESS</Typography>
          <Box height="1rem" />
          <Grid container spacing={1}>
            <Grid item>
              <Divider
                orientation="vertical"
                style={{ background: "#70c8ce", width: "2px" }}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.text1}>
                Main office:
              </Typography>
              <Typography className={classes.text}>
                {/* No. 1241, sector 18C */}
                2402, Tower 14, Lotus Panache
              </Typography>
              <Typography className={classes.text}>
                Noida Sector 110, Delhi NCR
              </Typography>
              <Typography className={classes.text}>
                {/* Chandigarh (U.T), 160018 */}
                India
              </Typography>
              {/* <Typography className={classes.text}>+91 96503 12556</Typography> */}
              <Typography className={classes.text}>+91 9958867718</Typography>
            </Grid>
            <Grid item>
              <Divider
                orientation="vertical"
                style={{ background: "#70c8ce", width: "2px" }}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                {/* Up Tower */}
                Webnyay Private Ltd (India)
              </Typography>
              <Typography className={classes.text}>
                {/* 77 Sheikh Zayed Rd - Trade CentreDIFC */}
                Boulevard Mansions
              </Typography>
              <Typography className={classes.text}>
                {/* Dubai */}
                46 Borough Road
              </Typography>
              <Typography className={classes.text}>
                {/* United Arab Emirates */}
                London
              </Typography>
              <Typography className={classes.text}>
                SE1 0AJ
              </Typography>
            </Grid>
          </Grid>
          <Box height="3rem" />
          <Typography className={classes.write}>
            Write to us at <u style={{ color: "#f66255" }}>admin@webnyay.in</u>
          </Typography>
          <div height="3rem" style={{ marginBottom: "0", height: "3rem" }} />
          <Typography className={classes.write}>
            Want to know more about what we do?
          </Typography>
          <div
            height="0.5rem"
            style={{ marginBottom: "0", height: "0.5rem" }}
          />
          <Typography className={classes.write}>
            <u style={{ color: "#f66255" }}>Request a demo</u>
          </Typography>
        </Grid>
        <Grid item md={5} sm={12} className={classes.grid}>
          <img src={Rafiki} alt="image" className={classes.img} />
        </Grid>
      </Grid>
      {/* <div height="2rem" style={{ marginBottom: "0", height: "2rem" }} /> */}
    </Box>
  );
}
