import { Box, Grid, IconButton, Menu, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import FileImage from "images/file.svg";
import DotsImage from "images/threeDots.svg";
import { formatDate } from "utils";
import { deleteFile, DownloadFile, UpdateFile } from "./FileApi";
import EditPermissionsFile from "./EditPermissionsFile";
import { postActivity } from "slices/activitySlice";
import { useDispatch } from "react-redux";

export default function FileView({
  file,
  disputeId,
  classes,
  reload,
  profile,
  allProfiles,
  parent_folder,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  useEffect(() => {
    if (
      file &&
      file.uploaded_by.id != profile.user.id &&
      file.permission.indexOf(profile.user.id) == -1
    ) {
      setButtonsEnabled(false);
    }
    if (
      profile.profile_type == "sub_admin_tiac_rv" ||
      profile.profile_type == "case_manager"
    ) {
      setButtonsEnabled(true);
    }
  }, []);

  const [openmodal, setOpenmodal] = useState(false);
  const handleOpenUploadFolderModal = () => {
    if (profile.user.id == file.uploaded_by.id) setOpenmodal(true);
    else alert("only owner can edit permissions");
  };
  const handleCloseModalAndRefresh = () => {
    reload();
    setOpenmodal(false);
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenmodal(false);
    setAnchorEl(null);
  };

  let arr = file.name.split(".");
  let extension = "." + arr[arr.length - 1];
  let localName = file.name;
  localName = localName.replace(extension, "");
  const [nameWithoutExtension, setNameWithoutExtension] = useState(localName);

  const [rename, setRename] = useState(false);
  const handleFileNameChange = (e) => {
    setNameWithoutExtension(e.target.value);
  };
  const inputRef = useRef(null);
  const handleOnBlur = (e) => {
    setRename(false);
    if (nameWithoutExtension != localName) {
      UpdateFile(file.id, {
        name:
          arr.length > 1
            ? nameWithoutExtension + extension
            : nameWithoutExtension,
      }).then(() => {
        reload();
      });
    }
  };

  const handleRename = () => {
    if (buttonsEnabled) {
      handleClose();
      setRename(true);
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 100);
    } else {
      alert("You don't have edit permissions.");
    }
  };

  const handleDelete = () => {
    if (buttonsEnabled) {
      handleClose();
      deleteFile(file.id).then(() => {
        dispatch(
          postActivity(
            "deleted a file",
            file.size,
            true,
            file.name,
            disputeId,
            file.view_permission
          )
        );
        reload();
      });
    } else {
      alert("You don't have delete permissions.");
    }
  };

  return (
    <>
      <Grid
        container
        className={classes.filesHover}
        spacing={1}
        onDoubleClick={() => {
          DownloadFile(file.id)
            .then((res) => {
              window.location.href = res.data.url;
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      >
        <Grid item lg={7} md={7} sm={7} xs={5}>
          <Typography
            style={{
              color: "#4F4F4F",
              fontWeigt: "500",
              fontFamily: `'Inter',sans-serif`,
              fontSize: "14px",
              wordBreak: "break-all",
            }}
          >
            <span>
              <img src={FileImage} alt="file" />
            </span>
            <span style={{ marginLeft: "1rem" }}>
              {!rename ? (
                file.name
              ) : (
                <input
                  ref={inputRef}
                  value={nameWithoutExtension}
                  style={{ width: "70%" }}
                  onChange={handleFileNameChange}
                  onBlur={handleOnBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOnBlur();
                    }
                  }}
                />
              )}
            </span>
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            style={{
              color: "#4F4F4F",
              fontWeigt: "500",
              fontSize: "14px",
              fontFamily: `'Inter',sans-serif`,
              wordBreak: "break-all",
            }}
          >
            {file.uploaded_by.first_name} {file.uploaded_by.last_name}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            style={{
              color: "#4F4F4F",
              fontWeigt: "500",
              fontSize: "14px",
              fontFamily: `'Inter',sans-serif`,
              wordBreak: "break-all",
            }}
          >
            {formatDate(file.modified)}
          </Typography>
        </Grid>
        {/* <Grid item lg={1} md={1} sm={1} xs={1} style={{ textAlign: "right" }}>
          <IconButton
            onClick={handleClick}
            aria-controls={`three-dots-menu${file.id}`}
            aria-haspopup="true"
            style={{ padding: 0 }}
          >
            <img src={DotsImage} alt="options" />
          </IconButton>
        </Grid> */}
      </Grid>
      {/* <Menu
        aria-controls={`three-dots-menu${file.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleRename}
            >
              Rename
            </Typography>
            <Typography
              className={classes.filesandfolder}
              onClick={handleDelete}
            >
              Delete
            </Typography>
          </Box>
        </Box>
      </Menu> */}
      <EditPermissionsFile
        openmodal={openmodal}
        handleCloseModal={handleCloseModal}
        handleCloseModalRefresh={handleCloseModalAndRefresh}
        file={file}
        allProfiles={allProfiles}
        parent_folder={parent_folder}
        disputeId={disputeId}
      />
    </>
  );
}
