import React from "react";
import { Box, Button, Grid } from '@material-ui/core'
import SummaryGraph from "DataAnalytics/SummaryGraph";
import CommonStatContainer from "./CommonStatContainer";
import PieChart from "./PieChart";
import Barchart from "./BarChart";
import BarChart from "./BarChart";




export const series = [{
  name: 'Sent',
  type: 'line',
  data: [150, 180, 300, 320, 430, 480, 550, 600, 620, 700, 850, 900]
}, {
  name: 'opened',
  type: 'line',
  data: [50, 80, 100, 120, 130, 180, 250, 300, 320, 400, 550, 600]
}
]


const Analytics = () => {
  return <Grid spacing={4} container >
    <Grid item md={12} sm={12} xs={12}>


      <SummaryGraph series={series} />
    </Grid>
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} xs={6}>
        <CommonStatContainer heading="Number of Notices">
          <PieChart d={150} series={[150, 107]} values={["Sent", "Open"]} />

        </CommonStatContainer>


      </Grid>
      <Grid item md={6} sm={6} xs={6}>

        <CommonStatContainer heading="Notice Types">
          <PieChart d={150} series={[56, 13, 38, 43]} values={["1Day", "30Days", "60Days", "90Days"]} />

        </CommonStatContainer>
      </Grid>

    </Grid>
    <Grid container >
      <Grid item md={5} sm={12} xs={12} >
        <CommonStatContainer heading="Addresses">
          <PieChart d={107} series={[80, 34]} values={["Open", "Resolved"]} />

        </CommonStatContainer>


      </Grid>
      <Grid item md={7} sm={12} xs={12}>

        <CommonStatContainer heading="Priority of Notices">
          <BarChart />

        </CommonStatContainer>
      </Grid>

    </Grid>

    <Box width="100%" display="flex" p="1rem" justifyContent="center">
    <Button variant="contained"
      color="primary" >
      Download PDF
    </Button>

  </Box>

  </Grid >
};

export default Analytics;
