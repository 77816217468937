import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { backgrounds } from "contexts/BackgroundContext";
import { useBodyBackgroundChange } from "hooks";
import React from "react";

import OverviewAnalyticsContainer from "./OverviewAnalyticsContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.6rem",
    },
  },
  blackheading: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "600",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  grey: {
    color: "#BDBDBD",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "600",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    "&:hover": {
      color: "#848484",
    },
  },
  sortbtn: {
    background: "#E0E0E0",
    border: "1px solid #828282",
    borderRadius: "4px",
    textTransform: "none",
  },
  menutext: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  totalnoticebox: {
    border: "1px solid #828282",
    borderRadius: "8px",
    background: "#F2F2F2",
    marginTop: "1rem",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem",
    },
  },
  noticeboxblacktext: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  noticeboxbluetext: {
    color: "#1D4ED8",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
    fontSize: "36px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  trackbox: {
    border: "1px solid #ebebeb",
    borderRadius: "8px",
    marginTop: "1rem",
    // overflow: "scroll",
  },
  trackheadergrid: {
    padding: "1rem",
    background: "#F3F4F6",
    // minWidth: 880,
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem",
    },
  },
  trackrowgrid: {
    padding: "0.4rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.3rem 0.5rem",
    },
    "&:hover": {
      background: "#F3F4F6",
      cursor: "pointer",
    },
  },
  selectedGrid: {
    background: "#F3F4F6",
  },
  trackExpandedgrid: {
    padding: "3rem 1rem 2rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0.5rem 1rem 0.5rem",
    },
  },
  trackingboxbluetext: {
    color: "#1D4ED8",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "600",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  trackingboxblueUnderlinedtext: {
    color: "#1D4ED8",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
    fontSize: "14px",
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  greenText: {
    color: "#27AE60",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  orangeText: {
    color: "#F2994A",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  viewgriditem: {
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
  viewbtn: {
    border: "1px solid #1D4ED8",
    textTransform: "none",
    padding: "8px 15px",
    width: "50%",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  viewbtntext: {
    color: "#1D4ED8",
    fontFamily: `'Poppins',sans-serif`,
    fontWeight: "500",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  expand_created_by_text: {
    color: "#4B5563",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  expand_name_text: {
    color: "#333333",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  expand_green_text: {
    color: "#047857",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  expand_orange_text: {
    color: "#F2994A",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  expand_sent_text: {
    color: "#333333",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}));

const CredHomePage = () => {
  useBodyBackgroundChange(backgrounds.WHITE);
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <OverviewAnalyticsContainer classes={classes} />
    </Box>
  );
};

export default CredHomePage;
