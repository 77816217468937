import instance from "api/globalaxios";
import React, { useState } from "react";
import ComplianceReportYearWise from "./ComplianceReportYearWise";

async function getComplianceReports(company_name, queryYear) {
  return await instance({
    method: "get",
    url: `/publishedcomplaincereportsanonymous/${company_name}/?year=${queryYear}`,
  });
}

async function downloadReport(id) {
  return await instance({
    method: "get",
    url: "/compliancereporturlanonymous/" + id + "/",
  }).then(
    (response) => {
      window.open(response.data.url, "_blank");
      return response.data;
    },
    (error) => {
      console.log(error);
    }
  );
}

export default function ComplianceReportContainerAnonymous() {
  const [yearlist, setYearlist] = useState([]);

  React.useEffect(() => {
    let startyear = 2022;
    let date = new Date();
    let latestYear = date.getFullYear();
    let list_of_years = [];
    for (let index = startyear; index <= latestYear; index++) {
      list_of_years.push(index);
    }
    setYearlist(list_of_years);
  }, []);

  return (
    <ComplianceReportYearWise
      downloadReport={downloadReport}
      yearlist={yearlist}
      getComplianceReports={getComplianceReports}
    />
  );
}
