import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FolderIcon from "@material-ui/icons/Folder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { MEDIATION, RESPONDENT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DocGenerator } from "utils";
import {
  CloseDisputeAtLevelOne,
  CloseLevelOneGrievance,
  CloseLeveltwoGrievance,
  downloadLegalDoc,
  MoveDisputeToLevelTwo,
  updateDispute,
} from "slices/disputeSlice";
import { useState } from "react";
import {
  isCDR,
  isDigipub,
  isHotStar,
  isNbf,
  isWebnyay,
  isDev,
  isNSE,
  isVikalp,
  isStar,
  isMuthoot,
} from "Routes/Routes";
import instance from "api/globalaxios";
import Popup from "reactjs-popup";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";

const useStyles = makeStyles((theme) => ({
  topText: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
  disputeName: {
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
      marginBottom: "0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      marginBottom: "0",
    },
  },
  box1: {
    [theme.breakpoints.down("sm")]: {
      height: "2rem",
    },
  },
}));
export default function DisputeDisplay({ activeDispute: dispute, activeCase }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [proceed, setProceed] = useState(false);
  const [activeDispute, setActiveDispute] = useState(dispute);
  const docs = activeDispute.all_docs;
  let doc;
  docs.map((item) => {
    if (item.is_final) {
      doc = item.id;
    }
  });

  const profile = useSelector((state) => state.profile, shallowEqual);

  console.log("profile -- - ", profile);

  const [isClosedLevel1, setClosedLevel1] = useState(
    activeDispute.level_1_compete
  );

  async function closeGrievance(disputeId) {
    // console.log("disputeId: ", disputeId);
    if (isDigipub || (profile.profile.user.id == 419 && isDev)) {
      console.log("closing this grievance");
      await dispatch(CloseLeveltwoGrievance(disputeId));
      await dispatch(CloseLevelOneGrievance(disputeId));
      setClosedLevel1(true);
    } else {
      await dispatch(CloseLeveltwoGrievance(disputeId));
      await dispatch(CloseLevelOneGrievance(disputeId));
      setClosedLevel1(true);
    }
  }

  // async function closeGrievanceDigipub(disputeId) {
  //   dispatch(updateDispute({ id: disputeId, status: "closed" }));
  //   setClosedLevel1(true);
  // }

  let dispute_text = "dispute id:";
  if (isHotStar || isStar || isMuthoot) {
    dispute_text = "Grievance id:";
  } else if (isNSE) {
    dispute_text = "Case id:";
  }

  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openpopup = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const CloseDisputeForClaimant = () => {
    dispatch(
      updateDispute({
        id: activeDispute.id,
        claimant_status: "closed",
      })
    );
    setOpen(false);
  };

  const CloseDisputeLevel1 = () => {
    dispatch(CloseDisputeAtLevelOne(activeDispute.id)).then((res) => {
      setActiveDispute(res);
    });
    setOpen(false);
  };

  const MoveToLevel2 = () => {
    dispatch(MoveDisputeToLevelTwo(activeDispute.id)).then((res) => {
      console.log("res", res);
      setActiveDispute(res);
    });
    setOpen(false);
  };

  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  console.log("lol", location.pathname.split("/"));
  console.log(user);
  const [showGuide, setShowGuide] = useState(
    location.pathname.split("/")[1] == "disputes" &&
      (user.id == 473 || user.id == 214) &&
      !isDev
  );

  const exit = (t) => {
    setShowGuide(false);
  };

  const steps = [
    {
      element: ".viewdetailsstep1",
      intro: "View details of your grievance.",
    },
    {
      element: ".viewdetailsstep2",
      intro: "Drop a message to the company for quicker resolution.",
    },
    {
      element: ".viewdetailsstep3",
      intro: "Store any documents relating to your case here.",
    },
  ];

  return (
    <Container>
      <Box
        p="2rem 0"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Steps
          enabled={showGuide}
          steps={steps}
          initialStep={0}
          onExit={exit}
          options={{
            hideNext: false,
            hidePrev: true,
            keyboardNavigation: true,
            exitOnEsc: true,
            exitOnOverlayClick: false,
            showBullets: false,
            showStepNumbers: false,
            disableInteraction: true,
          }}
        />
        <Button
          onClick={() => {
            // history.push("/disputes");
            history.goBack();
          }}
          size="large"
          startIcon={<ArrowBackIosIcon />}
        >
          <FormattedMessage id="buttons.back" />
        </Button>
        <Grid container>
          <Grid item md={2} sm={0} xs={0}></Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper style={{ padding: "1rem", textAlign: "center" }}>
              <Box display="flex">
                <Typography className={classes.topText} variant="subtitle2">
                  {`${dispute_text} ${activeDispute.id}`}
                </Typography>
                <Box flexGrow={1}></Box>

                <Box>
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push(`/disputes/${activeDispute.id}/files`);
                    }}
                    className="viewdetailsstep3"
                    endIcon={<FolderIcon />}
                  >
                    <FormattedMessage id="common.files" />
                  </Button>
                </Box>
              </Box>
              <Box height="5rem" className={classes.box1}></Box>
              <Typography variant="h3" className={classes.disputeName}>
                {activeDispute.name}
              </Typography>
              <Box display="flex" justifyContent="center">
                <Button
                  style={{ margin: "0.5rem 0 0 0" }}
                  color="primary"
                  size="large"
                  startIcon={<VisibilityIcon />}
                  onClick={() => {
                    DocGenerator(activeDispute.id, "claimant");
                  }}
                  className="viewdetailsstep1"
                >
                  {isCDR || isVikalp ? (
                    <FormattedMessage id="dispute.statementOfClaim1" />
                  ) : (
                    <FormattedMessage id="dispute.statementOfClaim" />
                  )}
                </Button>
              </Box>
              <Box display="flex" justifyContent="center">
                {activeDispute.flow_type !== MEDIATION && (
                  <Button
                    style={{ margin: "0 0 0.5rem 0" }}
                    color="primary"
                    size="large"
                    startIcon={<VisibilityIcon />}
                    onClick={() => {
                      DocGenerator(activeDispute.id, RESPONDENT);
                    }}
                  >
                    {isCDR || isVikalp ? (
                      <FormattedMessage id="dispute.statementOfDefence1" />
                    ) : (
                      <FormattedMessage id="dispute.statementOfDefence" />
                    )}
                  </Button>
                )}
              </Box>
              {doc && (isHotStar || isStar || isMuthoot) && (
                <Box display="flex" justifyContent="center">
                  <Button
                    style={{ margin: "0 0 0.5rem 0" }}
                    color="primary"
                    size="large"
                    onClick={() => {
                      downloadLegalDoc(doc);
                    }}
                  >
                    Decision by grievance officer
                  </Button>
                </Box>
              )}

              <Hidden smDown xsDown>
                <Box height="2rem"></Box>
              </Hidden>
              <Hidden xsDown mdUp>
                <Box height="1rem" />
              </Hidden>
              {/* (isNbf &&  activeDispute.level_of_dispute == 'two') */}

              {isNbf ? (
                activeDispute.level_of_dispute == "two" ? (
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        history.push(`/disputes/${activeDispute.id}/chat`);
                      }}
                    >
                      <FormattedMessage id="common.hearingRooms" />
                    </Button>
                  </Box>
                ) : activeCase.type == "claimant" ? (
                  activeDispute.claimant_status != "closed" &&
                  activeDispute.status != "closed" && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={(e) => {
                          openpopup(e);
                        }}
                      >
                        Proceed
                      </Button>
                    </Box>
                  )
                ) : (
                  activeDispute.claimant_status == "closed" &&
                  activeDispute.status != "closed" && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={(e) => {
                          CloseDisputeLevel1();
                        }}
                      >
                        Close dispute at level 1
                      </Button>
                    </Box>
                  )
                )
              ) : (
                !isHotStar && !isStar && !isMuthoot &&
                !isNbf && (
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        history.push(`/disputes/${activeDispute.id}/chat`);
                      }}
                      className="viewdetailsstep2"
                    >
                      <FormattedMessage id="common.hearingRooms" />
                    </Button>
                  </Box>
                )
              )}

              <Box height="5rem" className={classes.box1}></Box>
            </Paper>

            {
              // console.log("activeDispute.name.split", activeDispute.name.split(" ")[activeDispute.name.split(" ").length - 1 ])
              // console.log("activeDispute", activeDispute, "activeCase:", activeCase, "isClosedLevel1", isClosedLevel1)
            }

            {(isDigipub ||
              (profile.profile.user.id === 478 && isWebnyay) ||
              (profile.profile.user.id === 444 && isDev) ||
              profile.profile.user.id == 221 ||
              (profile.profile.user.id == 419 && isDev) ||
              activeDispute.name.split(" ")[
                activeDispute.name.split(" ").length - 1
              ] == "Bench") &&
              activeCase.type == "respondent" && (
                <>
                  <Box height="1rem"></Box>
                  <Box display="flex" justifyContent="center">
                    {isClosedLevel1 ? (
                      <Button
                        // className={classes.btn2}
                        variant="contained"
                        color="#a74a2a"
                        disabled="true"
                        // onClick={(event) => { fun(event) }}
                      >
                        <FormattedMessage id="common.GrievanceLevel1IsClosed" />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="#a74a2a"
                        style={{ backgroundColor: "rgb(159 149 149 / 50%)" }}
                        size="large"
                        onClick={() => {
                          // if (isDigipub) {
                          //   closeGrievanceDigipub(activeCase.dispute);
                          // } else {
                          closeGrievance(activeCase.dispute);
                          // }
                        }}
                      >
                        <FormattedMessage id="common.closeGrievanceLevel1" />
                      </Button>
                    )}
                  </Box>
                </>
              )}
          </Grid>
          <Grid item md={2} sm={0} xs={0}></Grid>
        </Grid>
      </Box>
      <Popup
        open={open}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal}
        position="center"
        modal
        nested
        contentStyle={{ background: "white", width: "330px", height: "185px" }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
      >
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Typography
            style={{
              fontSize: "20px",
              color: "#333333",
              "font-weight": "bold",
              "line-height": "23px",
              "letter-spacing": "0.0015em",
            }}
          >
            Are you sure?
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontSize: "16px",
              color: "#828282",
              "line-height": "28px",
            }}
          >
            Are you satisfied with the response ?
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeModal}
              >
                No
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "green",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={MoveToLevel2}
              >
                Escalate to PNBSA
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={CloseDisputeForClaimant}
              >
                Yes
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </Container>
  );
}
