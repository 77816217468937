import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0f0f0",
  },
  heading1: {
    margin: "20px 0",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "40px",
    color: "#333333",
    // textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize:"18px",
      lineHeight: "30px",
    },
    
  },
  grid: {
    padding: "28px",
  },
  btnblue: {
    background: "#70c8ce",
    border: "1px solid transparent",
    color: "#f2f2f2",
    borderRadius: "0.8rem",
    transition: "0.5s",
    width: "270px",
    height: "45px",
    "&:hover": {
      color: "#70c8ce",
      borderRadius: "0",
      border: "1px solid #70c8ce",
      background: "#f0f0f0",
    },
  },
  qwett: {
    background: "#f66255",
    border: "1px solid transparent",
    color: "#f2f2f2",
    borderRadius: "0.8rem",
    transition: "0.5s",
    width: "270px",
    height: "45px",
    "&:hover": {
      color: "#f66255",
      borderRadius: "0",
      border: "1px solid #f66255",
      background: "#f0f0f0",
    },
  },
}));


export default function Demo() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <Grid container justify="center" className={classes.grid}>
        <Grid item sm={9}>
          <Typography className={classes.heading1}>
          Webnyaya provides a cloud-based comprehensive arbitration and mediation management and hearing platform. 
          </Typography>
          <Typography className={classes.heading1}>
          Start today with Webnyaya’s end-to-end platform for arbitrations, mediations, conciliations and adjudications. 
          </Typography>
          <Typography className={classes.heading1}>
          Use Webnyaya’s platform for online, physical and hybrid arbitrations and mediations
          </Typography>
          <Box height="1rem" />
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <a
                target="__blank"
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=qjfY1zkcekWz6XUWzxZ2X7QtQAXgGixEsmvvze2zb0ZUNTg4OUFOM0NOTDRPMlNYWjBLRzVJUU5KUC4u"
              >
                <Button variant="contained" className={classes.btnblue}>
                  REQUEST A DEMO
                </Button>
              </a>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.qwett}
                onClick={(e) => {
                  history.push("/signup");
                }}
              >
                SIGN UP
              </Button>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <Box height="2rem" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
