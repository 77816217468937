import { Button, Grid, Snackbar, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { updateDisputesNameForUserApi } from "Admin/Sebi/CaseDisplay";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getRealhost } from "slices/disputeSlice";
import { updateProfile } from "slices/profileSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
  },
  editprofile: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
}));

export default function EditProfileRow({ profileData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [haveProfile, setHaveProfile] = useState(
    profileData.profile ? true : false
  );

  const [id, setid] = useState(
    haveProfile ? profileData.profile.id : profileData.id
  );
  const user_id = profileData.user.id;
  const [firstname, setFirstname] = useState(profileData.user.first_name);
  const [lastname, setLastname] = useState(profileData.user.last_name);
  const [phone, setPhone] = useState(
    haveProfile ? profileData.profile.phone_number : profileData.phone_number
  );
  const [email, setEmail] = useState(profileData.user.email);
  const [address, setAddress] = useState(
    haveProfile ? profileData.profile.address : profileData.address
  );

  const onFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const [openSnackBar1, setOpenSnackBar1] = useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const handleCloseSnackBar2 = () => {
    setOpenSnackBar2(false);
  };
  const [openSnackBar3, setOpenSnackBar3] = useState(false);
  const handleCloseSnackBar3 = () => {
    setOpenSnackBar3(false);
  };
  const [openSnackBar4, setOpenSnackBar4] = useState(false);
  const handleCloseSnackBar4 = () => {
    setOpenSnackBar4(false);
  };
  const [openSnackBar5, setOpenSnackBar5] = useState(false);
  const handleCloseSnackBar5 = () => {
    setOpenSnackBar5(false);
  };

  const onSubmit = () => {
    const profileData = {
      id: id,
      phone_number: phone,
      address: address,
      user: {
        first_name: firstname,
        last_name: lastname,
        email: email,
      },
    };

    if (!firstname || !lastname || !email || !phone) {
      setOpenSnackBar3(true);
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        setOpenSnackBar4(true);
      } else {
        let countspace = (phone.match(/ /g) || []).length;
        if (countspace > 0) {
          if (countspace > 1) {
            setOpenSnackBar5(true);
          } else {
            if (/^\d+$/.test(phone.split(" ")[1])) {
              dispatch(updateProfile(profileData)).then(() => {
                dispatch(
                  updateDisputesNameForUserApi(user_id, getRealhost())
                )
              });
            } else {
              setOpenSnackBar5(true);
            }
          }
        } else {
          if (/^\d+$/.test(phone)) {
            dispatch(updateProfile(profileData)).then(() => {});
          } else {
            setOpenSnackBar5(true);
          }
        }
      }
    }
  };

  return (
    <>
      <Grid container justify="space-evenly" spacing={2}>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            fullWidth={true}
            value={firstname}
            onChange={onFirstNameChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth={true}
            value={lastname}
            onChange={onLastNameChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Phone Number"
            variant="outlined"
            fullWidth={true}
            value={phone}
            onChange={onPhoneChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email ID"
            variant="outlined"
            fullWidth={true}
            value={email}
            onChange={onEmailChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Address"
            variant="outlined"
            fullWidth={true}
            value={address}
            onChange={onAddressChange}
          />
        </Grid>
      </Grid>
      <Box height="2rem"></Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.bigbtn}
        onClick={onSubmit}
      >
        Save
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Problem in creating guest profile."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Problem in creating guest profile.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar2}
        onClose={handleCloseSnackBar2}
        message="Guest profile is created."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Profile is created.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar3}
        onClose={handleCloseSnackBar3}
        message="Please enter all the details."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="warning">
          Please enter all the details.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar4}
        onClose={handleCloseSnackBar4}
        message="Please enter valid email address."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter valid email address.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar5}
        onClose={handleCloseSnackBar5}
        message="Please enter valid phone number."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter valid phone number.
        </Alert>
      </Snackbar>
    </>
  );
}
