import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Hidden,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useLocation } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { updateDispute, uploadDispute } from "slices/disputeSlice";
import { useDispatch } from "react-redux";
import { downloadLegalDoc } from "slices/disputeSlice";
import { formatDate } from "utils";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneDialog } from "material-ui-dropzone";
import instance from "api/globalaxios";
import { getWindowDimension } from "utils/responsiveUtils";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

const useStyles = makeStyles((theme) => ({
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  text:{
    textAlign: "left",
    paddingLeft : "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      paddingLeft: "20px",
    },
  },
}));

export default function GrievanceRow({ dispute, setOpenCase, setCaseId }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const arr = dispute.name.split("vs");
  const [complainant, setComplainant] = useState(arr[0]);
  const [respondent, setRespondent] = useState(arr[1]);
  const level = dispute.level_1_compete == true ? 2 : 1;

  return (
    <>
      <Paper
        style={{ padding: "10px 0", cursor: "pointer" }}
        onClick={(e) => {
          setOpenCase(true);
          setCaseId(dispute.id);
          sessionStorage.setItem("subadmin_overview_showCase", true);
          sessionStorage.setItem("caseid", dispute.id);
        }}
      >
        <Grid container justify="flex-start">
          <Grid item sm={2} xs={2}>
            <Typography  className={[classes.blueheadingtext, classes.text].join(' ')} >
              {dispute.id} 
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3}>
            <Typography className={classes.blueheadingtext}>
              {complainant}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3}>
            <Typography className={classes.blueheadingtext}>
              {respondent}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            <Typography className={classes.blueheadingtext}>{level}</Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            <Typography className={classes.blueheadingtext}>
              {formatDate(dispute.created)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box height="0.6rem"></Box>
    </>
  );
}
