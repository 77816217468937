import React, { useState } from 'react';
import {
    Box,
    Paper,
    Grid,
    makeStyles,
    Button,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
    Typography,
    Snackbar,
    Checkbox,
    FormControlLabel,
    IconButton,
    Menu,
    Divider

} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { updateDispute, uploadDispute } from "slices/disputeSlice";
import { useDispatch } from "react-redux";
import { downloadLegalDoc } from "slices/disputeSlice";
import { formatDate } from "utils";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneDialog } from "material-ui-dropzone";
import instance from 'api/globalaxios';
import { getWindowDimension } from "utils/responsiveUtils";
import filterBlackSvg from "images/filter.svg"
import searchSvg from "images/search.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
    blackheading: {
        fontWeight: "550",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.05em",
        color: "#2C2C2C",
        padding: "17px 0",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "14px",
        },
    },
    inactivetext: {
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "14px",
        color: "#6E6893",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            lineHeight: "12px",
        },
    },
    activetext: {
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "14px",
        color: "#3F51B5",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            lineHeight: "12px",
        },
    },
    greytext: {
        fontSize: "12px",
        lineHeight: "14px",
        color: "#828282",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            lineHeight: "12px",
        },
    },
    greyheading: {
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "14px",
        letterSpacing: "0.05em",
        color: "#828282",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            lineHeight: "12px",
        },
    },
    grey: {
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.05em",
        color: "#828282",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "14px",
        },
    },
    black: {
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.05em",
        color: "#2C2C2C",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "16px",
        },
    },
    grid: {
        paddingLeft: "1.6rem",
        // background: "#ECEEF8",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "0.9rem",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "0.3rem",
        },
    },
    inactive: {
        background: "#F2F0F9",
        borderRadius: "22px",
        padding: "4px 10px"
    },
    active: {
        background: "#ECEEF8",
        borderRadius: "22px",
        padding: "4px 10px"
    }
}));

export default function ClientProfile({
    name, status, last_login, open, closed
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [selected, setSelected] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectedChange = (e) => {
        setSelected(e.target.checked);
    }

    return (<>
        <Grid container justify="flex-start" alignItems='baseline' className={classes.grid}>
            <Grid item md={1} sm={1} xs={1} 
            // style={{ textAlign: "left" }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selected}
                            onChange={handleSelectedChange}
                            name="selectall"
                            color="primary"
                        />
                    }
                    label=""
                />
            </Grid>
            <Grid item md={3} sm={3} xs={3} style={{ textAlign: "left" }}>
                <Typography className={classes.blackheading}>{name}</Typography>
            </Grid>
            <Grid item md={3} sm={3} xs={3} style={{ textAlign: "left", marginTop: "11px" }}>
                <Grid container justify="space-between" direction="column">
                    <Grid item sm={6} xs={6}>
                        <Grid container justidy="flex-start">
                            <Grid item>
                                <Box className={status?classes.active:classes.inactive}>
                                    <Typography className={status?classes.activetext:classes.inactivetext}>{status ? "Active" : "Inactive"}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <Typography className={classes.greytext}>Last login: {last_login ? formatDate(last_login) : "--"}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={2} sm={2} xs={2} style={{ textAlign: "left" }}>
                <Typography className={classes.blackheading}>{open}</Typography>
            </Grid>
            <Grid item md={2} sm={2} xs={2} style={{ textAlign: "left" }}>
                <Typography className={classes.blackheading}>{closed}</Typography>
            </Grid>
            <Grid item md={1} sm={1} xs={1} style={{display:"flex",justifyContent:"center"}}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}
                    >Edit</MenuItem>
                    <MenuItem onClick={handleClose}
                    >View Profile</MenuItem>
                    <MenuItem onClick={handleClose}
                    >Activate User</MenuItem>
                    <MenuItem onClick={handleClose}
                    >Delete</MenuItem>
                </Menu>
            </Grid>
        </Grid>

    </>)
}
