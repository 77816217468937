import React from "react";
import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";

import DoneCircleImg from "images/cred/doneCircle.svg";
import CurrentCircleImg from "images/cred/currentCircle.svg";
import OtherCircleImg from "images/cred/otherCircle.svg";

const BlueLine = () => {
  return (
    <div
      style={{
        background: "#1D4ED8",
        height: "3px",
        width: "100%",
        borderRadius: 5,
        margin: 8,
      }}
    ></div>
  );
};

const GreyLine = () => {
  return (
    <div
      style={{
        background: "#E5E7EB",
        height: "3px",
        width: "100%",
        borderRadius: 5,
        margin: 8,
      }}
    ></div>
  );
};

const StepperDate = ({ children, classes }) => {
  return <span className={classes.date}>{children}</span>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  stepperGrid: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  date: {
    position: "absolute",
    top: -40,
    left: -13,
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      top: -30,
    },
  },
  textBox: {
    position: "absolute",
    top: 50,
  },
  step: {
    color: "#4B5563",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  blackHeading: {
    color: "#1F2937",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  viewBox: {
    marginTop: 5,
    padding: "4px 10px",
    background: "#E8F0F3",
    borderRadius: 20,
    width: "fit-content",
    cursor: "pointer",
  },
  view: {
    color: "#1D4ED8",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  pendingBox: {
    marginTop: 5,
    padding: "4px 10px",
    background: "#F3F4F6",
    borderRadius: 20,
    width: "fit-content",
  },
  pending: {
    color: "#4B5563",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: 500,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
}));

const Stepper = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item sm={2} xs={4} className={classes.stepperGrid}>
          <img src={DoneCircleImg} alt="step done" />
          <BlueLine />
          <StepperDate classes={classes}>15/12/2020</StepperDate>
          <Box className={classes.textBox}>
            <Typography className={classes.step}>STEP 01</Typography>
            <Typography className={classes.blackHeading}>
              Draft Notice
            </Typography>
            <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
              <Box className={classes.viewBox}>
                <Typography className={classes.view}>VIEW</Typography>
              </Box>
            </a>
          </Box>
        </Grid>
        <Grid item sm={2} xs={4} className={classes.stepperGrid}>
          <img src={DoneCircleImg} alt="step done" />
          <BlueLine />
          <StepperDate classes={classes}>16/12/2020</StepperDate>
          <Box className={classes.textBox}>
            <Typography className={classes.step}>STEP 02</Typography>
            <Typography className={classes.blackHeading}>1 Day</Typography>
            <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
              <Box className={classes.viewBox}>
                <Typography className={classes.view}>VIEW</Typography>
              </Box>
            </a>
          </Box>
        </Grid>
        <Grid item sm={2} xs={4} className={classes.stepperGrid}>
          <img src={DoneCircleImg} alt="step done" />
          <BlueLine />
          <StepperDate classes={classes}>1/01/2021</StepperDate>
          <Box className={classes.textBox}>
            <Typography className={classes.step}>STEP 03</Typography>
            <Typography className={classes.blackHeading}>30 Days</Typography>
            <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
              <Box className={classes.viewBox}>
                <Typography className={classes.view}>VIEW</Typography>
              </Box>
            </a>
          </Box>
        </Grid>
        <Hidden smUp>
          <Box height={150} width="100%" />
        </Hidden>
        <Grid item sm={2} xs={4} className={classes.stepperGrid}>
          <img src={DoneCircleImg} alt="step done" />
          <BlueLine />
          <StepperDate classes={classes}>1/03/2021</StepperDate>
          <Box className={classes.textBox}>
            <Typography className={classes.step}>STEP 04</Typography>
            <Typography className={classes.blackHeading}>60 Days</Typography>
            <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
              <Box className={classes.viewBox}>
                <Typography className={classes.view}>VIEW</Typography>
              </Box>
            </a>
          </Box>
        </Grid>
        <Grid item sm={2} xs={4} className={classes.stepperGrid}>
          <img src={CurrentCircleImg} alt="current step" />
          <GreyLine />
          <StepperDate classes={classes}>21/08/2021</StepperDate>
          <Box className={classes.textBox}>
            <Typography className={classes.step}>STEP 05</Typography>
            <Typography className={classes.blackHeading}>90 Days</Typography>
            <a href="https://webnyaya.com/files/Notice.pdf" target="__blank">
              <Box className={classes.viewBox}>
                <Typography className={classes.view}>VIEW</Typography>
              </Box>
            </a>
          </Box>
        </Grid>
        <Grid item sm={2} xs={4} className={classes.stepperGrid}>
          <img src={OtherCircleImg} alt="step not reached" />
          <GreyLine />
          <Box className={classes.textBox}>
            <Typography className={classes.step}>STEP 06</Typography>
            <Typography className={classes.blackHeading}>
              Response filed
            </Typography>
            <Box className={classes.pendingBox}>
              <Typography className={classes.pending}>Pending</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stepper;
