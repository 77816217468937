import React from "react";
import { useSnackbar } from "contexts/SnackbarContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SnackbarAlert() {
  const { open, message, severity, closeSnackbar } = useSnackbar();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    closeSnackbar();
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={5000}
    >
      <pre>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </pre>
    </Snackbar>
  );
}

export default SnackbarAlert;
