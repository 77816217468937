import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDispute } from "slices/disputeSlice";
import Files from "./Files";
import {
  getFiles,
  getFolder,
  getFolders,
  getProfilesOfDispute,
} from "./FileApi";
import { removeSessionStorage } from "features/tiac_header_waiting/TiacAppHeader";
import { isDifc, isMSME, isSebi } from "Routes/Routes";
import { KeyboardBackspace } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  btntext: {
    color: theme.palette.primary.main,
    fontFamily: "Satoshi-Bold",
    // fontSize: "14px",
    textTransform: "none",
  },
  hov: {
    "&:hover": {
      color: "#333333",
    },
  },
}));

export default function FileContainer() {
  let { disputeId, folder_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.profile, shallowEqual);

  const [dispute, setDispute] = useState();
  const [loading, setLoading] = useState(true);

  const [parent_folder, setParent_folder] = useState();
  const [allFiles, setAllFiles] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);

  useEffect(() => {
    dispatch(getDispute(disputeId)).then((res) => {
      setDispute(res.results[0]);
      setLoading(false);
    });
    getProfiles();
  }, []);

  const getProfiles = () => {
    getProfilesOfDispute(disputeId)
      .then((res) => {
        let profiles = [];
        res.data.details.claimant.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Claimant";
          if (p.profile.id !== profile.profile.id) profiles.push(p);
        });
        res.data.details.claimant_lawyer.forEach((item) => {
          let p = item;
          p.profile.profile_type = "Claimant's Counsel";
          if (p.profile.id !== profile.profile.id) profiles.push(p);
        });
        res.data.details.respondent.forEach((item) => {
          let p = item;
          p.profile.profile_type = isDifc ? "Defendant" : "Respondent";
          if (p.profile.id !== profile.profile.id) profiles.push(p);
        });
        res.data.details.respondent_lawyer.forEach((item) => {
          let p = item;
          p.profile.profile_type = isDifc
            ? "Defendant's Counsel"
            : "Respondent's Counsel";
          if (p.profile.id !== profile.profile.id) profiles.push(p);
        });
        res.data.details.professionals.forEach((item) => {
          let p = item;
          if (p.profile.profile_type === "arbitrator_rv") {
            p.profile.profile_type = "Arbitrator";
          } else if (p.profile.profile_type === "witness") {
            p.profile.profile_type = "Witness";
          } else if (p.profile.profile_type === "witness_rv") {
            p.profile.profile_type = "Barrister";
          } else if (p.profile.profile_type === "expert_rv") {
            p.profile.profile_type = "Solicitor";
          } else {
            p.profile.profile_type =
              isSebi || isMSME ? "Conciliator" : "Mediator";
          }
          if (p.profile.id !== profile.profile.id) profiles.push(p);
        });
        setAllProfiles(profiles);
      })
      .catch((error) => {});
  };

  const reload = () => {
    setAllFiles([]);
    setAllFolders([]);
    getFiles(disputeId, folder_id).then((res) => {
      setAllFiles(res.data);
    });
    getFolders(disputeId, folder_id).then((res) => {
      setAllFolders(res.data);
    });
    getFolder(folder_id).then((response) => {
      setParent_folder(response.data[0]);
      if (response.data.length > 0) {
        let routes = getParent(response.data[0]);
        routes = routes.reverse();
        setAllRoutes(routes);
      } else {
        setAllRoutes([]);
      }
    });
  };

  const getParent = (parent) => {
    let arr = [];
    arr.push({ name: parent.name, id: parent.id });
    if (parent.parent_folder) {
      let t = getParent(parent.parent_folder);
      t.map((item) => {
        arr.push(item);
      });
    }
    return arr;
  };

  useEffect(() => {
    reload();
  }, [folder_id]);

  if (loading) return <CircularCenteredLoader />;
  return (
    <Grid container justify="center">
      <Grid item lg={10} md={10} sm={11} xs={11} className={classes.root}>
        <Button
          // startIcon={<KeyboardArrowLeftIcon style={{ color: "#2F80ED" }} />}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={{ backgroundColor: "transparent", cursor: "default" }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Typography
                className={classes.btntext}
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  if (
                    ["sub_admin_tiac_rv", "case_manager", "admin"].includes(
                      profile.profile.profile_type
                    )
                  ) {
                    removeSessionStorage();
                  }
                  history.goBack();
                }}
              >
                <KeyboardBackspace />
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.btntext} ${classes.hov}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    ["sub_admin_tiac_rv", "case_manager", "admin"].includes(
                      profile.profile.profile_type
                    )
                  ) {
                    removeSessionStorage();
                  }
                  history.push("/");
                }}
              >
                Dashboard
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.btntext}
                style={{ color: "black" }}
              >
                /
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.btntext} ${classes.hov}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    ["sub_admin_tiac_rv", "case_manager"].includes(
                      profile.profile.profile_type
                    )
                  ) {
                    sessionStorage.setItem("tiac_overview_caseid", dispute.id);
                    history.push(`/`);
                  } else if (["admin"].includes(profile.profile.profile_type)) {
                    sessionStorage.setItem("caseid", dispute.id);
                    history.push(`/`);
                  } else history.push(`/disputes/${dispute.id}/`);
                }}
              >
                {dispute.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.btntext}
                style={{ color: "black" }}
              >
                /
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.btntext} ${classes.hov}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(`/disputes/${dispute.id}/files/0`);
                }}
              >
                Files
              </Typography>
            </Grid>
            {allRoutes.map((route) => {
              return (
                <React.Fragment key={route.id}>
                  <Grid item>
                    <Typography
                      className={classes.btntext}
                      style={{ color: "black" }}
                    >
                      /
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.btntext} ${classes.hov}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push(
                          `/disputes/${dispute.id}/files/${route.id}`
                        );
                      }}
                    >
                      {route.name}
                    </Typography>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Button>
        <Files
          disputeId={disputeId}
          folder_id={folder_id}
          allFiles={allFiles}
          allFolders={allFolders}
          reload={reload}
          allProfiles={allProfiles}
          profile={profile.profile}
          parent_folder={parent_folder}
        />
      </Grid>
    </Grid>
  );
}
