import { Box, Typography } from '@material-ui/core'
import React from 'react'

function SuccessPage() {
  return (
    <Box>
        <Typography variant='h2'>Payment is successful.</Typography>
    </Box>
  )
}

export default SuccessPage
