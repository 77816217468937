import {
  Box,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { formatDate } from "utils";
import CaseDisplay from "./CaseDisplay";
import GeneralChoicesContainer from "./GeneralChoicesContainer";
import GeneralDisputeItem from "./GeneralDisputeItem";
import GeneralSebiActivities from "./GeneralSebiActivities";

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "left",
    margin: "1rem 0 0 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.5rem",
    },
  },
  overview: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "400",
    color: "#252733",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    padding: "1rem 2rem",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
    "&:hover": {
      background: "#ebebeb",
    },
  },
  selectedPaper: {
    padding: "1rem 2rem",
    textAlign: "center",
    borderRadius: "8px",
    background: "#BDBDBD",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
  },
  headingPaper: {
    fontWeight: "500",
    fontSize: "20px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontize: "15px",
    },
  },
  numberPaper: {
    fontWeight: "500",
    fontSize: "40px",
    color: "#2C2C2C",
    [theme.breakpoints.down("sm")]: {
      fontize: "30px",
    },
  },
  activityText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
  },
  activityPaper: {
    border: "1px solid #BDBDBD",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    padding: "2rem 2rem 1.3rem 2rem",
  },
  activityUsername: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#2F80ED",
  },
  activityheading: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityTime: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityfileFolderName: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    wordBreak: "break-word",
  },
  disputeHeading: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#333333",
  },
  disputeText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#333333",
    wordBreak: "break-word",
  },
  sortbybtn: {
    cursor: "pointer",
    border: "none",
    background: "#E0E0E0",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    borderRadius: "4px",
  },
  sortbytext: {
    padding: "10px 30px 10px 10px",
    fontSize: "12px",
    lineHeight: "0px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#454545",
  },
}));

export default function GeneralOverview({ profile }) {
  const classes = useStyles();

  let cid = sessionStorage.getItem("tiac_overview_caseid");

  const [openCase, setOpenCase] = useState(cid ? true : false);
  const [caseId, setCaseId] = useState(cid ? cid : null);

  let ch = sessionStorage.getItem("tiac_overview_choice");

  const [choice, setChoice] = useState(ch ? ch : null);
  const [loadingDisputes, setLoadingDisputes] = useState(true);
  const [disputes, setDisputes] = useState([]);
  const [disputesReceived, setDisputesReceived] = useState([]);
  const [disputesRegistered, setDisputesRegistered] = useState([]);
  const [disputesClosed, setDisputesClosed] = useState([]);
  const [activities, setActivities] = useState({});

  const reloadOverview = () => {
    getDisputes()
      .then((res) => {
        setDisputesReceived(res.data["Arbitration"]);
        setDisputesRegistered(res.data["Conciliation"]);
        setDisputesClosed(res.data["closed"]);
        if (choice == "received") {
          setDisputes(res.data["Arbitration"]);
        } else if (choice == "registered") {
          setDisputes(res.data["Conciliation"]);
        } else if (choice == "closed") {
          setDisputes(res.data["closed"]);
        }
        setLoadingDisputes(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // dispatch(getActivities())
    //   .then((res) => {
    //     setActivities(res);
    //     setLoadingActivities(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    reloadOverview();
  }, []);

  async function getDisputes() {
    return await instance.get("/tiac_admin_disputes/");
  }

  const [sortby, setSortby] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event, action) => {
    if (action === "open") {
      setAnchorEl(event.currentTarget);
    } else if (action === "close") {
      setAnchorEl(null);
    }
  };

  function handleSort(sortBy, fieldName) {
    setSortby(sortBy);
    setAnchorEl(null);

    const getField = (name) => name.split("v.")[fieldName].trim().toUpperCase();

    const sortAndUpdate = (arr) => {
      let result = [...arr];
      result.sort((a, b) => {
        const nameA = getField(a.name);
        const nameB = getField(b.name);
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return result;
    };

    const resultReceived = sortAndUpdate(disputesReceived);
    setDisputesReceived(resultReceived);
    if (choice === "received") setDisputes(resultReceived);

    const resultRegistered = sortAndUpdate(disputesRegistered);
    setDisputesRegistered(resultRegistered);
    if (choice === "registered") setDisputes(resultRegistered);

    const resultClosed = sortAndUpdate(disputesClosed);
    setDisputesClosed(resultClosed);
    if (choice === "closed") setDisputes(resultClosed);
  }

  if (openCase) {
    return (
      <CaseDisplay
        setOpenCase={setOpenCase}
        caseId={caseId}
        mode={"RV"}
        profile={profile}
        reloadOverview={reloadOverview}
      />
    );
  }

  const menuItems = [
    { label: "ID", value: "id", field: 0 },
    { label: "Complainant Name", value: "cname", field: 0 },
    { label: "Respondent Name", value: "rname", field: 1 },
    // Add more menu items as needed
  ];
  if (loadingDisputes) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <>
        <Grid container justify="center">
          <Grid item md={10} sm={11} xs={12}>
            <Box className={classes.box}>
              <Grid
                container
                justify="space-between"
                direction="row"
                style={{ padding: "10px" }}
              >
                <Grid item>
                  <Typography className={classes.overview}>
                    Overview (Open cases)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.username}>
                    {profile.user.first_name} {profile.user.last_name}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="2rem" />
              {choice && (
                <Grid container justify="flex-end">
                  <Grid item>
                    <button
                      type="button"
                      className={classes.sortbybtn}
                      onClick={(event) => handleMenu(event, "open")}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <Typography className={classes.sortbytext}>
                        Sort by
                      </Typography>
                    </button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={(event) => handleMenu(event, "close")}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {menuItems.map((menuItem) => (
                        <MenuItem
                          key={menuItem.value}
                          onClick={() =>
                            handleSort(menuItem.value, menuItem.field)
                          }
                          style={
                            sortby === menuItem.value
                              ? { background: "#BDBDBD" }
                              : {}
                          }
                        >
                          {menuItem.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              )}
              <Box height="1rem" />
              <GeneralChoicesContainer
                choice={choice}
                setChoice={setChoice}
                disputesReceived={disputesReceived}
                disputesRegistered={disputesRegistered}
                disputesClosed={disputesClosed}
                setDisputes={setDisputes}
              />
              <Box height="2rem" />
              {choice ? (
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box style={{ padding: "1rem" }}>
                      <Grid container justify="flex-start">
                        <Grid item sm={2} xs={3}>
                          <Typography className={classes.disputeHeading}>
                            ID
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.disputeHeading}>
                            Claimant Name
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.disputeHeading}>
                            Respondent Name
                          </Typography>
                        </Grid>
                        <Grid item sm={2} xs={3}>
                          <Typography className={classes.disputeHeading}>
                            Start Date
                          </Typography>
                        </Grid>
                        {choice === "closed" && (
                          <Grid item sm={2} xs={3}>
                            <Typography className={classes.disputeHeading}>
                              Status
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    <Box height="0.4rem"></Box>
                    {disputes.map((dispute) => (
                      <GeneralDisputeItem
                        key={dispute.id}
                        dispute={dispute}
                        setOpenCase={setOpenCase}
                        setCaseId={setCaseId}
                        formatDate={formatDate}
                        choice={choice}
                      />
                    ))}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container>
                    <Grid item md={10} sm={11} xs={12}>
                      <GeneralSebiActivities
                        activities={activities}
                        setActivities={setActivities}
                        activityTitle="Account Activity"
                      />
                      <Box height="2rem" />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}
