import { Box, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import Line from "./Line";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "14px",
    color: "#000000",
  },
  box: {
    height: "10px",
  },
}));

export default function Lines({ labels, cols, data }) {
  const classes = useStyles();

  const [closedPercentage, setClosedPercentage] = useState("100");
  const [pendingPercentage, setPendingPercentage] = useState();
  const [newPercentage, setNewPercentage] = useState();
  const [draftsPercentage, setDraftsPercentage] = useState();
  const [rejectedPercentage, setRejectedPercentage] = useState();

  React.useEffect(() => {
    setClosedPercentage(data[0] > 0 ? 100 : 0);

    let cl = (data[1] / data[0]) * 100;
    setPendingPercentage(isNaN(cl) ? 0 : cl);

    cl = (data[2] / data[0]) * 100;
    setNewPercentage(isNaN(cl) ? 0 : cl);

    cl = (data[3] / data[0]) * 100;
    setDraftsPercentage(isNaN(cl) ? 0 : cl);

    cl = (data[4] / data[0]) * 100;
    setRejectedPercentage(isNaN(cl) ? 0 : cl);
  }, [data]);

  return (
    <>
      <Line
        percentage={closedPercentage}
        label={labels[0]}
        data={data[0]}
        col={cols[0]}
      />
      <Box height="1rem" />
      <Line
        percentage={pendingPercentage}
        label={labels[1]}
        data={data[1]}
        col={cols[1]}
      />
      <Box height="1rem" />
      <Line
        percentage={newPercentage}
        label={labels[2]}
        data={data[2]}
        col={cols[2]}
      />
      <Box height="1rem" />
      <Line
        percentage={draftsPercentage}
        label={labels[3]}
        data={data[3]}
        col={cols[3]}
      />
      <Box height="1rem" />
      <Line
        percentage={rejectedPercentage}
        label={labels[4]}
        data={data[4]}
        col={cols[4]}
      />
      <Box height="1rem" />
    </>
  );
}
