import React from "react";
import UserVerifyForm from "./UserVerifyForm";
import FullPageSpinner from "features/common/FullPageSpinner";
import { getProfiles } from "slices/profileSlice";
import {
  ADVOCATE_RV,
  GENERAL_RV,
  ARBITRATOR_RV,
  CLAIMANT_ADVOCATE_COMPLETE,
  RESPONDENT_COMPLETE,
  RESPONDENT_ADVOCATE_COMPLETE,
  ARBITRATOR_COMPLETE,
} from "app/constants";

const getProfileToSearch = (rvStatus) => {
  switch (rvStatus) {
    case CLAIMANT_ADVOCATE_COMPLETE:
      return ADVOCATE_RV;
    case RESPONDENT_COMPLETE:
      return GENERAL_RV;
    case RESPONDENT_ADVOCATE_COMPLETE:
      return ADVOCATE_RV;
    case ARBITRATOR_COMPLETE:
      return ARBITRATOR_RV;
  }
};

export default function UserVerifyFormContainer({
  profileFetched,
  setNotEmpty,
  rvStatus,
  step
}) {
  const [loading, setLoading] = React.useState(true);
  const [profiles, setProfiles] = React.useState([]);

  React.useEffect(() => {
    getProfiles(getProfileToSearch(rvStatus)).then((data) => {
      setProfiles(data);
      setLoading(false);
    });
  }, []);

  if (loading) return <FullPageSpinner />;

  const childProps = { profileFetched, setNotEmpty, rvStatus, profiles ,step};

  return <UserVerifyForm {...childProps} />;
}
