import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import DisplayContainer from "features/profile/DisplayContainer";
import { StyledTextFieldTiac } from "features/TempTiacFormV2/ClaimantPage/questions";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  isArb,
  isArbitrationConference,
  isDifc,
  isHotStar,
  isKilumaya,
  isMSME,
  isMuthoot,
  isSebi,
  isStar,
  isTiac,
} from "Routes/Routes";
import { changePassword } from "slices/loginSlice";
import { changeTimeZone, getTimeZones } from "slices/timezoneSlice";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

export default function ProfileSecurity({ profile }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    error: "",
    nonFieldErrors: [],
  });
  const [emptyError1, setEmptyError1] = useState();
  const [emptyError2, setEmptyError2] = useState();

  const { currentPassword, newPassword, error, nonFieldErrors } = state;
  const [currentTimezone, setCurrentTimezone] = useState(profile.timezone);

  const timezones = useSelector((state) => state.timezones, shallowEqual);

  useEffect(() => {
    dispatch(getTimeZones());
  }, [dispatch]);

  const onTimeZoneChange = (e) => {
    dispatch(changeTimeZone(e.target.value)).then(() => {
      setCurrentTimezone(e.target.value);
    });
  };

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: value });
    if (name == "currentPassword" && value != "") {
      setEmptyError1();
    }
    if (name == "newPassword" && value != "") {
      setEmptyError2();
    }
    if (name == "currentPassword" && value == "") {
      setEmptyError1("Current password required");
    }
    if (name == "newPassword" && value == "") {
      setEmptyError2("New password required");
    }
  };

  const setErrors = (error, nonFieldErrors) => {
    setState({ ...state, error, nonFieldErrors });
  };

  const onSubmit = async (e) => {
    if (currentPassword == "" || newPassword == "") {
      if (currentPassword == "") {
        setEmptyError1("Current password required");
      }
      if (newPassword == "") {
        setEmptyError2("New password required");
      }
    } else {
      setEmptyError1();
      setEmptyError2();
      if (currentPassword == newPassword) {
        setEmptyError2("New password can't be the same as current password");
        return;
      }
      try {
        await dispatch(changePassword(currentPassword, newPassword));
        Swal.fire({
          icon: "success",
          title: "Password changed succesfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        let parsedError = JSON.parse(err);
        if (parsedError.error.non_field_errors) {
          setErrors("", parsedError.error.non_field_errors);
        } else {
          setErrors(parsedError.error, []);
        }
      }
    }
  };

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <Box>
      <DisplayContainer title={<FormattedMessage id="auth.password" />}>
        {isTiac ||
        isArb ||
        isKilumaya ||
        isDifc ||
        isArbitrationConference ||
        isSebi ||
        isMSME ? (
          <StyledTextFieldTiac
            name="currentPassword"
            value={currentPassword}
            onChange={onChange}
            placeholder="current password"
            fullWidth
            type={passwordType}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            name="currentPassword"
            value={currentPassword}
            onChange={onChange}
            placeholder="current password"
            fullWidth
            type={passwordType}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}

        <Box height="1rem" />
        {error.length > 0 && !emptyError1 && !emptyError2 && (
          <Alert severity="error">{error}</Alert>
        )}
        {emptyError1 && <Alert severity="error">{emptyError1}</Alert>}
        <Box height="2.5rem" />
        {isTiac ||
        isArb ||
        isKilumaya ||
        isDifc ||
        isArbitrationConference ||
        isSebi ||
        isMSME ? (
          <StyledTextFieldTiac
            name="newPassword"
            value={newPassword}
            onChange={onChange}
            placeholder="new password"
            fullWidth
            type={passwordType}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            name="newPassword"
            value={newPassword}
            onChange={onChange}
            placeholder="new password"
            fullWidth
            type={passwordType}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        <Box height="1rem" />
        {emptyError2 && <Alert severity="error">{emptyError2}</Alert>}
        {!emptyError1 &&
          !emptyError2 &&
          nonFieldErrors.map((error, index) => {
            return (
              <Alert severity="error" key={index}>
                {error}
              </Alert>
            );
          })}
        <Box height="2.5rem" />

        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
          style={
            isTiac ||
            isArb ||
            isKilumaya ||
            isDifc ||
            isArbitrationConference ||
            isSebi ||
            isMSME
              ? { background: "#2F80ED" }
              : {}
          }
        >
          {<FormattedMessage id="buttons.resetPassword" />}
        </Button>
      </DisplayContainer>
      <Box height="2rem" />
      <DisplayContainer title={"Timezone"}>
        {timezones.hasErrors && (
          <Alert severity="error">{timezones.hasErrors}</Alert>
        )}
        <FormControl
          variant="outlined"
          disabled={timezones.loading}
          fullWidth={true}
        >
          <InputLabel id="against">Select Timezone</InputLabel>
          <Select
            id="select_timezone"
            name="select_timezone"
            value={currentTimezone}
            onChange={onTimeZoneChange}
            label="Select Timezone"
          >
            {timezones.timezonesList.map((timezone) => (
              <MenuItem key={timezone[0]} value={timezone[0]}>
                {timezone[0]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DisplayContainer>
    </Box>
  );
}
