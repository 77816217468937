import { backgrounds } from "contexts/BackgroundContext";
import CircularLoading from "features/common/CircularCenteredLoader";
import { useBodyBackgroundChange } from "hooks";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDisputes, getDisputesAll } from "slices/disputeSlice";
// import Analytics from "./Analytics";
// import Analytics from "./NewAnalytics";
import Analytics from "./NewAnalyticsSubAdmin";
import {
  createCompanyMap,
  createSROMap,
  filterByRange,
  getAverageTimeTaken,
  getNoOfDisputesData,
  getTimeTaken,
  getlenNoOfDisputesData,
  getTimeTakenSRO,
  getNoOfDisputesDataSRO,
  getCompanyActionTaken,
  getSROActionTaken,
} from "./NewAnalytics.utils";

export default function AnalyticsContainer() {
  useBodyBackgroundChange(backgrounds.WHITE);

  const dispatch = useDispatch();

  const [companyData, setcompanyData] = useState([]);
  const [SROData, setSROData] = useState([]);

  const [companyActionTaken, setCompanyActionTaken] = useState([]);
  const [SROActionTaken, setSROActionTaken] = useState([]);

  const [NoOfDisputesCompanyData, setNoOfDisputesCompanyData] = useState([]);
  const [NoOfDisputesSROData, setNoOfDisputesSROData] = useState([]);
  const [TimeTakenCompany, setTimeTakenCompany] = useState([]);
  const [TimeTakenSRO, setTimeTakenSRO] = useState([]);
  const [TimeTakenCompanyandSRO, setTimeTakenCompanyandSRO] = useState([]);
  const [averageTimeCompany, setaverageTimeCompany] = useState(0);
  const [averageTimeSRO, setaverageTimeSRO] = useState(0);
  const [averageTimeCompanySRO, setaverageTimeCompanySRO] = useState(0);

  // const [disputes, setdisputes] = useState()

  const [start, setstart] = useState();
  const [end, setend] = useState();

  const { disputesall, loading } = useSelector(
    (state) => state.disputes,
    shallowEqual
  );

  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  React.useEffect(() => {
    dispatch(getDisputesAll());
  }, []);

  React.useEffect(() => {
    let filteredDisputes = [];
    if (start && end) {
      filteredDisputes = filterByRange(disputesall, start, end);
    } else {
      filteredDisputes = disputesall;
    }
    setcompanyData(createCompanyMap(filteredDisputes));
    setSROData(createSROMap(filteredDisputes));
    setTimeTakenCompanyandSRO(getTimeTaken(filteredDisputes));
  }, [disputesall, start, end]);

  React.useEffect(() => {
    setNoOfDisputesCompanyData(getNoOfDisputesData(companyData));
    setNoOfDisputesSROData(getNoOfDisputesDataSRO(SROData));

    setTimeTakenCompany(getTimeTaken(companyData));
    setTimeTakenSRO(getTimeTakenSRO(SROData));

    setCompanyActionTaken(getCompanyActionTaken(companyData));
    setSROActionTaken(getSROActionTaken(SROData));
  }, [companyData, SROData]);

  React.useEffect(() => {
    setaverageTimeCompany(getAverageTimeTaken(TimeTakenCompany));
    setaverageTimeSRO(getAverageTimeTaken(TimeTakenSRO));
    setaverageTimeCompanySRO(getAverageTimeTaken(TimeTakenCompanyandSRO));
  }, [TimeTakenCompany, TimeTakenSRO.push, TimeTakenCompanyandSRO]);

  if (loading) return <CircularLoading />;

  return (
    <Analytics
      start_Date={start}
      set_start={setstart}
      end_Date={end}
      set_end={setend}
      Nodc={NoOfDisputesCompanyData}
      NodSRO={NoOfDisputesSROData}
      TimeTakenCom={TimeTakenCompany}
      TimeTakenSRO={TimeTakenSRO}
      TimeTakenCompanyandSRO={TimeTakenCompanyandSRO}
      averageTimeCompany={averageTimeCompany}
      averageTimeSRO={averageTimeSRO}
      averageTimeCompanySRO={averageTimeCompanySRO}
      companyActionTaken={companyActionTaken}
      SROActionTaken={SROActionTaken}
      UserId={user.id}
    />
  );
}
