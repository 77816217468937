import { Box, Typography } from '@material-ui/core'
import React from 'react'

function IncorrectPaymentLink() {
  return (
    <Box>
        <Typography variant='h2'>Link is invalid.</Typography>
    </Box>
  )
}

export default IncorrectPaymentLink
