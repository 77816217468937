import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {
  ARBITRATOR,
  ARBITRATOR_RV,
  DEV_WEBNYAY_URL,
  MEDIATOR,
  SECRETARIAT,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import DevSelectPlatform from "features/header/DevSelectPlatform";
import TIAC from "images/TIAC.png";
import WebLogo from "images/TiacWebLogo.png";
import WebnyayLogo from "images/webnyay.svg";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  isArb,
  isArbitrationConference,
  isDev,
  isDifc,
  isHotStar,
  isTiac,
  isSebi,
  isKilumaya,
  isPaytm,
  isStar,
  isMuthoot,
  isMSME,
} from "Routes/Routes";
// import Tiac_DashboardDropDown from "./tiac_DashboardDropdown";
import Tiac_HambergerMenu from "./tiac_HamburgerMenu/Tiac_HambergerMenu";
import Tiac_ProfileDropdown from "./tiac_ProfileDropdown";
import PaytmLogo from "images/paytmbank-logo.png";
import TiacDashboardDropDown from "./TiacDashboardDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#4F4F4F",
  },
  title: {
    flexGrow: 1,

    "& img": {
      width: isMuthoot ? "8rem" : "10rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: isMuthoot ? "10rem" : "7rem",
      },
    },
  },
  difc: {
    flexGrow: 1,

    "& img": {
      width: "7rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: "6rem",
      },
    },
  },
  title1: {
    // flexGrow: 2,

    "& img": {
      width: "10rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: "7rem",
      },
    },
  },
}));

export const removeSessionStorage = () => {
  let t = localStorage.getItem("token");
  sessionStorage.clear();
  sessionStorage.setItem("token", t);
};

const TiacAppHeader = ({ profile }) => {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();

  const token = useSelector((state) => state.login.token);
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === MEDIATOR ||
    profileType === SECRETARIAT ||
    profileType === "guest_arbitrator";
  const loggedIn = token !== "" ? true : false;
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let MenuItems;
  if (loggedIn) {
    MenuItems = (
      <Fragment>
        <Fragment>
          {isProfessionalAccount &&
          !profile.profile.is_tiac_profile &&
          !isHotStar &&
          !isStar &&
          !isMuthoot ? (
            <TiacDashboardDropDown profileType={profileType} />
          ) : (
            <Button
              // color="inherit"
              style={{
                textTransform: "none",
                color:
                  isSebi ||
                  isMSME ||
                  isTiac ||
                  isDifc ||
                  isArb ||
                  isKilumaya ||
                  isPaytm ||
                  isMuthoot
                    ? "black"
                    : "#4F4F4F",
                fontSize: isHotStar || isStar ? "20px" : "1rem",
                fontWeight: isHotStar || isStar ? "400" : "normal",
                fontFamily:
                  isSebi ||
                  isMSME ||
                  isTiac ||
                  isDifc ||
                  isArb ||
                  isKilumaya ||
                  isPaytm ||
                  isMuthoot
                    ? "Satoshi-Bold"
                    : `'Inter',sans-serif`,
              }}
              onClick={() => {
                history.push("/");
                removeSessionStorage();
              }}
            >
              {/* <FormattedMessage id="nav.dashboard" /> */}
              {/* <Typography style={{color : "#4F4F4F"}}>Dashboard</Typography> */}
              Dashboard
            </Button>
          )}
        </Fragment>

        <Box width="1rem"></Box>

        <Tiac_ProfileDropdown profile={profile.profile} />
        <Box width="1rem"></Box>
        {(isTiac || isPaytm) && (
          <div className={classes.title1}>
            <a href="https://webnyaya.com" target="_blank">
              <img
                // className={classes.vectorImage}
                src={WebLogo}
                alt=""
                align="right"
              />
            </a>
          </div>
        )}
      </Fragment>
    );
  } else {
    MenuItems = (
      <>
        {/* <Button
          // color="inherit"
          style={{ textTransform: "none", color: "#4F4F4F", fontSize: "1rem", fontWeight: "normal" }}

          onClick={() => {
            history.push("/login");
          }}
        >

          Dashboard
        </Button> */}

        <Button
          // color="inherit"
          style={{
            textTransform: "none",
            color: "black",
            fontSize: isHotStar || isStar ? "20px" : "1rem",
            fontWeight: isHotStar || isStar ? "400" : "normal",
            fontFamily:
              isTiac || isKilumaya || isArb || isPaytm || isMuthoot
                ? "Satoshi-Medium"
                : `'Inter',sans-serif`,
          }}
          onClick={() => {
            if (isHotStar || isStar || isMuthoot) history.push("/");
            else history.push("/login_tiac");
          }}
        >
          <FormattedMessage id="auth.login" />
        </Button>
        <Box width="1rem"></Box>
        {(isTiac || isPaytm) && (
          <div className={classes.title1}>
            <a href="https://webnyaya.com" target="_blank">
              <img
                // className={classes.vectorImage}
                src={WebLogo}
                alt=""
                align="right"
              />
            </a>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar
          style={
            isSebi ||
            isMSME ||
            isTiac ||
            isDifc ||
            isArb ||
            isKilumaya ||
            isPaytm ||
            isMuthoot
              ? { background: "white", borderBottom: "1px solid #E5E5E5" }
              : { background: "#F2F2F2" }
          }
          variant={isArbitrationConference ? "dense" : "regular"}
        >
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={handleDrawerOpen}
              // color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div
            className={isArbitrationConference ? classes.difc : classes.title}
          >
            {!isHotStar &&
              !isStar &&
              !isArbitrationConference &&
              !isArb &&
              !isKilumaya && (
                <Link to="/">
                  <img
                    className={classes.vectorImage}
                    src={
                      isTiac
                        ? TIAC
                        : isPaytm
                        ? PaytmLogo
                        : isMuthoot
                        ? "/Muthoot_Finance_Logo.svg"
                        : WebnyayLogo
                    }
                    alt="Logo"
                    onClick={() => {
                      removeSessionStorage();
                    }}
                  />
                </Link>
              )}
          </div>

          <Box width="1rem"></Box>
          {isDev &&
            !isPaytm &&
            (window.location.host == DEV_WEBNYAY_URL ||
              window.location.hostname == "localhost") && <DevSelectPlatform />}
          <Box width="2rem"></Box>
          {/* <LanguageMenu /> */}
          {location.pathname.toUpperCase() != "/RFA" &&
            location.pathname != "/resumerfa" &&
            location.pathname != "/old_tiac_form" &&
            location.pathname != "/tiac_claimant" &&
            location.pathname != "/tiac_claimant_lawyer" &&
            location.pathname != "/tiac_respondent" &&
            location.pathname != "/tiac_respondent_lawyer" &&
            location.pathname != "/tiac_upload" &&
            location.pathname != "/tiac_arbitrator" &&
            location.pathname != "/tiac_additionalinfo" &&
            location.pathname != "/tiac_final" &&
            location.pathname != "/tiac_waitingscreen" &&
            !isDifc &&
            !isArbitrationConference &&
            !isHotStar &&
            !isStar &&
            !isMuthoot &&
            !isPaytm && (
              <>
                <div id="google_translate_element"></div>
                <Box width="1rem"></Box>
              </>
            )}

          <Hidden smDown>{MenuItems}</Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Tiac_HambergerMenu
          open={open}
          handleDrawerClose={handleDrawerClose}
          isProfessionalAccount={isProfessionalAccount}
          profileType={profileType}
        />
      </Hidden>
    </div>
  );
};

export default TiacAppHeader;
