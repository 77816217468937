import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { isHotStar, isMuthoot, isRv, isStar } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  footer: {
    flexShrink: "0",
    [theme.breakpoints.down("sm")]: { padding: "1rem 0" },
  },
  footer_text: {
    [theme.breakpoints.down("sm")]: { fontSize: "0.8rem" },
  },
  footer_logo: { [theme.breakpoints.down("sm")]: { fontSize: "1.5rem" } },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

// const links = {
//   "Terms of use": "files/termsAndConditions.pdf",
//   Privacy: "files/wenyayPrivacyPolicy.pdf",
//   "Contact Us": "/contact",
// };

const links = [
  {
    title: <FormattedMessage id="footer.terms" />,
    value: "/files/termsAndConditions.pdf",
  },
  {
    title: <FormattedMessage id="footer.privacy" />,
    value: "/files/wenyayPrivacyPolicy.pdf",
  },
  {
    title: <FormattedMessage id="footer.contact" />,
    value: "/contact",
  },
];

const renderLinks = (links, classes) => {
  // const linksArray = Object.keys(links);
  return links.map((link, index) => {
    let newTab = index < 2 ? true : false;
    return (
      <Grid key={index} item xs={4} sm={4} md={2}>
        <Link
          className={classes.link}
          to={link.value}
          target={newTab ? "_blank" : "_self"}
        >
          <Typography variant="subtitle1" className={classes.footer_text}>
            {link.title}
          </Typography>
        </Link>
      </Grid>
    );
  });
};

export default function Footer() {
  const token = useSelector((state) => state.login.token);
  const classes = useStyles();
  const location = useLocation();
  const pathNameArray = location.pathname.split("/");
  const isChat = pathNameArray[pathNameArray.length - 1] === "chat";
  return (
    <Fragment>
      {!isChat && (
        <Box
          bgcolor="#D9D7D7"
          textAlign="center"
          p="2rem 0"
          className={classes.footer}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} sm={3} md={3}>
              <Typography variant="h4" className="footer_logo">
                <Link to="/">
                  <img
                    src="/Logo_Transparent.png"
                    alt=""
                    style={{ width: "15rem", height: "4.6rem" }}
                  />
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Grid container>
                {renderLinks(links, classes)}{" "}
                {token ? (
                  <Grid item xs={4} sm={4} md={2}>
                    {
                      !isRv && (
                        <Link
                          className={classes.link}
                          to={`${process.env.PUBLIC_URL}/files/webnyayRulesOfArbitration.pdf`}
                          target="_blank"
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.footer_text}
                          >
                            <FormattedMessage id="footer.rules" />
                          </Typography>
                        </Link>
                      )
                      // : (
                      //   <Link
                      //     className={classes.link}
                      //     to="/calculator/1"
                      //     target="_blank"
                      //   >
                      //     <Typography
                      //       variant="subtitle1"
                      //       className={classes.footer_text}
                      //     >
                      //       Calculator
                      //     </Typography>
                      //   </Link>
                      // )
                    }
                  </Grid>
                ) : (
                  <span></span>
                  // <Grid item xs={4} sm={4} md={2}>
                  //   {isRv && (
                  //     <Link
                  //       className={classes.link}
                  //       to="/calculator/1"
                  //       target="_blank"
                  //     >
                  //       <Typography
                  //         variant="subtitle1"
                  //         className={classes.footer_text}
                  //       >
                  //         Calculator
                  //       </Typography>
                  //     </Link>
                  //   )}
                  // </Grid>
                )}
                {(isHotStar || isStar || isMuthoot) && (
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      variant="subtitle1"
                      className={classes.footer_text}
                      // style={{marginTop:'1rem'}}
                    >
                      Support: techsupport@webnyay.in
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}
