import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import CustomReactTooltip from "features/CustomToolTip/CustomToolTip";
import React from "react";
import rvnotificationOff from "images/rvnotificationOff.svg";
import rvnotificationOn from "images/rvnotificationOn.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  notify: {
    fontSize: "20px",
    color: "#4F4F4F",
    lineHeight: "36px",
    fontFamily: "Roboto",
    textAlign: "center",
    marginTop: "10px",
    textTransform: "capitalize",
  },
  alertSelected: {
    padding: "0.3rem",
    background: "#E0E0E0",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  alertUnSelected: {
    padding: "0.3rem",
    cursor: "pointer",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  alertOnHeading: {
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 600,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
  alertOnText: {
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 300,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
}));

function NotifyOthers({alert, setAlert}) {
  const classes = useStyles();
  return (
    <>
      <Box height="1rem" />
      <Grid container justify="flex-end" className={classes.container}>
        <Grid item className={classes.notify} justify="center">
          <Typography>Notify others?</Typography>
        </Grid>

        <Box width="1rem" />
        <Grid
          item
          className={
            alert === "on" ? classes.alertSelected : classes.alertUnSelected
          }
          onClick={() => {
            if (alert !== "on") setAlert("on");
          }}
        >
          <img
            src={rvnotificationOn}
            alt="alert on"
            data-tip
            data-for="alerton"
          />
          <CustomReactTooltip id="alerton">
            <Box width="12rem">
              <Typography className={classes.alertOnHeading}>
                High-Priority Documents?
              </Typography>
              <Typography className={classes.alertOnText}>
                Notify the Parties involved in this Arbitration by an Email
                update
              </Typography>
            </Box>
          </CustomReactTooltip>
        </Grid>
        <Grid
          item
          className={
            alert === "off" ? classes.alertSelected : classes.alertUnSelected
          }
          onClick={() => {
            if (alert !== "off") setAlert("off");
          }}
          style={{ marginLeft: "1rem" }}
        >
          <img
            src={rvnotificationOff}
            alt="alert off"
            data-tip
            data-for="alertoff"
          />

          <CustomReactTooltip id="alertoff">
            <Box width="12rem">
              <Typography className={classes.alertOnText}>
                Upload documents on the platform without sending an Email update
                to the Parties involved.
              </Typography>
            </Box>
          </CustomReactTooltip>
        </Grid>
      </Grid>
    </>
  );
}

export default NotifyOthers;
