import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import SingleDisputeDetails from "../SingleDisputeDetails";
import ClaimantWaiting from "./ClaimantWaiting";
import ClaimantDecision from "./ClaimantDecision";
import DisputeDisplay from "features/singleDispute/DisputeDisplay";
import {
  RESPONDENT_COMPLETE,
  MEDIATION,
  ADJUDICATION,
  CLAIMANT_COMPLETE,
  COMPLETE,
  RESPONDENT_INPROGRESS,
  IO_COMPLETE,
  MD_COMPLETE,
} from "app/constants";
import ClaimandAdjudicationDecision from "./ClaimantAdjudicationDecision/ClaimandAdjudicationDecision";
import DisputeDisplayHotstar from "../DisputeDisplay/DisputeDisplayHotstar";
import { isCDR, isHotStar, isMuthoot, isStar, isVikalp } from "Routes/Routes";
import DisputeDetailsMuthoot from "features/disputes/MuthootDisputes/DisputeDetailsMuthoot";

export default function ClaimantFlowSteps({
  activeDispute,
  activeCase,
  profile,
}) {
  function getStepContent(activeDispute, activeCase, profile) {
    switch (activeDispute.status) {
      case "new":
        if (activeCase.case_status !== "review_complete") {
          return <SingleDisputeDetails profile={profile} />;
        } else {
          return <ClaimantWaiting disputeName={activeDispute.name} />;
        }
      case RESPONDENT_INPROGRESS:
        return <ClaimantWaiting disputeName={activeDispute.name} />;

      case RESPONDENT_COMPLETE:
        if (activeDispute.flow_type === MEDIATION) {
          return (
            <ClaimantDecision
              activeDispute={activeDispute}
              activeCase={activeCase}
            />
          );
        } else if (activeDispute.flow_type === ADJUDICATION) {
          return (
            <ClaimandAdjudicationDecision
              activeDispute={activeDispute}
              activeCase={activeCase}
            />
          );
        } else {
          return <ClaimantWaiting disputeName={activeDispute.name} />;
        }

      case CLAIMANT_COMPLETE:
      case IO_COMPLETE:
      case MD_COMPLETE:
        if (isHotStar || isStar || isCDR || isVikalp) {
          return (
            <DisputeDisplayHotstar
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          );
        } else if(isMuthoot) {
          return (
            <DisputeDetailsMuthoot
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile} 
            />
          )
        }
        return (
          <DisputeDisplay
            activeDispute={activeDispute}
            activeCase={activeCase}
          />
        );
      case COMPLETE:
        return "done";

      default:
        if (isHotStar || isStar || isCDR || isVikalp) {
          return (
            <DisputeDisplayHotstar
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile}
            />
          );
        } else if(isMuthoot) {
          return (
            <DisputeDetailsMuthoot
              activeDispute={activeDispute}
              activeCase={activeCase}
              profile={profile} 
            />
          )
        }
        return (
          <DisputeDisplay
            activeDispute={activeDispute}
            activeCase={activeCase}
          />
        );
    }
  }

  return (
    <React.Fragment>
      {getStepContent(activeDispute, activeCase, profile)}
    </React.Fragment>
  );
}
