import React from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography'
import downSvg from '../../images/dataanalytics/down.svg';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem",
    fontFamily:"Inter"

  },
  innerContainer:{
    padding: "1rem",
    border: "1px solid #828282",
    borderRadius:"4px",
  }
}))



export default function CommonStatContainer({ heading, children }) {
  const classes = useStyles();
  return (
    <Box style={{height:"100%"}}  className={classes.container} >
      <Box style={{height:"90%"}} className={classes.innerContainer} >
      <Grid container justify='center' alignItems='center' >
        <Grid item md="6">
          <Typography style={{ fontSize: "18px", color: "#1F2937", fontWeight: "700", lineHeight: "26px",fontFamily:"Inter" }}>
            {heading}

          </Typography>
        </Grid>


        <Grid item style={{ padding: "10px" }}>
          <Button
            style={{
              marginTop: "0.5rem",
              width: "100%",
              background: "#E0E0E0",
              borderRadius: "4px",
              border:"1px solid #1F2937",
              color: "#1F2937",
              textTransform: "none",
              padding: "10px",
              "&:hover": {
                background: "#4F4F4F",
              },
            }}
            onClick={(e) => {
              //       handleClick(e);
            }}
          >
            Mar 21 &nbsp;&nbsp;&nbsp;&nbsp;
            <ArrowDropDownIcon />
          </Button>
        </Grid>
      </Grid>
      <Box>
        {children}
      </Box>

      </Box>


    </Box>

  )
}
