import { Grid, Step, StepButton, Stepper } from "@material-ui/core";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isOtpRequiredInTiac } from "Routes/Routes";
// import AdditionalInfoPage from "../AdditionalPage";
import AdditionalInfoPageWithoutOtp from "../AdditionalPage/AdditionalPageWithoutOtp";
import ArbitratorPage from "../ArbitratorPage";
import ClaimantLawyerPage from "../ClaimantLawyerPage";
import instance from "api/globalaxios";
import ClaimantPage from "../ClaimantPage";
import RespLawyerPage from "../RespondentLawyerPage";
import RespondentPage from "../RespondentPage";
import UploadPage from "../UploadPage";
import {
  getDisputeTiac,
  stepsFiledByClaimant,
  stepsFiledByClaimantCounsel,
} from "./Form";
import React from "react";


async function getQuestionsForAll(email) {
  return await instance({
    method: "get",
    url: "/newtiacanonymousquestions",
    params: {
      email: email,
    },
  });
}

const OldFormContainerNew = () => {
  const history = useHistory();
  const location = useLocation();
  const [questionsForAll, setQuestionsForAll] = useState({});
  let searchParams = new URLSearchParams(location.search);
  const queryEmail = searchParams.get("email");
  const queryDisputeId = searchParams.get("dispute_id");
  const queryIsActiveStepNeedsToBeCalculated =
    searchParams.get("find_active_step");
  const queryRandomNo = searchParams.get("random");
  useEffect(() => {
    if (queryRandomNo) {
      setSubmitDetailsSignal(false);
      setActiveStep(parseInt(localStorage.getItem("activeStep")));
      setApiLoading(false);
      history.push("/resumerfa2");
    }
  }, [queryRandomNo, location]);

  useEffect(() => {
    if (queryEmail) localStorage.setItem("email", queryEmail);
    if (queryDisputeId) localStorage.setItem("dispute_id", queryDisputeId);
  }, [queryEmail, queryDisputeId]);

  let localEmail = queryEmail ? queryEmail : localStorage.getItem("email");
  let localDisputeId = queryDisputeId
    ? queryDisputeId
    : localStorage.getItem("dispute_id");
  let localActiveStep = localStorage.getItem("activeStep");

  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [filedByProfileType, setFiledByProfileType] = useState();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(
    localActiveStep ? parseInt(localActiveStep) : 0
  );
  const [completedSteps, setCompletedSteps] = useState({});
  const [submitDetailsSignal, setSubmitDetailsSignal] = useState(false);

  const handleStepClick = (index) => {
    localStorage.setItem("activeStep", index);
    setSubmitDetailsSignal(true);
    setApiLoading(true);
  };

  useEffect(() => {
    getDisputeTiac(localEmail, localDisputeId).then((res) => {
      let stepsCompleted = {};
      if (res.data.results[0].created_by.profile_type == "general_rv") {
        setSteps(stepsFiledByClaimant);
        if (queryIsActiveStepNeedsToBeCalculated) {
          if (!res.data.results[0].rv_claimant_choose_step_complete) {
          } else if (!res.data.results[0].rv_claimant_lawyer_step_complete) {
            setActiveStep(1);
          } else if (!res.data.results[0].rv_respondent_choose_step_complete) {
            setActiveStep(2);
          } else if (
            res.data.results[0].tiac_dispute_step == "upload_complete"
          ) {
            setActiveStep(5);
          } else if (
            res.data.results[0].tiac_dispute_step == "arbitrator_complete"
          ) {
            setActiveStep(6);
          } else if (!res.data.results[0].rv_respondent_lawyer_step_complete) {
            setActiveStep(3);
          } else {
            setActiveStep(4);
          }
        }
        if (res.data.results[0].rv_claimant_choose_step_complete) {
          stepsCompleted[0] = true;
        }
        if (res.data.results[0].rv_claimant_lawyer_step_complete) {
          stepsCompleted[1] = true;
        }
      } else {
        setSteps(stepsFiledByClaimantCounsel);
        if (queryIsActiveStepNeedsToBeCalculated) {
          if (!res.data.results[0].rv_claimant_lawyer_step_complete) {
          } else if (!res.data.results[0].rv_claimant_choose_step_complete) {
            setActiveStep(1);
          } else if (!res.data.results[0].rv_respondent_choose_step_complete) {
            setActiveStep(2);
          } else if (
            res.data.results[0].tiac_dispute_step == "upload_complete"
          ) {
            setActiveStep(5);
          } else if (
            res.data.results[0].tiac_dispute_step == "arbitrator_complete"
          ) {
            setActiveStep(6);
          } else if (!res.data.results[0].rv_respondent_lawyer_step_complete) {
            setActiveStep(3);
          } else {
            setActiveStep(4);
          }
        }
        if (res.data.results[0].rv_claimant_lawyer_step_complete) {
          stepsCompleted[0] = true;
        }
        if (res.data.results[0].rv_claimant_choose_step_complete) {
          stepsCompleted[1] = true;
        }
      }
      if (res.data.results[0].rv_respondent_choose_step_complete) {
        stepsCompleted[2] = true;
      }
      if (res.data.results[0].rv_respondent_lawyer_step_complete) {
        stepsCompleted[3] = true;
      }
      if (res.data.results[0].tiac_dispute_step == "upload_complete") {
        stepsCompleted[4] = true;
      }
      if (res.data.results[0].tiac_dispute_step == "arbitrator_complete") {
        stepsCompleted[4] = true;
        stepsCompleted[5] = true;
      }
      if (localStorage.getItem("arbi_step")) {
        stepsCompleted[5] = true;
      }
      if (res.data.results[0].tiac_dispute_step == "additional_info_complete") {
        stepsCompleted[4] = true;
        stepsCompleted[5] = true;
        stepsCompleted[6] = true;
      }
      setFiledByProfileType(res.data.results[0].created_by.profile_type);
      setCompletedSteps(stepsCompleted);
      setLoading(false);
    });
  }, [
    localEmail,
    localDisputeId,
    queryIsActiveStepNeedsToBeCalculated,
    activeStep,
  ]);

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "auto",
      },
      500
    );
    localStorage.setItem("activeStep", activeStep);
  }, [activeStep]);

  useEffect(() => {
    getQuestionsForAll(localEmail).then((res)=> {
      setQuestionsForAll(res.data);
    },
    (error) => {
      console.log("err", error);
    })
  }, []);

  return (
    <>
      <Grid container direction="row">
        <Grid item sm={3} xs={12}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            nonLinear
            style={{ background: "none", padding: "50px 15px" }}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completedSteps[index]}>
                <StepButton
                  color="inherit"
                  onClick={() => {
                    handleStepClick(index);
                  }}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {apiLoading && <CircularCenteredLoader />}
        </Grid>
        {loading ? (
          <CircularCenteredLoader />
        ) : (
          <Grid item sm={9} xs={12}>
            {((activeStep == 0 && filedByProfileType == "general_rv") ||
              (activeStep == 1 && filedByProfileType != "general_rv")) && (
              <ClaimantPage
                proceed={activeStep == 0}
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
              />
            )}
            {((activeStep == 0 && filedByProfileType != "general_rv") ||
              (activeStep == 1 && filedByProfileType == "general_rv")) && (
              <ClaimantLawyerPage
                proceed={activeStep == 0}
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
              />
            )}
            {activeStep == 2 && (
              <RespondentPage
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
              />
            )}
            {activeStep == 3 && (
              <RespLawyerPage
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
              />
            )}
            {activeStep == 4 && (
              <UploadPage
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
              />
            )}
            {activeStep == 5 && (
              <ArbitratorPage
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
                completedSteps={completedSteps}
              />
            )}
            {activeStep == 6 && isOtpRequiredInTiac ? (
              <AdditionalInfoPageWithoutOtp
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
                isAllPreviosStepsCompleted={
                  Object.keys(completedSteps).length >= 6
                }
                questionsForAll={questionsForAll?.additional_page_questions}
              />
            ) : activeStep == 6 && !isOtpRequiredInTiac ? (
              <AdditionalInfoPageWithoutOtp
                setActiveStep={setActiveStep}
                submitDetailsSignal={submitDetailsSignal}
                isAllPreviosStepsCompleted={
                  Object.keys(completedSteps).length >= 6
                }
                questionsForAll={questionsForAll?.additional_page_questions}
              />
            ) : null}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OldFormContainerNew;
