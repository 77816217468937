import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
  makeStyles
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { scrollPageToTop } from "Routes/Routes";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import DownloadSvg from "images/download.svg";
import React, { useEffect, useState } from "react";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  tableHead: {
    color: "#000",
    fontSize: "15px",
    fontWeight: "500",
    wordBreak: "break-word",
  },
  tableData: {
    color: "black",
    lineHeight: "100%",
    fontSize: "13px",
  },
  tableRow: {
    padding: "16px",
    borderBottom: "1px solid #E5E5E5",
    textAlign: "left",
    "&:hover": {
      backgroundColor: "#f7f7f7",
    },
  },
}));

export const downloadInvoice = async (id) => {
  try {
    const response = await instance({
      method: "get",
      url: `/surepay/download_invoice/${id}/`,
    });
    window.location.href = response?.data?.url;
  } catch (err) {
    console.log(err);
  }
};

export default function InvoiceList({ profile }) {
  const classes = useStyles();
  const [invoiceList, setInvoiceList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  useEffect(() => {
    getInvoiceList(1);
  }, []);

  const getInvoiceList = async (page) => {
    try {
      setLoadingInvoices(true);
      const response = await instance({
        method: "get",
        url: `/surepay/invoice_paymentlink_dashboard/?page=${page}`,
      });
      const count = response?.data?.count;
      const t = parseInt(count / 100);
      const r = count % 100;
      const total = t + (r > 0 ? 1 : 0);
      setTotalPages(total);
      setInvoiceList(response?.data?.results);
      setLoadingInvoices(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (event, value) => {
    if (page === value) {
      return;
    }
    scrollPageToTop();
    setPage(value);
    getInvoiceList(value);
  };

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" direction="column">
        <Grid item>
          <Grid
            container
            justify="space-between"
            direction="row"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography className={classes.heading}>Invoices</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.username}>
                {profile.user.first_name} {profile.user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Box height="5rem" />
          <Box>
            <Box style={{ padding: "1rem", textAlign: "left" }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.tableHead}>Name</Typography>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.tableHead}>
                    Date of payment link generation
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.tableHead}>
                    Date of payment initiation
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.tableHead}>Case ID</Typography>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Typography className={classes.tableHead}>
                    Invoice Number
                  </Typography>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <Typography className={classes.tableHead}>
                    Payment status
                  </Typography>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <Typography className={classes.tableHead}></Typography>
                </Grid>
              </Grid>
            </Box>
            <Paper
              style={{
                boxShadow: "none",
                border: "1px solid #E5E5E5",
                borderRadius: "0",
              }}
            >
              {loadingInvoices ? (
                <Box height="4rem">
                  <CircularCenteredLoader />
                </Box>
              ) : (
                invoiceList.map((invoice) => {
                  return (
                    <Grid
                      container
                      key={invoice.id}
                      alignItems="center"
                      spacing={{ xs: 1, sm: 2 }}
                      className={classes.tableRow}
                    >
                      <Grid item xs={2} sm={2}>
                        <Typography className={classes.tableData}>
                          {invoice.full_name_of_user}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Typography className={classes.tableData}>
                          {/* date of payment link generation */}
                          {invoice.date_and_time
                            ? formatDate(invoice.date_and_time)
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Typography className={classes.tableData}>
                          {/* date of payment initiation */}
                          {invoice.date_of_payment_initiation
                            ? formatDate(invoice.date_of_payment_initiation)
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Typography className={classes.tableData}>
                          {invoice.disputeId}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Typography className={classes.tableData}>
                          {invoice.invoice_number}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <Typography className={classes.tableData}>
                          {invoice.payment_status}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        {invoice.invoice_id && (
                          <IconButton
                            style={{
                              padding: 0,
                            }}
                            onClick={() => downloadInvoice(invoice.invoice_id)}
                          >
                            <Typography className={classes.tableData}>Download</Typography>
                            <img src={DownloadSvg} alt="download invoice" style={{marginLeft: "8px"}}/>
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  );
                })
              )}
            </Paper>
            <Box style={{ padding: "16px" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
