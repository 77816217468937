import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Menu,
  Paper,
  RadioGroup,
  Typography,
  IconButton,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import instance from "api/globalaxios";
import Axios from "axios";
import { format } from "date-fns";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import DeletedPopup from "features/common/Popup/DeletedPopup";
import DonePopup from "features/common/Popup/DonePopup";
import POPUP from "features/common/Popup/Popup";
import { StyledRadio } from "features/TempTiacFormV2/ArbitratorPage/questions";
import AnalyticsVector from "images/Hotstar/Analytics.svg";
import CalenderVector from "images/Hotstar/Calender.svg";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { typeChoices } from "../DisputesContainerHotstar";
import DisputeRow from "./DisputeRow";
import { ExpandButtonOnHover } from "./ExpandButtonOnHover";
import { GradientBox } from "./GradientBox";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { isMuthoot } from "Routes/Routes";

export async function changeDisputeState(disputeId, state) {
  return await instance.get(
    `/changedisputestatehotstar/${disputeId}/${state}/`
  );
}
export async function deleteDisputeapi(disputeId) {
  return await instance.delete(`/deletedisputehotstar/${disputeId}/`);
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5rem 3.5rem 5rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "3rem 1rem",
    },
  },
  sidebar: {
    padding: "1rem",
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem",
    },
  },
  bigheading: {
    color: isMuthoot ? 'black' : "#4F4F4F",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "36px",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  searchbar: {
    border: "none",
    background: "#DADBDB",
    width: "-webkit-fill-available",
    color: "#828282",
    fontSize: "14px",
    outline: "none",
    padding: "0rem 1.5rem",
    height: "42px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      height: "34px",
      padding: "0rem 0.7rem",
    },
  },
  typeButton: {
    textTransform: "none",
    border: "1px solid #4F4F4F",
    borderRadius: "0",
    padding: "0 2rem",
    color: "#4F4F4F",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18px",
    height: "42px",

    [theme.breakpoints.down("xs")]: {
      height: "34px",
      fontSize: "14px",
    },
  },
  typeButtonBlue: {
    textTransform: "none",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    background: isMuthoot ? theme.palette.primary.main : "#2F80ED",
    borderRadius: "0",
    padding: "0 2rem",
    color: "#fff",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18px",
    height: "42px",
    "&:hover": {
      background: "#FFFFFF",
      color: isMuthoot ? theme.palette.primary.main : "#2F80ED",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      height: "34px",
      fontSize: "14px",
    },
  },
  typechoices: {
    color: "#333333",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "capitalize",
    paddingRight: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  typeFormControl: {
    margin: 0,
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  spaceBox: {
    height: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "2rem",
    },
  },
  griditem: {
    padding: "8px",
    [theme.breakpoints.down("xs")]: {
      padding: "4px",
    },
  },
  title: {
    // color: "#333333",
    // padding: "1rem",
    fontFamily: `"Inter","sans-serif"`,

    border: "none",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    // whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      lineHeight: "1.2rem",
    },
  },
  value: {
    border: "none",
    // padding: "1rem",
    fontFamily: `"Inter",sans-serif`,
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "400",
    textTransform: "capitalize",
    "&:hover": {
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      lineHeight: "1.2rem",
    },
  },
  titlePaper: {
    padding: "1.5rem",
    boxShadow: "none",
    width: "100%",
    backgroundColor: "initial",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  valuePaper: {
    padding: "1.5rem",
    cursor: "pointer",
    boxShadow: "none",
    width: "100%",
    border: "1px solid #E0E0E0",
    "&:hover": {
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  menuwidth: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "130px",
    },
  },
  alginCenter: {
    textAlign: "center",
  },
}));

// Side Bar ......
const SideBar = ({ history }) => {
  return (
    <Grid container spacing={1}>
      <Grid item md={12} sm={3} xs={6}>
        <ExpandButtonOnHover
          history={history}
          vector={AnalyticsVector}
          vector_text={"Analytics img"}
          buttonText={"Analytics"}
          link={"/analytics"}
        />
      </Grid>
      <Grid item md={12} sm={3} xs={6}>
        <ExpandButtonOnHover
          history={history}
          vector={CalenderVector}
          vector_text={"Calender img"}
          buttonText={"Calender"}
          link={"/events"}
        />
      </Grid>
    </Grid>
  );
};

export function isValidMonth(value) {
  if (
    value == "january" ||
    value == "february" ||
    value == "march" ||
    value == "april" ||
    value == "may" ||
    value == "june" ||
    value == "july" ||
    value == "august" ||
    value == "september" ||
    value == "october" ||
    value == "november" ||
    value == "december"
  ) {
    return true;
  }
  return false;
}
export function isValidNumber(value) {
  return !isNaN(value);
}
export function isValidDate(dateObject) {
  return new Date(dateObject).toString() !== "Invalid Date";
}

export default function Disputes({
  count,
  disputesLoading,
  setType,
  type,
  disputes,
  reload,
  totalPages,
  currentPage,
  setCurrentPage,
  getUserDataAnotherPage,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorType, setAnchorType] = React.useState(null);
  const handleClickType = (event) => {
    setAnchorType(event.currentTarget);
  };
  const handleCloseType = () => {
    setAnchorType(null);
  };

  const handleTypeChange = (newType) => {
    setType(newType);
    setSelectedDisputes([]);
    handleCloseType();
    setSearchText("");
    sessionStorage.setItem("hotstar-type", newType);
    reload(newType);
  };

  const handleClickRegistered = () => {
    setType(typeChoices[0]);
    setSelectedDisputes([]);
    setSearchText("");
    sessionStorage.setItem("hotstar-type", typeChoices[0]);
    reload(typeChoices[0]);
  };
  const handleClickClosed = () => {
    setType(typeChoices[1]);
    setSelectedDisputes([]);
    setSearchText("");
    sessionStorage.setItem("hotstar-type", typeChoices[1]);
    reload(typeChoices[1]);
  };
  const handleClickSerious = () => {
    setType(typeChoices[2]);
    setSelectedDisputes([]);
    setSearchText("");
    sessionStorage.setItem("hotstar-type", typeChoices[2]);
    reload(typeChoices[2]);
  };
  const handleClickFrivolous = () => {
    setType(typeChoices[3]);
    setSelectedDisputes([]);
    setSearchText("");
    sessionStorage.setItem("hotstar-type", typeChoices[3]);
    reload(typeChoices[3]);
  };

  const [selectedDisputes, setSelectedDisputes] = useState([]);
  const addDispute = (id) => {
    let res = [];
    selectedDisputes.forEach((i) => {
      res.push(i);
    });
    res.push(id);
    setSelectedDisputes(res);
  };
  const removeDispute = (id) => {
    let res = [];
    selectedDisputes.forEach((i) => {
      res.push(i);
    });
    let index = res.indexOf(id);
    if (index > -1) {
      res.splice(index, 1);
    }
    setSelectedDisputes(res);
  };

  const [searchText, setSearchText] = useState("");
  const [disputesBySearch, setDisputesBySearch] = useState(disputes);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [cancelToken, setCancelToken] = useState();
  let firstname = "";
  let lastname = "";

  const debouceSearch = useCallback(debounce(search, 500), [disputes]);

  useEffect(() => {
    setDisputesBySearch(disputes);
  }, [disputes]);

  function search(value, type) {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    let x = Axios.CancelToken.source();
    setCancelToken(x);

    if (value.trim() != "") {
      let ar = value.trim().split("-");
      let date = new Date(ar[1] + "/" + ar[0] + "/" + ar[2]);
      if (isValidDate(date)) {
        dispatch(gethotstardatabydate(date, x, type));
        setLoadingSearch(false);
      } else if (isValidNumber(value.trim())) {
        dispatch(gethotstardatabyDisputeId(value.trim(), x, type));
        setLoadingSearch(false);
      } else if (isValidMonth(value.trim().toLowerCase())) {
        dispatch(gethotstardatabyMonth(value.trim().toLowerCase(), x, type));
        setLoadingSearch(false);
      } else {
        dispatch(gethotstardatabyname(value.trim(), x, type));
        setLoadingSearch(false);
      }
    } else {
      setDisputesBySearch(disputes);
      setLoadingSearch(true);
    }
  }

  function gethotstardatabyname(name, cancelToken, type) {
    return async (dispatch) => {
      try {
        const answer_array = name.split(" ");
        firstname = answer_array[0];
        lastname = answer_array[1];
        if (firstname != "" && (lastname == "" || !lastname)) {
          const response2 = await instance.get(
            `/api/HotstarsearchView1/${firstname}/${null}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response2.data);

          return response2.data;
        } else {
          const response3 = await instance.get(
            `/api/HotstarsearchView1/${firstname}/${lastname}/?type=${type}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(response3.data);
          return response3.data.profiles;
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabydate(date, cancelToken, type) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/HotstarsearchbydateView/${format(
            date,
            "dd-MM-yyyy"
          )}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputesBySearch(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabyDisputeId(value, cancelToken, type) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/HotstarsearchViewByDisputeId/${value}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputesBySearch(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function gethotstardatabyMonth(value, cancelToken, type) {
    return async (dispatch) => {
      var months = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ];
      let index = months.indexOf(value);
      try {
        const response = await instance.get(
          `/api/HotstarsearchbyMonth/${index}/?type=${type}`,
          { cancelToken: cancelToken.token }
        );
        setDisputesBySearch(response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const handleClickDelete = () => {
    setOpenDeletePopup(true);
  };
  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };
  const handleDelete = () => {
    handleCloseDelete();

    let total = selectedDisputes.length;
    let count = 0;
    selectedDisputes.forEach((disputeid) => {
      deleteDisputeapi(disputeid)
        .then((res) => {
          count += 1;
          if (count == total) {
            reload();
            setSelectedDisputes([]);
            handleOpenDeletedGifPopup();
          }
        })
        .catch((error) => {
          console.log("error.response.data", error.response.data);
        });
    });
    if (count == total) handleOpenDeletedGifPopup();
  };
  const [openFrivolousPopup, setopenFrivolousPopup] = React.useState(false);
  const handleClickMarkAsFrivolous = () => {
    setopenFrivolousPopup(true);
  };
  const handleCloseFrivolous = () => {
    setopenFrivolousPopup(false);
  };
  const handleFrivolous = () => {
    handleCloseFrivolous();
    let total = selectedDisputes.length;
    let count = 0;
    selectedDisputes.forEach((disputeid) => {
      changeDisputeState(disputeid, "frivolous")
        .then((res) => {
          count += 1;
          if (count == total) {
            reload();
            setSelectedDisputes([]);
            handleOpenFrivolousGifPopup();
          }
        })
        .catch((error) => {
          console.log("error.response.data", error.response.data);
          if (error?.response?.data?.status == "not done") {
            alert("This dispute is already frivolous: " + disputeid);
          }
        });
    });
    if (count == total) handleOpenFrivolousGifPopup();
  };
  const [openSeriousPopup, setopenSeriousPopup] = React.useState(false);
  const handleClickMarkAsSerious = () => {
    setopenSeriousPopup(true);
  };
  const handleCloseSerious = () => {
    setopenSeriousPopup(false);
  };
  const handleSerious = () => {
    handleCloseSerious();
    let total = selectedDisputes.length;
    let count = 0;
    selectedDisputes.forEach((disputeid) => {
      changeDisputeState(disputeid, "serious")
        .then((res) => {
          count += 1;
          if (count == total) {
            reload();
            setSelectedDisputes([]);
            handleOpenSeriousGifPopup();
          }
        })
        .catch((error) => {
          console.log("error.response.data", error.response.data);
          if (error?.response?.data?.status == "not done") {
            alert("This dispute is already serious: " + disputeid);
          }
        });
    });
    if (count == total) handleOpenSeriousGifPopup();
  };
  const [openDeletedGifPopup, setOpenDeletedGifPopup] = React.useState(false);
  const handleOpenDeletedGifPopup = () => {
    setOpenDeletedGifPopup(true);
  };
  const handleCloseDeletedGifPopup = () => {
    setOpenDeletedGifPopup(false);
  };
  const [openSeriousGifPopup, setOpenSeriousGifPopup] = React.useState(false);
  const handleOpenSeriousGifPopup = () => {
    setOpenSeriousGifPopup(true);
  };
  const handleCloseSeriousGifPopup = () => {
    setOpenSeriousGifPopup(false);
  };
  const [openFrivolousGifPopup, setOpenFrivolousGifPopup] =
    React.useState(false);
  const handleOpenFrivolousGifPopup = () => {
    setOpenFrivolousGifPopup(true);
  };
  const handleCloseFrivolousGifPopup = () => {
    setOpenFrivolousGifPopup(false);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item md={2} xs={10} className={classes.sidebar}>
          <SideBar history={history} />
        </Grid>
        <Grid item md={10} xs={12} className={classes.content}>
          <Grid container spacing={3}>
            <GradientBox
              color="blue"
              headingText="Grievances Registered"
              leftText="Registered"
              leftCount={count.registered.registered}
              handleOnClickLeft={handleClickRegistered}
              rightText="Closed"
              rightCount={count.registered.closed}
              handleOnClickRight={handleClickClosed}
            />
            <GradientBox
              color="green"
              headingText="Grievances Received"
              leftText="Serious"
              leftCount={count.received.serious}
              handleOnClickLeft={handleClickSerious}
              rightText="Frivolous"
              rightCount={count.received.frivolous}
              handleOnClickRight={handleClickFrivolous}
            />
          </Grid>
          <Box className={classes.spaceBox} />
          {disputesLoading ? (
            <CircularCenteredLoader />
          ) : (
            <>
              <Typography className={classes.bigheading}>
                {type} grievances
              </Typography>
              <Grid container>
                <Grid
                  item
                  xl={5}
                  lg={7}
                  md={9}
                  sm={12}
                  xs={12}
                  className={classes.griditem}
                  style={{ paddingLeft: 0 }}
                >
                  <input
                    type="text"
                    placeholder="Search by Complainant's Name, Date (DD-MM-YYYY) or Grievance ID"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      debouceSearch(e.target.value, type);
                    }}
                    onBlur={(e) => {
                      e.target.placeholder =
                        "Search by Complainant's Name, Date (DD-MM-YYYY) or Grievance ID";
                    }}
                    className={classes.searchbar}
                    onFocus={(e) => (e.target.placeholder = "")}
                  />
                </Grid>
                <Grid item className={classes.griditem}>
                  <Button
                    aria-controls="type-menu"
                    aria-haspopup="true"
                    onClick={handleClickType}
                    className={
                      Boolean(anchorType)
                        ? classes.typeButtonBlue
                        : classes.typeButton
                    }
                    endIcon={
                      <KeyboardArrowDownIcon
                        style={
                          Boolean(anchorType)
                            ? { color: "#fff" }
                            : { color: "#4F4F4F" }
                        }
                      />
                    }
                  >
                    Type
                  </Button>
                  <Menu
                    id="type-menu"
                    anchorEl={anchorType}
                    keepMounted
                    open={Boolean(anchorType)}
                    onClose={handleCloseType}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    style={{ padding: 0 }}
                  >
                    <RadioGroup
                      value={type}
                      onChange={(e) => {
                        handleTypeChange(e.target.value);
                      }}
                    >
                      {typeChoices.map((item, index) => {
                        return (
                          <FormControlLabel
                            className={classes.typeFormControl}
                            key={index}
                            label={
                              <Typography className={classes.typechoices}>
                                {item}
                              </Typography>
                            }
                            value={item}
                            control={<StyledRadio />}
                          ></FormControlLabel>
                        );
                      })}
                    </RadioGroup>
                  </Menu>
                </Grid>
                {/* Show these buttons on selecting disputes only */}
                {selectedDisputes.length > 0 &&
                (type == "serious" || type == "frivolous") ? (
                  <>
                    <Grid item className={classes.griditem}>
                      <Button
                        className={classes.typeButtonBlue}
                        onClick={handleClickDelete}
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Grid item className={classes.griditem}>
                      <Button
                        className={classes.typeButtonBlue}
                        onClick={
                          type == "serious"
                            ? handleClickMarkAsFrivolous
                            : handleClickMarkAsSerious
                        }
                      >
                        {`Mark as ${
                          type == "serious" ? "frivolous" : "serious"
                        }`}
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              {/* Disputes list starts from here */}
              <Box className={classes.spaceBox}></Box>
              <Grid container style={{ rowGap: "8px" }}>
                <Paper className={classes.titlePaper} square>
                  <Grid container spacing={2} justifyContent="flex-start">
                    {type === "serious" || type === "frivolous" ? (
                      <>
                        <Grid
                          item
                          xs
                          sm={1}
                          className={classes.alginCenter}
                        ></Grid>
                        <Grid item xs sm={1}>
                          <Typography className={classes.title}>
                            G. ID
                          </Typography>
                        </Grid>
                        <Grid item xs sm={3}>
                          <Typography className={classes.title}>
                            COMPLAINANT
                          </Typography>
                        </Grid>
                        <Grid item xs sm={2}>
                          <Typography className={classes.title}>
                            DATE OF FILING
                          </Typography>
                        </Grid>
                        <Grid item xs sm={2}>
                          <Typography className={classes.title}>
                            REGISTRATION
                          </Typography>
                        </Grid>
                        <Grid item xs sm={2}>
                          <Typography className={classes.title}>
                            VIEW GRIEVANCE
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs
                          sm={1}
                          className={classes.alginCenter}
                        ></Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs sm={1}>
                          <Typography className={classes.title}>
                            G. ID
                          </Typography>
                        </Grid>
                        <Grid item xs sm={4}>
                          <Typography className={classes.title}>
                            COMPLAINANT
                          </Typography>
                        </Grid>
                        <Grid item xs sm={2}>
                          <Typography className={classes.title}>
                            DATE OF FILING
                          </Typography>
                        </Grid>
                        <Grid item xs sm={2}>
                          <Typography className={classes.title}>
                            REGISTRATION
                          </Typography>
                        </Grid>
                        <Grid item xs sm={3}>
                          <Typography className={classes.title}>
                            VIEW GRIEVANCE
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
                {disputesBySearch.length !== 0 ? (
                  disputesBySearch.map((dispute) => {
                    return (
                      <DisputeRow
                        id={dispute.id}
                        name={dispute.name}
                        filingDate={dispute.created}
                        registrationDate={dispute.approved_date}
                        type={type}
                        history={history}
                        addDispute={addDispute}
                        removeDispute={removeDispute}
                        reload={reload}
                      />
                    );
                  })
                ) : (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box textAlign="center">
                        <Typography
                          style={{
                            fontFmaily: '"Inter","sans-serif"',
                            fontWeight: 500,
                            fontSize: "initial",
                          }}
                        >
                          Dispute does not exist.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Box height="10px"></Box>
              {disputesBySearch.length !== 0 && !searchText && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: `Roboto,'sans-serif'`,
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      <IconButton
                        disabled={currentPage == 1}
                        onClick={(e) => {
                          setCurrentPage(currentPage - 1);
                          getUserDataAnotherPage(currentPage - 1);
                        }}
                      >
                        <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                      </IconButton>
                      {`${currentPage} / ${totalPages}`}
                      <IconButton
                        disabled={currentPage >= totalPages}
                        onClick={(e) => {
                          setCurrentPage(currentPage + 1);
                          getUserDataAnotherPage(currentPage + 1);
                        }}
                      >
                        <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <POPUP
        opener={openDeletePopup}
        onClose={handleCloseDelete}
        heading="Delete grievances"
        message="Are you sure you would like to delete the selected grievance(s)?"
        mainButton="DELETE"
        onMainButton={handleDelete}
      />
      <DeletedPopup
        opener={openDeletedGifPopup}
        onClose={handleCloseDeletedGifPopup}
        text="Deleted!"
      />
      <POPUP
        opener={openSeriousPopup}
        onClose={handleCloseSerious}
        heading="Move grievances"
        message="Are you sure you would like to move the selected grievance(s) to serious?"
        mainButton="MOVE"
        onMainButton={handleSerious}
      />
      <DonePopup
        opener={openSeriousGifPopup}
        onClose={handleCloseSeriousGifPopup}
        text="Moved to serious"
      />
      <POPUP
        opener={openFrivolousPopup}
        onClose={handleCloseFrivolous}
        heading="Move grievances"
        message="Are you sure you would like to move the selected grievance(s) to frivolous?"
        mainButton="MOVE"
        onMainButton={handleFrivolous}
      />
      <DonePopup
        opener={openFrivolousGifPopup}
        onClose={handleCloseFrivolousGifPopup}
        text="Moved to frivolous"
      />
    </>
  );
}
