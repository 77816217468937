import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import VisibilityIcon from "@material-ui/icons/Visibility";
import instance from "api/globalaxios";
import { RESPONDENT } from "app/constants";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FormattedMessage from "features/common/TranslatedMessage";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import { CloseDisputeAtLevelTwo, gethost } from "slices/disputeSlice";
import { DocGenerator } from "utils";
import CaseDisplayProfileList from "./CaseDisplayProfileList";
import CaseManager from "./CaseManager";
import Company from "./Company";
import Complainant from "./Complainant";
import GRO from "./GRO";
import SRB from "./SRB";
import Summary from "./Summary";
import Witness from "./Witness";

const useStyles = makeStyles((theme) => ({
  backbtn: {
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  bigbtn: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "0.4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "15px",
    },
  },
  closedbtn: {
    background: "#E0E0E0",
    color: "white",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "0.4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "15px",
    },
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#3F51B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  blueheadingtext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default function CaseDisplay({
  setOpenCase,
  isSmallScreen,
  caseId,
  mode,
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  let t = sessionStorage.getItem("company_id");

  const [isDisputeClosed, setIsDisputeClosed] = React.useState();
  const [startDate, setStartDate] = useState();
  const [summary, setSummary] = useState();
  const [complainantData, setcomplainantData] = useState();
  const [companyData, setCompanyData] = useState();
  const [groData, setgroData] = useState();
  const [lawyerData, setLawyerData] = useState();
  const [lawyerDataRV, setLawyerDataRV] = useState();
  const [casefiles, setCasefiles] = useState();
  const [witnessData, setwitnessData] = useState();
  const [casemanagerData, setCasemanagerData] = useState();
  const [allProfiles, setAllProfiles] = useState();
  const [loading, setLoading] = useState(true);
  const [claimantcasestatus, setClaimantstatus] = useState("");
  const [respondentcasestatus, setRespondentstatus] = useState("");

  React.useEffect(() => {
    dispatch(disputeDetails()).then(() => {});
  }, []);

  function disputeDetails() {
    return async () => {
      try {
        const response = await instance.get(
          `/api/disputedetailsbyid/${caseId}/`
        );
        let response2 = await instance({
          method: "get",
          url: "/api/casefile/" + "?host=" + gethost(),
          params: {
            dispute__id: caseId,
          },
        });
        let casefilesres = response2.data.results;
        setCasefiles(casefilesres);
        let result = [];
        casefilesres.forEach((casefile) => {
          if (casefile.type == "claimant") {
            let claimant_lawyer = [];
            casefile.lawyer_rv.forEach((i) => {
              claimant_lawyer.push(i);
            });
            result.push({
              claimant_lawyer: claimant_lawyer,
            });
          } else {
            let respondent_lawyer = [];
            casefile.lawyer_rv.forEach((i) => {
              respondent_lawyer.push(i);
            });
            result.push({
              respondent_lawyer: respondent_lawyer,
            });
          }
        });

        casefilesres.map((item) => {
          if (item.type == "claimant") {
            setClaimantstatus(item.case_status);
          } else {
            setRespondentstatus(item.case_status);
          }
        });

        setLawyerDataRV(result);
        let d = response.data;
        setIsDisputeClosed(d.dispute_data.dispute[0].status == "closed");
        setSummary(d.dispute_data.dispute[0].summery);
        setcomplainantData(d.dispute_data.claimant);
        if (d.dispute_data.respondent)
          setCompanyData(d.dispute_data.respondent);
        else setCompanyData();
        let resArbitrator = [];
        let resLawyer = [];
        let resWitness = [];
        let professionals = d.dispute_data.professionals;
        professionals.forEach((item) => {
          if (
            item.profile.profile_type == "arbitrator" ||
            item.profile.profile_type == "arbitrator_rv"
          ) {
            resArbitrator.push(item);
          } else if (
            item.profile.profile_type == "lawyer" ||
            item.profile.profile_type == "lawyer_rv"
          ) {
            resLawyer.push(item);
          } else if (
            item.profile.profile_type == "witness" ||
            item.profile.profile_type == "witness_rv"
          ) {
            resWitness.push(item);
          }
        });
        setgroData(resArbitrator);
        setLawyerData(resLawyer);
        setwitnessData(resWitness);
        setCasemanagerData(d.dispute_data.case_manager_rv);
        let c = d.dispute_data.claimant;
        let r;
        let resultProfiles = [];
        c.profile.rv_profile = "Claimant";
        resultProfiles.push(c);
        if (d.dispute_data.respondent) {
          r = d.dispute_data.respondent;
          r.profile.rv_profile = "Respondent";
          resultProfiles.push(r);
        }
        d.dispute_data.professionals.forEach((item) => {
          resultProfiles.push(item);
        });
        setAllProfiles(resultProfiles);
        let date = new Date(d.dispute_data.dispute[0].created);
        setStartDate(date.toLocaleDateString("en-US"));
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const openpopup2 = () => {
    setOpen2(true);
  };

  const openpopup3 = () => {
    setOpen3(true);
  };

  const closeModal2 = () => {
    setOpen2(false);
  };

  const closeModal3 = () => {
    setOpen3(false);
  };

  const closeGrievence = () => {
    // dispatch(updateDispute({ id: caseId, status: "closed" }));
    dispatch(CloseDisputeAtLevelTwo(caseId));
    setIsDisputeClosed(true);
    setOpen2(false);
  };

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <Grid container justify="flex-start" direction="column">
        <Grid item>
          <Grid container justify="flex-start">
            <Grid item>
              <Button
                onClick={() => {
                  setOpenCase(false);
                  sessionStorage.removeItem("subadmin_overview_showCase");
                  sessionStorage.removeItem("caseid");
                  sessionStorage.removeItem("company_id");
                }}
                className={classes.backbtn}
                startIcon={<ArrowBackIosIcon />}
              >
                Back to all cases
              </Button>
            </Grid>
          </Grid>
          <Box height="3rem"></Box>
          {!isSmallScreen() ? (
            <>
              <Grid
                container
                justify="space-evenly"
                direction="row"
                alignItems="stretch"
                style={{ display: "flex" }}
                spacing={2}
              >
                <Grid item sm={8} xs={8} style={{ flex: "1" }}>
                  <Paper
                    style={{
                      height: "100%",
                      background: "none",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography className={classes.blackheading}>
                          CASE ID: {caseId}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blackheading}>
                          Start Date: {startDate}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blackheading}>
                          Mode: {mode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item sm={4} xs={4} style={{ flex: "1" }}>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    spacing={2}
                  >
                    <Grid item style={{ width: "70%" }}></Grid>
                    <Grid item style={{ width: "70%" }}></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-evenly"
                direction="row"
                alignItems="stretch"
                style={{ display: "flex" }}
                spacing={3}
              >
                <Grid item sm={8} xs={6} style={{ flex: "1" }}>
                  <Summary summary={summary} caseId={caseId} />
                </Grid>
                <Grid item sm={4} xs={6} style={{ flex: "1" }}>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    spacing={2}
                  >
                    <Grid item style={{ width: "90%" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.bigbtn}
                        style={{ width: "100%" }}
                        onClick={() => {
                          history.push(`/disputes/${caseId}/chat`);
                        }}
                      >
                        <FormattedMessage id="common.hearingRooms" />
                      </Button>
                    </Grid>
                    <Grid item style={{ width: "90%" }}>
                      <Button
                        variant="contained"
                        // color="primary"
                        className={classes.bigbtn}
                        size="large"
                        style={{
                          color: "black",
                          background: "white",
                          width: "100%",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          openpopup3();
                          // history.push(`/disputes/${activeDispute.id}/chat`);
                        }}
                      >
                        Details & Updates
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography className={classes.blackheading}>
                    CASE ID: {caseId}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.blackheading}>
                    Start Date: {startDate}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.blackheading}>
                    Mode: {mode}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="1rem"></Box>
              <Summary summary={summary} caseId={caseId} />
              <Box height="1rem"></Box>
              <Grid container direction="row" justify="space-evenly">
                <Grid item sm={5} xs={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.bigbtn}
                    style={{ width: "100%" }}
                    onClick={() => {
                      // history.push(`/disputes/${activeDispute.id}/chat`);
                    }}
                  >
                    <FormattedMessage id="common.hearingRooms" />
                  </Button>
                </Grid>
                <Grid item sm={5} xs={5}>
                  <Button
                    variant="contained"
                    // color="primary"
                    className={classes.bigbtn}
                    size="large"
                    style={{
                      color: "black",
                      background: "white",
                      width: "100%",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      openpopup3();
                      // history.push(`/disputes/${activeDispute.id}/chat`);
                    }}
                  >
                    Details & Updates
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          <Box height="4rem"></Box>
          <Grid container justify="space-evenly" spacing={4}>
            <Grid
              item
              sm={6}
              md={6}
              lg={4}
              style={{ width: "100%", display: "flex" }}
            >
              <Complainant
                isSmallScreen={isSmallScreen}
                groData={complainantData}
                caseId={caseId}
                companyId={t}
                mode={mode}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              lg={4}
              style={{ width: "100%", display: "flex" }}
            >
              <Company
                isSmallScreen={isSmallScreen}
                groData={companyData}
                caseId={caseId}
                companyId={t}
                mode={mode}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              lg={4}
              style={{ width: "100%", display: "flex" }}
            >
              <GRO
                isSmallScreen={isSmallScreen}
                groData={groData}
                caseId={caseId}
                companyId={t}
                mode={mode}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              lg={4}
              style={{ width: "100%", display: "flex" }}
            >
              <SRB
                isSmallScreen={isSmallScreen}
                caseId={caseId}
                companyId={t}
                groData={lawyerData}
                mode={mode}
                lawyerDataRV={lawyerDataRV}
                casefiles={casefiles}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              lg={4}
              style={{ width: "100%", display: "flex" }}
            >
              <Witness
                isSmallScreen={isSmallScreen}
                caseId={caseId}
                companyId={t}
                groData={witnessData}
                mode={mode}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              lg={4}
              style={{ width: "100%", display: "flex" }}
            >
              <CaseManager
                isSmallScreen={isSmallScreen}
                casemanagerData={casemanagerData}
                caseId={caseId}
                companyId={t}
                mode={mode}
              />
            </Grid>
          </Grid>
          <Box height="4rem"></Box>
          <Grid container justify="center" spacing={3}>
            <Grid item sm={4} xs={5}>
              <Button
                variant="contained"
                // color="primary"
                className={classes.bigbtn}
                size="large"
                style={{
                  color: "black",
                  background: "white",
                  width: "100%",
                  textTransform: "none",
                }}
                onClick={() => {
                  // final decision
                }}
              >
                Final Decision
              </Button>
            </Grid>
            <Grid item sm={4} xs={5}>
              {isDisputeClosed ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.closedbtn}
                  style={{ width: "100%" }}
                >
                  Close Grievance
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.bigbtn}
                  style={{ width: "100%" }}
                  onClick={() => {
                    openpopup2();
                  }}
                >
                  Close Grievance
                </Button>
              )}
            </Grid>
            {(claimantcasestatus == "review_complete" ||
              claimantcasestatus == "complete" ||
              respondentcasestatus == "review_complete" ||
              respondentcasestatus == "complete") && (
              <Grid item sm={4} xs={5}>
                {/* <Box> */}
                {(claimantcasestatus == "review_complete" ||
                  claimantcasestatus == "complete") && (
                  <Button
                    style={{ margin: "0.5rem 0 0 0" }}
                    color="primary"
                    size="large"
                    startIcon={<VisibilityIcon />}
                    onClick={() => {
                      DocGenerator(caseId, "claimant");
                    }}
                  >
                    <FormattedMessage id="dispute.statementOfClaim" />
                  </Button>
                )}
                {/* </Box> */}
                {/* <Box> */}
                {(respondentcasestatus == "review_complete" ||
                  respondentcasestatus == "complete") && (
                  <Button
                    style={{ margin: "0.5rem 0 0 0" }}
                    color="primary"
                    size="large"
                    startIcon={<VisibilityIcon />}
                    onClick={() => {
                      DocGenerator(caseId, RESPONDENT);
                    }}
                  >
                    <FormattedMessage id="dispute.statementOfDefence" />
                  </Button>
                )}

                {/* </Box> */}
              </Grid>
            )}
          </Grid>
          <Box height="5rem"></Box>
          <Grid container justify="flex-start">
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>NAME</Typography>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>
                PROFILE TYPE
              </Typography>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>CALENDER</Typography>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>DRIVE</Typography>
            </Grid>
          </Grid>
          <Box height="0.4rem"></Box>
          {allProfiles.map((profile) => {
            return (
              <CaseDisplayProfileList
                key={profile.profile.id}
                profile={profile}
                caseId={caseId}
                mode={mode}
              />
            );
          })}
        </Grid>
        <Popup
          open={open2}
          closeOnDocumentClick
          closeOnEscape
          onClose={closeModal2}
          position="center"
          modal
          nested
          contentStyle={{
            background: "white",
            width: "330px",
            height: "185px",
          }}
          overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
        >
          <Box style={{ padding: "1rem 1.3rem" }}>
            <Typography
              style={{
                fontSize: "20px",
                color: "#333333",
                "font-weight": "bold",
                "line-height": "23px",
                "letter-spacing": "0.0015em",
              }}
            >
              Are you sure?
            </Typography>
            <Box height="0.5rem"></Box>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                "line-height": "28px",
              }}
            >
              Are you sure you want to close this grievance?
            </Typography>
            <Box height="0.7rem"></Box>
          </Box>
          <Box style={{ padding: "1rem 1.3rem" }}>
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    "line-height": "16px",
                    "letter-spacing": "0.02em",
                    "font-weight": "500",
                    cursor: "pointer",
                  }}
                  onClick={closeModal2}
                >
                  CANCEL
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#2F80ED",
                    "line-height": "16px",
                    "letter-spacing": "0.02em",
                    "font-weight": "500",
                    cursor: "pointer",
                  }}
                  onClick={closeGrievence}
                >
                  CLOSE
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Popup>
        <Popup
          open={open3}
          closeOnDocumentClick
          closeOnEscape
          onClose={closeModal3}
          position="center"
          modal
          nested
          contentStyle={{
            background: "white",
            width: "330px",
            height: "170px",
          }}
          overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
        >
          <Box style={{ padding: "1rem 1.3rem" }}>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                "line-height": "28px",
                textAlign: "center",
                paddingTop: " 1.3rem",
              }}
            >
              Claimant Status :{claimantcasestatus == "new" && " Dispute Filed"}
              {claimantcasestatus == "in_progress" && " Dispute Filed"}
              {claimantcasestatus == "dispute_lodged" && " Dispute Filed"}
              {claimantcasestatus == "questions_complete" &&
                " Questions Complete"}
              {claimantcasestatus == "upload_complete" && " Upload Complete"}
              {claimantcasestatus == "review_complete" && " Review Complete"}
              {claimantcasestatus == "complete" && "Complete"}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                "line-height": "28px",
                textAlign: "center",
                paddingTop: " 1.3rem",
              }}
            >
              Respondent Status :
              {respondentcasestatus == "new" && " Dispute Filed"}
              {respondentcasestatus == "in_progress" && " Dispute Filed"}
              {respondentcasestatus == "dispute_lodged" && " Dispute Filed"}
              {respondentcasestatus == "questions_complete" &&
                " Questions Complete"}
              {respondentcasestatus == "upload_complete" && " Upload Complete"}
              {respondentcasestatus == "review_complete" && " Review Complete"}
              {respondentcasestatus == "complete" && "Complete"}
            </Typography>
          </Box>
        </Popup>
      </Grid>
    );
  }
}
