import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { isRv, isWebnyay } from "Routes/Routes";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteMeeting } from "slices/appointmentSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    minHeight: "10rem",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
  },

  greyColor: {
    color: theme.palette.common.grey1,
  },
}));

export default function EventDisplay({
  eventDetails,
  handleEventDetailsClose,
  profileType,
  sources,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [by, setBy] = React.useState();

  React.useEffect(() => {
    if (sources[0]["events"].length > 0) {
      sources[0]["events"].map((e) => {
        if (e.meeting.id == meetingId) {
          let meet = e.meeting.group;
          // if(meet == "arbitration"){
          //   setMeetingRoom("DPCGC")
          // }
          // else if(meet == "ADRP-breakout"){
          //   setMeetingRoom("DPCGC breakout")
          // }
          // else{
          //   setMeetingRoom(meet);
          // }
          if (e.meeting.platform == "zoom") {
            setBy("Zoom VC");
          } else {
            setBy("In-built VC");
          }
        }
      });
    }
  }, []);

  const {
    title,
    extendedProps: {
      appointmentId,
      dispute,
      meetingId,
      appointmentName,
      isPastEvent,
      meetingStartAt,
    },
  } = eventDetails.event["_def"];

  const onCancel = () => {
    dispatch(deleteMeeting(meetingId, dispute)).then(() => {
      handleEventDetailsClose();
    });
  };

  // const data = [
  //   {
  //     title: "Grievance ID",
  //     value: dispute,
  //   },
  //   {
  //     title: "Meeting Room",
  //     // value: "DPCGC/DPCGC breakout",
  //     value : meetingRoom
  //   },
  //   {
  //     title: "By",
  //     // value: "DPCGC VC/Zoom VC",
  //     value: by ? by:"DPCGC VC" ,
  //   },
  // ];
  let data;
  if (isRv || isWebnyay) {
    data = [
      {
        title: isRv ? "Case Id" : "Dispute",
        value: dispute,
      },
      {
        title: isRv ? "Case name" : "Dispute name",
        value: appointmentName,
      },
      {
        title: "Event title",
        value: title,
      },
      {
        title: "Hearing Details",
        value: by,
      },
      {
        title: "Hearing Start At",
        value: meetingStartAt,
      },
      {
        title: "Meeting ID",
        value: meetingId,
      },
    ];
  } else {
    const data = [
      {
        title: "Dispute",
        value: dispute,
      },
      {
        title: "Event title",
        value: title,
      },

      {
        title: "Meeting ID",
        value: meetingId,
      },
    ];
  }

  return (
    <div className={classes.paper}>
      <Typography variant="h5">Event Details</Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="90%"
        m="1rem auto"
        gridGap='1rem'
      >
        {data.map((singleItem) => {
          return (
            <Info
              title={singleItem.title}
              value={singleItem.value}
              key={singleItem.title}
            />
          );
        })}

        {isPastEvent && (
          <Button disabled color="primary" variant="contained">
            {"Completed"}
          </Button>
        )}

        {/* {profileType === "secretariat" &&
          <Button
            disabled={isPastEvent}
            color="primary"
            variant="contained"
            onClick={onCancel}
          >
            {isPastEvent ? "Completed" : "Cancel Meeting"}
          </Button>
        }
        {(profileType !== "secretariat" && isPastEvent) &&
          <Button
            disabled={isPastEvent}
            color="primary"
            variant="contained"
            onClick={onCancel}
          >
            {"Completed"}
          </Button>
        } */}
      </Box>
    </div>
  );
}

const Info = ({ title, value }) => {
  return (
    <Box
      width="100%"
      // m="1rem 0"
      display="flex"
      // flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography style={{ color: "#000", fontSize: '1rem', textAlign: 'left' }} variant="h6">
          {title}
        </Typography>
      </Box>
      <Box height="0.2rem"></Box>
      <Box>
        <Typography variant="h6" style={{fontSize: '1rem', textAlign: 'right'}}>{value}</Typography>
      </Box>
    </Box>
  );
};
