import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import FormattedMessage from "features/common/TranslatedMessage";
import { updateDisputeTiac } from "features/TempTiacFormV3/UploadPage/questions";
import { GetNewAnswersTiac } from "features/Tiacfinal/final";
import Sign from "features/TiacSign";
import { sendSignature } from "features/TempTiacFormV3/TiacSign/TiacSign";
// import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {claimantPageConstants} from '../constants/index';
import ConfigRow from '../constants/ConfigRow';
import getFieldValue from "../constants/getFieldValue";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "2rem",
    height: "100%",
  },

  guestbtn: {
    // width: "10%",
    // height : "50%",
    height: "100%",
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  vectorImage: {
    width: "10%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },

  text: {
    color: "#2C3345",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "500",
  },

  text1: {
    "font-size": "18px",
    color: "#2C3345",
    padding: "1rem",
    fontFamily: `'Inter',sans-serif`,
  },

  text2: {
    "font-size": "18px",
    fontFamily: `'Inter',sans-serif`,
    color: "#464d5f",
    padding: "0 1rem",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  required: {
    color: "#f23a3c",
  },
  guestbtn: {
    background: "#101C50",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: "#101C50",
      border: "1px solid #101C50",
    },
  },
  paper: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  input1: {
    height: 180,
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  sigImage: {
    width: "22rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

var signUpData = {};

async function UploadAnswer(answer, casefile, question) {
  return await instance.post(
    `/api/tiacanonymousanswer/?email=${localStorage.getItem("email")}`,
    {
      question: question.id,
      case: casefile.id,
      value: answer,
    }
  );
}

async function UploadNewAnswer(disputeId, casefile, answers) {
  return await instance.post(
    `/newtiacanonymousanswer/?email=${localStorage.getItem("email")}&dispute=${disputeId}`,
    {
     "answers": answers
     
    }
  );
}

async function getCaseFile(email, disputeId) {
  return await instance({
    method: "get",
    url: "/api/tiaccasefileanonymous/",
    params: {
      email: email,
      id: disputeId,
    },
  });
}
async function getQuestions(email) {
  return await instance({
    method: "get",
    url: "/tiacanonymousquestions",
    params: {
      email: email,
    },
  });
}

async function getDisputeTiac(email, dispute_id) {
  return await instance.get(
    `/api/tiacdisputeanonymous/?id=${dispute_id}&email=${email}`
  );
}

async function getSignatureUrl(email, id) {
  return await instance.get(`/signatureurl/?email=${email}&id=${id}`);
}

const AdditionalInfoPageWithoutOtp = ({
  setActiveStep,
  isAllPreviosStepsCompleted,
  submitDetailsSignal,
  questionsForAll
}) => {
  let id = localStorage.getItem("dispute_id");
  let email = localStorage.getItem("email");

  const [loading, setLoading] = useState(false);
  const [fieldsInput, setFieldsInput] = useState({});
  const {ADDITIONAL_DETAILS_PAGE} = claimantPageConstants;

  let signPad = useRef({});
  const [signError, setSignError] = useState();
  const [isSignatureDisabled, setIsSignatureDisabled] = useState(false);
  const [signatureImageUrl, setSignatureImageUrl] = useState();
  const startSign = () => {
    setSignError();
  };
  function clear() {
    signPad.current.clear();
    setSignError("Required");
  }
  console.log(fieldsInput,'fieldsInput')

  const [dispute, setDispute] = useState();

  React.useEffect(() => {
    getDisputeTiac(email, id)
      .then((res) => {
        if (res.data.results[0].id) {
          setDispute(res.data.results[0]);
          if (res.data.results[0].sig) {
            setIsSignatureDisabled(true);
            getSignatureUrl(email, res.data.results[0].sig).then((re) => {
              setSignatureImageUrl(re.data.url);
            });
          }
        } else history.push("/RFA2");
      })
      .catch((e) => {
        history.push("/RFA2");
      });
  }, []);

  const [casefile, setCasefile] = useState();

  React.useEffect(() => {
    getCaseFile(email, id)
      .then((res) => {
        setCasefile(res.data.results[0]);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, []);
   
  useEffect(()=>{
    GetNewAnswersTiac(email,id).then((res)  => {
      const updatedTiacAnswer = res?.data?.additional_page_answers || [];
      var result = {};
      for (var i = 0; i < updatedTiacAnswer?.length; i++) {
        const updatedItem = `newanswer${i+1}-${updatedTiacAnswer[i].question}`
        result[updatedItem] = updatedTiacAnswer[i].value;
      }
      setFieldsInput(result);
    })
  },[])

  const classes = useStyles();

  let history = useHistory();
  const dispatch = useDispatch();

  function checkFieldError(fieldName, condition, errorText, hasError) {
    if (condition) {
        setFieldsInput((prevFieldsInput) => ({
            ...prevFieldsInput,
            error: {
                ...prevFieldsInput.error,
                [fieldName]: errorText,
            }
        }));
        return [...hasError, fieldName]
    }

    return hasError;
  }

  function handleSubmitDetails(){
    if (!isSignatureDisabled && signPad.current.isEmpty()) {
      setSignError("Required");
    }
    let hasError = [];
    hasError = checkFieldError("newanswer1-8693", !fieldsInput['newanswer1-8693'], "Answer question 1", hasError);
    hasError = checkFieldError("newanswer2-8694", !fieldsInput['newanswer2-8694'], "Answer question 2", hasError);
    hasError = checkFieldError("newanswer3-8695", !fieldsInput['newanswer3-8695'], "Answer question 3", hasError);
    hasError = checkFieldError("newanswer4-8696", !fieldsInput['newanswer4-8696'], "Answer question 4", hasError);
    hasError = checkFieldError("newanswer5-8697", !fieldsInput['newanswer5-8697'], "Answer question 5", hasError);
    hasError = checkFieldError("newanswer6-8686", !fieldsInput['newanswer6-8686'], "Answer question 6", hasError);
    hasError = checkFieldError("newanswer7-8687", !fieldsInput['newanswer7-8687'], "Answer question 7", hasError);

    if (hasError.length) return false;

    let newObj = []
    delete fieldsInput.error;
    const hewrw = Object.keys(fieldsInput).map((item, index)=>{
        const quesIds = item?.split?.('-')?.[1];
        newObj[index] = {question_id: quesIds , value: fieldsInput[item]}

        return newObj;
    })

    if (isAllPreviosStepsCompleted) {
      //   send otp
    } else {
      signUpData = fieldsInput;
      setLoading(true);
      UploadNewAnswer(id, casefile, newObj).then((res)=>{
        updateDisputeTiac(
          {
            id: id,
            tiac_dispute_step:
              "additional_info_complete",
          },
          email
        ).then((resdisp)=>{
          tempInvoiceAndOtp(email, id)
            .then((resdisp) => {
              if (
                resdisp.data.status == "success"
              ) {
                updateDisputeTiac(
                  {
                    id: id,
                    tiac_dispute_step:
                      "additional_info_complete",
                  },
                  email
                );
                history.push(
                  "/tiac_waitingscreen"
                );
              }
            })
        })
        .catch((err) => {
          console.log("err", err);
          console.log(
            "resp",
            err.response.data
          );
          alert(err.response.data.status);
        });
      }).catch(()=>{
        console.log('errpr')
      })
    }
    if (!isSignatureDisabled && !signPad.current.isEmpty()) {
      dispatch(
        sendSignature(
          id,
          email,
          signPad.current.getTrimmedCanvas().toDataURL("image/png")
        )
      );
    }
  }

  useEffect(() => {
    if (submitDetailsSignal) {
      let newObj = []
      delete fieldsInput.error;
      const hewrw = Object.keys(fieldsInput).map((item, index)=>{
          const quesIds = item?.split?.('-')?.[1];
          newObj[index] = {question_id: quesIds , value: fieldsInput[item]}
  
          return newObj;
      })

      if (!isSignatureDisabled && !signPad.current.isEmpty()) {
        dispatch(
          sendSignature(
            id,
            email,
            signPad.current.getTrimmedCanvas().toDataURL("image/png")
          )
        );
      }
      UploadNewAnswer(id, casefile, newObj).then((res) => {
          history.push(
            "/resumerfa2?random=" + Math.random()
          );
      });
    }
  }, [submitDetailsSignal]);

  async function tempInvoiceAndOtp(email, dispute_id) {
    return await instance.get(
      `/tiacotpandinvoice/${dispute_id}/?email=${email}`
    );
  }

  function handleFieldChange(value, name) {
    setFieldsInput((prevFieldsInput) => ({
        ...prevFieldsInput,
        ...(getFieldValue(value, fieldsInput, name.toString()))
    }));
  }

  return (
    <Box p="2rem 0 7rem 0">
      <Grid container spacing={3} justify="center">
          <Grid item xs={11}>
              <Paper className={classes.formContainer}>
                  <Box>
            <ConfigRow
              fieldsInput={fieldsInput}
              cards={ADDITIONAL_DETAILS_PAGE(questionsForAll)}
              onChange={handleFieldChange}
              classes={classes}
            />
            <Grid item md={12} sm={12} xs={12}>
            <Typography className={classes.text1}>
              <FormattedMessage id="tiac.agreement" />
              {": "}
              <span className={classes.required}>*</span>
            </Typography>
            <Grid item style={{ padding: "0 2rem" }}>
              <Box height="2rem"></Box>
              {!isSignatureDisabled ? (
                <Sign
                  signPad={signPad}
                  startSign={startSign}
                  clear={clear}
                />
              ) : (
                <img
                  src={signatureImageUrl}
                  alt="user's sign"
                  className={classes.sigImage}
                />
              )}
              {signError && (
                <Grid container>
                  <Grid item md={6} sm={8} xs={12}>
                    <Alert severity="error">{signError}</Alert>
                  </Grid>
                </Grid>
              )}
            </Grid>
            </Grid>
            <Box height="3rem"></Box>
            <Grid container justify="center" spacing={3}>
              <Grid item>
                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ background: "#2F80ED" }}
                    disabled={loading}
                    onClick={handleSubmitDetails}
                  >
                    <Typography className={classes.btnTextWhite}>
                      <FormattedMessage id="buttons.proceed" />
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
    </Box>
    </Paper>
    </Grid>
    </Grid>
    </Box>
  );
};

export default AdditionalInfoPageWithoutOtp;
