import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "0.5rem",
  },
  button: {
    padding: "0.5rem 3.2rem",
    fontSize: "1.2rem",
    color: "#3F51B5",
      border: "1px solid #3F51B5",
  },
  outlineButton:{
    color: "#3F51B5",
    border: "1px solid #3F51B5",
    "&:hover": {
      backgroundColor: "#3F51B5",
      color: "#ffffff",
      border: "1px solid transparent",
    },
  }
}));
