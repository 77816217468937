import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import instance from "api/globalaxios";
import SignaturePad from "react-signature-canvas";
import React from "react";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#e7effe",
    fontFamily: `'Inter',sans-serif`,
    fontSize: "12px",
    display: "inline-block",
    cursor: "pointer",
    color: "#57647e",
    padding: "4px 10px",
    borderRadius: "4px",
    marginTop: "4px",
  },
}));

export function sendSignature(dispute_id, email, dataUrl) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append("img", dataUrl);
      const response = await instance.post(
        `/savesignature/${dispute_id}/${email}/`,
        data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export default function Sign({ signPad, startSign, clear }) {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item md={6} sm={8} xs={12}>
          <SignaturePad
            ref={signPad}
            canvasProps={{
              className: "signatureCanvas",
            }}
            onBegin={startSign}
          />
        </Grid>
      </Grid>
      <Box height="0.5rem"></Box>

      <Grid container spacing={2}>
        <Grid item>
          <Button onClick={clear}>
            <Typography className={classes.btn}>Clear</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
