import { DyteMeeting } from "@dytesdk/react-ui-kit";
import { DyteProvider, useDyteClient } from "@dytesdk/react-web-core";
import { Url } from "api/globalaxios";
import { Box, Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { instance, } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isLoggedIn } from "slices/loginSlice";
import axios from "axios";
import { v4 as uuid } from 'uuid';
import * as Yup from "yup";
import React from "react";
import { useTranslation } from "react-i18next";

function generateTranscriptsString(transcripts) {
  let result = "";
  transcripts.forEach(transcript => {
      result += `${transcript.name}: ${transcript.transcript}\n`;
  });

  return result;
}

async function createPadAndSetDataDyte(data) {
  const token = isLoggedIn();
  return await axios.post(Url + "transcribe/request/", data, {
    headers:{
      Authorization: "Bearer " + token,
    }
  })
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "3rem",
    marginTop: "5rem",
		borderRadius: "5px",
    textAlign: "center",
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    margin: 'auto',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  joinButton: {
    backgroundColor: '#286efd',
    color: 'white',
    marginTop: '1rem',
    textTransform: 'capitalize',
    '&:hover' : {
      backgroundColor: '#2c88fe',
    }
  }
}));

const JoinDyteLinkMeeting = ({preset_name}) => {
  const classes = useStyles();
  const { disputeId} = useParams();
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [meeting, initClient] = useDyteClient();
  const [userId,setUserId] = useState(null);
  const [dyteToken, setDyteToken] = useState("");
  const [newUser, setNewUser] = useState(false);
  const [transcripts, setTranscripts] = useState({});
  const [resultData, setResultData]= useState('');
  const [etherpadId, setEtherpadId] = useState('');
  const { t } = useTranslation();

  const full_name_translation = t("full_name")
  const full_name_req_translation = t("full_name_req")

  const isUserLoggedIn = isLoggedIn() === '' ? false : true ;

  const containerStyle = {
    width: "100%",
    height: "80vh",
  };

  useEffect(()=>{
    let values = {preset_name : preset_name};
    if(checkDyteMeetingStatus()){
      if(!isUserLoggedIn){
        if(!localStorage.getItem('dyte_uuid') || !localStorage.getItem('anonymousUserName')){
          let userid = uuid();
          setUserId(userid);
          setNewUser(true);
        }
        else{
          setUserId(localStorage.getItem('dyte_uuid'));
          values.name = localStorage.getItem('anonymousUserName');
          values.userId = localStorage.getItem('dyte_uuid');
          startConferenceDyte(values);
        }
      }
      else{
        setUserId(user.id);
        values.name = user.first_name+' '+user.last_name;
        values.userId = user.id;
        startConferenceDyte(values);
      }
    }
  },[])

  useEffect(() => {
    if (dyteToken) {
      //initalising the dyte client (it is running again)
      initClient({
        authToken: dyteToken,
        // set default values for user media
        defaults: {
          audio: false,
          video: false,
        },
      });
    }
    return () => {
      // window.location.reload(false)
    };
  }, [dyteToken]);

  const pad_id = localStorage.getItem('pad_id');

  useEffect(() => {
    if (meeting) {
      meeting.meta.on("disconnected", () => {
        setDyteToken("");
        setNewUser(false);
        localStorage.removeItem('pad_id');
      });
      meeting.self.on("roomLeft", () => {
        setDyteToken("");
        setNewUser(false);
        localStorage.removeItem('pad_id');
      });
      meeting.self.on('roomJoined', () => {
        setLoading(false);
        // guest joined in room
        admitparticipantApi(userId)
      });
      meeting.ai.on('transcript', (transcriptData) => {
          setTranscripts(transcriptData);
      });
    }
  }, [meeting]);

  useEffect(() => {
    // Create a pad when result is available
    if (Object?.keys(transcripts)?.length > 0) {
      transcripts.pad_id = pad_id;
      createPadAndSetDataDyte(transcripts)
        .then(response => {
          console.log(response,'response')
        })
        .catch(error => {
          console.error('Error Setting text:', error);
        });
    }
  }, [transcripts]);

  const formValidation = Yup.object().shape({
    name: Yup.string().required(full_name_req_translation),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      localStorage.setItem('dyte_uuid',userId);
      localStorage.setItem('anonymousUserName',values.name);
      values.userId = userId;
      values.preset_name = preset_name
      startConferenceDyte(values);
    },
  })

  async function checkDyteMeetingStatus() {
    try {
      setLoading(true);
      let response = await instance({
        method: "get",
        url: "/checkdytemeetingstatus",
        params: {
          disputeId: disputeId,
        },
      });
      setLoading(false);
      if(response.data.status === 'active')
        return true;
      else
        return false;
    } catch (err) {
      const reason = err.response?.data?.status;
      if (reason) {
        setErrorType(reason);
        setLoading(false);
        return;
      }
    }
  }

  async function startConferenceDyte(values) {
    console.log('values',values)
    const {name, userId, preset_name} = values;
    try {
      setLoading(true);
      setErrorType(null);
      setDyteToken(null);
      let response = await instance({
        method: "post",
        url: "/joindytemeetinganonymous/",
        data: {
          userid: userId,
          disputeId: disputeId,
          name: name,
          preset_name: preset_name
        },
      });
      // setLoading(false);
      setDyteToken(response?.data?.user?.token);
      localStorage.setItem('pad_id', response?.data?.pad_id);
      setEtherpadId(response?.data?.pad_id);
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        setLoading(false);
        return;
      }
    }
  }

  async function admitparticipantApi(userId) {
    try {
      await instance({
        method: "post",
        url: "/dytemeetingparticipantadmitted/",
        data: {
          userid: userId,
          disputeId,
        },
      });
    } catch (err) {
      console.log('something is wrong', err)
    }
  }


  const renderErrorContainer = () => {
    switch (errorType) {
      case "not-found":
      case "inactive" :
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Meeting does not exist</h3>
          </Box>
        );
      default:
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Please refresh to try again.</h3>
          </Box>
        );
    }
  };

  return (
    <Box display="flex" justifyContent="center" flexBasis="50%" flexGrow={1}>
      <div style={containerStyle}>
        {loading && (
          <Box display="flex" justifyContent="center" pt="3rem">
            <CircularCenteredLoader />
          </Box>
        )}
        {errorType && renderErrorContainer()}
        {!errorType && !loading && !meeting && !isUserLoggedIn && newUser &&
          <Grid container justify='center' >
            <Grid item md={6} sm={6} xs={10}>
              <Paper className={classes.paper}>
                <Typography style={{fontSize:'2rem',fontWeight:500,marginBottom:'1rem'}}>
                  <p>{t("welcome_msg")}</p>
                </Typography>
                <form
                  // className={classes.form}
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    variant="filled"
                    value={formik.name}
                    required
                    fullWidth
                    id="name"
                    label={full_name_translation}
                    name="name"
                    autoComplete="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.errors.name && formik.touched.name}
                    helperText={formik.errors.name}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.joinButton}
                  >
                    <p>{t("join_meeting")}</p>
                  </Button>
                </form>

              </Paper>
            </Grid>
          </Grid>
        }
        {meeting &&
          <DyteProvider value={meeting}>
            <DyteMeeting
              meeting={meeting}
              mode="fill"
              showSetupScreen={false}
            />
          </DyteProvider>
        }
      </div>
    </Box>
  );
};
export default JoinDyteLinkMeeting;
