import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCaseAnonymous } from "slices/casesSlice";
import { getCompanyFlowsAnonymous } from "slices/companyFlowsSlice";
import { getDisputeAnonymous } from "slices/disputeSlice";
import SingleDispute from "./SingleDispute";

export default function SingleDisputeContainer() {
  const dispatch = useDispatch();
  const disputeId = sessionStorage.getItem("disputeId");
  const email = sessionStorage.getItem("email");

  function getUserProfile(email) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/userappointmentprofilelistanonymous/${null}/${email}/${null}`
        );
        return response.data.profiles[0];
      } catch (error) {
        console.log(error);
      }
    };
  }

  const [profile, setprofile] = useState();
  const [profileLoading, setProfileLoading] = useState(true);
  React.useEffect(() => {
    dispatch(getUserProfile(email)).then((res) => {
      setprofile(res);
      setProfileLoading(false);
    });
  }, []);

  const {
    // profile: { profile, loading: profileLoading },
    disputes: { loading: disputeLoading, activeDispute: activeDispute },
    cases: { loading: caseLoading, activeCase: activeCase },
    companyFlows: { loading: companyFlowsLoading, companyFlows },
  } = useSelector((state) => state, shallowEqual);

  React.useEffect(() => {
    dispatch(getDisputeAnonymous(disputeId, email));
    dispatch(getCaseAnonymous(disputeId, email));
    dispatch(getCompanyFlowsAnonymous());
  }, []);

  const passObjects = {
    profile,
    activeDispute,
    activeCase,
    companyFlows,
  };
  const loading =
    profileLoading ||
    caseLoading ||
    disputeLoading ||
    companyFlowsLoading ||
    isEmpty(activeDispute) ||
    isEmpty(activeCase);

  if (!loading) return <SingleDispute {...passObjects} />;
  else return <CircularCenteredLoader />;
}
